import { Component, OnInit } from '@angular/core';
import { OperationsService } from '../../_services/operations.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sms-lateness-notification',
  templateUrl: './sms-lateness-notification.component.html',
  styleUrls: ['./sms-lateness-notification.component.css']
})
export class SmsLatenessNotificationComponent implements OnInit {
  loading: boolean;
  vendor_id: any;
  message: string;
  token: any;

  constructor(private operationServces: OperationsService, public toastr: ToastrService) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');

    this.operationServces.getLatenessSms(this.token)
      .subscribe(msg => {
        console.log(msg);
        this.message = msg.SMS_LATENESS_NOTOFICATION;
      });
  }

  onSubmitSMS(f) {
    this.loading = true;
    const token = this.vendor_id;
    const formvalue = f.form.value.message;

    this.operationServces.latenessSms(this.token, formvalue)
      .subscribe(onUpdateBodyContent => {
        this.loading = false;
        this.toastr.success('Success', 'Success!');
      });
  }

}
