import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
@Component({
  selector: 'app-rejectedrequest',
  templateUrl: './rejectedrequest.component.html',
  styleUrls: ['./rejectedrequest.component.css']
})
export class RejectedrequestComponent implements OnInit {
  @Input('loan') loan:any;
  constructor(private dataService: DataService) { }

  ngOnInit() {
  }
  confirmReopenRequest(){
    this.dataService.onOpenApplicationsModal.emit({ section: 'confirm_reopen_request', request_id: this.loan.request_id, loan: this.loan });
  }
  showProfilePic(pic){
    this.dataService.showProfilePic.emit({pic:pic});
  }
}
