<div class="title flex justify-between">
  <span>Compose Email<br><small>Send email to single or multiple contacts</small></span>

</div>
<section class="overpanel-scroll">
  <div class="padding-regular-x mt-2">

    <div style="margin-top: 10px; font-size: 12px">
      <div class="compose__email_div" *ngIf="from_where=='0'">
        <!-- <form #f="ngForm"> -->
          <div class="form-group mt-2">
            <input type="text" class="form-control" [(ngModel)]="emailsubject" name="emailsubject" id="subject"
              placeholder="Add Subject">
              
          </div>
          <div *ngIf="from_where == '0'">
            <label class="radio-inline" style="padding-left:0px !important; margin-bottom: 10px">
              <!-- <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"
                (click)="selectCustomer()"> -->
              Search Customer
            </label>
           <!--  <label class="radio-inline" style="padding-left:0px !important; margin-bottom: 10px">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" (click)="allCustomer()">
              All Customer
            </label> -->
            <div *ngIf="selectedCustomer">
              <ul>
                <li style="float:left;margin-bottom:5px;" *ngFor="let item of selectedCustomer; index as i">
                  <div style="border:1px solid grey;margin-right:4px;padding:3px;border-radius:4px">{{item}}
                    <button class="btn btn-white btn-xs" (click)="removeSelectedName(item)">X</button>
                  </div>
                </li>
              </ul>
              
            </div>
            <div class="flex mb-4" style="flex-wrap:wrap" *ngIf="showallCustomers">
              <div class="chip mr-2 mb-2" *ngFor="let item of selectedCustomer; index as i">{{item}}
                <button class="chip-close" (click)="removeSelectedName(item)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <input style="margin-top: 7px; margin-bottom: 7px" class="form-control"
              placeholder="Search for customer" name="search" [(ngModel)]="search">
              <div style="margin-bottom: 10px;">
                <app-button (click)="searchForCustome()" type="button" class="small mt-2" label="Search" [loading]="loader">
                </app-button>
              </div>
            <div class="panel mb-4" *ngIf="hasCustomerLists" style="overflow:scroll; height: 120px">
              <p><i class="fa fa-spinner fa-spin" *ngIf="results?.length == 0"></i></p>
              <ul *ngIf="results?.length > 0">
                <li class="pd_10 border-bottom cursor-pointer" *ngFor="let result of results | slice:0:9"
                  (click)="selectedName(result.LEGAL_NAME, result.PEOPLE_ID)">
                  <p> {{ result.LEGAL_NAME }}</p>
                </li>
              </ul>
            </div>
          </div>

          <app-ngx-editor [(ngModel)]="emailmessage" name="emailmessage" height="300px" minHeight="300px"  [style]="{height: '400px'}" [required]="true">
          </app-ngx-editor>
          <app-button (click)="onSubmitEmails()" type="submit" class="small mt-2" label="Submit" [loading]="loader">
          </app-button>
        <!-- </form> -->
      </div>
      <div class="compose__email_div" *ngIf="from_where=='1'">
        <form #f="ngForm">
          <div class="form-group mt-2">
            <input type="text" class="form-control" ngModel name="subject" #subject="ngModel" id="subject"
              placeholder="Add Subject">
          </div>
          <div *ngIf="from_where == '0'">
            <label class="radio-inline" style="padding-left:0px !important; margin-bottom: 10px">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"
                (click)="selectCustomer()">
              Select Customer
            </label>
            <label class="radio-inline" style="padding-left:0px !important; margin-bottom: 10px">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" (click)="allCustomer()">
              All Customer
            </label>
            <!-- <div *ngIf="showallCustomers">
              <ul>
                <li style="float:left;margin-bottom:5px;" *ngFor="let item of selectedCustomer; index as i">
                  <div style="border:1px solid grey;margin-right:4px;padding:3px;border-radius:4px">{{item}}
                    <button class="btn btn-white btn-xs" (click)="removeSelectedName(item)">X</button>
                  </div>
                </li>
              </ul>
              
            </div> -->
            <div class="flex mb-4" style="flex-wrap:wrap" *ngIf="showallCustomers">
              <div class="chip mr-2 mb-2" *ngFor="let item of selectedCustomer; index as i">{{item}}
                <button class="chip-close" (click)="removeSelectedName(item)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <input *ngIf="showallCustomers" style="margin-top: 7px; margin-bottom: 7px" class="form-control"
              placeholder="Search for customer" (keyup.enter)="searchTerm$.next($event.target.value)">
            <div class="panel mb-4" *ngIf="hasCustomerLists" style="overflow:scroll; height: 120px">
              <p><i class="fa fa-spinner fa-spin" *ngIf="results?.length == 0"></i></p>
              <ul *ngIf="results?.length > 0">
                <li class="pd_10 border-bottom cursor-pointer" *ngFor="let result of results | slice:0:9"
                  (click)="selectedName(result.legal_name, result.people_id)">
                  <p> {{ result.legal_name }}</p>
                </li>
              </ul>
            </div>
          </div>

          <app-ngx-editor ngModel name="message" height="300px" minHeight="300px" #message="ngModel" [style]="{height: '400px'}" [required]="true">
          </app-ngx-editor>
          <app-button (click)="onSubmitEmail(f)" type="submit" class="small mt-2" label="Submit" [loading]="loader">
          </app-button>
        </form>
      </div>
    </div>
  </div>
</section>