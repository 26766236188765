import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, HostListener, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AnalysisService } from '../../setting-module/analysis.service';
import { OperationsService, DataService, StorageService } from 'src/app/_services';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailService } from 'src/app/email-template/email.service';

@Component({
  selector: 'app-templatedesign',
  templateUrl: './templatedesign.component.html',
  styleUrls: ['./templatedesign.component.css'],
  encapsulation: ViewEncapsulation.Native
})
export class TemplatedesignComponent implements OnInit, OnChanges {
  @Input('email_type') email_type = '14';
  loading = false;
  template_sections: any;
  currentUser: any;
  vendor_id: any;
  @Input('mailBodySection') mailBodySection: any = '14';
  mailBodyContent: any;
  mailSubjectContent: any;
  mailLogoSection: any;
  token: any;
  logoUrl: any;
  mailFooterSection: any;
  footerBodyContent: any;
  vendor: any;
  @Input('location') location: any = 'welcome_email';
  constructor(private services: EmailService, public toastr: ToastrService,
    public operationsService: OperationsService, public dataService: DataService,
    private sanitize: DomSanitizer,
    public storageService: StorageService, ) {
    const vendorIdData = localStorage.getItem('platform');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.emailTemplateUpdate.subscribe(res => {
      this.getTemplateSections();
    });
  }

  ngOnInit() {
    this.getTemplateSections();
  }
  ngOnChanges(changes: SimpleChanges) {
    const email_type: SimpleChange = changes.email_type;
    const mailBodySection: SimpleChange = changes.mailBodySection;
    const location: SimpleChange = changes.location;
    if (email_type.currentValue) {
      if (mailBodySection.currentValue) {
        if (location.currentValue) {
          console.log(location.currentValue);
          this.getTemplateSections()
        }
      }
    }

  }
  getTemplateSections() {
    const uploadData = {
      'vendor_id': this.vendor_id,
      'email_type': this.email_type,
      'token': this.currentUser.token
    };


    this.services.getVendorInformation_(uploadData)
      .subscribe(res => {
        this.vendor = res.data;
      });

    ////////////////////////////////////////////////////////////////////
    // Get mail body content

    const getBodyContent = {
      'vendor_id': this.vendor_id,
      'email_type': this.email_type,
      'section_type': this.mailBodySection,
      'token': this.currentUser.token
    };


    this.services.getContent(getBodyContent)
      .subscribe(bodyContent => {
        this.mailBodyContent = bodyContent[0].content;
        this.mailSubjectContent = bodyContent[0].subject;
      });

    ////////////////////////////////////////////////////////////////////
    // Get vendor logo

    const getLogoContent = {
      'vendor_id': this.vendor_id,
      'email_type': this.email_type,
      'section_type': this.mailLogoSection,
      'token': this.currentUser.token
    };


    this.services.getContent(getLogoContent)
      .subscribe(logoContent => {
        this.logoUrl = logoContent[0].content;
      });

    ////////////////////////////////////////////////////////////////////
    // Get footer content

    const getFooterContent = {
      'vendor_id': this.vendor_id,
      'email_type': this.email_type,
      'section_type': this.mailFooterSection,
      'token': this.currentUser.token
    };


    this.services.getContent(getFooterContent)
      .subscribe(footerContent => {
        this.footerBodyContent = footerContent[0].content;
      });
  }
  openemail() {
    this.dataService.openmailevent.emit({ emailopen: this.location });
  }
  updateimageadvert(){
    this.dataService.openmailadvertevent.emit();
  }
}
