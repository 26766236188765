<section *ngIf="view === 'list'">
  <div class="skeleton-children dark" *ngIf="loading">
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
  </div>


  <div *ngIf="!statement">
    <div class="h-group center mt-4 empty-state mt-5" *ngIf="!loading && !statements.length">
      <div class="icon"><i class="fa fa-meh-o"></i></div>
      <h2 class="title no-float">No bank statement</h2>
      <app-button class="small outline mt-4 mr-1" label="Upload" (click)="openMore.emit('attachment')"></app-button>
      <app-button class="small outline mt-4" label="Send Request" (click)="openMore.emit('sendbankstatementrequest')">
      </app-button>
    </div>

    <div *ngIf="!loading && statements.length">
       
      <div class="panel" *ngFor="let statement of statements"> 
        <div class="panel-body padding-regular" *ngIf="statement.IS_SEVEN_HUNDRED !='1'">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="/assets/images/pdf.svg" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">{{ statement.COMMENTS }}</h3>
              <p class="subtitle fill-w" *ngIf="statement.ANALYSIS_STATUS != 1">Not analyzed</p>
              <p class="subtitle fill-w" *ngIf="statement.ANALYSIS_STATUS == 1">Analysis done</p>
              <div class="subtitle no-margin">{{statement.DATE_ADDED}}</div>
              <div class="subtitle no-margin" *ngIf="statement?.ANALYSED == '1'">{{statement.ANALYSIS_LAST_DONE}}</div>
              <div class="subtitle no-margin" *ngIf="statement?.SENT_FOR_ANALYSIS == '1'">Sent for Analysis</div>
              <div class="flex mt-3" *ngIf="statement.ANALYSIS_STATUS != 1">
                <app-button type="button" class="small outline teal" label="Run analysis"
                  (click)="runAnalytics(statement)"></app-button>
                <a class="btn btn-sm btn-outline-secondary ml-2" target="_blank" href='{{statement.FILE_NAME}}'>
                  View statement
                </a>
              </div>
              <div class="flex mt-3" *ngIf="statement.ANALYSIS_STATUS == 1">
                <app-button type="button" class="small green outline" label="View result"
                  (click)="viewResult(statement)">
                </app-button>
                <app-button type="button" class="small outline ml-1" label="Rerun analysis"
                  (click)="runAnalytics(statement)">
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body padding-regular" *ngIf="statement.IS_SEVEN_HUNDRED =='1'">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img *ngIf="statement.IS_TEST=='1'" class="w-60" src="/assets/images/pdf.svg" />
                <img *ngIf="statement.IS_TEST=='3'" class="w-60" src="/assets/images/1550483386_nibss.jpg" />
                <img *ngIf="statement.IS_TEST=='2'" class="w-60" src="/assets/images/Okra_Logo.f8d2b021.svg" />
                <img *ngIf="statement.IS_TEST=='4'" class="w-60" src="/assets/images/mono.svg" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">{{ statement.DESCRIPTION }}</h3>
              <div *ngIf="statement.CLOUDINARY_LINK !='1'">
                <p class="subtitle fill-w" *ngIf="statement.ANALYSIS_STATUS != 1">Pending customer action</p>

                <div class="flex mt-3" *ngIf="statement.ANALYSIS_STATUS == 1">
                  <app-button type="button" class="small green outline" label="Resend Request"
                    (click)="viewResult(statement)">
                  </app-button>
                </div>
              </div>
              <div *ngIf="statement.CLOUDINARY_LINK =='1'">
                <p class="subtitle fill-w" *ngIf="statement.ANALYSIS_STATUS != 1">Not analyzed</p>
                <p class="subtitle fill-w" *ngIf="statement.ANALYSIS_STATUS == 1">Analysis done</p>
                <div class="subtitle no-margin">{{statement.DATE_ADDED}}</div>
                <div class="subtitle no-margin" *ngIf="statement?.ANALYSED == '1'">{{statement.ANALYSIS_LAST_DONE}}
                </div>
                <div class="subtitle no-margin" *ngIf="statement?.SENT_FOR_ANALYSIS == '1'">Sent for Analysis</div>
                <div class="flex mt-3"
                  *ngIf="statement.ANALYSIS_STATUS != 1 && statement.IS_TEST!='2' && statement.IS_TEST!='4'">
                  <app-button type="button" class="small outline teal" label="Run analysis"
                    (click)="runAnalytics(statement)"></app-button>
                  <a class="btn btn-sm btn-outline-secondary ml-2" target="_blank" href='{{statement.FILE_NAME}}'>
                    View statement
                  </a>
                </div>
                <div class="flex mt-3"
                  *ngIf="statement.ANALYSIS_STATUS != 1 && (statement.IS_TEST=='2'||statement.IS_TEST=='4')">
                  <app-button type="button" class="small outline teal mr-1" *ngIf="statement.FILE_NAME !=''"
                    label="Run analysis" (click)="runStatementAnalysis(statement)"></app-button>
                  <app-button type="button" class="small outline teal mr-1" *ngIf="statement.FILE_NAME !=''"
                    label="View Statement" (click)="viewStatement(statement)"></app-button> 
                </div>
                <div class="flex mt-3" *ngIf="statement.ANALYSIS_STATUS == 1">
                  <div *ngIf="(statement.IS_TEST=='2'||statement.IS_TEST=='4')">
                    <app-button type="button" class="small green outline" label="View result"
                      (click)="viewResult(statement)">
                    </app-button>
                    <app-button type="button" class="small outline ml-1" label="Rerun analysis"
                    (click)="runStatementAnalysis(statement)">
                    </app-button>
                    <app-button type="button" class="small outline teal ml-1" *ngIf="statement.FILE_NAME !=''"
                    label="View Statement" (click)="viewStatement(statement)"></app-button>
                  </div>
                  <div *ngIf="(statement.IS_TEST=='1'||statement.IS_TEST=='3')">
                    <app-button type="button" class="small green outline" label="View result"
                      (click)="viewResult(statement)">
                    </app-button>
                    <app-button type="button" class="small outline ml-1" label="Rerun analysis"
                      (click)="runAnalytics(statement)">
                    </app-button>
                    <app-button type="button" class="small outline teal ml-1" *ngIf="statement.FILE_NAME !=''"
                    label="View Statement" (click)="viewStatement(statement)"></app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="statement">
    <app-bank-statement-analysis-modal [statement]="statement" (cancel)="cancel()" (done)="done($event)" [reqid]="reqid"
      (viewResult)="viewResult($event)">
    </app-bank-statement-analysis-modal>
  </div>
</section>

<section *ngIf="view === 'result'">
  <app-bank-statement-analysis-result [statement]="statement" (close)="cancel()"></app-bank-statement-analysis-result>
</section>
<section *ngIf="view === 'okratransactions'">
  <app-okratransactionsview [statement]="statements" (close)="cancel()"></app-okratransactionsview>
</section>
<section *ngIf="view === 'monotransactions'">
  <app-monotransactionsview [statement]="statements" (close)="cancel()"></app-monotransactionsview>
</section>