<nav class="nav__container">
  <ul class="list__container">
    <li class="list__item">
      <a href="#" class="list__link"><img src="assets/images/alt-logo.png" alt="" class="list__img"></a>
    </li>
    <li class="list__item">
      <img src="assets/images/icon-user.png" class="list__img" style="margin-right: 5px">
      <a href="#" class="list__link">Municipal MFB</a>
    </li>
  </ul>
</nav>
