import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../_services/data.service';
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class ItemComponent implements OnInit {
  @Input('request') request :any;
  @Output() loadRequest = new EventEmitter();
  @Output() loadPortfolio = new EventEmitter();
  @Input('loading') loading = false;
  @Input('active') active = false;

  request_loading = false;
  constructor(private dataService: DataService) {
    this.dataService.onRequestLoadingFromBackend.subscribe(res => {
      this.loading = false;
    });
    this.dataService.onRequestLoadingEndFromBackend.subscribe(res => {
      this.loading = false;
    });
    this.dataService.investmentHasLoaded.subscribe(res => {
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  openLoan(request) {
    this.loading = true;
    this.loadRequest.emit({ request: request });
    this.loadPortfolio.emit({ request: request });
  }
}
