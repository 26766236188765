<div class="padding-regular text-center">
  <h3 class="title flex-column flex-center align-start">Take Ownership<br>
    <small>This implies you are the account officer of the customer.</small>
  </h3>

  <div class="flex flex-column align-center">
    <div class="image-avatar large">
      <img class="img-circle"
        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{ownershipCustomer?.FILE_NAME | imagenotfound}}">
    </div>
    <p>{{ownershipCustomer?.LEGAL_NAME}}</p>
  </div>
  <label class="form-group">
    <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
    <span class="control-label normal">Also take owner of all previous transactions up to date</span>
  </label>

  <div class="mt-4">
    <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
      (click)="confirmOwner(currentUser,ownershipCustomer)" [disabled]="loading||is_done=='1'"
      [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'" class="small">
    </app-button>
  </div>
</div>