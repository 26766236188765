<app-portfolioheader (changeTheDefaultCard_)="changeTheDefaultCard($event)" style="margin-bottom:16px" [loan]="disburse"
  [cards]="cards" [where]='where'></app-portfolioheader>
<div *ngIf="!loadingData">
  <div class="recollection__container">
    <div *ngIf="!has_default_card">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon mb-4"><i class="fa fa-ban"></i></div>
        <h2 class="title no-float">No Default Card</h2>
        <p class="subtitle">No default card has been added yet</p>
      </div>

    </div>

    <div *ngIf="has_default_card" class="flex">
      <div class="mr-3 flex-1">
        <div class="card">
          <div class="top">
            <div class="name">{{card?.bank}}</div>
          </div>
          <div class="middle">
            <div class="number">{{card?.bin.substr(0, 4)}} {{card?.bin.substr(4, 2)}}** **** {{card?.lastfour}}</div>
          </div>
          <div class="bottom">
            <div class="items">
              <div class="item" *ngIf="card?.default === true">
                <span class="label">Status</span>
                <span class="value">Default</span>
              </div>
              <div class="item">
                <span class="label">Expiry</span>
                <span class="value">{{card?.expiry_month}}/{{card?.expiry_year}}</span>
              </div>
              <div class="item">
                <img *ngIf="card?.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                  class="card__img">
                <img *ngIf="card?.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                  class="card__img">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <form [formGroup]="debitAllForm" (ngSubmit)="doPaymentConfirmDebitAll(debitAllForm.value)">
          <h4>Transaction Details</h4>

          <div class="form-group"
            [ngClass]="{'has-error':!debitAllForm.controls['SECURITY_QUESTION_ANSWER'].valid && debitAllForm.controls['SECURITY_QUESTION_ANSWER'].touched}"
            style="margin-bottom:15px">
            <label for="exampleInputEmail1">{{security_question}}</label>
            <input [formControl]="debitAllForm.controls['SECURITY_QUESTION_ANSWER']" type="password"
              class="form-control" id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
            <div *ngIf="otpError" class="error alert alert-block alert-danger fade in"
              style="margin:10px 0px !important">
              Wrong answer.
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-block btn-white" [disabled]="!debitAllForm.valid" type="submit">Confirm
                Payment
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>