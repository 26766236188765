<app-requestheader (autodebitHistory)="autodebitHistory($event)" (changeTheDefaultCard_)="changeTheDefaultCard($event)"
  (setCardAsDefault_)="setCardAsDefault()" (requestForNewCard)="requestForNewCard()" style="margin-bottom:16px"
  [loan]="loan" [cards]="cards" [where]='where'>
</app-requestheader>
<div class="overpanel-scroll">
  <div *ngIf="loadingData" class="padding-regular">
    <div class="cards">
      <div class="card skeleton" class="light">
      </div>
      <div class="card skeleton" class="light">
      </div>
    </div>
  </div>
  <div *ngIf="!loadingData">
    <div class="panel" *ngIf="autodebit_history">
      <div class="padding-regular">
        <div class="flex justify-start mb-4">
          <button class="btn btn-xs btn-outline-secondary"
            (click)="autodebit_history = false; has_default_card = true;"><i
              class="fa fa-arrow-left mr-2"></i>Back</button>
        </div>
        <div class="panel flex justify-between pd_15">
          <div class="h-group flex-1 text-center">
            <h3 class="title no-float color-blue medium no-margin">{{loan?.autodebit_tries}}</h3>
            <p class="subtitle fill-w no-margin">Tries</p>
          </div>
          <div class="h-group flex-1 text-center">
            <h3 class="title no-float color-red medium no-margin">{{loan?.autodebit_failed}}</h3>
            <p class="subtitle fill-w no-margin">Failed</p>
          </div>
          <div class="h-group flex-1 text-center">
            <h3 class="title no-float color-green medium no-margin">{{loan?.autodebit_success}}</h3>
            <p class="subtitle fill-w no-margin">Successfull</p>
          </div>
        </div>
      </div>

      <div class="panel-table padding-regular">
        <table class="table">
          <thead>
            <tr>
              <ng-container *ngFor="let column of objectKeys(columns)">
                <th *ngIf="columns[column]">{{column}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of rows">
              <ng-container *ngFor="let column of objectKeys(columns)">
                <td *ngIf="columns[column]">{{row[column]}}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="!autodebit_history">
      <div *ngIf="!loadingData">
        <div *ngIf="!sending_card_request && !setting_card_as_default" class="recollection__container">
          <div *ngIf="!has_default_card">
            <div class="h-group center mb-4 padded empty-state">
              <div class="icon mb-4"><i class="fa fa-ban"></i></div>
              <h2 class="title no-float">No Default Card</h2>
              <p class="subtitle">No default card has been added yet</p>
              <p class="subtitle" *ngIf="card_count > 0">Cards have been added by the customer</p>
              <p class="subtitle" *ngIf="card_count == 0">No card added</p>
            </div>

          </div>

          <div *ngIf="has_default_card && loan?.loan_status >= 3" class="flex">
            <div class="flex-1">
              <div class="card">
                <div class="top">
                  <div class="name">{{card?.bank}}</div>
                </div>
                <div class="middle">
                  <div class="number">{{card?.bin.substr(0, 4)}} {{card?.bin.substr(4, 2)}}** **** {{card?.lastfour}}
                  </div>
                </div>
                <div class="bottom">
                  <div class="items">
                    <div class="item" *ngIf="card?.default === true">
                      <span class="label">Status</span>
                      <span class="value">Default</span>
                    </div>
                    <div class="item">
                      <span class="label">Expiry</span>
                      <span class="value">{{card?.expiry_month}}/{{card?.expiry_year}}</span>
                    </div>
                    <div class="item">
                      <img *ngIf="card?.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                        class="card__img">
                      <img *ngIf="card?.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                        class="card__img">
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="collection__starts">
                <h2 class="collection__starts-heading">Starts <span>{{loan?.repayment_started_when}}</span></h2>
              </div> -->
              <br><br>
              <div class="panel padding-regular flex justify-between align-center" *ngIf="!chargingCard">
                <!-- <div class="text-center mt-5">
                </div> -->
                <div class="secton-title">Debit this card</div>
                <app-button *ngIf="loan?.loan_status >= 3" class="medium" (click)="chargingCard = !chargingCard"
                  label="Debit" [loading]="loading"></app-button>
              </div>
              <div *ngIf="chargingCard">
                <div>
                  <div class="flex align-center justify-between mb-2 border-bottom">
                    <p class="no-margin">Amount Due</p><span class="default-text-size">
                      {{loan?.due||0 | number:'.2-2'}} </span>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p>Debit total amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="1" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p>Specify amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="2" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="autodebit_form.CHARGE_TYPE=='2'">
                    <div class="col-md-6">
                      <p>Amount</p>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox pull-right">
                        <input type="text" class="form-control" name="AMOUNT_TO_DEBIT"
                          [(ngModel)]="autodebit_form.AMOUNT_TO_DEBIT">
                      </div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <!-- <p>Password</p> -->
                    <input [(ngModel)]="autodebit_form.SECURITY_QUESTION_ANSWER" type="password" class="form-control"
                      id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER" placeholder="Enter password">
                    <div *ngIf="otpError" class="error alert alert-block alert-danger fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 flex justify-between">
                      <button class="btn btn-orange" [disabled]="autodebit_form.SECURITY_ANSWER==''|| loading"
                        (click)="initiateAutodebit()">
                        Confirm Payment
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      </button>
                      <button class="btn btn-outline-danger" [disabled]="loading"
                        (click)="chargingCard = !chargingCard">
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <div *ngIf="requestSuccess" class="error alert alert-block alert-success fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="flex-2" *ngIf="updateCardSchedule">
              <app-cardcollectiontimes (chargeCard)="chargeCard($event)"
                (saveRecollectionTimes)="saveRecollectionTimes($event)"
                [card_recollection_times]="card_recollection_times"></app-cardcollectiontimes>
            </div> -->
            <!-- <div class="flex-2" *ngIf="!updateCardSchedule">
              <div *ngIf="chargingCard">
                <div>
                  <div class="flex align-center justify-between mb-2 border-bottom">
                    <p class="default-text-size no-margin">Amount Due</p><span class="tag medium blue">
                      {{loan?.due||0 | number:'.2-2'}} </span>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p class="default-text-size">Debit total amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="1" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p class="default-text-size">Specify amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="2" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="autodebit_form.CHARGE_TYPE=='2'">
                    <div class="col-md-6">
                      <p class="default-text-size">Amount</p>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox pull-right">
                        <input type="text" class="form-control" name="AMOUNT_TO_DEBIT"
                          [(ngModel)]="autodebit_form.AMOUNT_TO_DEBIT">
                      </div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <p class="default-text-size">Password</p>
                    <input [(ngModel)]="autodebit_form.SECURITY_QUESTION_ANSWER" type="password" class="form-control"
                      id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
                    <div *ngIf="otpError" class="error alert alert-block alert-danger fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-block btn-white" [disabled]="autodebit_form.SECURITY_ANSWER==''||loading"
                        (click)="initiateAutodebit()">Confirm
                        Payment
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <div *ngIf="requestSuccess" class="error alert alert-block alert-success fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!chargingCard">
                <div class="ml-4 flex-3">
                  <ul class="decList bigger">
                    <li><span class="title no-float">Daily Card Debit Schedule</span>
                    </li>
                    <li *ngFor="let times of card_recollection_times"><span>{{times.display}}</span>
                      <span>{{times.amount_to_try}}% {{times.time_to_try}}{{times.time_to_try_minutes}}</span></li>
                  </ul>
                  <app-button *ngIf="loan?.loan_status >= 3" class="small mr-3" (click)="chargingCard=!chargingCard"
                    label="Debit this card" [loading]="loading"></app-button>
                  <app-button class="small" (click)="updateCardSchedule=!updateCardSchedule" label="Update">
                  </app-button>
                </div>

              </div>
            </div> -->
          </div>
          <div *ngIf="has_default_card && loan?.loan_status < 3" class="">
            <div class="flex-1">
              <div class="card">
                <div class="top">
                  <div class="name">{{card?.bank}}</div>
                </div>
                <div class="middle">
                  <div class="number">{{card?.bin.substr(0, 4)}} {{card?.bin.substr(4, 2)}}** **** {{card?.lastfour}}
                  </div>
                </div>
                <div class="bottom">
                  <div class="items">
                    <div class="item" *ngIf="card?.default === true">
                      <span class="label">Status</span>
                      <span class="value">Default</span>
                    </div>
                    <div class="item">
                      <span class="label">Expiry</span>
                      <span class="value">{{card?.expiry_month}}/{{card?.expiry_year}}</span>
                    </div>
                    <div class="item">
                      <img *ngIf="card?.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                        class="card__img">
                      <img *ngIf="card?.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                        class="card__img">
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="collection__starts">
                <h2 class="collection__starts-heading">Starts <span>{{loan?.repayment_started_when}}</span></h2>
              </div> -->
              <br><br>
              
            </div>
            <br><br>
            <div class="fill-w fill-h flex-center flex-column" *ngIf="!card?.default">
              <div class="h-group center mb-4 padded mt-4">
                <h2 class="title no-float">Set Card As Default</h2>
                <p class="subtitle mb-4" style="width:100% !important">This card will be used for repayment</p>
                <app-button class="small mr-1" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
                  (click)="setAsConnectedCard()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
                  [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
                </app-button>
    
    
                <app-button class="small red outline" (click)="setting_card_as_default=false"
                  [disabled]="loading || is_done=='1'" label="Cancel">
                </app-button>
              </div>
            </div>
            <!-- <div class="flex-2" *ngIf="updateCardSchedule">
              <app-cardcollectiontimes (chargeCard)="chargeCard($event)"
                (saveRecollectionTimes)="saveRecollectionTimes($event)"
                [card_recollection_times]="card_recollection_times"></app-cardcollectiontimes>
            </div> -->
            <!-- <div class="flex-2" *ngIf="!updateCardSchedule">
              <div *ngIf="chargingCard">
                <div>
                  <div class="flex align-center justify-between mb-2 border-bottom">
                    <p class="default-text-size no-margin">Amount Due</p><span class="tag medium blue">
                      {{loan?.due||0 | number:'.2-2'}} </span>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p class="default-text-size">Debit total amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="1" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <p class="default-text-size">Specify amount</p>
                    </div>
                    <div class="col-md-3">
                      <div class="checkbox pull-right">
                        <input type="radio" name="CHARGE_TYPE" [value]="2" [(ngModel)]="autodebit_form.CHARGE_TYPE">
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="autodebit_form.CHARGE_TYPE=='2'">
                    <div class="col-md-6">
                      <p class="default-text-size">Amount</p>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox pull-right">
                        <input type="text" class="form-control" name="AMOUNT_TO_DEBIT"
                          [(ngModel)]="autodebit_form.AMOUNT_TO_DEBIT">
                      </div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <p class="default-text-size">Password</p>
                    <input [(ngModel)]="autodebit_form.SECURITY_QUESTION_ANSWER" type="password" class="form-control"
                      id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
                    <div *ngIf="otpError" class="error alert alert-block alert-danger fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-block btn-white" [disabled]="autodebit_form.SECURITY_ANSWER==''||loading"
                        (click)="initiateAutodebit()">Confirm
                        Payment
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form-group" style="margin-bottom:15px">
                    <div *ngIf="requestSuccess" class="error alert alert-block alert-success fade in"
                      style="margin:10px 0px !important">
                      {{message}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!chargingCard">
                <div class="ml-4 flex-3">
                  <ul class="decList bigger">
                    <li><span class="title no-float">Daily Card Debit Schedule</span>
                    </li>
                    <li *ngFor="let times of card_recollection_times"><span>{{times.display}}</span>
                      <span>{{times.amount_to_try}}% {{times.time_to_try}}{{times.time_to_try_minutes}}</span></li>
                  </ul>
                  <app-button *ngIf="loan?.loan_status >= 3" class="small mr-3" (click)="chargingCard=!chargingCard"
                    label="Debit this card" [loading]="loading"></app-button>
                  <app-button class="small" (click)="updateCardSchedule=!updateCardSchedule" label="Update">
                  </app-button>
                </div>

              </div>
            </div> -->
          </div>
        </div>

        <div *ngIf="sending_card_request" class="fill-w fill-h flex-center flex-column">
          <div class="h-group center mb-4 padded mt-4">
            <h2 class="title no-float">Send Card Request</h2>
            <p class="subtitle mb-4">An email will be sent to borrower to add a card</p>
            <app-button class="small mr-1" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
              (click)="requestCard()" [disabled]="loading || is_done=='1' || !grantRight('35')"
              [loading]="loading && is_done=='0'" [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
            </app-button>

            <app-button class="small red outline" (click)="sending_card_request=false"
              [disabled]="loading || is_done=='1'" label="Cancel">
            </app-button>
            <app-linkgenerator [link_type]='2' [request_id]='loan?.request_id' (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
                         
          </div>
          <div class="h-group center mb-4 padded mt-4">
            <p>{{link}}</p> 
                         
          </div> 

        </div>
        <div *ngIf="setting_card_as_default" class="fill-w fill-h flex-center flex-column">
          <div class="h-group center mb-4 padded mt-4">
            <h2 class="title no-float">Set Card As Default</h2>
            <p class="subtitle mb-4" style="width:100% !important">This card will be used for repayment</p>
            <app-button class="small mr-1" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
              (click)="setAsConnectedCard()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
              [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
            </app-button>


            <app-button class="small red outline" (click)="setting_card_as_default=false"
              [disabled]="loading || is_done=='1'" label="Cancel">
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>