
<div class="row">
    <div class="col-md-9">
      <section class="panel overflow-visible">
        <div class="padding-regular flex justify-between align-center">
          <h4 class="section-title">
            Opened Task
          </h4> 
        </div>
      </section>
      <section class="panel">
        <!-- <div *ngIf="tasks.length == 0 && !loading">
          <div class="h-group center mb-4 padded empty-state mt-5">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title no-float">No records</h2>
            <p class="subtitle">No records found</p>
          </div>
        </div> -->
          <!-- <section infinite-scroll (scrolled)="onScrollDown()" class="item-area no-margin" slimScroll width="auto"
            [infiniteScrollDistance]="0"> -->
          <section>
              
          </section>
          <section>
            <div *ngFor="let task of tasks" class="customer align-center">
              <div class="header align-center">
                <div class="content">
                  <div class="name">Created by: {{task.tasked_creator}}</div>
                  <div style="margin-top:10px; margin-bottom: 10px" class="name">{{task.task}}</div>
                  
                  <div *ngIf="task.task_comment">
                      <small><b>Comment</b></small>
                      <div class="type comment">{{task.task_comment }}</div>
                  </div>
  
                  <div class="type" *ngIf="task.task_status == 1"><p><strong>Status: High</strong></p></div>
                  <div class="type" *ngIf="task.task_status == 2"><p><strong>Status: Medium</strong></p></div>
                  <div class="type" *ngIf="task.task_status == 3"><p><strong>Status: Low</strong></p></div>

                  <span class="flex align-center">
                      <p><small><b>End Date </b> {{task.task_end_date | date:'fullDate'}}</small></p>
                  </span>
                  <span *ngIf="task.task_done == 1" class="flex align-center">
                    <p><small><b>Performed by </b> {{task.perform_name}}</small></p>
                  </span>
                  <span *ngIf="task.task_done == 1" class="flex align-center">
                      <p><small><b>Date completed </b> {{task.task_done_date | date:'fullDate'}}</small></p>
                  </span>
                  <h3 *ngIf="task.task_done == 1">
                      <i style="color: green" class="fa fa-check mr-2"></i>
                  </h3>
                  <h3 (click)="deleteTask(task.task_id)" class="trash-icon">
                    <i style="color: red; cursor: pointer" class="fa fa-trash mr-2"></i>
                  </h3>
                </div>
              </div>
            </div>
            <div *ngIf="tasks?.length == 0" class="noTask">
              <div class="panel box edged_box mb_10">
                <div class="panel-body"> 
                  <section class="fill-h fill-v">
                    <div class="h-group center mb-4 padded empty-state">
                      <div class="icon"><i class="fa fa-meh-o"></i></div>
                      <h2 class="title">No Tasks</h2>
                      
                    </div>
                  </section>
                </div>
              </div>
            </div>
  
            <div class="customer align-center" *ngIf="loading">
              <div class="header">
                <a class="img-wrap">
                  <img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
                </a>
                <div class="content">
                  <div class="name skeleton mb-1">Sample Amount</div>
                  <div class="type skeleton">Sample Amount</div>
                </div>
              </div>
            </div>
            <div class="customer align-center" *ngIf="loading">
              <div class="header">
                <a class="img-wrap">
                  <img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
                </a>
                <div class="content">
                  <div class="name skeleton mb-1">Sample Amount</div>
                  <div class="type skeleton">Sample Amount</div>
                </div>
              </div>
            </div>
          </section>
      </section>
  
    </div>
    <div class="col-md-3 pl_0">
      <div class="panel box mb-1">
        <div class="panel-body text-center">
          <div class="h-group">
            <h3 class="title large">{{taskCount}}</h3>
            <p class="subtitle mb-3">Tasks</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- Model -->
  <div class="overlay_ app_overlay animated" *ngIf="showTask">
    <div>
        <div class="col-md-12">
            <a class="dismiss cancel_treat_ close" role="button" (click)="showTask=!showTask">
              <i class="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <div class="overpanel-content padding-regular tall" role="document">
              <div class="form-group">
                  <label for="comment">Comment</label>
                  <textarea class="form-control" name="comment" [(ngModel)]="comment" id="comment" rows="3"></textarea>
                </div>
                <p>
                  <button (click)="updateComment(task_id)" type="submit" class="btn btn-default mtop18">Submit <i *ngIf="loading"
                    class="fa fa-spinner fa-spin"></i></button>
                </p>
          </div>
         
    </div>
  </div>
  