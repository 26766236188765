<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="row">
    <div class="col-md-12">
      <div id="ajax_view">
        <div class="row">
          <div class="col-md-8" id="_profile_edit_parent">

            <section class="panel padding-regular" *ngIf="userType == 'individual'">
              <div class="flex justify-end mb-4">
                <!-- Open for editing and save -->
                <!-- <a [routerLink]="['/i/customer/', userType]" class="btn btn-white btn-xs">Corporate</a>  -->
                <a [routerLink]="['/crm/customers']" class="btn btn-outline-secondary btn-sm">Done</a>
              </div>

              <!-- Individual Profile Editing Section -->
              <ul class="panel padding-regular">
                <li class="flex justify-between align-center">
                  <h3 class="section-title">Basic details</h3>
                  <div>
                    <a data-sub="basic" class="icon-button hide">
                      <i class="fa fa-chevron-up"></i>
                    </a>
                    <a (click)="toogleView('basicInfo')" data-sub="basic" class="icon-button">
                      <i class="fa fa-chevron-down"></i>
                    </a>
                  </div>
                </li>

                <li class="mt-4">
                  <!-- editable form -->
                  <!-- <div class="_profile_edit_toogle panel-body"> -->
                  <form #f="ngForm" novalidate class="row">
                    <div class="col-md-9 no-padding">
                      <div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label normal">Fullname</label>
                            <input type="text" class="form-control" name="LEGAL_NAME" [(ngModel)]="user.LEGAL_NAME">
                            <div class="error otp_error alert alert-block alert-danger fade in hide">
                              Please enter your fullname
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label normal">Email</label>
                            <input [disabled]="user.USE_AUTOMATED_EMAIL" type="email" class="form-control" name="EMAIL" [(ngModel)]="user.EMAIL">
                            <label><input type="checkbox" [(ngModel)]="user.USE_AUTOMATED_EMAIL" name="USE_AUTOMATED_EMAIL"> No email</label>
                            <div class="error otp_error alert alert-block alert-danger fade in hide"> Please
                              enter your email </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label normal">Phone</label>
                            <div class="input-group">
                              <div class="input-group-btn">
                                <button type="button" class="btn btn-white"><span
                                    class="">({{selectedCountryCode}})</span></button>
                                <button style="padding:6px;" data-toggle="dropdown"
                                  class="btn btn-white dropdown-toggle" type="button">
                                  <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu" style="padding:5px">
                                  <li *ngFor="let c of countries">
                                    <a href="javascript:;" (click)="changeCurrency(c)">+{{c?.callingCode[0]}}</a>
                                  </li>
                                </ul>
                              </div>
                              <input type="text" name="PHONE" id="PHONE" [(ngModel)]="user.PHONE" class="form-control">
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-12">
                        <div class="form-group">
                          <div class="error phone_error alert alert-block alert-danger fade in hide">
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-6 ">
                          <div class="form-group">
                            <label class="control-label normal">Date of birth </label>
                            <input type="text" class="form-control" name="DATE_OF_BIRTH"
                              [(ngModel)]="user.DATE_OF_BIRTH" ngui-datetime-picker date-only="true">
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12">
                        <div class="form-group">
                          <div class="error rent_error alert alert-block alert-danger fade in hide"> </div>
                        </div>
                      </div> -->
                      <div>
                        <div class="col-md-6">
                          <!-- <div class="col-md-5">
                                <label class="control-label normal">Nationality</label>
                                <select class="form-control" name="NATIONALITY" [(ngModel)]="user.NATIONALITY">
                                            <option value="0"></option>
                                            <option *ngFor="let country of countries" [value]="country.calling_code[0]"> {{country.name['common']}} </option>
                                </select>
                              <div class="error otp_error alert alert-block alert-danger fade in hide"> Please select your nationality </div>
                             -->
                          <div class="form-group">
                            <label class="control-label normal">Gender</label>
                            <select class="form-control" name="GNEDER" [(ngModel)]="user.GENDER">
                              <option *ngFor="let sex of gender" [value]="sex.value">{{sex.display}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label normal">Marital Status</label>
                            <select class="form-control" name="MARITAL_STATUS" [(ngModel)]="user.MARITAL_STATUS">
                              <option *ngFor="let status of marital_status" [value]="status.value">
                                {{status.display}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label normal">Referrer Code</label>
                          <input class="form-control" name="REFEREE_ID" [(ngModel)]="user.REFEREE_ID">
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="form-group">
                          <input type="checkbox" name="SEND_WELCOME_EMAIL" [(ngModel)]="user.SEND_WELCOME_EMAIL">
                          <label class="control-label normal">Send Welcome email</label>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <app-button (click)="updateBasicInfo(f.value, f.valid)" type="button"
                            [disabled]="loading||user.LEGAL_NAME==''||user.EMAIL==''||user.PHONE==''" class="small"
                            [loading]="loading" [label]="actionText"></app-button>
                        </div>
                      </div>
                      <div *ngIf="saveCustomerFirst" class="alert alert-block alert-danger fade in">
                        Please save the basic details
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group profile-nav">
                        <div class="flex justify-end mt-5">
                          <a class="image-avatar large">
                            <img class="prev_img_1"
                              src="http://app.creditclan.com/creditclan_demo/pub/attachments/avatar-mini.jpg">
                          </a>
                          <input type="hidden" name="ATTACHMENT1_ID" [(ngModel)]="user.ATTACHMENT1_ID">
                          <input type="hidden" name="PEOPLE_ID" [(ngModel)]="user.PEOPLE_ID">
                          <input type="hidden" name="IS_EDIT" [(ngModel)]="user.IS_EDIT">
                          <input type="hidden" name="CUSTOMER_TYPE" [(ngModel)]="user.CUSTOMER_TYPE" value="1">
                          <input type="file" class="att-add-file" style="display:none">
                        </div>
                      </div>
                    </div>
                  </form>
                  <!-- </div> -->
                </li>
              </ul>

              <ul class="panel padding-regular">
                <li class="flex justify-between align-center">
                  <h3 class="section-title">Address</h3>
                  <div>
                    <a href="javascript:;" data-sub="address" class="icon-button hide"><i
                        class="fa fa-chevron-up"></i></a>
                    <a (click)="toogleView('address')" data-sub="address" class="icon-button"><i
                        class="fa fa-chevron-down"></i></a>
                  </div>
                </li>

                <li class="sub address animated mt-4" *ngIf="model.address.open">
                  <!-- Address Form -->
                  <div class="_profile_edit_toogle panel-body">
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <input type="hidden" name="ADDRESS_ID" id="ADDRESS_ID" value="">
                        <label class="control-label normal">Country</label>
                        <select id="COUNTRY_ID" name="COUNTRY_ID" class="form-control" name="COUNTRY_ID"
                          [(ngModel)]="model.address.data['COUNTRY_ID']">
                          <option value="">select a country</option>
                          <option *ngFor="let country of model.dump.countries" [value]="country.callingCode[0]">
                            {{country?.name['common']}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide cty_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">
                          State
                        </label>
                        <select id="STATE_ID" name="STATE_ID" class="form-control"
                          [(ngModel)]="model.address.data['STATE_ID']">
                          <option value="">Select an option</option>
                          <option value="1">Abia </option>
                          <option value="2">Adamawa </option>
                          <option value="3">Akwa Ibom </option>
                          <option value="4">Anambra </option>
                          <option value="5">Bauchi </option>
                          <option value="6">Bayelsa </option>
                          <option value="7">Benue </option>
                          <option value="8">Borno </option>
                          <option value="9">Cross River </option>
                          <option value="10">Delta </option>
                          <option value="11">Ebonyi </option>
                          <option value="12">Edo </option>
                          <option value="13">Ekiti </option>
                          <option value="14">Enugu </option>
                          <option value="15">FCT</option>
                          <option value="16">Gombe </option>
                          <option value="17">Imo </option>
                          <option value="18">Jigawa </option>
                          <option value="19">Kaduna </option>
                          <option value="20">Kano </option>
                          <option value="21">Katsina </option>
                          <option value="22">Kebbi </option>
                          <option value="23">Kogi </option>
                          <option value="24">Kwara </option>
                          <option value="25">Lagos </option>
                          <option value="26">Nasarawa </option>
                          <option value="27">Niger </option>
                          <option value="28">Ogun </option>
                          <option value="29">Ondo </option>
                          <option value="30">Osun </option>
                          <option value="31">Oyo </option>
                          <option value="32">Plateau </option>
                          <option value="33">Rivers </option>
                          <option value="34">Sokoto </option>
                          <option value="35">Taraba </option>
                          <option value="36">Yobe </option>
                          <option value="37">Zamfara </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide st_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">
                          Address
                        </label>
                        <label class="pull-right item-text-counter2">160</label>
                        <textarea id="ADDRESS" name="ADDRESS" class="form-control"
                          [(ngModel)]='model.address.data["ADDRESS"]'>{{model.address.data["ADDRESS"]}}</textarea>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide add_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">
                          Landmark
                        </label>
                        <input class="form-control" id="LANDMARK" name="LANDMARK"
                          [(ngModel)]='model.address.data["LANDMARK"]'>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide ld_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="hideAT">
                      <div class="col-md-8 col-md-offset-2">
                        <div class="form-group">
                          <label class="control-label normal">
                            Nature of Accom.
                          </label>
                          <select class="form-control" name="NATURE_OF_ACCOMODATION"
                            [(ngModel)]='model.address.data["NATURE_OF_ACCOMODATION"]'>
                            <option [value]="0">select nature of accomodation</option>
                            <option [value]="1">Owned</option>
                            <option [value]="2">Rent</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-8 col-md-offset-2 hideAT ">
                        <div class="form-group">
                          <label class="control-label normal">
                            Time at Current Address (Years)
                          </label>
                          <input class="form-control" id="TIME_AT_CURRENT_ADDRESS" name="TIME_AT_CURRENT_ADDRESS"
                            [(ngModel)]='model.address.data["TIME_AT_CURRENT_ADDRESS"]'>
                        </div>
                      </div>
                      <div class="col-md-8 col-md-offset-2">
                        <div class="form-group row hide taca_error e">
                          <div class="error alert alert-block alert-danger fade in">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 col-md-offset-2" *ngIf="model.address.data?.NATURE_OF_ACCOMODATION==='2'">
                        <div class="form-group hideExpiry row">
                          <label class="control-label normal">
                            Expiry Date
                          </label>
                        </div>
                        <div class="hide">
                          <select class="form-control" id="DAY" name="DAY" [(ngModel)]="model.address.data['DAY']">
                            <option *ngFor="let day of model.dump.days" [value]="day">{{day}}</option>
                          </select>
                        </div>
                        <div class="col-md-4 col-md-offset-2 ">
                          <select class="form-control" id="MONTH" name="MONTH"
                            [(ngModel)]="model.address.data['MONTH']">
                            <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
                          </select>
                        </div>
                        <div class="col-md-4 ">
                          <select class="form-control" id="YEAR" name="YEAR" [(ngModel)]="model.address.data['YEAR']">
                            <option *ngFor="let year of model.dump.years" [value]="year">{{year}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-group row hideAT">
                                <div class="col-md-8 col-md-offset-2">
                                    <label class="control-label normal">
                                        Proof of address
                                    </label>
                                    <select class="form-control" [(ngModel)]="model.address.data['ATTACHMENT_TYPE']">
                                        <option [value]="1">Utility Bill</option>
                                    </select>
                                </div>
                            </div>
                            <div class="pp ">
                                <div class="form-group row">
                                    <div class="col-md-8 col-md-offset-2">
                                        <label class="control-label normal">
                                            Upload file
                                        </label>
                                        <div style=" background:rgba(243,243,243,1.00); padding:5px; width:auto">
                                            <a href="javascript:void(0)" style="cursor:pointer;border-radius:0px" class="staff-education_add_2"><i class="fa fa-file" style="padding-right:5px"></i><span class="bst_">Choose file</span></a>
                                            <input type="hidden" name="ATTACHMENT_ID" id="ATTACHMENT_ID" value="">
                                            <input class="uploadType" type="hidden" value="8">
                                            <input type="file" class="att-add-file" style="display:none">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                    <div class="form-group row hide p_error e">
                      <div class="col-md-8 col-md-offset-2">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="col-md-8 col-md-offset-2">
                        <app-button (click)="updateAddress()" type="button" [disabled]="loading" class="small"
                          label="Save" [loading]="loading"></app-button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <ul class="panel padding-regular">
                <li class="flex justify-between align-center">
                  <h3 class="section-title">Next of Kin Information and Dependants</h3>
                  <div>
                    <a href="javascript:;" data-sub="nextofkin" class="icon-button hide">
                      <i class="fa fa-chevron-up"></i></a>
                    <a (click)="toogleView('nok')" data-sub="nextofkin" class="icon-button fadeIn">
                      <i class="fa fa-chevron-down"></i></a>
                  </div>
                </li>

                <li class="sub nextofkin animated mt-4" *ngIf="model.nok.open">
                  <!-- Next of Kin Form -->
                  <div class="_profile_edit_toogle panel-body">
                    <div class="row">
                      <div class="col-md-8 col-md-offset-2">
                        <div class="">
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label class="control-label normal" for="">Name</label>
                              <input name="NOK_NAME" class="form-control" id="NOK_NAME" type="text" placeholder=""
                                [(ngModel)]="model.nok.data['NOK_NAME']">
                              <div class="error nok_error alert alert-block alert-danger fade in hide">
                                Enter the next of kin name
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label class="control-label normal" for="">Next Of Kin Phone</label>
                              <input name="NOK_PHONE" class="form-control" id="NOK_PHONE" type="text" placeholder=""
                                [(ngModel)]="model.nok.data['NOK_PHONE']">
                              <div class="error nop_error alert alert-block alert-danger fade in hide">
                                Enter the next of kin phone
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label class="control-label normal" for="">Next Of Address</label>
                              <textarea class="form-control" name="NOK_ADDRESS" id="NOK_ADDRESS" cols="30" rows="3"
                                [(ngModel)]="model.nok.data['NOK_ADDRESS']"></textarea>
                              <div class="error noad_error alert alert-block alert-danger fade in hide">
                                Enter the next of kin address
                              </div>
                            </div>
                          </div>
                          <div class="error noe__error alert alert-block alert-danger fade in hide">
                            Enter the next of kin email
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label class="control-label normal" for="">Next Of Kin Email</label>
                              <input name="NOK_EMAIL" class="form-control" id="NOK_EMAIL" type="text" placeholder=""
                                [(ngModel)]="model.nok.data['NOK_EMAIL']">
                              <div class="error noe_error alert alert-block alert-danger fade in hide">
                                Enter the next of kin email
                              </div>
                            </div>
                          </div>
                          <div class="error noe__error alert alert-block alert-danger fade in hide">
                            Enter the next of kin email
                          </div>
                          <hr>
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label class="control-label normal" for="">Number of Dependants</label>
                              <input name="NUMBER_OF_DEPENDANTS" class="form-control" id="NUMBER_OF_DEPENDANTS"
                                type="text" placeholder="" [(ngModel)]="model.nok.data['NUMBER_OF_DEPENDANTS']">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <!-- <a (click)="updateNok()" id="addNextOfKin" class="  btn btn-block btn-blue btn-primary addNextOfKin" style="padding: 6px 12px">Submit <i class="fa fa-spinner fa-spin hide"></i></a> -->
                              <app-button (click)="updateNok()" type="button" [disabled]="loading" class="small"
                                *ngIf="!loading" label="Save" [loading]="loading"></app-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>

            <section class="panel padding-regular" *ngIf="userType == 'corporate'">
              <!-- Corporate Profile Editing Section -->
              <ul class="panel padding-regular">
                <li class="flex justify-between align-center">
                  <h3 class="section-title">Basic details</h3>
                  <div>
                    <a href="javascript:;" data-sub="basic" class="icon-button hide">
                      <i class="fa fa-chevron-up"></i>
                    </a>
                    <a (click)="toogleView('basicInfo')" href="javascript:;" data-sub="basic"
                      class="icon-button fadeIn"><i class="fa fa-chevron-down"></i></a>
                  </div>
                </li>

                <li class="sub basic animated mt-4" *ngIf="model.basicInfo.open">
                  <div class="_profile_edit_toogle panel-body" aria-expanded="false">
                    <!-- Main Form -->
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">Company Name</label>
                        <input type="text" class="form-control" id="LEGAL_NAME" name="LEGAL_NAME"
                          [(ngModel)]="company.LEGAL_NAME" placeholder="">
                        <div class="error otp_error alert alert-block alert-danger fade in hide">
                          Please enter the company name
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label normal">Registeration Number</label>
                        <input type="text" class="form-control" id="BUSINESS_REG_NUMBER" name="BUSINESS_REG_NUMBER"
                          [(ngModel)]="company.BUSINESS_REG_NUMBER" placeholder="">
                        <div class="error otp_error alert alert-block alert-danger fade in hide">
                          Please enter the business registeration number
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label normal">Email</label>
                        <input type="email" class="form-control" id="EMAIL" name="EMAIL" [(ngModel)]="company.EMAIL"
                          placeholder="">
                        <div class="error otp_error alert alert-block alert-danger fade in hide">
                          Please enter email
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label normal">Website</label>
                        <input type="text" class="form-control" id="WEBSITE" name="WEBSITE"
                          [(ngModel)]="company.WEBSITE" placeholder="">
                        <div class="error otp_error alert alert-block alert-danger fade in hide">
                          Please enter website
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label normal">Nature of Business</label>
                        <input type="text" class="form-control" id="NATURE_OF_BUSINESS" name="NATURE_OF_BUSINESS"
                          [(ngModel)]="company.NATURE_OF_BUSINESS" placeholder="">
                        <div class="error otp_error alert alert-block alert-danger fade in hide">
                          Please enter nature of business
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label normal">Tax ID Number</label>
                        <input type="text" class="form-control" id="TAX_ID_NUMBER" name="TAX_ID_NUMBER"
                          [(ngModel)]="company.TAX_ID_NUMBER" placeholder="">
                      </div>
                      <div class="form-group">
                        <app-button (click)="saveCompanyBasic()" type="button"
                          [disabled]="loading||(company.LEGAL_NAME=='')||company.EMAIL==''" class="small"
                          id="updateBasicIdentity" label="Save" [loading]="loading"></app-button>
                      </div>
                    </div>
                    <!-- Phone Number Section -->
                    <link href="http://app.creditclan.com/creditclan_demo/pub/css/flags16.css" rel="stylesheet"
                      type="text/css">
                    <link href="http://app.creditclan.com/creditclan_demo/pub/css/flags32.css" rel="stylesheet"
                      type="text/css">
                    <style>
                      ul.f16 {
                        max-width: 320px;
                        display: block;
                        vertical-align: top;
                        font-size: 12px;
                      }

                      ;

                      ul.f32 {
                        display: block;
                      }

                      .f16 .flag,
                      .f32 .flag {
                        width: auto;
                        clear: right;
                      }

                      .f16 .flag {
                        padding-left: 24px;
                      }

                      .f32 .flag {
                        padding-left: 44px;
                      }

                      .f16 abbr,
                      .f16 i {
                        display: inline-block;
                        width: 24px;
                      }

                      .f32 abbr,
                      .f32 i {
                        display: inline-block;
                        width: 32px;
                        line-height: 32px;
                        vertical-align: bottom;
                      }

                      .f16 i {
                        margin-left: -48px;
                        margin-right: 24px;
                        color: silver;
                      }

                      .f32 i {
                        margin-left: -76px;
                        margin-right: 44px;
                        color: silver;
                      }

                      .flag.deprecated {
                        color: silver;
                      }

                      .flag.island {
                        color: navy;
                      }
                    </style>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">Phone 1</label>
                        <div class="input-group">
                          <div class="input-group-btn">
                            <button type="button" class="btn btn-white"><a href="javascript:;"><span
                                  class="flag ng">(+234)</span></a></button>
                            <button data-toggle="dropdown" class="btn btn-white dropdown-toggle" type="button">
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu f32 hide">
                              <li><a href="javascript:;" data-max_length="11" data-ext="ng" data-ext_="+234"
                                  data-ce="(+234)" class="active flagger flag ng">(+234)
                                  Nigeria</a></li>
                              <li><a href="javascript:;" data-max_length="" data-ext="us" data-ext_="+001"
                                  data-ce="(+001)" class="active flagger flag us">(+001)
                                  USA</a></li>
                            </ul>
                          </div>
                          <input type="hidden" name="PEOPLE_PHONE_ID" id="PEOPLE_PHONE_ID"
                            [(ngModel)]="companyPhone.PEOPLE_PHONE_ID">
                          <input type="hidden" name="PEOPLE_CUSTOMERS_ID" id="PEOPLE_CUSTOMERS_ID"
                            [(ngModel)]="companyPhone.PEOPLE_CUSTOMERS_ID">
                          <input type="hidden" name="COUNTRY_CODE" id="COUNTRY_CODE"
                            [(ngModel)]="companyPhone.COUNTRY_CODE" value="+234">
                          <input type="hidden" name="MAX_LENGTH" id="MAX_LENGTH" value="11">
                          <input type="text" value="" name="PHONE" id="PHONE" class="form-control"
                            [(ngModel)]="companyPhone.PHONE">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <app-button (click)="updateCompanyPhone()" [disabled]="loading||companyPhone.PHONE==''"
                          id="confirm_by_sms" class="small" label="Submit" [loading]="loading"></app-button>
                      </div>
                    </div>
                  </div>
                  <link rel="stylesheet" type="text/css"
                    href="http://app.creditclan.com/creditclan_demo/pub/assets/bootstrap-fileupload/bootstrap-fileupload.css">
                  <script type="text/javascript"
                    src="http://app.creditclan.com/creditclan_demo/pub/assets/bootstrap-fileupload/bootstrap-fileupload.js">
                    </script>
                  <script type="text/javascript"
                    src="http://app.creditclan.com/creditclan_demo/emblem/js/jquery.formatCurrency-1.4.0.js">
                    </script>
                  <script src="http://app.creditclan.com/creditclan_demo/pub/js/core/upload_actions.js"></script>
                </li>
              </ul>

              <ul class="hide">
                <li class="title basic_dir">
                  Directors
                  <a href="javascript:;" data-sub="dir" class="pull-right co-panel hide" style="color:#000"><i
                      class="fa fa-chevron-up"></i></a>
                  <a (click)="toogleView('director')" data-sub="dir" class="pull-right co-panel_ fadeIn"
                    style="color:#000"><i class="fa fa-chevron-down"></i></a>
                </li>
              </ul>

              <ul class="panel padding-regular">
                <li class="flex justify-between align-center">
                  <h3 class="section-title">Address</h3>
                  <a href="javascript:;" data-sub="address" class="icon-button hide">
                    <i class="fa fa-chevron-up"></i></a>
                  <a (click)="toogleView('address')" data-sub="address" class="icon-button fadeIn">
                    <i class="fa fa-chevron-down"></i></a>
                </li>
                <li class="mt-4" *ngIf="model.address.open">
                  <div class="_profile_edit_toogle panel-body" aria-expanded="false">
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <input type="hidden" name="ADDRESS_ID" id="ADDRESS_ID" [(ngModel)]="companyAddress.ADDRESS_ID"
                          value="">
                        <label class="control-label normal">Country</label>
                        <select id="COUNTRY_ID" name="COUNTRY_ID" class="form-control"
                          [(ngModel)]="companyAddress.COUNTRY_ID">
                          <option *ngFor="let country of model.dump.countries" [value]="country['COUNTRY_ID']">
                            {{country["COUNTRY_NAME"]}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-md-8 col-md-offset-2">
                        <label class="control-label normal">State</label>
                        <select id="STATE_ID" name="STATE_ID" class="form-control"
                          [(ngModel)]="companyAddress.STATE_ID">
                          <option value=""></option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide st_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">Business Address</label>
                        <label class="pull-right item-text-counter2">160</label>
                        <textarea id="ADDRESS" name="WORK_ADDRESS" class="form-control"
                          [(ngModel)]="companyAddress.WORK_ADDRESS"></textarea>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">Fax Number</label>
                        <input type="text" class="form-control" id="FAX_NUMBER" name="FAX_NUMBER"
                          [(ngModel)]="companyAddress.FAX_NUMBER" placeholder="">
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <label class="control-label normal">Contact Person(s)</label>
                        <input type="text" class="form-control" id="CONTACT_PERSON" name="CONTACT_PERSON"
                          [(ngModel)]="companyAddress.CONTACT_PERSON" placeholder="">
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group hide p_error e">
                        <div class="error alert alert-block alert-danger fade in">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-md-offset-2">
                      <div class="form-group">
                        <app-button (click)="updateCompanyAddress()" [disabled]="loading" id="addAddress" class="small"
                          label="Submit" [loading]="loading"></app-button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="panel-heading" style="overflow:auto">
                <span class="tools pull-right">
                  <a class="btn btn-outline-secondary open-for-editing" data-toggle="collapse"
                    data-parent="#_profile_edit_parent" href="._profile_edit_toogle" aria-expanded="false"
                    aria-controls="_profile_edit_toogle">Open for Editing</a>
                  <a [routerLink]="['/i/customer/', userType, userId ]" class="btn btn-outline-secondary">Done
                    <i class="fa fa-spin fa-spinner hide"></i></a>
                </span>
              </div>
            </section>

          </div>
          <div class="col-md-4 pl_0">
            <div class="panel padding-regular">
              <div class="icon-text medium">
                <!-- <div style="margin-top: 10px; margin-bottom: 20px;"><img src="assets/images/profile.PNG"></div> -->
                <div class="icon"><i class="fa fa-user-plus"></i></div>
                <div class="text">Create Customer</div>
              </div>
            </div>
            <section class="panel padding-regular">
              <h3 class="section-title mb-5">Records</h3>
              <div class="alert no-padding mt-3">
                <div class="icon"><i class="fa fa-lock"></i></div>
                <div class="texts">
                  <div class="title">Secure</div>
                  <p class="subtitle">All information provided are secured</p>
                </div>
              </div>
              <div class="alert no-padding mt-3">
                <div class="icon default-font-size">
                  <i class="fa fa-briefcase"></i>
                </div>
                <div class="texts">
                  <div class="title">Backed Up</div>
                  <div class="subtitle">Daily Backups ensure no information is lost.</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>