<div *ngIf="from_loan">
    <div class="list-item" (click)="openLoan(request)" [ngClass]="{'active': active}">
        <div class="image" [ngClass]="{'skeleton rounded': loading}">
            <img onerror="this.src='assets/img/avatar-mini.png';" class="{{request | requeststatus}}"
                src="{{request?.FILE_NAME | imagenotfound}}">
        </div>
        <div class="body">
            <h3 class="title ellipsize" [ngClass]="{'skeleton': loading}">{{request?.REQUEST_PRINCIPAL | money}} |
                {{request?.REQUEST_TENOR}} {{request?.REQUEST_PERIOD_ID|loanduration}}({{request?.REQUEST_ID}})</h3>
            <h5 class="subtitle ellipsize mb-1" [ngClass]="{'hidden': loading}">{{request?.LEGAL_NAME}}</h5>
            <p [ngClass]="{'skeleton mt-1': loading, 'replace-parent': request?.AOFF}">
                <span class="first">{{request?.WORKSECTOR}}</span><br>
                <span style="display: blocked; color:red"
                    *ngIf="lender?.PEOPLE_ID=='40245' && request?.PENDING_WALLET_DISBURSE=='4'"
                    class="first">Queued</span><br *ngIf="lender?.PEOPLE_ID=='40245' && request?.PENDING_WALLET_DISBURSE=='4'">
                <span style="display: blocked; color:red"
                    *ngIf="lender?.PEOPLE_ID=='40245' && request?.GENERAL_LENDER!=null"
                    class="first">Sent To Partners - {{request?.SIGNATURE_RIGHT}}</span><br *ngIf="lender?.PEOPLE_ID=='40245' && request?.GENERAL_LENDER!=null">
                <span class="second" *ngIf="showoff">{{request?.AOFF | aoff}}</span>
            </p>

        </div>
        <div class="align-end flex flex-column icons justify-between ml-2">
            <div class="flex inline align-center">
                <span *ngIf="!loading && request?.ELIGIBILITY_ANALYSIS" class="small-text-size color-orange"><i
                        class="fas fa-bell"></i></span>
                <span *ngIf="request?.LOAN_STATUS!='3'" class="tag smaller blue ml-1" [ngClass]="{
            'skeleton fit-content': loading, 
            'blue': request?.TIME_SPENT < 2, 
            'orange': request?.TIME_SPENT > 1 && request?.TIME_SPENT < 4, 
            'red': request?.TIME_SPENT > 3 }">
                    {{request?.TIME_SPENT}}d
                </span>
            </div>
            <div *ngIf="request?.USE_REMITA=='1'" class="remita" [ngClass]="{'hidden': loading}">
                <img src="assets/images/remita.svg" alt="Remita Logo">
            </div>
            <div *ngIf="request?.REPAYMENT_MODE=='6'" class="remita" [ngClass]="{'hidden': loading}">
                <img style="width: 70% !important;" src="assets/images/coat_of_arms_of_nigeria.svg" alt="Payslip Logo">
            </div>
        </div>
    </div>
</div>
<div *ngIf="!from_loan">
    <div class="list-item" (click)="openLoan(request)" [ngClass]="{'active': active}">
        <div class="image" [ngClass]="{'skeleton rounded': loading}">
            <img class="{{request | requeststatus}}" src="{{request?.FILE_NAME | imagenotfound}}">
        </div>
        <div class="body">
            <h3 class="title ellipsize" [ngClass]="{'skeleton': loading}">{{request?.REQUEST_PRINCIPAL | money}} |
                {{request?.REQUEST_TENOR}} {{request?.BID_PERIOD_ID|loanduration}}</h3>
            <h5 class="subtitle ellipsize" [ngClass]="{'hidden': loading}">{{request?.LEGAL_NAME}}</h5>

        </div>
    </div>
</div>