import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';

@Component({
  selector: 'app-ineligiblerequest',
  templateUrl: './ineligiblerequest.component.html',
  styleUrls: ['./ineligiblerequest.component.css']
})
export class IneligiblerequestComponent implements OnInit {
  @Input('loan') loan:any;
  @Input('eligibility') eligibility:any;
  @Input('recommended') recommended:any;
  @Input('request_id') request_id:any;
  constructor(private dataService: DataService) { }

  ngOnInit() {
  }
  confirmReopenRequest(){
    this.dataService.onOpenApplicationsModal.emit({ section: 'confirm_reopen_request', request_id: this.loan.request_id, loan: this.loan });
  }
  
}
