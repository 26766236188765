import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDateModel, IMyInputFieldChanged, IMyCalendarViewChanged, IMyInputFocusBlur, IMyMarkedDate, IMyDate, IMySelector } from 'mydatepicker';
@Component({
  selector: 'app-approvingprepayment',
  templateUrl: './approvingprepayment.component.html',
  styleUrls: ['./approvingprepayment.component.css', 'style.css', 'boot-outline-btn.css']
})
export class ApprovingprepaymentComponent implements OnInit {
  @Input('newloan') newloan: any;
  @Input('disburse') disburse: any;
  @Input('security_question') security_question: any;
  otpconfirmwalletWithdrawalFormError = false;
  public loading = false;
  public selDate: IMyDate = { year: 0, month: 0, day: 0 };
  prepaymentForm: FormGroup;
  public otpError = false;
  public otpHBSFC = false;
  public otpHBR = false;
  public otpErrorMessage = '';
  public otpmessage = '';
  currentUser: any;
  makingFinalPayment = false;
  paymentHasBeenProcessed = false;
  paymentConfirmed = false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    height: '34px',
    width: '210px',
    dateFormat: 'yyyy-mm-dd',
    openSelectorTopOfInput: false,
  };
  constructor(public toastr: ToastrService, private DataService: DataService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService, public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');

    this.prepaymentForm = fb.group({
      'REQUEST_ID': [null, Validators.required],
      'DATE_PAID': [null, Validators.required],
      'LOAN_PREREPAYMENT_ID': [null, Validators.required]
    });

  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  ngOnInit() {
    console.log(this.disburse);
    this.otpError = false;
    this.processDisbursement(this.disburse);

  }
  processDisbursement(disburse) {
    this.loading = false;
    this.otpError = false;
    let d: Date = new Date(disburse.DATE_PAID);
    this.selDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    (<FormControl>this.prepaymentForm.controls['REQUEST_ID'])
      .setValue(this.disburse.REQUEST_ID, { onlySelf: true });
    (<FormControl>this.prepaymentForm.controls['LOAN_PREREPAYMENT_ID'])
      .setValue(disburse.LOAN_PREREPAYMENT_ID, { onlySelf: true });
  }
}
