<h3 class="title">Loan Schedule</h3>
<section class="overpanel-scroll padding-regular">
  <div *ngIf="!loading">
    <div class="flex justify-end align-center mb-4"> 
      <div *ngIf="!OPEN_SCHEDULE && canEditSchedule">
        <app-button buttonclass="small outline" [disabled]="loading" (click)="OPEN_SCHEDULE = true" label="Edit">
        </app-button>
      </div>
      <div *ngIf="OPEN_SCHEDULE">
        <app-button buttonclass="small outline green" [disabled]="loading" (click)="saveNewRepayment()"
          [loading]="loading" label="Save"></app-button>
      </div>
    </div>
    <div class="contract-table" *ngIf="loan_status < '3'"> 
      <table class="table medium">
        <thead>
          <tr>
            <th>Date</th>
            <th>OB</th>
            <th>Principal</th>
            <th>Interest</th>
            <th>Fees</th>
            <th>Repayment</th>
            <th>CB</th>
          </tr>
        </thead>
        <tbody *ngIf="OPEN_SCHEDULE">
          <tr *ngFor="let item of schedule">
            <td>
              <input type="text" name="REPAYMENT_DATE" [(ngModel)]="item.REPAYMENT_DATE" ngui-datetime-picker
                date-only="true" />
            </td>
            <td>
              <input type="text" name="INITIAL_PRINCIPAL" [(ngModel)]="item.INITIAL_PRINCIPAL">
            </td>
            <td>
              <input type="text" name="PRINCIPAL_REPAYMENT" [(ngModel)]="item.PRINCIPAL_REPAYMENT">
            </td>
            <td>
              <input type="text" name="INTEREST_REPAYMENT" [(ngModel)]="item.INTEREST_REPAYMENT">
            </td>
            <td>
              <input type="text" name="FEES" [(ngModel)]="item.FEES">
            </td>
            <td>
              <input type="text" name="TERM_REPAYMENT" [(ngModel)]="item.TERM_REPAYMENT">
            </td>
            <td>
              <input type="text" name="OUTSTANDING_PRINCIPAL" [(ngModel)]="item.OUTSTANDING_PRINCIPAL">
            </td>
          </tr>


        </tbody>
        <tbody *ngIf="!OPEN_SCHEDULE">
          <tr *ngFor="let item of schedule">
            <td>{{item.REPAYMENT_DATE}}</td>
            <td>{{item.INITIAL_PRINCIPAL | number:'.2-2'}}</td>
            <td>{{item.PRINCIPAL_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.INTEREST_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.FEES + item.VAT_ON_FEES | number:'.2-2'}}</td>
            <td>{{item.TERM_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.OUTSTANDING_PRINCIPAL < 1 ? 0 : item.OUTSTANDING_PRINCIPAL | number:'.2-2'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>{{getTotal('PRINCIPAL_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <td>{{getTotal('INTEREST_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <!-- <td>{{getTotal('FEES', schedule) | number:'.2-2'}}</td> -->
            <td></td>
            <td>{{getTotal('TERM_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="contract-table" *ngIf="loan_status >= '3'"> 
      <table class="table medium">
        <thead>
          <tr>
            <th>Date</th>
            <th>OB</th>
            <th>Principal</th>
            <th>Interest</th>
            <th>Fees</th>
            <th>Repayment</th>
            <th>CB</th>
            <th>Fines</th>
            <th>Paid</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of schedule">
            <td>{{item.REPAYMENT_DATE}}</td>
            <td>{{item.INITIAL_PRINCIPAL | number:'.2-2'}}</td>
            <td>{{item.PRINCIPAL_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.INTEREST_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.FEES | number:'.2-2'}}</td>
            <td>{{item.TERM_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.OUTSTANDING_PRINCIPAL < 1 ? 0 : item.OUTSTANDING_PRINCIPAL | number:'.2-2'}}</td>
            <td>{{item.TOTAL_FINES_SO_FAR | number:'.2-2'}}</td>
            <td>{{item.HOW_MUCH_PAID | number:'.2-2'}}</td>
            <td>{{item.HOW_MUCH_REMAINING | number:'.2-2'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>  
            <td>{{getTotal('PRINCIPAL_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <td>{{getTotal('INTEREST_REPAYMENT', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('FEES', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('TERM_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <td></td>
            <td></td>
            <td>{{getTotal('TOTAL_FINES_SO_FAR', schedule) | number:'.2-2'}}</td>
            <td>{{getTotal('HOW_MUCH_PAID', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('HOW_MUCH_REMAINING', schedule) | number:'.2-2'}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div *ngIf="loading">

  </div>
</section>
