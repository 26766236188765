<app-navbar></app-navbar>
<app-header></app-header>
<div class="cont">
  <div class="container">
    <div class="col-md-3">
      <div class="left-container">
        <app-sidebar></app-sidebar>
      </div>
    </div>
    <div class="col-md-4">
      <div class="middle-container">
        <div class="shadow_container">
          <img src="assets/images/alt-logo.png" alt=""/>
          <div class="text-center">
            <div style="text-decoration: underline;"><h4>Backend</h4></div>
            <p>Underwriting, Reporting, Analytics, workflow, everything</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="right-container">
        <h2 class="onb-title">Backend Settings</h2>
        <div class="onb-content" style="border-bottom: 1px solid #ccc">
          <p>
            There are over 100 set up on our backend which cover the broadscope of lending. For simulation let's cover a
            few
          </p>
          <div style="border-bottom: 1px solid #ccc">
            <ol>
              <li>Configure basic settings</li>
              <li>See how requests works</li>
              <li>Get started</li>
            </ol>
          </div>
        </div>
        <button
          (click)="simulate()"
          class="btn btn-block btn-default padded orange-btn"
        >
          Simulate
        </button>
        <div style="text-align: center;
    padding: 10px;
    font-size: 18px;"><a href="javascript:">Configure the settings</a></div>
      </div>
    </div>
  </div>
</div>
