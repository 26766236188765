import { EventEmitter, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService, LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-okratransactionsview',
  templateUrl: './okratransactionsview.component.html',
  styleUrls: ['./okratransactionsview.component.css']
})
export class OkratransactionsviewComponent implements OnInit {

  @Input() statement: any;
  @Output() cancel = new EventEmitter();
  @Output() done = new EventEmitter();
  @Output() viewResult = new EventEmitter();
  @Input() reqid = '';
  @Input() from_where = '1';
  loading = false;
  password: string;
  currentUser: any;
  transaction_id: string;
  result: any;
  error = null;

  constructor(
    public storageService: StorageService,
    public analyticsService: AnalyticsService,
    public toastr: ToastrService,
    public loansService:LoansService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    console.log(this.statement)
    //this.statement.smstransactions = JSON.parse(this.statement.smstransactions);
  }

  loadResult() {
    this.viewResult.emit({ ...this.statement, ATTACHMENT_ANALYSIS: this.transaction_id });
  }

  analyze() {
    this.error = null;
    this.loading = true;
    this.analyticsService.analyze(this.statement.FILE_NAME, this.password).subscribe(async (data: any) => {
      this.transaction_id = data.transaction_id;
      this.checkStatus();
    });
  }

  checkStatus(count = 0) {
    if (count === 30) return false;
    this.analyticsService.checkStatus(this.transaction_id).subscribe((data: any) => {
      if (!data.analytics_pdf) return this.checkStatus(++count);
      this.analysisComplete(data);
    }, (err) => {
      this.analysisFailed(err.error && err.error.error);
    })
  }

  analysisFailed(err) {
    this.error = err;
    this.result = null;
    this.loading = false;
  }

  analysisComplete(data) {
    this.result = data;
    this.sendToBackend();
  }

  sendToBackend() {
    this.analyticsService.sendBSAnalysisToBackend({
      token: this.currentUser.token,
      request_id: this.reqid,
      attachment_id: this.statement.ATTACHMENT_ID,
      ...this.result,
      analysis_pdf: this.result.analytics_pdf
    }).subscribe(({ status, data }: any) => {
      if (status) this.done.emit(data);
      this.loading = false;
    }, err => {
      this.analysisFailed('An unknown error occurred, please try again');
    })
  }
  getMoreRecords(url){
    this.loading = true;
    this.loansService.getMoreRecordsOkra(this.currentUser.token, url, this.statement.id)
      .subscribe(res => {
        this.loading = false;
        this.statement  = res;
        
      });
  }
}