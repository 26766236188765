<div class="padding-regular" *ngIf="authenticating">
  <div class="three-dots-loader">
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  </div>
  <div class="default text-size text-center">Authenticating..</div>
</div>

<div *ngIf="!authenticating">
  <section *ngIf="view === 'options' && !selected && !currentData">
    <h3 class="section-title mb-5 flex justify-between align-center">
      Identity Check
    </h3>
    <div class="grid three id-items">
      <div class="panel padding-regular icon-text id-item" *ngFor="let id of ids" (click)="select(id)">
        <div class="icon color-blue">
          <i [class]="id.icon || 'fas fa-id-badge'"></i>
          <span class="bg-blue check small-text-size" *ngIf="history[id.type]">
            <i class="fa fa-check"></i>
            {{ history[id.type].length }}
          </span>
        </div>
        <div class="text">{{ id.name }}</div>
      </div>
    </div>
  </section>

  <section *ngIf="view === 'options' && selected && !currentData">
    <div class="panel padding-regular flex">
      <div class="flex-1">
        <div class="h-group mt-2">
          <div class="title no-float">{{ selected.name }}</div>
        </div>
      </div>
      <div class="flex-1">
        <div class="alert alert-danger alert-sm mb-4" *ngIf="error">
          {{ error }}
        </div>
        <div class="form-group">
          <label>{{ selected.label }}</label>
          <input type="text" class="form-control" name="password" [(ngModel)]="id_number" [disabled]="loading">
        </div>
        <div class="form-group" *ngIf="selected.type === 'BANK_ACCOUNT'">
          <label class="control-label normal">Select Bank</label>
          <select name="type" class="form-control" [(ngModel)]="code" [disabled]="loading">
            <option *ngFor="let bank of banks" [value]="bank.BANK_CODE">{{ bank.BANK_NAME }}</option>
          </select>
        </div>
        <div class="text-left mt-4">
          <app-button class="small mr-1 blue" [loading]="loading" (click)="runCheck()" label="Run check">
          </app-button>
          <app-button class="small outline red" label="Cancel" (click)="deselect()" [disabled]="loading"></app-button>
        </div>
      </div>
    </div>

    <!-- <div class="panel padding-regular" *ngIf="history[selected.type]">
      <div class="section-title mb-4">History</div>
      <ul class="flex align-center justify-between default-text-size border-bottom mb-2"
      <ul class="decList">
        <li *ngFor="let id of history[selected.type]">
          <span>
            {{ id }}
          </span>
          <span>
            <button class="btn btn-outline-secondary btn-xs mb-2" (click)="viewHistory(id)">View</button>
          </span>
        </li>
      </ul>
    </div> -->
  </section>

  <section *ngIf="currentData">
    <div class="section-title mb-4 cursor-pointer" (click)="backToIds()">
      <i class="fas fa-long-arrow-alt-left mr-3"></i> Back to Identity Cards
    </div>
    <div class="row flex mb-3">
      <div class="col-md-7 pr_0">
        <div class="panel padding-regular flex-center id-item">
          <div class="image-avatar large">
            <img src="/assets/images/user.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="panel padding-regular flex-center flex-column id-item fill-h">
          <i class="fa-3x" [ngClass]="selected.icon ? selected.icon : ''"></i>
          <div class="default-text-size mt-3 text-center">{{ selected.name }}</div>
        </div>
      </div>
    </div>
    <div class="panel padding-regular">
      <ul class="decList bigger fill-w">
        <li *ngFor="let item of currentData"><span>{{ item.label }}</span><span>{{ item.value }}</span></li>
      </ul>
      <br><br>
      <ul class="decList bigger fill-w" *ngIf="directors">
        <li class="title"><span class="no-padding">Directors</span></li>
        <li class="no-padding" *ngFor="let director of directors">
          <span>{{ director }}</span>
          <span></span>
        </li>
      </ul>
      <br><br>
      <ul class="decList bigger fill-w" *ngIf="shareholders">
        <li class="title"><span class="no-padding">Shareholders</span></li>
        <li class="no-padding" *ngFor="let shareholder of shareholders">
          <span>{{ shareholder }}</span>
          <span></span>
        </li>
      </ul>
    </div>
  </section>
</div>