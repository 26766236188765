import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-portfolioheader',
  templateUrl: './portfolioheader.component.html',
  styleUrls: ['./portfolioheader.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class PortfolioheaderComponent implements OnInit {

  @Input('loan') loan: any;
  @Input('cards') cards: any;
  @Input('where') where = 'cards';
  @Output() changeTheDefaultCard_ = new EventEmitter();
  card_selected: any;
  @Input('accounts') accounts: any;
  account_selected: any;
  constructor() { }

  ngOnInit() {
  }
  changeTheDefaultCard(event) {
    this.changeTheDefaultCard_.emit({ default_card: this.card_selected });
  }
}
