import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lenderType'
})
export class LenderTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Micro Lender";
    } 
  }

}
