import { Component, OnInit, ElementRef, OnDestroy, ViewContainerRef, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AnonymousSubscription } from 'rxjs/Subscription';
import { MarketService, DataService, CustomerService, StorageService, OperationsService, AuthenticationService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Component({
  selector: 'app-kycchecker',
  templateUrl: './kycchecker.component.html',
  styleUrls: ['./kycchecker.component.css']
})
export class KyccheckerComponent implements OnInit {
  loading = false;
  @Input('request_id') request_id: any;
  @Input('people_id') people_id: any;
  @Input('kyctype') kyctype: any;
  currentUser: any;
  @Output() kychcheck = new EventEmitter();
  @Output() checkKYCRecord = new EventEmitter();
  record_exists = false;
  case_id:any;
  case_id_:any;
  constructor(
    public customerServ: CustomerService, private toastr: ToastrService, private _dataService: DataService,
    public router: Router, public operationsService: OperationsService,
    public storageService: StorageService,
    protected customerService: CustomerService, public marketService: MarketService,
    private _eref: ElementRef, private authService: AuthenticationService, private loansService: LoansService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {

    this.cc();


  }

  cc() {
    this.case_id = this.case_id_;
    this.operationsService.checkKYCRecord(this.currentUser.token,
      this.people_id,
      this.request_id, this.kyctype)
      .subscribe(res => {
        this.loading = false;
        if (res.status == true) {
          this.record_exists = true
          this.case_id = res.case_id;
        }
      });
  }

  sendForKYCWork() {
    this.kychcheck.emit({kyctype:this.kyctype})
  }
  checkForKYCWork() {
    this.checkKYCRecord.emit();
  }
}

