<div class="section-title mb-4 cursor-pointer" (click)="close.emit()">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>
<div class="row" *ngIf="loading">
  <div class="col-md-7 pr_0">
    <div class="panel padding-regular fill-w skeleton" style="min-height: 100px;"></div>
  </div>
  <div class="col-md-5">
    <div class="panel padding-regular fill-w skeleton" style="min-height: 100px;"></div>
  </div>
</div>

<div *ngIf="!loading && analysis">
  <div *ngIf="!loading && analysis">
    <div class="flex" *ngIf="from_where=='1'">
      <div class="panel padding-regular flex-1 flex flex-column flex-center">
        <div class="h-group center">
          <div class="title large color-teal"><i class="fas fa-file-invoice"></i></div>
          <div class="subtitle fill-w"><a target="_blank" href="https://view.statementanalysis.co/index.php/bank_analysis/analysis_pdf/{{unique_id}}-{{tid}}">View Report</a></div>
        </div>
      </div>

      <div class="panel padding-regular flex-1 flex flex-column flex-center ml-2">
        <div class="text-center mb-2 flex flex-column align-center">
          <div class="circle medium filled green flex-center flex-column">
            {{ analysis.eligibility.score | number: ".2-2" }}
          </div>
          <div class="default-text-size mt-4">Analysis Score</div>
        </div>
      </div>

      <div class="panel padding-regular flex-1 flex flex-column flex-center ml-2">
        <div class="text-center mb-2 flex flex-column align-center">
          <div class="circle medium filled green flex-center flex-column">
            <i class="fa fa-check"></i>
          </div>
          <div class="default-text-size mt-4">Validity Check</div>
        </div>
      </div>
    </div>

    <div>
      <div class="table-heading">
        <h4>Cashflow Highlight</h4>
      </div>
      <table class="table table-sm table-bordered table-striped">
        <tr>
          <th></th>
          <th>Deposit</th>
          <th>Withdrawal</th>
        </tr>
        <tr *ngIf="from_where=='1'">
          <td>Statement Total</td>
          <td>{{ analysis.highlight.total_deposit | number: ".2-2" }}</td>
          <td>{{ analysis.highlight.total_withdrawal | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Monthly Average</td>
          <td>{{ analysis.highlight.average_monthly_deposit | number: ".2-2" }}</td>
          <td>{{ analysis.highlight.average_monthly_withdraw | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Avg. Weekly</td>
          <td>{{ analysis.highlight.average_weekly_deposit | number: ".2-2" }}</td>
          <td>{{ analysis.highlight.average_weekly_withdraw | number: ".2-2" }}</td>
        </tr>
        <tr *ngIf="from_where=='1'">
          <td>Daily Activity / Month (%)</td>
          <td>{{ analysis.highlight.monthly_percent_deposit | number: ".2-2" }}%</td>
          <td>{{ analysis.highlight.monthly_percent_withdraw | number: ".2-2" }}%</td>
        </tr>
      </table>

      <div class="table-heading">
        <h4 class="bg-red">Income Highlight</h4>
      </div>
      <table class="table table-sm table-bordered table-striped">
        <tr>
          <th></th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        <tr *ngIf="from_where=='1'">
          <td>Constant salary</td>
          <td>{{ analysis.highlight.constant_salary }}</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Most frequent salary</td>
          <td>{{ analysis.highlight.most_frequent_salary }}</td>
          <td>{{ analysis.highlight.most_frequent_salary_date }}</td>
        </tr>
        <tr *ngIf="from_where=='1'">
          <td>Got bonus / Allowance?</td>
          <td>{{ analysis.highlight.benefit_m?.length ? 'Yes' : 'No' }}</td>
          <td>{{ computeBonusDate(analysis.highlight.benefit_m) }}</td>
        </tr>
        <tr>
          <td>Suspected Salary</td>
          <td>{{ analysis.highlight.suspected_salary?.length ? '--' : 'None' }}</td>
          <td>- (D)</td>
        </tr>
      </table>

      <div class="table-heading"  *ngIf="from_where=='1'">
        <h4 class="bg-orange">Borrowing Highlight</h4>
      </div>
      <table  *ngIf="from_where=='1'" class="table table-sm table-bordered table-striped">
        <tr>
          <th></th>
          <th>Amount</th>
          <th>Date/Period</th>
        </tr>
        <tr>
          <td>Latest loan repayment</td>
          <td>{{ analysis.highlight.last_loan_amount }}</td>
          <td>{{ analysis.highlight.last_loan_date }}</td>
        </tr>
        <tr>
          <td>Avg, Repayment</td>
          <td>{{ computeAverageLoans(analysis.highlight.past_loans) | number: ".2-2" }}</td>
          <td>Last {{ analysis.highlight.past_loans?.length }} months</td>
        </tr>
        <tr>
          <td>Total loans disbursed</td>
          <td>{{ analysis.highlight.total_loans_disbursed }}</td>
          <td>Last {{ analysis.highlight.no_month }} month(s)</td>
        </tr>
        <tr>
          <td>Total loans repaid</td>
          <td>{{ analysis.highlight.total_loans_repaid }}</td>
          <td>Last {{ analysis.highlight.no_month }} month(s)</td>
        </tr>
        <tr>
          <td>Maximum OD collection</td>
          <td>{{ analysis.highlight.highest_overdraft || 'None'  }}</td>
          <td>{{ analysis.highlight.days_overdraft || 'None' }}</td>
        </tr>
      </table>

      <div class="table-heading">
        <h4 class="bg-orange">Other Highlight</h4>
      </div>
      <table class="table table-sm table-bordered table-striped">
        <!-- <tr>
        <th></th>
        <th>Amount</th>
        <th>Date/Period</th>
      </tr> -->
        <tr>
          <td>Current Balance</td>
          <td>{{ analysis.highlight.current_balance | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Total Utilities</td>
          <td>{{ analysis.highlight.total_utility | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Total bank / Other charges</td>
          <td>{{ analysis.highlight.bank_charges | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Total gambling</td>
          <td>{{ analysis.highlight.total_gamble | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Total religious giving</td>
          <td>{{ (analysis.highlight.religion_total || 0) | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Entertainment</td>
          <td>{{ analysis.highlight.total_entertainment | number: ".2-2" }}</td>
        </tr>
        <tr>
          <td>Interest earning</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Any embassy payment</td>
          <td>{{ analysis.highlight.total_embassy | number: ".2-2" }}</td>
        </tr>
      </table>
    </div>

    <div class="panel padding-regular">
      <div class="section-title mb-4">
        Derived data
        <small>Validation of registered data</small>
      </div>

      <ul class="decList bigger">
        <li>
          <span>{{ analysis.lender_details.statement_month }}</span>
          <span><i class="fa fa-check color-green"></i></span>
        </li>
        <li  *ngIf="from_where=='1'">
          <span>{{ analysis.lender_details.lender_name?.trim() || 'Account name ?' }}</span>
          <span><i class="fa"
              [ngClass]="analysis.lender_details.lender_name?.trim() ? 'fa-check color-green' : 'fa-times color-red'"></i></span>
        </li>
        <li  *ngIf="from_where=='1'">
          <span>{{ analysis.lender_details.lender_bank?.trim() || 'Bank ?' }}</span>
          <span><i class="fa"
              [ngClass]="analysis.lender_details.lender_bank?.trim() ? 'fa-check color-green' : 'fa-times color-red'"></i></span>
        </li>
        <li  *ngIf="from_where=='1'">
          <span>{{ analysis.lender_details.lender_acc_number_bank?.trim() || 'Account number ?' }}</span>
          <span><i class="fa"
              [ngClass]="analysis.lender_details.lender_acc_number_bank?.trim() ? 'fa-check color-green' : 'fa-times color-red'"></i></span>
        </li>
        <li  *ngIf="from_where=='1'">
          <span>Statement current? {{ analysis.lender_details.statement_current }}</span>
          <span><i class="fa"
              [ngClass]="analysis.lender_details.statement_current === 'No' ? 'fa-times color-red' : 'fa-check color-green'"></i></span>
        </li>
      </ul>
    </div>
  </div>

  <div   *ngIf="from_where=='1'" class="panel padding-regular flex justify-between align-center">
    <div class="section-title color-orange">View original statement attached
      <small class="subtitle no-margin color-grey">Validation or registered data</small>
    </div>
    <a class="btn btn-sm btn-outline-warning" target="_blank"
      href='https://view.statementanalysis.co/index.php/bank_analysis/transactions_view/{{unique_id}}-{{tid}}'>
      View
    </a>
  </div>
</div>