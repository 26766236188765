import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collateral'
})
export class CollateralPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Land";
    }
    if (value == "2") {
      return "House";
    }
    if (value == "3") {
      return "Car";
    }
    if (value == "4") {
      return "Overdue";
    }
    if (value == "5") {
      return "Paid";
    }
    if (value == "6") {
      return "Shares/Stocks/Bonds";
    }
    if (value == "7") {
      return "Invoice";
    }
    if (value == "8") {
      return "Jewellry";
    }
    if (value == "9") {
      return "Other Securities";
    }
  } 
}
