<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{'no-padding': embedModal}">
    <app-contractheader *ngIf="loan?.loan_status < 3 && showHeader" [text]="text" [request_id]="parentRouteId">
    </app-contractheader>
    <app-applicationportfolioheader [text]="text" *ngIf="loan?.loan_status >= 3" [loan]="loan"
      [request_id]="parentRouteId"></app-applicationportfolioheader>

    <section *ngIf="false">
      <div class="row">
        <div class="col-md-7">
          <div class="panel padding-regular text-center flex flex-column align-center">
            <br><br>
            <div class="image-avatar large">
              <img src="/assets/images/user.png" alt="user">
            </div>
            <br><br>
            <br>
            <div class="flex fill-w">
              <div class="h-group flex-1 border-right">
                <div class="title no-margin">23 Dec 2019</div>
                <small class="color-blue">Application Date</small>
              </div>
              <div class="h-group flex-1 border-left">
                <div class="title no-margin">24 Dec 2019</div>
                <small class="color-blue">Test Date</small>
              </div>
            </div>
          </div>
          <div class="panel padding-regular bg-off-white text-center">
            <a href="#">https://bit.ly/notWorking</a>
          </div>
        </div>
        <div class="col-md-5 pl_0">
          <div class="panel padding-regular">
            <div class="text-center mb-2 flex flex-column align-center">
              <div class="circle large filled green flex-center flex-column">67
              </div>
              <div class="default-text-size mt-4">Analysis Score</div>
            </div>
          </div>
          <div class="panel padding-regular">
            <div class="h-group center">
              <div class="title large color-green"><i class="fa fa-shield"></i></div>
              <div class="subtitle">Guaranteed Data Security</div>
            </div>
          </div>
        </div>
      </div>

      <section class="panel box mb-2">
        <div class="panel-body flex padding-regular">
          <div class="flex flex-2 flex-column flex-center">
            <div class="h-group mr-4">
              <div class="title">Analysis Data Score Meter</div>
              <div class="mt-3">Constitutes behaviour that is seen as a pattern for account owner</div>
            </div>
          </div>
          <div class="flex-3 ml-4">
            <div class="decProgress mb_20">
              <div class="progress progress-xs mb_5">
                <div class="progress-bar progress-bar-success" role="progressbar" [attr.aria-valuenow]="20"
                  aria-valuemin="0" aria-valuemax="100" [style.width]="20 + '%'">
                </div>
              </div>
              <p class="no-margin">Credibility</p>
            </div>
            <div class="decProgress mb_20">
              <div class="progress progress-xs mb_5">
                <div class="progress-bar" role="progressbar progress-bar-info" [attr.aria-valuenow]="20"
                  aria-valuemin="0" aria-valuemax="100" [style.width]="20 + '%'">
                </div>
              </div>
              <p class="no-margin">Activeness</p>
            </div>
            <div class="decProgress mb_20">
              <div class="progress progress-xs mb_5">
                <div class="progress-bar" role="progressbar progress-bar-info" [attr.aria-valuenow]="20"
                  aria-valuemin="0" aria-valuemax="100" [style.width]="20 + '%'">
                </div>
              </div>
              <p class="no-margin">Ownership</p>
            </div>
            <div class="decProgress mb_20">
              <div class="progress progress-xs mb_5">
                <div class="progress-bar" role="progressbar progress-bar-info" [attr.aria-valuenow]="20"
                  aria-valuemin="0" aria-valuemax="100" [style.width]="20 + '%'">
                </div>
              </div>
              <p class="no-margin">Low Risk</p>
            </div>
          </div>
        </div>
      </section>

      <div class="row">
        <div class="col-md-7">
          <div class="panel padding-regular">
            <div class="section-title mb-4">Truth test result highlight</div>
            <ul class="decList bigger">
              <li>
                <span>Account number</span>
                <span><i class="fa fa-check color-green"></i></span>
              </li>
              <li>
                <span>Account name</span>
                <span><i class="fa fa-times color-red"></i></span>
              </li>
              <li>
                <span>Ownership confirmed</span>
                <span><i class="fa fa-check color-green"></i></span>
              </li>
              <li>
                <span>Exagerrated income</span>
                <span><i class="fa fa-check color-green"></i></span>
              </li>
              <li>
                <span>Exagerrated expense</span>
                <span><i class="fa fa-times color-red"></i></span>
              </li>
              <li>
                <span>BVN Match</span>
                <span><i class="fa fa-times color-red"></i></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 pl_0">
          <div class="panel padding-regular">
            <div class="text-center mb-2 flex flex-column align-center">
              <div class="circle large filled green flex-center flex-column">67
              </div>
              <div class="default-text-size mt-4">Truth score</div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel">
        <div class="panel-body padding-regular">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="/assets/images/bank-statement.svg" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">Data we analyze</h3>
              <p class="subtitle fill-w mb-2">
                Profile, SMS logs, Phone logs, Repayment data, Remita records, IPPIS, Credit Bureau, Account, BVN, NIN,
                Driver's Licence, Linkedin, Facebook, Twitter, Creditclan, Bank statement, POS transaction
              </p>
              <app-button type="button" class="small outline teal mt-2" label="Goto settings"></app-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="true">
      <div class="panel">
        <div class="panel-body padding-regular" style="padding-top: 4rem!important">
          <div class="flex align-center mt-5 mb-5">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="http://700lenders.com/images/crediblesmockup.png"
                  style="position: absolute; bottom: 0; width: 150px!important; left: -5px;opacity: 1;" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">Send for analysis</h3>
              <p class="subtitle fill-w mb-2">
                Fetch loans from credible market and make good margins
              </p>
              <app-button type="button" class="small outline green mt-3" label="Send"></app-button>
            </div>
          </div>
        </div>
      </div>

      <div class="panel padding-regular flex justify-between align-center">
        <div class="section-title">Where would you like us to focus</div>
        <app-button class="small outline orange" label="View" (click)="openMore.emit('area-of-focus')"></app-button>
      </div>

      <div class="panel padding-regular">
        <!-- <div class="section-title mb-4">All the data points we cover</div> -->
        <div class="grid four mt-4">
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-yellow round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-user"></i>
              </div>
              <div class="text medium-text-size w-60">Profile Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-blue round-icon"><i class="fas fa-building"></i></div>
              <div class="text medium-text-size w-60">Bank Statement Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-red round-icon" style="position:relative;">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-money"></i></div>
              <div class="text medium-text-size w-60">Repayment Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-yellow round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-sms"></i>
              </div>
              <div class="text medium-text-size w-60">SMS Logs Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-blue round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-phone"></i>
              </div>
              <div class="text medium-text-size w-60">Call Logs Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-blue round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fa fa-facebook"></i>
              </div>
              <div class="text medium-text-size w-60">Social Media Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-orange round-icon"><i class="fas fa-plane-departure"></i></div>
              <div class="text medium-text-size w-60">Salary inflight Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-purple round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-money-check"></i>
              </div>
              <div class="text medium-text-size w-60">Cards Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-green round-icon" style="position: relative;">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-fingerprint"></i>
              </div>
              <div class="text medium-text-size w-60">BVN Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-yellow round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-user-alt"></i>
              </div>
              <div class="text medium-text-size w-60">Account Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-blue round-icon" style="position: relative">
                <span *ngIf="false" class="no_data"><i class="fa fa-times"></i></span>
                <i class="fas fa-user"></i>
              </div>
              <div class="text medium-text-size w-60">Guarantor Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-orange round-icon"><i class="fas fa-id-card"></i></div>
              <div class="text medium-text-size w-60">National Identity Analytics</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3">
            <div class="icon-text medium cursor-pointer mt-4">
              <div class="icon color-red round-icon"><i class="fas fa-user"></i></div>
              <div class="text medium-text-size w-60">FRSC Analytics</div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel">
        <div class="panel-body padding-regular">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="/assets/images/settings.svg" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">General Settings</h3>
              <p class="subtitle fill-w mb-2">
                Fetch loans from credible market and make good margins
              </p>
              <app-button type="button" class="small outline teal mt-2" label="Goto settings"></app-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="false">
      <div class="panel" *ngIf="analytics_done">
        <app-help-popover [content]="[
            {
              title: 'Analyzed Records',
              img: 'assets/images/medical-result.svg',
              text:
                '
            <div>
              <p>Data points such as call logs, repayment history, social profile, cards and accounts are analysed.
              </p>
            </div>'
            }
          ]"></app-help-popover>
        <div class="panel-body padding-regular">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="assets/images/correct.svg" />
              </div>
            </div>
            <div class="flex-1">
              <h3 class="section-title mb-4">Analyzed Records</h3>
              <ul class="decList bigger">
                <li *ngIf="analytics_already_done?.CALLOG_ANALYTICS_LAST_DONE">
                  <span>Call logs<br /><span>{{
                      analytics_already_done?.CALLOG_ANALYTICS_LAST_DONE
                    }}</span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'calllogs'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.SMS_LOGS_ANALYTICS_LAST_DONE">
                  <span>Sms logs<br /><span>{{
                      analytics_already_done?.SMS_LOGS_ANALYTICS_LAST_DONE
                    }}</span></span><span><a class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'sms'
                      ]">View</a></span>
                </li>
                <li *ngIf="analytics_already_done?.REPAYMENTS_ANALYTICS_LAST_DONE">
                  <span>Repayments<br /><span>{{
                      analytics_already_done?.REPAYMENTS_ANALYTICS_LAST_DONE
                    }}</span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'repayments'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.SOCIAL_ANALYTICS_LAST_DONE">
                  <span>Social<br /><span>{{
                      analytics_already_done?.SOCIAL_ANALYTICS_LAST_DONE
                    }}</span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'social'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.PROFILE_ANALYTICS_LAST_DONE">
                  <span>Profile<br /><span>{{
                      analytics_already_done?.PROFILE_ANALYTICS_LAST_DONE
                    }}</span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'profile'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.CARD_ANALYTICS_LAST_DONE">
                  <span>Cards<br /><span>
                      {{
                    analytics_already_done?.CARD_ANALYTICS_LAST_DONE
                    }}
                    </span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'cards'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.ACCOUNT_ANALYTICS_LAST_DONE">
                  <span>Account<br /><span>
                      {{
                    analytics_already_done?.ACCOUNT_ANALYTICS_LAST_DONE
                    }}
                    </span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'accounts'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="analytics_already_done?.GUARANTOR_ANALYTICS_LAST_DONE">
                  <span>Guarantor<br /><span>
                      {{
                    analytics_already_done?.GUARANTOR_ANALYTICS_LAST_DONE
                    }}
                    </span></span><span><button class="btn btn-outline-secondary btn-xs" [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'guarantors'
                      ]">
                      View
                    </button></span>
                </li>
                <li *ngIf="
                    analytics_already_done?.DIRECT_DEBIT_ANALYTICS_LAST_DONE
                  ">
                  <span>Direct debit<br /><span></span></span><span><button class="btn btn-outline-secondary btn-xs"
                      [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'direct_debit'
                      ]">
                      View
                    </button></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="panel padding-regular">
        <app-help-popover [content]="[
            {
              title: 'Run Specific Analytics',
              img: 'assets/images/laptop.svg',
              text:
                '
                <div>
                  <p>This section is about all you need to run analytics on your platform.
                  </p>
                </div>'
            }
          ]"></app-help-popover>
        <div class="flex align-center mt-5 mb-5">
          <div class="section-poster flex-1">
            <div class="image flex-center">
              <img class="w-60" src="assets/images/specific.svg" />
            </div>
          </div>
          <div class="h-group flex-1">
            <h3 class="title no-margin">Run Specific Analytics</h3>
            <p class="subtitle fill-w mb-2">
              Everything you want in an analytics of your platform is right
              available for you anyway you want it to play
            </p>
            <button type="button" [disabled]="!loan" (click)="openModal('specific_analytics')"
              class="btn btn-outline-secondary mt-5">
              Run Analytics
            </button>
          </div>
        </div>
      </div>

      <div class="panel padding-regular">
        <app-help-popover [content]="[
            {
              title: 'Bank Statement Analytics',
              img: 'assets/images/bank-statement.svg',
              text:
                '
            <div>
              <p>Analyze any bank statement and predict customer
                  financial behaviour in 19secs. 
              </p>
            </div>'
            }
          ]"></app-help-popover>
        <div class="flex align-center mt-5 mb-5">
          <div class="section-poster flex-1">
            <div class="image flex-center">
              <img class="w-60" src="assets/images/predictive-chart.svg" />
            </div>
          </div>
          <div class="h-group flex-1">
            <h3 class="title no-margin">Bank Statement Analytics</h3>
            <p class="subtitle fill-w mb-2">
              Run expert analysis around the bank statements
            </p>
            <button type="button" [disabled]="!loan" (click)="openBSAnalysis(parentRouteId)"
              class="btn btn-outline-secondary mt-5">
              Run Analytics
            </button>
          </div>
        </div>
      </div>

      <div class="panel">
        <div class="alert padding-regular bg-off-white">
          <div class="texts pl_0">
            <h4 class="title no-margin">Carry Out Credit Check</h4>
            <p class="subtitle no-margin">
              We are connecting with Bureaus in your country
            </p>
          </div>
          <div class="action">
            <app-button (click)="opeCreditCheck(parentRouteId)" buttonclass="small outline" label="Run Credit Check">
            </app-button>
          </div>
        </div>
      </div>

      <div class="panel mb-1" *ngIf="loan?.customer.have_something">
        <div class="panel-body padding-regular flex justify-between align-center">
          <div class="user">
            <div class="image">
              <img src="assets/img/mask.png" />
            </div>
            <div class="content">
              <h4 class="title">
                We know something about {{ loan?.customer?.legal_name }}
              </h4>
              <p class="subtitle">Find out what we know</p>
            </div>
          </div>
          <app-button (click)="openLoanSpider(loan?.request_id)" buttonclass="small outline" label="Run"></app-button>
        </div>
      </div>
    </section>
  </div>
</div>