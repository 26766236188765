<app-portfolioheader style="margin-bottom:16px" [loan]="disburse" [where]='where'></app-portfolioheader>
<section class="panel">
  <div class="panel-body">
    <div class="row">
      <div class="col-md-5">
          <div><img src="assets/images/remita.jpg"></div>
      </div>
      <div class="col-md-7">
          <div *ngIf="!paymentHasBeenProcessed">
              <form [formGroup]="debitInstruction" (ngSubmit)="doSendDebitInstruction(debitInstruction.value)">
                <!-- <h4>Transaction Details</h4> -->
                <div [ngClass]="acc?.HAS_DIRECT_DEBIT==true? 'beachacc': ''" *ngFor="let acc of accounts">  
                    <div *ngIf="acc?.HAS_DIRECT_DEBIT==true && acc?.DIRECT_DEBIT_REQUEST?.IS_ACTIVE=='1'">
                        <div class="beachacc-details pull-left" (click)="useAsDirectDebitAccount(acc?.ACCOUNT_CARD_ID,acc?.DIRECT_DEBIT_REQUEST)">
                            <p style="margin-bottom: 0px">{{acc.bank_name}}</p>
                            <p style="margin-bottom: 0px">{{acc.CARD_NAME}}</p>
                            <p>{{acc.LAST_FOUR_DIGITS}}</p>
                          </div>
                          <div class="beachacc-status pull-right" *ngIf="acc.ACCOUNT_CARD_ID==directdebitrequest?.ACCOUNT_CARD_ID">
                            <a>
                              <i class="fa fa-check-square"></i>
                            </a>
                          </div>
                    </div>
      
                  </div>
                  <div class="row">
                      <div class="col-md-9">
                        <label class="control_label">Debit total amount</label>
                      </div>
                      <div class="col-md-3">
                        <div class="checkbox pull-right">
                          <input type="radio" name="CHARGE_TYPE" [value]="1" [formControl]="debitInstruction.controls['CHARGE_TYPE']">
      
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <label class="control_label">Specify amount</label>
                      </div>
                      <div class="col-md-3">
                        <div class="checkbox pull-right">
                          <input type="radio" name="CHARGE_TYPE" [value]="2" [formControl]="debitInstruction.controls['CHARGE_TYPE']">
      
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="debitInstruction.controls['CHARGE_TYPE'].value=='2'">
                      <div class="col-md-6">
                        <label class="control_label">Amount</label>
                      </div>
                      <div class="col-md-6">
                        <div class="checkbox pull-right">
                          <input type="text" class="form-control" name="AMOUNT_TO_DEBIT" [formControl]="debitInstruction.controls['AMOUNT_TO_DEBIT']">
      
                        </div>
                      </div>
                    </div>
                <div class="form-group" [ngClass]="{'has-error':!debitInstruction.controls['SECURITY_QUESTION_ANSWER'].valid && debitInstruction.controls['SECURITY_QUESTION_ANSWER'].touched}"
                  style="margin-bottom:15px">
                  <label for="exampleInputEmail1">Enter your password</label>
                  <input [formControl]="debitInstruction.controls['SECURITY_QUESTION_ANSWER']" type="password" class="form-control" id="SECURITY_QUESTION_ANSWER"
                    name="SECURITY_QUESTION_ANSWER">
                  <div *ngIf="otpError" class="error alert alert-block alert-danger fade in" style="margin:10px 0px !important">
                    Wrong answer.
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-block btn-white" [disabled]="!debitInstruction.valid" type="submit">Confirm Payment
                      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="paymentHasBeenProcessed">
              <div class="">
                <h4 class="text-center">Instruction Sent</h4>
      
                <div style="width: 120px;margin:20px auto;">
                  <svg style="width: 100%;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                    y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve">
                    <g>
                      <circle fill="none" stroke="#1CA261" stroke-width="6" stroke-miterlimit="10" cx="50" cy="50" r="47" />
                      <polygon style="color:#1CA261" points="72,25 42,71 27,56 23,60 43,80 77,28  " />
                    </g>
                  </svg>
                </div>
      
              </div>
            </div>
      </div>
    </div>
  </div>
</section>