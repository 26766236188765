import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService, DataService, CustomerService, AuthenticationService, StorageService, LoansService, OperationsService } from '../../_services/index';
@Component({
  selector: 'app-loancontractsection',
  templateUrl: './loancontractsection.component.html',
  styleUrls: ['./loancontractsection.component.css']
})
export class LoancontractsectionComponent implements OnInit {
  @Input('currentUser') currentUser:any;
  @Input('request_id') request_id:any;
  @Input('loan') loan:any;
  @Input('loading') loading:any;
  @Input('is_top_up') is_top_up:any;
  @Output() showActionsModal = new EventEmitter();
  @Output() editOtherAction = new EventEmitter();
  constructor(public dataService: DataService) { 
    this.dataService.acceptingContractIsDone.subscribe(res => {
      this.loan.schedule_has_been_created = true;
    })
  }

  ngOnInit() {
  }
  openContractModal() {
    //this.dataService.openContractModal.emit({ currentUser: this.currentUser.token, request_id: this.request_id, loan: this.loan });
    this.editOtherAction.emit({ currentUser: this.currentUser.token, request_id: this.request_id, loan: this.loan });
  }
  openLoanActions(){
    this.showActionsModal.emit({request_id:this.request_id})
  }
}
