<div *ngIf="!loadingData">
    <div class="recollection__container">
        <div *ngIf="!has_default_card">
            
        </div>
      
        <div *ngIf="has_default_card" class="flex">
          <div class="mr-3 flex-1">
            <div class="card">
              <div class="top">
                <div class="name">*****</div>
              </div>
              <div class="middle">
                <div class="number">**** **** **** ****</div>
              </div>
              <div class="bottom">
                <div class="items">
                  <div class="item" *ngIf="card?.default === true">
                    <span class="label">Status</span>
                    <span class="value">Default</span>
                  </div>
                  <div class="item">
                    <span class="label">Expiry</span>
                    <span class="value">**/**</span>
                  </div>
                  <div class="item">
                      <img src="assets/images/mastercard.png" alt="card type"
                      class="card__img">
                  </div>
                </div>
              </div>
            </div> 
          </div>
      
          <div class="flex-1">
            <div *ngIf="!fundingComplete">
              <div class="flex">
                <form [formGroup]="cardAddForm" (ngSubmit)="doWalletTopup(walletTopUpForm.value)">
                  <div class="form-group" style="margin-bottom:15px">
                    <label for="exampleInputEmail1">Amount</label>
                    <input [formControl]="walletTopUpForm.controls['AMOUNT']" type="number" class="form-control" id="AMOUNT" name="AMOUNT">
                  </div> 
                  <div class="form-group" style="margin-bottom:15px">
                    <label for="exampleInputEmail1">Password</label>
                    <input [formControl]="walletTopUpForm.controls['PASSWORD']" type="password" class="form-control" id="PASSWORD" name="PASSWORD">
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-block btn-white btn-block" [disabled]="!walletTopUpForm.valid" type="submit">Top Up
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      </button>
                    </div>
                     
                  </div>
                </form>
            </div>
            </div>
            <div *ngIf="fundingComplete">
              <div>
                <a href="javascript:;" style="font-size:40px">
                  <i class="fa fa-check"></i>
                </a>
                <span style="display: block;">Top Successful</span>
              </div>
            </div>
          </div>
      
        </div>
      </div> 
</div>