import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService, AuthenticationService } from '../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../_services/index';

@Component({
  selector: 'app-openapproval',
  templateUrl: './openapproval.component.html',
  styleUrls: ['./openapproval.component.css']
})
export class OpenapprovalComponent implements OnInit {
  sub: any;
  parentRouteId: number;
  loading: boolean;
  currentUser: any;
  loan: any;
  view = 'approvals';
  constructor(private customerService: CustomerService,
    private DataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public router: Router,
    public authService: AuthenticationService) { 
      this.currentUser = this.storageService.read<any>('currentUser');
    }

  ngOnInit() {
    this.getSummary(); 
  }
  getSummary() {

    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.getLoan();

    });

  }
  getLoan() {
    this.loading = true;
    this.loansService.getLoanContractSimple(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        
        this.loading = false;
        this.loan = data.loan;
        this.dataService.onRequestLoadingEndFromBackend.emit({loan:this.loan});
        this.dataService.onRequestLoadingEndFromBackend.emit({loan:this.loan});  
      });
  } 
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.loan.request_id, loan: this.loan });
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
}
