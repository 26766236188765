import { Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService, DataService, CustomerService, AuthenticationService, StorageService, LoansService, OperationsService, OptionsserviceService } from '../_services/index';
import { Router } from '@angular/router';
import { LoancontractComponent } from '../loancontract/loancontract.component';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDateModel, IMyInputFieldChanged, IMyCalendarViewChanged, IMyInputFocusBlur, IMyMarkedDate, IMyDate, IMySelector } from 'mydatepicker';
import { ToastrService } from 'ngx-toastr';
declare var swal: any;

@Component({
  selector: 'app-homeportfolio',
  templateUrl: './homeportfolio.component.html',
  styleUrls: ['./homeportfolio.component.css']
})
export class HomeportfolioComponent implements OnInit {

  due_loans_by_date = false;
  due_date = 'today';
  suspendingfines = false;
  assigning_collection_agent = false;
  link = '';
  calltype = '1';
  showing_due_loans = false;
  activeRequestId = null;
  chargingCard = false;
  sending_instruction = false;
  repayment_mode: any;
  type_of_entry = '-1';
  filteringLoans = false;
  sideview = 'master';
  platform_summary: any;
  newrepayment = false;
  initiate_stop_remita = false;
  initiate_buy_back = false;
  canViewModule = false;
  record_type = '3';
  notification: any;
  initiate_debit_instruction = false;
  initiate_debit_instruction_cancel = false;
  initiate_debit_instruction_status = false;
  stopdebitmandates = false;
  repayment: any;
  initiate_recollection = false;
  dont_queue_payments = false;
  finalBreaking = false;
  platformwallet: any;
  security_question: any;
  state: any;
  disburse: any;
  done = false;
  useDrawer = localStorage.getItem('portfolioLoanViewMode') || false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    height: '34px',
    width: '100%',
    dateFormat: 'yyyy-mm-dd',
    openSelectorTopOfInput: false,
  };
  post_action = '6';
  public trans: any;
  public selDate: IMyDate = { year: 0, month: 0, day: 0 };
  public is_done = '0';
  public parentRouteId: number;
  public loading = false;
  public sub = '7';
  public subb: any;
  public currentUser: any;
  public canDoApproval: false;
  public approvals_queue: any;
  public open_approval = false;
  public approving = false;
  public rejecting = false;
  public level: any;
  public prev_levels: any;
  public model_r = { ilo: 0, reject_reason: '', reject_action: '', approval_notes: '', reject_level: '', wtd: 0, request_id: '', level: '' };
  public model_a = { chk_acts: [], past_one: 1, approval_all_waivers: 1, approval_all_checklist: 1, is_waiver_level: 0, has_waiver: 0, ilo: 0, istd: 0, approval_notes: '', wtd: 1, request_id: '', level: '' };
  public IS_PEER_TO_PEER: any;
  public ADDED_TO_PAYMENT_QUEUE: any;
  public editContract = false;
  loan_approvals = 0;
  public loan: any;
  public overlayOpen = false;
  queue_disbursement = '0';
  break_loan = false;
  public applyMethod = { '1': 'Backend', '2': 'USSD', '3': 'Web' };
  viewing_loan = false;
  rolling_back_payment = false;
  childModal = { location: '', data: {} };
  loan_viewed: any;
  view_state = '0';
  dontshownext: any;
  overlayType = '0';
  kyc_request = {
    KYC_COMMENTS: '',
    KYC_TYPE_ONE: false,
    KYC_TYPE_TWO: false,

  }
  pickup = {
    KYC_COMMENTS: '',
    PICKUP_ADDRESS_TYPE: '1',
    CUSTOM_PICKUP_ADDRESS: ''

  }
  loan_statement = {
    ddlDocList: 1,
    ddlAddHeader: '0',
    ddlAddSignature: '0',
    ddlSendDateOption: '1',
    txtSendDate: '',
    ddlEmailReceiver: '0'
  }
  email_reminder = {
    ddlSendDateOption: '1',
    txtSendDate: '',
    ddlEmailReceiver: '0',
    message: ''
  }
  sms_reminder = {
    ddlSendDateOption: '1',
    txtSendDate: '',
    ddlEmailReceiver: '0',
    message: ''
  }

  doctypes: any;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '5rem',
    minHeight: '7rem',
    placeholder: 'Enter your text here',
    translate: 'no',
    width: '100%',
    minWidth: '100%'
  };
  result: any;
  has_remita = false;
  PASSWORD = '';
  account_for_direct_debit: any;
  changingpayment = false;
  isloanofficer = true;
  canViewLinks = true;
  creditingstatement = false;
  ttype = '1';
  makeloanpayment = false
  reversing_transaction = false;
  clearingFines = false;
  remitanotify = false;
  remita_records: any;
  refreshingremita = false;
  miniSearch = false;
  searchView = false;
  loans: any;
  request_id: any;
  overlayModalSection = '';
  datechosen: any;
  adding_more = false;
  public durations = [
    { display: '0 - 1', checked: false, min: 0, max: 30 },
    { display: '0 - 3', checked: false, min: 31, max: 90 },
    { display: '4 - 6', checked: false, min: 91, max: 180 },
    { display: '7 - 12', checked: false, min: 181, max: 365 },
    { display: '1+ Years', checked: false, min: 366, max: 3650 }
  ];
  public adays = [
    { display: '1 - 3', checked: false, min: 0, max: 3 },
    { display: '4 - 10', checked: false, min: 4, max: 10 },
    { display: '> 10', checked: false, min: 10, max: 365 }
  ];

  public incomes = [
    { display: '< 50K', checked: false, min: 0, max: 50000 },
    { display: '51 - 100k', checked: false, min: 50001, max: 100000 },
    { display: '101k - 250k', checked: false, min: 100001, max: 250000 },
    { display: '250k - 500k', checked: false, min: 250001, max: 500000 },
    { display: '500k - 1m', checked: false, min: 500001, max: 1000000 },
    { display: '> 1m', checked: false, min: 1000001, max: 120000000 }
  ];
  public ages = [
    { display: '18 - 24', checked: false, min: 18, max: 24 },
    { display: '25 - 34', checked: false, min: 25, max: 34 },
    { display: '35 - 45', checked: false, min: 35, max: 45 },
    { display: 'above 45', checked: false, min: 46, max: 100 }
  ];
  public request_date = [
    { display: 'Today', checked: false, min: 0, max: 1 },
    { display: '2 - 7 days', checked: false, min: 2, max: 7 },
    { display: '2 - 4 weeks', checked: false, min: 8, max: 30 },
    { display: '1+ months', checked: false, min: 31, max: 100000 }
  ];
  public statuses = [
    { value: '-1', display: 'Repaid' },
    { value: '2', display: 'Ongoing' },

  ];
  public portfoliostatuses = [
    // { value: '-2', display: 'All' },
    { value: '1', display: 'No Due' },
    { value: '2', display: 'Due' },
    { value: '3', display: 'Late' },
    { value: '4', display: 'NPL' },
    { value: '6', display: 'Par 60' },
    { value: '7', display: 'Par 90' },

  ];
  public percentrepaid = [
    { display: '0', checked: false, min: 0, max: 0 },
    { display: '1 - 20', checked: false, min: 1, max: 21 },
    { display: '21 - 40', checked: false, min: 21, max: 40 },
    { display: '41 - 60', checked: false, min: 41, max: 60 },
    { display: '61 - 80', checked: false, min: 61, max: 80 },
    { display: '81 - 100', checked: false, min: 81, max: 100 },
  ];
  public breaking = [
    { value: '1', display: 'Rollover', 'checked': false },
    { value: '2', display: 'Topup', 'checked': false },
  ];
  public rmodes = [
    { value: '1', display: 'Remita Inflight' },
    { value: '2', display: 'Cards' },
    { value: '3', display: 'Direct Debit' },
    { value: '4', display: 'Others' },
    { value: '5', display: 'Cheques' },
    { value: '6', display: 'Agent' },
  ];
  public marital_statuses = [
    // { value: '-2', display: 'All' },
    { value: '1', display: 'Single' },
    { value: '2', display: 'Married' },
    { value: '3', display: 'Divorced' },
    { value: '4', display: 'Widowed' },
  ];
  brances: any;
  products: any;
  states: any;
  companies: any;
  template = {
    branch_id: '0',
    portfoliostatuses: '0',
    repayment_status: '0',
    repayment_mode: '0',
    customer_category: '0',
    ompany_id: '',
    peer_to_peer: '',
    restructured: false,
    reset: false,
    my_approvals: false,
    account_officer: false,
    start: 0,
    funding: '100',
    token: '',
    min: 0,
    max: 10000000,
    loan_status: '2',
    searchText: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    funding_amount_one: 1,
    funding_amount_two: 1,
    funding_amount_three: false,
    funding_status_disbursement: false,
    funding_status_contract_created: false,
    funding_status_applied: false,
    funding_status_funded: false,
    funding_status: false,
    amount: false,
    approval_level: false,
    rating: false,
    sector: false,
    date: false
  };
  magic_filter = {
    branch_id: '0',
    portfoliostatuses: '0',
    repayment_status: '0',
    repayment_mode: '0',
    customer_category: '0',
    ompany_id: '',
    peer_to_peer: '',
    restructured: false,
    reset: false,
    my_approvals: false,
    account_officer: false,
    start: 0,
    funding: '100',
    token: '',
    min: 0,
    max: 10000000,
    loan_status: '2',
    searchText: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    funding_amount_one: 1,
    funding_amount_two: 1,
    funding_amount_three: false,
    funding_status_disbursement: false,
    funding_status_contract_created: false,
    funding_status_applied: false,
    funding_status_funded: false,
    funding_status: false,
    amount: false,
    approval_level: false,
    rating: false,
    sector: false,
    date: false
  };
  sectors: any;

  designations: any;
  occupations: any;
  approval_levels: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  cards = 'cards';
  accountdebit = 'accountdebit';
  date_chosen: any;
  show_portfolio_calendar = false;
  show_portfolio_calendar_bulk_payment = false;
  show_cash_flow_analysis = false;
  calendar_from = '0';
  calendar_type = '0';
  loan_viewed_details: any;
  changingofficer = false;
  actions_panel = '';
  showActionsModal = false;
  view_ = 'cheques';
  text = 'Change Repayment Mode';
  loadingRouteConfig = false;
  customerreportedpayments = false;
  drawer = false;
  public other_docs = [
    {
      display: 'Valid ID Card',
      value: '1',
      checked: false
    },
    {
      display: 'Other ID Card',
      value: '15',
      checked: false
    },
    {
      display: 'Payslips',
      value: '6',
      checked: false
    },
    {
      display: 'Bank Statements',
      value: '7',
      checked: false
    },
    {
      display: 'Employment Letter',
      value: '8',
      checked: false
    },
    {
      display: 'Purchase Order',
      value: '9',
      checked: false
    },
    {
      display: 'Invoices',
      value: '10',
      checked: false
    },
    {
      display: 'Certificate of Incorporation',
      value: '11',
      checked: false
    },
    {
      display: 'Company Profile',
      value: '12',
      checked: false
    },
    {
      display: 'Allotment of Shares',
      value: '13',
      checked: false
    },
    {
      display: 'Particulars of Directors',
      value: '14',
      checked: false
    },
    {
      display: 'Work ID Card',
      value: '16',
      checked: false
    },
    {
      display: 'Utility Bill',
      value: '17',
      checked: false
    },
    {
      display: 'National ID',
      value: '18',
      checked: false
    },
    {
      display: 'Voter ID',
      value: '19',
      checked: false
    },
    {
      display: 'International Passport',
      value: '20',
      checked: false
    },
    {
      display: 'Offer Letter (Previous Loan)',
      value: '21',
      checked: false
    },
    {
      display: 'Confirmation Letter',
      value: '22',
      checked: false
    },
    {
      display: 'Completed application form',
      value: '23',
      checked: false
    },
    {
      display: 'Original proof of ownership certificate',
      value: '24',
      checked: false
    },
    {
      display: 'Original vehicle license',
      value: '25',
      checked: false
    },
    {
      display: 'Original road worthiness certificate',
      value: '26',
      checked: false
    },
    {
      display: 'Insurance certificate',
      value: '27',
      checked: false
    },
    {
      display: 'Passport photograph',
      value: '28',
      checked: false
    },
    {
      display: 'Inspection report',
      value: '29',
      checked: false
    },
    {
      display: 'Credit report',
      value: '30',
      checked: false
    },
    {
      display: 'Vehicle drop-off form',
      value: '31',
      checked: false
    },
    {
      display: 'Others',
      value: '32',
      checked: false
    },
    {
      display: 'Deed of Transfer',
      value: '33',
      checked: false
    },
    {
      display: 'Credit and Security Agreement',
      value: '34',
      checked: false
    },
    {
      display: 'Financial Statement',
      value: '35',
      checked: false
    },
    {
      display: 'Grace Periods',
      value: '36',
      checked: false
    },
    {
      display: 'Driver License',
      value: '36',
      checked: false
    }
  ];
  guarantors_request = {
    HOW_MANY_GUARANTORS: ''
  };
  rejection = {
    MESSAGE: '',
    SEND_REJECTION_MAIL: false
  };
  sendingindebtedness = false;
  staff_performance_result:any;
  constructor(public authService: AuthenticationService, public optionsService: OptionsserviceService, public operationsService: OperationsService, public toastr: ToastrService,
    vcr: ViewContainerRef, private DataService: DataService,
    public router: Router, public route: ActivatedRoute,
    public loansService: LoansService, public storageService: StorageService) {
    this.DataService.showDueLoansList.subscribe(res => {
      this.showing_due_loans = true;
      this.show_portfolio_calendar_bulk_payment = true;
    });
    this.DataService.loadingRoute.subscribe(res => {
      this.loadingRouteConfig = res.loadingRouteConfig;
    });
    this.DataService.onChangeLoanOfficer.subscribe(res => {
      this.loan = res.loan;
      this.changingofficer = true;
    });
    this.DataService.sendIndebtednessLetter.subscribe(res => {
      this.loan = res.loan;
      this.sendingindebtedness = true;
    });
    this.DataService.portfolioPlatformSummaryLoaded.subscribe(res => {
      this.platform_summary = res.platform_summary;
      if (!this.loan) {
        this.sideview = 'master';
      }
      this.activeRequestId = null;
    });
    this.DataService.portfolioRequestHasBeenLoaded.subscribe(res => {
      //this.loan = res.loan;
      this.sideview = !this.useDrawer ? 'child' : 'master';
    });
    // if (!this.authService.canViewModule('1,3,1026')) {
    //   this.canViewLinks = false;
    // }
    // if (!authService.canViewModule('1,3,5,1026')) {
    //   this.router.navigate(['../unauthorized']);
    // }
    this.currentUser = this.storageService.read<any>('currentUser');
    this.has_remita = this.storageService.read<any>('has_remita');
    this.DataService.onGetData.subscribe(res => {
      if (res) {
        this.overlayOpen = true;
      }
    })
    this.DataService.onOpenApplicationsModal.subscribe(res => {


      if (res.loan) {
        this.loan = res.loan;
      }
      if (res.request_id) {
        this.request_id = res.request_id;
      }
      if (res.section) {
        this.overlayModalSection = res.section;
      }
      if (res.repayment) {
        this.repayment = res.repayment;
      }
    });
    this.DataService.openRollOverActionModal.subscribe(res => {
      this.loan = res.loan;
      this.actions_panel = res.actions_panel;
      this.parentRouteId = res.request_id;
      this.showActionsModal = true;

    });
    this.DataService.acceptBorrowerPayment.subscribe(res => {
      this.notification = res.notify;
      this.acceptingBorrowerPayment();
    })
    this.DataService.onChangeDefaultPayment.subscribe(res => {
      //this.loan = res.loan;
      this.changingpayment = true;
    })
    this.DataService.remitaLoanNotification.subscribe(res => {
      this.loan = res.loan;
      this.remitanotify = true;
    })
    this.DataService.onCreditLoanStatement.subscribe(res => {
      //this.loan = res.loan;
      this.ttype = res.ttype;
      this.creditingstatement = true;
    })
    this.DataService.onSuspendFines.subscribe(res => {

      this.loan = res;
      this.suspendingfines = true;
    })
    this.DataService.rejectBorrowerPayment.subscribe(res => {
      this.notification = res.notify;
      this.childModal.location = 'reject_notify';
    })
    this.DataService.onViewLoan.subscribe(res => {

      this.overlayOpen = false;
      if (res.from == '2') {
        this.getApp();
      }
    })
    this.DataService.initiateDebitInstruction.subscribe(res => {
      this.repayment = res.repayment;
      //this.initiate_debit_instruction = true;
      this.showActionsModal = false;
      this.sending_instruction = true;
      this.DataService.onOpenApplicationsModal.emit({ section: 'account_debit', request_id: this.loan.request_id, loan: this.loan, repayment: this.repayment });
    })
    this.DataService.initStopRemita.subscribe(res => {
      //this.loan = res.loan;
      this.repayment = res.repayment;
      this.initiate_stop_remita = true;

    })
    this.DataService.initLoanBuyBack.subscribe(res => {
      //this.loan = res.loan;
      this.initiate_buy_back = true;

    })
    this.DataService.refreshRemitaDetails.subscribe(res => {
      this.remita_records = res.remita_records;
      this.refreshingremita = true;
    })
    this.DataService.oninitiateReverseTransaction.subscribe(res => {
      this.repayment = res.repayment;
      this.reversing_transaction = true;

    })
    this.DataService.onInitiateClearFines.subscribe(res => {
      this.repayment = res.repayment;
      this.clearingFines = true;

    })
    this.DataService.initiateCheckDebitInstruction.subscribe(res => {
      this.repayment = res.repayment;
      this.initiate_debit_instruction_status = true;
    })
    this.DataService.initiateDebitInstructionCancel.subscribe(res => {
      this.repayment = res.repayment;
      this.initiate_debit_instruction_cancel = true;
    })
    this.DataService.onCancelPayment.subscribe(res => {
      this.break_loan = false;
      this.finalBreaking = false;
      this.dont_queue_payments = false;
    })
    this.DataService.onInitiateRecollection.subscribe(res => {
      this.chargingCard = true;
      this.loan.due = res.HOW_MUCH_REMAINING;
      this.DataService.onOpenApplicationsModal.emit({ section: 'setup_cards', request_id: this.loan.request_id, loan: this.loan });
      //this.initiate_recollection = true;
      this.repayment = res;
    })
    this.DataService.stopDebitMandate.subscribe(res => {
      this.repayment = res.loan;
      this.stopdebitmandates = true;
    })
    this.DataService.onBreakingLoan.subscribe(res => {
      this.break_loan = true
      this.loan_viewed = res.request_id;
      this.loan_viewed_details = res.loan_view;
      this.finalBreaking = false;
    })

    // Catches requests from children on the modal to open.
    this.DataService.onOpenLoanChildModal.subscribe(res => {
      this.loading = false;
      this.is_done = '0'
      this.done = false;
      this.childModal = res;
      if (res.location == 'check_debit_mandate') {

        this.account_for_direct_debit = res.acc;
      }
      if (res.location == 'set_default_repayment_mode') {
        this.loan = res.data;
        this.repayment_mode = res.repayment_mode;
      }
    })
    this.DataService.onMakePaymentFromStatement.subscribe(res => {

      this.viewing_loan = true
      this.loan_viewed = res.parentRouteId;
      this.view_state = '7';
      this.dontshownext = '1';
      //this.makeloanpayment = true;
    })
    this.DataService.onRollbackPaymentFromStatement.subscribe(res => {

      this.rolling_back_payment = true
      this.trans = res.data.trans;
      this.loan = res.data.loan;
    })
    this.DataService.onStartNewRepaymentFlow.subscribe(res => {

      this.request_id = res.parentRouteId;
      this.newrepayment = true;
    })
    this.DataService.showPortfolioCalendarDetails.subscribe(res => {

      this.date_chosen = res.date_chosen;
      this.show_portfolio_calendar = true;
      this.calendar_from = '7';
      this.calendar_type = '7';
    })
    this.DataService.openTheCashFlowForTheDay.subscribe(res => {
      this.date_chosen = res.date_chosen;
      this.show_cash_flow_analysis = true;
    })
    this.DataService.doOpenCustomerReportedPayments.subscribe(res => {
      this.request_id = res.parentRouteId;
      this.customerreportedpayments = true;
    })
    this.DataService.openCalendarBulkRepayments.subscribe(res => {

      this.showing_due_loans = false;
      this.date_chosen = res.date_chosen;
      this.show_portfolio_calendar_bulk_payment = true;
      this.calltype = res.type;
    })
    this.DataService.openCalendarBulkRepayments.subscribe(res => {

      this.showing_due_loans = false;
      this.date_chosen = res.date_chosen;
      this.show_portfolio_calendar_bulk_payment = true;
      this.calltype = res.type;
    })
    this.DataService.assignCollectionAgent.subscribe(res => {

      this.text = 'Assign collection agent';
      this.assigning_collection_agent = true
      this.loan = res.loan;
    })
    this.DataService.getDueRepaymentByDate.subscribe(res => {
      console.log(res)
      this.due_date = res.due_date;
      this.due_loans_by_date = true;
    })
    this.DataService.openSidemenuForDueLoan.subscribe(res => {
      this.activeRequestId = res.due.REQUEST_ID;
      this.reloadCurrentRequestWithId(this.activeRequestId);
      return this.openDrawer();

    })
  }

  breakloanSent(event) {
    this.DataService.breakLoanSentReloadRequest.emit();
    this.break_loan = false;
  }
  acceptingBorrowerPayment() {
    this.loansService.confirmPayment(this.currentUser.token, this.notification)
      .subscribe(result => {
        this.viewing_loan = false;
        this.dont_queue_payments = true;
        this.disburse = result.disbursement;
        this.security_question = result.security_question;
        this.record_type = '6';
      });
  }
  paymentHasBeenProcessedFinally(event) {
    this.break_loan = false;
    this.finalBreaking = false;
    this.DataService.paymentHasBeenProcessedFinally.emit(event)
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }
  showError(message) {
    this.toastr.error(message, 'Error');
  }
  openForFinalPayment(event) {
    this.viewing_loan = false;
    this.dont_queue_payments = true;
    this.disburse = event.disbursement;
    this.security_question = event.security_question;
  }

  doingDisbursement(event) {
    event.new_loan = this.loan.loan;
    this.DataService.disbursingRequest.emit(event);
  }
  cancelCustomerNotification() {
    this.loading = true;
    const sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];

      this.loansService.cancelCustomerNotification(this.currentUser.token, this.parentRouteId, this.notification, this.PASSWORD)
        .subscribe(data => {
          this.loading = false;
          if (data.status) {
            this.is_done = '1';
            this.showSuccess('Payment Cancelled');
          } else {
            this.showError(data.message);
          }
        });
    });
  }
  showMessage(event) {
    if (event.type == 'success') {
      this.showSuccess(event.message)
    }
    if (event.type == 'error') {
      this.showError(event.message)
    }
  }
  getLoanLink(status) {
    this.filteringLoans = true;
    this.magic_filter.loan_status = status;
    this.getLoans();
  }
  getLoanRepaymentMode(status) {
    this.filteringLoans = true;
    this.magic_filter.repayment_mode = status;
    this.getLoans();
  }
  resetFilters() {
    this.filteringLoans = true;
    this.magic_filter.reset = true;
    this.magic_filter.loan_status = '2';
    this.magic_filter = this.template;
    this.getLoans();
  }
  closeOverlay() {
    this.viewing_loan = false;
    this.rolling_back_payment = false;
    this.initiate_debit_instruction = false;
    this.reversing_transaction = false;
    this.initiate_debit_instruction_cancel = false;
    this.stopdebitmandates = false;
    this.initiate_debit_instruction_status = false;
    this.changingpayment = false;
    this.creditingstatement = false;
    this.makeloanpayment = false;
    this.initiate_stop_remita = false;
    this.remitanotify = false;
    this.refreshingremita = false;
    this.clearingFines = false;
    this.initiate_buy_back = false;
    this.assigning_collection_agent = false;
  }
  checkLevel(sector, event, index) {

    this.doctypes[index]['checked'] = event;

  }
  acceptBorrowerContract() {
    this.loading = true;
    this.loansService.acceptContract(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.DataService.acceptingContractIsDone.emit();
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendBVN() {
    this.loading = true;
    this.loansService.sendBVNRequest(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendBVN_() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_bvn_mod', data: this.loan });
  }
  viewschedule() {
    this.showActionsModal = false;
    this.DataService.showScheduleDetails.emit({ request_id: this.loan, canEditSchedule: true, loan: this.loan, schedule_open: true, from_where: '3', loan_status: '', schedule: [] });
  }
  requestCard() {
    this.loading = true;
    this.loansService.sendCardRequest(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  suspendFines() {
    this.loading = true;
    this.loansService.sendSuspendFines(this.currentUser.token, this.loan.loan.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  linkGenerated(data) {
    this.link = data.url;
  }
  confirmBankAccount() {

    this.loading = true;
    this.loansService.sendAccountConfirmationRequest(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendContract() {
    this.loading = true;
    this.loansService.sendContractDocumentRequest(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  closeChildModal() {
    this.childModal.location = '';
    this.break_loan = false;
    this.dont_queue_payments = false;
    this.initiate_recollection = false;
  }
  closeChildModal_(event) {
    this.childModal.location = '';
    if (event.post_action == 'edit_contract') {
      this.post_action = '8'
      this.loan.REQUEST_ID = event.request_id;
      this.sub = event.is_top_up;
    }
  }

  sendKYCRequest() {
    this.loading = true;
    this.loansService.sendKYCRequest(this.currentUser.token, this.loan.REQUEST_ID, this.kyc_request)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendDocsRequest() {
    this.loading = true;
    this.loansService.sendDocumentPickupRequest(this.currentUser.token, this.loan.REQUEST_ID, this.pickup, this.doctypes)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  // Broadcast confirmtion to publish on loan market
  confirmPublish(request_id) {
    this.closeChildModal();
    this.DataService.onConfirmaLoanToMarket.emit({ 'request_id': request_id });
  }

  // Broadcast confirmation of request from lender
  confirmAccept(lender_id) {
    this.closeChildModal();
    this.DataService.onConfirmAcceptOffer.emit({ 'lender_id': lender_id });
  }

  // Broadcast rejection of request from lender
  confirmReject(lender_id) {
    this.closeChildModal();
    this.DataService.onConfirmRejectOffer.emit({ 'lender_id': lender_id });
  }
  cancelContract() {
    this.closeChildModal();
    this.DataService.onConfirmCancelContract.emit();
  }
  // Broadcast confirmation of offer placement for lender
  confirmOffer(form_data) {
    this.closeChildModal();
    this.DataService.onConfirmMakeOffer.emit({ 'form_data': form_data });
  }

  // Broadcast confirmation of removal of loan from market
  confirmRemoval(request_id) {
    this.closeChildModal();
    this.DataService.onConfirmRemoveLoan.emit({ 'request_id': request_id });
  }


  // Navigate to contact on close of 'send to market modal'
  gotoContract(request_id) {
    this.router.navigate(['/loan/', request_id, 'contract']);
    this.closeChildModal();
  }
  sendTheStatement() {
    this.loan_statement.ddlEmailReceiver = '1';
    this.sendLoanStatement();
  }
  previewTheStatement() {
    this.loan_statement.ddlEmailReceiver = '0';
    this.sendLoanStatement();
  }
  previewTheEmailReminder() {
    this.email_reminder.ddlEmailReceiver = '1';
    this.sendEmailReminder();
  }
  sendForCancelAutoDebit() {
    this.loading = true;
    this.loansService.sendForCancelAutoDebit(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendForReactivateAutoDebit() {
    this.loading = true;
    this.loansService.sendForReactivateAutoDebit(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  sendTheEmailReminder() {
    this.email_reminder.ddlEmailReceiver = '0';
    this.sendEmailReminder();
  }
  sendEmailReminder() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.subb = this.route.params.subscribe(params => {

      this.parentRouteId = +params['id'];
      this.operationsService.sendEmailReminder(this.currentUser.token, this.parentRouteId, this.email_reminder)
        .subscribe(result => {
          this.result = result;
          if (result.status == true) {
            this.done = true;
            this.showMessage(result.data.message)
          } else {
            this.showError(result.data.message)
          }
        });
    });
  }
  previewTheSMSReminder() {
    this.sms_reminder.ddlEmailReceiver = '1';
    this.sendSMSReminder();
  }
  sendTheSMSReminder() {
    this.sms_reminder.ddlEmailReceiver = '0';
    this.sendSMSReminder();
  }
  sendSMSReminder() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.operationsService.sendSMSReminder(this.currentUser.token, this.parentRouteId, this.sms_reminder)
        .subscribe(result => {
          this.result = result;
          if (result.status == true) {
            this.done = true;
            this.showMessage(result.data.message)
          } else {
            this.showError(result.data.message)
          }
        });
    });
  }
  sendLoanStatement() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.operationsService.sendLoanStatement(this.currentUser.token, this.parentRouteId, this.loan_statement)
        .subscribe(result => {
          this.result = result;
          if (result.status == true) {
            this.done = true;
            this.showMessage(result.data.message)
          } else {
            this.showError(result.data.message)
          }
        });
    });
  }
  getApp() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.loansService.getASingleLoan(this.currentUser.token, this.parentRouteId)
        .subscribe(loans => {
          this.IS_PEER_TO_PEER = loans.loan.IS_PEER_TO_PEER;
          this.loan = loans.loan;
        });
    });
  }

  closeApproving() {
    this.open_approval = false;
    this.rejecting = false;
    this.approving = false;
  }
  approveRequest() {

    this.open_approval = true;
    this.approving = true;
  }

  confirmRejectRequest() {
    this.childModal = { location: 'confirm_reject_request', data: {} };
  }
  confirmedRejectRequest() {
    this.closeChildModal();
    this.rejectRequest();
  }
  rejectRequest() {
    this.open_approval = true;
    this.rejecting = true;
  }
  rejectThisRequest(isValid: boolean, f: any) {
    this.loading = true;
    //if (!isValid) return;
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.loansService.rejectThisRequest(this.currentUser.token, this.model_r)
        .subscribe(loans => {
          this.closeApproving;
          this.loading = false;
          this.canDoApproval = loans.status;
          this.approvals_queue = loans.approvals_queue;
          this.level = loans.level;
          this.prev_levels = loans.prev_levels;
        });
    });
  }
  approveThisRequest(isValid: boolean, f: any) {
    this.loading = true;
    //if (!isValid) return;
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];

      this.loansService.approveThisRequest(this.currentUser.token, this.model_a)
        .subscribe(loans => {
          this.loading = false;
          if (loans.status) {
            this.closeApproving();
            this.loading = false;
            this.getApp();
            // this.canDoApproval = loans.status;
            // this.approvals_queue = loans.approvals_queue;
            // this.level = loans.level;
            // this.prev_levels = loans.prev_levels;
            //window.location.reload();
            this.getApp();
          } else {
            if (loans.message) {
              swal({
                title: 'Approval',
                text: loans.message,
                type: 'error'
              });
            }

            this.getApp();

            this.closeApproving();
          }
        });
    });
  }

  queueForDisbursement() {
    this.loading = true;
    //if (!isValid) return;
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];

      this.loansService.queueForDisbursement(this.currentUser.token, this.parentRouteId)
        .subscribe(loans => {
          this.loading = false;
          if (loans.status) {
            this.getApp()
            this.closeApproving();
            this.loading = false;
            this.ADDED_TO_PAYMENT_QUEUE = loans.ADDED_TO_PAYMENT_QUEUE;
            //this.DataService.onGetLoan.emit(loans.loan); 
            swal({
              title: 'Disbursement',
              text: 'Added to payment queue.',
              type: 'success'
            });
          } else {


            if (loans.action == 'Dismiss') {
              swal({
                title: 'Disbursement',
                text: loans.message,
                type: 'error'
              });
              this.closeApproving();
            } else {
              swal({
                title: 'Disbursement',
                text: loans.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: loans.action,
                cancelButtonText: 'Cancel'
              }).then(function () {

                swal(
                  'Deleted!',
                  'Your imaginary file has been deleted.',
                  'success'
                )
              }, function (dismiss) {
                // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
                if (dismiss === 'cancel') {
                  swal(
                    'Cancelled',
                    '',
                    'error'
                  )
                }
              })
            }
          }
        });
    });
  }
  sendForDirectDebitStatus() {
    this.loading = true;
    this.subb = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];

      this.loansService.sendForDirectDebitStatus(this.currentUser.token, this.parentRouteId, this.account_for_direct_debit)
        .subscribe(data => {
          this.loading = false;

          this.is_done = '1';

          if (data.isActive == true) {
            this.showSuccess('Mandate Is Active');
          } else {
            this.showError('Mandate is not active');
          }
        });
    });
  }

  ngOnInit() {
    this.sideview = 'master';
    this.activeRequestId = null;
    this.optionsService.getSec().subscribe(sectors => (this.sectors = sectors));
    this.optionsService.getOcc().subscribe(sectors => (this.occupations = sectors));
    this.optionsService.getDes().subscribe(sectors => (this.designations = sectors));
    if (this.storageService.read<any>('magic_filter')) { }
    this.getLoans();
    this.pickup.PICKUP_ADDRESS_TYPE = '1'
    let d: Date = new Date();

    this.selDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    if (this.activeRequestId) {

    }
    //this.reloadCurrentRequest();
  }

  onScrollDown() {
    if (this.loans.complete == false) {
      this.loadMoreRecords(this.magic_filter.start)
    }
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  loadMoreRecords(start) {
    this.adding_more = true;
    this.magic_filter.start = start;
    // this.getLoans()
    this.searchView = false;
    this.magic_filter.token = this.currentUser.token;
    //this.loading = true;
    this.loansService.getAllLenderPortfolio(this.ages, this.currentUser.token, this.magic_filter, this.sectors, this.approval_levels, this.statuses, this.durations, this.request_date, this.adays, this.marital_statuses, this.percentrepaid, this.breaking)
      // this.loansService.getLoans(this.magic_filter)
      .subscribe(customers => {
        this.adding_more = false;
        // this.loading = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
      });
  }
  getLoans() {
    this.searchView = false;
    this.loading = true;
    this.magic_filter.token = this.currentUser.token;
    this.magic_filter.account_officer = true;
    this.loansService.getAllLenderPortfolio(this.ages, this.currentUser.token, this.magic_filter, this.sectors,
      this.approval_levels, this.statuses, this.durations, this.request_date, this.adays, this.marital_statuses,
      this.percentrepaid, this.breaking)

      .subscribe(customers => {
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
      });
  }
  openThePaymentForFinalBreaking(event) {

    this.finalBreaking = true;
    this.disburse = event.disbursement;
    this.security_question = event.security_question;
  }
  openSearchView() { }
  enter() { }
  leave() { }
  searchForLoans() {
    this.loading = true;
    this.loansService.searchForLoans(this.currentUser.token, this.magic_filter)
      .subscribe(customers => {
        this.loading = false;
        this.loans = customers;
      });
    // this.getLoans();
  }
  reloadCurrentRequest() {
    this.sideview = 'placeholder';
    let url = this.router.url;
    const tokens = url.split('/');
    if (tokens.length > 2) {
      this.activeRequestId = tokens[3];
      if (this.parentRouteId) {
        this.loansService.getLoanPortfolioSub(this.currentUser.token, this.activeRequestId)
          .subscribe(data => {
            this.loading = false;
            this.loan = data.loan;
            this.sideview = !this.useDrawer ? 'child' : 'master';
          });
      }

    } else {
      this.sideview == 'master';
    }
  }
  reloadCurrentRequestWithId(request_id) {
    this.loan = null;
    this.sideview = 'placeholder';
    this.activeRequestId = request_id;
    this.loansService.getLoanPortfolioDetails(this.currentUser.token, this.activeRequestId)
      .subscribe(data => {
        this.loading = false;
        this.loan = data.loan;
        this.DataService.loanHomePortfolioSideView.emit({ loan: this.loan });
      });
  }
  loadPortfolio(event) {
    this.reloadCurrentRequestWithId(event.request.REQUEST_ID);
    this.activeRequestId = event.request.REQUEST_ID;
    
  }

  doCancelTopUp() {
    this.loading = true;
    this.loansService
      .sendCancelTopUpRequest(
        this.currentUser.token,
        this.childModal.data
      )
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
        this.closeChildModal();
        this.DataService.breakLoanSentReloadRequest.emit();
      });
  }
  openModal_(section) {

    this.showActionsModal = false;
    if (section === 'setup_cheques') {
      if (!this.grantRight('27')) {
        section = 'no_right';
      }
    }
    if (section === 'setup_cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'account_debit') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'remita_inflight') {
      if (!this.grantRight('81')) {
        section = 'no_right';
      }
    }
    if (section === 'bvn') {
      if (!this.grantRight('80')) {
        section = 'no_right';
      }
    }
    if (section === 'cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'accounts') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'attachments') {
      if (!this.grantRight('28')) {
        section = 'no_right';
      }
    }
    this.DataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan });
  }
  setDefaultRepaymentMode(loan, repayment_mode) {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'set_default_repayment_mode', data: this.loan, repayment_mode: repayment_mode });
  }
  changeDefaultRepayment() {
    this.showActionsModal = false;
    this.DataService.onChangeDefaultPayment.emit({ loan: this.loan });
  }
  requestForCard() {
    this.showActionsModal = false;
    if (!this.grantRight('39')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_new_card', data: this.loan });
  }
  stopAutoDebit() {
    this.showActionsModal = false;
    if (!this.grantRight('59')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'stop_auto_debit', data: this.loan });
  }
  startAutoDebit() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'start_auto_debit', data: this.loan });
  }
  creditStatement() {
    this.showActionsModal = false;
    if (!this.grantRight('60')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onCreditLoanStatement.emit({ loan: this.loan, ttype: 2 });
  }
  debitStatement() {
    this.showActionsModal = false;
    if (!this.grantRight('60')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onCreditLoanStatement.emit({ loan: this.loan, ttype: 1 });
  }
  initiateSendRemitaLoanNotification() {
    this.showActionsModal = false;
    this.DataService.remitaLoanNotification.emit({ loan: this.loan });
  }
  initiateStopRemitaCollection() {
    this.showActionsModal = false;
    this.DataService.initStopRemita.emit({ loan: this.loan.loan });
  }
  initiateLoanBuyBack() {
    this.showActionsModal = false;
    this.DataService.initLoanBuyBack.emit({ loan: this.loan.loan });
  }
  requestToGetPaid() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_to_get_paid', data: this.loan });
  }
  requestCard_() {
    this.showActionsModal = false;
    if (!this.grantRight('39')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
  }

  sendContract_() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
  }

  confirmAccount_() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'confirm_account_mod', data: this.loan });
  }
  requestOthers_() {
    this.showActionsModal = false;
    if (!this.grantRight('40')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_others_mod', data: this.loan });
  }
  requestGuarantors_() {
    this.showActionsModal = false;
    if (!this.grantRight('41')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
  }
  assignLoanOfficer_() {
    this.showActionsModal = false;
    if (!this.grantRight('45')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onChangeLoanOfficer.emit({ loan: this.loan });
  }
  deleteRequest_() {
    this.showActionsModal = false;
    if (!this.grantRight('46')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'delete_request_mod', data: this.loan });
  }
  acceptContract_() {
    this.showActionsModal = false;
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'accept_contract_mod', data: this.loan });
  }
  loadMoreRecordsAll(start) {
    this.filteringLoans = true;
    this.magic_filter.reset = true;
    this.magic_filter.start = start;
    // this.getLoans()
    this.searchView = false;
    this.magic_filter.token = this.currentUser.token;
    this.adding_more = true;
    this.loansService
      .getAllLenderPortfolio(
        this.ages, this.currentUser.token, this.magic_filter, this.sectors, this.approval_levels, this.statuses, this.durations, this.request_date, this.adays, this.marital_statuses, this.percentrepaid, this.breaking
      )
      // this.loansService.getLoans(this.magic_filter)
      .subscribe(customers => {
        this.adding_more = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
      });
  }
  displayCondition(i) {
    // if (i <= 2) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  }
  openDrawer() {
    this.drawer = true;
  }

  closeDrawer() {
    this.drawer = false;
    this.activeRequestId = null;
    this.loan = null;
  }

  handleViewModeChange({ target: { checked } }) {
    if (!checked) this.sideview = 'master';
    else if (checked && this.loan) this.sideview = 'child';
    localStorage.setItem('portfolioLoanViewMode', checked)
  }
  sendTheIndebted() {
    this.loading = true;
    this.loansService.sendIndebtednessLetter(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  makePayment(event) {
    this.DataService.onStartNewRepaymentFlow.emit({ parentRouteId: this.loan.REQUEST_ID });
  }
  
}
