<div *ngIf="view=='master'">
  <!-- <app-requestheader [loan]="loan" [where]='where'></app-requestheader> -->
  <h3 class="title flex flex-column text-center flex-center">
    Run Analytics
    <small class="mt-2">Choose an analytics category below</small>
  </h3>
  <div class="legend_div">
    <span class="legend_span"><i class="fa fa-times"></i></span>
    <span>No data for analytics</span>
  </div>
  <div class="padding-regular">
    <div class="grid four mt-4">
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeProfileValue(); icon = 'fas fa-user';">
          <div class="icon color-yellow round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.profile" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-user"></i>
          </div>
          <div class="text w-60">Profile Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeBankStatementValue(); icon = 'fas fa-building'">
          <div class="icon color-blue round-icon"><i class="fas fa-building"></i></div>
          <div class="text w-60">Bank Statement Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeRepaymentValue(); icon = 'fas fa-money'">
          <div class="icon color-red round-icon" style="position:relative;">
            <span *ngIf="!analytics_data_status.repayments" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-money"></i></div>
          <div class="text w-60">Repayment Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeSmsValue(); icon = 'fas fa-sms'">
          <div class="icon color-yellow round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.sms" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-sms"></i>
          </div>
          <div class="text w-60">SMS Logs Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeCallLogsValue(); icon = 'fas fa-phone'">
          <div class="icon color-blue round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.call_logs" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-phone"></i>
          </div>
          <div class="text w-60">Call Logs Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeSocialValue(); icon = 'fa fa-facebook'">
          <div class="icon color-blue round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.social" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fa fa-facebook"></i>
          </div>
          <div class="text w-60">Social Media Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer">
          <div class="icon color-orange round-icon"><i class="fas fa-plane-departure"></i></div>
          <div class="text w-60">Salary inflight Analytics</div>
        </div>
      </div>
    </div>
    <div class="grid four mb-4">
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeCardValue(); icon = 'fas fa-money-check'">
          <div class="icon color-purple round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.cards" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-money-check"></i>
          </div>
          <div class="text w-60">Cards Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeBVNValue(); icon = 'fas fa-fingerprint'">
          <div class="icon color-green round-icon" style="position: relative;">
            <span *ngIf="!analytics_data_status.bvn" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-fingerprint"></i>
          </div>
          <div class="text w-60">BVN Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeAccountValue(); icon = 'fas fa-user-alt'">
          <div class="icon color-yellow round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.account" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-user-alt"></i>
          </div>
          <div class="text w-60">Account Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer" (click)="changeGuarantorValue(); icon = 'fas fa-user'">
          <div class="icon color-blue round-icon" style="position: relative">
            <span *ngIf="!analytics_data_status.guarantor" class="no_data"><i class="fa fa-times"></i></span>
            <i class="fas fa-user"></i>
          </div>
          <div class="text w-60">Guarantor Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer">
          <div class="icon color-orange round-icon"><i class="fas fa-id-card"></i></div>
          <div class="text w-60">National Identity Analytics</div>
        </div>
      </div>
      <div class="flex-1 flex-center mb-3">
        <div class="icon-text medium cursor-pointer">
          <div class="icon color-red round-icon"><i class="fas fa-user"></i></div>
          <div class="text w-60">FRSC Analytics</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="flex-center mt-3 mb-4">
    <button class="btn btn-outline-secondary" type="button" data-toggle="collapse" data-target="#collapseExample"
      aria-expanded="false" aria-controls="collapseExample"><i class="fa fa-chevron-down"></i></button>
  </div> -->
</div>


<div *ngIf="view=='nodata'">
  <div class="flex padding-regular">
    <div class="flex-1 flex align-center">
      <div class="h-group mt-5">
        <h3 class="title no-float no-margin" style="font-size: 1.4rem">You don't have the data for this analysis</h3>
        <app-button class="small mt-2" label="Back"></app-button>
      </div>
    </div>
    <div class="flex-1 flex-center">
      <img src="assets/images/app.png" alt="" style="width: 150px;">
    </div>
  </div>
</div>


<div *ngIf="view=='child'" style="margin-top: -60px">
  <div class="flex-center flex-column fill-w fill-h">
    <div class="h-group padded center mt-4 flex-center flex-column">
      <div class="circle large grey filled flex-center mb-3"><i [class]="icon"></i></div>
      <h3 class="title no-float">{{type}} Analytics</h3>
      <p class="subtitle">Review related {{type}} information for credibility check</p>

      <div class="text-center mt-4">
        <app-button class="small outline mr-1" (click)="view = 'master'" label="Back"></app-button>
        <app-button class="small mr-1" (click)="runAnalytics()" [loading]="loading" label="Run analysis"></app-button>
        <app-button *ngIf="finished" class="small green" (click)="viewTheSelectedAnalytics()" label="View Result">
        </app-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="view=='invite_for_data'" style="margin-top: -60px">
  <div class="flex-center flex-column fill-w fill-h">
    <div class="h-group padded center mt-4 flex-center flex-column">
      <div class="flex align-center mt-5 mb-5">
        <div class="section-poster flex-1">
          <div class="image flex-center">
            <img class="w-60" src="http://700lenders.com/images/crediblesmockup.png"
              style="position: absolute; bottom: 0; width: 185px!important; left: -5px;opacity: 1;" />
          </div>
        </div>
        <div class="h-group flex-1">
          <h3 class="title no-margin">Invite for analytics</h3>
          <p class="subtitle fill-w mb-2">
            Everything you want in an analytics of your platform is right
            available for you anyway you want it to play
          </p>
          <button type="button" [disabled]="!loan" (click)="openModal('invite_for_analytics')"
            class="btn btn-outline-secondary mt-5">
            Send Invite
          </button>
        </div>
      </div>
    </div>
  </div>
</div>