import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OperationsService, StorageService, OptionsserviceService, LoansService, AuthenticationService, DataService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-collateral',
  templateUrl: './collateral.component.html',
  styleUrls: ['./collateral.component.css']
})
export class CollateralComponent implements OnInit {
  @Input() requestId;
  @Input() from_where = 'self';
  @Input() embeded = false;
  addModalVisible = false;
  token: any;
  sub: any;
  parentRouteId: any;
  loading: boolean;
  collaterals: any;
  has_data = false;
  currentUser: any;
  employees: any;
  collateral_ = {
    'request_id': '',
    'collateral_type': '',
    'is_verified': '0',
    'verified_by': '',
    'vehicle_estimated_worth_below': '',
    'depreciation_factor': '',
    'est_liquidation_value': '',
    'comment': '',
    'vehicle_estimated_worth_above': '',
    'vehicle_estimated_worth_average': '',
    'vin': '',
    'mileage': '',
    'make': '',
    'model': '',
    'year': '',
    'trim': '',
    'reg_number': '',
    'state': '',
    'lic_expiry': '',
    'insurance_exp': '',
    'reg_owner': '',
    'owner_type': '',
    'company_name': '',
    'company_cac': '',
    'document_held': '',
    'house_owner_type': '',
    'house_owner_name': '',
    'house_owner_address': '',
    'house_owner_state': '',
    'house_owner_price': '',
    'no_of_plots': '',
    'house_pic': '',
    'house_depreciation_factor': '',
    'project_name': '',
    'project_owner': '',
    'project_amount': '',
    'company_of_project': '',
    'about_project': '',
    'cost_of_implementation': '',
    'net_amount': '',
    'lien_on_account': '',
    'maturity_date': '',
    'payment_date': '',
    'project_pic': '',
    'project_depreciation_factor': '',
    'stock_owner': '',
    'companies_in_portfolio': '',
    'portfolio_amount': '',
    'lien_on_sale': '',
    'insurance': '',
    'stock_pic': '',
    'stock_depreciation_factor': '',
    'jewellry_owner': '',
    'jewellry_amount': '',
    'jewellry_lien_on_sale': '',
    'jewellry_pic': '',
    'jewellry_depreciation_factor': '',
    'other_name': '',
    'other_estimated_amount': '',
    'other_lien_on_sale': '',
    'other_pic': '',
    'other_depreciation_factor': '',
    'land_document_held': '',
    'land_owner_type': '',
    'land_owner_name': '',
    'land_owner_address': '',
    'land_owner_state': '',
    'land_owner_price': '',
    'land_pic': '',
    'land_depreciation_factor': '',
    'LOAN_COLLATERAL_DOCS_ID': ''
  };
  collateral = {
    'request_id': '',
    'collateral_type': '',
    'is_verified': '0',
    'verified_by': '',
    'vehicle_estimated_worth_below': '',
    'depreciation_factor': '',
    'est_liquidation_value': '',
    'comment': '',
    'vehicle_estimated_worth_above': '',
    'vehicle_estimated_worth_average': '',
    'vin': '',
    'mileage': '',
    'make': '',
    'model': '',
    'year': '',
    'trim': '',
    'reg_number': '',
    'state': '',
    'lic_expiry': '',
    'insurance_exp': '',
    'insurance': '',
    'reg_owner': '',
    'owner_type': '',
    'company_name': '',
    'company_cac': '',
    'document_held': '',
    'house_owner_type': '',
    'house_owner_name': '',
    'house_owner_address': '',
    'house_owner_state': '',
    'house_owner_price': '',
    'no_of_plots': '',
    'house_pic': '',
    'house_depreciation_factor': '',
    'project_name': '',
    'project_owner': '',
    'project_amount': '',
    'company_of_project': '',
    'about_project': '',
    'cost_of_implementation': '',
    'net_amount': '',
    'lien_on_account': '',
    'maturity_date': '',
    'payment_date': '',
    'project_pic': '',
    'project_depreciation_factor': '',
    'stock_owner': '',
    'companies_in_portfolio': '',
    'portfolio_amount': '',
    'lien_on_sale': '',
    'stock_pic': '',
    'stock_depreciation_factor': '',
    'jewellry_owner': '',
    'jewellry_amount': '',
    'jewellry_lien_on_sale': '',
    'jewellry_pic': '',
    'jewellry_depreciation_factor': '',
    'other_name': '',
    'other_estimated_amount': '',
    'other_lien_on_sale': '',
    'other_pic': '',
    'other_depreciation_factor': '',
    'land_document_held': '',
    'land_owner_type': '',
    'land_owner_name': '',
    'land_owner_address': '',
    'land_owner_state': '',
    'land_owner_price': '',
    'land_pic': '',
    'land_depreciation_factor': '',
    'LOAN_COLLATERAL_DOCS_ID': ''
  };
  states: any;
  loan: any;
  is_update = true;
  request_id: any;
  view = 'select';
  years = '2019';
  makes: any;
  models: any;
  constructor(private operationsService: OperationsService, public route: ActivatedRoute,
    public storageService: StorageService, private loansService: LoansService, private dataService: DataService,
    public router: Router) {
    this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
    });

    this.dataService.carVinLookupCompleted.subscribe(res => {
      this.getSummary();
      this.getStates();
      this.getMakes();
    });
  }

  ngOnInit() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.getSummary();
    this.getStates();
    this.getMakes();
  }

  getStates() {
    fetch('/assets/states.json')
      .then(res => res.json())
      .then(states => {
        this.states = states;
      })
      .catch(err => console.log('Error Fetching States!', err));
  }

  getMakes() {
    fetch('/assets/carlist.json')
      .then(res => res.json())
      .then(makes => {
        this.makes = makes;
      })
      .catch(err => console.log('Error Fetching car makes!', err));
  }

  getSummary() {
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
      this.collateral.request_id = this.parentRouteId;
      this.collateral_.request_id = this.parentRouteId;
      this.getLoan();
    });
  }

  getLoan() {
    this.loading = true;
    this.loansService.getLoanForTimeline(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loading = false;
        this.loan = data.loan;
        //    this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });
        //     this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });

        this.getLoanCollaterals();
      });
  }

  getLoanCollaterals() {
    this.loading = true;
    this.operationsService.getCollaterals(this.currentUser.token, this.parentRouteId).subscribe((res) => {
      this.loading = false;
      if (res.status == true) {
        this.collaterals = res.data;
        this.has_data = res.has_data;
      }
      this.employees = res.employees;
    });
  }

  showAddModal = () => {
    this.addModalVisible = true;
    this.collateral = this.collateral_;
  }

  closeAddModal = () => {
    this.is_update = false;
    this.addModalVisible = false;
  }

  handleSubmit(form) {
    // let err = false;
    // Object.keys(form.value).forEach(key => {
    //   if (!form.value[key] && key !== 'LOAN_COLLATERAL_DOCS_ID') err = true;
    // })
    // if (err) return alert('Unable to validate fields, please fill all fields appropriately');
    // this.loading = true;
    this.operationsService.saveCollateral(this.currentUser.token, this.parentRouteId, {
      collateral_type: this.collateral.collateral_type, ...form.value
    }).subscribe((res) => {
      this.loading = false;
      this.addModalVisible = false;
      this.getLoanCollaterals();
    });
  }

  updateCollateral(collateral) {
    this.collateral = {
      'request_id': collateral.request_id,
      'collateral_type': collateral.collateral_type,
      'is_verified': collateral.is_verified,
      'verified_by': collateral.verified_by,
      'vehicle_estimated_worth_below': collateral.request_data.vehicle_estimated_worth_below,
      'depreciation_factor': collateral.depreciation_factor,
      'est_liquidation_value': collateral.est_liquidation_value,
      'comment': collateral.request_data.comment || '',
      'vehicle_estimated_worth_above': collateral.request_data.vehicle_estimated_worth_above || '',
      'vehicle_estimated_worth_average': collateral.request_data.vehicle_estimated_worth_average || '',
      'vin': collateral.request_data.vin || '',
      'mileage': collateral.request_data.mileage || '',
      'make': collateral.request_data.make || collateral.request_data.vehicle_make,
      'model': collateral.request_data.model || collateral.request_data.vehicle_model,
      'year': collateral.request_data.year || collateral.request_data.vehicle_year,
      'trim': collateral.request_data.trim || collateral.request_data.vehicle_trim,
      'reg_number': collateral.request_data.reg_number || collateral.request_data.vehicle_registration_number,
      'state': collateral.request_data.state || collateral.request_data.vehicle_registration_state_id,
      'lic_expiry': collateral.request_data.lic_expiry || collateral.request_data.vehicle_license_expiry_date,
      'insurance_exp': collateral.request_data.insurance_exp || collateral.request_data.vehicle_license_expiry_date,
      'insurance': collateral.request_data.insurance || collateral.request_data.insurance,
      'reg_owner': collateral.request_data.reg_owner || '',
      'owner_type': collateral.request_data.owner_type || '',
      'company_name': collateral.request_data.company_name || '',
      'company_cac': collateral.request_data.company_cac || '',
      'document_held': collateral.request_data.document_held || '',
      'house_owner_type': collateral.request_data.house_owner_type || '',
      'house_owner_name': collateral.request_data.house_owner_name || '',
      'house_owner_address': collateral.request_data.house_owner_address || '',
      'house_owner_state': collateral.request_data.house_owner_state || '',
      'house_owner_price': collateral.request_data.house_owner_price || '',
      'no_of_plots': collateral.request_data.no_of_plots || '',
      'house_pic': collateral.request_data.house_pic || '',
      'house_depreciation_factor': collateral.request_data.house_depreciation_factor || '',
      'project_name': collateral.request_data.project_name || '',
      'project_owner': collateral.request_data.project_owner || '',
      'project_amount': collateral.request_data.project_amount || '',
      'company_of_project': collateral.request_data.company_of_project || '',
      'about_project': collateral.request_data.about_project || '',
      'cost_of_implementation': collateral.request_data.cost_of_implementation || '',
      'net_amount': collateral.request_data.net_amount || '',
      'lien_on_account': collateral.request_data.lien_on_account || '',
      'maturity_date': collateral.request_data.maturity_date || '',
      'payment_date': collateral.request_data.payment_date || '',
      'project_pic': collateral.request_data.project_pic || '',
      'project_depreciation_factor': collateral.request_data.project_depreciation_factor || '',
      'stock_owner': collateral.request_data.stock_owner || '',
      'companies_in_portfolio': collateral.request_data.companies_in_portfolio || '',
      'portfolio_amount': collateral.request_data.portfolio_amount || '',
      'lien_on_sale': collateral.request_data.lien_on_sale || '',
      'stock_pic': collateral.request_data.stock_pic || '',
      'stock_depreciation_factor': collateral.request_data.stock_depreciation_factor || '',
      'jewellry_owner': collateral.request_data.jewellry_owner || '',
      'jewellry_amount': collateral.request_data.jewellry_amount || '',
      'jewellry_lien_on_sale': collateral.request_data.jewellry_lien_on_sale || '',
      'jewellry_pic': collateral.request_data.jewellry_pic || '',
      'jewellry_depreciation_factor': collateral.request_data.jewellry_depreciation_factor || '',
      'other_name': collateral.request_data.other_name || '',
      'other_estimated_amount': collateral.request_data.other_estimated_amount || '',
      'other_lien_on_sale': collateral.request_data.other_lien_on_sale || '',
      'other_pic': collateral.request_data.other_pic || '',
      'other_depreciation_factor': collateral.request_data.other_depreciation_factor || '',
      'land_document_held': collateral.request_data.land_document_held || '',
      'land_owner_type': collateral.request_data.land_owner_type || '',
      'land_owner_name': collateral.request_data.land_owner_name || '',
      'land_owner_address': collateral.request_data.land_owner_address || '',
      'land_owner_state': collateral.request_data.land_owner_state || '',
      'land_owner_price': collateral.request_data.land_owner_price || '',
      'land_pic': collateral.request_data.land_pic || '',
      'land_depreciation_factor': collateral.request_data.land_depreciation_factor || '',
      'LOAN_COLLATERAL_DOCS_ID': collateral.LOAN_COLLATERAL_DOCS_ID
    };
    this.addModalVisible = true;
    this.is_update = true;
    this.view = 'form';
    this.models = collateral.request_data.vehicle_make == '' ? [] : this.makes.find(make => make.brand === collateral.request_data.vehicle_make).models;
  }

  select(n) {
    this.collateral.collateral_type = n;
    this.view = 'form';
  }

  getCarModels(e) {
    console.log(this.makes, e.target.value);
    this.models = this.makes.find(make => make.brand === e.target.value).models;
  }
  checkCarValuation(collateral) {
    this.dataService.sendForCarValuation.emit({ collateral_id: collateral, request_id: this.parentRouteId });
  }
}
