import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-setupwidget',
  templateUrl: './setupwidget.component.html',
  styleUrls: ['./setupwidget.component.css']
})
export class SetupwidgetComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  displayNext() {
    this.router.navigate(['/sources']);
  }
}