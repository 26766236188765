<form class="add__member-form">
  <h3 style="padding-left: 108px;" *ngIf="view == ''">Enter Business Details</h3>
  <div class="form__flex" *ngIf="view == ''">
    <div class="form-group">
      <input name="" placeholder="Pick an alias" [(ngModel)]="alias" class="form-control form__input"/>
    </div>
    <div class="form-group">
      <input type="text" class="form-control form__input" [value]="primary_phone_number"
             [(ngModel)]="primary_phone_number">
    </div>
    <div class="form-group">
      <input type="email" class="form-control form__input" [value]="primary_email"
             [(ngModel)]="primary_email">
    </div>
    <button class="btn btn-default padded orange-btn wide-btn" style="width: 61%" (click)="next()">Next</button>
  </div>
  <div *ngIf="view == 'add_member'" style="padding-left: 108px;"><span><img src="assets/images/user.png"
                                                                            class="member__avatar" alt=""></span><span
    style="font-size: 18px">Add a Team member</span>
  </div>
  <hr>
  <div class="form__flex" *ngIf="view == 'add_member'">
    <div class="form-group">
      <input type="name" class="form-control form__input" placeholder="Name" [(ngModel)]="name">
    </div>
    <div class="form-group">
      <input type="email" class="form-control form__input" placeholder="Email Address" [(ngModel)]="email">
    </div>
    <div class="form-group">
      <select name="" id="" class="form-control form__input" [(ngModel)]="role">
        <option disabled>Approval Level</option>
        <option value="1">A</option>
        <option value="2">B</option>
        <option value="3">C</option>
      </select>
    </div>
    <button class="btn btn-default padded orange-btn wide-btn" style="width: 61%" (click)="sendInvite()">Send Invite
    </button>
    <div><a href="javacript:">Skip, I am alone</a></div>
  </div>
  <div class="form__flex" *ngIf="view == 'add_more'">
    <h3>Add a team member</h3>
    <div class="circle__flex">
      <div class="circle__member">
        <span class="circle_number">2</span>
        <span class="circle_text">Added</span>
      </div>
      <div class="circle__member">
        <a href="">
          <img src="assets/images/plus.png" alt="" style="height: 50px;">
        </a>
      </div>
    </div>
    <button class="btn btn-default padded orange-btn wide-btn" style="width: 61%" (click)="submit()">Submit</button>
  </div>
</form>
