<div class="panel padding-regular">
  <div class="h-group padded center flex-center flex-column">
    <div class="circle large red filled flex-center mb-5">{{eligibility?.output?.total_eligibilty_score|number:'.2-2'}}%</div>
    <h3 class="title">Ineligible Requests</h3>
    <p class="subtitle mt-1">This request has been considered ineligible for sone few reasons</p>
    <div class="text-center mt-4">
      <app-button (click)="confirmReopenRequest()" class="small red" label="Reopen"></app-button>
    </div>
  </div>
</div>
<div>
  <div class="panel mb-1">
    <div class="panel-body padding-regular">
      <div class="flex">
        <div class="section-poster">
          <div class="image flex-center">
            <img src="assets/images/eligibility.svg">
          </div>
        </div>
        <div class="flex-3 mb-2 ml-4">
          <ul class="decList bigger">
            <li><span class="title">Analysis</span></li>
            <li><span class="title">Bank Account</span></li>
            <li *ngFor="let analyse of eligibility?.description?.account?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.account?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Call Logs</span></li>
            <li *ngFor="let analyse of eligibility?.description?.calllogs?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.calllogs?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Repayment Card</span></li>
            <li *ngFor="let analyse of eligibility?.description?.card?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.card?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Direct Debit</span></li>
            <li *ngFor="let analyse of eligibility?.description?.direct_debit?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.direct_debit?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Guarantor</span></li>
            <li *ngFor="let analyse of eligibility?.description?.guarantor?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.guarantor?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Profile</span></li>
            <li *ngFor="let analyse of eligibility?.description?.profile?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.profile?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
            <li><span class="title">Repayments</span></li>
            <li *ngFor="let analyse of eligibility?.description?.repayment?.passed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-check color-green"></i>
              </span>
            </li>
            <li *ngFor="let analyse of eligibility?.description?.repayment?.failed">
              <span class="text-primary">{{analyse}}</span>
              <span>
                <i class="fa fa-times color-red"></i>
              </span>
            </li>
          </ul>
          <!-- <div class="mt-3">
            <app-button class="small outline" label="Reopen"></app-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="panel mb-1">
    <div class="panel-body padding-regular">
      <div class="flex">
        <div class="flex-3 mb-2 mr-4" *ngIf="recommended?.status==true">
          <ul class="decList bigger">
            <li><span class="title">Recomment Offer</span></li>
            <li><span class="text-primary">Principal</span><span>N{{recommended?.band?.MAXIMUM_AMOUNT}}</span></li>
            <li><span class="text-primary">Duration</span><span>{{recommended?.band?.LOAN_DURATION}} Months</span>
            </li>
            <li><span class="text-primary">Interest</span><span>{{recommended?.band?.MAXIMUM_RATE}}%</span></li>
          </ul>
        </div>
        <div class="flex-3 mb-2 mr-4" *ngIf="recommended?.status==false">
          <p>No offer</p>
          <div class="mt-3" *ngIf="loan?.loan_status > 0">
            <app-button (click)="reopenRequest(loan?.request_id)" class="small green" label="Reopen Request">
            </app-button>
          </div>
        </div>
        <div class="section-poster">
          <div class="image flex-center">
            <img src="assets/images/offer.svg">
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="panel box edged_box mb-1">
    <div class="alert padding-regular bg-off-white">
      <div class="texts no-padding">
        <h4 class="title">View profile of {{loan?.customer?.legal_name}}</h4>
        <p class="subtitle">&nbsp;</p>
      </div>
      <div class="action">
        <app-button (click)="confirmReopenRequest()" label="View" buttonclass="small outline"></app-button>
      </div>
    </div>
  </div>

  <div class="panel box edged_box mb-1">
    <div class="alert padding-regular bg-off-white">
      <div class="texts no-padding">
        <h4 class="title">View eligibility settings</h4>
        <p class="subtitle">&nbsp;</p>
      </div>
      <div class="action">
        <app-button (click)="confirmReopenRequest()" label="View" buttonclass="small outline"></app-button>
      </div>
    </div>
  </div>
</div>
