import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CustomerService, DataService, OperationsService, StorageService, MarketService, AuthenticationService, LoansService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-updatingapprovallevel',
  templateUrl: './updatingapprovallevel.component.html',
  styleUrls: ['./updatingapprovallevel.component.css']
})
export class UpdatingapprovallevelComponent implements OnInit {
  @Input('APPROVAL_LEVEL_ID_') APPROVAL_LEVEL_ID_:any;
  loading = false;
  currentUser:any;
  approval_level:any;
  employees:any;
  formLevel:any;
  constructor(
    public customerServ: CustomerService, private toastr: ToastrService, private _dataService: DataService,
    public router: Router, public operationsService: OperationsService,
    public storageService: StorageService,
    protected customerService: CustomerService, public marketService: MarketService,
    private _eref: ElementRef,
    public _notificationService: NotificationService, private authService: AuthenticationService, private loansService: LoansService
  ){
    this.currentUser = this.storageService.read<any>('currentUser');
   }

  ngOnInit() {
    this.getApprovalLevel(this.APPROVAL_LEVEL_ID_)
  }
  getApprovalLevel(id){
    this.loading = true;
    this.operationsService.getApprovalLevel(this.currentUser.token, this.APPROVAL_LEVEL_ID_)
      .subscribe(data => {
        this.loading = false;
        this.formLevel = data.level;
        this.employees = data.employees;
        
      });
  }
  onSubmit(){
    this.loading = true;
    this.operationsService.updateApprovalLevel(this.currentUser.token, this.formLevel, this.employees)
      .subscribe(data => {
        this.loading = false;
        //this.formLevel = data.level;
        //this.employees = data.employees;
        
      });
  }
}
