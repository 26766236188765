<div class="section-title mb-4 cursor-pointer" (click)="cancel.emit()" *ngIf="!loading">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>


<div>
  <div class="panel padding-regular flex">
    <div class="panel-body padding-regular">
      <h3 class="section-title mb-4">Transactions</h3>
      <div class="contract-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Bank</th>
              <th>Narration</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Balance</th>

              
            </tr>
          </thead>
         
          <tbody> 
            <tr *ngFor="let trans of statement?.smstransactions?.CustomerTransactions">
              <td>{{trans.DateOfTransaction}}</td>
              <td>{{trans.BankName}}</td>
              <td>{{trans.TransactionNarration}}</td>
              <td>{{trans.CreditAmount}}</td>
              <td>{{trans.DebitAmount}}</td>
              <td>{{trans.BalanceAmount}}</td>
            </tr>
           
        </table>
      </div>  
    </div>
  </div>
</div>