import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService, StorageService } from '../../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../../_services/index';

@Component({
  selector: 'app-analyticshome',
  templateUrl: './analyticshome.component.html',
  styleUrls: ['./analyticshome.component.css']
})
export class AnalyticshomeComponent implements OnInit {
  @Input() showHeader = true;
  @Input() embedModal = false;
  @Input() requestId;
  @Output() openRunAnalytics = new EventEmitter();
  @Output() openMore = new EventEmitter();

  endDate = '1561935599000';
  text = 'Analytics';
  parentRouteId: any;
  currentUser: any;
  sub: any;
  loan: any;
  creating_contract = false;
  customer: any;
  count: any;
  showPicture = false;
  loading = false;
  account_status: boolean;
  card_status: boolean;
  dd_status: boolean;
  call_logs_status: boolean;
  sms_logs_status: boolean;
  repayment_analytics_status: boolean;
  others_status: boolean;
  social_status: boolean;
  profile_status: any;
  sms_analytics: any;
  analytics_done = false;
  analytics_already_done: any;
  analytics_data_status: any;
  analytics_summary: any;
  constructor(public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.emitAnalytics.subscribe(res => {

      // this.sms_logs_status = res.status.sms;
      // this.call_logs_status = res.status.calllogs;
      // this.social_status = res.status.social;
      // // this.card_status = res.status.card;
      // this.profile_status = res.status.profile;
      // this.repayment_analytics_status = res.status.repayments;
      // this.others_status = res.status.guarantor || res.status.card || res.status.account;
      // this.sms_analytics = res.sms_analytics;
    });
  }
  ngOnInit() {
    this.getSummary();
  }
  getSummary() {
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
      this.getLoan();
    });
  }
  getCusomer(people_id) {
    this.loansService.getLoanContractCustomer(this.currentUser.token, people_id)
      .subscribe(data => {
        this.customer = data.data;
      });
  }
  getLoan() {
    this.loading = true;
    this.loansService.getLoanContractForAnalysis(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {

        this.loading = false;

        this.analytics_data_status = data.analytics_data_status;
        this.loan = data.loan;
        this.analytics_summary = data.loan.analytics_summary;

        this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });
        this.getCusomer(data.loan.people_id);
        this.analytics_already_done = data.loan.analytics;
        if (data.loan.analytics_summary.analytics_done) {
          this.count = '1';
          this.analytics_done = data.loan.analytics_summary.analytics_done;
          // this.sms_logs_status = data.loan.analytics_summary.customer_analysis.status.sms;
          // this.call_logs_status = data.loan.analytics_summary.customer_analysis.status.calllogs;
          // this.social_status = data.loan.analytics_summary.customer_analysis.status.social;
          // // this.card_status = res.status.card;
          // this.profile_status = data.loan.analytics_summary.customer_analysis.status.profile;
          // this.repayment_analytics_status = data.loan.analytics_summary.customer_analysis.status.repayments;
          // this.others_status = data.loan.analytics_summary.customer_analysis.status.guarantor || data.loan.analytics_summary.customer_analysis.status.card || data.loan.analytics_summary.customer_analysis.status.account;
          // //this.sms_analytics = res.sms_analytics;
        }
      });
  }
  openModal(section) {
    if (this.embedModal) return this.openRunAnalytics.emit({ key: 'analytics_data_status', value: this.analytics_data_status });
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan, analytics_data_status: this.analytics_data_status });
  }
  openLoanSpider(request_id) {
    this.router.navigate(['applications/analytics/' + request_id + '/spider']);
  }
  opeCreditCheck(request_id) {
    this.router.navigate(['./applications/creditcheck/' + request_id]);
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  openBSAnalysis(request_id) {
    this.router.navigate(['./applications/bankstatements/' + request_id]);
  }
  doingDisbursement(event) {

  }
  openSpecificAnalytics(title) {
    if (title == 'SMS') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/sms']);
    }
    if (title == 'Call Logs') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/calllogs']);
    }
    if (title == 'Profile') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/profile']);
    }
    if (title == 'Social') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/social']);
    }
    if (title == 'Repayment') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/repayments']);
    }
    if (title == 'Remita / Cards / Banks') {
      this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/accounts']);
    }
  }
  showProfilePic(pic) {
    this.dataService.showProfilePic.emit({ pic: pic });
  }
}
