import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService, } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OptionsserviceService } from '../../_services/optionsservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inviteguarantors',
  templateUrl: './inviteguarantors.component.html',
  styleUrls: ['./inviteguarantors.component.css']
})
export class InviteguarantorsComponent implements OnInit {
  endDate = '1561330800000';
  showWhatsapp = false;
  @Input('currentUser') currentUser: any;
  request = {
    'name': '',
    'email': '',
    'product_id':''
  }
  whatsapp = {
    'send_to_whatsapp': false,
    'whatsapp_number': '',
    'whatsapp_message': ''
  }
  addingInvite = true;
  coolSubmit = false;
  loading = false;
  success = false;
  invites = [];
  result:any;
  view='link';
  generated_link='';
  request_id:any;
  sub:any;
  constructor(public toastr: ToastrService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService,public router: Router,
    private route: ActivatedRoute,public storageService: StorageService, public optionsService: OptionsserviceService, public dataService: DataService) {

  }

  ngOnInit() {
    this.request.name = '';
    this.request.email = '';
    this.initialize();
  }
  initialize(){
    //this.loading = true;
    // this.operationsService.initializeInvites(this.currentUser.token)
    //   .subscribe(data => {
    //     this.loading = false;
    //     this.result = data.data;
    //   });
    this.sub = this.route.params.subscribe(params => {
      this.request_id = +params['id']; 
    });
  }
  generateLink(value, valid){
    this.loading = true;
    this.operationsService.generateInviteLink(this.currentUser.token, value)
      .subscribe(data => {
        this.loading = false;
        this.view = 'link';
        this.generated_link = data.data.data.link;
      });
  }
  saveInterestRateBand(value, valid) {
    this.invites.push(value);
    this.request.name = '';
    this.request.email = '';
    this.addingInvite = false;
    this.view = 'invite';
  }
  deleteInvite(invite) {
    console.log(this.invites, invite);
    this.invites = this.invites.filter(inv => inv.email !== invite.email);
  }
  openCoolSubmit() {
    this.coolSubmit = true;
  }
  sendInvite() {
    this.loading = true;
    this.loading = false;
    this.showSuccess('Invites are now being sent.');
    this.success = true;
    this.operationsService.sendLoanGuarantorInvites(this.currentUser.token, this.invites);
  }
  sendMultiple() {
    this.dataService.inviteMultipleCustomers.emit();
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  showingWhatsapp() {
    this.showWhatsapp = !this.showWhatsapp;
    this.whatsapp.send_to_whatsapp = !this.whatsapp.send_to_whatsapp
  }
}
