<div class="panel padding-regular flex">
  <div class="flex-3">
    <div class="section-title color-red mb-4">Past Loans</div>
    <ul class="decList bigger">
      <li><span>Total loans outstanding</span>
        <span *ngIf="!loading">{{ (data?.total_outstanding || 0) | currency:'NGN':false:'1.2-2' }}</span>
        <span *ngIf="loading">Lorem, ipsum.</span>
      </li>
      <li><span>Total loans disbursed</span>
        <span *ngIf="!loading">{{ (data?.booked || 0) | currency:'NGN':false:'1.2-2' }}</span>
        <span *ngIf="loading">Lorem, ipsum.</span>
      </li>
      <li><span>Total lateness</span>
        <span *ngIf="!loading">{{ data?.total_lateness }}</span>
        <span *ngIf="loading">Lorem, ipsum.</span>
      </li>
      <li><span>Total booked</span>
        <span *ngIf="!loading">{{ data?.booked_count }}</span>
        <span *ngIf="loading">Lorem, ipsum.</span>
      </li>
    </ul>
    <div class="text-right mt-4">
      <a [routerLink]="['/customer/individual/' + people_id + '/loans']" class="mt-3 btn btn-outline-primary btn-sm">
        View more
      </a>
    </div>
  </div>
  <div class="flex-1 flex flex-center">
    <div class="section-poster">
      <img src="/assets/images/clock.svg" alt="" style="width: 50px">
    </div>
  </div>
</div>