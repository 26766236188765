import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from '../email.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-header-content',
  templateUrl: './email-header-content.component.html',
  styleUrls: ['./email-header-content.component.css']
})
export class EmailHeaderContentComponent implements OnInit, OnDestroy {
  @Input() hideHeader: boolean;

  private getLogoUrl: Subscription;
  private addlogotData: Subscription;

  form: FormGroup;
  vendor_id: any;
  selectedFile: File;
  loading: boolean;
  logoUrl: string;
  token: any;

  constructor(private fb: FormBuilder,
    public toastr: ToastrService,
    private services: EmailService) {
    const vendorIdData = localStorage.getItem('platform');
    this.token = localStorage.getItem('token');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;
  }

  ngOnInit() {
    this.createForm();

    const resource = {
      'token': this.token,
      'vendor_id': this.vendor_id
    };

    this.getLogoUrl = this.services.getLogo(resource)
    .subscribe(uploadLogo => {
      this.logoUrl = uploadLogo.email_template_logo;
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // preparing the uploaded logo for upload

  private prepareSave(): any {
    let input = new FormData();
    input.append('avatar', this.form.get('avatar').value);
    return input;
  }

  onFileChange(event) {
    this.selectedFile = event.target.files[0];
    let file = event.target.files[0];
    this.form.get('avatar').setValue({
      filename: file.name,
      filetype: file.type
    })
  }

  ////////////////////////////////////////////////////////////////////
  // upload logo to endpoint

  createForm() {
    this.form = this.fb.group({
      avatar: [null , Validators.required]
    });
  }


  onSubmit() {
    this.loading = true;

    const uploadData = new FormData();
    uploadData.append('logo', this.selectedFile, this.selectedFile.name);
    uploadData.append('vendor_id', this.vendor_id);
    uploadData.append('token', this.token);

    this.addlogotData = this.services.uploadLogo(uploadData)
    .subscribe(uploadLogo => {
      if (uploadLogo.status == true) {
        this.toastr.success('Success', 'Success!');
        this.loading = false;
        this.logoUrl = uploadLogo.logo_link;
        this.clearFile();
      } else {
        this.toastr.success('Failed', 'Unable to upload try again !!!');
        this.loading = false;
      }
    });
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  clearFile() {
    this.fileInput.nativeElement.value = '';
  }

  ngOnDestroy() {
    this.getLogoUrl.unsubscribe();
    this.addlogotData.unsubscribe();
  }

}
