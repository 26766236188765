<div class="row">
  <div class="col-md-9">
    <app-homenavigation></app-homenavigation>

    <section class="panel padding-regular">
      <div *ngIf="!tasks.length && !loading">
        <div class="h-group center mb-4 padded empty-state mt-5">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title no-float">No tasks</h2>
          <p class="subtitle">No tasks found</p>
        </div>
      </div>
      <!-- <section infinite-scroll (scrolled)="onScrollDown()" class="item-area no-margin" slimScroll width="auto"
          [infiniteScrollDistance]="0"> -->

      <div class="timeline mb-4 border-bottom" *ngFor="let task of tasks; let idx = index">
        <article class="timeline-item" [ngClass]="{ alt: idx % 2 > 0 }">
          <div class="timeline-desk grid two">
            <div class="panel"
              [ngClass]="{'border-red': task.task_status == 1, 'border-orange': task.task_status == 2, 'border-blue': task.task_status == 3}">
              <div class="panel-body">
                <div class="flex justify-between align-center mb-2 border-bottom">
                  <span
                    [ngClass]="{'color-red': task.task_status == 1, 'color-orange': task.task_status == 2, 'color-blue': task.task_status == 3}">Task</span>
                  <!-- <span class="timeline-date">20/20/20</span> -->
                </div>
                <h1 class="medium-text-size mb-2">{{task.task}}({{task.customer}})</h1>
                <p><small><b>Created by: </b> {{task.tasked_creator}}</small></p>
                <p><small><b>End Date: </b> {{task.task_end_date | date:'fullDate'}}</small></p>
                <div class="right flex justify-between align-center mt-3">
                  <div class="flex align-center">
                    <button *ngIf="task.task_done == 0" (click)="addComment(task.task_id)"
                      class="btn btn-xs btn-outline-success">Mark as completed</button>
                    <span class="no-margin" *ngIf="task.task_done == 1">
                      <i style="color: green" _ngcontent-c2="" class="fa fa-check mr-2"></i>
                    </span>
                  </div>
                  <div class="flex align-center">
                    <div class="tag small red mr-1" *ngIf="task.task_status == 1">Status: High</div>
                    <div class="tag small blue mr-1" *ngIf="task.task_status == 2">Status: Medium</div>
                    <div class="tag small blue mr-1" *ngIf="task.task_status == 3">Status: Low</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="panel pd_15" *ngIf="task.task_comment">
                <span class="timeline-icon red"></span>
                <div class="flex justify-between align-center mb-2 border-bottom">
                  <span>Comments</span>
                </div>
                <h1 class="medium-text-size">{{task.task_comment }}</h1>
                <p *ngIf="task.task_done == 1" class="no-margin"><small><b>Date completed </b>
                    {{task.task_done_date | date:'fullDate'}}</small></p>
              </div>
              <div class="panel pd_15" *ngIf="!task.task_comment">
                <span class="timeline-icon red"></span>
                <div class="flex justify-between align-center mb-2">
                  <span>No Comment</span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>

      <!-- <section>
        <div *ngFor="let task of tasks" class="customer align-center">
          <div class="align-center">
            <div class="name mb-2">{{task.task}}</div>
            <div *ngIf="task.task_comment" class="mt-4 mb-4">
              <small><b>Comment</b></small>
              <div class="type comment">{{task.task_comment }}</div>
            </div>
            <div class="flex justify-between align-center fill-w">
              <div class="right flex flex-column">
                <p class="no-margin"><small><b>Created by: </b> {{task.tasked_creator}}</small></p>
                <p class="no-margin"><small><b>End Date: </b> {{task.task_end_date | date:'fullDate'}}</small></p>
                <p *ngIf="task.task_done == 1" class="no-margin"><small><b>Date completed </b>
                    {{task.task_done_date | date:'fullDate'}}</small></p>
              </div>

              <div class="right flex justify-end align-center">
                <div class="tag medium red mr-1" *ngIf="task.task_status == 1">Status: High</div>
                <div class="tag medium blue mr-1" *ngIf="task.task_status == 2">Status: Medium</div>
                <div class="tag medium blue mr-1" *ngIf="task.task_status == 3">Status: Low</div>
                <app-button *ngIf="task.task_done == 0" (click)="addComment(task.task_id)" class="small green outline"
                  label="Mark as completed">
                </app-button>
                <h3 class="no-margin" *ngIf="task.task_done == 1">
                  <i style="color: green" _ngcontent-c2="" class="fa fa-check mr-2"></i>
                </h3>
              </div>
            </div>
          </div>
          <div *ngIf="tasks?.length == 0" class="noTask">
            <h4>You have no task available</h4>
          </div>

          <div class="customer align-center" *ngIf="loading">
            <div class="header">
              <a class="img-wrap">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
              </a>
              <div class="content">
                <div class="name skeleton mb-1">Sample Amount</div>
                <div class="type skeleton">Sample Amount</div>
              </div>
            </div>
          </div>
          <div class="customer align-center" *ngIf="loading">
            <div class="header">
              <a class="img-wrap">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
              </a>
              <div class="content">
                <div class="name skeleton mb-1">Sample Amount</div>
                <div class="type skeleton">Sample Amount</div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </section>
  </div>
  <div class="col-md-3 pl_0">
    <div class="panel box mb-1">
      <div class="panel-body text-center">
        <div class="h-group">
          <h3 class="title large">{{taskCount}}</h3>
          <p class="subtitle mb-3">Tasks</p>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Model -->
<div class="overlay_ app_overlay animated" *ngIf="showTask">
  <div>
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="showTask=!showTask">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content padding-regular tall" role="document">
      <div class="form-group">
        <label for="comment">Comment</label>
        <textarea class="form-control" name="comment" [(ngModel)]="comment" id="comment" rows="3"></textarea>
      </div>
      <p>
        <button (click)="updateComment(task_id)" type="submit" class="btn btn-default mtop18">Submit <i *ngIf="loading"
            class="fa fa-spinner fa-spin"></i></button>
      </p>
    </div>

  </div>
</div>