import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  @Input() endDate: string;

  timeout: number;
  day: string;
  hour: string;
  minute: string;
  second: string;

  constructor() { }

  ngOnInit(): void {
    window.setInterval(() => {
      const TODAY = new Date().getTime();
      const one_day = 1000 * 60 * 60 * 24;
      const one_hour = 1000 * 60 * 60;
      const one_minute = 1000 * 60;
      const one_second = 1000;

      this.timeout = Number(this.endDate) - Number(TODAY);
      this.day = formatNumber(Math.floor(this.timeout / one_day), 'en', '1.0');
      this.hour = formatNumber(Math.floor(this.timeout / one_hour) % 24, 'en', '2.0');
      this.minute = formatNumber(Math.floor(this.timeout / one_minute) % 60, 'en', '2.0');
      this.second = formatNumber(Math.floor(this.timeout / one_second) % 60, 'en', '2.0');
    }, 1000);
  }
}
