<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{ pr_0 : embeded }">
    <app-contractheader [text]="text" [request_id]="parentRouteId" *ngIf="!requestId"></app-contractheader>

    <div *ngIf="view=='master'">
      <div class="panel loading" *ngIf="!loaded && loading">
        <div style="height: 100px;"></div>
      </div>
      <div *ngIf="!loan?.schedule_has_been_created">
        <div class="panel padding-regular" *ngIf="loaded && preview">
          <div class="flex">
            <div class="flex-1">
              <div class="section-poster width_1_2 text-center">
                <img src="assets/images/letter.svg" alt="Letter Icon">
              </div>
            </div>
            <div class="flex-1 flex flex-column justify-end">
              <ul class="decList bigger">
                <li class="text-right"><span class="title color-orange">Loan offer letter</span></li>
                <li><span>Run</span><span>[Date]</span></li>
                <li><span>By</span><span>[Name]</span></li>
                <li><span>URL</span><span>[URL]</span></li>
              </ul>
              <div class="flex justify-between align-center mt-4">
                <app-button class="small" label="Preview" (click)="preview = !preview"></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel padding-regular" *ngIf="!loan?.schedule_has_been_created && loaded">
        <section class="fill-h fill-v">
          <div class="h-group center mb-4 padded empty-state">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title">No Schedule</h2>
            <p class="subtitle">Schedule not yet created</p>
          </div>
        </section>
      </div>

      <div *ngIf="loan?.schedule_has_been_created">
        <app-offerlettertemplate (onLoad)="handleOfferLetter($event)" (editsec)="editsec($event)"
          [parentRouteId]="parentRouteId"></app-offerlettertemplate>
        <div class="panel box edged_box mt-2 mb-2 border-green" *ngIf="offerLetter">
          <a class="alert padding-regular bg-off-white">
            <div class="texts no-padding">
              <h4 class="title color-green">Preview the contract</h4>
              <p class="subtitle">View a copy of this contract in a new tab</p>
            </div>
            <div class="action" *ngIf="!loan?.show_old">
              <a class="btn btn-outline-success btn-sm"
                href="https://creditclanapis.creditclan.com/api/v2/services/downloadloancontract?token={{parentRouteId}}"
                target="_blank">Download PDF</a>
            </div>
            <div class="action" *ngIf="!loan?.show_old">
              <a class="btn btn-outline-success btn-sm" [href]="offerLetter.url" target="_blank">Preview</a>
            </div>
            <div class="action" *ngIf="loan?.show_old">
              <a class="btn btn-outline-success btn-sm" [href]="loan?.offer_link" target="_blank">Preview</a>
            </div>
            <div class="action">
              <button class="btn btn-outline-success btn-sm" (click)="sendContractDocs()">Send</button>
            </div>
          </a>
        </div>

      </div>
      <!-- <div class="panel border-orange">
        <a class="alert padding-regular bg-off-white">
          <div class="texts no-padding">
            <h4 class="title color-orange">View Profile</h4>
            <p class="subtitle">Go to this customer's profile page</p>
          </div>
          <div class="action">
            <a class="btn btn-outline-secondary btn-sm">View</a>
          </div>
        </a>
      </div>

      <div class="panel border-blue">
        <a class="alert padding-regular bg-off-white">
          <div class="texts no-padding">
            <h4 class="title color-blue">View eligibility settings</h4>
            <p class="subtitle">View eligibility settings of this customer</p>
          </div>
          <div class="action">
            <a class="btn btn-outline-secondary btn-sm">View</a>
          </div>
        </a>
      </div> -->



    </div>
    <div *ngIf="view=='slave'">
      <app-offer-letter (goback)="gotomaster($event)" [request_id]="parentRouteId" [updateSection]="updateSection"
        [where_from]="where_from">
      </app-offer-letter>
    </div>
  </div>

</div>