<div class="padding-regular overpanel-scroll">
   
  <form #interestBandForm="ngForm" novalidate>
    <div class="form-group">
      <label class="control-label normal">Select Path</label>
      <select class="form-control" required name="MARKET_OPTION" [(ngModel)]="change_payment_form.MARKET_OPTION">
        <option value="0"></option>
        <option [value]=""></option>
        <option [value]="1">General Market</option>
        <option [value]="2">Partner</option>
      </select>
    </div>
    <div class="form-group" *ngIf="change_payment_form.MARKET_OPTION == '2'">
      <label class="control-label normal">Select Partner</label>
      <select class="form-control" name="PARTNER_ID" [(ngModel)]="change_payment_form.PARTNER_ID">
        <option value="0"></option>
        <option *ngFor="let partner of partners" [value]="partner.company_id">{{partner.legal_name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="control-label normal">Password</label>
      <input type="password" required class="form-control" name="SECURITY_QUESTION_ANSWER"
        [(ngModel)]="change_payment_form.SECURITY_QUESTION_ANSWER">
      <input type="hidden" name="REQUEST_ID" [(ngModel)]="change_payment_form.REQUEST_ID">
    </div>
    <div class="text-left mt-4">
      <app-button (click)="changeTheDefaultPayment(interestBandForm.value, interestBandForm.valid)"
        [disabled]="loading" class="medium" [loading]="loading" label="Submit">
      </app-button>
    </div>
  </form>
</div>