<section class="panel mb-1" *ngIf="!external">
  <div class="panel-body padding-regular flex align-center">
    <button class="btn btn-outline-secondary btn-xs mr-4" (click)="goBack()">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h4 class="section-title">Loans</h4>
  </div>
</section>
<!-- List of loans -->
<div *ngIf="canViewModule">
  <div *ngIf="loading">
    <div class="panel mb-1 no-padding">
      <div class="alert padding-regular bg-off-white">
        <div class="texts">
          <div class="title skeleton mb-1">This is a sample text</div>
          <p class="subtitle skeleton">This is a sample subtext</p>
        </div>
        <div class="action flex flex-column align-end">
          <p class="default-text-size mb-1 skeleton">Sample date</p>
          <p class="default-text-size no-margin skeleton">Sample loan count</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="panel padding-regular" *ngIf="resp.count == 0">
      <section class="fill-h fill-v">
        <div class="h-group center mb-4 padded empty-state">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title">No other loans</h2>
        </div>
      </section>
    </div>
    <div *ngIf="resp.count > 0">
      <div class="panel mb-1 no-padding" *ngFor="let loan of loans">
        <div class="alert padding-regular bg-off-white">
          <div class="icon"><i class="fa fa-exclamation-circle"></i></div>
          <div class="texts">
            <div class="title">{{loan.LOAN_ID}} &nbsp;|&nbsp;
              N{{loan.REQUEST_PRINCIPAL | number}} &nbsp;|&nbsp; {{loan.REQUEST_TENOR}}
              {{loan.REQUEST_PERIOD_ID|loanduration}}</div>
            <p class="subtitle">{{loan?.DESCRIPTION}}</p>
          </div>
          <div class="action flex flex-column">
            <p class="default-text-size no-margin mb-1">On {{loan.DATE_ADDED | date: 'yMMMMd'}}</p>
            <p class="default-text-size no-margin">Status: {{loan?.STATUS}}</p>
          </div>
        </div>

        <div class="panel-overlay flex-center">
          <!-- <a *ngIf="loan?.LOAN_STATUS<3 && loan?.LOAN_STATUS > 0" (click)="openPending(loan.REQUEST_ID)" -->
          <a *ngIf="loan?.LOAN_STATUS<3 && loan?.LOAN_STATUS > 0" (click)="openRequest(loan.REQUEST_ID)"
            class="btn btn-outline-secondary pointer">View</a>
          <a *ngIf="loan?.LOAN_STATUS>2" (click)="openPortfolio(loan.REQUEST_ID)"
            class="btn btn-outline-secondary pointer">View</a>
        </div>
      </div>

      <div *ngIf="data?.complete == false" class="mt-4 text-right">
        <a (click)="loadMoreRecords(data?.start)" class="btn btn-outline-secondary">Load More...</a>
      </div>
    </div>
  </div>
</div>


<app-requset-sideview *ngIf="activeRequestId" (close)="closeRequest()" [requestId]="activeRequestId" [autoLoad]="true">
  <!-- (openModal)="openModal($event)" (approveThisLoan)="approveThisLoan($event)" (rejectThisLoan)="rejectThisLoan($event)"
  (doingDisbursement)="doingDisbursement($event)"> -->
</app-requset-sideview>