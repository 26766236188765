<div class="row">
  <!-- <div *ngIf="loading" class="col-md-9">
    <section class="panel">
      <div class="customers">
        <div class="customer align-center">
          <div class="header">
            <a class="img-wrap cursor-pointer">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
            </a>
            <div class="content">
              <div class="name skeleton mb-1">Sample Amount</div>
              <div class="type skeleton mb-1">Sample Amount</div>
              <div class="type skeleton mb-1">Sample Amount</div>
            </div>
            <button class="icon-button" type="button" data-toggle="collapse" aria-expanded="false"
              aria-controls="collapseExample">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
        </div>

        <div class="customer align-center">
          <div class="header">
            <a class="img-wrap cursor-pointer">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
            </a>
            <div class="content">
              <div class="name skeleton mb-1">Sample Amount</div>
              <div class="type skeleton mb-1">Sample Amount</div>
              <div class="type skeleton mb-1">Sample Amount</div>
            </div>
            <button class="icon-button" type="button" data-toggle="collapse" aria-expanded="false"
              aria-controls="collapseExample">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div> -->
  <div class="col-md-9">
    <app-homenavigation></app-homenavigation>

    <section class="panel">
      <app-help-popover
        [content]="[
          {
            title: 'All notifications',
            img: 'assets/images/bell.svg',
            text:
              '
            <div>
              <p>All notifications you will need to run your lending platform. Such as, new customer registered, customer verifird emeil, request rejected, contract accepted, and many more.</p>
            </div>
          '
          }
        ]"
      ></app-help-popover>
      <div *ngIf="notifications.length == 0 && !loading">
        <div class="h-group center mb-4 padded empty-state mt-5">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title no-float">No records</h2>
          <p class="subtitle">No records found</p>
        </div>
      </div>
      <div class="customers" *ngIf="notifications.length !== 0 || loading">
        <div class="customer flex align-center justify-between padding-regular">
          <div class="section-title">All nofications</div>
          <input type="text" class="notify_search_input" />
        </div>

        <section
          infinite-scroll
          (scrolled)="onScrollDown()"
          class="item-area no-margin"
          slimScroll
          width="auto"
          [infiniteScrollDistance]="0"
        >
          <div
            *ngFor="let notification of notifications"
            class="customer align-center"
          >
            <div class="header align-center">
              <a class="img-wrap">
                <img
                  src="{{notification?.FILE_NAME}}"
                />
              </a>
              <div class="content">
                <div class="name">{{ notification.LEGAL_NAME }}</div>
                <div class="type">{{ notification.TIMELINE_ACTIVITY }}</div>
              </div>
              <span class="flex align-center">
                <small>{{ notification.DATE_ADDED | date: "fullDate" }}</small>
              </span>
            </div>
          </div>

          <div class="customer align-center" *ngIf="loading">
            <div class="header">
              <a class="img-wrap">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg"
                />
              </a>
              <div class="content">
                <div class="name skeleton mb-1">Sample Amount</div>
                <div class="type skeleton">Sample Amount</div>
              </div>
            </div>
          </div>
          <div class="customer align-center" *ngIf="loading">
            <div class="header">
              <a class="img-wrap">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg"
                />
              </a>
              <div class="content">
                <div class="name skeleton mb-1">Sample Amount</div>
                <div class="type skeleton">Sample Amount</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
  <!-- <div class="col-md-9">
      <div class="panel mb-1">
          <div class="panel-body flex align-start">
        
            <div class="section-poster flx-2" style="max-width: none">
              <app-timer [endDate]='endDate'></app-timer>
            </div>
        
            <div class="flex-2 ml-3" style="margin-left:100px !important">
              <div class="label-value-list mb-2">
                <h4 class="section-title mb-5">Features</h4>
                <div class="item">
                  
                  <span class="value pl_0 ">Get notifications on your customer activities on your platform</span> 
                </div>
                <div class="separator"></div> 
                <div class="item">
                  
                  <span class="value pl_0">know when customers add card or perform an action.</span> 
                </div>
                
                 
        
              </div> 
            </div>
          </div>
        </div>
  </div> -->
  <div class="col-md-3 pl_0">
    <div class="panel box mb-1">
      <div class="panel-body text-center">
        <div class="h-group">
          <h3 class="title large">{{ notifications_count }}</h3>
          <p class="subtitle mb-3">Notifications</p>
        </div>
      </div>
    </div>
  </div>
</div>
