<div *ngIf="loan?.loan_status > -1">
    <div class="panel mb-1 overflow-visible">
      <div class="padding-regular">
        <div class="small-menu no-padding">
          <button class="btn btn-outline-secondary btn-sm" *ngIf="backToSummary" (click)="openRequests()">
            <i class="fa fa-arrow-left mr-1"></i> Summary
          </button>
          <h4 class="section-title flex flex-row align-center" *ngIf="!backToSummary"><span>Loan Details</span>
            <div class="dropdown" *ngIf="nooptions">
              <button class="icon-button ml-2 dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-chevron-down"></i>
              </button>
              <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li><a (click)="openAprovals()">Approvals</a></li>
                <li><a (click)="openRequests()">Requests</a></li> 
              </ul> -->
            </div>
          </h4>
          <ul class="list-inline skeleton" *ngIf="!request_id_">
            <li><a>Contract</a></li>
            <li><a>Profile</a></li>
          </ul>
          <ul class="list-inline" *ngIf="!nooptions && request_id_">
            <li><a [routerLinkActive]="'active'"
                [routerLink]="['../../../../applications/pending/' + this.request_id_]">Contract</a></li>
            <li><a [routerLinkActive]="'active'"
                [routerLink]="['../../../../applications/profile/' + this.request_id_]">Profile</a></li>
            <li><a [routerLinkActive]="'active'"
                [routerLink]="['../../../../applications/timeline/' + this.request_id_]">Timeline</a></li>
            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                [ngClass]="{'active': text !== 'More'}" aria-expanded="false">{{text}} <span
                  class="ml-2 caret"></span></a>
              <ul class="dropdown-menu dropdown-menu-right mt-3">
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/offerletter/' + request_id_]">Offer letter</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/eligibility/' + this.request_id_]">Eligibility</a></li>
                <li style="cursor: not-allowed disable"><a>Analytics</a></li>
                <!-- <li><a [routerLinkActive]="'active'" [routerLink]="['../../../../applications/analytics/' + this.request_id_]">Analytics</a></li> -->
                <li><a [routerLinkActive]="'active'" [routerLink]="['../../../../applications/kyc/' + request_id_]">Address
                    VS</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/creditcheck/' + request_id_]">Credit Bureau</a></li>
                <!-- <li><a [routerLink]="['../../../../applications/guarantors/' + request_id_]">Loan cover</a></li> -->
                <li *ngIf="enable_peer_to_peer=='1'"><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/p2p/' + request_id_]">P2P</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/cover/' + request_id_]">Guarantors</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/requests/' + request_id_]">Other loans</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/recommendations/' + request_id_]">Recommendations</a></li>
                <li><a [routerLinkActive]="'active'"
                    [routerLink]="['../../../../applications/collaterals/' + request_id_]">Collaterals</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loan?.loan_status < 0">
    <div class="panel mb-1 overflow-visible">
      <div class="padding-regular">
        <div class="small-menu no-padding">
          <h4 class="section-title flex flex-row align-center"><span>{{text}}</span>
          </h4>
          <ul class="list-inline">
            <li><button (click)="openProfile()">Profile</button></li>
            <li><button (click)="openEligibility()">Eligibility</button></li>
            <li *ngIf="loan?.is_eligible !='1'"><a (click)="openTimeline()">Timeline</a></li>
  
          </ul>
        </div>
      </div>
    </div>
  </div>