import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loanStatus'
})
export class LoanStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Applied";
    }
    if (value == "2") {
      return "Pending";
    }
    if (value == "3") {
      return "Repayment";
    }
    if (value == "4") {
      return "Overdue";
    }
    if (value == "5") {
      return "Paid";
    }
    if (value == "-1") {
      return "Rejected";
    }
    if (value == "-3") {
      return "Rejected";
    }
  }

}

