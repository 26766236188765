import { Component, OnInit, OnDestroy, Output, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, OptionsserviceService, LoansService, StorageService, AuthenticationService } from '../_services/index';
import { Loan } from '../_interfaces/loan.interface';
import { Loan_ } from '../_models/loan_';
import { ActivatedRoute } from '@angular/router';
// import { Uploader } from 'angular2-http-file-upload';
// import { MyUploadItem } from './my-upload-item';
@Component({
  selector: 'app-loanphotos',
  templateUrl: './loanphotos.component.html',
  styleUrls: ['./loanphotos.component.css']
})
export class LoanphotosComponent implements OnInit {
  @Input('parentRouteId') parentRouteId: number;
  @Input('sub') sub: any;
  @Input('sub_summary') sub_summary: any;
  @Input('where') where = '1';
  @Input('customer_id') customer_id = '0';
  @Input('from') from = '0';
  @Input('token') token: any;
  view = 'attach';
  attachments = [];
  currentUser: any;
  description = "";
  file_field: any;
  loading = false;
  file_type = '2';
  
  fileHasBeenUploaded = false;
  doc_types: any;
  DOCTYPE: any;
  @ViewChild('file') file;
  photoHasBeenUploaded = false;
  filesUploaded = [];
  public files: Set<File> = new Set();
  uploadingfile = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  photos = [];
  constructor(private sanitizer: DomSanitizer,
    private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    private authService: AuthenticationService,
    public loansService: LoansService) {

  }
  fileUploadeds(event) {
    //console.log(event)
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    this.loadRecords()
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  addingphoto() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.file_type = '2';
      this.open('photo_form');
      this.filesUploaded = [];
    }
  }
  uploadThePhoto() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
          .subscribe(result => {
            this.attachments = result.data.attachments;
            this.doc_types = result.data.doctypes;
            this.open('photo');
            this.loading = false;
          });
      });
    }else if(this.where == '2'){
      this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
      .subscribe(result => {
        this.attachments = result.data.attachments;
        this.doc_types = result.data.doctypes;
        this.open('attach');
        this.loading = false;
      });
    }  else {
      this.loansService.uploadTheLoanPhotoCustomer(this.currentUser.token, this.customer_id, this.filesUploaded, this.DOCTYPE, this.description)
        .subscribe(result => {
          this.attachments = result.data.attachments;
          this.doc_types = result.data.doctypes;
          this.open('attach');
          this.loading = false;
        });
    }
  }
  uploadTheFile() {
    console.log(this.where)
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
        .subscribe(result => {
          this.attachments = result.data.attachments;
          this.doc_types = result.data.doctypes;
          this.open('attach');
          this.loading = false;
        });
      });
    }else if(this.where == '2'){
      this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
      .subscribe(result => {
        this.attachments = result.data.attachments;
        this.doc_types = result.data.doctypes;
        this.open('attach');
        this.loading = false;
      });
    } else {
      this.loansService.uploadTheLoanPhotoCustomer(this.currentUser.token, this.customer_id, this.filesUploaded, this.DOCTYPE, this.description)
        .subscribe(result => {
          this.attachments = result.data.attachments;
          this.doc_types = result.data.doctypes;
          this.open('attach');
          this.loading = false;
        });
    }
  }
  loadRecords() {
    // this.currentUser = this.storageService.read<any>('currentUser');

    // if (this.where == '1') {
    //   this.sub = this.route.parent.params.subscribe(params => {
    //     this.parentRouteId = +params["id"];
    //     this.loansService.getLoanAttachments(this.currentUser.token, this.parentRouteId)
    //       .subscribe(result => {
    //         this.attachments = result.data.attachments;
    //         this.doc_types = result.data.doctypes;

    //       });
    //   });
    // } else {
    //   this.loading = true;
    //   this.loansService.getLoanAttachments_(this.currentUser.token, this.parentRouteId, this.customer_id, this.from)
    //     .subscribe(result => {
    //       this.photos = result.data.attachments.filter(attach => attach.FILE_TYPE === '2' && attach.FILE_NAME);
    //       this.attachments = result.data.attachments.filter(attach => attach.FILE_TYPE === '1' && attach.FILE_NAME);
    //       this.doc_types = result.data.doctypes;
    //       this.loading = false;
    //     });
    // }
    this.currentUser = this.storageService.read<any>('currentUser');

    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.getLoanAttachments(this.currentUser.token, this.parentRouteId)
          .subscribe(result => {
            this.attachments = result.data.attachments;
            this.doc_types = result.data.doctypes;

          });
      });
    }else if(this.where == '2'){
      this.loansService.getLoanAttachments(this.currentUser.token, this.parentRouteId)
      .subscribe(result => {
        this.attachments = result.data.attachments;
        this.doc_types = result.data.doctypes;

      });
    } else {
      this.loading = true;
      this.loansService.getLoanAttachments_(this.currentUser.token, this.parentRouteId, this.customer_id, this.from)
        .subscribe(result => {
          this.attachments = result.data.attachments;
          this.doc_types = result.data.doctypes;
          this.loading = false;
        });
    }
  }
  onFilesAdded(event) {
    this.uploadingfile = true;
    let files = event.target.files;
    this.loansService.doUpload(files[0])
      .subscribe(result => {
        this.uploadingfile = false;
        if (result.status == true) {
          this.photoHasBeenUploaded = true;
          this.filesUploaded.push(result.result);
        }

      });
  }
  openUploadDialog() {
    this.file.nativeElement.click();
  }
  uploadFile(event) {
    // let files = event.target.files;
    // //this.loansService.uploadFile(files[0],this.description,this.parentRouteId);
    // let uploadFile = files[0];
    // let myUploadItem = new MyUploadItem(uploadFile);
    // myUploadItem.formData = { description: this.description, token: this.currentUser.token, request_id: this.parentRouteId, section: this.file_type };  // (optional) form data can be sent with file
    // this.loading = true;
    // this.uploaderService.onSuccessUpload = (item, response, status, headers) => {
    //   // success callback
    //   this.loading = false;
    // };
    // this.uploaderService.onErrorUpload = (item, response, status, headers) => {
    //   // error callback
    //   this.loading = false;
    // };
    // this.uploaderService.onCompleteUpload = (item, response, status, headers) => {
    //   // complete callback, called regardless of success or failure
    //   this.loading = false;
    //   this.loadRecords();
    //   this.view = "photo"
    // };
    // this.uploaderService.upload(myUploadItem);
  }
  open(section) {
    this.view = section;
    this.description = '';
    this.filesUploaded = [];
  }
  close() {
    this.view = 'photo';
    this.filesUploaded = [];
  }

}
