import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flowsummary',
  templateUrl: './flowsummary.component.html',
  styleUrls: ['./flowsummary.component.css']
})
export class FlowsummaryComponent implements OnInit {
  @Input('loan_viewed') loan_viewed: any;
  @Input('is_approval') is_approval=false;
  @Input('dontshownext') dontshownext = '0';
  @Input('pending_approvals') pending_approvals = '0';
  @Input('loan_status') loan_status = '0';
  @Output() modalClosed = new EventEmitter();
  @Output() showMessage = new EventEmitter();
  @Output() openForFinalPayment = new EventEmitter(); 
  @Input('view_state') view_state = '0';
  constructor() { }

  ngOnInit() {
  }
  paymentHasBeenQueued(event){
    this.openForFinalPayment.emit(event);
  }
}
