import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoansService } from '../_services/loans.service';
import { StorageService } from '../_services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { Router } from '@angular/router';


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit, OnDestroy {
  tasks: any;
  perform_by = localStorage.getItem('user_id');
  currentUser: any;
  request_id: any;
  comment: string;
  loading: boolean;
  passed_task_id: any;
  createdTasks: any;
  showTask: boolean;
  taskCount: number;
  taskSubscription: Subscription;
  countTaskSubscription: Subscription;
  updateTaskSubscription: Subscription;

  constructor(public toastr: ToastrService,
    private storageService: StorageService,
    public route: ActivatedRoute,
    private loansService: LoansService) { 
      this.currentUser = this.storageService.read<any>('currentUser');
      this.request_id = route.snapshot.parent.params['id'];
    }

  ngOnInit() {
    this.getTasks();
    this.countTasks();
    this.myCreatedTasks();
  }

  getTasks() {
    this.loading = true;
    this.taskSubscription = this.loansService.myTasks(this.currentUser.token, this.perform_by)
    .subscribe(result => {
        this.tasks = result;
        this.loading = false;
    });
  }

  myCreatedTasks() {
    this.loading = true;
    this.taskSubscription = this.loansService.myCreatedTasks(this.currentUser.token, this.perform_by)
    .subscribe(result => {
        this.createdTasks = result;
        this.loading = false;
    });
  }

  countTasks() {
    this.countTaskSubscription = this.loansService.countMyTasks(this.currentUser.token, this.perform_by)
    .subscribe(result => {
        this.taskCount = result;
        console.log(result);
    });
  }
  
  updateComment() {
    this.loading = true;
    this.updateTaskSubscription = this.loansService.staffUpdateTask(this.currentUser.token, this.passed_task_id,
      this.comment, this.perform_by)
    .subscribe(result => {
        this.toastr.success('Success', 'Comment saved!');
        this.loading = false;
        this.tasks = result;
        this.getTasks();
    });
  }
  
  addComment(task_id) {
    this.showTask = true;
    this.passed_task_id = task_id;
  }

  ngOnDestroy() {
    // this.taskSubscription.unsubscribe();
    // this.updateTaskSubscription.unsubscribe();
  }

}
