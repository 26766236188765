import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loanduration'
})
export class LoandurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Days";
    }
    if (value == "2") {
      return "Months";
    }
    if (value == "3") {
      return "Years";
    }
    if (value == "4") {
      return "Weeks";
    } 
    return 'N/A';
  }

}
