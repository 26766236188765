<div class="body__wrapper">
  <div class="body__container">
    <nav class="nav">
      <ul class="nav__flex-container">
        <li class="nav__list"><a href="javascript:">About Us</a></li>
        <li class="nav__list"><a href="javascript:">Borrow Loans</a></li>
        <li class="nav__list"><a href="javascript:">Buy Loans</a></li>
        <li class="nav__list"><a href="javascript:">Sign In</a></li>
      </ul>
    </nav>
    <div class="main">
      <form class="main__form">
        <div class="form-group">
          <input type="text" placeholder="How much do you need" class="form-control">
        </div>
        <div class="form-group">
          <select name="select_name" id="" class="form-control">
            <option disabled>Select an option</option>
            <option value="2">What do you need it for</option>
            <option value="3">When do you need it</option>
          </select>
        </div>
        <div class="form-group">
          <select name="select_name" id="" class="form-control">
            <option disabled>Select an option</option>
            <option value="2">How is your credit</option>
            <option value="3">When do you need it</option>
          </select>
        </div>
        <button class="orange-btn padded btn btn-default" style="width: 100%"
                onclick="event.preventDefault(); CreditClan.open('iFNvz2BhpgddcgjybqDFNUAmHDYcTleACv99G8hZ')">Borrow
        </button>
      </form>
    </div>
  </div>
</div>
