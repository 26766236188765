import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-requestheader',
  templateUrl: './requestheader.component.html',
  styleUrls: ['./requestheader.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class RequestheaderComponent implements OnInit {
  @Input('loan') loan: any;
  @Input('cards') cards: any;
  @Input('where') where = '';
  @Input('account') account: any;
  @Input('accounts_added') accounts_added = '0';
  @Output() requestForNewCard = new EventEmitter();
  @Output() requestForNewAccount = new EventEmitter();
  @Output() changeTheDefaultCard_ = new EventEmitter();
  @Output() changeTheAccountCard_ = new EventEmitter();
  @Output() changeTheAccountAccount_ = new EventEmitter();
  @Output() checkDirectDebitStatus = new EventEmitter();
  @Output() cancelDirectDebit = new EventEmitter();
  @Output() setupDirectDebit = new EventEmitter();
  @Output() resendingLink = new EventEmitter();
  @Output() setCardAsDefault_ = new EventEmitter();
  @Output() autodebitHistory = new EventEmitter();
  card_selected: any;
  @Input('accounts') accounts: any;
  account_selected: any;
  constructor() { }

  ngOnInit() { 
    if (this.accounts_added != '0') {
      this.account_selected = this.accounts[0];
    }

  }
  showHistory(){
    this.autodebitHistory.emit();
  }
  requestForNewCard_() { 
    this.requestForNewCard.emit();
  }
  setCardAsDefault(){
    this.setCardAsDefault_.emit();
  }
  requestForNewAccount_() {
    this.requestForNewAccount.emit();
  }
  changeTheDefaultCard(event) {
    this.changeTheDefaultCard_.emit({ default_card: this.card_selected });
  }
 
  changeTheDefaultAccount(event) {
    this.changeTheAccountAccount_.emit({ account_card: this.account_selected });
  }
  checkDDStatus() {
    this.checkDirectDebitStatus.emit();
  }
  cancelDD() {
    this.cancelDirectDebit.emit();
  }
  setUpDD() {
    this.setupDirectDebit.emit();
  }
  resendLink() {
    this.resendingLink.emit();
  }
}
