<div class="side-view animated slideInRight">
    <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
    <div class="side-view-wrap">
        <div class="header" *ngIf="!loan">
            <div class="user">
                <div class="image large skeleton fit-content">
                    <img class="image-avatar" src="" />
                </div>
                <div class="content skeleton-children">
                    <div class="title mb-1">Lorem ipsum dolor sit lofoe.</div>
                    <div class="subtitle mb-1">Lorem ipsum </div>
                    <div>Lorem,.</div>
                </div>
            </div>
        </div>

        <div class="header" *ngIf="loan">
            <div class="user">
                <div class="image large cursor-pointer" (click)="child = 'profile-picture'">
                    <img class="{{ loan | requeststatus }}" [ngClass]="{'image-avatar': true}"
                        src="{{loan?.customer?.profile_pic | imagenotfound}}" />
                </div>
                <div class="content flex-1">
                    <div class="title">{{ loan?.customer.legal_name }}</div>
                    <div class="subtitle">
                        N{{ loan.request_principal | number:'.2-2' }} | {{ loan.request_tenor }}
                        {{ loan.loan_duration }}
                    </div>
                    <div class="loan-action-buttons mt-2">
                        <app-loanaction (pushToMarket)="pushToMarket.emit($event)"
                            (approveThisLoan)="approveThisLoan.emit($event)"
                            (rejectThisLoan)="rejectThisLoan.emit($event)"
                            
                            (createPRequest)="is_partner=true; child = 'create-partner-contract'"
                            (doingDisbursement)="doingDisbursement($event)" [request_id]="requestId">
                        </app-loanaction>
                    </div>
                </div>
                <div class="flex flex-row justify-around" style="align-self: flex-start">
                    <app-button class="small outline mr-1" (click)="setChild('liste')" icon="fa fa-list"
                        label="Send Message"></app-button>
                    <app-button class="small outline" (click)="child = 'schedule'"
                        *ngIf="grantRight('22') && loan?.loan_status > 0" icon="fa fa-list" label="View Schedule">
                    </app-button>
                    <button *ngIf="loan?.has_collateral && loan?.loan_status > 0"
                        class="btn btn-outline-info ml-2 bounce-animation" (click)="child = 'collateral'">
                        <i class="fas fa-car color-teal"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="nav" *ngIf="loan?.loan_status > 0">
            <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
                Application
            </div>
            <div class="nav-item" (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">
                Timeline
            </div>
            <div class="nav-item" (click)="setView('links')" [ngClass]="{'active': view === 'links'}">
                Analysis
            </div>
            <div class="dropdown">
                <div class="dropdown-toggle nav-item" [ngClass]="{'active': view === 'more'}" id="dropdownMenu1"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{{ more }}
                    <span class="caret ml-2"></span>
                </div>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li><a (click)="setMore('Collateral')">Collateral</a></li>
                    <li><a (click)="setMore('Recommendation')">Recommendation</a></li>
                    <li><a (click)="setMore('Offer Letter')">Offer Letter</a></li>
                    <li><a (click)="setMore('Checklist')">Checklist</a></li>
                    <li><a (click)="setMore('Recollection')">Recollection</a></li>
                    <li><a (click)="setMore('Loan Action')">Loan Action</a></li>
                    <li><a (click)="setMore('Account Officer')">Account Officer</a></li>
                    <li><a (click)="setMore('Guarantor')">Guarantor</a></li>
                    <!-- <li><a (click)="setMore('ippis')">IPPIS</a></li> -->
                    <li *ngIf="loan && loan?.repayment_mode=='6'"><a (click)="setMore('More')">More</a></li>
                </ul>
            </div>
        </div>
        <div class="nav" *ngIf="loan?.loan_status < 0">
            <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
                Application
            </div>
            <div class="nav-item" (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">
                Timeline
            </div>
            <div class="nav-item" (click)="child='eligibility'" [ngClass]="{'active': view === 'links'}">
                Eligibility
            </div>

        </div>

        <div class="content skeleton-children fit-content" *ngIf="loading">
            <div class="fill-w mb-2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, possimus suscipit illum
                consectetur quibusdam magnam provident tempora ducimus expedita nostrum non aliquid quod earum eos
                dignissimos, recusandae unde sed soluta! recusandae unde sed
                soluta!
            </div>
            <div class="fill-w mb-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
                nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
                Quidem adipisci facilis porro doloremque at
                quos pariatur, illo magnam in minima!
            </div>
            <div class="fill-w mb-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
                nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
                Quidem adipisci facilis porro doloremque at
                quos pariatur, illo magnam in minima!
            </div>
        </div>

        <div class="content" *ngIf="loan && view === 'application'">
            <div *ngIf="loan?.loan_status > 0">
                <app-requestlastactivity [loan]="loan"></app-requestlastactivity>
                <app-fraudtest *ngIf="lender?.PEOPLE_ID=='40245'" [type]="'datacheck'" [reqid]="loan?.request_id"></app-fraudtest>
                <app-fraudtest *ngIf="lender?.PEOPLE_ID=='40245' && loan" [reqid]="loan?.request_id"></app-fraudtest>
                <div class="panel padding-regular-medium">
                    <br>
                    <br>
                    <ng-container *ngIf="loan?.schedule_has_been_created">
                        <ng-container *ngIf="!loan?.contract_has_changed">
                            <ng-container *ngIf="loan?.contract_doc_sent">
                                <!-- Offer sent to customer - Not accepted -->
                                <div class="flex flex-column align-center" *ngIf="loan?.is_accepted == '0'">
                                    <img src="/assets/images/offer-sent.svg" alt="" style="max-width: 100px;">
                                    <div class="default-text-size text-center mt-5">Offer sent to customer</div>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        The offer is yet to be accepted by the customer
                                    </p>
                                </div>
                                <!-- Offer sent to customer - Accepted -->
                                <div class="flex flex-column align-center" *ngIf="loan?.is_accepted == '1'">
                                    <img src="/assets/images/offer-sent.svg" alt="" style="max-width: 100px;">
                                    <div class="default-text-size text-center mt-5">Offer sent to customer</div>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        The offer has been accepted by the customer
                                    </p>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        {{loan?.date_accepted}}
                                    </p>
                                </div>
                            </ng-container>
                            <!-- Offer created -->
                            <div class="flex flex-column align-center" *ngIf="!loan?.contract_doc_sent">
                                <img src="/assets/images/offer-not-sent.svg" alt="" style="max-width: 100px;">
                                <div class="default-text-size text-center mt-5">Offer created</div>
                                <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                    The offer has been created but not sent to the customer
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="loan?.contract_has_changed">
                            <ng-container *ngIf="loan?.contract_doc_sent">
                                <div class="flex flex-column align-center" *ngIf="loan?.is_accepted == '0'">
                                    <img src="/assets/images/offer-sent.svg" alt="" style="max-width: 100px;">
                                    <div class="default-text-size text-center mt-5">Offer changed and sent to customer
                                    </div>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        The offer is yet to be accepted by the customer
                                    </p>
                                </div>
                                <!-- Offer sent to customer - Accepted -->
                                <div class="flex flex-column align-center" *ngIf="loan?.is_accepted == '1'">
                                    <img src="/assets/images/offer-sent.svg" alt="" style="max-width: 100px;">
                                    <div class="default-text-size text-center mt-5">Offer changed and sent to customer
                                    </div>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        The offer has been accepted by the customer
                                    </p>
                                    <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                        {{loan?.date_accepted}}
                                    </p>
                                </div>
                            </ng-container>
                            <div class="flex flex-column align-center" *ngIf="!loan?.contract_doc_sent">
                                <img src="/assets/images/offer-not-sent.svg" alt="" style="max-width: 100px;">
                                <div class="default-text-size text-center mt-5">Offer changed</div>
                                <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                                    The offer has changed but not sent to the customer
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- Offer not yet created -->
                    <div class="flex flex-column align-center" *ngIf="!loan?.schedule_has_been_created">
                        <img src="/assets/images/offer-not-created.svg" alt="" style="max-width: 100px;">
                        <div class="default-text-size text-center mt-5">Offer not yet created</div>
                        <p class="text-center mb-5 text-center text-muted width_1_3 mt-1">
                            No offer has been created for this customer
                        </p>
                    </div>
                    <br>
                    <br>

                    <div class="flex">
                        <div class="flex-1 flex-center border-right" *ngIf="loan.request_period_id!='1'">
                            <div class="h-group text-center">
                                <div class="title" *ngIf="loan.monthly_repayment">
                                    N{{ loan.monthly_repayment | number:'.2-2' }}</div>
                                <div class="title" *ngIf="!loan.monthly_repayment">
                                    N{{ calculateMonthlyRepayment(loan.request_principal, loan.request_tenor,
                                    loan.request_rate) | number:'.2-2' }}
                                </div>
                                <div>Monthly Repayment</div>
                            </div>
                        </div>
                        <div class="flex-1 flex-center border-right" *ngIf="loan.request_period_id=='1'">
                            <div class="h-group text-center">
                                <div class="title" *ngIf="loan.total_repayment">
                                    N{{ loan.total_repayment | number:'.2-2' }}</div>
                                <div class="title" *ngIf="!loan.total_repayment">
                                    N0
                                </div>
                                <div>Total Repayment</div>
                            </div>
                        </div>
                        <div class="flex-1 flex-center">
                            <div class="h-group text-center">
                                <div class="title">N{{ loan.request_principal | number:'.2-2' }}</div>
                                <div>for {{ loan.request_tenor }} {{ loan.loan_duration }}</div>
                                <!-- <div>Request principal</div> -->
                            </div>
                        </div>
                        <div class="flex-1 flex-center border-left">
                            <div class="h-group text-center">
                                <div class="title">{{ loan.request_rate }}%</div>
                                <div>Interest rate</div>
                            </div>
                        </div>
                    </div>

                    <div class="panel-overlay flex-center justify-end flex-column status-overlay">
                        <div class="flex mt-3 mb-4" *ngIf="loan?.schedule_has_been_created">
                            <app-button class="small outline orange" label="Edit Request" (click)="child = 'edit'">
                            </app-button>
                            <app-button class="small green ml-1" label="Send" (click)="sendContract()"></app-button>
                        </div>

                        <div class="flex mt-3 mb-4" *ngIf="!loan?.schedule_has_been_created">
                            <app-button class="small green outline" label="Create contract"
                                (click)="child = 'create-contract'" [disabled]="!grantRight('17')"></app-button>
                        </div>
                        <br>
                        <br>
                        <div class="flex justify-between mt-4 w-75">
                            <div class="icon-text medium cursor-pointer mb-4" (click)="setMore('Offer Letter')">
                                <div class="icon color-blue round-icon"><i class="fas fa-envelope"></i></div>
                                <div class="text">Offer Letter</div>
                            </div>
                            <div class="icon-text medium cursor-pointer mb-4" (click)="setMore('Loan Action')">
                                <div class="icon color-green round-icon"><i class="fas fa-external-link-square-alt"></i>
                                </div>
                                <div class="text">Take action</div>
                            </div>
                            <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'contractdetails'">
                                <div class="icon color-orange round-icon"><i class=" fas fa-info-circle"></i></div>
                                <div class="text">Loan Details</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-1" *ngIf="loan?.show_statement && loan?.attachment">
                    <div class="panel bg-off-white">
                        <div class="alert padding-regular bg-off-white">
                            <div class="texts no-padding">
                                <h4 class="title color-teal">Bank Statement Analysis</h4>
                                <p class="subtitle" style="margin-top: 3px;">
                                    Name Check: {{loan?.attachment?.ANALYSIS_PDF?.message}}</p>
                            </div>
                            <div class="action">
                                <a href="{{loan?.attachment?.FILE_NAME}}" target="_blank" class="btn btn-outline-info">
                                    <i class="fas fa-external-link-square-alt mr-2"></i>View result
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <app-request-sideview-more-pictures [eligibility_analysis]="loan?.eligibility_analysis?.offers[0]"
                    [is_anonymous]="loan?.nodes_and_scored?.is_anonymous"
                    [loan_amount]="loan?.eligibility_analysis?.request_principal" [referal_code]="loan?.referal_code"
                    [creditclan_request_id]="loan?.request_id" *ngIf="lender?.PEOPLE_ID=='40245' && loan && !loading">
                </app-request-sideview-more-pictures>
                <app-upfrontpayment [reqid]="loan?.request_id" *ngIf="loan && lender?.PEOPLE_ID=='40245'">
                </app-upfrontpayment>
                <app-ippisdetails (openMore)="ippischeckerview='default';setChild('ippischecker')" [loan]="loan" *ngIf="loan" [loan]="loan">
                </app-ippisdetails>
                <div *ngIf="lender?.PEOPLE_ID=='40245'">
                    <div class="panel bg-off-white">
                        <div class="alert">
                            <div class="texts no-padding">
                                <div class="title">Disbursement Account</div>
                                <div class="subtitle">Generate Partner Disbursement Account</div>
                            </div>
                            <div class="action">
                                <app-button *ngIf="!loading" class="small outline mr-1" label="Generate Account"
                                (click)="ippischeckerview='generteaccount';setChild('ippischecker')"></app-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="lender?.PEOPLE_ID=='40245'">
                    <div class="panel bg-off-white">
                        <div class="alert">
                            <div class="texts no-padding">
                                <div class="title">Upfront Account</div>
                                <div class="subtitle">Generate Upfront Account</div>
                            </div>
                            <div class="action">
                                <app-button *ngIf="!loading" class="small outline mr-1" label="Generate Account"
                                (click)="ippischeckerview='generteupfrotaccount';setChild('ippischecker')"></app-button>
                            </div>
                        </div>
                    </div>
                </div>

                <app-requestloanproduct [loan]="loan" *ngIf="loan && lender?.PEOPLE_ID=='40245'">
                </app-requestloanproduct>
                <app-ninbyphonenumber *ngIf="loan && lender?.PEOPLE_ID=='40245'" [reqid]="loan?.people_id">
                </app-ninbyphonenumber>
                <app-statementlink [phone]="loan?.customer?.phone" *ngIf="lender?.PEOPLE_ID=='40245' && loan"></app-statementlink>
                <app-bank-statement-analyze [customername]="loan?.customer?.legal_name" *ngIf="loan && lender?.PEOPLE_ID=='40245'" [reqid]="requestId"
                    (openMore)="setChild($event)">
                </app-bank-statement-analyze>
                <!-- <app-applicationsofferletter *ngIf="loan && lender?.PEOPLE_ID=='40245' && loan?.schedule_has_been_saved=='1'" [loan__]="loan" [requestId]="requestId"
                    [embeded]="true">
                </app-applicationsofferletter> -->
                <div class="panel padding-regular">
                    <app-nibbsdebit *ngIf="lender?.PEOPLE_ID=='40245'" [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan">
                    </app-nibbsdebit>
                </div>
                <div class="panel" *ngIf="lender?.PEOPLE_ID=='40245'">
                    <app-accountmodal [request_id]="requestId" [token]="currentUser.token"></app-accountmodal>
                </div>
                <app-remitainfodetails [customer]="customer" *ngIf="loan && loan?.use_remita==1">
                </app-remitainfodetails>
                <app-applicationschecklist *ngIf="loan && loan?.request_view_type=='3'" [loan]='loan' [where]="1"
                    [parentRouteId]="requestId">
                </app-applicationschecklist>
                <div class="panel padding-regular">
                    <div class="flex align-center mt-5 mb-5">
                        <div class="image flex-center justify-start mr-4">
                            <img src="assets/images/form.svg" style="max-width: 100px;">
                        </div>
                        <div class="h-group flex-1 ml-4">
                            <h3 class="title no-margin">NIBBS Mandate</h3>
                            <p class="subtitle fill-w mb-2">
                                Set up NIBBS Mandate
                            </p>
                            <!-- <app-button class="small outline orange mt-2" label="Run analysis"
                                (click)="setupmandate()"></app-button> -->
                            <app-button class="small outline orange mt-2" label="Open" (click)="child='nibbsdebit'">
                            </app-button>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="panel padding-regular">
                    <div class="flex align-center mt-5 mb-5">
                        <div class="image flex-center justify-start mr-4">
                            <img src="assets/images/form.svg" style="max-width: 100px;">
                        </div>
                        <div class="h-group flex-1 ml-4">
                            <h3 class="title no-margin">Account Balance</h3>
                            <p class="subtitle fill-w mb-2">
                                Fetch Account Balance
                            </p> 
                            <app-button class="small outline orange mt-2" label="Open" (click)="child='accountbalance'">
                            </app-button>
                        </div>
                    </div>
                  </div> -->

                <app-customer-info *ngIf="!loading" [loan]="loan" [customer]="customer" [is_individual]="is_individual"
                    (refresh)="getRequestCustomer(loan)" [reqid]="requestId" [pid]="loan.people_id" [from_loan]="true"
                    [show_header]="false" (setView)="setView($event)" (setMore)="setMore($event)"
                    (openModalChild)="setChild($event)" [embeded]="true" [checklist]="checklist"
                    (requestingData)="requestingData($event)" [cid]="loan.people_id">
                </app-customer-info>
                <app-microcreditresult *ngIf="lender?.PEOPLE_ID=='40245'" [summary]="microcredit" (close)="cancel()"></app-microcreditresult>
            </div>
            <div *ngIf="loan?.loan_status < 0">
                <app-requestlastactivity [loan]="loan"></app-requestlastactivity>
                <div *ngIf="loan?.is_rejected">
                    <app-rejectedrequest [loan]="loan"></app-rejectedrequest>
                </div>
                <div *ngIf="loan?.is_eligible=='0'">
                    <app-ineligiblerequest [loan]="loan" [request_id]="parentRouteId" [recommended]="loan?.recommended"
                        [eligibility]="loan?.result"></app-ineligiblerequest>
                </div>
            </div>

        </div>

        <div class="content" *ngIf="loan && view === 'timeline'">
            <app-applicationstimeline [loan]="loan" [view_from]="'drawer'" [requestId]="requestId"
                (openWorkFlow)="setChild('workflow', $event)"></app-applicationstimeline>
        </div>

        <div class="content" *ngIf="loan && view === 'links'">
            <div class="panel padding-regular-y">
                <div class="grid four mt-1 mb-1">
                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'avs'">
                        <div class="icon color-teal round-icon">
                            <i class="fas fa-credit-card"></i>
                            <span class="check" *ngIf="checklist?.avs"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">Address VS</div>
                    </div>

                    <!-- <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'analytics'">
                        <div class="icon color-purple round-icon">
                            <i class="fas fa-chart-line"></i>
                            <span class="check" *ngIf="checklist?.advanced_analytics"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">Advance Analytics</div>
                    </div> -->

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'bankstatement'">
                        <div class="icon color-blue round-icon">
                            <i class="fas fa-book"></i>
                        </div>
                        <div class="text mt-1">Bank statement</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'nanocreditbureau'">
                        <div class="icon color-yellow round-icon">
                            <i class="fas fa-cog"></i>
                            <span class="check" *ngIf="checklist?.blockchain"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">Nano Credit check</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'creditbureau'">
                        <div class="icon color-orange round-icon">
                            <i class="fas fa-external-link-square-alt"></i>
                            <span class="check" *ngIf="checklist?.creditbureau"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">Credit bureau</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'eligibility'">
                        <div class="icon color-red round-icon">
                            <i class="fas fa-user-check"></i>
                            <span class="check" *ngIf="checklist?.elibility"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">Eligibility</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'identity'">
                        <div class="icon color-green round-icon">
                            <i class="fas fa-address-card"></i>
                        </div>
                        <div class="text mt-1">Identity check</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'smsbank'">
                        <div class="icon color-green round-icon">
                            <i class="fas fa-address-card"></i>
                        </div>
                        <div class="text mt-1">SMS Bank Transaction</div>
                    </div>

                    <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'nin'">
                        <div class="icon color-green round-icon">
                            <i class="fas fa-address-card"></i>
                            <span class="check" *ngIf="checklist?.identity"><i class="fa fa-check"></i></span>
                        </div>
                        <div class="text mt-1">NIN</div>
                    </div>
                    <!-- <div class="icon-text medium cursor-pointer mb-3 mt-3" (click)="child = 'nin'">
                        <div class="icon color-green round-icon">
                            <i class="fas fa-address-card"></i>
                        </div>
                        <div class="text mt-1">IPPIS</div>
                    </div> -->


                    <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'loanspider'">
                        <div class="icon color-teal round-icon">
                            <i class="fas fa-user-lock"></i>
                        </div>
                        <div class="text">What we know</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="view === 'more' && more">

            <app-guarantorscover *ngIf="more === 'Guarantor'" [requestId]="requestId" [embedded]="true">
            </app-guarantorscover>

            <app-collateral *ngIf="more === 'Collateral'" [requestId]="requestId" [embeded]="true">
            </app-collateral>

            <app-recommendations *ngIf="more === 'Recommendation'" [requestId]="requestId" [embeded]="true">
            </app-recommendations>

            <app-applicationsofferletter *ngIf="more === 'Offer Letter'" [loan__]="loan" [requestId]="requestId"
                [embeded]="true">
            </app-applicationsofferletter>

            <app-applicationschecklist *ngIf="more === 'Checklist'" [loan]='loan' [where]="1"
                [parentRouteId]="requestId">
            </app-applicationschecklist>

            <app-recollectionoptions *ngIf="more === 'Recollection'" [requestId]="requestId" [loan]="loan"
                [embedModal]="true" (openModalChild)="setChild($event)">
            </app-recollectionoptions>

            <app-loanactions *ngIf="more === 'Loan Action'" [requestId]="requestId" [loan]="loan" [embedModal]="true"
                (openModalChild)="setChild($event)">
            </app-loanactions>

            <div class="panel" *ngIf="more === 'Account Officer'">
                <app-accountofficer [ownershipCustomer]="loan?.customer">
                </app-accountofficer>
            </div>

            <div class="panel" *ngIf="more === 'More'">
                <div class="grid four mt-4">
                    <div class="flex-1 flex-center mb-3" (click)="child = 'bvn'">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-blue round-icon"><i class="fas fa-fingerprint"></i></div>
                            <div class="text">BVN</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="child = 'account'">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-red round-icon"><i class="fas fa-building"></i></div>
                            <div class="text">Account</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="child = 'card'">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-blue round-icon"><i class="fas fa-credit-card"></i></div>
                            <div class="text">Card</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="child = 'attachment'">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-blue round-icon"><i class="fas fa-file"></i></div>
                            <div class="text">Attachment</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="child = 'loanlocation'" *ngIf="grantRight('26')">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-red round-icon"><i class="fas fa-map"></i></div>
                            <div class="text">Request Location</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Child Modals -->
        <div class="overlay" *ngIf="child">
            <button class="closer" (click)="child = ''"><i class="fa fa-times"></i></button>
            <div class="child overflow-visible">
                <app-requestingdata [customerdetail]="customerdetails" [loan]="loan" [priority]="'loan'"
                    [requestingData]="requesting_data" *ngIf="child === 'requesting-data'">
                </app-requestingdata>
                <app-applicationschecklist *ngIf="child === 'checklist'" [loan]='loan' [where]="1"
                    [parentRouteId]="requestId">
                </app-applicationschecklist>

                <div class="grid three mt-4" *ngIf="child === 'liste'">
                    <div class="flex-1 flex-center mb-3" (click)="sendSMSToCustomer(loan)">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-blue round-icon"><i class="fas fa-mobile"></i></div>
                            <div class="text">SMS</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="sendEmailToCustomer(loan)">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-red round-icon"><i class="fas fa-envelope"></i></div>
                            <div class="text">EMAIL</div>
                        </div>
                    </div>
                    <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
                        <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
                            <div class="icon color-blue round-icon"><i class="fas fa-whatsapp"></i></div>
                            <div class="text">WhatsApp</div>
                        </div>
                    </div>

                </div>

                <app-accountofficer *ngIf="child === 'account-officer'" [ownershipCustomer]="loan?.customer">
                </app-accountofficer>

                <app-assignaccountofficer *ngIf="child === 'assign-account-officer'"
                    [ownershipCustomer]="loan?.customer">
                </app-assignaccountofficer>

                <app-blockchain-analysis *ngIf="child === 'blockchain' && !isUnderConstruction('blockchain')">
                </app-blockchain-analysis>

                <app-under-contruction *ngIf="isUnderConstruction(child)"></app-under-contruction>

                <app-analyticshome [requestId]="requestId"
                    *ngIf="child === 'analytics' && !isUnderConstruction('analytics')" [showHeader]="false"
                    [embedModal]="true" (openRunAnalytics)="setChild('runanalytics', $event)"
                    (openMore)="setChild($event)">
                </app-analyticshome>

                <app-analyticsspiderservice *ngIf="child === 'loanspider' && !isUnderConstruction('loanspider')"
                    [parentRouteId]="requestId" [loan]="loan" token="currentUser.token" [showHeader]="false">
                </app-analyticsspiderservice>

                <div class="panel padding-regular" *ngIf="child === 'loanlocation'">
                    <app-loanlocation [parentRouteId]="requestId" [where]='2'>
                    </app-loanlocation>
                </div>

                <app-profile-picture *ngIf="child === 'profile-picture'" [loan]="loan" (reload)="loadRequest()">
                </app-profile-picture>

                <app-contractdetails *ngIf="child === 'contractdetails'" [loan]="loan" [request_id]="requestId"
                    [currentUser]="currentUser" [loading]="loading" [embedModal]="true">
                </app-contractdetails>

                <app-loanapprovals *ngIf="child === 'workflow'" [hide_header]="true" [parentRouteId]="requestId"
                    [where]='2' [loan]="loan">
                </app-loanapprovals>

                <app-disbursement *ngIf="child === 'disbursement'" [record_type]="1" [schedule_type]="1" [sub]="1"
                    [disburse]="disbursement.disburse" [security_question]="disbursement.security_question"
                    [newloan]="disbursement.loan">
                </app-disbursement>

                <app-identity-check *ngIf="child === 'identity'" [checklist]="checklist"></app-identity-check>

                <app-bank-statement-analyze *ngIf="child === 'bankstatement'" [reqid]="requestId"
                    (openMore)="setChild($event)">
                </app-bank-statement-analyze>



                <app-sms-transactions-analysis *ngIf="child === 'smsbank'" [reqid]="requestId"
                    (openMore)="setChild($event)">
                </app-sms-transactions-analysis>

                <app-ninbyphonenumber *ngIf="child === 'nin'" [reqid]="loan?.people_id" (openMore)="setChild($event)">
                </app-ninbyphonenumber>



                <app-locationdetails [requestId]="requestId" [customer_id]='loan.people_id' *ngIf="child === 'avs'"
                    from="drawer">
                </app-locationdetails>

                <app-creditregistry [from_where]='1' [request_id]="requestId" *ngIf="child === 'creditbureau'"
                    (showResultForMicroCredit)="showResultForMicroCredit($event)" (openMore)="setChild($event)">
                </app-creditregistry>

                <app-microcreditsummary *ngIf="child === 'nanocreditbureau'"
                    (openMoreResult)="showResultForMicroCredit($event)" (openMore)="setChild('microattachment')"
                    [reqid]="requestId" [from_where]="1"></app-microcreditsummary>

                <app-ippischecker [view]="ippischeckerview" [loan]="loan" [token]="currentUser.token" *ngIf="child === 'ippischecker'"
                    (openMoreResult)="showResultForMicroCredit($event)"></app-ippischecker>

                <app-repaymentschedule *ngIf="child === 'schedule'" [loan]='loan' [currentUser]="currentUser"
                    [request_id]="requestId">
                </app-repaymentschedule>

                <app-applicationseligibility [runstatus]="runstatus" *ngIf="child === 'eligibility'"
                    (analyseCustomerComplete)="showEligCrit($event)" [requestId]="requestId" [embedModal]="true"
                    (openProfileAnalysisModal)="setChild('profileanalysis')">
                </app-applicationseligibility>

                <div class="panel overflow-visible" *ngIf="child === 'eligibilitycriteria'">
                    <app-eligibilitycriteria [loan]="loan" (doEligibility)="doEligibility($event)"
                        (showBankStatement)="showBankStatement($event)" (showCreditBureau)="showCreditBureau($event)"
                        [eligibilitycriteria]="eligibilitycriteria" (openNIN)="child = 'nin'" (closed)="closeSMS()"
                        [parentRouteId]="requestId" [where]='2'></app-eligibilitycriteria>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'edit'">
                    <app-newloanform [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2'
                        [contraints]="false"
                        [contract_details]="{ currentUser: this.currentUser.token, request_id: requestId, loan: loan }"
                        (close)="child = ''">
                    </app-newloanform>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'create-contract'">
                    <app-newloanform [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2'
                        [contraints]="false"
                        [contract_details]="{ currentUser: this.currentUser.token, request_id: requestId, loan: loan }"
                        (close)="child = ''">
                    </app-newloanform>
                </div>
                <div class="panel padding-regular" *ngIf="child === 'create-partner-contract'">
                    <app-newloanform [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2'
                        [contraints]="false" [is_partner]='is_partner' 
                        [contract_details]="{ currentUser: this.currentUser.token, request_id: requestId, loan: loan }"
                        (close)="is_partner = false; child = ''">
                    </app-newloanform>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'runanalytics'">
                    <app-runselectedanalyticsmodal (closeSelectedAnalyticsModal)="overlayModalSection=''" [loan]="loan"
                        [parentRouteId]="requestId" [token]="currentUser.token"
                        [analytics_data_status]="analytics_data_status">
                    </app-runselectedanalyticsmodal>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'profileanalysis'">
                    <app-profileanalysismodal (closeSelectedAnalyticsModal)="overlayModalSection=''">
                    </app-profileanalysismodal>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'setup_cards'">
                    <app-carddebitsetup [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan">
                    </app-carddebitsetup>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'account_debit'">
                    <app-accountdebit [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan">
                    </app-accountdebit>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'nibbsdebit'">
                    <app-nibbsdebit [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan">
                    </app-nibbsdebit>
                </div>
                <div class="panel padding-regular" *ngIf="child === 'accountbalance'">
                    <app-accountbalance [reqid]="requestId"></app-accountbalance>
                </div>
                <div class="panel padding-regular" *ngIf="child === 'setup_cheques'">
                    <app-loancheque [parentRouteId]="requestId" [where]='2'></app-loancheque>
                </div>

                <div class="panel padding-regular" *ngIf="child === 'remita_inflight'">
                    <app-remitainflight [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan">
                    </app-remitainflight>
                </div>

                <div class="panel" *ngIf="child === 'newloanform'">
                    <app-newloanform
                        [contract_details]="{ currentUser: currentUser.token, request_id: requestId, loan: loan }"
                        [contraints]="false" [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2'
                        (close)="overlayOpen=false">
                    </app-newloanform>
                </div>

                <div class="panel overflow-visible" *ngIf="child === 'bvn'">
                    <app-bvnmodal [request_id]="requestId" [customer_id]="loan?.people_id" [token]="currentUser.token"
                        [viewOnly]="true"></app-bvnmodal>
                </div>

                <div class="panel overflow-visible" *ngIf="child === 'card'">
                    <app-cardmodal [request_id]="requestId" [token]="currentUser.token"></app-cardmodal>
                </div>

                <div class="panel overflow-visible" *ngIf="child === 'account'">
                    <app-accountmodal [request_id]="requestId" [token]="currentUser.token"></app-accountmodal>
                </div>

                <div class="panel overflow-visible" *ngIf="child === 'attachment'">
                    <app-loanphotos [parentRouteId]="requestId" [where]='2'></app-loanphotos>
                </div>

                <div class="panel overflow-visible" *ngIf="child === 'smsattachment'">
                    <app-initiatesmstransactionanalysis (closed)="closeSMS()" [parentRouteId]="requestId" [where]='2'>
                    </app-initiatesmstransactionanalysis>
                </div>
                <div class="panel overflow-visible" *ngIf="child === 'microattachment'">
                    <app-initiatesmstransactionanalysis (closed)="closeSMS()" [parentRouteId]="requestId" [where]='3'>
                    </app-initiatesmstransactionanalysis>
                </div>
                <div class="panel overflow-visible" *ngIf="child === 'sendbankstatementrequest'">
                    <app-initiatesmstransactionanalysis (openMore)="setChild($event)" (closed)="closeSMS()"
                        [parentRouteId]="requestId" [where]='4'></app-initiatesmstransactionanalysis>
                </div>
                <div class="panel overflow-visible" *ngIf="child === 'tokennization'">
                    <app-cardtokennization (openMore)="setChild($event)" (closed)="closeSMS()"
                        [parentRouteId]="requestId" [where]='4'> </app-cardtokennization>
                </div>
                <div class="panel overflow-visible" *ngIf="child === 'bankstatementsettings'">
                    <app-bankstatementsettings> </app-bankstatementsettings>
                </div>

                <div *ngIf="child === 'area-of-focus'">
                    <app-analysis-area-of-focus></app-analysis-area-of-focus>
                </div>
                <div *ngIf="child === 'micro-result'">
                    <app-microcreditresult [summary]="microcredit" (close)="cancel()"></app-microcreditresult>
                </div>

            </div>
        </div>
    </div>
</div>




<!-- Summary Modal -->
<!-- <div class="overlay" *ngIf="details">
            <button class="closer" (click)="toggleDetails()"><i class="fa fa-times"></i></button>
            <div class="dialog panel padding-regular">
                <ul class="decList bigger">
                    <li><span>Reason</span><span>{{ loan.loan_purpose }}</span></li>
                    <li><span>Interest rate</span><span>{{ loan.request_rate }}%</span></li>
                    <li><span>Total fees</span><span>{{ loan.total_fees_and_charges }}</span></li>
                    <li><span>1st repayment</span><span>{{ loan.repayment_started_when }}</span></li>
                    <li><span>Net disbursement</span><span>{{ loan.net_disbursement }}</span></li>
                    <li><span>Officer</span><span><img class="image-avatar" src="assets/images/user.png" /></span></li>
                </ul>
                <app-button class="outline small orange block mb-1 mt-2" label="Edit request"
                    (click)="details = false; child = 'edit'"></app-button>
                <app-button class="outline small block" label="Send offer letter"></app-button>
            </div>
        </div> -->