import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caryear'
})
export class CaryearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let res = [];
    for (let i = 2000; i <= value; i++) {
        res.push(i);
      }
      return res;
  }

}
