import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mobilesimulate',
  templateUrl: './mobilesimulate.component.html',
  styleUrls: ['./mobilesimulate.component.css']
})
export class MobilesimulateComponent implements OnInit {
  view = 'main_app';

  constructor() {
  }

  ngOnInit() {
  }

  simulate() {
    //console.log('simulate');
  }

  changeView() {
    this.view = 'stand_alone';
  }
}
