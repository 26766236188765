<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin ">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-xs-12 col-sm-3">
      <div class="sidebar sticky">
        <div class="sidebar-icon"><i class="fa fa-gear"></i></div>
        <div class="sidebar-title">{{viewTitle}}</div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-7">
      <div class="panel box edged_box overflow-visible">
        <div class="settings-heading">
          <h3>{{viewTitle}}</h3>
          <div class="dropdown">
            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenu1"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <i class="fa fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenu1">
              <li style="cursor: pointer" (click)="email_type='14';mailBodySection='3';location='welcome_email';viewTitle='Welcome Email'"><a>Welcome Email</a></li>
              <li style="cursor: pointer" (click)="email_type='18';mailBodySection='2';location='phone_otp';viewTitle='Phone OTP'"><a>Phone OTP</a></li>
              <li style="cursor: pointer" (click)="email_type='899';mailBodySection='2';location='reset_pin';viewTitle='Reset Pin'"><a>Reset Pin</a></li>
              <li style="cursor: pointer" (click)="email_type='6001';mailBodySection='4';location='direct_debit';viewTitle='Direct Debit Email'"><a>Direct Debit Email</a></li>
              <li  style="cursor: pointer" (click)="email_type='897';mailBodySection='4';location='validate_work';viewTitle='Validate Work'"><a>Validate Work</a></li>
              <li style="cursor: pointer" (click)="email_type='333';mailBodySection='4';location='loan_rejection';viewTitle='Validate Work'"><a>Loan Rejection Email</a></li>
              <li style="cursor: pointer" (click)="email_type='901';mailBodySection='4';location='payment_received';viewTitle='Payment Received'"><a>Payment Received</a></li>
              <li style="cursor: pointer" (click)="email_type='33';mailBodySection='4';location='borrower_received_email';viewTitle='Borrower Received Offer'"><a>Borrower Received Offer Email</a></li>
              <li style="cursor: pointer" (click)="email_type='30';mailBodySection='4';location='lender_make_offer';viewTitle='Lender Make Offer'"><a>Lender Make Offer</a></li>
              <li  style="cursor: pointer" (click)="email_type='31';mailBodySection='4';location='borrower_accepted';viewTitle='Borrower Accept Offer'"><a>Borrower Accepted Offer Email</a></li>
              <li style="cursor: pointer" (click)="email_type='222';mailBodySection='4';location='withdrawal_email';viewTitle='Withdrawal Email'"><a>Withdrawal Email</a></li>
              <li style="cursor: pointer" (click)="email_type='1234';mailBodySection='4';location='send_to_market';viewTitle='Send To Market'"><a>Send To Market</a></li>
              <li style="cursor: pointer" (click)="email_type='2001';mailBodySection='4';location='guarantor_email';viewTitle='Guarantor Request'"><a>Guarantor Request Email</a></li>
              <li style="cursor: pointer" (click)="email_type='93';mailBodySection='4';location='card_request_email';viewTitle='Card Rrequest'"><a>Card Request Email</a></li>
              <li style="cursor: pointer" (click)="email_type='91';mailBodySection='4';location='bank_account_confirmation';viewTitle='Bank Account'"><a>Bank Account Confirmation Email</a></li>
              <li style="cursor: pointer" (click)="email_type='335';mailBodySection='4';location='request_attachment';viewTitle='Request For Attachment'"><a>Request for Attachments</a></li>
              <li style="cursor: pointer" (click)="email_type='92';mailBodySection='4';location='bvn_verification';viewTitle='BVN Verification'"><a>BVN Verification Email</a></li>
              <li style="cursor: pointer" (click)="email_type='52';mailBodySection='4';location='loan_disbursed';viewTitle='Loan Disbursed'"><a>Loan Disbursed Email</a></li>
              <li style="cursor: pointer" (click)="email_type='80';mailBodySection='4';location='repayment_email';viewTitle='Repayment Reminder'"><a>Repayment Reminder Email</a></li>
              <li style="cursor: pointer" (click)="email_type='22';mailBodySection='4';location='due-reminder';viewTitle='Repayment Late'"><a>Loan Lateness Email</a></li>
            </ul>
          </div>
        </div>
        <app-templatedesign [location]="location" [email_type]="email_type" [mailBodySection]="mailBodySection"></app-templatedesign>
      
      </div>

    </div>
  </div>

  <div class="flex" *ngIf="loadingRouteConfig">
    <div class="col-md-9 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>