<div class="login-body">
  <div class="container-fluid">
    <div class="login-wrap">
      <div class="left">
        <div class="logo-wrap">
          <img src="assets/images/cc-logo-md.png" alt="Creditclan's Logo">
        </div>
        <div class="img-wrap">
          <img src="assets/images/analytics.svg" alt="Illustration">
        </div>
        <p class="welcome-text">Monitor your operations seamlessly with Creditclan</p>
      </div>
      <div class="right">
        <form class="login-form" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
          <div class="title">
            <h3>Sign in</h3>
            <!-- <p>Sign in with your email address and password, If you already have an account with us. or
              <a data-toggle="modal" [routerLink]="['/welcome']" class="link"> Sign Up</a> here if you dont</p> -->
          </div>
          <input #email="ngModel" autocomplete="false" [(ngModel)]="model.email" [disabled]="loading" class="form-control custom"
            name="email" placeholder="Email" required type="email" autofocus />
          <input #password="ngModel" autocomplete="false" [(ngModel)]="model.password" [disabled]="loading" class="form-control custom"
            name="password" placeholder="Password" required type="password" />
          <app-button [disabled]="loading" type="submit" label="Sign in" [loading]="loading"></app-button>
          <div class="flex flex-row justify-between">
            <div class="text-right mb-3">Don't have an account? <a [routerLink]="['/welcome']" class="link">
                Sign up</a>
            </div>
            <div class="text-right mb-3"><a [routerLink]="['/forgot']" class="link grey">Forgot Password?</a></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>