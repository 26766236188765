import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-backendsimulate',
  templateUrl: './backendsimulate.component.html',
  styleUrls: ['./backendsimulate.component.css']
})
export class BackendsimulateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  simulate() {
    //console.log('simulate');
  }
}
