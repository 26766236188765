  <div class="padding-regular overpanel-scroll">
    <div class="flex">
      <div class="section-poster">
        <div class="image flex-center">
          <img src="assets/images/share.svg" alt="">
        </div>
      </div>
      <div class="profile_area ml-3">
        <section>
          <div class="decList bigger">
            <form #interestBandForm="ngForm" novalidate>
              <div class="form-group">
                <label class="control-label normal">Name</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="request.name">
              </div>
              <div class="form-group">
                <label class="control-label normal">Email</label>
                <input type="email" class="form-control" name="name" [(ngModel)]="request.email">
              </div>
              <div class="form-group">
                <input type="checkbox" name="send_to_whatsapp" [(ngModel)]="request.send_to_whatsapp"> 
                <span class="control-label normal">Send Through Whatsapp</span>
              </div>
              <div class="form-group" *ngIf="request.send_to_whatsapp">
                <span class="control-label normal">Your Whatsapp Number</span>
                <input name="whatsapp_number" [(ngModel)]="request.whatsapp_number" type="text" class="form-control">
              </div>
              <div class="form-group" *ngIf="request.send_to_whatsapp">
                <span class="control-label normal">Your Whatsapp Message</span>
                <textarea name="whatsapp_message" [(ngModel)]="request.whatsapp_message" type="text" class="form-control"></textarea>
              </div>
              <div class="text-left mt-4">
                <app-button (click)="sendInvite()" [disabled]="loading" class="medium block" [loading]="loading" label="Send Invite"></app-button>
                <app-button (click)="sendMultiple()" [disabled]="loading" class="mt-1 block medium" [loading]="loading" label="Invite More"></app-button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
