import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AppConfig } from '../app.config';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading = false;
  error = '';

  constructor(public router: Router,
    public authenticationService: AuthenticationService,
    private toastr: ToastrService) {

  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
  }
  login() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password)
      .subscribe((response: any) => {
        if(response.changepassword == true){
          return this.router.navigate(['/password']);
        }
        if(response.company_id == "40245"){
          return this.router.navigate(['/twofa']);
        }
        if (response.landing) {
          switch (response.landing) {
            case 'request':
              return this.router.navigate(['/applications/dashboard']);
            case '2':
              return this.router.navigate(['/applications/dashboard']);
            case '1':
              return this.router.navigate(['/crm/customers']);
            case 'portfolio':
              return this.router.navigate(['/portfolio/dashboard']);
            case '3':
              return this.router.navigate(['/portfolio/dashboard']);
            case '5':
              return this.router.navigate(['/reports']);
            case '6':
              return this.router.navigate(['/settings']);
            case 'customers':
              return this.router.navigate(['/crm/customers']);
            case 'operations':
              return this.router.navigate(['/operations/queue']);
            case '4':
              return this.router.navigate(['/operations/queue']);
            case 'products':
              return this.router.navigate(['/loanproducts']);
            case '7':
              return this.router.navigate(['/loanproducts']);
            case 'branches':
              return this.router.navigate(['/operations/branches']);
            case '8':
              return this.router.navigate(['/operations/branches']);
            case 'customer-clusters':
              return this.router.navigate(['/serviced/companies']);
            case '9':
              return this.router.navigate(['/serviced/companies']);
            case '17':
              return this.router.navigate(['/home/summary']);
            default:
              return;
          }
        } else {
          this.showError('Unable to login');
          this.loading = false;
        }
      }, err => {
        this.loading = false;
      });
  }
}
