import { ElementRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService, DataService, OperationsService } from '../../_services/index';
import { CustomerService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';
import { LoancontractComponent } from '../../loancontract/loancontract.component';

@Component({
  selector: 'app-loanaction',
  templateUrl: './loanaction.component.html',
  styleUrls: ['./loanaction.component.css']
})
export class LoanactionComponent implements OnChanges, OnInit {
  eliloading = false;
  @Output() doingDisbursement = new EventEmitter();
  @Output() createPRequest = new EventEmitter();
  @Output() approveThisLoan = new EventEmitter();
  @Output() rejectThisLoan = new EventEmitter();
  @Output() pushToMarket = new EventEmitter();
  @Input('request_id') request_id: any;
  @Input('view') view: any = 'request';
  @Input('loan') loan: any;
  @Input('loan_') loan_: any;
  @Input() inlineButtons = false;
  request_id_: any;
  canViewModule = false;
  PASSWORD = '';
  account_for_direct_debit: any;
  adjustingWeights = false;
  NOTIFY_ALL_LENDERS = false;
  islarger = false;
  enable_peer = '0';
  prev: any;
  next: any;
  public is_done = '0';
  public parentRouteId: number;
  public loading = false;
  public sub: any;
  public currentUser: any;
  public canDoApproval: false;
  public approvals_queue: any;
  public open_approval = false;
  public approving = false;
  public rejecting = false;
  public level: any;
  public prev_levels: any;
  public model_r = { ilo: 0, reject_reason: '', reject_action: '', approval_notes: '', reject_level: '', wtd: 0, request_id: '', level: '' };
  public model_a = { chk_acts: [], past_one: 1, approval_all_waivers: 1, approval_all_checklist: 1, is_waiver_level: 0, has_waiver: 0, ilo: 0, istd: 0, approval_notes: '', wtd: 1, request_id: '', level: '' };
  public editContract = false;
  public IS_PEER_TO_PEER: any;
  public ADDED_TO_PAYMENT_QUEUE: any;
  loan_approvals = 0;
  public overlayOpen = false;
  queue_disbursement = '0';
  pay_from_loan = '0';
  break_loan = false;
  public applyMethod = { '1': 'USSD', '2': 'Mobile', '3': 'Back Office', '4': 'Back Office' };
  viewing_loan = false;
  childModal = { location: '', data: {} };
  loan_viewed: any;
  view_state = '0';
  dontshownext: any;
  overlayType = '0';
  kyc_request = {
    KYC_COMMENTS: '',
    KYC_TYPE_ONE: false,
    KYC_TYPE_TWO: false,
    KYC_PACKAGE: ''
  }
  financials_request = {
    HOW_MANY_MONTHS: '',
    BANK_STATEMENTS: false,
    PAYSLIPS: false
  }
  guarantors_request = {
    HOW_MANY_GUARANTORS: '',
  }
  pickup = {
    KYC_COMMENTS: '',
    PICKUP_ADDRESS_TYPE: '1',
    CUSTOM_PICKUP_ADDRESS: '',
    PICKUP_PACKAGE: ''
  }
  rejection = {
    MESSAGE: '',
    SEND_REJECTION_MAIL: false
  }
  reopen = {
    MESSAGE: '',
    SEND_REOPEN_MAIL: false,
    SUSPEND_CUSTOMER: false,
    SUSPEND_FOR_HOW_LONG: '24'
  }
  doctypes: any;
  dbAnalysisScore = {
    profile: 0,
    address: 0,
    income: 0,
    work: 0,
    guarantor: 0,
    account: 0,
    education: 0,
    call_log: 0,
    linkedln: 0
  };
  disburse: any;
  overlayOpenPay = false;
  overlayOpenPayConfirm = false;
  security_question = '';
  canDisburse = false;
  analysisModalView: any = 'calculate';
  sub_profile_analysis: any;
  sub_address_analysis: any;
  sub_income_analysis: any;
  sub_work_analysis: any;
  sub_guarantor_analysis: any;
  sub_account_analysis: any;
  sub_education_analysis: any;
  sub_call_log_analysis: any;
  sub_linkedln_analysis: any;
  sumAnalytics: any = 0;
  sumDetailsScore: any = 0;
  fqScore: any;
  socialAnalysis = true;
  magic_filter: any = {
    profile: 0,
    address: 0,
    income: 0,
    work: 0,
    guarantor: 0,
    account: 0,
    education: 0,
    call_log: 0,
    linkedln: 0
  };
  banks: any;
  changingofficer = false;
  lender:any;
  loading_statement=false
  statement_message = '';
  constructor(private toastr: ToastrService, private _elementRef: ElementRef,
    private authService: AuthenticationService,
    public operationsService: OperationsService, private DataService: DataService,
    public router: Router, public route: ActivatedRoute, public loansService: LoansService, public storageService: StorageService) {
    // if (!this.authService.canViewModule('1,2,3,1026')) {
    //   this.router.navigate(['../unauthorized']);
    // }
    this.enable_peer = this.storageService.read<any>('enable_peer_to_peer');
    this.DataService.refreshPage.subscribe(res => {
      this.getApp(this.request_id_);
    })
    this.lender = this.storageService.read<any>('platform');
  }
  ngOnInit() {

  }
  confirmRejectRequest() {
    //   this.DataService.rejectingRequest.emit({ 'open_approval': true, 'rejecting': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_  });
    // this.rejectThisLoan.emit({ 'open_approval': true, 'approving': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_ });
    this.rejectThisLoan.emit({ 'open_approval': true, 'rejecting': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_ });
  }
  approveRequest() {

    //    this.DataService.approvingRequest.emit({ 'open_approval': true, 'approving': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_ });
    this.approveThisLoan.emit({ 'open_approval': true, 'approving': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_ });

  }
  payBorrowerFromLoan() {
    this.loading = true;
    this.loansService.queueForDisbursement(this.currentUser.token, this.request_id_)
      .subscribe(loans => {
        this.loading = false;
        if (loans.status) {
          this.disburse = loans.queue.result[0];
          this.doingDisbursement.emit({ disburse: this.disburse, result: loans, loan: this.loan, security_question: this.security_question });
        } else {
          if (loans.action == 'Dismiss') {
            this.showError(loans.message)
            this.closeApproving();
          } else {
            this.showError(loans.message);
          }
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.request_id;
    if (request_id.currentValue) {
      this.request_id_ = request_id.currentValue;
      this.getApp(request_id.currentValue)
      this.pickup.PICKUP_ADDRESS_TYPE = '1';
      if (this.authService.canViewModule('3')) {
        this.canDisburse = true;
      }
    }

  }
  getSecurityQuestion() {
    this.operationsService.getSecurityQuestion(this.currentUser.token)
      .subscribe(data => {

        this.security_question = data.security_question.QUESTION;
      });
  }
  getApp(request_id) {
    this.currentUser = this.storageService.read<any>('currentUser');

    this.loansService.getRequestApprovalQueue(this.currentUser.token, request_id)
      .subscribe(loans => {
        this.security_question = loans.security_question.QUESTION;
        this.canDoApproval = loans.status;
        this.approvals_queue = loans.approvals_queue;
        this.level = loans.level;
        this.prev_levels = loans.prev_levels;
        if (loans.queue == '1') {
          this.model_r.request_id = loans.loan.REQUEST_ID;
          this.model_r.level = this.approvals_queue.LEVEL_ID;
          this.model_a.request_id = this.approvals_queue.REQUEST_ID;
          this.model_a.level = this.approvals_queue.LEVEL_ID;
          this.model_a.ilo = this.approvals_queue.ILO;
          this.model_a.istd = this.approvals_queue.ISTD;
        }
        this.IS_PEER_TO_PEER = loans.loan.IS_PEER_TO_PEER;
        this.ADDED_TO_PAYMENT_QUEUE = loans.loan.ADDED_TO_PAYMENT_QUEUE;
        //this.loan_approvals = loans.loan_approvals_count;
        this.queue_disbursement = loans.queue_disbursement;
        this.pay_from_loan = loans.pay_from_loan;
        this.loan = loans.loan;
        //this.prev = loans.prev;
        //this.next = loans.next;
        this.DataService.onLoadPreviousLoad.emit({ loan: loans.loan });
      });

  }
  doCheckWalletTransactionStatus() {
    //queue for disburse then pay
    this.loading = true;
    this.loansService.queueForDisbursement(this.currentUser.token, this.loan.REQUEST_ID)
      .subscribe(loans => {
        this.loading = false;
        if (loans.status) {
          //good to go
          this.disburse = loans.queue.result[0];
          //this.overlayOpenPayConfirm = true;
          this.DataService.doDisbursementConfirmation.emit({ disburse: this.disburse });
        }
      });
  }

  // confirmReopenRequest() {
  //   this.DataService.launchReopenRequestModal.emit({ location: 'confirm_reopen_request', data: this.loan });
  //   //this.childModal = { location: 'confirm_reopen_request', data: this.loan };
    
  // }
  confirmReopenRequest(){
    this.DataService.onOpenApplicationsModal.emit({ section: 'confirm_reopen_request', request_id: this.loan.request_id, loan: this.loan });
  }
  createPartnerRequest(){
    //this.DataService.openPartnerForm.emit({ request_id: this.loan.request_id, loan: this.loan });
    this.createPRequest.emit();
  }
  closeChildModal() {
    this.childModal.location = '';
    //this.router.navigate['../../requests/pending'];
  }
  confirmedRejectRequest() {
    this.closeChildModal();
    this.rejectRequest();
  }
  confirmedReopenRequest() {
    this.closeChildModal();
    this.reopenRequest();
  }
  reopenRequest() {

    this.loading = true;
    this.loansService.reopenRequest(this.currentUser.token, this.loan.REQUEST_ID, this.reopenRequest)
      .subscribe(loans => {
        this.closeApproving();
        this.loading = false;
        this.getApp(this.request_id_);
        this.DataService.refreshPage.emit();
      });
  }
  closeApproving() {
    this.open_approval = false;
    this.rejecting = false;
    this.approving = false;
  }
  rejectRequest() {
    this.open_approval = true;
    this.rejecting = true;
  }
  rejectThisRequest(isValid: boolean, f: any) {

    this.loading = true;
    this.model_r.request_id = this.loan.REQUEST_ID;
    this.model_r.level = this.loan.APPROVAL_LEVEL;
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.loansService.rejectThisRequest(this.currentUser.token, this.model_r)
        .subscribe(loans => {
          this.closeApproving();
          this.loading = false;
          this.getApp(this.request_id_);
          this.DataService.refreshPage.emit();
        });
    });
  }
  approveThisRequest(isValid: boolean, f: any) {
    this.loading = true;
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];

      this.loansService.approveThisRequest(this.currentUser.token, this.model_a)
        .subscribe(loans => {
          this.loading = false;
          if (loans.status) {
            this.closeApproving();
            this.loading = false;
            this.getApp(this.request_id_);

            this.DataService.refreshPage.emit();
          } else {
            if (loans.message) {
              this.showError(loans.message);
            }

            this.getApp(this.request_id_);

            this.closeApproving();
          }
        });
    });
  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  queueForDisbursement() {
    this.DataService.queuefordisbnpl.emit({ request_id: this.request_id_, loan: this.loan });
    // this.loading = true;
    // this.loansService.queueForDisbursement(this.currentUser.token, this.request_id_)
    //   .subscribe(loans => {
    //     this.loading = false;
    //     if (loans.status) {
    //       this.getApp(this.request_id_)
    //       this.closeApproving();
    //       this.loading = false;
    //       this.ADDED_TO_PAYMENT_QUEUE = loans.ADDED_TO_PAYMENT_QUEUE;
    //       this.showSuccess('Added to payment queue')
    //     } else {


    //       if (loans.action == 'Dismiss') {
    //         this.showError(loans.message);
    //         this.closeApproving();
    //       } else {
    //         this.showError(loans.message);
    //       }
    //     }
    //   });
  }
  showProfilePic(pic) {
    this.DataService.showProfilePic.emit({ pic: pic });
  }
  pushToPartners(){
    this.pushToMarket.emit({ 'open_approval': true, 'approving': true, 'level': this.level, 'prev_levels': this.prev_levels, 'loan': this.loan_ });
  }
  rerunEligibility(){
    this.eliloading = true;
    this.loansService.rerunEligibilitys(this.currentUser.token, this.request_id_)
      .subscribe(loans => {
        this.eliloading = false;
        if (loans.status) {
           
        } else {

 
        }
    });
  }
  initiatestatement(){
    this.statement_message = '';
    this.loading_statement = true
    this.loansService.initiatestatement(this.currentUser.token, this.request_id_)
      .subscribe(loans => {
        this.loading_statement = false;
        if (loans[1].status == true) {
          this.showSuccess("Statement pulled successfully")
        } else {
          this.showError("Unable to get statement ")
          this.statement_message = loans[1].message.message
 
        }
    });
  }
  queueForPartners(){
    // this.loansService.queueForPartners(this.currentUser.token, this.request_id_)
    //   .subscribe(loans => {
    //     this.loading = false;
    //     if (loans.status) {
           
    //     } else {

 
    //     }
    // });
    this.DataService.queueForPartners.emit({ request_id: this.request_id_ });
  }
  unqueueForPartners(){
    // this.loansService.queueForPartners(this.currentUser.token, this.request_id_)
    //   .subscribe(loans => {
    //     this.loading = false;
    //     if (loans.status) {
           
    //     } else {

 
    //     }
    // });
    this.DataService.unqueueForPartners.emit({ request_id: this.request_id_ });
  }
  moveToPortfolio(){
    this.loading = true
    this.loansService.moveToPortfolio(this.currentUser.token, this.request_id_)
      .subscribe(result => {
        this.loading = false;
        if (result.status == true) {
          this.showSuccess("Request successfully")
        } else {
          this.showError("Unable to get statement ")
          
 
        }
    });
  }
}
