<div>
  <div class="panel mb-1 overflow-visible">
    <div class="padding-regular">
      <div class="small-menu no-padding">
        <h4 class="section-title flex flex-row align-center"><span>Welcome</span>
        </h4>
        <ul class="list-inline">
          <li><button (click)="openProfile()">Customers</button></li>
          <li><button (click)="openAPp()">Approvals</button></li>
          <li><button (click)="openRequests()">Requests</button></li>
          <li><button (click)="openPortfolio()">Portfolio</button></li>
          <li><button (click)="openNotifications()">Notifications</button></li>
          <li><button (click)="openTasks()">Tasks</button></li>
        </ul>
      </div>
    </div>
  </div>
</div>