<div class="pop_nav">
    <ul class="list-inline text-right">
        <li><a (click)="viewing='default'" [ngClass]="{'active': viewing=='default'}"> Loan Terms</a></li>
        <li><a (click)="viewing='work_info'" [ngClass]="{'active': viewing=='work_info'}"> Approvals</a></li> 
    </ul>
</div>

<div class="address_info" *ngIf="viewing=='default'">

    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary">Loan Terms</span>
            <span class="pull-right">
                {{loan?.LOAN_ID}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="">
        <div class="form-group">
            <span class="pull-left text-primary">Required Credit</span>
            <span class="pull-right">
                {{loan?.REQUEST_PRINCIPAL | number:'.2-2'}}
            </span>
            <div class="clearfix"></div>
        </div>
        <div class="form-group">
            <span class="pull-left text-primary">Duration</span>
            <span class="pull-right">
                {{loan?.REQUEST_TENOR}} {{loan?.LOAN_DURATION}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div> 
        <div class="form-group">
            <span class="pull-left text-primary">Total Interest</span>
            <span class="pull-right">
                {{loan?.TOTAL_INTERESTS | number:'.2-2'}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary ">Total Repayment</span>
            <span class="pull-right">
                {{loan?.TOTAL_REPAYMENT | number:'.2-2'}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>


    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary ">Loan Purpose</span>
            <span class="pull-right">
                {{loanPurpose[loan?.LOAN_PURPOSE_ID]}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary ">Request Time</span>
            <span class="pull-right">
                {{loan?.DATE_ADDED | amTimeAgo}}
            </span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary ">Time to Expire</span>
            <span class="pull-right">{{loan?.EXPIRATION_DATE | amTimeAgo}}</span>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
        <div class="form-group">
            <span class="pull-left text-primary ">Mode of Application</span>
            <span class="pull-right">{{applyMethod[loan?.WHERE_FROM]}}</span>
            <div class="clearfix"></div>
        </div>
    </div>
    
</div>

<div class="address_info" *ngIf="viewing=='work_info'">
    <div  *ngIf="!open_approval" >
            <div *ngFor="let approval of loanapprovals">
                    <!-- Approved -->
                    <div *ngIf="approval?.IS_APPROVED=='1'">
                        <div class="hover_toggle_desc apprv">
                            <div class="pull-left" style="margin-right: 15px">
                                <img class="img-circle" width="25" src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{approval?.WHO_APPROVED_FILE | imagenotfound}}" style="width: 25px; height: 25px">
                            </div>
                            <div class="pull-left">
                                <p style="line-height: 1.2; margin-top: 3px; letter-spacing: 0.4px;">
                                  <span>{{approval?.LEVEL_TITLE}}</span><br>
                                  <span>Approved by {{approval?.WHO_APPROVED}}</span><br>
                                  <span>{{approval?.TIME_APPROVED | amTimeAgo}}</span>
            
                                </p>
                            </div>
                            <div class="pull-right">
                                <a class="pull-right btn btn-white wd_btn btn-orange edged_box hover_show" style=" color: #fff; margin-top: 10px" (click)="openNotes(approval?.LOAN_APPROVAL_ID)">View</a>
                            </div>
                        </div>
                        <div class="apprv_details" *ngIf="notesOpen==approval?.LOAN_APPROVAL_ID">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="pull-left">
                                        <p class="txt t_l2 t_lh1" style="font-size: 28px">{{approval?.TIME_APPROVED | amDifference: approval?.TIME_REQUESTED :'minutes' : true}} minutes</p>
                                        <p class="txt t_dull">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ul class="label-value">
                                        <li class="text-left ">
                                            <span class="t_cool"> Request Time</span>
                                            <p class="pull-right">{{approval?.TIME_REQUESTED | amDateFormat:'LL'}}</p>
                                        </li>                                          
                                        <li class="text-left ">
                                            <span class="t_cool"> Approval Time</span>
                                            <p class="pull-right">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</p>
                                        </li>
                                        <li class="text-left ">
                                            <span class="t_cool"> Approval By</span>
                                            <p class="pull-right">{{approval?.WHO_APPROVED}}</p>
                                        </li>
                                        <li class="text-left ">
                                              <span class="t_cool"> Notes</span>
                                              <p class="pull-right">{{approval?.APPROVAL_NOTES}}</p>
                                          </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
            
            
                    <!-- Que -->
                    <div class="apprv" style="margin-top: 15px;" *ngIf="approval?.IS_APPROVED!='1' && approval?.IS_SKIPPED!='1'">
                        <div class="row">
                            <div class="col-md-6">
                                <p style="line-height: 1.2; letter-spacing: 0.4px;">
                                     
                                  <span>{{approval?.LEVEL_TITLE}}</span><br>
                                  <span>{{approval?.TIME_REQUESTED | amTimeAgo | dateinvalid}}</span>
                                </p>
                            </div>
                            <div class="col-md-6 text-right">
                                <ul class="list-inline">
                                    <li *ngFor="let executor of approval?.EXECUTORS">
                                        <a>
                                            <img class="img-circle" title="{{executor?.LEGAL_NAME}}" src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{executor?.FILE_NAME | imagenotfound}}" style="width: 25px; height: 25px">
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
    
    <section class="panel" *ngIf="open_approval && approving">
            
            <div class="panel-body" style="padding:15px 0px">
                <form #f="ngForm" novalidate>
                    <ul class="frm v-wel vcc animated">
                        <li class="sub details animated">
                            <div class="">
                                <h4>Review your position on the request</h4>
                                <div style="">
                                    <div class="form-group row">

                                        <div class="col-md-12">
                                            <label for="" class="pull-left control-label">Notes</label>
                                            <textarea name="approval_notes" class="form-control" [(ngModel)]="model_a.approval_notes" approval_notes></textarea>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row" *ngIf="hasError">

                                            <div class="col-md-12">
                                                <p  class="error">{{errorMessage}}</p>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button class="btn btn-block btn-white" [disabled]="loading" (click)="approveThisRequest(f.value, f.valid)">
                                                Approve
                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <button (click)="closeApproving()" class="btn btn-block btn-white">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </section>
        <section class="panel" *ngIf="open_approval && rejecting">
            <header class="panel-heading">
                Reject Request
            </header>
            <div class="panel-body" style="padding:10px 0px">
                <form #f="ngForm" novalidate>
                    <div class="form-group row">
                        <label class="control-label col-md-6">Reason</label>
                        <div class="col-md-6">
                            <select class="form-control" required="" name="reject_reason" [(ngModel)]="model_r.reject_reason">
                                <option value="0">select</option>
                                <option value="1">Irreconciliable Documents</option>
                                <option value="2">Unverifyable Documents</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="" class="control-label col-md-6">Reject Action</label>
                        <div class="col-md-6">
                            <select name="reject_action" required="" class="form-control" [(ngModel)]="model_r.reject_action">

                                <option value="0">select</option>
                                <option value="1">Return to level</option>
                                <option value="2">Move to rejected requests</option>
                            </select>
                        </div>

                    </div>
                    <div class="form-group row animated" *ngIf="model_r.reject_action=='1'">
                        <label for="" class="control-label col-md-6">Return Level</label>
                        <div class="col-md-6">
                            <select name="reject_level" required class="form-control" [(ngModel)]="model_r.reject_level">

                                <option *ngFor="let l of prev_levels" [value]="l.LEVEL_ID">
                                    {{l.LEVEL_NAME}}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label for="" class="col-md-6">Notes</label>
                        <div class="col-md-6">
                            <textarea name="approval_notes" class="form-control" [(ngModel)]="model_r.approval_notes" approval_notes></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-block btn-white" [disabled]="loading" (click)="rejectThisRequest(f.value, f.valid)">
                                <i class="fa fa-save" *ngIf="!loading"></i><i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Reject</button>
                        </div>
                        <div class="col-md-6">
                            <button (click)="closeApproving()" class="btn btn-block btn-white">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    <div *ngIf="!open_approval">
        <section class="panel" *ngIf="queue_disbursement=='2'&&loan?.IS_CANCELLED=='0'">
            <div class="">
              <div class="row">
                <div class="col-md-6">
                    <a class="btn btn-white btn-block" (click)="approveRequest()">
                        Approve
                    </a>
                    
                </div>
                <div class="col-md-6">
                    <a class="btn btn-white btn-block" (click)="confirmRejectRequest()">
                        Reject
                    </a>
                </div>
              </div>
            </div>
        </section> 
    </div>
</div>