<div class="panel box edged_box mb_10" *ngIf="customer?.remita?.has_remita">
  <div class="panel-body padding-regular">
      <div class="flex">
          <div class="flex-3 mr-4">
              <ul class="decList bigger">
                  <li><span class="title">Remita Information</span></li>
                  <li><span>Remita</span>
                      <span>{{customer?.remita?.data?.CUSTOMER_ID || 'Not provided'}}</span></li>
                  <li><span>Name</span>
                      <span>{{customer?.remita?.data?.CUSTOMER_NAME || 'Not provided'}}</span></li>
                  <li><span>Account Number</span>
                      <span>{{customer?.remita?.data?.ACCOUNT_NUMBER || 'Not provided'}}</span></li>
                  <li><span>Bank</span>
                      <span>{{customer?.remita?.data?.bank_name || 'Not provided'}}</span></li>
                  <li><span>Company</span>
                      <span>{{customer?.remita?.data?.COMPANY_NAME || 'Not provided'}}</span></li>
              </ul>
          </div>
          <div class="section-poster flex-center flex-column">
              <div class="image flex-center">
                  <img src="assets/images/remita.svg">
              </div>
          </div>
      </div>
      <h4 class="section-title mb-3 mt-5">Salary History</h4>
      <ul class="decList bigger">
          <li class="title">
              <span class="color-blue">Date</span>
              <span class="color-blue">Amount</span>
          </li>
          <li *ngFor="let sal of customer?.remita?.data?.SALARY_PAYMENT_DETAILS">
              <span class="color-grey">{{sal.paymentDate}}</span>
              <span>{{sal.amount|number:'.2-2'}}</span>
          </li>
      </ul>
  </div>
</div>