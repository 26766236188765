import { ElementRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService, DataService, OperationsService } from '../../_services/index';
import { CustomerService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-repaymentflow',
  templateUrl: './repaymentflow.component.html',
  styleUrls: ['./repaymentflow.component.css', 'style.css', 'boot-outline-btn.css']
})
export class RepaymentflowComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input('token') token: any;
  @Input() fromModal = true;
  step = 'home';
  loading = false;
  view_state = '7';
  dontshownext = '1';
  loan: any;
  currentUser: any;
  data: any;
  public pay_opt = [
    { value: '1' },
    { value: '2' },
    { value: '3' }
  ]
  amountChosen = false;
  amount = '0';
  state: any;
  @Output() doingDisbursement = new EventEmitter();
  payment = { PAY_WITH: '0', LENDER_ACCOUNT_ID: 0, REQUEST_ID: 0, PAYMENT_DATE: '', AMOUNT_TO_PAY: '0', PAYMENT_OPTION: '1' };
  newpayment: any;
  constructor(private toastr: ToastrService, private _elementRef: ElementRef,
    private authService: AuthenticationService,
    public operationsService: OperationsService, private dataService: DataService,
    public router: Router, public route: ActivatedRoute, public loansService: LoansService, public storageService: StorageService) {
    // if (!this.authService.canViewModule('1,2,3,1026')) {
    //   this.router.navigate(['../unauthorized']);
    // }
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    //this.getLoanStatement();
  }

  doPayDue() {
    this.doingDisbursement.emit({})
  }
  gotochooseoption() {
    this.step = 'chooseoption';
    this.amountChosen = true;
  }
  openForFinalPayment(event) {
    this.step = 'chooseoption';
    this.newpayment = event;
  }
  chosenPayment(event) {
    this.step = event.payment_type;
  }
}
