<div class="avatar-preview" role="document">
  <img
    src="{{loan.customer.profile_pic | imagenotfound}}"
    alt="customer's profile" />
  <button class="change-picture" (click)="changeProfilePic()" *ngIf="!loading">
    <i class="fas fa-pen mr-2"></i>
    Change Picture
  </button>
  <button class="change-picture" *ngIf="loading">
    <i class="fas fa-sync-alt fa-spin mr-2"></i>
    Uploading
  </button>
  <input class="file-upload" (change)="fileChangeEvent($event)" name="logo" type="file" #fileInput accept="image/*" />
</div>