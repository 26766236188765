<div class="panel padding-regular">
  <div class="h-group padded center flex-center flex-column">
    <div class="circle large red filled flex-center mb-5"><i class="fa fa-times"></i></div>
    <h3 class="title">Request Rejected</h3>
    <p class="subtitle mt-1">This request has been rejected</p>
    <div class="text-center mt-4">
      <app-button (click)="confirmReopenRequest()" class="small red" label="Reopen"></app-button>
    </div>

    <div class="inline-block top">
      <div class="decRoundImage cursor-pointer" (click)="showProfilePic(loan?.customer?.profile_pic)" style="    min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;
      margin: 10px auto;">
        <img
          src="{{loan?.customer?.profile_pic | imagenotfound}}">
      </div> 
    </div>
    <h3 class="title">Rejected By {{loan?.rejected_by}}</h3>
    <p class="subtitle mt-1">{{loan?.rejection_date}}</p> 
  </div>
</div>
<app-timeline [request_id]="loan?.request_id" [where]='2'></app-timeline>
