<div [ngClass]="{'overpanel-scroll': !hideheader}">
  <section *ngIf="!isdelete && !isremita">
    <div *ngIf="showForm" [ngClass]="{'padding-regular': !hideheader}">
      <h3 *ngIf="!hideheader" class="section-title mb-4">New Bank</h3>
      <div *ngIf="confirming">
        <div class="form-area animated">
          <form role="form animated">
            <div class="form-group row" *ngIf="showTestTransfer">

              <label class="control-label col-md-6">Use for Ebills</label>
              <div class="col-md-6">
                <input type="checkbox" (ngModelChange)="checkIfEbillsAlreadyExists($event)" name="IS_FOR_EBILLS"
                  id="IS_FOR_EBILLS" [(ngModel)]="bank.IS_FOR_EBILLS">
              </div>
              <p *ngIf="has_ebills_error">{{ebills_error}}</p>
            </div>
            <div class="form-group" *ngIf="from_where == '2'">
              <label class="control-label normal">Choose an option</label>
              <select [(ngModel)]="choose_an_option" class="form-control" name="choose_an_option" id="choose_an_option">
                <option value="0"></option>
                <option [value]="1">select from your list of accounts</option>
                <option [value]="2">add new account</option>
              </select>
            </div>
            <div *ngIf="choose_an_option == '1'">
              <div class="form-group">
                <label class="control-label normal">Bank Lists</label>
                <select [(ngModel)]="chosen_bank" class="form-control" name="chosen_bank" id="chosen_bank">
                  <option value="0"></option>
                  <option *ngFor="let nb of lender_banks.banks" [ngValue]="nb">{{nb.LENDER_BANK_ACCOUNT_NAME}}</option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button [disabled]="loading" class="btn btn-block btn-white" (click)="saveSelectedLenderBank()">Save
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                  </button>
                </div>
                <div class="col-md-6">
                  <button [disabled]="loading" class="btn btn-block btn-white" (click)="cancelOperation()">Cancel
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="choose_an_option == '2'">
              <div class="form-group">
                <label class="control-label normal">Bank</label>
                <select (ngModelChange)="changeBankCode($event,true)" [(ngModel)]="bank.LENDER_BANK_ID"
                  class="form-control" name="LENDER_BANK_ID" id="LENDER_BANK_ID">
                  <option value="0"></option>
                  <option *ngFor="let nb of nigerian_banks" [ngValue]="nb">{{nb.BANK_NAME}}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label normal">Bank Code</label>
                <input type="text" [(ngModel)]="bank.LENDER_BANK_CODE" class="form-control" disabled=""
                  id="LENDER_BANK_CODE" name="LENDER_BANK_CODE">
              </div>

              <div class="form-group">
                <label class="control-label normal">Account Number</label>
                <input type="text" [(ngModel)]="bank.LENDER_ACCOUNT_NUMBER" class="form-control"
                  id="LENDER_ACCOUNT_NUMBER" name="LENDER_ACCOUNT_NUMBER">
              </div>

              <div class="form-group">
                <label class="control-label normal">Account Name</label>
                <input [(ngModel)]="bank.LENDER_BANK_ACCOUNT_NAME" type="text" disabled="" class="form-control"
                  id="LENDER_BANK_ACCOUNT_NAME" name="LENDER_BANK_ACCOUNT_NAME">
              </div>
              <div class="destination" *ngIf="destination">
                <h3>Specify Destination Account</h3>
                <div class="form-group row">
                  <label class="control-label col-md-6">Bank</label>
                  <div class="col-md-6">
                    <select (ngModelChange)="changeBankCode_($event,false)" class="form-control"
                      [(ngModel)]="credit.DEST_BANK_ID" name="DEST_BANK_ID" id="DEST_BANK_ID">
                      <option value="0"></option>
                      <option *ngFor="let nb of nigerian_banks" [ngValue]="nb">{{nb.BANK_NAME}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">

                  <label class="control-label col-md-6">Bank Code</label>
                  <div class="col-md-6">
                    <input [(ngModel)]="credit.DEST_BANK_CODE" type="text" class="form-control" name="DEST_BANK_CODE"
                      disabled id="DEST_BANK_CODE" value="">
                  </div>
                </div>
                <div class="form-group row">

                  <label class="control-label col-md-6">Account Number</label>
                  <div class="col-md-6">
                    <input [(ngModel)]="credit.DEST_ACCOUNT_NUMBER" type="text" class="form-control"
                      id="DEST_ACCOUNT_NUMBER" name="DEST_ACCOUNT_NUMBER">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-12">
                    <button [disabled]="loading" class="btn btn-block btn-white" (click)="doTransfer()">Submit
                      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                  </div>

                </div>
                <div class="mt-4">
                  <app-button (click)="cancelTransfer()" class="small red" label="Cancel"></app-button>
                </div>
              </div>
              <div class="mt-4" *ngIf="!charge_account&&!destination">
                <app-button [disabled]="loading" (click)="verifyAccount()" class="small" label="Verify Account"
                  [loading]="loading">
                </app-button>
              </div>
              <div class="row charge_btn" *ngIf="charge_account">
                <app-button class="small" (click)="saveLenderBank()" label="Save" [loading]="loading">
                </app-button>
                <app-button *ngIf="showTestTransfer" class="small outline" (click)="testTransfer()"
                  label="Test Transfer">
                </app-button>
              </div>
              <div class="error alert-sm alert alert-block alert-danger mt-2 mb-2" *ngIf="VERIFY_STATUS&&!destination">
                Account number could not be verified.
              </div>
            </div>
          </form>
        </div>
      </div>
      <div style="padding-top: 20px; width: 80%; margin: 0px auto;" *ngIf="!confirming">
        <div class="otp-form animated fadeIn" *ngIf="!otpHBSFC">
          <h4>
            <p>{{fresponse}}</p>
          </h4>
          <div class="form-group row">

            <label class="control-label col-md-6">OTP</label>
            <div class="col-md-6">
              <input type="text" class="form-control" id="CONFIRM_OTP_CODE" [(ngModel)]="otp.CONFIRM_OTP_CODE"
                name="CONFIRM_OTP_CODE" value="">

            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <button [disabled]="loading" class="btn btn-block btn-white" (click)="confirmOTPForTransfer()">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>
            </div>

          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <a href="javascript:;" class="btn btn-block btn-danger btn-cancel-transfer">Cancel</a>
            </div>

          </div>
          <div class="form-group row f-net-error" *ngIf="!otpConfirmed&&otpHBSFC">
            <div class="col-md-12">
              <div class="error  alert alert-block alert-danger fade in f-net-error_"
                style="margin:10px 0px !important">
                Unable to perform request
              </div>

            </div>
          </div>
        </div>
        <div class="otp-success animated text-center" *ngIf="!confirming&&otpConfirmed&&otpHBSFC">
          <h4>Account is confirmed</h4>
          <p>
            <span style="font-size: 68px;color: green;">
              <i class="fa fa-check"></i>
            </span>
          </p>
          <h3>Account Details</h3>
          <ul class="l_summary_wrapper "
            style="border:1px solid #eaeaea;border-radius:5px; font-size:12px; text-align:left">

            <li style="padding: 14px 28px;border-bottom: solid 1px #e5e5e5;">Account Name
              <span style="float:right;font-weight: 700;">{{bank.LENDER_BANK_ACCOUNT_NAME}}</span>
            </li>
            <li style="padding: 14px 28px;border-bottom: solid 1px #e5e5e5;">Account Number
              <span style="float:right;font-weight: 700;">{{bank.LENDER_ACCOUNT_NUMBER}}</span>
            </li>



          </ul>
          <div class="row">
            <div class="col-md-6">
              <button [disabled]="loading" class="btn btn-block btn-white" (click)="saveLenderBank()">Save
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>
            </div>
            <div class="col-md-6">
              <button [disabled]="loading" class="btn btn-block btn-white" (click)="cancelOperation()">Submit </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="panel" *ngIf="isremita">
    <header class="panel-heading">Use for Remita Inflight Collection</header>
    <div class="panel-body text-center" style="">
      <div style="width: 120px;margin:20px auto;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
          <path d="M50,8A42,42,0,1,1,8,50,42,42,0,0,1,50,8m0-3A45,45,0,1,0,95,50,45,45,0,0,0,50,5Z"></path>
          <path
            d="M47.08,46.44q-.79-4-1.18-6.42t-.51-3.54q-.11-1.15-.11-2a4.3,4.3,0,0,1,1.29-3.25,5.36,5.36,0,0,1,6.84,0,4.3,4.3,0,0,1,1.29,3.25q0,.84-.11,2T54.1,40q-.39,2.38-1.18,6.42T50.79,57.1H49.21Q47.87,50.48,47.08,46.44Zm-.34,15.88a4.6,4.6,0,0,1,7.85,3.25,4.16,4.16,0,0,1-1.35,3.17,4.82,4.82,0,0,1-6.51,0,4.16,4.16,0,0,1-1.35-3.17A4.43,4.43,0,0,1,46.75,62.31Z">
          </path>
        </svg>
      </div>
      <div class="text-center">
        <button [disabled]="loading" class="btn btn-white" (click)="confirmRemitaBank()">Confirm
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </div>
    </div>
  </section>
  <section class="panel" *ngIf="isdelete">
    <header class="panel-heading">Delete Bank</header>
    <div class="panel-body text-center" style="">
      <div style="width: 120px;margin:20px auto;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
          <path d="M50,8A42,42,0,1,1,8,50,42,42,0,0,1,50,8m0-3A45,45,0,1,0,95,50,45,45,0,0,0,50,5Z"></path>
          <path
            d="M47.08,46.44q-.79-4-1.18-6.42t-.51-3.54q-.11-1.15-.11-2a4.3,4.3,0,0,1,1.29-3.25,5.36,5.36,0,0,1,6.84,0,4.3,4.3,0,0,1,1.29,3.25q0,.84-.11,2T54.1,40q-.39,2.38-1.18,6.42T50.79,57.1H49.21Q47.87,50.48,47.08,46.44Zm-.34,15.88a4.6,4.6,0,0,1,7.85,3.25,4.16,4.16,0,0,1-1.35,3.17,4.82,4.82,0,0,1-6.51,0,4.16,4.16,0,0,1-1.35-3.17A4.43,4.43,0,0,1,46.75,62.31Z">
          </path>
        </svg>
      </div>


      <p class="text-center">
        <small>All transactions will be removed</small>
      </p>

      <div class="text-center">
        <button [disabled]="loading" class="btn btn-white" (click)="confirmBankDelete()">Confirm
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </div>
    </div>
  </section>
</div>