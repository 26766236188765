 
   <div class="title flex justify-between">
      <span>Break Loans<br></span>
    
      <!-- <div class="input-group">
        <input type="search" name="search_string" [(ngModel)]="param.search_string" class="form-control"
          style="width: 200px;" placeholder="Search..">
        <span class="input-group-btn">
          <button class="btn btn-white" (click)="searchLoanPortfolio()" type="button">Go</button>
        </span>
      </div> -->
    </div>
    <section class="overpanel-scroll">
        <div class="panel mb-1">
            <div class="panel-body flex align-start">
          
              <div class="section-poster flx-2" style="max-width: none">
                <app-timer [endDate]='endDate'></app-timer>
              </div>
          
              <div class="flex-2 ml-3" style="margin-left:100px !important">
                <div class="label-value-list mb-2">
                  <h4 class="section-title mb-5">Features</h4>
                  <div class="item">
                    
                    <span class="value pl_0 ">Multiple active loans can be repaid concurrently</span> 
                  </div>
                  <div class="separator"></div>
                   
                </div> 
              </div>
            </div>
          </div>
    </section>
    <!-- <section *ngIf="!is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
     
      <div class="mt-2" *ngIf="added_requests">
        <div class="alert alert-info alert-sm">
          No of loans 
          <label class="pull-right mr-1"  style="margin-top: -5px;"> 
            <span class="mr-1">
              <app-button [disabled]="added_requests.length ==0" *ngIf="adding" (click)="adding=false" class="small" label="View ({{added_requests.length}})"></app-button>
              <app-button *ngIf="!adding" label="Add More Requests" (click)="adding=true" class="small"></app-button>
            </span>
          </label>
        </div>
      </div>
      <div class="flex justify-end mt-3 mb-4">
        <input *ngIf="!adding" type="date" name="payment_date" id="payment_date" [(ngModel)]="payment_date" class="form-control mr-1"
          style="width: 200px">
        <app-button *ngIf="adding" label="Finish" [loading]="loading" [disabled]="loading || payment_date=='' || added_requests.length == 0"
          (click)="adding=!adding" class="small hide">
        </app-button>
        <app-button *ngIf="!adding" label="Submit" [loading]="loading" [disabled]="loading || payment_date=='' || added_requests.length == 0"
          (click)="postDuePayments()" class="small mr-1">  
        </app-button>
        
      </div>
      <div *ngIf="!adding">
          <div class="contract-table">
              <table class="table medium">
                <thead>
                  <tr> 
                    <th>Name</th>
                    <th>Principal</th>
                    <th>Duration</th> 
                    <th>Date</th>
                    <th>Total Repayment</th>
                    <th>Paid</th>
                    <th>Balance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let due of added_requests; let i=index"> 
                    <td class="flex align-center">
                      <div class="image-avatar smaller mr-2"><img
                          src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
                      </div>
                      {{due.LEGAL_NAME}}
                    </td>
                    <td>{{due.REQUEST_PRINCIPAL|number:'.2-2'}}</td>
                    <td>{{due.REQUEST_TENOR}} {{due.REQUEST_PERIOD_ID|loanduration}}</td>
                    <td>{{due.DISBURSEMENT_DATE}}</td>
                    <td>{{due.TOTAL_REPAYMENT|number:'.2-2'}}</td>
                    <td>{{due.TOTAL_PAID|number:'.2-2'}}</td>
                    <td>{{due.LOAN_BALANCE|number:'.2-2'}}</td>
                    <td> 
                      <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
      </div>
      <div class="contract-table" *ngIf="adding">
        <table class="table medium">
          <thead>
            <tr>
              <th>Name</th>
              <th>Principal</th>
              <th>Duration</th> 
              <th>Date</th>
              <th>Total Repayment</th>
              <th>Paid</th>
              <th>Balance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let due of repayments; let i=index">
              <td class="flex align-center">
                <div class="image-avatar smaller mr-2"><img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
                </div>
                {{due.LEGAL_NAME}}
              </td>
              <td>{{due.REQUEST_PRINCIPAL|number:'.2-2'}}</td>
              <td>{{due.REQUEST_TENOR}} {{due.REQUEST_PERIOD_ID|loanduration}}</td>
              <td>{{due.DISBURSEMENT_DATE}}</td>
              <td>{{due.TOTAL_REPAYMENT|number:'.2-2'}}</td>
              <td>{{due.TOTAL_PAID|number:'.2-2'}}</td>
              <td>{{due.LOAN_BALANCE|number:'.2-2'}}</td>
                    <td>
                      <app-button *ngIf="!due.checked" class="small" (click)="addRecord(due,i)" label="Add"></app-button>
                      <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
                    </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section *ngIf="is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
      <section class="fill-h fill-v">
        <div class="h-group center mb-4 padded empty-state">
          <div class="icon"><i class="fa fa-check-o"></i></div>
          <h2 class="title">Request Successful</h2>
          <p class="subtitle">Your request has been posted successfully</p>
          
        </div>
      </section>
  </section> -->