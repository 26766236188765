import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService, StorageService } from '../../../_services/index';

@Component({
  selector: 'app-oldestapproval',
  templateUrl: './oldestapproval.component.html',
  styleUrls: ['./oldestapproval.component.css']
})
export class OldestapprovalComponent implements OnInit {
  @Input('token') token: string;
  @Input('url') url: string;
  oldest_approval: any;
  request_duration: any;
  request_tenor: any;

  constructor (
    private http: HttpClient,
    public router: Router,
    public dataService: DataService
  ) {
  }

  ngOnInit () {
    this.getLongestApproval()
  }

  getLongestApproval () {
    return this.http.post<any>(`${this.url}/getOldestApproval`, {token: this.token}).subscribe(res => {
      if (res.status) {
        if(res.data){
          this.oldest_approval = res.data;
        switch (this.oldest_approval.request_period_id) {
          case '1':
            this.request_tenor = `${this.oldest_approval.request_tenor} days`;
            break;
          case '2':
            this.request_tenor = `${this.oldest_approval.request_tenor} months`;
            break;
          case '3':
            this.request_tenor = `${this.oldest_approval.request_tenor} years`;
            break;
          case '4':
            this.request_tenor = `${this.oldest_approval.request_tenor} weeks`;
            break;
        }
        }
      }
    })
  }
  open_requests(request_id){
    this.dataService.loadRequests.emit();
    this.router.navigate(['../applications/pending/' + request_id]);
  }
}
