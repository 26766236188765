import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService, LoansService } from 'src/app/_services';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-statementlink',
  templateUrl: './statementlink.component.html',
  styleUrls: ['./statementlink.component.css']
})
export class StatementlinkComponent implements OnInit {
  @Input() phone = '';
  loading = true;
  @Input() request_id:any;
  currentUser: any;
  comments:any;
  response:any;
  constructor(public storageService: StorageService, public toastr: ToastrService,
    private productService: ProductsService, private loansService: LoansService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getComments();
  }
  getComments(){
    this.loading = true;
    this.loansService.getStatementLink(this.currentUser.token, this.phone)
      .subscribe(data => {
        this.loading = false;
        if(data.status == true){
          this.response = data.data;
        }
      });
  }
}
