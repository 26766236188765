<div class="title text-center">
  <span>Send multiple Invites</span>
</div>

<div class="flex-center flex-column mt-5" *ngIf="success">
  <div class="h-group padded center mt-5 flex-center flex-column">
    <div class="circle large green filled flex-center mb-3 mt-5"><i class="fa fa-check"></i></div>
    <h3 class="title no-float">Invites are now being sent.</h3>
    <div class="text-center mt-4">
      <app-button class="small outline" label="Send again" (click)="success = !success"></app-button>
    </div>
  </div>
</div>

<div class="flex flex-column overpanel-scroll padding-regular" *ngIf="!success">
  <div class="row">
    <div class="col-md-7 sticky">
      <div class="col-md-5">
        <div class="section-poster">
          <img src="assets/images/share.svg" alt="User" />
        </div>
      </div>
      <div class="col-md-7">
        <form #interestBandForm="ngForm" novalidate>
          <div class="form-group">
            <label class="control-label normal">Name</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="request.name" />
          </div>
          <div class="form-group">
            <label class="control-label normal">Email</label>
            <input type="email" class="form-control" name="email" [(ngModel)]="request.email" />
          </div> 
          <div class="mb-3">
            <app-button (click)="saveInterestRateBand(interestBandForm.value, interestBandForm.valid)"
              [disabled]="!request.name.length || !request.email.length" class="small" label="Add user">
            </app-button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-5" style="min-height: 300px!important;">
      <div class="h-group center flex flex-column mt-4 empty-state mt-5" *ngIf="!invites.length" style="height: 300px;">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title no-float">No user added.</h2>
      </div>
      <div class="flex justify-between align-center mb-4 border-bottom" *ngFor="let invite of invites">
        <div class="user">
          <div class="image">
            <img src="assets/images/user.png" alt="User" />
          </div>
          <div class="content">
            <div class="title">{{ invite.name }}</div>
            <div class="subtitle">{{ invite.email }}</div>
          </div>
        </div>
        <button class="icon-button red" (click)="deleteInvite(invite)"><i class="fa fa-times"></i></button>
      </div>
    </div>
    <div class="sender flex align-center justify-between bg-off-white padding-regular" style="z-index: 10">
      <span *ngIf="view=='invite'">{{invites.length}} Invitation(s) queued</span>
      <!-- <span *ngIf="view=='link'">{{generated_link}}</span> -->
      <app-button *ngIf="!coolSubmit" (click)="sendInvite()" [disabled]="loading || !invites.length" class="small"
        [loading]="loading" label="Send Invite">
      </app-button>
      <!-- <app-button *ngIf="!coolSubmit" (click)="generateLink(interestBandForm.value, interestBandForm.valid)" [disabled]="loading || !request.name.length || !request.email.length" class="small"
      [loading]="loading" label="Generate Link">
    </app-button> -->
    </div>
  </div>
</div>