<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Nibbs Instant Mandate</h3>
      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <th>Loan Id</th>
              <th>Customer</th>
              <th>Amount</th>
              <th>Account Number</th>
              <th>Bank</th>
              <th>Mandate ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
               
              <th></th>
            </thead>
           
            <tbody>
              <tr *ngFor="let record of data">
                <td>{{record?.loan_id}}</td>
                <td>{{record?.legal_name}}</td>
                <td>{{record?.MAX_AMOUNT}}</td>
                <td>{{record?.last_four_digits}}</td>
                <td>{{record?.bank_name}}</td>
                <td>{{record?.DD_MANDATE_ID}}</td>
                <td>{{record?.START_DATE}}</td>
                <td>{{record?.END_DATE}}</td>
                <td>{{record?.IS_ACTIVE=="1"?"Active":"Not Active"}}</td>
                <td><app-button (click)="checkStatus(record)" class="small green mb-1 mr-1" label="Check Status"></app-button></td>
                
              </tr>
            </tbody>
             
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>