<section *ngIf="view === 'list'">
  <div class="skeleton-children dark" *ngIf="loading">
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
  </div>
  <div *ngIf="!loading">
    <div *ngIf="data">
      <div class="panel box edged_box mb_10">
        <div class="panel-body padding-regular">
          <div class="flex">
            <div class="section-poster">
              <div class="image flex-center">
                <img [src]="photo">
              </div>
            </div>
            <div class="flex-3 mb-2 ml-4" *ngIf="editing !== 'bio'">
              <h3 class="section-title flex align-center justify-between mb-4">
                NIN Information
                <button class="btn btn-outline-secondary btn-xs" (click)="removeNIN()">
                        <i class="fa fa-edit mr-2 no-padding"></i>Remove NIN</button>
                        <button class="btn btn-outline-secondary btn-xs" (click)="useSign()">
                          <i class="fa fa-edit mr-2 no-padding"></i>Use Signature For Mandate</button>
              </h3>
              <ul class="decList bigger">

                <li>
                  <span>NIN</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.nin}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Title</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.title}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Surname</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.surname}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>First Name</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.firstname}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Middle Name</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.middlename}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Other Name</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.othername}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Maiden Name</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.maidenname}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Phone</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.telephoneno}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Email</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.email}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Date Of Birth</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.birthdate}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Gender</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.gender}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Marital Status</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.maritalstatus}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Spoken Language</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.nspokenlang}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Religion</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.religion}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li><span>Address</span>
                  <span *ngIf="!loading">
                    {{ data?.NIN_DETAILS?.residence_AdressLine1 }},{{ data?.NIN_DETAILS?.residence_AdressLine2 }}, {{
                    data?.NIN_DETAILS?.residence_Town }}, {{ data?.NIN_DETAILS?.residence_lga }},
                    {{ data?.NIN_DETAILS?.residence_state }}
                  </span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                </li>
                <li><span>Residence Status</span>
                  <span *ngIf="!loading">
                    {{ data?.NIN_DETAILS?.residencestatus }}
                  </span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Employment Status</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.employmentstatus}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Profession</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.profession}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Religion</span>
                  <span *ngIf="!loading">{{data?.NIN_DETAILS?.religion}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                <li>
                  <span>Signature</span>
                  <span *ngIf="!loading"> <img [src]="signature"></span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>

              </ul>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="!data">

      <div *ngIf="where !='4'">
        <div class="row">
          <div class="form-group col-md-12">
            <label class="control-label" for="type1">Type</label>
            <div class="row">
              <div class="form-group col-md-6">
                <label class="control-label" for="type1">Phone <input type="radio" value="1" class="pull-right" name="account" [(ngModel)]="TYPE"></label>
                
              </div> 
              <div class="form-group col-md-6">
                <label class="control-label" for="type1">NIN Number <input type="radio" value="2" class="pull-right" name="account" [(ngModel)]="TYPE"></label>
              </div> 
            </div>
          </div>
        </div>
        <section *ngIf="TYPE=='1'" [ngClass]="self == true? 'panel': ''" class=" padding-regular">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">Phone</label>
              <select class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type1">
                <option [value]="0"></option>
                <option [value]="1">Profile</option>
                <option [value]="2">BVN</option>
              </select>
            </div>

          </div>

          <app-button class="small default mr-2" label="Get Transactions" (click)="uploadThePhoto()"
            [disabled]="DOCTYPE==''|| loading" [loading]="loading"></app-button>
        </section>
        <section *ngIf="TYPE=='2'" [ngClass]="self == true? 'panel': ''" class=" padding-regular">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">NIN Number</label>
              <input type="text" class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type1">
            </div>

          </div>

          <app-button class="small default mr-2" label="Get Transactions" (click)="uploadThePhoto_()"
            [disabled]="DOCTYPE==''|| loading" [loading]="loading"></app-button>
        </section>
      </div>
    </div>
  </div>
</section>