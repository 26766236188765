import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../_services/notification.service';
import { StorageService } from '../_services';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css']
})
export class NotifyComponent implements OnInit {
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  page: number = 0;
  currentUser: any;
  completed = false;
  loading = false;
  notifications = [];
  notifications_count: any;
  endDate = '1561676400000';
  constructor(
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.readMessages(this.page);
  }

  readMessages(page) {
    this.loading = true;
    return this.notificationService.readMessages({ token: this.currentUser.token, page }).subscribe(res => {
      if (res.status) {
        this.loading = false;
        this.notifications_count = res.remaining_messages_count;
        this.completed = res.end;
        this.page++;
        this.notifications.push(...res.response);
      }
    }, err => {
      this.loading = false;
      console.log({ err });
    });
  }

  onScrollDown() {
    if (!this.completed) {
      this.readMessages(this.page);
    }
  }
}
