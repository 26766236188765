import { Component, Input, OnInit } from '@angular/core';

import { UserService, DataService, OperationsService, AuthenticationService, StorageService, LoansService } from '../_services/index';

@Component({
  selector: 'app-checkmandatebalance',
  templateUrl: './checkmandatebalance.component.html',
  styleUrls: ['./checkmandatebalance.component.css']
})
export class CheckmandatebalanceComponent implements OnInit {

  @Input() mandate : any;
   
  loading = false;
  data:any;
  constructor(public loansService: LoansService ,public authService: AuthenticationService,
    private DataService: DataService, public operationsService: OperationsService,
    public storageService: StorageService) { }

  ngOnInit() {
    this.loading = true;
    this.loansService.getNibbsInstantMandateDebitBalance(this.mandate)
      .subscribe(data => {
        this.data = data;
         
      });
  }

}
