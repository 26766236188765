
<div class="row">
  <div class="col-xs-12 col-md-6 pd_15">
    <div class="headerContentsection">
        <img id="logo-item" 
        *ngIf="this.logoUrl"  class="logo-item" [(src)]=" this.logoUrl " 
        style="width:200px; display: block; margin: auto;" id="headerImage"/> 
    </div>
  </div>
  <div *ngIf="hideHeader"  class="col-xs-6 col-md-5">
          <div class="upload_form_wrapper">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="file" id="avatar" (change)="onFileChange($event)" 
                    #fileInput>
                    <button type="button" class="btn btn-sm btn-default" 
                    (click)="clearFile()">clear file</button>
                </div>
                <button type="submit" [disabled]="form.invalid || loading" 
                    class="btn btn-success">Submit 
                <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
            </button>
            </form>
      </div>
  </div>
</div>