<div class="side-view animated slideInRight">
  <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
  <div class="side-view-wrap">

    <div class="header">
      &nbsp;
    </div>
    <div class="nav">
      <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
        New Customer
      </div>
       
    </div>
    <div class="content">


      <app-indcustomer></app-indcustomer>
    </div>

    
  </div>
</div>
