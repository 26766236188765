import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'; 
import { OperationsService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-fundyourwallet',
  templateUrl: './fundyourwallet.component.html',
  styleUrls: ['./fundyourwallet.component.css']
})
export class FundyourwalletComponent implements OnInit {

  @Input('wallettopup') wallettopup:any;
  @Input('wallet_settings') wallet_settings:any;
  loading =false;
  currentUser:any;
  topupstep = '1';
  topupWallet = false;
  @Output() showSuccess = new EventEmitter();
  @Output() showError = new EventEmitter();
  @Output() getWalletAccountStatus = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(private operationsService:OperationsService, private storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }
  cancelAction(){
    this.cancel.emit()
  }
  confirmWalletTop(){
    this.loading = true;
    this.operationsService.doWalletTopUp(this.currentUser.token, this.wallettopup, {})
      .subscribe(res => {
        this.loading = false;
        if(res.status == "success"){
          if(res.data.transfer.flutterChargeResponseCode=='02'){
            this.topupstep = '2';
            this.wallettopup.TRANSACTION_REFERENCE = res.data.transfer.flutterChargeReference;
          }else{
            this.topupWallet = false;
            this.showSuccess.emit({message:'Wallet funding successful'});
            this.getWalletAccountStatus.emit(); 
          }
        }
        if(res.status == "error"){
          this.showError.emit({message:res.message});
        }
      });
  }
  finishtopup(){
    this.loading = true;
    this.operationsService.finishWalletTopUp(this.currentUser.token, this.wallettopup, {})
      .subscribe(res => {
        
        this.loading = false;
        if(res.status=="success"){
          this.showSuccess.emit({message:'Wallet funding successful'});
          this.getWalletAccountStatus.emit(); 
        }else{
          this.showError.emit({message:'Unable to perform request'});
        }
      });
  }
}
