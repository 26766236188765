import { Component, OnInit, Input } from '@angular/core';
import { DataService, StorageService, CustomerService } from 'src/app/_services';

@Component({
  selector: 'app-accountofficer',
  templateUrl: './accountofficer.component.html',
  styleUrls: ['./accountofficer.component.css']
})
export class AccountofficerComponent implements OnInit {

  @Input() ownershipCustomer;
  chosen_lender: boolean;
  selected: any;
  currentUser: any;
  loading: boolean;
  is_done: string;
  TRANSFER_ALL_ACCOUNT = false;

  constructor(
    public customerServ: CustomerService,
    private dataService: DataService,
    public storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }

  confirmChangeOwner() {
    this.loading = true;
    this.customerServ.confirmChangeOwner(this.currentUser.token, this.ownershipCustomer, this.TRANSFER_ALL_ACCOUNT, this.selected, this.ownershipCustomer.ACCOUNT_OFFICER)
      .subscribe(lenders => {
        this.loading = false;
        //this.action='0'
        this.is_done = '1'
        this.dataService.reloadCustomers.emit();
      });
  }

  chooseLender(event) {
    this.selected = event;
    this.chosen_lender = true
  }
}
