import { Component, OnInit, ElementRef, OnDestroy, ViewContainerRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AnonymousSubscription } from 'rxjs/Subscription';
import { OfferModel } from '../_models/offer.model';
import { MarketService, DataService, CustomerService, StorageService, OperationsService, AuthenticationService, LoansService } from '../_services/index';
import { Notification, NotificationService } from '../_services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { CustomersService } from '../_services/customers.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.css']
})
export class MainNavigationComponent implements OnInit, OnDestroy {
  APPROVAL_LEVEL_ID_ = '';
  priority = 'customer';
  updatingApprovalLevels = false;
  approvingbulkdisburse = false;
  is_for_email_reminder = true;
  remindercontenteditor = false;
  message_content: any;
  message_subject: any;
  message_type: any;
  userId = '';
  taskCount = '0';
  opentaskCount = '0';
  endDate = '';
  showingUnderConstruction = false;
  approving_prepayemnt = false;
  security_question: any;
  lender_selected = false;
  chosen_lender = false
  selected: any;
  offerletterdata: any;
  updatingofferletter = false;
  schedule: any;
  open_schedule = false;
  disbursing_request = false;
  loan: any;
  disburse: any;
  emailopen = '';
  mailadveropen = false;
  timeLeft = 2000;
  TRANSFER_ALL_ACCOUNT = false
  overlayModalSection = '';
  simulateSection = 'default';
  overlayOpenInvestment = false;
  @Input('isloan') isloan = 'loans';
  webussd = false;
  newLoanOpen = false;
  is_company_staff = false;
  test_mode = '0';
  offer: OfferModel;
  public is_done = '0';
  public loading = false;
  public openShowInterest = false;
  public currentUser: any;
  customers: any;
  searchTerm: any;
  view = 'request';
  viewOptions = false;
  overlayOpen = false;
  overlayOpenPartnerLoan = false;
  request_step = '1';
  schedule_open = false;
  new_collateral_modal = false;
  add_guarantor_modal = false;
  guarantor_contacts_modal = false;
  posting_bulk_payments = false;
  posting_upload_payment = false;
  parentRouteId: any;
  public dashboarddata: any;
  public dashboardFilter = { byMe: false };
  public selectedCustomer: any;
  public customerSelected = false;
  public notifications: any;
  private timerSubscription: AnonymousSubscription;
  private postsSubscription: AnonymousSubscription;
  public enable_peer = '0';
  public accounting = '0';
  newCustomerRecord = false;
  main_menu = 'loans';
  contract_details: any;
  contract_details_: any;
  editing_contract = false;
  contacts: any;
  open_portfolio_target_modal = false;
  showNotifications = false;
  overlayOpenNewLoan = false;
  overlayOpenContractConfirm = false;
  overlayModifyContractConfirm = false;
  approvals_count = '0';
  request_id: any;
  canEditSchedule = false;
  loan_status: any;
  action = '0'
  ownershipCustomer: any;
  posting_break_loans = false;
  invitingCustomer = false;
  invitingMultipleCustomer = false;
  invitingMultipleGuarantors = false;
  preview_schedule = false;
  open_profile_analysis_modal = false;
  open_approval = false;
  open_market = false;
  level: any;
  prev_levels: any;
  approving = false;
  rejecting = false;
  searching = false;
  public model_r = {
    ilo: 0,
    reject_reason: '',
    reject_action: '',
    approval_notes: '',
    reject_level: '',
    wtd: 0,
    request_id: '',
    level: ''
  };
  public model_a = {
    chk_acts: [],
    past_one: 1,
    approval_all_waivers: 1,
    approval_all_checklist: 1,
    is_waiver_level: 0,
    has_waiver: 0,
    ilo: 0,
    istd: 0,
    approval_notes: '',
    wtd: 1,
    request_id: '',
    level: ''
  };
  disbursements: any;
  download_disbursement = false;
  analytics_data_status: any;
  perform_by: any;
  from_where = '1';
  sending_email = false;
  customerdetail: any;
  is_multiple = false;
  sending_sms = false;
  sub = '1';
  overlayEditModalSection='';
  requestingData:any;
  drawer=false;
  activeRequestId='';
  drawer_customer = false;
  loan_:any;
  pushing_to_partners = false;
  unpushing_to_partners = false;
  request_id_bnpl = '';
  loan_bnpl:any;
  queueing_for_dis = false;
  partner = ''; 
  record:any;
  lender:any; 
  checkerloading = true;
  is_partner = 0;
  constructor(
    protected customersSrvc: CustomersService,public customerServ: CustomerService, private toastr: ToastrService, private _dataService: DataService,
    public router: Router, public operationsService: OperationsService,
    public storageService: StorageService,
    protected customerService: CustomerService, public marketService: MarketService,
    private _eref: ElementRef,
    public _notificationService: NotificationService, private authService: AuthenticationService, private loansService: LoansService
  ) {
    
    this.lender = this.storageService.read<any>('platform');
    this.is_done = '0';
    this._dataService.openSchedule.subscribe((res) => {
      this.record = res.record;
      this.is_partner = res.is_partner;
      this.schedule_open = true;
     
    });
    this.partner = '';
    this.is_done = '0';
    this._dataService.queueForPartners.subscribe(res => { 
      this.request_id = res.request_id;
      this.pushing_to_partners =  true;
      this.is_done = '0';
    })
    this._dataService.unqueueForPartners.subscribe(res => { 
      this.request_id = res.request_id;
      this.unpushing_to_partners =  true;
    })
    this._dataService.queuefordisbnpl.subscribe(res => { 
      
      this.request_id_bnpl = res.request_id;
      this.loan_bnpl =  res.loan;
      this.queueing_for_dis = true;
      
      
    })
    this._dataService.loadHomeCustomerSideview.subscribe(res => { 
      this.loan = res.cust;
      this.activeRequestId = res.cust.PEOPLE_ID;
      this.drawer_customer = false;
      this.drawer = true;
    })
    this._dataService.customerDeleted.subscribe(res => { 
      this.loan = this.loan_;
      this.activeRequestId = '';
      this.drawer_customer = false;
      this.drawer = false;
    })
    this._dataService.createANewCustomer.subscribe(res => { 
      this.overlayOpenNewLoan = false;
      this.drawer_customer = true;
    })
    
    this._dataService.requestingfordataforcustomer.subscribe(res => {
      if(res.customer_details){
        this.customerdetail = res.customer_details
      }
      if(res.priority){
        this.priority = res.priority
      }
      this.overlayEditModalSection = 'requesting_data';
      this.requestingData = res;
    });
    this._dataService.updateAppLevel.subscribe(res => {
      this.updatingApprovalLevels = true;
      this.APPROVAL_LEVEL_ID_ = res.APPROVAL_LEVEL_ID_;
    });
    this._dataService.downloadingBulkPayment.subscribe(res => {
      this.disbursements = res.disbursements;
      this.download_disbursement = true;
    });
    this._dataService.closeDisbursementModal.subscribe(res => {
      this.disbursing_request = false;
    });
    this._dataService.approvingRequest.subscribe(res => {
      this.open_approval = true;
      this.level = res.level;
      this.prev_levels = res.prev_levels;
      this.approving = true;
      this.loan = res.loan;
    });
    this._dataService.pushingToMarket.subscribe(res => {
      this.open_market = true;
      this.level = res.level;
      this.prev_levels = res.prev_levels;
      this.approving = true;
      this.loan = res.loan;
    });
    this._dataService.rejectingRequest.subscribe(res => {
      this.open_approval = true;
      this.level = res.level;
      this.prev_levels = res.prev_levels;
      this.approving = false;
      this.rejecting = true;
      this.loan = res.loan;
    });
    this._dataService.showProfileAnalysisModal.subscribe(res => {
      this.open_profile_analysis_modal = true;
    });
    this._dataService.inviteNewCustomer.subscribe(res => {
      this.invitingCustomer = false;
      this.invitingMultipleCustomer = true;
    });
    this._dataService.inviteNewGuarantor.subscribe(res => {
      this.invitingCustomer = false;
      this.invitingMultipleCustomer = false;
      this.invitingMultipleGuarantors = true;
    });
    this._dataService.onOpenApplicationsModal.subscribe(res => {
      this.overlayModalSection = res.section;
      this.request_id = res.request_id;
      this.loan = res.loan;
      this.analytics_data_status = res.analytics_data_status
      if (res.userId) {
        this.userId = res.userId;
      }
    });
    this._dataService.openUnderConstruction.subscribe(res => {
      this.endDate = res.endDate;
      this.showingUnderConstruction = true;
    });
    this._dataService.onTakeOwnership.subscribe(res => {

      this.action = '1';
      this.ownershipCustomer = res;
    })
    this._dataService.onchangeOwnership.subscribe(res => {

      this.action = '2';
      this.ownershipCustomer = res;
    })
    this._dataService.newBulkPayments.subscribe(res => {
      this.posting_bulk_payments = true;
    })
    this._dataService.uploadBulkPayment.subscribe(res => {
      this.posting_upload_payment = true;
    })
    this._dataService.breakingBulkLoans.subscribe(res => {
      this.posting_break_loans = true;
    })
    this.currentUser = this.storageService.read<any>('currentUser');
    this.perform_by = this.currentUser.account_id;
    this.timeLeft = this.storageService.read<any>('timeLeft');
    this.enable_peer = this.storageService.read<any>('enable_peer_to_peer');
    this.accounting = this.storageService.read<any>('enable_accounting');
    this.is_company_staff = this.storageService.read<any>('is_company_staff');
    this.test_mode = this.storageService.read<any>('test_mode');
    this._dataService.onViewLoan.subscribe(res => {

      this.overlayOpen = false;
      if (res.from === '1') {

        this.router.navigate(['../loan/' + res.request_id]);
      }
    });
    this._dataService.disbursingRequest.subscribe(res => {
      this.disbursing_request = true;
      this.disburse = res.disburse;
      this.loan = res.new_loan;
      this.security_question = res.security_question;
    });
    this._dataService.approvingPrepayment.subscribe(res => {
      this.approving_prepayemnt = true;
      this.disburse = res.disburse;
      this.loan = res.new_loan;
      this.security_question = res.security_question;
    });
    this._dataService.openContractModal.subscribe(res => {
      this.editing_contract = true;
      this.overlayOpen = true;
      this.contract_details = res;
      this.view = 'summary';
    });
    this._dataService.openPartnerForm.subscribe(res => {
      
      this.overlayOpenPartnerLoan = true;
    });


    
    this._dataService.openContractConfirm.subscribe(res => {
      this.editing_contract = true;
      this.overlayOpenContractConfirm = true;
      this.contract_details = res;
      this.view = 'summary';
    });
    this._dataService.modifyContractModal.subscribe(res => {
      this.editing_contract = true;
      this.overlayModifyContractConfirm = true;
      this.contract_details = res;
      this.view = 'summary';
    });

    this._dataService.openmailevent.subscribe(res => {
      this.emailopen = res.emailopen;
    });
    this._dataService.openmailadvertevent.subscribe(res => {
      this.mailadveropen = true;
    });
    this._dataService.openReminderMessageContent.subscribe(res => {
      this.remindercontenteditor = true;
      this.message_content = res.message_content;
      this.message_subject = res.message_subject;
      this.message_type = res.message_type;
      this.is_for_email_reminder = true;
    });
    this._dataService.openSMSReminderMessageContent.subscribe(res => {
      this.remindercontenteditor = true;
      this.message_content = res.message_content;
      this.message_type = res.message_type;
      this.is_for_email_reminder = false;
    });
    this._dataService.runOperationsTest.subscribe(res => {

      this.operationsService.runOPTests();
    });
    this._dataService.bookanewloan.subscribe(res => {
      this.overlayOpenNewLoan = true;
    });
    this._dataService.modifyOfferTemplate.subscribe(res => {
      this.offerletterdata = res;
      this.updatingofferletter = true;
    });
    this._dataService.closeCurrentlyOpenModal.subscribe(res => {
      this.disbursing_request = false;
    });

    this._dataService.makeOfferToMarketLoan.subscribe(res => {

      this.overlayOpen = false;
      this.openShowInterest = true;
      this.loading = false;
      this.is_done = '0';
      this.offer = new OfferModel(
        res.REQUEST_ID,
        res.PLATFORM_ID,
        res.PEOPLE_ID,
        res.REQUEST_PRINCIPAL,
        res.REQUEST_TENOR,
        res.REQUEST_PERIOD_ID,
        res.REQUEST_RATE,
        res.REQUEST_RATE_PERIOD_ID, '1', '3');
    });
    this._dataService.closePAnalysisModal.subscribe((res) => {
      this.open_profile_analysis_modal = false;
    });
    this._dataService.showScheduleDetails.subscribe((res) => {
      this.parentRouteId = res.request_id;
      this.schedule = res.schedule;
      //this.open_schedule = res.open_schedule;
      this.canEditSchedule = res.canEditSchedule;
      this.loan_status = res.loan_status;
      this.schedule_open = true;
      if (res.from_where) {
        this.from_where = res.from_where;
        this.loan = res.loan.loan;
        this.parentRouteId = res.loan.request_id;
      }

    });
    this._dataService.previewScheduleDetails.subscribe((res) => {
      this.parentRouteId = res.request_id;
      this.schedule = res.schedule;
      this.canEditSchedule = res.canEditSchedule;
      this.loan_status = res.loan_status;
      this.preview_schedule = true;
    });
    this._dataService.showCollateralModal.subscribe(() => {
      this.new_collateral_modal = true;
    });
    this._dataService.showContactsModal.subscribe((res) => {
      this.contacts = res.contacts;
      this.guarantor_contacts_modal = true;
    });
    // add guarantor modal
    this._dataService.addGuarantorModal.subscribe((res) => {
      this.parentRouteId = res.id;
      this.add_guarantor_modal = true;
    });
    this._dataService.showPortfolioTarget.subscribe(res => {
      this.parentRouteId = res.id;
      this.open_portfolio_target_modal = true;
    });
    this._dataService.sendMailToCustomer.subscribe(res => {
      this.sending_email = true;
      this.customerdetail = res.data;
      this.userId = res.customer_id;
      this.is_multiple = res.is_multiple;
    });
    this._dataService.sendSMSToCustomer.subscribe(res => {
      this.sending_sms = true;
      this.customerdetail = res.data;
      this.userId = res.customer_id;
      this.is_multiple = res.is_multiple;
    });
  }
  queuePart(){
    this.loading = true;
    this.loansService.queueP(this.currentUser.token, this.request_id, this.partner)
      .subscribe(result => {
        this.is_done=='1';
        this.showSuccess('Request successful');
        this.loading = false;
      });
  }
  unqueuePart(){
    this.loading = true;
    this.loansService.unqueueP(this.currentUser.token, this.request_id)
      .subscribe(result => {
        this.is_done=='1';
        this.showSuccess('Request successful');
        this.loading = false;
      });
  }
  onSubmitMessageContentUpdate(event) {
    this._dataService.emailMessageContentHasBeenUpdated.emit({ message_content: event.message_content, message_subject: event.message_subject, message_type: event.message_type });
    this.remindercontenteditor = false;
  }
  onSubmitSMSMessageContentUpdate(event) {
    this._dataService.smsMessageContentHasBeenUpdated.emit({ message_content: event.message_content, message_type: event.message_type });
    this.remindercontenteditor = false;
  }
  rejectThisRequest(event) {
    this.model_r = event.model_r;
    this.model_r.request_id = this.loan.request_id;
    this.model_r.level = this.loan.approval_level;
    this.loading = true;
    this.model_r.level = this.loan.approval_level;
    this.loansService
      .rejectThisRequest(this.currentUser.token, event.model_r)
      .subscribe(loans => {
        this.open_approval = !this.open_approval;
        this.loading = false;

        this._dataService.refreshPage.emit();
        this._dataService.cancelationDone.emit();
      });

  }
  cancelThisApproval() {
    this.approving = false;
    this.rejecting = false;
    this.open_approval = false;
  }
  approveThisRequest(event) {
    this.model_a = event.model_a;
    this.model_a.request_id = this.loan.request_id;
    this.model_a.level = this.loan.approval_level;
    this.loansService
      .approveThisRequest(this.currentUser.token, this.model_a)
      .subscribe(loans => {
        this._dataService.refreshPage.emit();
        this._dataService.approvalDone.emit();
        this.loading = false;
        if (loans.status) {
          this.open_approval = !this.open_approval;
          this.loading = false;

          this.open_approval = !this.open_approval;
        } else {
          if (loans.message) {
            this.showError(loans.message)
          }
        }
        this.open_approval = !this.open_approval;
      });
  }
  ngOnInit() {
    this.is_done = '0';
    //this.loadDashboardData();
    this.is_company_staff = this.storageService.read<any>('is_company_staff');
    this.test_mode = this.storageService.read<any>('test_mode');
    const currentUrl = this.router.url;
    if (currentUrl === '/savings') {
      this.main_menu = 'savings';
    } else {
      this.main_menu = 'loans';
    }
    this.loansService.countMyTasks(this.currentUser.token, this.perform_by)
      .subscribe(result => {
        this.taskCount = result;
      });

    // opentaskCount
    this.loansService.countOpenTasks(this.currentUser.token, this.perform_by)
      .subscribe(result => {
        this.opentaskCount = result;
      });
  }
  grantRight(right: String) {
    return this.authService.userHasRights(right);
  }
  golive() {

  }
  closeShowInterest() {
    this.overlayOpen = false;
    this.openShowInterest = false;
  }
  private async loadDashboardData() {

    this.postsSubscription = this.operationsService.loanPlatforWallet(this.currentUser.token, this.dashboardFilter)
      .subscribe(dashboarddata => {
        if (!dashboarddata) {
          this.router.navigate(['/login']);
        } else {
          this.dashboarddata = dashboarddata;
          this.approvals_count = dashboarddata.approvals || 0;
        }
      });

    try {
      this.notifications = await this.getUnreadMessages(this.currentUser.token);

    } catch (err) {
      console.log(err);
    }
  }
  public ngOnDestroy(): void {
    if (this.postsSubscription) {
      this.postsSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
  private subscribeToData(): void {
    this.timerSubscription = Observable.timer(2000).first().subscribe(() => this.loadDashboardData());
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.viewOptions = false;
    }
  }
  openLoanForm(customer) {
    this.sub = '20';
    this.request_step = '1';
    this.contract_details = this.contract_details_;
    this.selectedCustomer = customer;
    this.customerSelected = true;
    this.overlayOpen = true;

    // this._dataService.onGetCustomer.emit(customer);
  }

  // Search for customer
  search = (e) => {
    // console.log({ e })
    // let data = {
    //   ORDER_BY: 2,
    //   SEARCH_NAME: this.searchTerm,
    //   SEARCH_TYPE: 0
    // };
    if (!this.searchTerm) return this.stopSearch();
    if (e.keyCode === 13) {
      this.searching = true;
      this.customerService.searchForCustomers(this.currentUser.token, this.searchTerm, '')
        .subscribe(data => {
          this.customers = data;
          this.viewOptions = true;
        });
    }
  }

  stopSearch() {
    this.searching = false;
    this.customers = null;
  }

  gotoCustomer(id) {
    if (id) this.router.navigate(['/customer/individual/' + id]);
    this.stopSearch();
  }

  makeOfferToAdvertisedLoan() {
    this.loading = true;
    this.marketService.makeOfferToAdvertisedLoan(this.currentUser.token, this.offer)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
        if (data.status === '0') {
          this.showError(data.message);
        }
      });
  }
  openSimulator() {
    this._dataService.openOnboardSimulator.emit();
  }
  openGoLive() {
    this._dataService.openGoLive.emit();
  }

  getUnreadMessages(token: string): Promise<object> {
    const payload = { token: token };
    return new Promise((resolve, reject) => {
      this._notificationService.getUnreadMessages(payload).subscribe(
        (data: Notification) => {
          resolve(data);
        },
        error => {
          reject(throwError(error));
        }
      );
    });
  }

  markMessageAsRead(id: string, people_ids: string) {
    const payload = { token: this.currentUser.token, notification_id: id, people_people_ids: people_ids.split(',') };

    try {
      this._notificationService.markMessageAsRead(payload).subscribe(
        (data: Notification) => {
          this.notifications = this.getUnreadMessages(this.currentUser.token);
        },
        error => {
          console.log('Notification service failed');
          throwError(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  closeChildModal() {
    this.action = "0";
  }
  confirmBulkPaymentApproval() {
    this.overlayModalSection = '';
    this._dataService.confirmBulkPaymentApprovals.emit({ disburse: this.disburse });
  }
  confirmOwner(currentUser, customer) {
    this.loading = true;
    this.customerServ.confirmOwner(this.currentUser.token, this.ownershipCustomer, this.TRANSFER_ALL_ACCOUNT)
      .subscribe(lenders => {
        this.loading = false;
        this.is_done = '1'
        this._dataService.reloadCustomers.emit();
      });
  }
  qdbnpl() {
    this.loading = true;
    // this.loansService.qdbnpl(this.currentUser.token, this.loan, this.request_id_bnpl)
    //   .subscribe(lenders => {
    //     this.loading = false;
    //     this.is_done = '1'
    //     this._dataService.reloadCustomers.emit();
    //   });
    this.loansService.qdbnpl(this.currentUser.token, this.request_id_bnpl,this.loan_bnpl, this.TRANSFER_ALL_ACCOUNT)
      .subscribe(loans => {
        this.loading = false;
          this.is_done = '1'
          this.showSuccess('Added to payment queue') 
      });
  }
  confirmChangeOwner(currentUser, customer) {
    this.loading = true;
    this.customerServ.confirmChangeOwner(this.currentUser.token, this.ownershipCustomer, this.TRANSFER_ALL_ACCOUNT, this.selected, this.ownershipCustomer.ACCOUNT_OFFICER)
      .subscribe(lenders => {
        this.loading = false;
        //this.action='0'
        this.is_done = '1'
        this._dataService.reloadCustomers.emit();
      });
  }
  chooseLender(event) {
    this.selected = event;
    this.chosen_lender = true
  }
  sendEmailToCustomer(customer) {
    this._dataService.sendMailToCustomer.emit({ customer_id: customer.PEOPLE_ID, is_multiple: false });
  }
  sendSMSToCustomer(customer) {
    this._dataService.sendSMSToCustomer.emit({ customer_id: customer.PEOPLE_ID, is_multiple: false });
  }
  sendRequestForData() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.showSuccess('Request successful');
    this.operationsService.sendRequestForCustomerData(
      this.currentUser.token,
      this.customerdetail.people_id,
      this.requestingData,
      false
    );
    }
  /*
    Super Admin -  Role Id - 1 - Can see everything
    Approver - Role Id - 2 - Requests, Portfolio, Customers
    Accounts - Role Id - 3 - Operations, Reports
    Customer Account Manager - 4 - Customers
    Admin -Role Id - 5 - Requests, Portfolio, Customers, Reports
  */
  disableLinkIfNotRole(name: string): boolean {
    // let raw = localStorage.getItem('roles');
    // let roles = raw.split(',').map(n => parseInt(n, 10));

    // const pages = {
    //   '1': ['requests', 'portfolio', 'customers', 'operations', 'reports', 'settings', 'products', 'branches', 'clusters'],
    //   '2': ['requests', 'portfolio', 'customers'],
    //   '3': ['operations', 'reports'],
    //   '4': ['customers'],
    //   '5': ['requests', 'portfolio', 'customers', 'reports'],
    // };

    // const allowedPages = roles.reduce((acc, n, i) => {
    //   if (pages[n] !== undefined) acc.push(...pages[n])
    //   return acc
    // }, []);

    // const allowedPagesWithoutDuplicates = allowedPages.reduce((acc, page) => {
    //   if (!acc.includes(page)) acc.push(page)
    //   return acc;
    // }, [])

    // return !allowedPagesWithoutDuplicates.includes(name);
    return false;
  }
  closeDrawer() {
    this.drawer = false;
    this.drawer_customer=false; 
    this.activeRequestId = null;
    this.loan = null;
  }
  openLoan(request) {
    this.customersSrvc.getCustomerProfile(1, request.PEOPLE_CUSTOMERS_ID).subscribe(data => {
      console.log('Hello')
      this.loan = data.cust;
      this._dataService.loadHomeCustomerSideview.emit({ 'cust': data.cust });

    });
  }
  updatechecker(event){
    console.log(event,'Update Checker..........');
    this.checkerloading = event;
  }
}
