<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">

  <div class="flex">
    

    <div class="col-md-12 pl_0">
      <div style="margin-left: -40px">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection==='accounts'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class=" overpanel-content floating-title animated zoomIn" role="document">
    <app-accountmodal [request_id]="request_id" [token]="currentUser.token"></app-accountmodal>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection==='cards'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class=" overpanel-content floating-title animated zoomIn" role="document">
    <app-cardmodal [request_id]="request_id" [token]="currentUser.token"></app-cardmodal>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection==='bvn'">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class=" overpanel-content floating-title tall animated zoomIn" role="document">
    <app-bvnmodal (clearBVNEvent)="clearBVN($event)" (retryBVNEvent)="retryBVN($event)" [request_id]="request_id"
      [token]="currentUser.token"></app-bvnmodal>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection==='location'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title wide" role="document">
    <app-loanlocation [parentRouteId]="request_id" [where]='2'></app-loanlocation>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="overlayModalSection==='documents'">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn" role="document">
    <app-loanphotos [parentRouteId]="request_id" [where]='2'></app-loanphotos>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="show_portfolio_calendar_bulk_payment">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="show_portfolio_calendar_bulk_payment=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-postingbulkpaymentscalendar [show_for_account_officer]=true [showing_due_loans]="showing_due_loans" [payment_date]="date_chosen">
    </app-postingbulkpaymentscalendar>
  </div>
</div>


<app-requset-sideview *ngIf="drawer_request" (close)="closeDrawer()" [requestId]="activeRequestId" [loan]="loan"
  (openModal)="openModal($event)" (approveThisLoan)="approveThisLoan($event)" (rejectThisLoan)="rejectThisLoan($event)"
  (doingDisbursement)="doingDisbursement($event)">
</app-requset-sideview>
<app-portfolio-sideview *ngIf="drawer_portfolio" (close)="closeDrawer()" [requestId]="activeRequestId" [loan]="loan"
  [repayment]="repayment">
</app-portfolio-sideview>