<div class="title flex justify-between align-center">
    <span>Offer Letter Template</span> 
  </div>
  <div class="overpanel-scroll">
      <div class="panel panel-default setting">
          <div class="panel-body">
              <form #ols="ngForm" novalidate>
                <div class="form-group">
                  <input type="hidden" name="LOAN_PRODUCT_ID" [(ngModel)]="mobile.loan_product_id">
                  <app-ngx-editor [config]="editorConfig" name="OFFER_LETTER" [(ngModel)]="ol.OFFER_LETTER">
                  </app-ngx-editor>
                </div>
                <app-button type="submit" (click)="saveOL(ols.value, ols.valid)" [disabled]="loading" class="small"
                  label="Submit" [loading]="loading"></app-button>
              </form>
            </div>
        </div>
  </div>
  