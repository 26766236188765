import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-websimulate',
  templateUrl: './websimulate.component.html',
  styleUrls: ['./websimulate.component.css']
})
export class WebsimulateComponent implements OnInit {
  showModal = false;

  constructor() {
  }

  ngOnInit() {
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
}
