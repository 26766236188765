import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { DataService, OptionsserviceService, LoansService } from '../../_services/index';
import { StorageService, CustomerService, OperationsService } from '../../_services/index';
import { SavingsService } from '../../_services/savings.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDateModel, IMyInputFieldChanged, IMyCalendarViewChanged } from 'mydatepicker';
import { IMyInputFocusBlur, IMyMarkedDate, IMyDate, IMySelector } from 'mydatepicker';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booksavings',
  templateUrl: './booksavings.component.html',
  styleUrls: ['./booksavings.component.css'],
})
export class BooksavingsComponent implements OnInit {

  public query = '';
  public url = 'https://creditclanapis.creditclan.com/api/v2/customer/searchForSavingsCustomersapi';
  public params = {
    token: 'en',
    q: this.query
  };
  loading = false;
  currentUser: any;
  postSavingsForm: FormGroup;
  customers: any;
  selectedcustomer: any;
  savings_products: any;
  searchString = '';
  customerSelected = false;
  searchForCustomer = false;
  loan_duration = 'Months';
  interest_duration = 'Per Month';
  paymentHasBeenProcessed = false;
  result: any;
  savings = {
    'PRINCIPAL': 0,
    'TENOR': 0,
    'INTEREST': 0,
    'SAVINGS_PRODUCT_ID': 0,
    'FUNDS_RECEIVED_DATE': '',
    'REQUEST_PERIOD_ID': '2',
    'REQUEST_RATE_PERIOD_ID': '2',
  };
  public loan_durations = [{ 'LOAN_INTEREST_DURATION_ID': '1', 'LOAN_DURATION': 'Days', 'INTEREST_DURATION': 'Per Day', 'ADJECTIVAL': 'Daily', 'ABBREV': 'd' },
  { 'LOAN_INTEREST_DURATION_ID': '2', 'LOAN_DURATION': 'Months', 'INTEREST_DURATION': 'Per Month', 'ADJECTIVAL': 'Monthly', 'ABBREV': 'Mo' },
  { 'LOAN_INTEREST_DURATION_ID': '3', 'LOAN_DURATION': 'Years', 'INTEREST_DURATION': 'Per Year', 'ADJECTIVAL': 'Yearly', 'ABBREV': 'Yr' },
  { 'LOAN_INTEREST_DURATION_ID': '4', 'LOAN_DURATION': 'Weeks', 'INTEREST_DURATION': 'Per Week', 'ADJECTIVAL': 'Weekly', 'ABBREV': 'Wk' }];
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    height: '34px',
    width: '210px',
    dateFormat: 'yyyy-mm-dd',
    openSelectorTopOfInput: false,
  };
  public selDate: IMyDate = { year: 0, month: 0, day: 0 };
  public selDate_: IMyDate = { year: 0, month: 0, day: 0 };
  public searchedCustomerResult: any;
  constructor(public savingsService: SavingsService, public toastr: ToastrService,
    private dataService: DataService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService,
    public storageService: StorageService, public customerService: CustomerService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.params.token = this.currentUser.token;
    this.postSavingsForm = fb.group({
      'PRINCIPAL': [null, Validators.required],
      'SAVINGS_PRODUCT_ID': [null, Validators.required],
      'INTEREST': [null, Validators.required],
      'TENOR': [null, Validators.required],
      'FUNDS_RECEIVED_DATE': [null, Validators.required],
      'REQUEST_PERIOD_ID': [null, Validators.required],
      'REQUEST_RATE_PERIOD_ID': [null, Validators.required],
    });

  }

  ngOnInit() {
    this.savingsService.getInvProducts(this.currentUser.token).subscribe(result => this.savings_products = result);
  }
  // handleResultSelected(result) {
  //   this.search = result;
  // }
  searchCustomers() {
    if (this.searchString === '') {
      this.customers = {}
    } else {
      this.customerService.searchForSavingsCustomers(this.currentUser.token, this.searchString, '')
        .subscribe(result => {
          this.searchedCustomerResult = result;
        });
    }

  }
  selectCustomer(customer) {
    this.selectedcustomer = customer;
    this.customerSelected = true;
    this.searchForCustomer = false;
  }

  changeDuration(d, T) {
    if (T === 1) {
      this.loan_duration = this.loan_durations[d]['LOAN_DURATION'];
      this.savings.REQUEST_PERIOD_ID = this.loan_durations[d]['LOAN_INTEREST_DURATION_ID'];
      (<FormControl>this.postSavingsForm.controls['REQUEST_PERIOD_ID'])
        .setValue(d.LOAN_INTEREST_DURATION_ID, { onlySelf: true });
    }
    if (T === 2) {

      this.interest_duration = this.loan_durations[d]['INTEREST_DURATION'];
      this.savings.REQUEST_RATE_PERIOD_ID = this.loan_durations[d]['LOAN_INTEREST_DURATION_ID'];

      (<FormControl>this.postSavingsForm.controls['REQUEST_RATE_PERIOD_ID'])
        .setValue(d.LOAN_INTEREST_DURATION_ID, { onlySelf: true });

    }

  }
  paymentProcessDone() {

  }
  doBookSavings(value) {
    this.loading = true;
    this.paymentHasBeenProcessed = false;
    this.savingsService.bookSavings(this.currentUser.token, this.selectedcustomer, value)
      .subscribe(data => {
        if (data.status == true) {
          this.result = data;
          this.paymentHasBeenProcessed = true; 
        } else {
          this.paymentHasBeenProcessed = false;
          this.loading = false;
          this.showError('Unable to process request');
        }
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
}
