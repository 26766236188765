import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, DataService, LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() requestId;
  @Input('view_from') view_from = 'request';
  @Input('request_id') request_id: any;
  @Input('loan') loan:any;


comments: any;
adding_comment = false;
loading = false;
currentUser: any;
isloading = false
adding_task = false;
comment:any


  constructor(
    
    private loansService: LoansService,
    public route: ActivatedRoute, 
    private authService: AuthenticationService,
    private dataService: DataService,
    private storageService: StorageService,
    ) { 

      // this.request_id = route.snapshot.parent.params['id'];

      // this.dataService.sendBackLoanToCaller.subscribe(res => {
      //   this.loan = res.loan;
      // })
      this.currentUser = this.storageService.read<any>('currentUser');

    }

  ngOnInit() {
    this.getRecoveryComment()
  }

  saveComment(value, valid) {

    this.loading = true;

    console.log(this.comment);
    
    this.loansService.SaveComment(this.requestId, this.comment, this.currentUser.account_id)
      .subscribe(result => {
        this.loading = false;
        if (result.status === true || result.status === 200 ) {
          this.adding_comment = false;
          this.getRecoveryComment();
        }
      });
  }

  grantRight(right: String) {
    return this.authService.userHasRights(right);
  }


  getRecoveryComment() {
    this.isloading = true
    return this.loansService.recoveryComment(this.requestId)
    .subscribe(result => {
      this.isloading = false
      if (result.status === 200 && result.data.length>0) {
        this.comments = result.data;
      }
    });

    


    // switch (`${this.where}`) {
    //   case '1':
       
    //   case '2':
    //     this.sub = this.route.params.subscribe(params => {
    //       this.request_id = +params['id'];
    //       this.loansService.getRequestTimeline(this.currentUser.token, this.request_id)
    //         .subscribe(result => {
    //           if (result.status === true) {
    //             this.timeline = result.data;
    //           }
    //         });
    //     });
    //     break;
    //   case '3':
    //     return this.loansService.getCustomerTimeline(this.currentUser.token, this.request_id)
    //       .subscribe(result => {
    //         if (result.status === true) {
    //           this.timeline = result.data;
    //         }
    //       });
    //   default:
    //     break;
    // }
  }

}
