<div class="panel mb-1">
  <app-help-popover [content]="[
      {
        title: 'What is Contract details about?',
        img: 'assets/images/table.svg',
        text:
          '
        <div>
          <p>Detailed summary about your customers loan, its term and conditions and fees attached are displayed. </p>
        </div>'
      }
    ]"></app-help-popover>
  <div class="panel-body flex align-start">
    <div class="image-avatar large mr-4 ring" *ngIf="!embedModal">
      <img
        src="{{loan?.customer?.profile_pic | imagenotfound}}">
    </div>

    <div class="flex-1" *ngIf="!loan?.is_general_market" [ngClass]="{'ml-3': !embedModal}">
      <div class="label-value-list mb-2">
        <h4 class="section-title mb-5">Borrower's Contract Details</h4>
        <div class="item">
          <span class="label">Loan ID</span>
          <span class="value" *ngIf="!loading">{{ loan?.loan_id}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.customer?.branch}}</span>
        </div>
        <div class="item" *ngIf="loan?.customer?.branch!=''">
          <span class="label">Branch name</span>
          <span class="value" *ngIf="!loading">{{ loan?.customer?.branch}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.customer?.branch}}</span>
        </div>
        <div class="item" *ngIf="loan?.referal_code!=''">
          <span class="label">Referrer name</span>
          <span class="value" *ngIf="!loading">{{ loan?.referal_code}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.referal_code}}</span>
        </div>
        <div class="item">
          <span class="label">Product name</span>
          <span class="value" *ngIf="!loading">{{ loan?.loan_title || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="separator"></div>
        <div class="item">
          <span class="label">Required Credit</span>
          <span class="value" *ngIf="!loading">{{ loan?.request_principal | number: ".2-2" || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="item" *ngIf="loan?.desired_amount > 0">
          <span class="label">Desired Credit</span>
          <span class="value" *ngIf="!loading">{{ loan?.desired_amount | number: ".2-2" || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.desired_amount | number: ".2-2" }}</span>
        </div>
        <div class="item" *ngIf="loan?.has_equity">
          <span class="label">Equity</span>
          <span class="value" *ngIf="!loading">{{ loan?.equity | number: ".2-2" || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.equity | number: ".2-2" }}</span>
        </div>
        <div class="item" *ngIf="loan?.desired_duration > 0">
          <span class="label">Desired Duration</span>
          <span class="value" *ngIf="!loading">{{ loan?.desired_duration || 'N/A'}} Monthly</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.desired_duration | number: ".2-2" }}</span>
        </div>
        <div class="item">
          <span class="label">Duration</span>
          <span class="value" *ngIf="!loading">{{ loan?.request_tenor || 'N/A' }}
            {{ loan?.request_period_id|loanduration}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="item">
          <span class="label">Rate</span>
          <span class="value" *ngIf="!loading">{{ loan?.request_rate || 'N/A' }}
            {{ loan?.request_rate_period_id|interestduration}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="separator"></div>
        <div class="item">
          <span class="label">Fees & Charges</span>
          <span class="value" *ngIf="!loading">{{ loan?.total_fees_and_charges | number: ".2-2" || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="item">
          <span class="label">Total Interest</span>
          <span class="value" *ngIf="!loading">{{ loan?.total_interest | number: ".2-2" }}</span>
          <span class="value" *ngIf="!loading && !loan?.total_interest">{{ 'Not computed' }}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="separator"></div>
        <div class="item">
          <span class="label">Monthly Repayment</span>
          <span class="value" *ngIf="!loading">{{ loan?.monthly_repayment | number: ".2-2" }}</span>
          <span class="value" *ngIf="!loading && !loan?.monthly_repayment">{{ 'Not computed' }}</span>
          <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="item">
          <span class="label">Total Repayment</span>
          <span class="value" *ngIf="!loading">{{ loan?.total_repayment | number: ".2-2" }}</span>
          <span class="value" *ngIf="!loading && !loan?.total_repayment">{{ 'Not computed' }}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>
        <div class="label-value-list collapse" id="more">
          <div class="separator"></div>
          <div class="item">
            <span class="label">Purpose</span>
            <span class="value" *ngIf="!loading">{{ loan?.loan_purpose || 'N/A' }}</span> <span class="value skeleton"
              *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">Comments</span>
            <span class="value" *ngIf="!loading">{{ loan?.comments || 'No comments' }}</span> <span
              class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="separator"></div>
          <div class="item">
            <span class="label">Loan ID</span><span class="value"
              *ngIf="!loading">{{ loan?.loan_id || 'N/A' }}</span><span class="value skeleton"
              *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">Account Officer</span>
            <span class="value" *ngIf="!loading">{{ loan?.account_manager || 'Not assigned'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">Credit Officer</span>
            <span class="value" *ngIf="!loading">{{ loan?.loan_officer || 'Not assigned' }}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>

          <div class="separator"></div>
          <div class="item">
            <span class="label">Repayment Frequency</span><span class="value"
              *ngIf="!loading">{{ loan?.installment_cyle || 'N/A'}}</span>
          </div>
          <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          <div class="item">
            <span class="label">Repayment Type</span>
            <span class="value" *ngIf="!loading">{{ loan?.repayment_type || 'Nor selected' }}</span> <span
              class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">First Repayment Date</span>
            <span class="value" *ngIf="!loading">{{ loan?.repayment_started_when | date }}</span>
            <span class="value" *ngIf="!loading && !loan?.repayment_started_when">N/A</span>
            <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">Net Disbursment</span>
            <span class="value" *ngIf="!loading">{{ loan?.net_disbursement | number: ".2-2" }}</span>
            <!-- <span class="value" *ngIf="!loading && !loan?.net_disbursement">N/A</span> -->
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>
          <div class="item">
            <span class="label">Disbursement Date</span>
            <span class="value" *ngIf="!loading">{{ loan?.disbursement_date | date }}</span>
            <span class="value" *ngIf="!loading && !loan?.disbursement_date">N/A</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.disbursement_date | number: ".2-2" }}</span>
          </div>
        </div>
        <div class="separator"></div>
      </div>
      <div class="text-right mt-3">
        <button class="btn btn-outline-secondary btn-xs" type="button" data-toggle="collapse" data-target="#more"
          aria-expanded="false" aria-controls="more">
          More
        </button>
      </div>
    </div>
    <div class="flex-1 ml-3" *ngIf="loan?.is_general_market">
      <div class="label-value-list mb-2">
        <h4 class="section-title mb-5">Request Details</h4>

        <div class="separator"></div>
        <div class="item">
          <span class="label">Required Credit</span>
          <span class="value" *ngIf="!loading">{{ loan?.request_principal | number: ".2-2" || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>


        <div class="item">
          <span class="label">Duration</span>
          <span class="value" *ngIf="!loading">{{ loan?.request_tenor || 'N/A' }}
            {{ loan?.loan_duration || 'N/A'}}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
        </div>


        <div class="item">
          <span class="label">Total Offers</span>
          <span class="value" *ngIf="!loading">{{ loan?.total_offers || 'N/A' }}</span>
          <span class="value skeleton" *ngIf="loading">{{ loan?.total_offers }}</span>
        </div>


        <div class="separator"></div>


        <div *ngIf="loan?.accepted">


          <h4 class="section-title mb-5">Accepted Offer</h4>

          <div class="separator"></div>
          <div class="item">
            <span class="label">Lender</span>
            <span class="value" *ngIf="!loading">{{ loan?.accepted_lender.legal_name || 'N/A'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>


          <div class="item">
            <span class="label">Rate</span>
            <span class="value" *ngIf="!loading">{{ loan?.accepted?.bid_rate || 'N/A' }}%
              {{loan?.accepted?.bid_rate_period_id | interestduration}} </span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number: ".2-2" }}</span>
          </div>


        </div>
      </div>
      <div *ngIf="!loan.self">
        <div class="text-right mt-3" *ngIf="!loan.has_offer">
          <app-button class="outline medium" (click)="makeOfferForMarketLoan()" label="Make Offer"></app-button>

        </div>
        <div class="text-right mt-3" *ngIf="loan.has_offer">
          <app-button class="outline medium" (click)="makeOfferForMarketLoan()" label="Modify Offer"></app-button>

        </div>
      </div>
      <div *ngIf="loan.self">
        <div class="text-right mt-3">
          <app-button class="outline medium" (click)="viewOffers()" label="View Offers"></app-button>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-1" *ngIf="loan?.has_collateral">
  <app-collateral [from_where]='loan_contract'></app-collateral>
</div>
<div class="mb-1" *ngIf="loan?.show_statement">
  <div class="panel bg-off-white" *ngIf="loan?.attachment">
    <div class="alert padding-regular bg-off-white">
      <div class="texts no-padding">
        <h4 class="title color-yellow">Bank Statement Analysis</h4>
        <p class="subtitle">Name Check : {{loan?.attachment?.ANALYSIS_PDF?.message}}</p>
      </div>
      <div class="action">
        <a class="icon-button" target="_blank" href="{{loan?.attachment?.FILE_NAME}}"><i class="fa fa-download"></i></a>

      </div>
    </div>
  </div>
</div>