import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from '../../_services/constants.service';

@Component({
  selector: 'app-golive',
  templateUrl: './golive.component.html',
  styleUrls: ['./golive.component.css']
})
export class GoliveComponent implements OnInit {
  view: any = '';
  alias: string;
  primary_email: any = JSON.parse(localStorage.getItem('email'));
  email: string;
  phone_number: string;
  primary_phone_number: any = JSON.parse(localStorage.getItem('phone'));
  role: any;
  name: string;
  errorMessage: any;
  send_mail: any;
  loading = false;
  primary_address: any = JSON.parse(localStorage.getItem('address'));
  token: any = JSON.parse(localStorage.getItem('token'));

  constructor(
    private router: Router,
    private http: HttpClient,
    private constant: ConstantsService
  ) {
  }

  ngOnInit() {
    this.getSavedItems();
    //console.log(this.token);
  }

  getSavedItems() {
    const items = JSON.parse(localStorage.getItem('setup'));
    this.primary_phone_number = items.PHONE;
    this.primary_email = items.EMAIL;
  }

  confirm() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read(`api_base`)}`, {
      alias: this.alias,
      primary_phone_number: this.primary_phone_number,
      primary_email: this.primary_email,
      primary_address: this.primary_address,
    }).subscribe(res => {
      //console.log(res);
    }, error => {
      //console.log(error);
    });
  }

  sendInvite() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_base')}onboard/add_employee`, {
      name: this.name,
      email: this.email,
      role: this.role,
      token: this.token,
      send_mail: this.send_mail
    }).subscribe(res => {
      //console.log(res);
      this.loading = false;
      if (res.status) {
        this.view = 'add_more';
      } else {
        this.errorMessage = res.message;
      }
    }, error => {
      //console.log(error);
      this.loading = false;
    });
  }

  submit() {
    this.router.navigate(['/bookloan']);
  }

  goLive() {
    //console.log('hello');
  }
}
