import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ussdsimulate',
  templateUrl: './ussdsimulate.component.html',
  styleUrls: ['./ussdsimulate.component.css']
})
export class UssdsimulateComponent implements OnInit {
  view = '';
  sessionId: any;
  phoneNumber: any;
  text: any;
  serviceCode: any;

  constructor() {
  }

  ngOnInit() {
  }

  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    this.sessionId = result;
    localStorage.setItem('sessionId', this.sessionId);
    return result;
  }

  simulate() {
    this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    //console.log(this.sessionId);
    this.view = 'webussd';
  }
}
