<section *ngIf="view === 'list'">
  <div class="skeleton-children dark" *ngIf="loading">
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
  </div>


  <div *ngIf="!statement">
    <div class="h-group center mt-4 empty-state mt-5" *ngIf="!loading && !statements.length">
      <div class="icon"><i class="fa fa-meh-o"></i></div>
      <h2 class="title no-float">No sms analysis</h2>
      <app-button class="small outline mt-4" label="Initiate" (click)="openMore.emit('smsattachment')"></app-button>
    </div>

    <div *ngIf="!loading && statements.length">
      <div class="flex justify-end mb-4">
        <button class="btn btn-outline-secondary btn-sm" (click)="openMore.emit('smsattachment')"><i
            class="fa fa-plus mr-2"></i>Initiate</button>
      </div>
      <div class="panel" *ngFor="let statement of statements"> 
        <div class="panel-body padding-regular">
          <div class="flex align-center">
            <div class="section-poster flex-1">
              <div class="image flex-center">
                <img class="w-60" src="/assets/images/json-file.png" />
              </div>
            </div>
            <div class="h-group flex-1" style="margin-left: 50px;">
              <h3 class="title no-margin">{{ statement.start_date }} - {{ statement.end_date }}</h3>
              <p class="subtitle fill-w" *ngIf="statement.has_been_analyzed != 1">Not analyzed</p>
              <p class="subtitle fill-w" *ngIf="statement.has_been_analyzed == 1">Analysis done</p>
              <div class="subtitle no-margin">{{statement.date_added}}</div>
              <!-- <div class="subtitle no-margin" *ngIf="statement?.ANALYSED == '1'">{{statement.ANALYSIS_LAST_DONE}}</div>
              <div class="subtitle no-margin" *ngIf="statement?.SENT_FOR_ANALYSIS == '1'">Sent for Analysis</div> -->
              <div class="flex mt-3" *ngIf="statement.has_been_analyzed != 1">
                <app-button type="button" class="small outline teal" label="Run analysis"
                  (click)="runAnalytics(statement)"></app-button>
                <a class="btn btn-sm btn-outline-secondary ml-2" (click)="viewTransactions(statement)" style="cursor: pointer;">
                  View Transactions
                </a>
                <!-- <app-button type="button" class="btn btn-sm btn-outline-secondary ml-2" label="View Transactions"
                  (click)="viewTransactions(statement)">
                </app-button> -->
              </div>
              <div class="flex mt-3" *ngIf="statement.has_been_analyzed == 1">
                <app-button type="button" class="small green outline" label="View result"
                  (click)="viewResult(statement)">
                </app-button>
                <app-button type="button" class="small outline ml-1" label="Rerun analysis"
                  (click)="runAnalytics(statement)">
                </app-button>
                <a class="btn btn-sm btn-outline-secondary ml-2" (click)="viewTransactions(statement)" style="cursor: pointer;">
                  View Transactions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="statement && !viewing_transactions">
    <app-bank-statement-analysis-modal [from_where]="'2'" [statement]="statement" (cancel)="cancel()" (done)="done($event)" [reqid]="reqid"
      (viewResult)="viewResult($event)">
    </app-bank-statement-analysis-modal>
  </div>
  <div *ngIf="statement && viewing_transactions">
    <app-sms-view-transactions-modal [statement]="statement" (cancel)="cancel()" (done)="done($event)" [reqid]="reqid">
    </app-sms-view-transactions-modal>
  </div>
</section>

<section *ngIf="view === 'result'">
  <app-bank-statement-analysis-result [from_where]="2" [statement]="statement" (close)="cancel()"></app-bank-statement-analysis-result>
</section>