<section class="guarantors_numbers_container white-bg mt_5">
  <div class="guarantors_numbers_left">
    <div class="phone_icon">
      <img src="assets/images/mobile-phone.png" alt="Phone Icon" style="width: 100px">
    </div>
  </div>
  <div class="guarantors_numbers_container_right">
    <div style="text-align: right;
    border-bottom: 1px solid #ccc;"><input type="checkbox" [(ngModel)]="showHashed" name="showHashed"> <span style="margin-left: 7px">Show Numbers</span> </div>
   <div *ngIf="showHashed">
     <ul class="decList bigger" *ngFor="let contact of contacts" >
       <li><span class="text-primary"></span><span
         class="pull-right">{{contact}}</span></li>
     </ul>
   </div>
    <div *ngIf="!showHashed">
      <ul class="decList bigger" *ngFor="let contact of hashed_contacts" >
        <li><span class="text-primary"></span><span
          class="pull-right">{{contact}}</span></li>
      </ul>
    </div>
  </div>
</section>
