<div class="header">
  <div class="user">
    <div class="image">
      <img
        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">
    </div>
    <div class="content">
      <h4 class="title">{{loan?.LEGAL_NAME}}</h4>
      <p class="subtitle">{{loan?.REQUEST_PRINCIPAL | money}} | {{loan?.REQUEST_TENOR}}{{loan?.LOAN_DURATION}}</p>
    </div>
  </div>

  <div class="input-group" *ngIf="where=='cards'">
    <select class="form-control no-border" name="ACCOUNT_CARD_ID" [(ngModel)]="card_selected"
            (change)="changeTheDefaultCard($event)">
      <option selected disabled>Select Card</option>
      <option *ngFor="let card of cards" [ngValue]="card">{{card?.brand}}-{{card?.bin}}</option>
    </select>
    <div class="input-group-btn">
      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
    </div>
  </div> 
</div>
