<main *ngIf="!loading" id="app">
    <div class="wrapper" *ngIf="data">
        <div class="strip">
            Hover over sections to modify
        </div>
        <div class="section">
        </div>

        <div class="section float-right" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('logo')">
        <i class="fa fa-edit"></i> Edit Logo
      </button>
            <section class="logo text-right" style="flex-basis: 40%;">
                <img [src]="data?.settings.offer_letter_logo">

                <section class="address">
                    <ul>
                        <li class="address">
                            <p>{{ data?.lender?.website_address }}</p>
                        </li>
                        <li class="phone">
                            <p>{{ data?.lender?.website_phone }}</p>
                        </li>
                    </ul>
                </section>
            </section>
        </div>

        <section class="address" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('address')">
        <i class="fa fa-edit"></i> Edit Address
      </button>
            <ul style="width:50% !important">
                <li class="date" *ngIf="options?.date">
                    <p>{{ data?.loan?.DATE_ADDED | date }}</p>
                </li>
                <li class="name" *ngIf="options?.na">
                    <p>{{ data?.customer?.legal_name }}</p>
                </li>
                <li class="company" *ngIf="options.company">
                    <strong></strong>
                </li>
                <li class="address" *ngIf="options.address">
                    <p>{{ data?.customer?.home_address }},
                        <br> {{ data?.customer?.lga }}, {{ data?.customer?.state_name }}
                    </p>
                </li>
                <!-- <li class="phone">
          <small>{{ data?.customer?.phone }}</small>
        </li> -->
            </ul>
        </section>

        <section class="salutation" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('salutation')">
        <i class="fa fa-edit"></i> Edit salutation
      </button>
            <div class="content" [innerHTML]="data?.settings.offer_letter_salutation"></div>
        </section>

        <section class="body" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('subject')">
        <i class="fa fa-edit"></i> Edit Subject
      </button>
            <h4 class="heading" [innerHTML]="data?.settings.offer_letter_subject"></h4>
        </section>

        <section class="schedule" *ngIf="data?.settings.offer_letter_repayment === '1' && where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('schedule')">
        <i class="fa fa-edit"></i> Edit Schedule
      </button>
            <ul>
                <li class="heading">
                    <h4>Repayment Schedule</h4>
                </li>
                <li class="content">
                    <table class="table-striped">
                        <thead>
                            <tr class="text-center">
                                <th>Date</th>
                                <!-- <th>Opening Balance</th> -->
                                <th>Principal</th>
                                <th>Interest</th>
                                <th>Fees</th>
                                <th>Repayment</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let repayment of data?.repayment_schedule">
                                <td>
                                    <small>{{ repayment.REPAYMENT_DATE }}</small>
                                </td>
                                <!-- <td class="text-right">
                  <small>{{ repayment.INITIAL_PRINCIPAL }}</small>
                </td> -->
                                <td class="text-right">
                                    <small>{{ repayment.PRINCIPAL_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.INTEREST_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.FEES | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.HOW_MUCH_REMAINING | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.OUTSTANDING_PRINCIPAL | number:'.2-2' }}</small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
        </section>

        <section class="body" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('body')">
        <i class="fa fa-edit"></i> Edit Introduction
      </button>
            <div class="content" [innerHTML]="introductionHTML"></div>
        </section>

        <!-- <section class="body">
    </section> -->

        <section class="tags" *ngIf="where_from != 'savings_offer_letter'">
            <h4 class="heading">General Terms and Conditions</h4>
            <button class="edit-button" (click)="editSection('tags')">
        <i class="fa fa-edit"></i> Edit Loan Tags
      </button>
            <table class="table-fluid">
                <tbody>
                    <tr *ngFor="let item of data?.settings.offer_letter_tags">
                        <td>
                            <small><b>{{ item.tag }}</b></small>
                        </td>
                        <td class="text-wrap">
                            <small *ngIf="item.tag === 'Principal' || item.tag === 'Introduction Fee' || item.tag === 'Commitment Fee' else templateName">{{ item.value }}</small>

                            <ng-template #templateName>
                                <small>{{ item.value }}</small>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>

        <section class="schedule" *ngIf="(data?.settings.offer_letter_repayment === '2' || !data?.settings.offer_letter_repayment) &&  where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('schedule')">
        <i class="fa fa-edit"></i> Edit Schedule
      </button>
            <ul>
                <li class="heading">
                    <h4 class="no-margin-top">Repayment Schedule</h4>
                </li>
                <li class="content">
                    <table class="table-striped">
                        <thead>
                            <tr class="text-center">
                                <th>Date</th>
                                <!-- <th>Opening Balance</th> -->
                                <th>Principal</th>
                                <th>Interest</th>
                                <th>Fees</th>
                                <th>Repayment</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let repayment of data?.repayment_schedule">
                                <td>
                                    <small>{{ repayment.REPAYMENT_DATE }}</small>
                                </td>
                                <!-- <td class="text-right">
                  <small>{{ repayment.INITIAL_PRINCIPAL }}</small>
                </td> -->
                                <td class="text-right">
                                    <small>{{ repayment.PRINCIPAL_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.INTEREST_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.FEES | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.HOW_MUCH_REMAINING | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.OUTSTANDING_PRINCIPAL | number:'.2-2' }}</small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
        </section>

        <section class="terms_and_conditions">
            <button class="edit-button" (click)="editSection('terms')">
        <i class="fa fa-edit"></i> Edit Contract Terms
      </button>
            <div class="content" [innerHTML]="offerLetterTermHTML" *ngIf="offerLetterTermHTML"></div>
        </section>

        <section class="schedule" *ngIf="data?.settings.offer_letter_repayment === '3' &&  where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('schedule')">
        <i class="fa fa-edit"></i> Edit Schedule
      </button>
            <ul>
                <li class="heading">
                    <h4 class="no-margin-top">Repayment Schedule</h4>
                </li>
                <li class="content">
                    <table class="table-striped">
                        <thead>
                            <tr class="text-center">
                                <th>Date</th>
                                <!-- <th>Opening Balance</th> -->
                                <th>Principal</th>
                                <th>Interest</th>
                                <th>Fees</th>
                                <th>Repayment</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let repayment of data?.repayment_schedule">
                                <td>
                                    <small>{{ repayment.REPAYMENT_DATE }}</small>
                                </td>
                                <!-- <td class="text-right">
                  <small>{{ repayment.INITIAL_PRINCIPAL }}</small>
                </td> -->
                                <td class="text-right">
                                    <small>{{ repayment.PRINCIPAL_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.INTEREST_REPAYMENT | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.FEES | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.HOW_MUCH_REMAINING | number:'.2-2' }}</small>
                                </td>
                                <td class="text-right">
                                    <small>{{ repayment.OUTSTANDING_PRINCIPAL | number:'.2-2' }}</small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
        </section>

        <section class="remark" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('signature')">
        <i class="fa fa-edit"></i> Edit Remark & Signature
      </button>
            <p>Yours {{ data?.settings?.remark == '1' ? 'Sincerely' : 'Faithfully' }},
                <br> For. {{ data?.lender?.LEGAL_NAME }}
            </p>
            <br>
            <div class="signatures-wrap">
                <div class="signatures" *ngIf="signatures.signature1Name">
                    <ul>
                        <li class="signature">
                            <img [src]="signatureimage1">
                        </li>
                        <li class="name">
                            <div>{{ signatures.signature1Name }}</div>
                        </li>
                        <li class="position">
                            <strong>{{ signatures.signature1Position }}</strong>
                        </li>
                    </ul>
                </div>

                <div class="signatures text-right" *ngIf="signatures.signature2">
                    <ul>
                        <li class="signature">
                            <img [src]="signatureimage2">
                        </li>
                        <li class="name">
                            <div>{{ signatures.signature2 }}</div>
                        </li>
                        <li class="position">
                            <strong>{{ signatures.signature2Position }}</strong>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
        <!-- <section class="customer" *ngIf="data.date_accepted"> -->
        <section class="customer" *ngIf="where_from != 'savings_offer_letter'">
            <button class="edit-button" (click)="editSection('customer')">
        <i class="fa fa-edit"></i> Edit Customer
      </button>
            <p>{{ data?.settings?.body }}</p>
            <ul>
                <li>
                    <div [ngClass]="{'signature-font': data.settings.name === 'italicized'}">
                        {{ data?.customer?.legal_name }}
                    </div>
                </li>
                <li>
                    <!-- <div>{{ data?.date_accepted | date}}</div> -->
                    <div *ngIf="data?.settings?.dateVisible === 'yes'">{{ data?.date_accepted | date }}</div>
                </li>
            </ul>
        </section>

        <div class="section">
            <!-- <section class="accept_title">
        <ul>
          <li class="name">
            <h4 class="no-margin">{{ data?.customer?.legal_name }}</h4>
          </li>
          <li class="title">
            <h2 class="no-margin">Offer Letter</h2>
          </li>
        </ul>
      </section> -->

            <!-- <section class="accept_contract text-center">
        <button type="button" class="btn" @click="acceptOffer()">Accept Offer</button>
        <button type="button" class="btn clear" @click="rejectOffer()">Reject Offer</button>
      </section> -->
        </div>
    </div>
</main>
<!-- <div *ngIf="loading">
  <div class="panel mb-1 no-padding">
    <div class="alert padding-regular bg-off-white">
      <div class="texts">
        <div class="title skeleton mb-1">Loading</div>
      </div>
    </div>
  </div>
</div> -->