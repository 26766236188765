<div class="row">
  <div class="col-md-12">
    <!-- <app-contractheader [nooptions]=true></app-contractheader> -->
    <app-homenavigation></app-homenavigation>

    <!-- <section class="panel mb-1" *ngIf="!loan?.schedule_has_been_created">
      <div class="alert padding-regular bg-off-white">
        <div class="icon color-red">
          <i class="fa fa-exclamation-circle"></i>
        </div>
        <div class="texts">
          <h3 class="title">Invite a Customer</h3>
          <p class="subtitle">It would be create to invite new members</p>
        </div>
        <div class="action">
          <app-button class="small outline green" label="Share Link" (click)="inviteNewCustomer()"></app-button>
        </div>
      </div>
    </section> -->
    <div class="panel mb-1 border-red" *ngIf="!loading_approvals && pending_approvals?.has_data==true">
      <div class="alert padding-regular bg-off-white">
        <div class="texts no-padding">
          <div class="title color-red">Pending Approval</div>
          <div class="subtitle">See how your portfolio is doing</div>
        </div>
        <div class="action">
          <div class="image-avatar-list medium">
            <div class="image-avatar ring red" *ngFor="let data of pending_approvals?.data">
              <img
                [src]="'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/'+data.file_name">
            </div>
          </div>
          <div class="circle medium border flex-center grey" *ngIf="numbers_left > 0">
            {{numbers_left}}+
          </div>
        </div>
      </div>
    </div>
    <div class="panel mb-1" *ngIf="!loading_approvals && pending_approvals?.has_data==false">
      <div class="h-group center mt-4 empty-state mt-5"><div class="icon"><i class="fa fa-meh-o"></i></div><h2 class="title no-float">No approvals</h2></div>
    </div>
    <div *ngIf="loading_approvals">
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white"> 
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div> 
      </div> 
    </div>
    <app-tat [token]="currentUser?.token" *ngIf="!loading_approvals && pending_approvals?.has_data==false"
      [token]="token" [url]="url"></app-tat>

    <app-oldestapproval [token]="currentUser?.token" *ngIf="!loading_approvals && pending_approvals?.has_data==true"
      [token]="token" [url]="url"></app-oldestapproval>

    <app-approvalcalendar [token]="currentUser?.token"></app-approvalcalendar>
  </div> 
</div>