<!-- <div class="section-title mb-5 text-center">Block chain</div> -->

<section *ngIf="view === 'form'">
  <div class="panel padding-regular flex">
    <div class="flex-1">
      <div class="h-group">
        <div class="title mb-2">Blockchain Analysis</div>
        <div class="subtitle no-margin medium-text-size">Get more data about this customer from different sources.</div>
      </div>
    </div>
    <div class="flex-1">
      <label class="form-group">
        <input type="text" class="form-control" placeholder="BVN number">
      </label>
      <label class="form-group">
        <input type="text" class="form-control" placeholder="IPPIS number">
      </label>
      <label class="form-group">
        <input type="checkbox" />
        <div class="control-label normal">Skip IPPIS</div>
      </label>
      <app-button class="medium mt-3" label="Analyze" (click)="view = 'result'"></app-button>
    </div>
  </div>
</section>

<section *ngIf="view === 'result'">
  <div class="section-title mb-4 cursor-pointer" (click)="view = 'form'">
    <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
  </div>
  <div class="panel flex justify-between">
    <div class="alert">
      <div class="texts no-padding">
        <div class="title">Remita</div>
        <div class="subtitle text-italic">Fetching data..</div>
      </div>
    </div>
    <div class="three-dots-loader small">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
  </div>
  <div class="panel flex justify-between">
    <div class="alert">
      <div class="texts no-padding">
        <div class="title">Creditclan</div>
        <div class="subtitle text-italic">Fetching data..</div>
      </div>
    </div>
    <div class="three-dots-loader small">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
  </div>
  <div class="panel">
    <div class="alert flex justify-between">
      <div class="texts no-padding">
        <div class="title">Credit bureau</div>
        <div class="subtitle text-italic">Fetched successfully</div>
      </div>
    </div>
    <div>
      <table class="table table-sm table-bordered table-striped table-responsive no-margin">
        <tr>
          <th>Lender</th>
          <th>Principal</th>
          <th>Duration</th>
          <th>Outstanding</th>
          <th>Status</th>
          <th>Max days late</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="panel flex justify-between">
    <div class="alert">
      <div class="texts no-padding">
        <div class="title">IPPIS</div>
        <div class="subtitle text-italic">Fetching data..</div>
      </div>
    </div>
    <div class="three-dots-loader small">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
  </div>
  <div class="panel flex justify-between">
    <div class="alert">
      <div class="texts no-padding">
        <div class="title">External sources</div>
        <div class="subtitle text-italic">Fetching data..</div>
      </div>
    </div>
    <div class="three-dots-loader small">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
  </div>
</section>