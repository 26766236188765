<div class="panel">
  <div class="filter__wrapper padding-regular-x">
    <div class="links__wrapper">
      <h4 class="section-title flex flex-row align-center">Invitation History</h4>
    </div>

    <div class="filter_input">
      <input type="text" name="keyword" [(ngModel)]="keyword" (keyup.enter)="searchInvites()"
        class="form-control search_input" placeholder="Search Here">
      <i class="fa fa-search search_icon" aria-hidden="true"></i>
    </div>
  </div>

  <div *ngIf="loading">
    <div class="customer" *ngFor="let customer of [0,1]">
      <div class="header">
        <a class="img-wrap cursor-pointer">
          <img
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
        </a>

        <div class="content">
          <div class="name cursor-pointer skeleton mb-1">
            Sample Customer
          </div>
          <div class="type skeleton">Sample Customer</div>
        </div>

        <div class="toggle flex-center">
          <button class="icon-button" type="button" data-toggle="collapse" aria-expanded="false"
            aria-controls="collapseExample">
            <i class="fa fa-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading">
    <div *ngIf="invites.length > 0" class="customers">
      <div *ngFor="let customer of invites; index as i" [id]="i+'_item'" class="customer">
        <div class="header">
          <a class="img-wrap cursor-pointer">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{customer?.file_name | imagenotfound}}">
          </a>

          <div class="content">
            <div class="name cursor-pointer">
              {{customer?.name}}
            </div>
            <div class="type">{{customer?.email}}</div>
            <p>{{customer?.date_added | amTimeAgo}}</p>
          </div>

          <div [attr.data-target]="i+'_preview_pane'" [attr.data-parent]="i+'_item'" class="toggle flex-center">
            <button class="icon-button ml-2" (click)="showInvitePreview($event, i)" type="button" data-toggle="collapse"
              [attr.data-target]="'#'+i+'_preview_pane'" aria-expanded="false" aria-controls="collapseExample">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
        </div>

        <div class="body collapse" [id]="i+'_preview_pane'">
          <form #invitesform="ngForm" novalidate>
            <div>
              <ul class="decList bigger mb-4">
                <li>
                  <span>Sent When</span>
                  <span>{{customer?.date_added | date:'fullDate'}}</span>
                </li>
                <li>
                  <span>Activated When</span>
                  <span>{{customer?.date_used ? (customer?.date_used | date:'fullDate') : 'Not Active'}}</span>
                </li>
                <li>
                  <span>Loans</span>
                  <span>{{customer?.loans}} {customer?.loans, plural, =0 {Request} =1 {Request} other {Requests}}</span>
                </li>
              </ul>

              <div class="row">
                <div class="col-md-6">
                  <app-button (click)="todo(customer)" class="medium outline block" label="Todo" type="button">
                  </app-button>
                </div>
                <div class="col-md-6" *ngIf="customer?.date_used!=''">
                  <app-button (click)="viewProfile(customer)" class="medium block" label="View Profile" type="button">
                  </app-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- <div class="padding-regular">
      <div class="h-group center flex-center flex-column">
        <div class="section-poster">
          <div class="image flex-center">
            <img src="assets/images/share.svg" style="width: 50%">
          </div>
        </div>

        <h3 class="title medium">Invite a borrower</h3>
        <p class="subtitle mt-1">It would be great to invite new members</p>

        <div class="text-center mt-4 width_1_2">
          <app-button class="block medium" label="Share Link" (click)="inviteNewCustomer()"></app-button>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <app-timer [endDate]="'1561676400000'"></app-timer> -->
</div>