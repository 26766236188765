import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '../app/app.component';
import { LoginComponent } from '../app/login/login.component';
import { SignupComponent } from '../app/signup/signup.component';
import { CustomerdetailsComponent } from './customerdetails/customerdetails.component';
import { CreditComponent } from '../app/credit/credit.component';
import { CreditdashboardComponent } from '../app/creditdashboard/creditdashboard.component';
import { LoansComponent } from '../app/loans/loans.component';
import { CustomerUpdateComponent } from '../app/customers/customer-update/customer-update.component';
import { CustomerCreateComponent } from './customers/customer-create/customer-create.component';
import { RepaymentComponent } from '../app/repayment/repayment.component';
import { ScheduleComponent } from '../app/schedule/schedule.component';
import { RequestsComponent } from '../app/requests/requests.component';
import { LoanscheduleComponent } from '../app/loanschedule/loanschedule.component';
import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';
import { InvestmentdetailsComponent } from '../app/investmentdetails/investmentdetails.component';
import { InvestmentscheduleComponent } from './investmentschedule/investmentschedule.component';
import { InvestmentborrowerComponent } from './investmentborrower/investmentborrower.component';
import { InvestmentComponent } from './investment/investment.component';
import { AccoutreportsComponent } from '../app/accoutreports/accoutreports.component';
import { CustomerPhotosComponent } from './customers/customer-photos/customer-photos.component';
import { StatementscheduleComponent } from './statementschedule/statementschedule.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoggedinGuard } from './_guards/loggedin.guard';
import { SetupComponent } from './createprofile/setup/setup.component';
import { BookloanComponent } from './createprofile/bookloan/bookloan.component';
import { GoliveComponent } from './createprofile/golive/golive.component';
import { TermsComponent } from './createprofile/terms/terms.component';
import { BackendsimulateComponent } from './createprofile/simulate/backendsimulate/backendsimulate.component';
import { AddmemberComponent } from './createprofile/addmember/addmember.component';
import { WebsimulateComponent } from './createprofile/simulate/websimulate/websimulate.component';
import { UssdsimulateComponent } from './createprofile/simulate/ussdsimulate/ussdsimulate.component';
import { SetupuserComponent } from './createprofile/setupuser/setupuser.component';
import { ActivateComponent } from './createprofile/activate/activate.component';
import { MobilesimulateComponent } from './createprofile/simulate/mobilesimulate/mobilesimulate.component';
import { WelcomeComponent } from './createprofile/welcome/welcome.component';
import { SetupdashboardComponent } from './createprofile/setupdashboard/setupdashboard.component';
import { SetupussdComponent } from './createprofile/setupussd/setupussd.component';
import { SetupwidgetComponent } from './createprofile/setupwidget/setupwidget.component';
import { SetupintegrationsComponent } from './createprofile/setupintegrations/setupintegrations.component';
import { SetupeligibilityComponent } from './createprofile/setupeligibility/setupeligibility.component';
import { SetupbankstatementComponent } from './createprofile/setupbankstatement/setupbankstatement.component';
import { SetupaddressComponent } from './createprofile/setupaddress/setupaddress.component';
import { SetupsalesComponent } from './createprofile/setupsales/setupsales.component';
import { HomeComponent } from './home/home.component';
import { HomeRequestComponent } from './home-request/home-request.component';
import { HomeApprovalComponent } from './home-approval/home-approval.component';
import { NotifyComponent } from './notify/notify.component';
import { TaskComponent } from './task/task.component';
import { CreatedTaskComponent } from './created-task/created-task.component';
import { OpenapprovalComponent } from './openapproval/openapproval.component';
import { CreditclanproductsComponent } from './creditclanproducts/creditclanproducts.component';
import { FeaturesComponent } from './features/features.component';
import { EmailtemplatedesignComponent } from './setting-module/emailtemplatedesign/emailtemplatedesign.component';
import { RequestpageguardGuard } from './_guards/requestpageguard.guard';
import { AuthorizeComponent } from './authorize/authorize.component';
import { HomeportfolioComponent } from './homeportfolio/homeportfolio.component';
import { HomesummaryComponent } from './homesummary/homesummary.component';
import { ClosedpartnerlloansComponent } from './closedpartnerlloans/closedpartnerlloans.component';
import { LoanarrearsageingComponent } from './loanarrearsageing/loanarrearsageing.component';
import { LoanreportComponent } from './loanreport/loanreport.component';
import { PrincipaloutstandingComponent } from './principaloutstanding/principaloutstanding.component';
import { MerchantwalletComponent } from './merchanttransfers/merchantwallet/merchantwallet.component';
import { NibbsmandateComponent } from './nibbsmandate/nibbsmandate.component';
import { NibbsmandatedebitComponent } from './nibbsmandatedebit/nibbsmandatedebit.component';
import { WemainflowsComponent } from './wemainflows/wemainflows.component';
import { InstantmandatedebitlogComponent } from './instantmandatedebitlog/instantmandatedebitlog.component';

const routes: Routes = [
  { path: 'principaloutstanding', component: PrincipaloutstandingComponent },
  { path: 'instantmandate', component: NibbsmandateComponent },
  { path: 'instantmandatedebit', component: NibbsmandatedebitComponent },
  { path: 'instantmandatedebitlogs', component: InstantmandatedebitlogComponent },
  //{ path: 'merchantwallet', component: MerchantwalletComponent },
  { path: 'loanreport', component: LoanreportComponent },
  { path: 'wema_inflows', component: WemainflowsComponent },
  { path: 'closedloans', component: ClosedpartnerlloansComponent }, 
  { path: 'arrearsageing', component: LoanarrearsageingComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'system/settings/template-design', component: EmailtemplatedesignComponent },
  {
    path: 'system/settings',
    loadChildren: '../app/setting-module/setting-module.module#SettingModuleModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'home', component: HomeComponent,
    children: [
      { path: '', redirectTo: 'summary', pathMatch: 'full' },
      { path: 'summary', component: HomesummaryComponent },
      { path: 'request', component: HomeRequestComponent },
      { path: 'portfolio', component: HomeportfolioComponent },
      { path: 'approval', component: HomeApprovalComponent },
      { path: 'notifications', component: NotifyComponent },
      { path: 'task', component: CreatedTaskComponent },
      { path: 'openedtask', component: TaskComponent },
      { path: 'approval/requests/:id', component: HomeApprovalComponent},
      { path: 'request/requests/:id', component: HomeRequestComponent}
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'applications',
    loadChildren: '../app/applications/applications.module#ApplicationsModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'merchantwallet',
    loadChildren: '../app/merchanttransfers/merchanttransfers.module#MerchanttransfersModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'treasury',
    loadChildren: '../app/treasury/treasury.module#TreasuryModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'loanapplications',
    loadChildren: '../app/loanapplications/loanapplications.module#LoanapplicationsModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'portfolio',
    loadChildren: '../app/applicationportfolio/applicationportfolio.module#ApplicationportfolioModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'saving/:id',
    loadChildren: '../app/saving/saving.module#SavingModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  }, {
    path: 'loan/:id',
    loadChildren: '../app/loan/loan.module#LoanModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'product/:id',
    loadChildren: '../app/product/product.module#ProductModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'authorize/:id', component: AuthorizeComponent },
  { path: 'creditclan_products', component: CreditclanproductsComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'signup', component: SignupComponent },

  { path: 'welcome', component: WelcomeComponent },
  // { path: 'web', component: SetupComponent },
  { path: 'web', component: SetupwidgetComponent },
  { path: 'sources', component: SetupComponent },
  { path: 'integrations', component: SetupintegrationsComponent },
  { path: 'dashboard', component: SetupdashboardComponent },
  { path: 'eligibility', component: SetupeligibilityComponent },
  { path: 'bankstatement', component: SetupbankstatementComponent },
  { path: 'address', component: SetupaddressComponent },
  { path: 'sales', component: SetupsalesComponent },
  // { path: 'setup2', component: SetupwidgetComponent },
  // { path: 'setup3', component: SetupdashboardComponent },
  // { path: 'setup4', component: SetupussdComponent },
  { path: 'user', component: SetupuserComponent },
  { path: 'activate', component: ActivateComponent },
  { path: 'golive', component: GoliveComponent },
  { path: 'addmember', component: AddmemberComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'bookloan', component: BookloanComponent },
  { path: 'simulate/web', component: WebsimulateComponent },
  { path: 'simulate/mobile', component: MobilesimulateComponent },
  {
    path: 'simulate/ussd', component: UssdsimulateComponent
  },
  { path: 'simulate/backend', component: BackendsimulateComponent },

  {
    path: '', component: CreditdashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren: '../app/change-password/change-password.module#ChangePasswordModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'security',
    loadChildren: '../app/security/security.module#SecurityModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'portfolio', component: PortfolioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'porfolio', component: PortfolioComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'credit', component: CreditComponent,
    children: [
      { path: '', redirectTo: 'creditdashboard', pathMatch: 'full' },
      { path: 'requests', component: LoansComponent },
      { path: 'creditdashboard', component: CreditdashboardComponent },
      { path: 'schedule/:id', component: ScheduleComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'credit/schedule/:id', component: ScheduleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/create/:type', component: CustomerCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/update/:type/:id', component: CustomerUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: '../app/reports/reports.module#ReportsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'crm',
    loadChildren: '../app/crm/crm.module#CrmModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'serviced',
    loadChildren: '../app/serviced/serviced.module#ServicedModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'marketplace',
    loadChildren: '../app/marketplace/marketplace.module#MarketplaceModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: '../app/chat/chat.module#ChatModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'setup',
    loadChildren: '../app/onboarding/onboarding.module#OnboardingModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'investments',
    loadChildren: '../app/investments/investments.module#InvestmentsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'statement/:id',
    loadChildren: '../app/statement/statement.module#StatementModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/:type/:id',
    loadChildren: '../app/people/people.module#PeopleModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'investment/:id',
    loadChildren: '../app/investment/investment.module#InvestmentModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'operations',
    loadChildren: '../app/operations/operations.module#OperationsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'accounting',
    loadChildren: '../app/accounting/accounting.module#AccountingModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'savings',
  //   loadChildren: '../app/savings/savings.module#SavingsModule',
  //   data: {
  //     preload: true
  //   },
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'savings',
    loadChildren: '../app/customersavings/customersavings.module#CustomersavingsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'treasury',
    loadChildren: '../app/treasury/treasury.module#TreasuryModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'cooperatives',
    loadChildren: '../app/cooperatives/cooperatives.module#CooperativesModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'requests',
    loadChildren: '../app/requests/requests.module#RequestsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'savingsrequests',
    loadChildren: '../app/savingsrequests/savingsrequests.module#SavingsrequestsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: '../app/settings/settings.module#SettingsModule',
    data: {
      preload: true
    }
  },
  {
    path: 'loanproducts',
    loadChildren: '../app/loanproducts/loanproducts.module#LoanproductsModule',
    data: {
      preload: true
    }
  },
  {
    path: 'investmentproducts',
    loadChildren: '../app/investmentproducts/investmentproducts.module#InvestmentproductsModule',
    data: {
      preload: true
    }
  },
  {
    path: 'settings/mobile',
    loadChildren: '../app/mobile/mobile.module#MobileModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/web',
    loadChildren: '../app/web/web.module#WebModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/decision-engine',
    loadChildren: '../app/decision/decision.module#DecisionModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/investors',
    loadChildren: '../app/investors/investors.module#InvestorsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/ussd',
    loadChildren: '../app/mobile/mobile.module#MobileModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/backend',
    loadChildren: '../app/backend/backend.module#BackendModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'operations/products',
    loadChildren: '../app/products/products.module#ProductsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'savings/settings/savings_products',
    loadChildren: '../app/invproducts/invproducts.module#InvproductsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc',
    loadChildren: '../app/kyc/kyc.module#KycModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'investments',
    loadChildren: '../app/investments/investments.module#InvestmentsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: '../app/messages/messages.module#MessagesModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'pickup',
    loadChildren: '../app/pickup/pickup.module#PickupModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'unauthorized', component: UnauthorizedComponent
  },
  {
    path: 'account_reports', component: AccoutreportsComponent,
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: 'applications/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
