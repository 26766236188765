<section class="panel" style="overflow: visible">
  statementschedule
  <div class="panel-body" *ngIf="showHeader">
    <header class="flex justify-between align-center border-bottom">
      <!-- <h3 class="section-title">Loan Schedule</h3> -->
      <div class="flex flex-1">
        <div class="flex-1 flex-center" (click)="changePortfolioView('statement')">
          <div class="icon-text medium cursor-pointer">
            <div class="text">Statement</div>
          </div>
        </div>
        <div class="flex-1 flex-center" (click)="changePortfolioView('schedule')">
          <div class="icon-text medium cursor-pointer active">
            <div class="text">Schedule</div>
          </div>
        </div>
      </div>
    </header>
  </div>
  <div class="padding-regular-x" [ngClass]="{'mt-4': !showHeader}" *ngIf="where !='6'">
    <div class="flex flex-row mb-4 panel pd_15">
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Repayment:</span>
        <span class="default-text-size">{{ getTotal('TERM_REPAYMENT', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Fines:</span>
        <span class="default-text-size">
          {{ getTotal('TOTAL_FINES_SO_FAR', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Paid:</span>
        <span class="default-text-size">{{ getTotal('HOW_MUCH_PAID', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
    </div>
    <div class="panel-table mt-3 pd_15">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Repayment</th>
            <th>Fines</th>
            <th>Paid</th>
            <th>Balance</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let repayment of repayments?.repayments">
            <td>
              {{ repayment?.REPAYMENT_DATE | amDateFormat: 'DD MMM YYYY' }}
            </td>
            <!-- <td>{{repayment?.INITIAL_PRINCIPAL | number:'.2-2'}}</td> 
              <td>{{repayment?.PRINCIPAL_REPAYMENT| number:'.2-2'}}</td> 
              <td>{{repayment?.INTEREST_REPAYMENT| number:'.2-2'}}</td> -->
            <td>{{ repayment?.TERM_REPAYMENT | number: '.2-2' }}</td>
            <!-- <td>{{repayment?.OUTSTANDING_PRINCIPAL < 1 ? 0 : repayment?.OUTSTANDING_PRINCIPAL| number:'.2-2'}}</td> -->
            <td>{{ repayment?.TOTAL_FINES_SO_FAR | number: '.2-2' }}</td>
            <td>{{ repayment?.HOW_MUCH_PAID | number: '.2-2' }}</td>
            <td>{{ repayment?.HOW_MUCH_REMAINING | number: '.2-2' }}</td>
            <td style="max-width: 60px;">
              <div class="btn-group" >
                <button data-toggle="dropdown" class="btn btn-white btn-xs dropdown-toggle" type="button"
                  aria-expanded="false">
                  <span class="caret"></span>
                </button>
                <ul role="menu" class="dropdown-menu dropdown-menu-right">
                  <li>
                    <a (click)="paytheinterest(repayment)">Pay The Interest</a>
                  </li>
                  <li *ngIf="repayment?.HOW_MUCH_REMAINING > 0">
                    <a (click)="initiate_recollection(repayment)">Push for Card Recollection</a>
                  </li>
                  <li *ngIf="repayment?.TOTAL_FINES_SO_FAR > 0">
                    <a (click)="initiateClearFines(repayment)">Clear Fines</a>
                  </li>
                  <li *ngIf="
                    loan?.USE_REMITA == '1' &&
                    loan?.REMITA_MANDATE_REF != '' &&
                    loan?.REPAYMENT_MODE == '1'
                  ">
                    <a (click)="initiateStopRemitaCollection(repayment)">Stop Remita Inflight</a>
                  </li>
                  <li *ngIf="
                    loan?.DIRECT_DEBIT_STATUS == '1' &&
                    repayment?.DIRECT_DEBIT_INSTRUCTION_SENT != '1' &&
                    loan?.REPAYMENT_MODE == '3'
                  ">
                    <a (click)="initiateSendDebitInstruction(repayment)">Send Debit Instruction</a>
                  </li>
                  <li *ngIf="
                    loan?.DIRECT_DEBIT_STATUS == '1' &&
                    repayment?.DIRECT_DEBIT_INSTRUCTION_SENT == '1' &&
                    loan?.REPAYMENT_MODE == '3'
                  ">
                    <a (click)="initiateCheckDebitInstruction(repayment)">Check Direct Debit Status</a>
                  </li>
                  <li *ngIf="
                    loan?.DIRECT_DEBIT_STATUS == '1' &&
                    repayment?.DIRECT_DEBIT_INSTRUCTION_SENT == '1' &&
                    loan?.REPAYMENT_MODE == '3'
                  ">
                    <a (click)="initiateCancelDebitInstruction(repayment)">Cancel Direct Debit Instruction</a>
                  </li>
                  <li>
                    <a (click)="initiateReverseTransaction(repayment)">Reverse Transaction</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <!--  <td></td> -->
            <!-- <td>
            {{ getTotal('PRINCIPAL_REPAYMENT', repayments?.repayments) | number: '.2-2' }}
          </td>
          <td>
            {{ getTotal('INTEREST_REPAYMENT', repayments?.repayments) | number: '.2-2' }}
          </td> -->
            <td>
              {{ getTotal('TERM_REPAYMENT', repayments?.repayments) | number: '.2-2' }}
            </td>
            <td>
              {{ getTotal('TOTAL_FINES_SO_FAR', repayments?.repayments) | number: '.2-2' }}
            </td>
            <td>
              {{ getTotal('HOW_MUCH_PAID', repayments?.repayments) | number: '.2-2' }}
            </td>
            <td>
              {{ getTotal('HOW_MUCH_REMAINING', repayments?.repayments) | number: '.2-2' }}
            </td>
          </tr>
        </tfoot>
      </table>

      <div class="pd_15 mt-4 text-right">
        <button (click)="viewExpandedSchedule()" class="btn btn-outline-secondary">View More</button>
      </div>
    </div>
  </div>
  <div class="padding-regular-x" [ngClass]="{'mt-4': !showHeader}" *ngIf="where =='6'">
    <!-- <div class="flex flex-row mb-4 panel pd_15">
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Repayment:</span>
        <span class="default-text-size">{{ getTotal('TERM_REPAYMENT', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Fines:</span>
        <span class="default-text-size">
          {{ getTotal('TOTAL_FINES_SO_FAR', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
      <div class="flex-1 flex flex-column">
        <span class="color-red small-text-size mb-1">Total Paid:</span>
        <span class="default-text-size">{{ getTotal('HOW_MUCH_PAID', repayments?.repayments) | number: '.2-2' }}</span>
      </div>
    </div> -->
    <div class="panel-table mt-3 pd_15">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th> 
            <th>Principal</th>
            <th>Interest</th> 
            <th>Total Collection</th> 
        </thead>
        
        <tbody>
          <tr *ngFor="let item of repayments">
            <td>{{ item.SCHEDULE_DATE }}</td> 
            <td>{{ item.START_PRINCIPAL | number: ".2-2" }}</td>
            <td>{{ item.INTEREST | number: ".2-2" }}</td> 
            <td>{{ item.TOTAL_COLLECTION | number: ".2-2" }}</td>
            
          </tr>
        </tbody>
        <!-- <tfoot>
          <tr>
            <td></td> 
            <td>
            </td>
            <td>
              {{ getTotal("INTEREST", schedule) | number: ".2-2" }}
            </td> 
            <td>{{ getTotal("TOTAL_COLLECTION", schedule) | number: ".2-2" }}</td> 
          </tr>
        </tfoot> -->
      </table>
    </div>
  </div>
</section>