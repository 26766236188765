<div *ngIf="!showing_due_loans">

  <div class="title flex justify-between">
    <span>Bulk Repayment<br><small>Batch by payment date and push once</small></span>
  </div>
  <section *ngIf="!is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
    <div class="padding-regular-x mt-2" *ngIf="send_payments_to_queue == '1'">
      <div class="alert alert-info alert-sm">Payments are queued for final approval</div>
    </div>
    <div class="flex justify-end mt-3 mb-4">
      <input *ngIf="!adding" type="date" name="payment_date" id="payment_date" [(ngModel)]="payment_date"
        class="form-control mr-1" style="width: 200px">
      <app-button *ngIf="adding" label="Finish" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="adding=!adding"
        class="small hide">
      </app-button>
      <app-button *ngIf="!adding" label="Submit" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="postDuePayments()"
        class="small mr-1">
      </app-button>

    </div>
    <div *ngIf="!adding">
      <div class="contract-table">
        <table class="table medium">
          <thead>
            <tr>
              <th>Name</th>
              <th>Principal</th>
              <th>Term Repayment</th>
              <th>Repaid</th>
              <th>Balance</th>
              <th>Due</th>
              <th>Amount Paid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let due of added_requests; let i=index">
              <td class="flex align-center">
                <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
                </div>
                {{due.Name}}
              </td>
              <td>{{due.Principal}}</td>
              <td>{{due.Total_Repayment}}</td>
              <td>{{due.Paid}}</td>
              <td>{{due.Balance}}</td>
              <td>{{due.Due}}</td>
              <td>{{due.AMOUNT_PAID}}</td>
              <td>
                <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="contract-table" *ngIf="adding">
      <table class="table medium">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Principal</th>
            <th>Repaid</th>
            <th>Balance</th>
            <th>Due</th>
            <th>Amount Paid</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let due of repayments; let i=index">
            <td><input type="checkbox" name="due{{due?.REQUEST_ID}}" [(ngModel)]="due.checked"></td>
            <td class="flex align-center">
              <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
              </div>
              {{due.Name}}
            </td>
            <td>{{due.Principal}}</td>
            <td>{{due.Paid}}</td>
            <td>{{due.Balance}}</td>
            <td>{{due.Due}}</td>
            <td><input type="number" min="0" [max]="due.Due" name="AMOUNT_PAID{{due?.REQUEST_ID}}"
                [(ngModel)]="due.AMOUNT_PAID"></td>
            <td>
              <app-button *ngIf="!due.checked" class="small" (click)="addRecord(due,i)" label="Add"></app-button>
              <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section *ngIf="is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
    <section class="fill-h fill-v">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon"><i class="fa fa-check-o"></i></div>
        <h2 class="title">Request Successful</h2>
        <p class="subtitle">Your request has been posted successfully</p>

      </div>
    </section>
  </section>
</div>
<div *ngIf="showing_due_loans">

  <div class="title flex justify-between">
    <span>All Due Loans<br></span>
    <button class="btn btn-outline-light" (click)="loanmoregetDueRepayments()" *ngIf="has_more" [disabled]='loading'>Load More Records <i *ngIf="loading" class="fa fa-spin fa-spinner"></i></button>
  </div>
  <section *ngIf="!is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
    <div class="padding-regular-x mt-2" *ngIf="send_payments_to_queue == '1'">
      <div class="alert alert-info alert-sm">Payments are queued for final approval</div>
    </div>
    <div class="flex justify-end mt-3 mb-4">
      <input *ngIf="!adding" type="date" name="payment_date" id="payment_date" [(ngModel)]="payment_date"
        class="form-control mr-1" style="width: 200px">
      <app-button *ngIf="adding" label="Finish" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="adding=!adding"
        class="small hide">
      </app-button>
      <app-button *ngIf="!adding" label="Submit" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="postDuePayments()"
        class="small mr-1">
      </app-button>

    </div>
    <div *ngIf="!adding">
      <div class="contract-table">
        <table class="table medium">
          <thead>
            <tr>
              <th>Name</th>
              <th>Principal</th>
              <th>Term Repayment</th>
              <th>Repaid</th>
              <th>Balance</th>
              <th>Due</th>
              <th>Amount Paid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let due of added_requests; let i=index">
              <td class="flex align-center">
                <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
                </div>
                {{due.Name}}
              </td>
              <td>{{due.Principal}}</td>
              <td>{{due.Total_Repayment}}</td>
              <td>{{due.Paid}}</td>
              <td>{{due.Balance}}</td>
              <td>{{due.Due}}</td>
              <td>{{due.AMOUNT_PAID}}</td>
              <td>
                <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="contract-table" *ngIf="adding">
      <table class="table medium">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Principal</th>
            <th>Repaid</th>
            <th>Balance</th>
            <th>Due</th>
            <th>Amount Paid</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let due of repayments; let i=index">
            <td><input type="checkbox" name="due{{due?.REQUEST_ID}}" [(ngModel)]="due.checked"></td>
            <td class="flex align-center">
              <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
              </div>
              {{due.Name}}
            </td>
            <td>{{due.Principal}}</td>
            <td>{{due.Paid}}</td>
            <td>{{due.Balance}}</td>
            <td>{{due.Due}}</td>
            <td><input type="number" min="0" [max]="due.Due" name="AMOUNT_PAID{{due?.REQUEST_ID}}"
                [(ngModel)]="due.AMOUNT_PAID"></td>
            <td>
              <app-button *ngIf="!due.checked" class="small" (click)="addRecord(due,i)" label="Add"></app-button>
              <app-button *ngIf="due.checked" class="small" (click)="removeRecord(due,i)" label="Remove"></app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section *ngIf="is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
    <section class="fill-h fill-v">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon"><i class="fa fa-check-o"></i></div>
        <h2 class="title">Request Successful</h2>
        <p class="subtitle">Your request has been posted successfully</p>

      </div>
    </section>
  </section>
</div>