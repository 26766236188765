import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-golivemodal',
  templateUrl: './golivemodal.component.html',
  styleUrls: ['./golivemodal.component.css']
})
export class GolivemodalComponent implements OnInit {
  @Output() goLiveNow = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  goLive() {
    this.goLiveNow.emit();
  }
  cancelGoLive() {
    this.closeModal.emit();
  }
}
