import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-bookloan',
  templateUrl: './bookloan.component.html',
  styleUrls: ['./bookloan.component.css']
})
export class BookloanComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  bookLoan() {
    //console.log('loan booked');
  }
}
