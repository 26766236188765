<div class="panel padding-regular-y" *ngIf="!loan">
  <div class="filter__wrapper flex align-center justify-between padding-regular-x">
    <div class="section-title fill-w mb-5">Collections</div>
    <div class="filter_input">
      <input type="text" name="searchTerm" class="form-control search_input" placeholder="Search Here">
      <i class="fa fa-search search_icon" aria-hidden="true"></i>
    </div>
  </div>
  <div class="customers">
    <div  class="customer" *ngFor="let collection of collections; let i = index">
      <div class="header">
        <a class="flex-center">
          <img class="img-wrap"
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{collection?.file_name | imagenotfound}}">
        </a>
        <div class="content">
          <div class="name cursor-pointer">
            {{ collection.originatorname }} - ₦{{ collection.amount | number: ".2-2" }}
          </div>
          <p>Source: {{ collection.craccount }}</p>
        </div>
        <div [attr.data-target]="'#collection-paned' + i" class="toggle flex-center">
          <button class="icon-button ml-2" type="button" data-toggle="collapse"
            [attr.data-target]="'#collection-paned' + i" aria-expanded="false" aria-controls="collapseExample">
            <i class="fa fa-chevron-down"></i>
          </button>
        </div>
      </div>
      <div class="body collapse" [id]="'collection-paned' + i">
        <ul class="decList bigger">
          <li>
            <span>Legal name</span>
            <span>{{ collection.legal_name }}</span>
          </li>
          <li>
            <span>Source</span>
            <span> {{ collection.craccount }}</span>
          </li>
          <li>
            <span>Date paid</span>
            <span>{{ collection.created_at }}</span>
          </li>
          <li>
            <span>Bank</span>
            <span>{{ collection.bankname  }}</span>
          </li>
          <li>
            <span>Bank</span>
            <span>{{ collection.originatorname  }}</span>
          </li>
          <li>
            <span>Bank</span>
            <span>{{ collection.originatoraccountnumber  }}</span>
          </li>
          <li>
            <span>Amount paid</span>
            <span>{{ collection.amount_paid   }}</span>
          </li>
          <li>
            <span>Session ID</span>
            <span>{{ collection.sessionid }}</span>
          </li>
          <li>
            <span>Loan ID</span>
            <span>{{ collection.loan_id }}</span>
          </li>
        </ul>
      </div>
    </div> 
  </div>
  
  <div class="padding-regular mt-3 text-right">
    <app-button class="small outline teal" label="View all collections"></app-button>
  </div>
</div>
<div class="panel padding-regular-y" *ngIf="loan">
  <div class="filter__wrapper flex align-center justify-between padding-regular-x">
    <div class="section-title fill-w mb-5">Collections <span *ngIf="loan">- {{loan}}</span></div> 
  </div>
  <div class="customers">
    <div class="customer" *ngFor="let collection of collections; let i = index">
      <div class="header">
        <a class="flex-center">
          <img class="img-wrap"
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{collection?.file_name | imagenotfound}}">
        </a>
        <div class="content">
          <div class="name cursor-pointer">
            {{ collection.transaction_payload.originatorname }} - ₦{{ collection.transaction_payload.amount | number: ".2-2" }}
          </div>
          <p>Source: {{ collection.transaction_payload.originatoraccountnumber }} - {{ collection.transaction_payload.bankname }}</p>
          <p>Date: {{ collection.date_added }}</p>
          <p>Session: {{ collection.transaction_payload.sessionid }}</p>
        </div>
        <div [attr.data-target]="'#collection-pane' + i" class="toggle flex-center">
          <button class="icon-button ml-2" type="button" data-toggle="collapse"
            [attr.data-target]="'#collection-pane' + i" aria-expanded="false" aria-controls="collapseExample">
            <i class="fa fa-chevron-down"></i>
          </button>
        </div>
      </div>
      <!-- <div class="body collapse" [id]="'collection-pane' + i">
        <ul class="decList bigger">
          <li>
            <span>Legal name</span>
            <span>{{ collection.legal_name }}</span>
          </li>
          <li>
            <span>Source</span>
            <span>{{ +collection.source > 0 ? 'Card' : 'Backend' }}</span>
          </li>
          <li>
            <span>Date paid</span>
            <span>{{ collection.date_paid | date }}</span>
          </li>
          <li>
            <span>Total repayment</span>
            <span>{{ collection.total_repayment | currency:'₦':false:'1.2-2' }}</span>
          </li>
          <li>
            <span>Amount paid</span>
            <span>{{ collection.amount_paid | currency:'₦':false:'1.2-2' }}</span>
          </li>
          <li>
            <span>Balance</span>
            <span>{{ collection.balance | currency:'₦':false:'1.2-2' }}</span>
          </li>
          <li>
            <span>Loan ID</span>
            <span>{{ collection.loan_id }}</span>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
  <div class="customers" *ngIf="!collections" >
 
    <div class="customer">
     <p>No payment records</p>
    </div>
  </div>
  <!-- <div class="padding-regular mt-3 text-right">
    <app-button class="small outline teal" label="View all collections"></app-button>
  </div> -->
</div>