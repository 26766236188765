<section class="padding-regular">
  <h3 class="title">
    Collateral Setup
  </h3>

  <form action="">
    <div class="form-group">
      <select name="collateral_type" id="" class="form-control">
        <option disabled>Select collateral type</option>
        <option value="1">House</option>
        <option value="2">Land</option>
        <option value="3">Car</option>
      </select>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="collateral_value" placeholder="Current Value">
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="collateral_expiry_date" placeholder="Expiry Date">
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="liquidation_value" placeholder="Expected Liquidation Value">
    </div>
    <div class="text-left mt-4">
      <app-button label="Save" class="small green">Save</app-button>
    </div>
  </form>
</section>