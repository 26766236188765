import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ApprovalsService, DataService, UserService, OperationsService } from '../_services/index';
import { AuthenticationService, StorageService, AnalyticsService } from '../_services/index';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router'; 
import { CustomService } from '../_services/custom.service';
import { CustomersService } from '../_services/customers.service';

@Component({
  selector: 'app-homesummary',
  templateUrl: './homesummary.component.html',
  styleUrls: ['./homesummary.component.css']
})
export class HomesummaryComponent implements OnInit {
  activeRequestId
  endDate = '1561676400000';
  show_empty_state = false;
  sharing_link = false;
  repayment:any;
  loan:any;
  miniSearch = false;
  searchView = false;
  view = 'master';
  statuses = [
    {
      'display':'Requests',
      'value':'1'
    },
    {
      'display':'Customers',
      'value':'2'
    }
  ]; 
  loading = false;
  currentUser:any;
  platform_summary: any;
  loading_approvals = false;
  approvals: any;
  loading_portfolio_snapsot = false;
  snapshot:any;
  adding_more=false;
  filteringLoans: boolean;
  loans: any;
  public durations = [
    { display: '0 - 1', checked: false, min: 0, max: 30 },
    { display: '0 - 3', checked: false, min: 31, max: 90 },
    { display: '4 - 6', checked: false, min: 91, max: 180 },
    { display: '7 - 12', checked: false, min: 181, max: 365 },
    { display: '1+ Years', checked: false, min: 366, max: 3650 }
  ];
  public adays = [
    { display: '1 - 3', checked: false, min: 0, max: 3 },
    { display: '4 - 10', checked: false, min: 4, max: 10 },
    { display: '> 10', checked: false, min: 10, max: 365 }
  ];

  public incomes = [
    { display: '< 50K', checked: false, min: 0, max: 50000 },
    { display: '51 - 100k', checked: false, min: 50001, max: 100000 },
    { display: '101k - 250k', checked: false, min: 100001, max: 250000 },
    { display: '250k - 500k', checked: false, min: 250001, max: 500000 },
    { display: '500k - 1m', checked: false, min: 500001, max: 1000000 },
    { display: '> 1m', checked: false, min: 1000001, max: 120000000 }
  ];
  public ages = [
    { display: '18 - 24', checked: false, min: 18, max: 24 },
    { display: '25 - 34', checked: false, min: 25, max: 34 },
    { display: '35 - 45', checked: false, min: 35, max: 45 },
    { display: 'above 45', checked: false, min: 46, max: 100 }
  ];
  public request_date = [
    { display: 'Today', checked: false, min: 0, max: 1 },
    { display: '2 - 7 days', checked: false, min: 2, max: 7 },
    { display: '2 - 4 weeks', checked: false, min: 8, max: 30 },
    { display: '1+ months', checked: false, min: 31, max: 100000 }
  ];
  searchTerm='';
  start: number = 0; 
  count = '0';
  openedTab: any; // Currently opened preview tab 
  magic_filter = {
    reset: false,
    account_officer: false,
    start: 0,
    token: '',
    registered_from: this.statuses[0].value,
    searchText: '',
    borrower: true,
    investor: true,
    loans: '',
    investments: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    customer_category:''
  };

  public marital_statuses = [
    // { value: '-2', display: 'All' },
    { value: '1', display: 'Single' },
    { value: '2', display: 'Married' },
    { value: '3', display: 'Divorced' },
    { value: '4', display: 'Widowed' },
  ];
  branches: any;
  products: any;
  states: any;
  action = 'normal'; // Search, Normal Request or Filter
  investor_box = true;
  borrower_box = true;
  ownershipCustomer: any;
  owningStaff: any; 
  customers: any;
  verifier = { phone_verifier: false, email_verifier: false, bvn_verifier: false, PEOPLE_CUSTOMERS_ID: '' };
  enable_peer: any;
  sectors: any; 
  designations: any;
  occupations: any;
  overlayOpen: any; 
  constructor(protected customersSrvc: CustomersService,public analytics: AnalyticsService, public approvalService: ApprovalsService, public DataService: DataService,
    public router: Router, public fb: FormBuilder, public operationsService: OperationsService, public authService: AuthenticationService,
    public storageService: StorageService, private customerService: CustomService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    
  }

  ngOnInit() {
    this.runPlatformSummary();
    //this.getPendingApprovals();
    this.getPortfolioSnapshot();
    //this.DataService.showMasterSideBar.emit({});
    this.getUserCustomers()
    this.runStaffPerformance();
    let url = this.router.url; 
    const tokens = url.split('/');
    if(tokens.length == 5){
      this.view = 'sub';
    } 
  }
  searchLoad(test) {

    this.getUserCustomers();
  }
  displayCondition(i) {
    // if (i <= 2) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  }
  bookanewloan(){
    this.DataService.bookanewloan.emit({});
  }
  invitenewmembers(){
    this.router.navigate(['./operations/employees']);
  }
  modifyloansettings(){
    this.router.navigate(['./system/settings/general']);
  }
  inviteNewCustomer() {
    this.DataService.inviteNewCustomer.emit({});
  }
  
  getPendingApprovals(){
    this.loading_approvals = true;
    this.analytics.getPendingApprovals(this.currentUser.token)
      .subscribe(data => {
        this.loading_approvals = false;
        this.approvals = data;

      });
  }
  getPortfolioSnapshot(){
    // this.loading_portfolio_snapsot = true;
    // this.analytics.getPortfolioSnapshot(this.currentUser.token)
    //   .subscribe(data => {
    //     this.loading_portfolio_snapsot = false;
    //     this.snapshot = data;

    //   });
  }
  runPlatformSummary() {
    this.loading = true;
    this.analytics.runPlatformSummary(this.currentUser.token)
      .subscribe(data => {
        this.loading = false;
        this.platform_summary = data;
        this.show_empty_state = data.show_empty_state;
        //this.DataService.platformSummaryLoaded.emit({platform_summary:data});
      });
  }
  openAnalytics(request_id){
    this.router.navigate(['./applications/approvals']);
  }
  openApprovals(){
    this.router.navigate(['./applications/approvals']);
  }
  getUserCustomers(start=0){
    this.loading_approvals = true;
    this.customerService.getAllCustomersByUser(this.currentUser.token,start,this.searchTerm)
      .subscribe(data => {
        this.loading_approvals = false; 
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = data;
        this.magic_filter.start = data.start;
        this.miniSearch = false;
        this.searchView = false; 
      });
  }
  filterCustomers() {
    this.loading = true; 
      this.customerService.getAllCustomersByUserFilter(this.currentUser.token,this.magic_filter.start,'',this.magic_filter, this.ages, this.adays, this.marital_statuses, this.occupations, this.sectors, this.designations, this.incomes, this.branches, this.states)
      .subscribe(data => {
        this.loading_approvals = false; 
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = data;
        this.magic_filter.start = data.start;
        this.miniSearch = false;
        this.searchView = false; 
      });
  }
  onScrollDown(ev) { 
    this.adding_more = true;
    this.customerService.getAllCustomersByUser(this.currentUser.token,this.magic_filter.start,'')
      .subscribe(data => {
        this.adding_more = false;
        this.loading_approvals = false; 
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = data;
        this.magic_filter.start = data.start;
        this.miniSearch = false;
        this.searchView = false; 
      });
  }
  staff_loading = false;
  staff_performance_result:any;
  public staff_performance = {
    'staff_id':''
  }
  runStaffPerformance(){
    this.staff_performance.staff_id = this.currentUser.account_id;
    this.staff_loading = true;
    this.analytics.runStaffPerformance(this.currentUser.token, this.staff_performance)
      .subscribe(data => {
        // //console.log(data);
        this.staff_loading = false;
        this.staff_performance_result = data; 
      });
  }
  loadRequest(request) {  
    this.activeRequestId = request.PEOPLE_ID;
    this.loadCustomerDetails(request.PEOPLE_ID);
  }
  loadCustomerDetails(people_id){
    this.customersSrvc.getCustomerProfile(1, people_id).subscribe(data => {
      this.loan = data.cust;
     
      this.DataService.loadHomeCustomerSideview.emit({ 'cust': data.cust });

    });
  }
  createNewCustomer(){
    this.DataService.createANewCustomer.emit();

  }

  
}
