<div [ngClass]="{'overpanel-scroll': contraints}">
  <div *ngIf="!editAfterAccepted" class="text-center" [ngClass]="{ 'blf': contraints }">
    <div class="flex-center flex-column">
      <div class="h-group padded center mt-4 flex-center flex-column">
        <div class="circle large green filled flex-center mb-3"><i class="fa fa-check"></i></div>
        <h3 class="title no-float mb-2" *ngIf="contract_details?.loan?.is_accepted=='1'">This contract has been accepted
        </h3>
        <h3 class="title no-float mb-2"
          *ngIf="contract_details?.loan?.is_accepted=='0' && contract_details?.loan?.contract_doc_sent==true">
          This contract has been sent to the customer
        </h3>
        <p class="subtitle" *ngIf="loan_request?.DIRECT_DEBIT_STATUS!='0'">
          NB:There is a direct debit mandate already setup. Please endeavour to setup another mandate if you change the
          contract</p>
        <div class="text-center mt-4">
          <app-button class="small blue" (click)="request_step = '3';editAfterAccepted=!editAfterAccepted"
            label="Modify">
          </app-button>
          <app-button class="small blue ml-1" (click)="viewSchedule()" label="View Schedule"></app-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editAfterAccepted" [ngClass]="{ 'blf': contraints }">
    <div *ngIf="request_step === '7'" class="text-center">
      <div class="flex-center flex-column">
        <div class="h-group padded center mt-4 flex-center flex-column">
          <div class="image-avatar large mb-4">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">
          </div>
          <!-- <div class="circle large green filled flex-center mb-3"><i class="fa fa-check"></i></div> -->
          <!-- <h3 class="title no-float" *ngIf="loan_request?.IS_ACCEPTED=='1'">Contract Created</h3> -->
          <div class="form-group mt-3">
            <input type="checkbox" name="SEND_CONTRACT_DOCS" [(ngModel)]="SEND_CONTRACT_DOCS">
            <div class="control-label normal">Send contract documents</div>
          </div>
          <div class="text-center mt-4">
            <app-button class="small outline mr-1" (click)="request_step = '3';" label="Modify"></app-button>
            <app-button *ngIf="(loan_request?.LOAN_STATUS=='2'||loan_request?.IS_PEER_TO_PEER!='1')" class="small"
              (click)="createContract()" [disabled]="loading"
              [label]="loan_request?.SCHEDULE_HAS_BEEN_SAVED=='1'?'Update Contract':'Create Contract'"
              [loading]="loading">
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="request_step === '4'" class="text-center">
      <div class="flex-center flex-column">
        <div class="h-group padded center mt-4 flex-center flex-column">
          <div class="circle large green filled flex-center mb-3"><i class="fa fa-check"></i></div>
          <h3 class="title no-float" *ngIf="loan_request?.IS_ACCEPTED=='1'">Contract Created</h3>
          <div class="text-center mt-4">
            <app-button class="small blue" (click)="request_step = '3';" label="Modify"></app-button>
            <app-button class="small blue ml-1" (click)="viewSchedule()" label="View Schedule"></app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="request_step === '5'" class="text-center">
      <div class="flex-center flex-column">
        <div class="h-group padded center mt-4">
          <div class="circle large grey filled flex-center"><i class="fa fa-question"></i></div>
          <p>Are you sure you want to create contract</p>
          <div class="text-center mt-4">
            <app-button class="small green" (click)="createContract()" [disabled]="loading" label="Create Contract"
              [loading]="loading">
            </app-button>
            <app-button class="small orange" (click)="request_step = '3';" label="Modify" [disabled]="loading">
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="request_step === '0'">
      <div class="title flex justify-between align-center">
        Create customer
        <button (click)="request_step='1'" class="btn btn-outline-light"><i class="fas fa-times"></i></button>
      </div>
      <div class="col-md-12 content">
        <form [formGroup]="myFormCustomer" novalidate
          (ngSubmit)="saveNewCustomer(myFormCustomer.value, myFormCustomer.valid)">
          <!-- <form #autorouting="ngForm" novalidate ></form> -->
          <div class="col-md-12 content padding-regular">

            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Full Name</span>
              </div>
              <div class="col-md-7">
                <input type="text" required formControlName="LEGAL_NAME" name="LEGAL_NAME" class="form-control">
              </div>
            </div>
            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Email</span>
              </div>
              <div class="col-md-7">
                <input type="email" autocomplete="false" formControlName="EMAIL" name="EMAIL" class="form-control">
                <label><input type="checkbox" formControlName="USE_AUTOMATED_EMAIL" name="USE_AUTOMATED_EMAIL"> No
                  email</label>
              </div>
            </div>
            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Phone</span>
              </div>
              <div class="col-md-7">
                <input type="text" required autocomplete="false" formControlName="PHONE" name="PHONE"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-1 mb-4 b-bottom">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Gender</span>
              </div>
              <div class="col-md-7">
                <select required class="form-control" formControlName="GENDER">
                  <option [value]=""></option>
                  <option [value]="0">Male</option>
                  <option [value]="1">Female</option>
                  <option [value]="2">I rather not say</option>

                </select>
              </div>
            </div>
            <div class="mt-4 text-left">
              <app-button type="submit" [loading]="loading" [disabled]="loading||!myFormCustomer.valid"
                class="small pull-right" label="Create Customer"></app-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="request_step === '1'">
      <app-help-popover [content]="[
        {
          title: 'How to Create loan request!',
          img: 'assets/images/analysis.svg',
          text:
            '
    <div>
      <p>Gives the option to create loan request for a new or existing customer,</p>
    </div>'
        }
      ]"></app-help-popover>
      <div class="title flex justify-between align-center">
        Create a loan request
        <div>
          <!-- <button *ngIf="loan_request?.REQUEST_ID==''" title="Search for customer" class="btn btn-outline-light"
            data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
            aria-controls="collapseExample"><i class="fas fa-search"></i></button> -->
          <button *ngIf="loan_request?.REQUEST_ID==''" title="Create new customer" (click)="request_step='0'"
            class="btn btn-outline-light ml-1"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <div class="col-md-12 content padding-regular">
        <!-- <div class="collapse" id="collapseExample"> -->
        <div>
          <div class="search-container mb-3">
            <input type="search" name="searchString" (keyup)="searchCustomers($event)" [(ngModel)]="searchString"
              placeholder="Search customer.." class="form-control form-control-large">
            <div class="panel loading" style="height: 20px;" *ngIf="searching"></div>
            <div class="search-results animate slideDown"
              *ngIf="searchedCustomerResult?.a?.length && !customerSelected && !searching">
              <div *ngFor="let customer of searchedCustomerResult?.a">
                <div class="user" (click)="selectCustomer(customer)">
                  <div class="image">
                    <img
                      src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{customer?.FILE_NAME | imagenotfound}}">
                  </div>
                  <div class="content no-padding">
                    <h4 class="title no-float">{{customer?.LEGAL_NAME}}</h4>
                    <p class="subtitle">{{customer?.PEOPLE_CUSTOMERS_ID}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="customer-details flex justify-between align-center mb-5">
            <div class="user">
              <div class="image">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{selectedCustomer?.FILE_NAME | imagenotfound}}">
              </div>
              <div class="content no-padding" *ngIf="customerSelected">
                <h4 class="title no-float">{{selectedCustomer?.LEGAL_NAME}}</h4>
                <p class="subtitle">{{selectedCustomer?.PEOPLE_CUSTOMERS_ID}}</p>
              </div>
              <div class="content no-padding" *ngIf="!customerSelected">
                <h4 class="title no-float">No customer selected</h4>
              </div>
            </div>
          </div>
          <label class="form-group mb-2" *ngIf="has_loan_products=='1'">
            <span class="control-label normal">Product</span>
            <select class="form-control" name="LOAN_PRODUCT_ID" [(ngModel)]="loan_product">
              <option value=""></option>
              <option [ngValue]="product" *ngFor="let product of other_products">{{product?.LOAN_TITLE}}
              </option>
            </select>
          </label>
          <div class="flex">
            <label class="form-group mb-2 flex-1 mr-1">
              <span class="control-label normal">Principal</span>
              <input type="number" [(ngModel)]="loan_request.REQUEST_PRINCIPAL" name="REQUEST_PRINCIPAL"
                class="form-control">
            </label>
            <label class="form-group mb-2 flex-1 ml-1">
              <span class="control-label normal">Duration</span>
              <div class="input-group">
                <input type="number" [(ngModel)]="loan_request.REQUEST_TENOR" name="REQUEST_TENOR" class="form-control">

                <div class="input-group-btn">
                  <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                    <span class="sel_cur">{{loan_duration}}</span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu pull-right">
                    <li *ngFor="let d of loan_durations;let i=index;">
                      <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </label>
          </div>
          <label class="form-group mb-2">
            <span class="control-label normal">Interest rate</span>
            <div class="input-group">
              <input type="number" [(ngModel)]="loan_request.REQUEST_RATE" name="REQUEST_RATE" class="form-control"
                aria-describedby="basic-addon">
              <div class="input-group-btn">
                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                  <span class="sel_cur">{{interest_duration}}</span>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right">
                  <li *ngFor="let d of loan_durations;let i=index;">
                    <a (click)="changeDuration(i,2)">{{d.INTEREST_DURATION}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </label>
          <div *ngIf="moreInfo">
            <div class="flex" *ngIf="loan_request.INSTALLMENT_FREQUENCY=='2'">
              <label class="form-group mb-2 flex-1 mr-1">
                <span class="control-label normal">Disbursement Date</span>
              </label>
              <label class="form-group mb-2 flex-1 ml-1">
                <!-- <my-date-picker (dateChanged)="onDateChanged($event)" [selDate]="selDates" name="DISBURSEMENT_DATE"
                  [options]="myDatePickerOptions" [(ngModel)]="loan_request.DISBURSEMENT_DATE"></my-date-picker> -->
                <input type="date" class="form-control" (ngModelChange)="onDateChangeds($event)"
                  name="DISBURSEMENT_DATE" [(ngModel)]="loan_request.DISBURSEMENT_DATE">
              </label>
            </div>
            <div class="flex" *ngIf="loan_request.INSTALLMENT_FREQUENCY=='2'">
              <label class="form-group mb-2 flex-1 mr-1">
                <span class="control-label normal">1st repayment date</span>
              </label>
              <label class="form-group mb-2 flex-1 ml-1">
                <!-- <my-date-picker (dateChanged)="onDateChanged($event)" [selDate]="selDates_" name="REPAYMENT_STARTED_WHEN"
                  [options]="myDatePickerOptions" [(ngModel)]="loan_request.REPAYMENT_STARTED_WHEN"></my-date-picker> -->
                <input type="date" class="form-control" (ngModelChange)="onDateChangeds($event)"
                  name="REPAYMENT_STARTED_WHEN" [(ngModel)]="loan_request.REPAYMENT_STARTED_WHEN">
              </label>
            </div>
          </div>
          <div class="text-left mt-4">
            <app-button class="small mr-1" (click)="previewLoanAndCreateSchedule()"
              [disabled]="loading||loan_request?.REQUEST_PRINCIPAL=='0'||loan_request?.REQUEST_TENOR=='0'||!customerSelected"
              [label]="loan_request?.REQUEST_ID==''?'Create request':'Save and Continue'" [loading]="loading">
            </app-button>
            <app-button class="small" (click)="moreInfo=!moreInfo"
              [disabled]="loading||loan_request?.REQUEST_PRINCIPAL=='0'||loan_request?.REQUEST_TENOR=='0'||!customerSelected"
              [label]="moreInfo?'Less Info':'More Info'" [loading]="loading">
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="request_step == '2'">
      <div *ngIf="!request?.schedule_has_been_created">
        <h3 class="title" [ngClass]="{'text-center': !contraints}">Review and Modify Terms</h3>
        <div class="col-md-12 content padding-regular">
          <div class="label-value-list flex-1 default-text-size">
            <div class="item"><span class="label">Required credit</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.REQUEST_PRINCIPAL | number:'.2-2'}}</span></div>
            <div class="item"><span class="label">Duration</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.REQUEST_TENOR}} {{loan_request?.LOAN_DURATION}}</span>
            </div>
            <div class="separator"></div>
            <div class="item"><span class="label">Est. fees and charges</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.TOTAL_FEES_CHARGES | number:'.2-2'}}</span></div>
            <div class="item"><span class="label">Est. Total Interest</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.TOTAL_INTERESTS | number:'.2-2'}}</span></div>

            <div class="item"><span class="label">Est. Total repayment</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.TOTAL_REPAYMENT | number:'.2-2'}}</span></div>
            <div class="separator"></div>
            <div class="item"><span class="label">Repayment frequency</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.REPAYMENT_FREQUENCY}}</span></div>
            <div class="item"><span class="label">Repayment type</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.REPAYMENT_TYPE}}</span></div>
            <div class="item"><span class="label">Monthly repayment</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.MONTHLY_REPAYMENT | number:'.2-2'}}</span></div>
            <div class="item"><span class="label">1st repayment date</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{loan_request?.REPAYMENT_STARTED_WHEN}}</span></div>
            <div class="separator"></div>
            <div class="item"><span class="label">Net disbursement</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{getNet(loan_request) | number:'.2-2'}}</span>
            </div>
            <!-- <div class="item"><span class="label">Disbursement date</span><span class="value"
                  [ngClass]="{'skeleton': loading}">{{loan_request?.DISBURSEMENT_DATE}}</span></div> -->
            <div class="separator"></div>
            <div class="item"><span class="label">Contract ID</span><span class="value"
                [ngClass]="{'skeleton': loading}">{{ loan_request?.LOAN_ID }}</span>
            </div>
          </div>
          <!-- <div class="text-right mt-2" *ngIf="!loading">
            <button class="btn btn-outline-secondary btn-sm" type="button" data-toggle="collapse" data-target="#more"
              aria-expanded="false" aria-controls="more">More</button>
          </div> -->

          <!-- <div class="form-group mt-3">
            <input type="checkbox" name="SEND_CONTRACT_DOCS" [(ngModel)]="SEND_CONTRACT_DOCS">
            <div class="control-label normal">Send contract documents</div>
          </div> -->

          <div class="mt-4 flex justify-between align-center">
            <!-- <app-button label="Create contract" class="small"></app-button>
            -->
            <!-- <button class="btn btn-white btn-normal" [disabled]="loading" (click)="doInvest()">Create Contract</button> -->
            <div class="flex">
              <app-button class="small outline mr-1" (click)="request_step = 3" [disabled]="loading"
                label="Edit Contract">
              </app-button>
              <app-button class="small outline" (click)="close.emit()" label="Continue later">
              </app-button>
            </div>
            <app-button *ngIf="(loan_request?.LOAN_STATUS=='2'||loan_request?.IS_PEER_TO_PEER!='1')" class="small"
              (click)="request_step = '7'" [disabled]="loading"
              [label]="loan_request?.SCHEDULE_HAS_BEEN_SAVED=='1'?'Update Contract':'Create Contract'"
              [loading]="loading">
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="request_step=='3'">
      <form [formGroup]="myForm" novalidate (ngSubmit)="save(myForm.value, myForm.valid)">
        <div class="content padding-regular">
          <h3 [ngClass]="{ 'section-title mb-5 border-bottom': !contraints }" class="title">Review</h3>
          <section class="form-section">
            <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Loan Setting</div>
            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Principal</span>
              </div>
              <div class="col-md-7">
                <input type="number" formControlName="REQUEST_PRINCIPAL" name="REQUEST_PRINCIPAL" class="form-control">
              </div>
            </div>
            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Duration</span>
              </div>
              <div class="col-md-7">
                <div class="input-group">
                  <input type="number" formControlName="REQUEST_TENOR" name="REQUEST_TENOR" class="form-control"
                    aria-describedby="basic-addon">
                  <input type="hidden" formControlName="REQUEST_RATE_PERIOD_ID" name="REQUEST_RATE_PERIOD_ID">
                  <input type="hidden" formControlName="REQUEST_PERIOD_ID" name="REQUEST_PERIOD_ID">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                      <span class="sel_cur">{{loan_duration}}</span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right">
                      <li *ngFor="let d of loan_durations;let i=index;">
                        <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 mt-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Interest Rate</span>
              </div>
              <div class="col-md-7">
                <div class="input-group">
                  <input type="number" formControlName="REQUEST_RATE" name="REQUEST_RATE" class="form-control"
                    aria-describedby="basic-addon">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                      <span class="sel_cur">{{interest_duration}}</span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right">
                      <li *ngFor="let d of loan_durations;let i=index;">
                        <a (click)="changeDuration(i,2)">{{d.INTEREST_DURATION}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Interest rate type</span>
              </div>
              <div class="col-md-7">
                <select (ngModelChange)="changeRepay($event)" class="form-control"
                  formControlName="INTEREST_RATE_TYPE_ID">
                  <option [value]="1">Flat</option>
                  <option [value]="2">Simple</option>
                </select>
              </div>
            </div>
          </section>

          <section class="form-section">
            <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Repayment Terms</div>
            <!-- Interest Frequency -->
            <div class="row mb-1">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Installment Frequency</span>
              </div>
              <div class="col-md-7">
                <select class="form-control" (ngModelChange)="changeInstallmentFrequency($event)"
                  formControlName="INSTALLMENT_FREQUENCY">
                  <option [value]="freq.value" *ngFor="let freq of installment_frequencies;">{{freq.display}}</option>
                </select>
                <!-- <div class="flex justify-between">
                    <div *ngFor="let freq of installment_frequencies;">
                      <input type="radio" [checked]="request.installment_frequency===freq.value"
                        (ngModelChange)="changeInstallmentFrequency($event)" name="installment_frequency"
                        [(ngModel)]="request.installment_frequency" [value]="freq.value">
        
                      <div class="control-label normal"> {{freq.display}}</div>
                    </div>
                  </div> -->
              </div>
            </div>
            <!-- Installment terms -->
            <div class="row mb-1" *ngIf="installment_frequency==='2'">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Installment Terms</span>
              </div>
              <div class="col-md-7">
                <select (ngModelChange)="cIA($event)" class="form-control" formControlName="REPAYMENT_TYPE_ID">
                  <option *ngFor="let r of rtypes;" [value]="r.value">{{r.display}}</option>

                </select>
              </div>
            </div>
            <!-- Installment cycle -->
            <div class="row mb-1" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Installment cycle</span>
              </div>
              <div class="col-md-7">
                <select name="MONTHLY_PERIOD" formControlName="MONTHLY_PERIOD" class="form-control">
                  <option [value]="1">Monthly</option>
                  <option [value]="2">+28 days</option>
                  <option [value]="3">+29 days</option>
                  <option [value]="4">+30 days</option>
                  <option [value]="5">+31 days</option>
                  <option [value]="6">+7 days</option>
                </select>
              </div>
            </div>
            <div class="row mb-1" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
              <div class="col-md-5">
                <span class="control-label normal default-text-size">Payment cycle</span>
              </div>
              <div class="col-md-7">
                <select name="PAYMENT_CYCLE" formControlName="PAYMENT_CYCLE" class="form-control">
                  <option [value]="1">Monthly</option>
                  <option [value]="2">Every 2 Months</option>
                  <option [value]="3">Every 3 Months</option>
                  <option [value]="4">Every 4 Months</option> 
                  <option [value]="5">Every 5 Months</option>
                  <option [value]="6">Every 6 Months</option> 
                </select>
              </div>
            </div>
            <section class="form-section">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Disbursement</div>
              <div class="row mb-1">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Disbursement date</span>
                </div>
                <div class="col-md-7">
                  <!-- <my-date-picker (dateChanged)="onDateChanged($event)" [selDate]="selDate"
                    name="disbursement_date" [options]="myDatePickerOptions" [(ngModel)]="request.disbursement_date">
                  </my-date-picker> -->
                  <!-- <input type="text" (ngModelChange)="onDateChanged($event)" name="disbursement_date" class="form-control"
                    [(ngModel)]="request.disbursement_date" ngui-datetime-picker date-only="true" /> -->
                  <my-date-picker (dateChanged)="onDateChanged($event)" [selDate]="selDate" name="DISBURSEMENT_DATE"
                    [options]="myDatePickerOptions" formControlName="DISBURSEMENT_DATE"></my-date-picker>
                </div>
              </div>
              <!-- 1st repayment date -->
              <div class="row mb-1" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">1st repayment date</span>
                </div>
                <div class="col-md-7">
                  <!-- <my-date-picker [selDate]="selDate_" name="repayment_started_when" [options]="myDatePickerOptions"
                    [(ngModel)]="request.repayment_started_when"></my-date-picker> -->
                  <!-- <input type="text" name="repayment_started_when" class="form-control"
                    [(ngModel)]="request.repayment_started_when" ngui-datetime-picker date-only="true" /> -->
                  <my-date-picker [selDate]="selDate_" name="REPAYMENT_STARTED_WHEN" [options]="myDatePickerOptions"
                    formControlName="REPAYMENT_STARTED_WHEN"></my-date-picker>
                </div>
              </div>
            </section>

            <section class="form-section" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Interest accruals</div>
              <div class="row mb-4">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">1 day equals 1 month</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" formControlName="RP_SET_TYPE">
                    <option [value]="1">Yes</option>
                    <option [value]="2">No, Prorate daily</option>
                  </select>
                </div>
              </div>
            </section>
          </section>
          <div class="label-value-list collapse" id="mored">

            <section class="form-section" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value == '2'">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Moratorium</div>
              <div class="row mb-1" *ngIf="myForm.get('INSTALLMENT_FREQUENCY').value=='2'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Allow moratorium</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="ALLOW_MORATORIUM" formControlName="ALLOW_MORATORIUM">
                    <option [value]="1">No</option>
                    <option [value]="2">Yes</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1" *ngIf="myForm.get('ALLOW_MORATORIUM').value == '2' || allowMoratorium">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Moratorium duration</span>
                </div>
                <div class="col-md-7">
                  <input type="text" class="form-control" formControlName="MORATORIUM_PERIOD" name="MORATORIUM_PERIOD">
                </div>
              </div>
              <div class="row mb-1" *ngIf="myForm.get('ALLOW_MORATORIUM').value == '2' || allowMoratorium">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Moratorium On</span>
                </div>
                <div class="col-md-7">
                  <select formControlName="MORATORIUM_TYPE" name="MORATORIUM_TYPE" class="form-control">
                    <option [value]="3">All</option>
                    <option [value]="1">Principal</option>
                  </select>
                </div>
              </div>
            </section>

            <section class="form-section">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Fees and related deductions
              </div>
              <div class="row mb-1">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Fees paid (flat)</span>
                </div>
                <div class="col-md-7">

                  <input type="text" formControlName="TOTAL_FLAT_FEES" name="TOTAL_FLAT_FEES" class="form-control">
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Fees paid (rate)</span>
                </div>
                <div class="col-md-7">
                  <input type="text" formControlName="TOTAL_PERCENTAGE_FEES" name="TOTAL_PERCENTAGE_FEES"
                    class="form-control">
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Fees collected when</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="FEE_TYPE" formControlName="FEE_TYPE">
                    <option [value]="0">Upfront</option>
                    <option [value]="1">Capitalized</option>
                    <!-- <option [value]="2">Backend</option> -->
                  </select>
                </div>
              </div>
              <div class="row mb-1" *ngIf="myForm.get('FEE_TYPE').value=='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Fees paid ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="ALL_FEES_AND_CHARGES_PAID"
                    formControlName="ALL_FEES_AND_CHARGES_PAID">
                    <option [value]="1">No</option>
                    <option [value]="2">Yes</option>
                  </select>
                </div>
              </div>
            </section>

            <section class="form-section">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Upfronted Interests
              </div>
              <div class="row mb-4">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">No of Interest Upfronted ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="NO_INTEREST_HAS_BEEN_UPFRONTED"
                    formControlName="NO_INTEREST_HAS_BEEN_UPFRONTED">
                    <option [value]="0">0</option>
                    <option *ngFor="let key of myForm.get('REQUEST_TENOR').value | demoNumber" [value]="key">{{key}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-4 b-bottom" *ngIf="myForm.get('NO_INTEREST_HAS_BEEN_UPFRONTED').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Paid Upfronted Interests ?</span>
                </div>
                <div class="col-md-7 b-bottom">
                  <select class="form-control" name="PAID_INTEREST_HAS_BEEN_UPFRONT"
                    formControlName="PAID_INTEREST_HAS_BEEN_UPFRONT">
                    <option [value]="0">No</option>
                    <option [value]="1">Yes</option>
                    <option [value]="2"></option>
                  </select>
                </div>
              </div>
            </section>

            <section class="form-section">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Equity Contribution
              </div>
              <div class="row mb-4">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Equity Contribution ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="EQUITY_CONTRIBUTION" formControlName="EQUITY_CONTRIBUTION">
                    <option [value]="0">No</option>
                    <option [value]="1">Yes</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1" *ngIf="myForm.get('EQUITY_CONTRIBUTION').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Equity Amount</span>
                </div>
                <div class="col-md-7">
                  <input type="text" formControlName="EQUITY_CONTRIBUTION_AMOUNT" name="EQUITY_CONTRIBUTION_AMOUNT"
                    class="form-control">
                </div>
              </div>
              <div class="row mb-4" *ngIf="myForm.get('EQUITY_CONTRIBUTION').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Equity Paid ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="EQUITY_CONTRIBUTION_PAID"
                    formControlName="EQUITY_CONTRIBUTION_PAID">

                    <option [value]="1">Deduct from Principal</option>
                    <option [value]="2">Yes</option>
                  </select>
                </div>
              </div>
            </section>

            <section class="form-section">
              <div class="control-label medium-text-size color-blue mb-4 mt-2 normal flex">Recurring Fees
              </div>
              <div class="row mb-4">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Recurring Fees ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="RECURRING_FEES" formControlName="RECURRING_FEES">
                    <option [value]="0">No</option>
                    <option [value]="1">Yes</option>
                  </select>
                </div>
              </div>
              <div class="row mb-4" *ngIf="myForm.get('RECURRING_FEES').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Intervals ?</span>
                </div>
                <div class="col-md-7">
                  <select class="form-control" name="INTERVALS" formControlName="INTERVALS">
                    <option [value]="0">None</option>
                    <option [value]="1">30 days</option>
                    <option [value]="2">60 days</option>
                    <option [value]="3">90 days</option>
                    <option [value]="4">120 days</option>
                    <option [value]="5">180 days</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1" *ngIf="myForm.get('RECURRING_FEES').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Recurring Fees (rate)</span>
                </div>
                <div class="col-md-7">
                  <input type="text" formControlName="EQUITY_CONTRIBUTION_AMOUNT" name="TOTAL_PERCENTAGE_RECURRING_FEES"
                    class="form-control">
                </div>
              </div>
              <div class="row mb-4" *ngIf="myForm.get('RECURRING_FEES').value!='0'">
                <div class="col-md-5">
                  <span class="control-label normal default-text-size">Recurring Fees (Flat)</span>
                </div>
                <div class="col-md-7">
                  <input type="text" formControlName="TOTAL_FLAT_RECURRING_FEES" name="TOTAL_PERCENTAGE_RECURRING_FEES"
                    class="form-control">
                </div>
              </div>
            </section>
          </div>
          <div class="text-right mt-3">
            <button class="btn btn-outline-secondary btn-xs" type="button" data-toggle="collapse" data-target="#mored"
              aria-expanded="false" aria-controls="mored">
              More
            </button>
          </div>
          <div class="mt-4 flex justify-between align-center">
            <!-- <app-button class="small" (click)="request_step='2'" label="Update"></app-button>
            <app-button class="small red outline" (click)="request_step='2'" label="Cancel"></app-button> -->
            <!-- <div class="mt-4 text-left">
              <app-button class="small" [loading]="loading" [disabled]="loading" (click)="updateTheLoanRequest()"
                label="Update"></app-button>
            </div> -->
            <div class="mt-4 text-right">
              <app-button class="small" [loading]="loading" [disabled]="loading" (click)="createContract()"
                label="Update Contract"></app-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="section-info" *ngIf="request_step === '1'">
  Search for a customer and add the loan details.
</div>