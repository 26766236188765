<section class="panel">
  <app-help-popover [content]="[
      {
        title: 'What is Request about?',
        img: 'assets/images/stopwatch2.svg',
        text:
          '
        <div>
          <p>This is an at-a-glance calendar view of all the days in a selected month. The red and blue color codes
            represents ineligible and eligible requests for each day. <br><br>For example, 300k(2) for a red bar means
            that particular day had 2 ineligible requests whose sum is 300k. While 628k(4) for a blue bar means
            that particular day had 4 eligible requests whose sum is 628k.lol. 
          </p>
        </div>'
      }
    ]"></app-help-popover>
  <div class="panel-body padding-regular">
    <!-- <header class="flex justify-between align-center mb-4" *ngIf="schedule_type==true && showSwitch">
      <h3 class="section-title">Investor Schedule</h3>
      <span class="tools">
        <ui-switch (ngModelChange)="onChange($event)" [(ngModel)]="schedule_type"></ui-switch>
      </span>
    </header>
    <header class="flex justify-between align-center mb-4" *ngIf="schedule_type==false">
      <h3 class="section-title">Borrower Schedule</h3>
      <span class="tools">
        <ui-switch *ngIf="showSwitch" (ngModelChange)="onChange($event)" [(ngModel)]="schedule_type"></ui-switch>
      </span>
      <button class="btn btn-sm btn-outline-secondary" (click)="exporting=!exporting">Export</button>
    </header> -->
    <div class="flex justify-between align-center mb-4" style="float:right !important" *ngIf="showFilterOptions">
      <div class="dropdown">
        <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-ellipsis-v"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-right">
          <li>
            <a class="pointer" (click)="reloadRecords('1')">Due vs Repayments</a>
          </li>
          <li>
            <a class="pointer" (click)="reloadRecords('2')">Cash Flow Analsis</a>
          </li>
        </ul>
      </div>
    </div>
    <header class="flex justify-between align-center mb-4" *ngIf="schedule_type == false">
      <h3 class="section-title" *ngIf="from !== 'request'">{{ title }}</h3>
      <h3 class="section-title mb-4" *ngIf="from === 'request'">Incoming request calender
        <small>All request received by your platform till date (Accepted and Rejected)</small>
      </h3>
    </header>
    <div *ngIf="exporting">
      <div class="form-inline">
        <div class="form-group">
          <label class="">Date Range</label>
        </div>
        <div class="input-group">
          <input type="text" name="PIETPDATE" class="form-control" [(ngModel)]="pie_perf.PIETPDATE" ngui-datetime-picker
            date-only="true" />
          <span class="input-group-addon">To</span>
          <input type="text" name="PIETPDATE_" class="form-control" [(ngModel)]="pie_perf.PIETPDATE_"
            ngui-datetime-picker date-only="true" />
        </div>

        <button (click)="downloadRepayments()" [disabled]="pie_perf.PIETPDATE == '' || pie_perf.PIETPDATE_ == ''"
          class="btn btn-primary btn-tp edged_box">
          Submit <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </div>
    </div>
    <div *ngIf="record_type == '1' && schedule_type == false">
      <angular2-fullcalendar [options]="calendarOptions"></angular2-fullcalendar>
    </div>
    <div *ngIf="record_type == '2' && schedule_type == false && showSwitch">
      <angular2-fullcalendar [options]="calendarOptions_"></angular2-fullcalendar>
    </div>
    <div *ngIf="record_type == '1' && schedule_type == true && showSwitch">
      <angular2-fullcalendar [options]="calendarOptions__"></angular2-fullcalendar>
      <div class="legend">
        <div class="item">
          <div class="color green"></div>
          <span class="text">Due 3</span>
        </div>
        <div class="item">
          <div class="color blue"></div>
          <span class="text">Repayment</span>
        </div>
      </div>
    </div>
    <div *ngIf="record_type == '2' && schedule_type == true && showSwitch">
      <angular2-fullcalendar [options]="calendarOptions___"></angular2-fullcalendar>
      <div class="legend">
        <div class="item">
          <div class="color green"></div>
          <span class="text">Due 4</span>
        </div>
        <div class="item">
          <div class="color blue"></div>
          <span class="text">Repayment</span>
        </div>
      </div>
    </div>

    <span [ngSwitch]="from">
      <div *ngSwitchCase="'portfolio'">
        <div class="legend">
          <div class="item">
            <div class="color green"></div>
            <span class="text">Due</span>
          </div>
          <div class="item">
            <div class="color blue"></div>
            <span class="text">Repayment</span>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'request'">
        <div class="legend">
          <div class="item">
            <div class="color red"></div>
            <span class="text">Rejected</span>
          </div>
          <div class="item">
            <div class="color blue"></div>
            <span class="text">Eligible</span>
          </div>
        </div>
      </div>
      <div *ngSwitchDefault></div>
    </span>
  </div>
</section>