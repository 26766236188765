<nav class="nav__container">
  <ul class="flex__container">
    <li class="nav__list"><a [routerLink]="['/simulate/web']" class="nav__link">Simulate</a></li>
    <li class="nav__list"><a href="#" class="nav__link">|</a></li>
    <li class="nav__list"><a href="#" class="nav__link">Settings</a></li>
    <li class="nav__list"><a href="#" class="nav__link">|</a></li>
    <li class="nav__list"><a [routerLink]="['/golive']" class="nav__link">Go live</a></li>

  </ul>
</nav>
