<div class="card__container" *ngIf="grantRight('35')">
  <div class="title mb-4">Card Added ({{ card_count }})
    <div *ngIf="!sending_account_request" class="fab bottom floating green" (click)="requestCardClick()">
      <i class="fa fa-send"></i>
    </div>
    <div *ngIf="sending_account_request" class="fab bottom floating red"
      (click)="sending_account_request=!sending_account_request">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div *ngIf="sending_account_request">
    <div *ngIf="sending_account_request" class="fill-w fill-h flex-center flex-column">
      <div class="h-group center mb-4 padded">
        <h2 class="title no-float">Send Card Request</h2>
        <p class="subtitle">An email will be sent to borrower to add a card</p>
      </div>
      <div class="flex">
        <app-button class="mr-1 small" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
          (click)="requestCard()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
          [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
        </app-button>
        <app-linkgenerator [link_type]='2' [request_id]='request_id' (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
         
      </div>
      <div class="flex">
          <div class="form-group mt-2">
            <p>{{link}}</p> 
          </div> 
      </div>
    </div>
  </div>

  <div *ngIf="!sending_account_request">
    <div *ngIf="loading">
      <div class="cards">
        <div class="card skeleton fit-content">
          <div class="top mb-1">
            <div class="name">Card Bank Name</div>
          </div>
          <div class="middle mb-1">
            <div class="number">**** **** **** ****</div>
          </div>
          <div class="bottom">
            <div class="items">
              <div class="item mb-1">
                <span class="label mb-1">Status</span>
                <span class="value">Default</span>
              </div>
              <div class="item mb-1">
                <span class="label mb-1">Expiry</span>
                <span class="value">00/00</span>
              </div>
              <div class="item">
                Expiry
              </div>
            </div>
          </div>
        </div>
        <div class="card skeleton fit-content">
          <div class="top mb-1">
            <div class="name">Card Bank Name</div>
          </div>
          <div class="middle mb-1">
            <div class="number">**** **** **** ****</div>
          </div>
          <div class="bottom">
            <div class="items">
              <div class="item mb-1">
                <span class="label mb-1">Status</span>
                <span class="value">Default</span>
              </div>
              <div class="item mb-1">
                <span class="label mb-1">Expiry</span>
                <span class="value">00/00</span>
              </div>
              <div class="item">
                Expiry
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="cards" *ngIf="cards.length">
        <div class="card" *ngFor="let card of cards" [ngClass]="{'light': !card.default}">
          <div class="top">
            <div class="name">{{card.bank}} <i *ngIf="card.samebank" class="fa fa-check-circle"></i> <span *ngIf="card.samebank">(Same with account)</span><span *ngIf="card.sameaccountname">(Account name match)</span></div>
          </div>
          <div class="middle">
            <div class="number">{{card.bin.substr(0, 4)}} {{card.bin.substr(4, 2)}}** **** ****</div>
          </div>
          <div class="bottom">
            <div class="items">
              <div class="item" *ngIf="card.default === true">
                <span class="label">Status</span>
                <span class="value">Default</span>
              </div>
              <div class="item">
                <span class="label">Expiry</span>
                <span class="value">{{card.expiry_month}}/{{card.expiry_year}}</span>
              </div>
              <div class="item">
                <img *ngIf="card.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                  class="card__img">
                <img *ngIf="card.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                  class="card__img">
                <img *ngIf="card.brand === 'visa'" src="assets/images/visa_card.png" alt="card type" class="card__img">
              </div>
            </div>
          </div>
          <div class="overlay" *ngIf="!card.default">
            <app-button class="default small" label="Make Default"
              (click)="setAsConnectedCard(request_id,card?.account_card_id)"></app-button>
            <app-button class="danger small" label="Delete" (click)="deleteCard(card?.account_card_id,request_id)">
            </app-button>
          </div>
        </div> 
      </div>
      <div *ngIf="!cards.length">
        <div class="h-group center mb-4 padded empty-state mt-5">
          <div class="icon"><i class="fa fa-credit-card"></i></div>
          <h2 class="title no-float">No card</h2>
          <p class="subtitle">No card has been added</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card__container" *ngIf="!grantRight('35')">
  <div>You have no right to view this page</div>
</div>