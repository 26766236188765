<div class="profile_area animated fadeIn" *ngIf="where == '1'">

  <section class="panel contract_area hideable_area" style="margin-bottom: 10px">
    <header class="panel-heading">
      <span class="tools pull-right">
        <ul class="list-inline">
          <li><a [routerLink]="['../contract']">Summary</a></li>
          <li><a [routerLink]="['../repayments']">Schedule</a></li>
          <li><a [routerLink]="['../more']">More</a></li>
        </ul>
      </span>
      <span>Workflow</span>
    </header>
  </section>

  <section class="panel">
    <div class="panel-body" style="padding: 30px">
      <div class="mb-1" *ngFor="let approval of approvals">
        <!-- Approved -->
        <div *ngIf="approval?.IS_APPROVED=='1'">
          <div class="hover_toggle_desc apprv">
            <div class="pull-left" style="margin-right: 15px">
              <img class="img-circle" width="50"
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{approval?.WHO_APPROVED_FILE | imagenotfound}}"
                style="width: 50px; height: 50px">
            </div>
            <div class="pull-left">
              <p style="line-height: 1.2; margin-top: 3px; letter-spacing: 0.4px;">
                <span>{{approval?.LEVEL_TITLE}}</span><br>
                <span>Approved by {{approval?.WHO_APPROVED}}</span><br>
                <span>{{approval?.TIME_APPROVED | amTimeAgo}}</span>

              </p>
            </div>
            <div class="pull-right">
              <a class="pull-right btn btn-white wd_btn btn-orange edged_box hover_show"
                style=" color: #fff; margin-top: 10px" (click)="openNotes(approval?.LOAN_APPROVAL_ID)">View</a>
            </div>
          </div>
          <div class="apprv_details" *ngIf="notesOpen==approval?.LOAN_APPROVAL_ID">
            <div class="row">
              <div class="col-md-6">
                <div class="pull-left">
                  <p class="txt t_l2 t_lh1" style="font-size: 28px">
                    {{approval?.TIME_APPROVED | amDifference: approval?.TIME_REQUESTED :'minutes' : true}} minutes</p>
                  <p class="txt t_dull">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <ul class="label-value">
                  <li class="text-left ">
                    <span class="t_cool"> Request Time</span>
                    <p class="pull-right">{{approval?.TIME_REQUESTED | amDateFormat:'LL'}}</p>
                  </li>
                  <li class="text-left ">
                    <span class="t_cool"> Approval Time</span>
                    <p class="pull-right">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</p>
                  </li>
                  <li class="text-left ">
                    <span class="t_cool"> Approval By</span>
                    <p class="pull-right">{{approval?.WHO_APPROVED}}</p>
                  </li>
                  <li class="text-left ">
                    <span class="t_cool"> Notes</span>
                    <p class="pull-right">{{approval?.APPROVAL_NOTES}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <!-- Que -->
        <div class="apprv" style="margin-top: 15px;" *ngIf="approval?.IS_APPROVED!='1' && approval?.IS_SKIPPED!='1'">
          <div class="row">
            <div class="col-md-6">
              <p style="line-height: 1.2; letter-spacing: 0.4px;">

                <span>{{approval?.LEVEL_TITLE}}</span><br>
                <span>{{approval?.TIME_REQUESTED | amTimeAgo | dateinvalid}}</span>
              </p>
            </div>
            <div class="col-md-6 text-right">
              <ul class="list-inline">
                <li *ngFor="let executor of approval?.EXECUTORS">
                  <a>
                    <img class="img-circle" title="{{executor?.LEGAL_NAME}}"
                      src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{executor?.FILE_NAME | imagenotfound}}"
                      style="width: 50px; height: 50px">
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
<div *ngIf="where == '2'">
  <div class="_header" *ngIf="!hide_header">
    <div class="user">
      <div class="image">
        <img
          src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">
      </div>
      <div class="content">
        <h4 class="title no-float no-margin">{{loan?.customer?.legal_name}}</h4>
        <p class="subtitle">{{loan?.request_principal | money}} | {{loan?.request_tenor}}{{loan?.loan_duration}}</p>
      </div>
    </div>
  </div>

  <section class="padding-regular">
    <div *ngFor="let approval of approvals" class="border-bottom">
      <!-- Approved -->
      <div *ngIf="approval?.IS_APPROVED=='1'">
        <div class="flex justify-between">
          <div class="user">
            <div class="image">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{approval?.WHO_APPROVED_FILE | imagenotfound}}">
            </div>
            <div class="content">
              <h3 class="title no-float no-margin">{{approval?.LEVEL_TITLE}}</h3>
              <p class="subtitle">Approved by {{approval?.WHO_APPROVED}} {{approval?.TIME_APPROVED | amTimeAgo}}</p>
            </div>
          </div>
          <a class="btn btn-outline-secondary" (click)="openNotes(approval?.LOAN_APPROVAL_ID)">View</a>
        </div>

        <div class="mt-5" *ngIf="notesOpen==approval?.LOAN_APPROVAL_ID">
          <div class="row">
            <div class="col-md-4">
              <div class="h-group mt-2 ml-1 text-right">
                <h3 class="title mb-1">
                  {{approval?.TIME_APPROVED | amDifference: approval?.TIME_REQUESTED :'minutes' : true}}
                  minutes</h3>
                <p class="subtitle fill-w no-margin">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <ul class="decList">
                <li>
                  <span>Request Time</span>
                  <span class="pull-right">{{approval?.TIME_REQUESTED | amDateFormat:'LL'}}</span>
                </li>
                <li>
                  <span>Approval Time</span>
                  <span class="pull-right">{{approval?.TIME_APPROVED | amDateFormat:'LL'}}</span>
                </li>
                <li>
                  <span>Approval By</span>
                  <span class="pull-right">{{approval?.WHO_APPROVED}}</span>
                </li>
                <li>
                  <span>Notes</span>
                  <span class="pull-right">{{approval?.APPROVAL_NOTES}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <!-- Que -->
      <div class="mt-3" *ngIf="approval?.IS_APPROVED!='1' && approval?.IS_SKIPPED!='1'">
        <div class="alert">
          <div class="texts no-padding">
            <div class="title no-float">{{approval?.LEVEL_TITLE}}</div>
            <div class="subtitle">{{approval?.TIME_REQUESTED | amTimeAgo | dateinvalid}}</div>
          </div>
          <div class="action">
            <div class="image-avatar-list">
              <div class="image-avatar ring" *ngFor="let executor of approval?.EXECUTORS">
                <img title="{{executor?.LEGAL_NAME}}"
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{executor?.FILE_NAME | imagenotfound}}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
