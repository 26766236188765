<app-requestheader [accounts_added]="accounts_added" (requestForNewAccount)="sendAccountRequest()"
  (changeTheAccountAccount_)="changeTheDefaultAccount($event)" [loan]="loan" [accounts]="accounts" [account]='account'
  [where]='where' (checkDirectDebitStatus)="checkDirectDebitStatus($event)" (resendingLink)="resendingLink($event)"
  (cancelDirectDebit)="cancelDirectDebit($event)" (setupDirectDebit)="setupDirectDebit($event)"></app-requestheader>

<div *ngIf="!loadingData" class="overpanel-scroll">
  <div *ngIf="!mandate_history">
    <div *ngIf="sending_account_request" class="fill-w fill-h flex-center flex-column">
      <div class="h-group center mt-3 mb-3 padded">
        <h2 class="title no-float">Send Account Request</h2>
        <p class="subtitle">An email will be sent to borrower to add an account</p>
      </div>
      <div class="h-group center mb-2 padded">
        <app-button class="small red outline" (click)="sending_account_request=false"
          [disabled]="loading || is_done=='1'" label="Cancel"></app-button>
        <app-button class="small ml-1" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
          (click)="requestAccount()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
          [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
        </app-button>
        <app-linkgenerator [link_type]='3' [request_id]='request_id' (linkGenerated)="linkGenerated($event)">
        </app-linkgenerator>
      </div>
      <div class="h-group center mb-2 padded">
        <p>{{link}}</p>
      </div>
    </div>

    <div
      *ngIf="!sending_account_request && !sending_direct_request && !checking_mandate_status && !cancelling_dd && !resending_link">
      <div class="flex flex-column pd_20" *ngIf="!loading">
        <div class="card mb-4" *ngFor="let account of accounts">
          <div class="flex justify-between align-center">
            <span class="bank">{{account?.bank_name}}</span>
            <div class="flex">
              <span class="tag blue mr-2" *ngIf="account?.direct_debit_exists === true">DD Attached</span>
              <div><input type="checkbox" name="account{{account.ACCOUNT_CARD_ID}}" [(ngModel)]="account.checked"></div>
              <div class="dropdown">
                <button class="btn btn-outline-secondary btn-xs" type="button" id="dropdownMenu1" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="true"><i class="fa fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE !='1'"
                    (click)="sendForDirectDebitStatuss(account)"><a>Check Status</a></li>
                  <li *ngIf="account?.direct_debit_exists" (click)="sendForCancelDirectDebitStatus(account)"><a>Cancel</a></li>
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'"
                    (click)="sendDebitInstruction()"><a>Skip Next Debit</a></li>
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'"
                    (click)="debitMandateHistory()"><a>Show Mandate History</a></li>
                  <li *ngIf="!account?.direct_debit_exists" (click)="setupDirectDebit(account)"><a>Set up Direct Debit</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="number">{{account?.account_number}}</div>
          <div class="name">{{account?.account_name}}</div>
          <div *ngIf="account.CARD_LAST_NAME"><a href="{{account.CARD_LAST_NAME}}" target="_blank">View Mandate Form</a></div>
          <div *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'" class="name">Staus : Active</div>
          <div *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='0'" class="name">Staus : Pending</div>
          <div *ngIf="!account?.direct_debit_exists" class="name">Staus : No Mandate</div>
          <div *ngIf="account?.direct_debit_exists" class="name">ID : {{account?.DIRECT_DEBIT_REQUEST?.DD_MANDATE_ID}}</div>
          <div *ngIf="account?.direct_debit_exists" class="name">Linkk : {{account?.DIRECT_DEBIT_REQUEST?.MANDATE_LINK}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="sending_direct_request">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <form class="password__form">
              <div class="h-group center mb-4 padded">
                <h2 class="title">Setup Direct Debit Request</h2>
              </div>
              <div class="form-group">
                <label class="control-label">Source</label>
                <select class="form-control custom" name="SOURCE" [(ngModel)]="SOURCE">
                  <option></option>
                  <option value="1">Remita</option>
                  <option value="2">Cyberpay</option>
                  <option value="3">Nibbs Instant</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label">Channel</label>
                <select class="form-control custom" name="CHANNEL" [(ngModel)]="CHANNEL">
                  <option></option>
                  <option value="1">Upload Mandate Form</option>
                  <option value="2">Send Request</option>
                  <option value="3">Select File</option>
                  <option value="4">New File</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label">Start Date</label>
                <input type="date" class="form-control custom" name="start_date" [(ngModel)]="start_date">
              </div>
              <div class="form-group">
                <label class="control-label">End Date</label>
                <input type="date" class="form-control custom" name="end_date" [(ngModel)]="end_date">
              </div>
              <div class="form-group">
                <label class="control-label">Frequency</label>
                <select class="form-control custom" name="frequency" [(ngModel)]="frequency">
                  <option></option>
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="7">7</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label">First Payment Date</label>
                <input type="date" class="form-control custom" name="first_payment_date"
                  [(ngModel)]="first_payment_date">
              </div>
              <div class="form-group">
                <label class="control-label">Amount</label>
                <input type="text" class="form-control custom" name="amount" [(ngModel)]="amount">
              </div>
              <div *ngIf="CHANNEL=='2'">
                <div class="form-group">
                  <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                    placeholder="Enter Password">
                </div>

                <div class="h-group center mb-4 padded">
                  <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                    (click)="sendForDirectDebitOnAccount()" [disabled]="loading || is_done == '1' || PASSWORD == ''"
                    [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                  </app-button>
                  <app-button class="small" (click)="sending_direct_request=false" [disabled]="loading || is_done=='1'"
                    label="Cancel"></app-button>
                </div>
                <p>{{link}}</p>
              </div>
              <div *ngIf="CHANNEL=='1'">
                <div class="form-group" *ngIf="photoHasBeenUploaded && filesUploaded.length">
                  <ul class="file-list">
                    <li *ngFor="let d of filesUploaded">{{d}}</li>
                  </ul>
                </div>
                <div class="form-group">
                  <input #file style="display: none" (change)="onFilesAdded($event)" type="file" accept=".pdf">
                  <button class="btn btn-white" [disabled]="uploadingfile||loading" (click)="openUploadDialog()">
                    Add Files <i class="fa fa-spinner fa-spin" *ngIf="uploadingfile"></i></button>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                    placeholder="Enter Password">
                </div>
                <div class="h-group center mb-4 padded">
                  <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                    (click)="sendForDirectDebitOnAccount()"
                    [disabled]="loading || is_done == '1' || PASSWORD == ''||filesUploaded.length=='0'"
                    [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                  </app-button>
                  <app-button class="small" (click)="sending_direct_request=false" [disabled]="loading || is_done=='1'"
                    label="Cancel"></app-button>
                  <p>{{link}}</p>
                </div>
              </div>
              <div *ngIf="CHANNEL=='3'">
                <div class="form-group" *ngIf="uploadedfile">
                  <ul class="file-list">
                    <li><a href="{{uploadedfile}}" target="_blank">View Mandate Form</a></li>
                  </ul>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                    placeholder="Enter Password">
                </div>
                <div class="h-group center mb-4 padded">
                  <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                    (click)="sendForDirectDebitOnAccount()"
                    [disabled]="loading || is_done == '1' || PASSWORD == ''||filesUploaded.length=='0'"
                    [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                  </app-button>
                  <app-button class="small" (click)="sending_direct_request=false" [disabled]="loading || is_done=='1'"
                    label="Cancel"></app-button>

                </div>
              </div>
              <div *ngIf="CHANNEL=='4'">
                <div class="form-group">
                  <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                    placeholder="Enter Password">
                </div>
                <div class="h-group center mb-4 padded">
                  <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                    (click)="sendForDirectDebitOnAccount()" [disabled]="loading || is_done == '1' || PASSWORD == ''"
                    [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                  </app-button>
                  <app-button class="small" (click)="sending_direct_request=false" [disabled]="loading || is_done=='1'"
                    label="Cancel"></app-button>

                  <span style="display: block; margin-top:10px;" *ngIf="nf!=''"><a target="_blank"
                      href="{{nf}}">Download File</a></span>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checking_mandate_status">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Check mandate setup status</h2>
            </div>
            <form class="password__form">
              <div class="h-group center mb-4 padded">

                <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="checking_mandate_status=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cancelling_dd">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Cancelling Direct Debit Request</h2>
            </div>
            <form class="password__form">
              <!-- <div class="form-group">
                    <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                      placeholder="Enter Password">
                  </div> -->

              <div class="h-group center mb-4 padded">

                <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForCancelDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="cancelling_dd=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="resending_link">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Resending Link</h2>
            </div>
            <form class="password__form">
              <!-- <div class="form-group">
                        <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                          placeholder="Enter Password">
                      </div> -->

              <div class="h-group center mb-4 padded">

                <app-button class="small small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForCancelDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="resending_link=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mandate_history">
    <div class="flex justify-start mb-4">
      <button class="btn btn-xs btn-outline-secondary" (click)="mandate_history = false;"><i
          class="fa fa-arrow-left mr-2"></i>Back</button>
    </div>
    <div *ngIf="!records_found && !checking_instructions" class="panel-body">
      <div class="animated">
        <div class="text-center" style="min-height:304px; padding-top:70px">
          <a href="javascript:;" style="font-size:30px"><i class="fa fa-meh-o"></i></a>
          <h3>No records found</h3>
        </div>
      </div>
    </div>
    <div *ngIf="records_found && !checking_instructions">
      <table class="table table-striped">
        <thead>
          <!-- <th>#</th> -->
          <th>Amount</th>
          <th>Last Status Update</th>
          <th>Status</th>
          <th>RRR</th>
          <th>Transaction Reference</th>

        </thead>
        <tbody>
          <tr *ngFor="let trans of history">
            <td>{{trans?.amount| number:'.2-2'}}</td>
            <td>{{trans?.lastStatusUpdateTime| amDateFormat:'DD MMM YYYY'}}</td>
            <td>{{trans?.status}}</td>
            <td>{{trans?.RRR}}</td>
            <td>{{trans?.transactionRef}}</td>

          </tr>

        </tbody>

      </table>
    </div>
  </div>
</div>
<div *ngIf="loadingData" class="overpanel-scroll">
  <div class="flex flex-column pd_20">
    <div class="card mb-4">
      <div class="flex justify-between align-center">
        <span class="bank skeleton mb-1">Sample test</span>
        <div class="flex">
          <span class="tag blue mr-2 skeleton">Sample Text</span>

        </div>
      </div>
      <div class="number skeleton">Sample text</div>
      <div class="name skeleton">Sample Text</div>
    </div>
  </div>
</div>