<section class="panel mb-1" style="min-height: 500px;">
  <div *ngIf="where == '1'">
    <header class="panel-heading">
      Loan Account Statement
      <span class="tools pull-right">
        <div class="btn-group">
          <!-- <button class="btn btn-white btn-xs" (click)="breakLoan()" type="button">Break Loan</button> -->
          <!-- <button class="btn btn-white btn-xs" (click)="makeLoanPayment()" type="button">Make Payment</button> -->
          <button class="btn btn-white btn-xs" type="button">More</button>
          <button data-toggle="dropdown" class="btn btn-white dropdown-toggle" type="button" aria-expanded="false">
            <span class="caret"></span>
          </button>
          <ul role="menu" class="dropdown-menu" *ngIf="canViewLinks && loan?.loan.LOAN_STATUS <='3'">
              <li *ngIf="enable_agents_collection">
                  <a (click)="assignCollectionAgent()">Assign Collection Agent</a>
                </li> 
            <li>
              <a (click)="changeDefaultRepayment()">Change Default Repayment</a>
            </li>

            <li>
              <a (click)="requestForCard()">Request For Card</a>
            </li>

            <li *ngIf="loan?.loan.ALLOW_AUTO_DEBIT=='1'">
              <a (click)="stopAutoDebit()">Cancel Autodebit</a>
            </li>
            <li *ngIf="loan?.loan.ALLOW_AUTO_DEBIT=='0'">
              <a (click)="startAutoDebit()">Enable Autodebit</a>
            </li>
            <li>
              <a (click)="type_of_entry='4';getStatement()">Show Payments</a>
            </li>
            <li>
              <a (click)="type_of_entry='1';getStatement()">Show Disbursements</a>
            </li>
            <li>
              <a (click)="type_of_entry='3';getStatement()">Show Interests</a>
            </li>
            <li>
              <a (click)="type_of_entry='5';getStatement()">Show Fees</a>
            </li>
            <li>
              <a (click)="type_of_entry='-1';getStatement()">Show All Transactions</a>
            </li>
            <li>
              <a (click)="creditStatement()">Credit Statement</a>
            </li>
            <li>
              <a (click)="debitStatement()">Debit Statement</a>
            </li>
            <li *ngIf="loan?.loan?.USE_REMITA=='1' && (loan?.loan?.REMITA_MANDATE_REF =='')">
              <a (click)="initiateSendRemitaLoanNotification(loan)">Initiate Remita Inflight</a>
            </li>
            <li *ngIf="loan?.loan?.USE_REMITA=='1' && loan?.loan?.REMITA_MANDATE_REF !=''">
              <a (click)="initiateStopRemitaCollection()">Cancel Remita Inflight</a>
            </li>
            <li *ngIf="loan?.enable_buy_back">
              <a (click)="initiateLoanBuyBack()">Buy Back Loan</a>
            </li>
            <li>
              <a (click)="modifyContract()">Modify Contract</a>
            </li>
            <li>
              <a (click)="modifySchedule()">Modify Schedule</a>
            </li>
            <li>
              <a (click)="sendStatement()">Send Statement</a>
            </li>
          </ul>
        </div>
      </span>
    </header>
    <div class="panel-body">
      <div class="row invoice-list" style="margin-bottom:0px">
        <div class="col-lg-4 col-sm-4">
          <h4>CLIENT INFO</h4>
          <ul class="unstyled">
            <li>Client :
              <strong>{{loan?.custoner_account.LEGAL_NAME}}</strong>
            </li>
            <li>Account Number : {{loan?.custoner_account.ACCOUNT_NUMBER}}</li>
            <li>Repayment Mode : {{repayment_mode[loan?.loan?.REPAYMENT_MODE]}}</li>
            <p *ngIf="loan?.loan?.ACCOUNT_MANAGER !=''">Account Officer : {{loan?.loan?.ACCOUNT_MANAGER}}
            </p>
          </ul>
        </div>
        <div class="col-lg-4 col-sm-4">
          <!-- <h4>OVERDUE IDEBTEDNESS</h4>
                                       <ul class="unstyled">
                                            <li>Total Debit		: <strong>{{}}</strong></li>
                                            <li>Total Credit		: 1,000,000.00</li>
                                            <li>Balance		: 1,650,000.00</li>
                                        </ul> -->
          &nbsp;
        </div>
        <div class="col-lg-4 col-sm-4">
          <h4>ACCOUNT SUMMARY</h4>
          <ul class="unstyled">
            <li>Principal :
              <strong>{{loan?.loan.REQUEST_PRINCIPAL|number:'.2-2'}}</strong>
            </li>
            <li>Total Debit : {{loan?.TOTAL_DEBIT|number:'.2-2'}}</li>
            <li>Total Credit : {{loan?.TOTAL_CREDIT|number:'.2-2'}}</li>
            <li>Current Balance : {{loan?.loan?.LOAN_BALANCE|number:'.2-2'}}</li>
          </ul>
        </div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Debit</th>
          <th>Credit</th>
          <th>Balance</th>
          <th></th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let trans of transactions;let i=index;">
          <td>{{trans.DATE_ADDED | amDateFormat: 'YYYY-MM-DD'}}</td>
          <td>{{trans.NARRATION_CUSTOMER}}</td>
          <td>{{trans.DEBIT|number:'.2-2'}}</td>
          <td>{{trans.CREDIT|number:'.2-2'}}</td>

          <td>{{trans.BALANCE|number:'.2-2'}}</td>
          <td>
            <div class="btn-group" *ngIf="type_of_entry=='4'">

              <button data-toggle="dropdown" class="btn btn-white dropdown-toggle" type="button" aria-expanded="false">
                <span class="caret"></span>
              </button>
              <ul role="menu" class="dropdown-menu">
                <li>
                  <a (click)="initiateRollback(trans)">Rollback</a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="where == '2'">
    <div class="panel-body padding-regular">
      <div class="flex justify-between align-center" [ngClass]="{'border-bottom': showHeader}">
        <div class="btn-group" *ngIf="canViewLinks && loan?.loan.LOAN_STATUS <='3'">
          <!-- <button type="button" (click)="makePay()" class="btn btn-xs btn-outline-info">Make Repayment</button>
          <button type="button" (click)="breakPay()" class="btn btn-xs btn-outline-secondary ">Break Loan</button> -->
          <!-- <button type="button" class="btn btn-xs btn-outline-secondary">More</button> -->
          <button type="button" class="btn btn-xs btn-outline-secondary dropdown-toggle" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-ellipsis-h"></i>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <ul role="menu" class="dropdown-menu" *ngIf="transactions">
              <li *ngIf="enable_agents_collection">
                  <a (click)="assignCollectionAgent()">Assign Collection Agent</a>
                </li> 
            <li>
              <a (click)="changeDefaultRepayment()">Change Default Repayment</a>
            </li>

            <li>
              <a (click)="requestForCard()">Request For Card</a>
            </li>

            <li *ngIf="loan?.loan.ALLOW_AUTO_DEBIT=='1'">
              <a (click)="stopAutoDebit()">Cancel Autodebit</a>
            </li>
            <li *ngIf="loan?.loan.ALLOW_AUTO_DEBIT=='0'">
              <a (click)="startAutoDebit()">Enable Autodebit</a>
            </li>
            <li>
              <a (click)="type_of_entry='4';getStatement()">Show Payments</a>
            </li>
            <li>
              <a (click)="type_of_entry='1';getStatement()">Show Disbursements</a>
            </li>
            <li>
              <a (click)="type_of_entry='3';getStatement()">Show Interests</a>
            </li>
            <li>
              <a (click)="type_of_entry='5';getStatement()">Show Fees</a>
            </li>
            <li>
              <a (click)="type_of_entry='-1';getStatement()">Show All Transactions</a>
            </li>
            <li>
              <a (click)="creditStatement()">Credit Statement</a>
            </li>
            <li>
              <a (click)="debitStatement()">Debit Statement</a>
            </li>
            <li>
              <a (click)="suspendFines()">Suspend Fines</a>
            </li>
            
            <li *ngIf="loan?.loan?.USE_REMITA=='1' && (loan?.loan?.REMITA_MANDATE_REF =='')">
              <a (click)="initiateSendRemitaLoanNotification(loan)">Initiate Remita Inflight</a>
            </li>
            <li *ngIf="loan?.loan?.USE_REMITA=='1' && loan?.loan?.REMITA_MANDATE_REF !=''">
              <a (click)="initiateStopRemitaCollection()">Cancel Remita Inflight</a>
            </li>
            <li *ngIf="loan?.enable_buy_back">
              <a (click)="initiateLoanBuyBack()">Buy Back Loan</a>
            </li>
             <li>
              <a (click)="modifyContract()">Modify Contract</a>
            </li>
            <li>
              <a (click)="modifySchedule()">Modify Schedule</a>
            </li>
            <li>
              <a (click)="sendStatement()">Send Statement</a>
            </li>
          </ul>
        </div>
        <div class="flex flex-1" *ngIf="showHeader">
          <div class="flex-1 flex-center" (click)="changePortfolioView('statement')">
            <div class="icon-text medium cursor-pointer active">
              <div class="text">Statement</div>
            </div>
          </div>
          <div class="flex-1 flex-center" (click)="changePortfolioView('schedule')">
            <div class="icon-text medium cursor-pointer">
              <div class="text">Schedule</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row mb-4 mt-5 panel pd_15">
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Total Debit :</span>
          <span class="default-text-size">{{loan?.TOTAL_DEBIT|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Total Credit :</span>
          <span class="default-text-size">{{loan?.TOTAL_CREDIT|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Accrued Interest :</span>
          <span class="default-text-size">{{loan?.accrued_interest?.accruedinterest|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Current Balance :</span>
          <span class="default-text-size">{{loan?.loan?.LOAN_BALANCE|number:'.2-2'}}</span>
        </div>
        <!-- <div class="flex-1 mr-4" style="margin-bottom:0px">
        <ul class="decList bigger">
          <li class="title text-bold">Client Info</li>
          <li><span>Client : </span><span>{{loan?.custoner_account.LEGAL_NAME}}</span></li>
          <li><span>Account Number :</span> <span>{{loan?.custoner_account.ACCOUNT_NUMBER}}</span></li>
          <li><span>Repayment Mode : </span><span>{{repayment_mode[loan?.loan?.REPAYMENT_MODE]}}</span>
          </li>
          <li *ngIf="loan?.loan?.ACCOUNT_MANAGER !=''"><span>Officer :</span>
            <span>{{loan?.loan?.ACCOUNT_MANAGER}}</span></li>
        </ul>
      </div>
      <div class="flex-1 ml-4">
        <ul class="decList bigger">
          <li class="title">Account Summary</li>
          <li><span>Principal : </span><span>{{loan?.loan.REQUEST_PRINCIPAL|number:'.2-2'}}</span></li>
          <li><span>Total Debit :</span> <span>{{loan?.TOTAL_DEBIT|number:'.2-2'}}</span></li>
          <li><span>Total Credit :</span> <span>{{loan?.TOTAL_CREDIT|number:'.2-2'}}</span></li>
          <li><span>Current Balance :</span> <span>{{loan?.loan?.LOAN_BALANCE|number:'.2-2'}}</span></li>
        </ul>
      </div> -->
      </div>
      <div class="panel-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let trans of transactions;let i=index;">
              <td style="width: 100px">{{trans.DATE_ADDED | amDateFormat: 'YYYY-MM-DD'}}</td>
              <td style="width: 200px">{{trans.NARRATION_CUSTOMER}}</td>
              <td>{{trans.DEBIT|number:'.2-2'}}</td>
              <td>{{trans.CREDIT|number:'.2-2'}}</td>

              <td>{{trans.BALANCE|number:'.2-2'}}</td>
              <td>
                <div class="btn-group" *ngIf="type_of_entry == '4'">
                  <button data-toggle="dropdown" class="btn btn-white dropdown-toggle" type="button"
                    aria-expanded="false">
                    <span class="caret"></span>
                  </button>
                  <ul role="menu" class="dropdown-menu">
                    <li>
                      <a (click)="initiateRollback(trans)">Rollback</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="where == '6'">
    <div class="panel-body padding-regular">
      <div class="flex justify-between align-center" [ngClass]="{'border-bottom': showHeader}">
        <div class="btn-group">
          <button type="button" class="btn btn-xs btn-outline-secondary dropdown-toggle" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-ellipsis-h"></i>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <ul role="menu" class="dropdown-menu" *ngIf="transactions">
            <li>
              <a (click)="type_of_entry='4';getStatement()">Initiate Period Collection</a>
            </li>
            <li>
              <a (click)="type_of_entry='1';getStatement()">Top Up</a>
            </li>
            <li>
              <a (click)="type_of_entry='3';getStatement()">Withdraw</a>
            </li>
            <li>
              <a (click)="type_of_entry='5';getStatement()">Terminate</a>
            </li>
            <li>
              <a (click)="type_of_entry='-1';getStatement()">Generate Statement</a>
            </li> 
          </ul>
        </div>
        <div class="flex flex-1" *ngIf="showHeader">
          <div class="flex-1 flex-center" (click)="changePortfolioView('statement')">
            <div class="icon-text medium cursor-pointer active">
              <div class="text">Statement</div>
            </div>
          </div>
          <div class="flex-1 flex-center" (click)="changePortfolioView('schedule')">
            <div class="icon-text medium cursor-pointer">
              <div class="text">Schedule</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row mb-4 mt-5 panel pd_15">
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Total Debit :</span>
          <span class="default-text-size">{{loan?.TOTAL_DEBIT|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Total Credit :</span>
          <span class="default-text-size">{{loan?.TOTAL_CREDIT|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Accrued Interest :</span>
          <span class="default-text-size">{{loan?.accruedinterest|number:'.2-2'}}</span>
        </div>
        <div class="flex-1 flex flex-column">
          <span class="color-red small-text-size mb-1">Current Balance :</span>
          <span class="default-text-size">{{loan?.investment?.LOAN_BALANCE|number:'.2-2'}}</span>
        </div>
       
      </div>
      <div class="panel-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let trans of transactions;let i=index;">
              <td style="width: 100px">{{trans.DATE_ADDED | amDateFormat: 'YYYY-MM-DD'}}</td>
              <td style="width: 200px">{{trans.NARRATION_CUSTOMER}}</td>
              <td>{{trans.DEBIT|number:'.2-2'}}</td>
              <td>{{trans.CREDIT|number:'.2-2'}}</td>

              <td>{{trans.BALANCE|number:'.2-2'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<!-- TODO: Markup -->