
<div *ngIf="requestingData?.section != 'KYC' && requestingData?.section != 'KYCWork'">
  <div class="h-group center mb-4 padded">
    <h3 class="title">Requesting {{requestingData?.section}}</h3>
    <div *ngIf="requestingData?.section=='Work Email'" style="width:50%; margin:0px auto; margin-top:20px">
      <label class="form-group flex align-center">
        <span class="control-label normal fill-w flex justify-between align-center">
          Request for work email
          <input  type="checkbox" name="ask_for_new_email" [(ngModel)]="ask_for_new_email" />
        </span>
      </label>
    </div>
    <p class="subtitle mb-4">An email will be sent to the customer.</p>
    <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="sendRequestForData()"
      [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
      [loading]="loading && is_done=='0'" buttonclass="small mt-3 mr-1">
    </app-button>
    <app-linkgenerator *ngIf="!amongSections(requestingData?.section)" [link_type]='requestingData.link_type' [people_id]="customerdetail.people_id" [request_id]='loan?.request_id' [priority]="priority"
      (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
    <div class="form-group mt-2">
      <p>{{link}}</p>
    </div>
  </div>
</div>

<div *ngIf="requestingData?.section == 'KYC'">
  <div class="h-group center mb-4 padded">
    <h3 class="title">Requesting KYC</h3>
    <div *ngIf="requestingData?.section=='Work Email'" style="width:50%; margin:0px auto; margin-top:20px">
      <label class="form-group flex align-center">
        <span class="control-label normal fill-w flex justify-between align-center">
          Send to partner for KYC Verification
          <!-- <input  type="checkbox" name="ask_for_new_email" [(ngModel)]="ask_for_new_email" /> -->
        </span>
      </label>
    </div>
    <p><textarea class="form-control" name="customfields" [(ngModel)]="customfields"></textarea></p>
    <!-- <p class="subtitle mb-4">An email will be sent to the customer.</p> -->
    <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="requestForKYC()"
      [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
      [loading]="loading && is_done=='0'" buttonclass="small mt-3 mr-1">
    </app-button>
    <!-- <app-linkgenerator *ngIf="!amongSections(requestingData?.section)" [link_type]='requestingData.link_type' [people_id]="customerdetail.people_id" [request_id]='loan?.request_id' [priority]="priority"
      (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
    <div class="form-group mt-2">
      <p>{{link}}</p>
    </div> -->
  </div>
</div>
<div *ngIf="requestingData?.section == 'KYCWork'">
  <div class="h-group center mb-4 padded">
    <h3 class="title">Requesting KYC Work</h3>
    <div *ngIf="requestingData?.section=='Work Email'" style="width:50%; margin:0px auto; margin-top:20px">
      <label class="form-group flex align-center">
        <span class="control-label normal fill-w flex justify-between align-center">
          Send to partner for KYC Verification
          <!-- <input  type="checkbox" name="ask_for_new_email" [(ngModel)]="ask_for_new_email" /> -->
        </span>
      </label>
    </div>
    <!-- <p class="subtitle mb-4">An email will be sent to the customer.</p> -->
    <p><textarea class="form-control" name="customfields" [(ngModel)]="customfields"></textarea></p>
    <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="requestForKYCWork()"
      [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
      [loading]="loading && is_done=='0'" buttonclass="small mt-3 mr-1">
    </app-button>
    <!-- <app-linkgenerator *ngIf="!amongSections(requestingData?.section)" [link_type]='requestingData.link_type' [people_id]="customerdetail.people_id" [request_id]='loan?.request_id' [priority]="priority"
      (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
    <div class="form-group mt-2">
      <p>{{link}}</p>
    </div> -->
  </div>
</div>