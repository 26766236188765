<div class="panel bg-off-white">
  <div class="alert">
    <div class="texts no-padding">
      <div class="title">Micro Credit Summary</div>
      <div class="subtitle">Run microcredit summary</div>
    </div>
    <div class="action">
      <app-button *ngIf="!loading" class="small outline mr-1" label="Run Check" (click)="openMore.emit()"></app-button>
      <app-button *ngIf="!loading && has_data" class="small outline" label="View Result" (click)="openResult()"></app-button> 
    </div>
  </div>
</div>