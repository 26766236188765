<div class="panel panel-default setting">
  <div class="panel-heading" role="tab" id="headingF">
    <h4 class="panel-title">
      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseF"
        aria-expanded="false" aria-controls="collapseF">
        MBS
      </a>
    </h4>
  </div>
  <div id="collapseF" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingF">
    <div class="panel-body">
      <form #fines="ngForm" novalidate>
         
        <label class="form-group" >
          <span class="control-label normal">Client ID</span>
          <input name="client_id" required [(ngModel)]="mbssettings.client_id" type="text"
            class="form-control">
        </label>
        <label class="form-group" >
          <span class="control-label normal">Client Secret</span>
          <input name="client_secret" required [(ngModel)]="mbssettings.client_secret" type="password"
            class="form-control">
        </label>
        
        <app-button type="submit" (click)="saveFines(fines.value, fines.valid)" [disabled]="loading||!fines.valid"
          class="small" label="Submit" [loading]="loading">
        </app-button>
      </form>
    </div>
  </div>
</div>