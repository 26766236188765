import { OnChanges, SimpleChanges, SimpleChange, Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../../_services/index';
import { DataService, OptionsserviceService, AnalyticsService, LoansService, OperationsService } from '../../../_services/index';
import { IonRangeSliderComponent } from 'ng2-ion-range-slider';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-runselectedanalyticsmodal',
  templateUrl: './runselectedanalyticsmodal.component.html',
  styleUrls: ['./runselectedanalyticsmodal.component.css', './boot-outline-btn.css', './style.css']
})
export class RunselectedanalyticsmodalComponent implements OnChanges, OnInit {

  view = 'master';
  @Input('token') token: any;
  @Input('parentRouteId') parentRouteId: any;
  @Input('loan') loan: any;
  @Input('analytics_data_status') analytics_data_status: any;
  @Output() closeSelectedAnalyticsModal = new EventEmitter();
  @Input('selected_analytics') selected_analytics = '1';
  @Input('nodesandscores_') nodesandscores_: any;
  @Input('nodesandscores') nodesandscores: any;
  @Input('isedit') isedit = '0';
  @Input('is_edit') is_edit = '0';
  analytics_result: any;
  @Input('analytics_last_done') analytics_last_done = '';
  loading = false;
  type = '';
  icon = '';
  sms_value: any;
  guarantor_value: any;
  call_logs_value: any;
  cards_value: any;
  profile_value: any;
  social_value: any;
  accounts_value: any;
  dd_value: any;
  repayment_value: any;
  lender_id: any;
  borrower_id: any;
  principal: any;
  maturity_date: any;
  account_card_id: any;
  loan_request_id: any;
  card_id: any;
  passed_analytics: any;
  failed_analytics: any;
  number_passed: number;
  number_failed: number;
  analytics_score: number;
  finished = false;
  payload: any;
  prefetch_value: any;
  bvn_value: any;
  constructor(public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public router: Router,
    public analyticsService: AnalyticsService,
    private http: HttpClient) {
    }

  ngOnInit() {
    // if (this.isedit == '0') {
    //   this.getAnalyticsNodes(this.selected_analytics);
    // }
    console.log(this.analytics_data_status);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.isedit == '1') {
    //   const nodesandscores_: SimpleChange = changes.nodesandscores_;
    //   if (nodesandscores_.currentValue) {
    //     this.nodesandscores = nodesandscores_.currentValue;
    //   }
    // }

  }
  getAnalyticsNodes(selected_analytics) {

    this.loansService.getAnalyticsNodesAndScores(this.token, this.selected_analytics, this.loan)
      .subscribe(data => {
        if (data.status) {
          this.nodesandscores = data.data.nodesandscores;
          this.analytics_last_done = data.data.sms_analytics_last_done;
        }
      });


  }
  changeAnalyticsNode(event) {
    this.getAnalyticsNodes(event.target.value);
  }
  runTheSelectedAnalytics() {
    if (this.selected_analytics === '1') {
      this.runTheSMSAnalytics();
    }
    if (this.selected_analytics === '2') {
      this.runTheCallLogsAnalytics();
    }
    if (this.selected_analytics === '6') {
      this.runTheRepaymentAnalytics();
    }
    if (this.selected_analytics === '4') {
      this.runTheProfileAnalytics();
    }
    if (this.selected_analytics === '5') {
      this.runTheSocialAnalytics();
    }
    if (this.selected_analytics === '3') {
      this.runTheStatementAnalytics();
    }
    if (this.selected_analytics === '7') {
      this.runRemitaAndCardAnalytics();
    }
  }
  runTheSocialAnalytics() {
    this.loading = true;
    this.loansService.runTheSocialAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.loading = false;
        this.analytics_result = data.result;
        this.analytics_last_done = data.sms_analytics_last_done;
        this.nodesandscores = data.nodes_and_scores;
      });
  }
  runTheStatementAnalytics() {
    this.loansService.runTheStatementAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.analytics_result = data.result;
      });
  }
  runTheSMSAnalytics() {
    this.loansService.runTheSMSAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.analytics_result = data.result;
      });
  }
  runTheCallLogsAnalytics() {
    this.loansService.runTheCallLogsAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.analytics_result = data.result;
      });
  }
  runTheRepaymentAnalytics() {
    this.loansService.runTheRepaymentAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.analytics_result = data.result;
      });
  }
  runTheProfileAnalytics() {
    this.loansService.runTheProfileAnalytics(this.token, this.selected_analytics, this.loan, this.nodesandscores)
      .subscribe(data => {
        this.analytics_result = data.result;
      });
  }
  runRemitaAndCardAnalytics() {
  }
  viewTheSelectedAnalytics() {
    this.dataService.closeAnalyticsModal.emit();
    if (this.selected_analytics === '1') {
      this.viewSMSAnalyticsResult();
    }
    if (this.selected_analytics === '2') {
      this.viewCalllogsAnalyticsResult();
    }
    if (this.selected_analytics === '5') {
      this.viewSocialsAnalyticsResult();
    }
    if (this.selected_analytics === '6') {
      this.viewTheRepaymentAnalyticsResult();
    }
    if (this.selected_analytics === '4') {
      this.viewTheProfileAnalyticsResult();
    }
    if (this.selected_analytics === '3') {
      this.viewTheStatementAnalyticsResult();
    }
    if (this.selected_analytics === '7') {
      this.viewTheDirectDebitAnalyticsResult();
    }
    if (this.selected_analytics === '17') {
      this.viewTheBVNAnalyticsResult();
    }
    if (this.selected_analytics === '27') {
      this.viewTheCardAnalyticsResult();
    }
    if (this.selected_analytics === '28') {
      this.viewTheBankAnalyticsResult();
    }
    if (this.selected_analytics === '29') {
      this.viewTheAccountAnalyticsResult();
    }
    if (this.selected_analytics === '30') {
      this.viewTheGuarantorAnalyticsResult();
    }
  }
  viewTheStatementAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/bankstatement']);
  }
  viewTheCardAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/cards']);
  }
  viewTheBankAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/accounts']);
  }
  viewTheProfileAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/profile']);
  }
  viewSMSAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/sms']);
  }
  viewTheBVNAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/bvn']);
  }
  viewCalllogsAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/calllogs']);
  }
  viewSocialsAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/social']);
  }
  viewTheRepaymentAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/repayments']);
  }
  viewTheDirectDebitAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/direct_debit']);
  }
  viewTheAccountAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/accounts']);
  }
  viewTheGuarantorAnalyticsResult() {
    this.closeSelectedAnalyticsModal.emit();
    this.router.navigate(['./applications/analytics/' + this.parentRouteId + '/guarantor']);
  }
  update__(slider, event, node, i) {
    node.POSITIVE_WEIGHT = event.from;
    this.nodesandscores[i] = node;
  }

  formatText(text) {
    return this.capitalize(text.replace(/_/ig, ' '));
  }

  capitalize(text) {
    return text.split(' ').map(str => `${str.substr(0, 1).toUpperCase()}${str.substr(1, str.length).toLowerCase()}`).join(' ');
  }
  runSpecificAnalysis(type) {
    this.type = type;
    this.view = 'child';
  }
  changeSmsValue() {
    if (this.analytics_data_status.sms) {
      this.sms_value = '1';
      this.type = 'SMS';
      this.view = 'child';
      this.selected_analytics = '1';
    } else {
      this.view = 'invite_for_data'
    }
  }
  changeBVNValue() {
    if (this.analytics_data_status.bvn){
      this.bvn_value = '1';
      this.type = 'BVN';
      this.view = 'child';
      this.selected_analytics = '17';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeCallLogsValue() {
    if (this.analytics_data_status.call_logs) {
      this.call_logs_value = '1';
      this.type = 'Call Logs';
      this.view = 'child';
      this.selected_analytics = '2';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeSocialValue() {
    if (this.analytics_data_status.social) {
      this.social_value = '1';
      this.type = 'Social Media';
      this.view = 'child';
      this.selected_analytics = '5';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeProfileValue() {
    if (this.analytics_data_status.profile) {
      this.profile_value = '1';
      this.type = 'Profile';
      this.view = 'child';
      this.selected_analytics = '4';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeGuarantorValue() {
    if (this.analytics_data_status.guarantor) {
      this.guarantor_value = '1';
      this.type = 'Guarantor';
      this.view = 'child';
      this.selected_analytics = '30';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeRepaymentValue() {
    if (this.analytics_data_status.repayments) {
      this.repayment_value = '1';
      this.type = 'Repayments';
      this.view = 'child';
      this.selected_analytics = '6';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeCardValue() {
    if (this.analytics_data_status.cards) {
      this.cards_value = '1';
      this.type = 'Cards';
      this.view = 'child';
      this.selected_analytics = '27';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeAccountValue() {
    if (this.analytics_data_status.account) {
      this.accounts_value = '1';
      this.type = 'Accounts';
      this.view = 'child';
      this.selected_analytics = '29';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeDdValue() {
    if (this.analytics_data_status.direct_debit) {
      this.dd_value = '1';
      this.type = 'Direct Debit';
      this.view = 'child';
      this.selected_analytics = '7';
    } else {
      this.view = 'invite_for_data';
    }
  }
  changeBankStatementValue() {

  }
  // changeBVNValue() {
  //   this.bvn_value = '1';
  //   this.type = 'BVN';
  //   this.view = 'child';
  //   this.selected_analytics = '7';
  // }
  viewAnalytics() {

  }
  getFullAnalyticsPreFetch() {
    this.loading = true;
    return this.analyticsService
      .getFullAnalyticsPreFetch(
        this.lender_id,
        this.borrower_id,
        this.principal,
        this.maturity_date,
        this.account_card_id,
        this.loan_request_id,
        this.card_id,
        this.sms_value,
        this.call_logs_value,
        this.profile_value,
        this.guarantor_value,
        this.cards_value,
        this.accounts_value,
        this.dd_value,
        this.repayment_value,
        this.social_value,
        this.prefetch_value
      )
      .subscribe(data => {
        this.finished = true;
        this.loading = false;
        this.dataService.emitAnalytics.emit({
          status: data.status,
          sms_analytics: data.description.sms
        });
      }, err => {
        this.loading = false;
      });
  }
  getFullAnalyticsnow() {
    this.loading = true;
    return this.analyticsService
      .getFullAnalytics(
        this.lender_id,
        this.borrower_id,
        this.principal,
        this.maturity_date,
        this.account_card_id,
        this.loan_request_id,
        this.card_id,
        this.sms_value,
        this.call_logs_value,
        this.profile_value,
        this.guarantor_value,
        this.cards_value,
        this.accounts_value,
        this.dd_value,
        this.repayment_value,
        this.social_value,
        this.bvn_value
      )
      .subscribe(data => {
        this.finished = true;
        this.loading = false;
        this.dataService.emitAnalytics.emit({
          status: data.status,
          sms_analytics: data.description.sms
        });
      }, err => {
        this.loading = false;
      });
  }
  getFullAnalytics() {
    if (this.guarantor_value == '1') {
      this.loading = true;
      return this.analyticsService
        .getFullAnalyticsGuarantor(
          this.payload.lender,
          this.payload.customer,
          this.payload.guarantor,
          this.payload.loan,
          this.payload.request_guarantor
        )
        .subscribe(data => {
          this.finished = true;
          this.loading = false;
          this.prefetch_value = data;
          this.getFullAnalyticsPreFetch();
        }, err => {
          this.loading = false;
        });
    } else if (this.dd_value == '1') {
      this.loading = true;
      return this.analyticsService
        .getFullAnalytics__(
          this.payload.lender,
          this.payload.customer,
          this.payload.direct_debit
        )
        .subscribe(data => {
          this.finished = true;
          this.loading = false;
          this.prefetch_value = data;
          this.getFullAnalyticsPreFetch();
        }, err => {
          this.loading = false;
        });
    } else if (this.sms_value) {
      this.loading = true;
      return this.analyticsService
        .getFullAnalytics___(
          this.payload.lender,
          this.payload.customer,
          this.payload.sms_logs
        )
        .subscribe(data => {
          this.finished = true;
          this.loading = false;
          this.prefetch_value = data;
          this.getFullAnalyticsPreFetch();
        }, err => {
          this.loading = false;
        });
    } else {
      this.getFullAnalyticsnow();
    }
  }
  runAnalytics() {
    this.loading = true;
localStorage.setItem('params', JSON.stringify({sms: this.sms_value, card: this.cards_value, direct_debit: this.dd_value, calllogs: this.call_logs_value, account: this.accounts_value, guarantor: this.guarantor_value, repayments: this.repayment_value, profile: this.profile_value, social: this.social_value, bvn: this.bvn_value}));
    return new Promise((resolve, reject) => {
      return this.http
        .post<any>(`https://eligibilitytest.creditclan.com/public/requestparams`, {
          request_id: this.parentRouteId, sms_value: this.sms_value, call_logs_value: this.call_logs_value,
          profile_value: this.profile_value, guarantor_value: this.guarantor_value, cards_value: this.cards_value,
          account_value: this.accounts_value, dd_value: this.dd_value, repayment_value: this.repayment_value,
          social_value: this.social_value, bvn_value: this.bvn_value
        })
        .toPromise()
        .then(res => {
          this.lender_id = res.lender_id;
          this.borrower_id = res.borrower_id;
          this.principal = res.principal;
          this.maturity_date = res.maturity_date;
          this.account_card_id = res.account_card_id;
          this.loan_request_id = res.loan_request_id;
          this.card_id = res.card_id;
          this.payload = res.payload;
          resolve();
        })
        .then(() => this.getFullAnalytics())
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    });
  }
  openModal(section) {
    //console.log('clicked', section);
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan, analytics_data_status: this.analytics_data_status });
  }
}
