import { Component, OnInit } from '@angular/core';
import { Invites, InvitesService } from '../../_services/invites.service';
import { StorageService, DataService } from '../../_services/index';
import { throwError } from 'rxjs';
import { CustomersService } from 'src/app/_services/customers.service';

@Component({
  selector: 'app-allinvites',
  templateUrl: './allinvites.component.html',
  styleUrls: ['./allinvites.component.css']
})
export class AllinvitesComponent implements OnInit {
  currentUser: any;
  invites: object;
  loading: boolean;
  keyword: string;
  openedTab: any;
  activeRequestId='';
  loan:any;
  constructor(
    private customersSrvc: CustomersService,
    private storageService: StorageService,
    private dataService: DataService,
    private _invitesService: InvitesService
  ) { 
    this.dataService.multipleInvitesSent.subscribe(res => {
      this.getUserInvites(this.currentUser.token);
   })
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.storageService.read<any>('currentUser');

    this.loading = true;

    try {
      this.invites = await this.getUserInvites(this.currentUser.token);
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  getUserInvites(token: string): Promise<object> {
    const payload = { token: token };

    return new Promise((resolve, reject) => {
      this._invitesService.getInvites(payload).subscribe(
        (data: Invites) => {
          resolve(data.data);
        },
        error => {
          reject(throwError(error));
        }
      );
    });
  }

  searchInvites(): void {

  }

  showInvitePreview(event, index): void {
    event.preventDefault();

    this.openedTab = index;
  }

  viewProfile(customer): void {

  }

  inviteNewCustomer(): void {
    this.dataService.inviteNewCustomer.emit({});
  }
  loadRequest(request) {  
    this.activeRequestId = request.PEOPLE_ID;
    this.loadCustomerDetails(request.PEOPLE_ID);
  }
  loadCustomerDetails(people_id){
    this.customersSrvc.getCustomerProfile(1, people_id).subscribe(data => {
      this.loan = data.cust;
     
      this.dataService.loadHomeCustomerSideview.emit({ 'cust': data.cust });

    });
  }
}
