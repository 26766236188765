<div class="panel box edged_box mb_10" *ngIf="!loan?.schedule_has_been_created">
  <div class="panel-body">
    <section class="fill-h fill-v">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title">No Schedule</h2>
        <p class="subtitle">Schedule not yet created</p>
        <div class="text-center mt-2">
          <!-- <app-button label="Modify contract" buttonclass="small outline" (click)="newContract()"></app-button> -->
          <app-button *ngIf="view_from !='approvals' && !loan?.is_general_market" label="Create Contract" buttonclass="small outline"
            (click)="newContract()"></app-button>
        </div>
      </div>
    </section>
  </div>
</div>
<div *ngIf="loan?.schedule_has_been_created">
  <section class="panel" *ngIf="!status && where == '1'">
    <div class="animated">
      <div class="text-center" style="min-height:304px; padding-top:70px">
        <a href="javascript:;" style="font-size:40px"><i class="fa fa-meh-o"></i></a>
        <h3>Schedule has not been generated</h3>
      </div>
    </div>
  </section>

  <section class="panel" *ngIf="status">
    <app-help-popover [content]="[
        {
          title: 'How loan Schedule works?',
          img: 'assets/images/schedule.svg',
          text:
            '
    <div>
      <p> A listing of the amounts of principal and interest, due dates, and balance after payment for a given loan.
      </p>
    </div>'
        }
      ]"></app-help-popover>
    <div class="panel-body padding-regular">
      <h3 class="section-title mb-4">Loan Schedule</h3>
      <div class="contract-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <!-- <th>OB</th> -->
              <th>Principal</th>
              <th>Interest</th>
              <!-- <th>Fees</th> -->
              <th>Repayment</th>
              <th>CB</th>
            </tr>
          </thead>
          <tbody *ngIf="OPEN_SCHEDULE">
            <tr *ngFor="let item of schedule">
              <td>
                <input style="width:100%" type="text" name="REPAYMENT_DATE" [(ngModel)]="item.REPAYMENT_DATE"
                  ngui-datetime-picker date-only="true" />
              </td>
              <!-- <td>
              <input style="width:100%" type="text" name="INITIAL_PRINCIPAL" [(ngModel)]="item.INITIAL_PRINCIPAL">
            </td> -->
              <td>
                <input style="width:100%" type="text" name="PRINCIPAL_REPAYMENT"
                  [(ngModel)]="item.PRINCIPAL_REPAYMENT" />
              </td>
              <td>
                <input style="width:100%" type="text" name="INTEREST_REPAYMENT" [(ngModel)]="item.INTEREST_REPAYMENT" />
              </td>
              <!-- <td>
              <input style="width:100%" type="text" name="FEES" [(ngModel)]="item.FEES">
            </td> -->
              <td>
                <input style="width:100%" type="text" name="TERM_REPAYMENT" [(ngModel)]="item.TERM_REPAYMENT" />
              </td>
              <td>
                <input style="width:100%" type="text" name="OUTSTANDING_PRINCIPAL"
                  [(ngModel)]="item.OUTSTANDING_PRINCIPAL" />
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!OPEN_SCHEDULE">
            <tr *ngFor="let item of schedule">
              <td>{{ item.REPAYMENT_DATE }}</td>
              <!-- <td>{{item.INITIAL_PRINCIPAL | number:'.2-2'}}</td> -->
              <td>{{ item.PRINCIPAL_REPAYMENT | number: ".2-2" }}</td>
              <td>{{ item.INTEREST_REPAYMENT | number: ".2-2" }}</td>
              <!-- <td>{{item.FEES + item.VAT_ON_FEES | number:'.2-2'}}</td> -->
              <td>{{ item.TERM_REPAYMENT | number: ".2-2" }}</td>
              <td>
                {{
                  item.OUTSTANDING_PRINCIPAL < 1
                    ? 0
                    : (item.OUTSTANDING_PRINCIPAL | number: ".2-2")
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <!-- <td></td> -->
              <td>
                {{ getTotal("PRINCIPAL_REPAYMENT", schedule) | number: ".2-2" }}
              </td>
              <td>
                {{ getTotal("INTEREST_REPAYMENT", schedule) | number: ".2-2" }}
              </td>
              <!-- <td>{{getTotal('FEES', schedule) | number:'.2-2'}}</td> -->
              <td>{{ getTotal("TERM_REPAYMENT", schedule) | number: ".2-2" }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="text-right" *ngIf="!loan?.is_general_market">
        <app-button class="small outline" label="Full view" (click)="showFullView()"></app-button>
      </div>
    </div>
  </section>

  <!-- <app-loanrepayment [currentUser]="currentUser" [parentRouteId]="loan?.request_id" [where]='2'></app-loanrepayment> -->
</div>