import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, HostListener, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AnalysisService } from '../../setting-module/analysis.service';
import { OperationsService, DataService, StorageService } from 'src/app/_services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-indebtedness',
  templateUrl: './indebtedness.component.html',
  styleUrls: ['./indebtedness.component.css']
})
export class IndebtednessComponent implements OnInit {

  vendor_id: any;
  token: string;
  request_id: any;
  where_from: any;
  loan_product_id: any;
  letter_address: any;
  body: any;
  logo_offer_letter: any;
  letter_repayment: any;
  offer_salutation: any;
  letter_signature: any; 
  term: any;
  signatureimage1: any;
  signatureimage2: any;
  subject: any;
  offerLetterObject: any;
  chosenTags: any;
  data: any;
  @Input('parentRouteId') parentRouteId: any;
  request_id_: any;
  loading: boolean;
  options: any;
  signatures: any;
  @Output() editsec = new EventEmitter();
  @Output() onLoad = new EventEmitter();
  currentUser: any;
  offerLetterTermHTML: any;
  introductionHTML: any;


  constructor(public storageService: StorageService, public toastr: ToastrService, public services: AnalysisService, public operationsService: OperationsService, public dataService: DataService, private sanitize: DomSanitizer) {
    this.dataService.reloadOfferLetter.subscribe(res => {
      this.getSettings();
    });
    this.currentUser = this.storageService.read<any>('currentUser');
  } 
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];

      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);
      this.getSettings();
      if (propName === 'parentRouteId') {
        this.getSettings();
      }
    }
  }
  getSettings() {
    this.loading = true;
    this.operationsService.getIndebtednessTemplate(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.data = data;
        this.options = JSON.parse(data.settings.offer_letter_address);
        this.signatures = JSON.parse(data.settings.offer_letter_signature);
        //const sign = JSON.parse(data.settings.signature_left);
        //this.signatureimage1 = sign.res1 ? sign.res1 : '';
        this.loading = false; 
        this.introductionHTML = this.sanitize.bypassSecurityTrustHtml(data.settings.offer_letter_body);
        this.onLoad.emit(data);
        this.dataService.onLoadPreviousLoad.emit(data);
      });
  }
  ngOnInit() {

    const vendorIdData = localStorage.getItem('platform');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;
    this.token = this.currentUser.token;
    this.getSettings();
 
  }
  editSection(section) {
    this.editsec.emit({ section: section })
  }
}
