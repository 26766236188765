// import { NewaccountComponent } from './newaccount/newaccount.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
// import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './_guards/auth.guard';
import { LoggedinGuard } from './_guards/loggedin.guard';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { CreditComponent } from './credit/credit.component';
import { CreditdashboardComponent } from './creditdashboard/creditdashboard.component';
import { LoansComponent } from './loans/loans.component';
import { CustomerCreateComponent } from './customers/customer-create/customer-create.component';
import { LoanscheduleComponent } from './loanschedule/loanschedule.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountreportsComponent } from './accountreports/accountreports.component';
import { AccoutreportsComponent } from './accoutreports/accoutreports.component';

import { SetupComponent } from './createprofile/setup/setup.component';
import { BookloanComponent } from './createprofile/bookloan/bookloan.component';
import { GoliveComponent } from './createprofile/golive/golive.component';
import { TermsComponent } from './createprofile/terms/terms.component';
import { BackendsimulateComponent } from './createprofile/simulate/backendsimulate/backendsimulate.component';
import { AddmemberComponent } from './createprofile/addmember/addmember.component';
import { WebsimulateComponent } from './createprofile/simulate/websimulate/websimulate.component';
import { UssdsimulateComponent } from './createprofile/simulate/ussdsimulate/ussdsimulate.component';
import { SetupuserComponent } from './createprofile/setupuser/setupuser.component';
import { ActivateComponent } from './createprofile/activate/activate.component';
import { MobilesimulateComponent } from './createprofile/simulate/mobilesimulate/mobilesimulate.component';
import { WelcomeComponent } from './createprofile/welcome/welcome.component';
import { SetupdashboardComponent } from './createprofile/setupdashboard/setupdashboard.component';
import { ApprovalsService, DataService, AuthenticationService, UserService, StorageService } from './_services/index';
import {
  CustomerService,
  InvestmentService,
  LoansService,
  OptionsserviceService,
  OperationsService,
  KycService,
  MarketService,
  AnalyticsService
} from './_services/index';
import { CustomersService } from './_services/customers.service';
import { HttpHelperService } from './_services/http-helper.service';
import { GmapsserviceService } from './_services/gmapsservice.service';
import { ChatService } from './_services/chat.service';
import { LoggingService } from './_services/logging.service';
import { HelperService } from './_services/helper.service';
import { ConstantsService } from './_services/constants.service';
import { NotificationService } from './_services/notification.service';
import { InvitesService } from './_services/invites.service';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { DuerepaymentComponent } from './duerepayment/duerepayment.component';
import { PendingDisbursementComponent } from './pending-disbursement/pending-disbursement.component';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { CreditsummaryComponent } from './creditsummary/creditsummary.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DisbursenavComponent } from './disbursenav/disbursenav.component';
import { LoadingComponent } from './loading/loading.component';
import { ImageComponent } from '../app/image/image.component';
import { PageNotFoundComponentComponent } from '../app/page-not-found-component/page-not-found-component.component';
import { RepaymentComponent } from '../app/repayment/repayment.component';
import { RepaymentsComponent } from './repayments/repayments.component';
import { MyapprovalsComponent } from './myapprovals/myapprovals.component';
import { StatementprofileComponent } from './statementprofile/statementprofile.component';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ForgotComponent } from './forgot/forgot.component';
import { Ng2TrackScrollModule } from 'ng2-track-scroll';
import { LoandistributionComponent } from './loandistribution/loandistribution.component';
import { NavbarComponent } from './createprofile/navbar/navbar.component';
import { HeaderComponent } from './createprofile/header/header.component';
import { SidebarComponent } from './createprofile/simulate/sidebar/sidebar.component';
import { WebsiteComponent } from './createprofile/simulate/website/website.component';
import { SetupwidgetComponent } from './createprofile/setupwidget/setupwidget.component';
import { SetupussdComponent } from './createprofile/setupussd/setupussd.component';
import { ProfileanalysisComponent } from './analytics/profile/profileanalysis/profileanalysis.component';
import { SetupeligibilityComponent } from './createprofile/setupeligibility/setupeligibility.component';
import { SetupbankstatementComponent } from './createprofile/setupbankstatement/setupbankstatement.component';
import { SetupaddressComponent } from './createprofile/setupaddress/setupaddress.component';
import { SetupsalesComponent } from './createprofile/setupsales/setupsales.component';
import { SetupintegrationsComponent } from './createprofile/setupintegrations/setupintegrations.component';
import { HomeApprovalComponent } from './home-approval/home-approval.component';
import { HomeComponent } from './home/home.component';
import { HomeRequestComponent } from './home-request/home-request.component'; 
import { NotifyComponent } from './notify/notify.component';
import { OpenapprovalComponent } from './openapproval/openapproval.component';
import { CreditclanproductsComponent } from './creditclanproducts/creditclanproducts.component';
import { FeaturesComponent } from './features/features.component';
import { MobileRestrictionComponent } from './mobile-restriction/mobile-restriction.component';
import { OptionsComponent } from './options/options.component';
import { OpenrequestComponent } from './openrequest/openrequest.component';
import { RequeststatusPipe } from './_pipes/requeststatus.pipe';
import { LazyloadDirective } from './_directives/lazyload.directive';
import { TaskComponent } from './task/task.component';
import { CreatedTaskComponent } from './created-task/created-task.component';
import { BackDirective } from './_directives/back.directive';
import { AuthorizeComponent } from './authorize/authorize.component';
import { YeargeneratorPipe } from './_pipes/yeargenerator.pipe';
import { HomesummaryComponent } from './homesummary/homesummary.component';
import { HomeportfolioComponent } from './homeportfolio/homeportfolio.component'; 
import { CustomeritemComponent } from './customeritem/customeritem.component';
import { SharedcustomerinfoonlyComponent } from './sharedcustomerinfoonly/sharedcustomerinfoonly.component'; 
import { PrincipaloutstandingComponent } from './principaloutstanding/principaloutstanding.component';
import { LoanreportComponent } from './loanreport/loanreport.component';
import { LoanarrearsageingComponent } from './loanarrearsageing/loanarrearsageing.component';
import { ClosedpartnerlloansComponent } from './closedpartnerlloans/closedpartnerlloans.component';
import { TwofaComponent } from './twofa/twofa.component';
import { NibbsmandateComponent } from './nibbsmandate/nibbsmandate.component';
import { NibbsmandatedebitComponent } from './nibbsmandatedebit/nibbsmandatedebit.component';
import { WemainflowsComponent } from './wemainflows/wemainflows.component';
import { CheckmandatebalanceComponent } from './checkmandatebalance/checkmandatebalance.component';
import { InstantmandatedebitlogComponent } from './instantmandatedebitlog/instantmandatedebitlog.component';

@NgModule({
  declarations: [
    CarouselComponent, CreditsummaryComponent, DisbursenavComponent, RepaymentsComponent,
    AppComponent, ImageComponent, LoadingComponent, PageNotFoundComponent,
    LoginComponent, PageNotFoundComponentComponent,
    SignupComponent,
    CreditComponent,
    CreditdashboardComponent,TaskComponent,CreatedTaskComponent,
    LoansComponent,
    RepaymentComponent,
    LoanscheduleComponent,
    PageNotFoundComponent,
    AccountreportsComponent,
    AccoutreportsComponent,
    DuerepaymentComponent,
    LoansComponent,
    PendingDisbursementComponent,
    StatementprofileComponent,
    MyapprovalsComponent,
    ForgotComponent,
    LoandistributionComponent,
    WelcomeComponent,
    SetupComponent,
    BookloanComponent,
    GoliveComponent,
    TermsComponent,
    BackendsimulateComponent,
    AddmemberComponent,
    WebsimulateComponent,
    UssdsimulateComponent,
    SetupComponent,
    ActivateComponent,
    MobilesimulateComponent,
    SetupdashboardComponent,
    SetupuserComponent,
    NavbarComponent,
    HeaderComponent,
    SidebarComponent,
    WebsiteComponent,
    SetupwidgetComponent,
    SetupussdComponent,
    ProfileanalysisComponent,
    SetupeligibilityComponent,
    SetupbankstatementComponent,
    SetupaddressComponent,
    SetupsalesComponent,
    SetupintegrationsComponent,
    HomeComponent,
    HomeRequestComponent,
    HomeApprovalComponent,
    // RoundPipe,
    // AddGuarantorComponent,
    // GuarantorcontactsComponent,
    NotifyComponent,
    OpenapprovalComponent,
    CreditclanproductsComponent,
    FeaturesComponent,
    MobileRestrictionComponent,
    OptionsComponent,
    OpenrequestComponent,
    LazyloadDirective,
    BackDirective,
    AuthorizeComponent,
    HomesummaryComponent,
    HomeportfolioComponent,
    CustomeritemComponent,
    SharedcustomerinfoonlyComponent,
    PrincipaloutstandingComponent,
    LoanreportComponent,
    LoanarrearsageingComponent,
    ClosedpartnerlloansComponent,
    TwofaComponent,
    NibbsmandateComponent,
    NibbsmandatedebitComponent,
    WemainflowsComponent,
    CheckmandatebalanceComponent,
    InstantmandatedebitlogComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    HttpModule,
    ChartsModule,
    IonRangeSliderModule,
    SlimLoadingBarModule.forRoot(),
    Ng2TrackScrollModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    LoggedinGuard,
    AuthenticationService,
    GmapsserviceService,
    DataService,
    UserService,
    StorageService,
    CustomerService,
    InvestmentService,
    LoansService,
    OptionsserviceService,
    OperationsService,
    HttpHelperService,
    HelperService,
    ConstantsService,
    CustomersService,
    ChatService,
    LoggingService,
    KycService,
    MarketService,
    ApprovalsService,
    AnalyticsService,
    NotificationService,
    InvitesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
