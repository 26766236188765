<div class="row">
  <div class="col-md-12"> 

    <div *ngIf="view=='master'">
      <div class="panel loading" *ngIf="!loaded && loading">
        <div style="height: 100px;"></div>
      </div>
      
      <div>
        <app-indebtedness (onLoad)="handleOfferLetter($event)" (editsec)="editsec($event)"
          [parentRouteId]="parentRouteId"></app-indebtedness>
        <div class="panel box edged_box mt-2 mb-2 border-green" *ngIf="offerLetter">
          <a class="alert padding-regular bg-off-white">
            <div class="texts no-padding">
              <h4 class="title color-green">Preview the letter</h4>
              <p class="subtitle">View a copy of this contract in a new tab</p>
            </div>
            <!-- <div class="action" *ngIf="!loan?.show_old">
              <a class="btn btn-outline-success btn-sm">Download PDF</a>
            </div> -->
            <!-- <div class="action" *ngIf="!loan?.show_old">
              <a class="btn btn-outline-success btn-sm" [href]="offerLetter.url" target="_blank">Preview</a>
            </div>
            <div class="action" *ngIf="loan?.show_old">
              <a class="btn btn-outline-success btn-sm" [href]="loan?.offer_link" target="_blank">Preview</a>
            </div> -->
            <div class="action">
              <button class="btn btn-outline-success btn-sm" (click)="sendContractDocs()">Send</button>
            </div>
          </a>
        </div>

      </div>

    </div> 
    <div *ngIf="view=='slave'">
      <app-indebtednessletter (goback)="gotomaster($event)" [request_id]="parentRouteId" [updateSection]="updateSection"
        [where_from]="where_from">
      </app-indebtednessletter>
    </div>
  </div>

</div>