<section *ngIf="type=='fraud'"> 
  <div *ngIf="!loading">
    <div>
      <div class="panel box edged_box mb_10">
        <div class="panel-body padding-regular">
          <div class="flex">
             
            <div class="flex-3 mb-2 ml-4">
              <h3 class="section-title flex align-center justify-between mb-4">
               Fraud Test 
              </h3>
              <ul class="decList bigger">

                 
                <li *ngFor="let d of data">
                  <span>{{d.title}}</span>
                  <span *ngIf="!loading">{{d.value==true?'Yes':'No'}}</span>
                  <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                </li>
                 
              </ul>

            </div>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</section>
<section *ngIf="type=='datacheck'"> 
  <div>
    <div>
    
      <div class="panel box edged_box mb_10">
        <div class="panel-heading">
          <button (click)="getDataCheck()" class="pull-right btn btn-small">Refresh</button>
        </div>
        <div class="panel-body padding-regular">
          <div class="flex">
             
            <div class="flex-3 mb-2 ml-4">
              <h3 class="section-title flex align-center justify-between mb-4">
               Data Check Test 
              </h3>
              <ul class="decList bigger">

                 
                <li *ngFor="let d of data">
                  <span *ngIf="data">{{d}}</span>
                  <span *ngIf="data">Update Required</span>
                  <!-- <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span> -->
                </li>
                 
              </ul>

            </div>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</section>