<div class="bvn__modal-container" *ngIf="grantRight('80')">
  <div class="title">BVN Details

    <div *ngIf="account_state=='start'" class="fab bottom floating" (click)="account_state='send_request'">
      <i class="fa fa-plus"></i>
    </div>
    <div *ngIf="account_state=='send_request'" class="fab bottom floating" (click)="account_state='start'">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div class="bvn__modal-flex" *ngIf="account_state=='send_request'">
    <section class="panel pinned padding-regular">

      <div class="form-group">
        <label class="control-label">BVN</label>
        <input type="number" class="form-control" name="bvn" [(ngModel)]="BVN">
      </div>
      <div>
        <app-button class="small default mr-2 mb-2" label="Validate BVN" (click)="validateBVN(bvn_details)"
        [disabled]="BVN==''|| loading" [loading]="loading"></app-button>

        <app-button class="small default mr-2 mb-2" label="Save BVN" (click)="saveBVN(bvn_details)"
        [disabled]="BVN==''|| loading" [loading]="loading"></app-button>
      </div>
      <!-- <app-button class="small default mr-2" label="Validate BVN" (click)="validateBVN(bvn_details)"
        [disabled]="BVN==''|| loading" [loading]="loading"></app-button> -->
      <app-linkgenerator [link_type]='1' [request_id]='request_id' (linkGenerated)="linkGenerated($event)">
      </app-linkgenerator>
      <div class="form-group mt-2">
        <p>{{link}}</p>
      </div>

    </section>
  </div>
  <div class="bvn__modal-flex" *ngIf="account_state=='start'">
    <!--<div>-->
    <!--<img src="assets/images/fingerprint.png" alt="finger print" class="bvn__modal-img">-->
    <!--</div>-->
    <div class="bvn__modal-table mt-4">
      <table class="table">
        <tbody>
          <tr>
            <td class="bvn__modal-text">BVN</td>
            <td><span *ngIf="!loading">{{bvn_details?.bvn}}</span><span *ngIf="loading" class="skeleton">Sample
                Test</span></td>
          </tr>
          <tr>
            <td class="bvn__modal-text">Name</td>
            <td><span *ngIf="!loading">{{bvn_details?.name}}</span><span *ngIf="loading" class="skeleton">Sample
                Test</span></td>
          </tr>
          <tr>
            <td class="bvn__modal-text">Phone Number</td>
            <td><span *ngIf="!loading">{{bvn_details?.phone}}</span><span *ngIf="loading" class="skeleton">Sample
                Test</span></td>
          </tr>
          <tr>
            <td class="bvn__modal-text">Date of Birth</td>
            <td><span *ngIf="!loading">{{bvn_details?.date_of_birth}}</span><span *ngIf="loading"
                class="skeleton">Sample Test</span></td>
          </tr>
          <tr>
            <td class="bvn__modal-text">BVN Validation Date</td>
            <td><span *ngIf="!loading">{{bvn_details?.bvn_validation_date | date}}</span><span *ngIf="loading"
                class="skeleton">Sample Test</span></td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-2" *ngIf="!viewOnly">
        <div class="col-md-6">
          <app-button [disabled]="loading" (click)="clearBVNRecord(bvn_details)" buttonclass="medium block mb-1"
            [loading]="loading" label="Clear"></app-button>
        </div>
        <div class="col-md-6">
          <app-button [disabled]="loading" (click)="retryBVNValidation(bvn_details)" buttonclass="medium block outline"
            [loading]="loading" label="Retry"></app-button>
        </div>
      </div>
      <!--<div class="btn__group">-->
      <!--<button class="btn bvn__modal-btn">Edit</button>-->
      <!--<button class="btn bvn__modal-btn" style="margin-right: 0;">View</button>-->
      <!--</div>-->
    </div>
  </div>
</div>
<div *ngIf="!grantRight('80')"></div>