<!-- <app-calendarview [calendarOptions]="calendarOptions"></app-calendarview> -->
<div *ngIf="loading">
    <div class="panel mb-1 no-padding">
      <div class="alert padding-regular bg-off-white">
        <div class="texts">
          <div class="title skeleton mb-1">This is a sample text</div>
          <p class="subtitle skeleton">This is a sample subtext</p>
        </div>
        <div class="action flex flex-column align-end">
          <p class="default-text-size mb-1 skeleton">Sample date</p>
          <p class="default-text-size no-margin skeleton">Sample loan count</p>
        </div>
      </div>
    </div>
  </div>
<div *ngIf="!loading">
    <div class="panel padding-regular" *ngIf="!approval_history_count">
        <section class="fill-h fill-v">
          <div class="h-group center mb-4 padded empty-state">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title">No approval history</h2>
            <p class="subtitle">You do not have any approval history</p>
            <div class="text-center mt-2">
            </div>
          </div>
        </section>
      </div>
      <div *ngIf="approval_history_count">
      
        <div class="panel">
          <div class="search-box">
            <input type="text" name="searchText" class="form-control custom" placeholder="Search..">
          </div>
        </div>
      
        <div class="panel pt_10">
          <h3 class="section-title padding-regular">History of approvals</h3>
          <div class="list-item" *ngFor="let approval of approval_history">
            <div class="image">
              <img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{approval?.file_name | imagenotfound}}">
            </div>
            <div class="body">
              <h3 class="title ellipsize">{{approval?.REQUEST_PRINCIPAL | money}} |
                {{approval?.request_tenor}}{{approval?.loan_duration}}</h3>
              <h5 class="subtitle ellipsize">{{approval?.legal_name}}</h5>
              <p>{{approval?.level_title}}</p>
              <p>{{approval?.time_approved}}</p>
            </div>
            <div class="align-end flex flex-column icons justify-between">
              <span>{{approval?.level_title}}</span>
              <div class="remita">
                <span>{{approval?.timeused}} days</span>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
  