<div class="page_loading">
  <div class="panel mb-1 no-padding">
    <div class="alert padding-regular bg-off-white">
      <div class="texts">
        <div class="title skeleton mb-1">This is a sample text</div>
        <p class="subtitle skeleton">This is a sample subtext</p>
      </div>
      <div class="action flex flex-column align-end">
        <p class="default-text-size mb-1 skeleton">Sample date</p>
        <p class="default-text-size no-margin skeleton">Sample loan count</p>
      </div>
    </div>
    <div class="alert padding-regular bg-off-white">
      <div class="texts">
        <div class="title skeleton mb-1">This is a sample text</div>
        <p class="subtitle skeleton">This is a sample subtext</p>
      </div>
      <div class="action flex flex-column align-end">
        <p class="default-text-size mb-1 skeleton">Sample date</p>
        <p class="default-text-size no-margin skeleton">Sample loan count</p>
      </div>
    </div>
    <div class="alert padding-regular bg-off-white">
      <div class="texts">
        <div class="title skeleton mb-1">This is a sample text</div>
        <p class="subtitle skeleton">This is a sample subtext</p>
      </div>
      <div class="action flex flex-column align-end">
        <p class="default-text-size mb-1 skeleton">Sample date</p>
        <p class="default-text-size no-margin skeleton">Sample loan count</p>
      </div>
    </div>
  </div>
</div>
