<div class="padding-regular animated fadeIn" *ngIf="grantRight('28') && self">
  <div class="title mb-4" *ngIf="where=='4'">Send request for tokennization
  </div> 
  <div *ngIf="where =='4'">
    <div class="row">
      <div class="form-group col-md-12">
        <label class="control-label" for="type1">Account</label>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Select account  <input type="radio" value="1" class="pull-right" name="account" [(ngModel)]="statement_request.select_account"></label>
            
          </div> 
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Request new account <input type="radio" value="2" class="pull-right" name="account" [(ngModel)]="statement_request.select_account"></label>
          </div> 
        </div>
      </div>
    </div>
    <section class="panel padding-regular"> 
      <div class="row" *ngIf="statement_request.select_account=='1'">
        <div class="form-group col-md-12">
          <label class="control-label" for="type1">Bank Account</label>
          <select class="form-control custom small" name="bank" [(ngModel)]="statement_request.bank" id="">
            <option [value]="0"></option>
            <option [ngValue]="account" *ngFor="let account of accounts">
              {{account?.account_number}}/{{account?.account_name}}/{{account?.bank_name}}</option>
          </select>
        </div> 
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label" for="type1">Channels</label>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">Flutterwave <input type="checkbox" class="pull-right" name="flutterwave" [(ngModel)]="statement_request.flutterwave"></label>
              
            </div> 
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">Paystack <input type="checkbox" class="pull-right" name="paystack" [(ngModel)]="statement_request.paystack"></label>
            </div> 
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">Direct Debit Mandate <input type="checkbox" class="pull-right" name="direct_debit_mandate" [(ngModel)]="statement_request.direct_debit_mandate"></label>
            </div> 
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label" for="type1">Agent <input type="checkbox" class="pull-right" name="agent" [(ngModel)]="statement_request.agent"></label>
            </div> 
          </div>
        </div>
      </div>

      <app-button class="small default mr-2" label="Send Request" (click)="sendRequest()"
        [disabled]="statement_request.bank=='0'|| loading" [loading]="loading">
      </app-button>

      <app-button class="small default mr-2" label="Generate Link" (click)="generateLink()"
      [disabled]="statement_request.bank=='0'|| loading" [loading]="loading">
    </app-button>
    </section>
    <div>
      <p>{{link}}</p>
    </div>
  </div>

</div> 
<div class="profile_area animated fadeIn" *ngIf="!grantRight('28') && self">
  <div>You don't have the right to perform operation on this page</div>
</div>