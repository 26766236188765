import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analysis-area-of-focus',
  templateUrl: './analysis-area-of-focus.component.html',
  styleUrls: ['./analysis-area-of-focus.component.css']
})
export class AnalysisAreaOfFocusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
