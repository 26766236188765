<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{ pr_0 : embedded }">
    <app-contractheader text="Guarantors Cover" *ngIf="loan?.loan_status < 3 && !embedded" [request_id]="parentRouteId">
    </app-contractheader>
    <app-applicationportfolioheader *ngIf="loan?.loan_status >= 3 && !embedded" [loan]="loan"
      [request_id]="parentRouteId">
    </app-applicationportfolioheader>
    <div *ngIf="view == 'master'">
      <section class="panel mb-1" *ngIf="guarantor_count != 0">
        <h4 class="section-title mb-4 flex align-center justify-between" style="padding: 2rem 2rem 0">
          Guarantors that have been Added
          <button (click)="addGuarantors()" class="btn btn-outline-secondary btn-sm"><i class="fa fa-plus"></i></button>
        </h4>

        <div class="pd_20 padding-regular-x flex justify-between align-center border-bottom"
          *ngFor="let guarantor of guarantors">
          <div class="user">
            <div class="image">
              <img *ngIf="guarantor.FILE_NAME == null" src="assets/images/user.png" alt="User Icon">
              <img
                [src]="'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/' +guarantor.FILE_NAME"
                *ngIf="guarantor.FILE_NAME != null" alt="User Icon">
            </div>
            <div class="content">
              <h4 class="title no-margin">{{guarantor.LEGAL_NAME}}</h4>
              <p class="subtitle">{{guarantor.GUARANTOR_PEOPLE_EMAIL}}</p>
              <p class="subtitle">{{guarantor.PHONE}}</p>
              <p class="subtitle">Date Added : {{guarantor.DATE_ADDED | date: 'dd/MM/yyyy'}}</p>
            </div>
          </div>
          <app-button (click)="viewGuarantor(guarantor)" buttonclass="small outline" label="View"></app-button>
        </div>
      </section>
      <div class="panel box edged_box mb_10" *ngIf="guarantor_count == 0">
        <app-help-popover [content]="[
            {
              title: 'Guarantor check',
              img: 'assets/images/protection.svg',
              text:
                '
            <div>
              <p> This section is about customers loan guarantors.
              </p>
            </div>'
            }
          ]"></app-help-popover>
        <div class="panel-body">
          <section class="fill-h fill-v">
            <div class="h-group center mb-4 padded empty-state">
              <div class="icon"><i class="fa fa-meh-o"></i></div>
              <h2 class="title">No guarantors</h2>
              <p class="subtitle">No guarantors has been requested</p>
              <div class="text-center mt-2">
                <app-button [disabled]="!grantRight('79')" label="Request for Guarantor" buttonclass="small outline"
                  (click)="requestGuarantors()"></app-button>
              </div>
              <div class="text-center mt-2">
                <app-button [disabled]="!grantRight('79')" label="Add Guarantor" buttonclass="small outline"
                  (click)="addGuarantors()"></app-button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- <div class="panel box edged_box mb-1"  *ngIf="guarantor_count != 0">
        <div class="alert padding-regular bg-off-white">
          <div class="texts no-padding">
            <h4 class="title">Run Guarantor Analytics</h4>
            <p class="subtitle">We know something about John Doe</p>
          </div>
          <div class="action">
            <app-button label="Run" buttonclass="small outline"></app-button>
          </div>
        </div>
      </div> -->

      <div class="panel padding-regular">
        <app-help-popover [content]="[
              {
                title: 'key phone numbers',
                img: 'assets/images/phone.svg',
                text:
                  '
              <div>
                <p>Important or Key mobile numbers.
                </p>
              </div>'
              }
            ]"></app-help-popover>
        <div class="flex">
          <div class="flex-2">
            <div class="section-title mb-4">Key mobile numbers <small>We have a few numbers</small></div>
            <ul class="decList bigger" *ngFor="let contact of hashed_contacts">
              <li><span></span><span> {{contact}}</span></li>
            </ul>
            <app-button class="small outline mt-2" (click)="showGuarantorContacts()" label="View more"></app-button>
          </div>
          <div class="flex-1 flex justify-end">
            <div class="section-poster">
              <div class="image flex justify-end">
                <img src="assets/images/phone-number.svg" alt="Key numbers" class="w-60">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="view == 'detail'">
      <div *ngIf="!loading">
        <section class="panel mb-1">
          <h4 class="section-title mb-4 flex align-center justify-between" style="padding: 2rem 2rem 0">
            Back
            <button (click)="view='master'" class="btn btn-outline-secondary btn-sm"><i
                class="fa fa-arrow-left"></i></button>
          </h4>
        </section>
        <div class="panel box edged_box mb-1">
          <div class="panel-body padding-regular">
            <div class="flex">
              <div class="flex-3 mb-2 mr-4" *ngIf="editing !== 'bio'">
                <ul class="decList bigger">
                  <li><span class="title">Bio-Information</span>
                  </li>
                  <li><span>Name</span>
                    <span>{{profile?.LEGAL_NAME || 'Not provided'}}</span>
                  </li>
                  <li><span>Email</span>
                    <span>{{profile?.EMAIL || 'Not provided'}}</span>
                  </li>
                  <li><span>Phone</span>
                    <span>{{phone?.PHONE || 'Not provided'}}</span>
                  </li>
                  <li><span>DOB</span>
                    <span>{{profile?.DATE_OF_BIRTH || 'Not provided'}}</span>
                  </li>
                  <li><span>Gender</span>
                    <span>{{profile?.gender }}</span>
                  </li>
                  <li><span>Marital Status</span><span>{{profile?.marital_status|maritalstatus}}</span>
                  </li>
                </ul>
                <div class="mb-2">
                  <ul class="decList bigger">
                    <li><span class="title">Home Address</span></li>
                    <li><span>Address</span><span
                        class="pull-right width_1_2 text-right">{{home_address?.ADDRESS || 'Not provided'}}</span>
                    </li>
                    <li><span>State</span><span>{{home_address?.state_name || 'Not provided'}} </span></li>
                    <li><span>Nature of
                        Accomodation</span><span>{{home_address?.NATURE_OF_ACCOMODATION|noa}}</span>
                    </li>
                    <li><span>Years of residence</span><span>{{home_address?.TIME_AT_CURRENT_ADDRESS|noa}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="section-poster">
                <div class="image flex-center">
                  <img src="assets/images/personal-information.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel box edged_box mb-1">
          <div class="panel-body padding-regular">
            <div class="flex">
              <div class="section-poster">
                <div class="image flex-center">
                  <img src="assets/images/wallet.svg">
                </div>
              </div>
              <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'income'">
                <ul class="decList bigger">
                  <li><span class="title">Income Details</span>
                  </li>
                  <li><span>Occupation</span><span>{{work?.occupation || 'Not provided'}}</span>
                  </li>
                  <li><span>Work
                      Sector</span><span>{{work?.sector || 'Not provided'}}</span>
                  </li>
                  <li><span>Designation</span><span>{{work?.designation || 'Not provided'}}</span>
                  </li>
                  <li><span>Monthly
                      Income</span><span>{{work?.NET_MONTHLY_INCOME || 'Not provided'}}</span>
                  </li>
                  <li><span>Gross Annual
                      Income</span><span>{{work?.GROSS_ANNUAL_INCOME || 'Not provided'}}</span>
                  </li>
                </ul>
                <div class="mb_10">
                  <ul class="decList bigger">
                    <li><span class="title">Work Details</span></li>
                    <li><span>Company
                        Name</span><span>{{work?.COMPANY_OF_WORK || 'Not provided'}}</span>
                    </li>
                    <li><span>Work Start
                        Date</span><span>{{work?.START_MONTH || 'Not provided'}}
                        /{{work?.START_YEAR}}</span></li>
                    <li><span>Work
                        Address</span><span>{{work?.WORK_ADDRESS || 'Not provided'}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="panel  box edged_box mb-1">

          <div class="flex mt-5">
            <div class="cards fill-w">
              <div class="card has-overlay light" *ngIf="has_bank">
                <div class="top">
                  <div class="name">{{bank?.bank_name}}</div>
                </div>
                <div class="middle">
                  <div class="number">{{bank?.LAST_FOUR_DIGITS}}</div>
                </div>
              </div>
              <div class="flex-1 flex-center" *ngIf="!has_bank">
                <div class="icon-text medium cursor-pointer">
                  <div class="icon color-blue"><i class="fas fa-bank"></i></div>
                  <div class="text w-60">No bank</div>
                </div>
              </div>
              <div class="card has-overlay" *ngIf="has_card">
                <div class="top">
                  <div class="name">{{card?.PAYSTACK_CHECK_ID?.authorization?.bank_name}}</div>
                </div>
                <div class="middle">
                  <div class="number">{{card?.PAYSTACK_CHECK_ID?.authorization?.bin}}**
                    {{card?.PAYSTACK_CHECK_ID?.authorization?.last4}}</div>
                </div>
                <div class="bottom">
                  <div class="items flex justify-between">
                    <div class="item">
                      <span class="label">Status</span>
                      <span class="value">Active</span>
                    </div>
                    <div class="item">
                      <span class="label">Expiry</span>
                      <span
                        class="value">{{card?.PAYSTACK_CHECK_ID?.authorization?.exp_month}}/{{card?.PAYSTACK_CHECK_ID?.authorization?.exp_year}}</span>
                    </div>
                    <div class="item">
                      <img src="assets/images/mastercard.png" alt="card type" style="width: 40px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-1 flex-center" *ngIf="!has_card">
                <div class="icon-text medium cursor-pointer">
                  <div class="icon color-yellow"><i class="fas fa-credit-card"></i></div>
                  <div class="text w-60">No card</div>
                </div>
              </div>
            </div>


          </div>
        </section>
      </div>
    </div>

  </div>
</div>