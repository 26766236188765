<!--<div style="vertical-align:center;" id="phone">-->
  <!--<div id="screen">-->
    <!--<div *ngIf="availability == '1'" style="margin-top:10px;">-->
        <!--<div style="text-align:center;margin-top:50px;font-size:15px;">{{response}}</div>-->
    <!--</div>-->
    <!--<div style="vertical-align: center">-->
      <!--<form #ussdform="ngForm" novalidate style="margin-top: 60px;" class="form-inline">-->
          <!--<div class="form-group" style="text-align:center" [ngClass]="availability == '1'? 'hidden':'show'">-->
              <!--<label style="display:block; font-weight: 900;">Your phone Number:</label>-->
              <!--<input type="text" class="form-control" name="phoneNumber" [(ngModel)]="ussdRequest.phoneNumber" maxlength="11" minlength="11">-->
          <!--</div><br><br>-->
          <!--<div class="form-group" style="text-align:center; display:block;" [ngClass]="availability == '1'? 'hidden':'show'">-->
              <!--<label for="USSD" style="display:block; font-weight: 900;">Enter USSD Code:</label>-->
              <!--<input type="text" class="form-control" name="serviceCode" [(ngModel)]="ussdRequest.serviceCode" placeholder="*4*0#">-->
          <!--</div><br>-->
          <!--<input type="hidden" name="sessionId"><br>-->
          <!--<div class="form-group" style="text-align:center;display:block;" [ngClass]="availability == '1'? 'show':'hidden'">-->
            <!--<input type="text" class="form-control" onfocus="this.value=''" [(ngModel)]="ussdRequest.text" name="text" id="resp" placeholder="Input Response">-->
          <!--</div>-->
          <!--<br><br>-->
          <!--<div class="text-center">-->
            <!--<button type="submit" class="btn btn-primary  sub" (click)="saveUssd(ussdform.value, ussdform.valid)">Send</button>-->
          <!--</div> -->
      <!--</form>-->
    <!--</div>   -->
  <!--</div>-->

<!--</div>-->
<div class="phone-container">
  <div class="phone-screen">
    <div class="top">
      <div class="icon">
        <i class="fa fa-mobile-phone"></i>
      </div>
      <div class="ussd-code">*123*1*2*3#</div>
      <div class="info">Enter your phone number below to begin.</div>
      <div class="phone-overlay">
        <div class="phone-display">
          <!--<div class="title">Welcome to ABC Lenders</div>-->
          <ol class="options">
            <li>One</li>
            <li>Two</li>
            <li>Three</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="bottom">
      <input type="text" placeholder="Type here..">
      <button>
        <i class="fa fa-paper-plane-o"></i>
      </button>
    </div>
  </div>
</div>
