import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService,  } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OptionsserviceService } from '../../_services/optionsservice.service';

@Component({
  selector: 'app-invitingnewcustomer',
  templateUrl: './invitingnewcustomer.component.html',
  styleUrls: ['./invitingnewcustomer.component.css']
})
export class InvitingnewcustomerComponent implements OnInit {
  @Input('currentUser') currentUser:any;
  request = {
    'name':'',
    'email':'',
    'send_to_whatsapp':false,
    'whatsapp_number':'',
    'whatsapp_message':''
  }
  loading = false;
  constructor(public toastr: ToastrService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService, 
    public storageService: StorageService, public optionsService: OptionsserviceService, public dataService: DataService) {

  }

  ngOnInit() {
    this.request.name = '';
    this.request.email = '';
  }
  sendInvite(){
    this.loading = true;
    this.operationsService.sendLoanInvite(this.currentUser.token, this.request)
      .subscribe(data => {
        this.loading = false; 
        if (data.status === true) {
          this.showSuccess(data.message)
        } else {
          this.showError(data.message)
        }
        this.request.name = '';
        this.request.email = '';
      });
  }
  sendMultiple(){
    this.dataService.inviteMultipleCustomers.emit();
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
}
