<div class="padding-regular animated fadeIn" *ngIf="grantRight('28') && self">
  <div class="title mb-4" *ngIf="where=='2'">Bank Transactions SMS Analysis
  </div>
  <div class="title mb-4" *ngIf="where=='3'">Micro Credit Report
  </div>
  <div class="title mb-4" *ngIf="where=='4'">Send Bank Statement Analysis Request
  </div>
  <div *ngIf="where !='4'">

    <section class="panel padding-regular">
      <!-- <div class="flex justify-between">
        <h4 class="form-title">Add New Photo</h4>
        <button class="icon-button danger" (click)="open('photo')"><i class="fa fa-times"></i></button>
      </div> -->

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Phone</label>
          <select class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type1">
            <option [value]="0"></option>
            <option [value]="1">Profile</option>
            <option [value]="2">BVN</option>
          </select>
        </div>

      </div>

      <app-button class="small default mr-2" label="Get Transactions" (click)="uploadThePhoto()"
        [disabled]="DOCTYPE==''|| loading" [loading]="loading"></app-button>
    </section>
  </div>
  <div *ngIf="where =='4'">

    <section class="panel padding-regular">
      <!-- <div class="flex justify-between">
      <h4 class="form-title">Add New Photo</h4>
      <button class="icon-button danger" (click)="open('photo')"><i class="fa fa-times"></i></button>
    </div> -->

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Bank Account</label>
          <select class="form-control custom small" name="bank" [(ngModel)]="statement_request.bank" id="">
            <option [value]="0"></option>
            <option [ngValue]="account" *ngFor="let account of accounts">
              {{account?.account_number}}/{{account?.account_name}}/{{account?.bank_name}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Statement Source</label>
          <select class="form-control custom small" name="statement_source"
            [(ngModel)]="statement_request.statement_source" id="">
            <option [value]="0"></option>
            <option [value]="1">Customer Upload</option>
            <option [value]="3">My Bank Statement (MBS)</option>
            <option [value]="5">MBS Ticket ID</option>
            <option [value]="2">Okra</option>
            <option [value]="4">Mono</option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="transaction_id">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Ticket ID</label>
          <input class="form-control custom small" name="ticket_id" [(ngModel)]="ticket_id" id=""> 
        </div>
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Password</label>
          <input class="form-control custom small" name="password" [(ngModel)]="password" id=""> 
        </div>
      </div>

      <app-button *ngIf="!transaction_id" class="small default mr-2" label="Send Request" (click)="sendRequest(statement_request)"
        [disabled]="statement_request.bank=='0'||statement_request.statement_source=='0'|| loading" [loading]="loading">
      </app-button>
      <app-button  *ngIf="transaction_id" class="small default mr-2" label="Submit Ticket" (click)="submitTicket(statement_request)"
        [disabled]="statement_request.bank=='0'||statement_request.statement_source=='0'|| loading" [loading]="loading">
      </app-button>
    </section>
  </div>

</div>
<div class="padding-regular animated fadeIn" *ngIf="!self">
  <div *ngIf="where !='4'">

    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label" for="type1">Phone</label>
        <select class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type1">
          <option [value]="0"></option>
          <option [value]="1">Profile</option>
          <option [value]="2">BVN</option>
        </select>
      </div>

    </div>

    <app-button class="small default mr-2" label="Get Transactions" (click)="uploadThePhoto()"
      [disabled]="DOCTYPE==''|| loading" [loading]="loading"></app-button>
  </div>
  <div *ngIf="where =='4'">

    <section class="padding-regular">
      
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Bank Account</label>
          <select class="form-control custom small" name="bank" [(ngModel)]="statement_request.bank" id="">
            <option [value]="0"></option>
            <option [ngValue]="account" *ngFor="let account of accounts">
              {{account?.account_number}}/{{account?.account_name}}/{{account?.bank_name}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Statement Source</label>
          <select class="form-control custom small" name="statement_source"
            [(ngModel)]="statement_request.statement_source" id="">
            <option [value]="0"></option>
            <option [value]="1">Customer Upload</option>
            <option [value]="3">My Bank Statement (MBS)</option>
            <option [value]="2">Okra</option>
            <option [value]="4">Mono</option>
          </select>
        </div>
      </div>

      <app-button class="small default mr-2" label="Send Request" (click)="sendRequest()"
        [disabled]="statement_request.bank=='0'||statement_request.statement_source=='0'|| loading" [loading]="loading">
      </app-button>
    </section>
  </div>

</div>
<div class="profile_area animated fadeIn" *ngIf="!grantRight('28') && self">
  <div>You don't have the right to perform operation on this page</div>
</div>