<!-- <section class="panel" style="overflow:hidden;">
  <div style="padding:24px 20px; overflow: auto;">
    <div class="text-center borrower-info">
      <a class="pull-left" style="margin-right: 25px">
        <img src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.FILE_NAME | imagenotfound}}"
          style="width:50px; height:50px">
      </a>
      <div class="borrower-text pull-left" style="text-align:left">
        <p class="" style="margin-bottom: 0px">{{loan?.LEGAL_NAME}}</p>
        <p class="" style="font-weight: bold">{{loan?.REQUEST_PRINCIPAL | number:'.2-2'}}</p>
      </div>
    </div>
  </div>
  <div class="text-center">
    <p class="" style="font-weight: bold">Repayment Mode</p>
  </div>
  <div class="paytype-switch" style="width: 100%;position: relative;z-index: 10;background: white; overflow: auto">
    <a class="paystack-switch-make active text-center" style="width:100% !important">Change Repayment Mode</a>
  </div>
  <div>

    <div class="panel-body">

      <form #interestBandForm="ngForm" novalidate>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Payment Mode</label>
              <select class="form-control" name="REPAYMENT_MODE" [(ngModel)]="change_payment_form.REPAYMENT_MODE">
                <option value="0"></option>
                <option *ngFor="let mode of mode_of_repayment" [value]="mode.value">{{mode.display}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" class="form-control" name="SECURITY_QUESTION_ANSWER" [(ngModel)]="change_payment_form.SECURITY_QUESTION_ANSWER">
              <input type="hidden" name="REQUEST_ID" [(ngModel)]="change_payment_form.REQUEST_ID">
            </div>
          </div>
        </div>
        <button type="submit" (click)="changeTheDefaultPayment(interestBandForm.value, interestBandForm.valid)"
          [disabled]="loading" class="btn btn-white pull-right">
          <span *ngIf="!loading">Submit</span>
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </form>
    </div>
  </div>
</section> -->
<!-- <div class="padding-regular">
  
</div> -->
<div class="padding-regular overpanel-scroll">
  <div class="padding-regular-x mt-2" *ngIf="loan?.rmode=='Remita Inflight'">
    <div class="alert alert-info alert-sm">Remita Inflight will be cancelledn <label class="pull-right mr-1"> <span
          class="mr-1">I agree</span><input [(ngModel)]="terms" type="checkbox" class="ml-1"></label></div>
  </div>
  <div class="padding-regular-x mt-2 flex" *ngIf="loan?.rmode=='Direct Debit'">
    <div class="alert alert-info alert-sm flex-2">All previous mandate instructions will be cancelled and no more debit
      instruction would be possible this month <label class="pull-right mr-1"> <span class="mr-1 flex-1">I
          agree</span><input [(ngModel)]="terms" type="checkbox" class="ml-1"></label></div>
  </div>
  <!-- <div class="section-poster">
      <div class="image flex-center">
        <img src="assets/images/share.svg" alt="">
      </div>
    </div> -->
  <form #interestBandForm="ngForm" novalidate>
    <div class="form-group">
      <label class="control-label normal">Payment Mode</label>
      <select class="form-control" name="REPAYMENT_MODE" [(ngModel)]="change_payment_form.REPAYMENT_MODE">
        <option value="0"></option>
        <option *ngFor="let mode of mode_of_repayment" [value]="mode.value">{{mode.display}}</option>
      </select>
    </div>
    <div class="form-group">
      <label class="control-label normal">Password</label>
      <input type="password" class="form-control" name="SECURITY_QUESTION_ANSWER"
        [(ngModel)]="change_payment_form.SECURITY_QUESTION_ANSWER">
      <input type="hidden" name="REQUEST_ID" [(ngModel)]="change_payment_form.REQUEST_ID">
    </div>
    <div class="text-left mt-4">
      <app-button (click)="changeTheDefaultPayment(interestBandForm.value, interestBandForm.valid)"
        [disabled]="loading" class="medium" [loading]="loading" label="Submit">
      </app-button>
    </div>
  </form>
</div>