<app-requestheader style="margin-bottom:16px" [loan]="newloan" [where]=''></app-requestheader>
<div *ngIf="!makingFinalPayment"> 
  <div class="content-wrap"> 
    <form [formGroup]="prepaymentForm" (ngSubmit)="approvePrepayment(prepaymentForm.value)">
      <h4 class="section-title mb-3 mt-3">Approve Prepayment</h4>
 

      <!-- <div class="form-group">
        <label class="control-label normal">Contract Date</label>
        <my-date-picker [selDate]="selDate" name="DATE_PAID" [options]="myDatePickerOptions"
          formControlName="DATE_PAID"></my-date-picker>
      </div> -->
 
      <div class="form-group"
        [ngClass]="{'has-error':!prepaymentForm.controls['SECURITY_QUESTION_ANSWER'].valid && prepaymentForm.controls['SECURITY_QUESTION_ANSWER'].touched}">
        <label class="control-label normal">{{security_question}}</label>
        <input [formControl]="prepaymentForm.controls['SECURITY_QUESTION_ANSWER']" type="password" class="form-control"
          id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
        <div *ngIf="otpError" class="alert alert-danger mt-2 alert-sm">
          {{otpmessage}}
        </div>
      </div> 
    </form>
  </div>    
</div>
<div class="panel-body animated" *ngIf="makingFinalPayment">
  <div>
    <div *ngIf="paytype=='make'">
      <div class="panel-body" style="padding-bottom: 0px">
        <div class="text-center" style="padding: 20px 0px; padding-bottom: 0px"
          *ngIf="makingFinalPayment&&!paymentHasBeenProcessed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-hourglass fa-spin"></i>
          </a>
          <span style="display: block;">processing payment...</span>

        </div> 
        <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
          *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&!paymentConfirmed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-info"></i>
          </a>
          <span style="display: block;">Payment Unsuccessful</span>
          <button type="button" class="btn btn-white" (click)="paymentProcessDone()">Close</button>
        </div>
      </div>
    </div> 

  </div>
</div>