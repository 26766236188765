<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Principal Oustanding</h3>
      <h6>Outstanding principal on open loans<app-button (click)="moveAllForPartners(data)" class="small green mb-1 mr-1" label="Move"></app-button></h6>
      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <th>Loan Id</th>
              <th>Customer</th>
              <th>Product</th>
              <th>Disbursed</th>
              <th>Maturity</th>
              <th>Principal</th>
              <th>Principal Paid</th>
              <th>Principal Balance</th>
              <th>Principal Due</th>
              <th>Principal Arrears</th>
              <th>Lender</th>
              <th></th>
            </thead>
            <thead>
              <th></th>
              <th></th>
              <th>
                <select (change)="getRecords_()" name="loan_product_id" [(ngModel)]="loan_product_id">
                  <option value=""></option>
                  <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                </select>

              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let record of data">
                <td>{{record?.loan_id}}</td>
                <td>{{record?.legal_name}}</td>
                <td>{{record?.loan_title}}</td>
                <td>{{record?.disbursement_date}}</td>
                <td>{{record?.maturity_date}}</td>
                <td>{{record?.request_principal|number:'.2-2'}}</td>
                <td>{{record?.principal_paid|number:'.2-2'}}</td>
                <td>{{record?.principal_balance|number:'.2-2'}}</td>
                <td *ngIf="record.principal_due">{{record?.principal_due|number:'.2-2'}}</td>
                <td *ngIf="!record.principal_due">{{0|number:'.2-2'}}</td>
                <td *ngIf="record.principal_arrears">{{record?.principal_arrears|number:'.2-2'}}</td>
                <td *ngIf="!record.principal_arrears">{{0|number:'.2-2'}}</td>
                <td>{{record?.general_lender}}</td>
                <td><app-button (click)="moveForPartners(record)" class="small green mb-1 mr-1" label="Move"></app-button></td>
                
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ getTotal('request_principal', data) | number: '.2-2' }}
                </td>
                <td>
                  {{ getTotal('principal_paid', data) | number: '.2-2' }}
                </td>
                <td>
                  {{ getTotal('principal_balance', data) | number: '.2-2' }}
                </td>
                <td>
                  {{ getTotal('principal_due', data) | number: '.2-2' }}
                </td>
                <td>
                  {{ getTotal('principal_arrears', data) | number: '.2-2' }}
                </td>
              </tr>
            </tfoot>
            <thead>
              <tr>
                <td colspan="9"><app-button class="small outline mr-1" (click)="getRecords()" label="Next"></app-button></td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>