<div class="title text-center">
  <span>Send multiple Invites</span>
</div>

<div class="flex-center flex-column mt-5" *ngIf="success">
  <div class="h-group padded center mt-5 flex-center flex-column">
    <div class="circle large green filled flex-center mb-3 mt-5"><i class="fa fa-check"></i></div>
    <h3 class="title no-float">Invites are now being sent.</h3>
    <div class="text-center mt-4">
      <app-button class="small outline" label="Send again" (click)="success = !success"></app-button>
    </div>
  </div>
</div>

<div class="flex flex-column overpanel-scroll padding-regular" *ngIf="!success">
  <div class="row">
    <div class="col-md-7 sticky">
      <div class="col-md-5">
        <div class="section-poster">
          <img src="assets/images/share.svg" alt="User" />
        </div>
      </div>
      <div class="col-md-7">
        <form #interestBandForm="ngForm" novalidate>
          <div class="form-group">
            <label class="control-label normal">Name</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="request.name" />
          </div>
          <div class="form-group">
            <label class="control-label normal">Email</label>
            <input type="email" class="form-control" name="email" [(ngModel)]="request.email" />
          </div>
          <div class="form-group" *ngIf="result?.enable_loan_products=='1'">
            <label class="control-label normal">Product</label>
            <select class="form-control" name="product_id" [(ngModel)]="request.product_id">
              <option value=""></option>
              <option [value]="product.LOAN_PRODUCT_ID" *ngFor="let product of result?.products">
                {{product.LOAN_TITLE}}
              </option>
            </select>
            
          </div>
          <div class="mb-3">
            <app-button class="outline small" (click)="saveInterestRateBand(interestBandForm.value, interestBandForm.valid)"
              [disabled]="!request.name.length || !request.email.length" class="small" label="Add user">
            </app-button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-5" style="min-height: 300px!important;">
      <div class="h-group center flex flex-column mt-4 empty-state mt-5" *ngIf="!invites.length" style="height: 300px;">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title no-float">No user added.</h2>
      </div>
      <div class="flex justify-between align-center mb-4 border-bottom" *ngFor="let invite of invites">
        <div class="user">
          <div class="image">
            <img src="assets/images/user.png" alt="User" />
          </div>
          <div class="content">
            <div class="title">{{ invite.name }}</div>
            <div class="subtitle">{{ invite.email }}</div>
          </div>
        </div>
        <button class="icon-button red" (click)="deleteInvite(invite)"><i class="fa fa-times"></i></button>
      </div>
    </div>
    <div class="sender flex align-center justify-between bg-off-white padding-regular" style="z-index: 10">
      <span *ngIf="view=='invite'">{{invites.length}} Invitation(s) queued</span>
      <span *ngIf="view=='link'">{{generated_link}}</span>
      <app-button *ngIf="!coolSubmit" (click)="sendInvite()" [disabled]="loading || !invites.length" class="small"
        [loading]="loading" label="Send Invite">
      </app-button>
      <app-button *ngIf="!coolSubmit" (click)="generateLink(interestBandForm.value, interestBandForm.valid)" [disabled]="" class="small"
      [loading]="loading" label="Generate Link">
    </app-button>
    </div>
  </div>
</div>
<!-- 
<div class="overpanel-scroll" *ngIf="!coolSubmit">
  <section *ngIf="showWhatsapp">
    <div class="decList bigger">
      <form #interestBandForm="ngForm" novalidate class="centralize">
        <div class="form-group" *ngIf="whatsapp.send_to_whatsapp">
          <span class="control-label normal">Your Whatsapp Number</span>
          <input name="whatsapp_number" [(ngModel)]="whatsapp.whatsapp_number" type="text" class="form-control" />
        </div>
        <div class="form-group" *ngIf="whatsapp.send_to_whatsapp">
          <span class="control-label normal">Your Whatsapp Message</span>
          <textarea name="whatsapp_message" [(ngModel)]="whatsapp.whatsapp_message" type="text"
            class="form-control"></textarea>
        </div>
      </form>
    </div>
  </section>

  <div class="padding-regular">
    <form #interestBandForm="ngForm" novalidate class="flex align-end">
      <div class="form-group">
        <label class="control-label normal">Name</label>
        <input type="text" class="form-control" name="name" [(ngModel)]="request.name" />
      </div>
      <div class="form-group ml-2">
        <label class="control-label normal">Email</label>
        <input type="email" class="form-control" name="email" [(ngModel)]="request.email" />
      </div>
      <div class="mb-3 ml-2">
        <app-button (click)="saveInterestRateBand(interestBandForm.value, interestBandForm.valid)"
          [disabled]="!request.name.length || !request.email.length" class="small" label="Add user">
        </app-button>
      </div>
    </form>
  </div>
  <div class="padding-regular grid five mt-3 border_top">
    <div class="flex-center flex-column mb-3" *ngFor="let invite of invites" style="position: relative">
      <div class="image-avatar mb-2">
        <img src="assets/images/user.png" alt="" />
      </div>
      <button class="delete-invite" (click)="deleteInvite(invite)"><i class="fa fa-times"></i></button>
      <div class="h-group center">
        <div class="no-margin">{{ invite.name }}</div>
        <div class="subtitle small-text-size fill-w no-margin">
          {{ invite.email }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="padding-regular overpanel-scroll" *ngIf="coolSubmit">
  <div class="col-md-6 col-md-offset-3">
    <div class="flex justify-between mb-4">
      <div class="image-avatar medium"><img src="assets/images/user.png" alt=""></div>
      <div *ngIf="invites.length > 1" class="circle medium flex-center border grey">{{invites.length - 1}}</div>
      <div class="image-avatar medium"><img src="assets/images/whatsapp.png" alt=""></div>
    </div>
    <form #interestBandForm="ngForm" novalidate>
      <div class="form-group mt-3">
        <input type="checkbox" name="send_to_whatsapp" [(ngModel)]="request.send_to_whatsapp" />
        <span class="control-label normal">Send Through Whatsapp</span>
      </div>
      <div class="form-group" *ngIf="request.send_to_whatsapp">
        <span class="control-label normal">Your Whatsapp Number</span>
        <input name="whatsapp_number" [(ngModel)]="request.whatsapp_number" type="text" class="form-control" />
      </div>
      <div class="form-group" *ngIf="request.send_to_whatsapp">
        <span class="control-label normal">Your Whatsapp Message</span>
        <textarea name="whatsapp_message" [(ngModel)]="request.whatsapp_message" type="text"
          class="form-control"></textarea>
      </div>
      <div class="text-left mt-4">
        <app-button (click)="coolSubmit = !coolSubmit" class="small outline mr-1" label="Back">
        </app-button>
        <app-button (click)="sendInvite()" [disabled]="loading" class="small" [loading]="loading" label="Send Invite">
        </app-button>
      </div>
    </form>
  </div>
</div> -->