<div class="cont">
  <div class="sect-wrap">
    <div class="left-container">
      <div class="onb-title">
        <h3>Sign up for free.</h3>
        <p>Begin your journey with us today.</p>
      </div>
      <form #signupForm="ngForm">
        <div class="form-group">
          <input type="text" class="form-control custom" placeholder="Business Name" #LEGAL_NAME="ngModel"
            [(ngModel)]="setup.legal_name" name="legal_name" required>

        </div>
        <div class="form-group">
          <input type="email" class="form-control custom" placeholder="Business Email" #EMAIL="ngModel"
            [(ngModel)]="setup.email" name="email" required>

        </div>
        <div class="form-group">
          <input type="text" class="form-control custom" placeholder="Phone Number" #PHONE="ngModel"
            [(ngModel)]="setup.phone" name="phone" required>
        </div>
        <div class="form-group">
          <select class="form-control custom" name="setup.license" [(ngModel)]="setup.license">
            <option value="0" disabled>How do you currently operate?</option>
            <option *ngFor="let license of licenses" [value]="license.value">{{license.display}}</option>
          </select>
        </div>
        <div class="form-group input-group">
          <input type="text" class="form-control custom" placeholder="Specify Target Interest Rate" name="interest_rate"
            [(ngModel)]="setup.interest_rate">
          <div class="input-group-btn">
            <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown"
              style="padding:14px 12px">{{interest_duration}} <span class="caret"></span></button>
            <ul class="dropdown-menu pull-right">
              <li *ngFor="let d of loan_durations;let i=index;">
                <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <div class="g-recaptcha" data-sitekey="6LcxWH0UAAAAAOCtIQlvUyLS4XSp2mFCmM2a1luk"></div>
        </div>

        <div class="mt-3">
          <app-button (click)="displaySetUp()"
            [disabled]="setup.phone== '' || setup.email== '' || setup.legal_name== '' || setup.interest_rate == '' || setup.license == ''"
            type="submit" label="Submit"></app-button>
          <div class="flex flex-row justify-between">
            <div class="text-right mt-2">Already have an account? <a [routerLink]="['/login']" class="link">
                Login here</a>
            </div>
          </div>
        </div>
      </form>
      <!--<p style="margin-top: 10px; font-size: 16px;">Already have an Account? <a-->
      <!--[routerLink]="['/login']">Login</a></p>-->

    </div>
    <div class="right-container flex-center flex-column">
      <div class="logo-wrap">
        <img src="assets/images/cc-logo-md.png" alt="Creditclan's Logo">
      </div>
      <div class="img-wrap">
        <img src="assets/images/signup.svg" alt="Illustration">
      </div>
      <p class="welcome-text">We are building Africa's smartest technology for lending</p>
    </div>
  </div>
</div>