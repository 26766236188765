 
<div class="panel padding-regular flex" >
  <div class="flex-3">
    <div class="section-title color-red mb-4">Frequently Called Numbers</div>
    <ul class="decList bigger">
      <li *ngFor="let fc of fcn"><span>{{fc.name}}</span>
        <span *ngIf="!loading">{{fc.frequently_called_number}}</span>
        <span *ngIf="loading">Lorem, ipsum.</span>
      </li>
       
    </ul> 
  </div>
  <div class="flex-1 flex flex-center">
    <div class="section-poster">
      <img src="/assets/images/clock.svg" alt="" style="width: 50px">
    </div>
  </div>
</div>