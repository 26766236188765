<div class="section-title mb-4 cursor-pointer" (click)="cancel.emit()" *ngIf="!loading">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>


<div>
  <div class="panel padding-regular flex">
    <div class="panel-body padding-regular">
      <h3 class="section-title mb-4">Transactions</h3>
      <div class="contract-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Bank</th>
              <th>Narration</th>
              <th>Credit</th>
              <th>Debit</th> 

              
            </tr>
          </thead>
         
          <tbody> 
            <tr *ngFor="let trans of statement?.data?.transaction">
              <td>{{trans.unformatted_trans_date}}</td>
              <td>{{trans.bank.name}}</td>
              <td>{{trans.notes.desc}}</td>
              <td>{{trans.credit}}</td>
              <td>{{trans.debit}}</td> 
            </tr>
           
        </table>
      </div>  
    </div>
  </div>
</div>
<div *ngIf="statement.data.pagination.hasPrevPage" class="section-title mb-4 cursor-pointer pull-left" (click)="getMoreRecords(statement.data.pagination.prevPage)">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>

<div *ngIf="statement.data.pagination.hasNextPage" class="section-title mb-4 cursor-pointer pull-right" (click)="getMoreRecords(statement.data.pagination.nextPage)">
  <i class="fas fa-long-arrow-alt-right mr-3"></i> Next
</div>
