import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoansService, StorageService } from 'src/app/_services';

const _questions = [
  {
    id: 0,
    title: 'How to create customers'
  },
  {
    id: 1,
    title: 'How to book a loan request/Edit contact'
  },
  {
    id: 2,
    title: 'How to view/edit customers profile'
  },
  {
    id: 3,
    title: 'How to use the timeline'
  },
  {
    id: 4,
    title: 'How to create users/align roles'
  },
  {
    id: 5,
    title: 'How to setup approvals and workflow'
  },
  {
    id: 6,
    title: 'How to request other documents and requirements'
  },
  {
    id: 7,
    title: 'How to set up guarantors'
  },
  {
    id: 8,
    title: 'How do I use the credit bureau on the platform?'
  },
  {
    id: 9,
    title: 'How to set up cards/Accounts'
  },
  {
    id: 10,
    title: 'How to set up Dirct Debit'
  },
  {
    id: 11,
    title: 'How to set up Remita inflight'
  },
  {
    id: 12,
    title: 'How to fund the platform walet'
  },
  {
    id: 13,
    title: 'How to disburse to a customer'
  },
  {
    id: 14,
    title: 'How to register cheques on the platform'
  },
  {
    id: 15,
    title: 'How to confirm payments outside the platform'
  },
  {
    id: 16,
    title: 'How to break loan (pay balance)'
  },
  {
    id: 17,
    title: 'How to top up loans'
  },
  {
    id: 18,
    title: 'How to rollover loans'
  },
  {
    id: 19,
    title: 'How to change default repayments'
  },
  {
    id: 20,
    title: 'How to request for another card after payment'
  },
  {
    id: 21,
    title: 'How to cancel autodebit'
  },
  {
    id: 22,
    title: 'How to disburse loan queued for disbursement'
  },
  {
    id: 23,
    title: 'How to view platform wallet statement'
  },
  {
    id: 24,
    title: 'How to view accounts'
  },
  {
    id: 25,
    title: 'How to do general settings/other settings'
  },
  {
    id: 26,
    title: 'How to view reports'
  },
  {
    id: 27,
    title: 'How to set-up Products'
  },
  {
    id: 28,
    title: 'How to create branch'
  },
  {
    id: 29,
    title: 'How to setup Eligibility'
  },
  {
    id: 30,
    title: 'How to setup Analytics.'
  },
  {
    id: 31,
    title: 'How to run eligibility'
  },
  {
    id: 32,
    title: 'How to run analytics'
  },
]

@Component({
  selector: 'app-qna',
  templateUrl: './qna.component.html',
  styleUrls: ['./qna.component.css']
})
export class QnaComponent implements OnInit {
  visible = false;
  selected = {
    id: null,
    content: null
  }
  options = {
    showHelp: false,
    autoVisible: false
  };
  questions = _questions;
  query = '';
  currentUser:any;
link:any;
  constructor(
    public loansService: LoansService,
    public storageService: StorageService,
    private sanitize: DomSanitizer
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    
    
  }

  ngOnInit() {
    this.options = JSON.parse(localStorage.getItem('options')) || { showHelp: false, autoVisible: false };
    this.sendEvent();
//    this.link = this.sanitize.bypassSecurityTrustResourceUrl('https://appnine.creditclan.com/?src='+this.currentUser.token);
  }

  toggleVisible() {
    this.visible = !this.visible;
  }

  search() {
    if (!this.query) {
      this.questions = _questions;
      return;
    }
    const search = new RegExp(this.query, 'gi');
    const questions = [..._questions].filter(q => q.title.match(search));
    this.questions = questions;
  }

  select(id) {
    this.selected.id = id;
  }

  clear() {
    this.selected.id = null;
    this.selected.content = null;
  }

  setOption(key, value) {
    this.options[key] = value;
    localStorage.setItem('options', JSON.stringify(this.options));
    this.sendEvent();
  }

  sendEvent() {
    let event = new Event("options");
    window.dispatchEvent(event);
  }

  handleChange(e) {
    const key = e.target.name;
    const value = e.target.checked;
    this.setOption(key, value);
  }
}
