import { Component, OnInit, Input } from '@angular/core';
import { DataService, UserService, CustomerService, AuthenticationService, StorageService, OperationsService } from '../../_services/index';
import { Subject } from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-smssender',
  templateUrl: './smssender.component.html',
  styleUrls: ['./smssender.component.css']
})
export class SmssenderComponent implements OnInit {

  @Input('is_multiple') is_multiple = true;
  @Input('customer') customer:any;
  endDate = '1561762799000';
  overlayOpen: Boolean;
  showallCustomers: Boolean;
  choosedcustomer: number;
  loading: Boolean;
  loader: Boolean;
  currentUser: any;
  customerEmail: any;
  items = [true, 'Two', 3];
  listemail: any;
  emailSelected = [];
  dropdownList = [];
  selectedItems = [];
  selectedCustomer = [];
  selectedCustomerId = [];
  showSMSlist = false;
  selectedCustomerForSms = [];
  selectedCustomerIdForSms = [];

  dropdownSettings = {};
  openSms: Boolean;
  openEmail: Boolean = true;
  modalEmail: Boolean;
  modalSms: Boolean;
  loadsentmail: any;
  loadsentsms: any;
  showEmaillist = true;
  showSmslist = false;
  loadedemail: any;
  loadedsms: any;

  // Loader list
  preEmailloader: boolean;
  nextEmailloader: boolean;

  preSmsloader: boolean;
  nextSmsloader: boolean;


  // pagination
  emailpaginationstart: number;
  smspaginationstart;
  pageNo = 1;
  smsPageNo = 1;
  no_of_records_per_page = 4;
  defaultPageNumber = 3;

  emailCount: any;
  smsCount: any;

  results: Object;
  searchTerm$ = new Subject<string>();
  @Input('from_where') from_where = '0';
  hasCustomerLists = false;
  @Input('userId') userId :any;
  emailmessage = '';
  emailsubject='';
  search='';
  message='';
  constructor(public operationsService: OperationsService, 
    public toastr: ToastrService,
    public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    if(this.from_where == '1'){
      this.selectedCustomer = this.customer;  
      this.selectedCustomerId.push(this.userId);
    }
    // this.operationsService.search(this.searchTerm$, this.currentUser.token)
    // .subscribe(results => {
    //   if(results.length > 0) {
    //     this.hasCustomerLists = true;
    //     this.results = results;
    //   } else {
    //     this.results = 0;
    //     this.hasCustomerLists = false;
    //   }
    // }); 
   
    this.operationsService.loadSentEmail(this.currentUser.token, 0, this.defaultPageNumber).subscribe((data) => {
      this.emailpaginationstart = data.length;
      this.loadedemail = data;
    });

    this.operationsService.loadSentSMS(this.currentUser.token, 0, this.defaultPageNumber).subscribe((data) => {
      this.smspaginationstart = data.length;
      this.loadedsms = data;
    });

    this.operationsService.countEmail(this.currentUser.token).subscribe((data) => {
      this.emailCount = data; 
    });

    this.operationsService.countSMS(this.currentUser.token).subscribe((data) => {
      this.smsCount = data; 
    });

  }

onShowEmailList() {
  this.showEmaillist = !this.showEmaillist;
}

onShowSMSList() {
  this.showSmslist = !this.showSmslist;
}


onNextEmailList() {
  this.nextEmailloader = true;
  const offset = (this.pageNo + 1) * this.no_of_records_per_page;
  this.operationsService.loadNextSentEmail(this.currentUser.token, offset, this.no_of_records_per_page)
  .subscribe((data) => {
    if(data){
      this.pageNo ++;
      this.nextEmailloader = false;
      this.loadedemail = data;
    }

    if (data.status == false) {
      this.nextEmailloader = false;
    }
    
  });
}

onPreviousEmailList() {
  this.preEmailloader = true;
  const offset = (this.pageNo - 1) * this.no_of_records_per_page;
  this.operationsService.loadPreviousSentEmail(this.currentUser.token, offset, this.no_of_records_per_page)
  .subscribe((data) => {
    if (data) {
      this.pageNo --;
      this.preEmailloader = false;
      this.loadedemail = data;
    }
    if (data.status == false) {
      this.preEmailloader = false;
    }

  });
}


onNextSMSList() {
  this.preSmsloader = true;
  const smsoffset = (this.smsPageNo + 1) * this.no_of_records_per_page;
  this.operationsService.loadNextSentSMS(this.currentUser.token, smsoffset, this.no_of_records_per_page)
  .subscribe((data) => {
    if (data) {
      this.smsPageNo ++;
      this.preSmsloader = false;
      this.loadedsms = data;
    } 
    if (data.status == false) {
      this.preSmsloader = false;
    }
    
  });
}

onPreviousSMSList() {
  this.nextSmsloader = true;
  const smsoffset = (this.smsPageNo - 1) * this.no_of_records_per_page;
  this.operationsService.loadPreviousSentSMS(this.currentUser.token, smsoffset, this.no_of_records_per_page)
  .subscribe((data) => {
    if (data) {
      this.nextSmsloader = false;
      this.smsPageNo --;
      this.loadedsms = data;

    }

    if (data.status == false) {
      this.nextSmsloader = false;
    }
    
  });
}


selectedName(selectedNameId, peopleId) {
  
  console.log(this.selectedCustomer)
  // if (!this.selectedCustomer.includes(selectedNameId)) {
    
  // }
  this.selectedCustomer.push(selectedNameId);
  // if (!this.selectedCustomerId.includes(peopleId)) {
    
  // }
  this.selectedCustomerId.push(peopleId);
  this.hasCustomerLists = false; 
}


removeSelectedName(id) {
  this.selectedCustomer
  .splice(this.selectedCustomer.indexOf(id), 1);

  this.selectedCustomerId
  .splice(this.selectedCustomerId.indexOf(id), 1);

}
removeSelectedNames(id) {
  console.log(id)
  console.log(this.selectedCustomer)
  this.selectedCustomer
  .splice(this.selectedCustomer.indexOf(id), 1);

  this.selectedCustomerId
  .splice(this.selectedCustomerId.indexOf(id), 1);

}

selectedNameForSms(selectedNameId, peopleId) {
  if (!this.selectedCustomerForSms.includes(selectedNameId)) {
    this.selectedCustomerForSms.push(selectedNameId);
  }

  if (!this.selectedCustomerIdForSms.includes(peopleId)) {
    this.selectedCustomerIdForSms.push(peopleId);
  }
}

removeSelectedNameFromSms(id) {
  this.selectedCustomerForSms
  .splice(this.selectedCustomerForSms.indexOf(id), 1);

  this.selectedCustomerIdForSms
  .splice(this.selectedCustomerIdForSms.indexOf(id), 1);

}


  onSubmitEmail(f) {
    this.loader = true;
    const submited = {
      subject: f.value.subject,
      message: f.value.message,
      ids: JSON.stringify(this.selectedCustomerId),
      send_type: this.choosedcustomer,
      message_type: 1,
      token: this.currentUser.token
    };

    this.operationsService.sendEmail(
      submited.token,
      submited.subject,
      submited.message,
      submited.send_type,
      submited.message_type,
      submited.ids)
    .subscribe(results => {
        console.log(results);
        this.loader = false;
        this.toastr.success('Success', 'Success!');
    });
  }


  onSubmitSMS(f) {
    // this.loader = true;
    // const submitsms = {
    //   message: f.value.message,
    //   ids: JSON.stringify(this.selectedCustomerIdForSms),
    //   send_type: this.choosedcustomer,
    //   message_type: 2,
    //   token: this.currentUser.token
    // };
   
    // this.operationsService.sendSMS(
    //   submitsms.token,
    //   submitsms.message,
    //   submitsms.send_type,
    //   submitsms.message_type,
    //   submitsms.ids)
    // .subscribe(results => { 
    //     this.loader = false;
    //     this.toastr.success('Success', 'Success!');
    // });
    this.loader = true;
    this.loader = false;
    this.toastr.success('Success', 'Success!');
    
    this.operationsService.sendBulkSMS(
      this.currentUser.token,
      this.emailsubject,
      f.value.message,
      this.selectedCustomerId);
      this.emailmessage='';
    this.emailsubject='';
    this.selectedCustomerId=[];
    this.selectedCustomer=[];
  }
  onSubmitSMSES() { ;
    this.loader = true;
    this.loader = false;
    this.toastr.success('Success', 'Success!');
    
    this.operationsService.sendBulkSMS(
      this.currentUser.token,
      this.emailsubject,
      this.emailmessage,
      this.selectedCustomerId);
      this.emailmessage='';
    this.emailsubject='';
    this.selectedCustomerId=[];
    this.selectedCustomer=[];
  }
  onEmailOpen() {
    this.openEmail = true;
    this.openSms = false;
  }

  onSmsOpen() {
    this.openSms = true;
    this.openEmail = false;
  }

  openModal() {
    this.overlayOpen = true;
    this.modalEmail = true;
    this.modalSms = false;
  }

  openModalSMS() {
    this.overlayOpen = true;
    this.modalSms = true;
    this.modalEmail = false;

  }

  closeOverlay() {
    this.overlayOpen = false;
  }

  selectCustomer() {
    this.showallCustomers = true;
    this.choosedcustomer = 1;
  }

  allCustomer() {
    this.showallCustomers = false;
    this.choosedcustomer = 2;
    this.hasCustomerLists = false;
  }
  searchForCustome(){
    this.loader = true;
 this.operationsService.searchCustomer(this.search, this.currentUser.token)
    .subscribe(results => {
      this.loader = false;
      if(results.count > 0) { 
        this.results = results.customers;
        this.hasCustomerLists = true;
      } else {
        this.results = 0;
        this.hasCustomerLists = false;
      }
    }); 
  }
}
