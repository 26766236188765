<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{ pr_0 : embeded }">
    <app-contractheader [loan]="loan" [request_id]="request_id" *ngIf="!requestId"></app-contractheader>
    <div class="panel padding-regular">
      <div class="flex justify-between align-center">
        <h3 class="section-title">Recommendations <span
            *ngIf="recommendations.length">({{ recommendations.length }})</span></h3>
        <app-button (click)="showAddModal()" icon="fa fa-plus" label="Add New" class="small outline blue"></app-button>
      </div>
    </div>

    <div class="panel padding-regular" *ngIf="!recommendations.length && !loading">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title">No Recommendations</h2>
        <p class="subtitle">There are no recommendations to show at the moment</p>
        <div class="text-center mt-2">
          <app-button label="New recommendation" class="small outline" (click)="showAddModal()" icon="fa fa-plus">
          </app-button>
        </div>
      </div>
    </div>

    <!-- <div class="panel padding-regular">
      <ul class="list-group">
        <li class="list-item">One</li>
        <li class="list-item">Two</li>
      </ul>
    </div> -->

    <div class="panel padding-regular loading" *ngIf="loading" style="height: 100px;">
    </div>
    <div class="panel padding-regular-y" *ngIf="recommendations.length && !loading">
      <div class="panel-table">
        <table class="table">
          <tr>
            <th>Date</th>
            <th>Recommender</th>
            <th>Principal</th>
            <th>Duration</th>
            <th>Interest</th>
            <!-- <th>Comment</th> -->
            <th></th>
          </tr>
          <tr *ngFor="let r of recommendations; let i=index">
            <td>{{ r.date_added | date }}</td>
            <td>{{ r.legal_name }}</td>
            <td>{{ r.bid_principal | number: '.2-2' }}</td>
            <td>{{ r.bid_tenor + ' ' + loan_durations[r.bid_period_id].ABBREV }}</td>
            <td>{{ r.bid_rate + '% / ' + loan_durations[r.bid_rate_period_id].ABBREV }}</td>
            <td>
              <app-button *ngIf="r.added_by == loggedInUser" class="small red outline"
                (click)="handleDelete(r.recommendation_id)" [disabled]="deleting"
                [loading]="deleting == r.recommendation_id" icon="fa fa-trash">
              </app-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="addModalVisible">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeAddModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding floating-title tall" role="document">
    <div class="title">New recommendation</div>
    <form class="padding-regular" (submit)="handleSubmit($event)">
      <label class="form-group">
        <span class="control-label normal">Principal</span>
        <input class="form-control" type="number" name="bid_principal" required>
      </label>
      <div class="flex">
        <label class="form-group flex-1 mr-1">
          <!-- <span class="control-label normal">Duration</span>
          <input class="form-control" type="number" name="bid_tenor"> -->
          <span class="control-label normal">Duration</span>
          <!-- <input type="number" name="bid_period_id" class="form-control"> -->
          <div class="flex">
            <input type="number" name="bid_tenor" class="form-control flex-1 mr-1" required>
            <select name="bid_period_id" class="form-control flex-1 ml-1" required>
              <option *ngFor="let d of loan_durations;let i=index;" [value]="i">{{d.LOAN_DURATION}}</option>
            </select>
          </div>
        </label>
        <label class="form-group flex-1 ml-1">
          <span class="control-label normal">Interest rate</span>
          <div class="flex">
            <input type="number" name="bid_rate" class="form-control flex-1 mr-1" required>
            <select name="bid_rate_period_id" class="form-control flex-1 ml-1" required>
              <option *ngFor="let d of loan_durations;let i=index;" [value]="i">{{d.INTEREST_DURATION}}</option>
            </select>
          </div>
        </label>
      </div>
      <label class="form-group">
        <span class="control-label normal">Comment</span>
        <input class="form-control" type="text" name="comment">
      </label>
      <app-button type="submit" class="small mt-2" label="Add" [loading]="adding"></app-button>
    </form>
  </div>
</div>