<div class="section-title mb-4 cursor-pointer" (click)="cancel.emit()" *ngIf="!loading">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>

<!-- <div class="panel bg-off-white">
  <div class="alert">
    <div class="icon">
      <i class="fa fa-area-chart"></i>
    </div>
    <div class="texts">
      <div class="title">
        {{statement.COMMENTS}}
      </div>
      <div class="subtitle">{{statement.DATE_ADDED}}</div>
      <div class="subtitle" *ngIf="statement?.ANALYSED=='1'">{{statement.ANALYSIS_LAST_DONE}}</div>
      <div class="subtitle" *ngIf="statement?.SENT_FOR_ANALYSIS =='1'">Sent for Analysis</div>
    </div>
  </div>
</div> -->

<div *ngIf="!loading && !result && from_where == '1'">
  <div class="panel padding-regular flex">
    <div class="flex-1">
      <div class="h-group mt-2">
        <div class="title no-float">Analyse statement</div>
      </div>
    </div>
    <div class="flex-1">
      <div class="alert alert-danger alert-sm mb-4" *ngIf="error">
        {{ error }}
      </div>
      <div class="form-group">
        <label>Statement Password (If any)</label>
        <input type="password" class="form-control" name="password" [(ngModel)]="password">
      </div>
      <div class="form-group">
        <label>Account Name</label>
        <input type="text" class="form-control" name="customername" [(ngModel)]="customername">
      </div>
      <small class="mt-2">Enter pdf password if necessary, ignore if statement is not locked.</small>
      <div class="text-left mt-4">
        <app-button class="small mr-1" [loading]="loading" (click)="analyze()" label="Analyze">
        </app-button>
        <app-button class="small outline red" label="Cancel" (click)="cancel.emit()"></app-button>
      </div>
    </div>
  </div>
  <div class="panel bg-off-white">
    <div class="flex justify-between align-center">
      <div class="alert">
        <div class="icon">
          <i class="fa fa-cog color-red"></i>
        </div>
        <div class="texts">
          <h4 class="title">Bank statement settings</h4>
          <p class="subtitle">View or modify you bank statement settings</p>
        </div>
        <div class="action">
          <app-button class="outline small" label="Goto settings"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loading && !result && from_where == '2'">
  <div class="panel padding-regular flex">
    <div class="flex-1">
      <div class="h-group mt-2">
        <div class="title no-float">Analyse transactions</div>
      </div>
    </div>
    <div class="flex-1">
      <div class="alert alert-danger alert-sm mb-4" *ngIf="error">
        {{ error }}
      </div>
      
      <div class="text-left mt-4">
        <app-button class="small mr-1" [loading]="loading" (click)="analyzeSMS()" label="Analyze">
        </app-button>
        <app-button class="small outline red" label="Cancel" (click)="cancel.emit()"></app-button>
      </div>
    </div>
  </div> 
</div>
<div class="panel padding-regular flex flex-center flex-column" *ngIf="!loading && result">
  <br>
  <img src="assets/images/analysis-done.svg" alt="Analysis Completed" style="width: 100px;" class="mb-2">
  <h3 class="section-title mt-5">Analysis Completed</h3>
  <div class="buttons mt-4">
    <app-button label="View result" class="small green" (click)="loadResult()"></app-button>
  </div>
  <br>
</div>

<div class="panel padding-regular" *ngIf="loading">
  <div class="three-dots-loader">
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  </div>
  <div class="default text-size text-center">Analyzing..</div>
</div>