<div class="cont">
  <div class="sect-wrap">
    <div class="left-container" *ngIf="current_view == 'terms'">
      <div class="onb-title">
        <h3>Terms and conditions for using this platform</h3>
      </div>
      <div class="onb-content">
        <div class="terms-text text-justify">
          <h4><b>Introduction</b></h4>
          <p>These terms and conditions (“Terms and Conditions”) set out:</p>
          <ul>
            <li>The process of being a Lender on the Creditclan platform.</li>
            <li>Our relationship.</li>
          </ul>

          <p>Creditclan Technology Limited is a company providing technology and infrastructure for lending operations
            incorporated under the Laws of Federal Republic of Nigeria] and having its place of business at 15 Bode
            Thomas Street, Surulere, Lagos</p>

          <p>Creditclan operates the Creditclan Platform through its Website for the purposes of matching those who wish
            to lend with those who wish to borrow, and services connected with lending and borrowing via the Creditclan
            Platform.

            In return for arranging and servicing loans made via the Creditclan Platform, Lenders may pay a fee or
            commission as set out in these Terms and Conditions and in any relevant documentation.
          </p>

          <h4><b>Definitions</b></h4>
          <ul>
            <li>In these Terms and Conditions the following words shall, unless the context requires otherwise, have the
              following meanings:
            </li>
            <li>“Borrower” means the actual or prospective borrower under a Loan Agreement facilitated via the
              Creditclan Platform;
            </li>
            <li>“Lender” means the actual or prospective lender(s) under a Loan Agreement facilitated via the Creditclan
              Platform; where there is more than one lender under the Loan Agreement, Lenders shall be construed
              accordingly for the Peer to Peer Platform;
            </li>
            <li>“Loan” means a loan transaction entered into by a Borrower and a Lender using the Creditclan Platform,
              the terms of which are set out in a Loan Agreement;
            </li>
          </ul>

          <h4><b>Creditclan's Platform</b></h4>
          <p>To access and use the Creditclan Platform you must sign up as a Lender of the Creditclan Platform pursuant
            to these Terms and Conditions.</p>

          <h4><b>Our Role</b></h4>
          <p> Our role is to:</p>
          <ul>
            <li>Set-up the Customer as a Lending merchant on CreditClan lending platforms for direct lending
              operations.
            </li>

            <li>Work with the payment gateways to provide a payment service gateway to the customer and transaction
              charges paid for by the customer. Services provided are: Wallet-to-account transfer, Direct-Debit Card and
              Bank Account Name Authentication.
            </li>

            <li>Manage all provided aspects of servicing and managing the Loans.</li>
          </ul>

          <h4><b>Our Agreement</b></h4>
          <p>These Terms and Conditions are entered into between Creditclan Technologies (“Creditclan”, “us”, “we”
            “our”) and you (“you”, “the Lender", “the User”).</p>
          <p>The Customer must not submit any transaction that the Customer and its Clientele either knows is, or should
            have known was, illegal or fraudulent;</p>

          <p>The Parties further warrant that no element of this transaction constitutes a breach of any existing law,
            regulation, patent, copyright, or other intellectual property in its country or countries of domicile and
            operation.</p>

          <p>The Customer warrants it will conduct appropriate customer due diligence using a risk based approach on all
            Clientele.</p>

          <p>CreditClan neither warrants that the use of the Platform or the operation thereof will be uninterrupted nor
            error free, however, CreditClan warrants that it shall use its best endeavours to ensure that the Platform
            functions optimally at all times and within generally accepted industry standards during the term of this
            Agreement</p>

          <p>You should only become a lender on the Creditclan Platform or access and/or use the Creditclan Platform, if
            you have read these Terms and Conditions, understood them and agree to be bound by them. If you do not
            understand any part of these Terms & Conditions, please ask us for further information before signing. You
            can contact us at support@creditclan.com</p>

        </div>
      </div>
      <div class="onb-action">
        <app-button class="block" (click)="agreeMethod()" label="I accept"></app-button>
      </div>
    </div>
    <div class="left-container" *ngIf="current_view == 'creating_profile'">
      <div class="loading">
        <div class="box">
          <div class="indicator"></div>
          <div class="text">Creating Account..</div>
          <small>Thank your for registering on our platform</small>
        </div>
      </div>
    </div>
    <div class="right-container flex-center">
      <div class="img-wrap">
        <img src="assets/images/cc-logo-md.png" alt="Creditclan's Logo">
        <p class="welcome-text">.. Related by Interest</p>
      </div>
    </div>
  </div>
</div>
