<section class="panel" style="overflow:hidden;">
  <div style="padding:24px 20px; overflow: auto;" *ngIf="customerSelected">
    <div class="text-right">
      <a (click)="customerSelected = !customerSelected" class="text-danger" style="font-size: 20px"><span class="fa fa-times-circle"></span></a>
    </div>
    <div class="text-center borrower-info">
      <a class="pull-left" style="margin-right: 25px">
        <img src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{selectedcustomer?.FILE_NAME | imagenotfound}}"
          style="width:50px; height:50px">
      </a>
      <div class="borrower-text pull-left" style="text-align:left">
        <p class="" style="margin-bottom: 0px">{{selectedcustomer?.LEGAL_NAME}}</p>
      </div>
    </div>
  </div>
  <div class="search-results" style="padding:15px" *ngIf="!customerSelected">
    <label class="control-label">Search customer</label>
    <input class="form-control" type="text" name="searchString" (keyup)="searchCustomers()" name="lender_name" [(ngModel)]="searchString"
      style="max-width: 90%">

    <!-- <input class="form-control" [value]="query" ngxTypeahead [taUrl]="url" [taParams]="params" [taItemTpl]="itemTpl" (taSelected)="handleResultSelected($event)"> -->
    <!-- <ng-template #itemTpl let-result class="list-item">
      <div>
        <i></i> <strong>{{ result.result }}</strong>
      </div>
    </ng-template> -->
    <div *ngIf="searchedCustomerResult?.count!='0'" style="    position: absolute;
    z-index: 100000;
    border: 1px solid #ccc;
    border-radius: 7px;
    background: #f5f5f5;
    width: 84%;">

      <article (click)="selectCustomer(customer)" class="media" *ngFor="let customer of searchedCustomerResult?.a" style="padding: 10px; margin-top: 0; cursor: pointer;">
        <div class="pull-left thumb p-thumb">
          <img src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{customer?.FILE_NAME | imagenotfound}}"
            style=" width: 40px;   height: 40px; border-radius: 50%; ">
        </div>
        <div class="media-body">
          <p class="txt t_l4 t_cool" style="word-wrap:break-word; ">{{customer?.LEGAL_NAME}}</p>
          <p style="font-size: 12px">{{customer?.PEOPLE_CUSTOMERS_ID}}</p>
        </div>
      </article>

    </div>
  </div>
  <div class="text-center" style="z-index: 0">
    <p class="" style="font-weight: bold">Book Savings/Investment</p>
  </div>
  <div *ngIf="paymentHasBeenProcessed">
    <div *ngIf="result?.status==true">
      <div style="width: 120px;margin:20px auto;">
        <svg style="width: 100%;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
          y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve">
          <g>
            <circle fill="none" stroke="#1CA261" stroke-width="6" stroke-miterlimit="10" cx="50" cy="50" r="47" />
            <polygon style="color:#1CA261" points="72,25 42,71 27,56 23,60 43,80 77,28  " />
          </g>
        </svg>
      </div>


      <p class="text-center">
        <small>Successful</small>
      </p>
    </div>
    <div *ngIf="result?.status==false">
      <div style="width: 120px;margin:20px auto;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
          <path d="M50,8A42,42,0,1,1,8,50,42,42,0,0,1,50,8m0-3A45,45,0,1,0,95,50,45,45,0,0,0,50,5Z"></path>
          <path d="M47.08,46.44q-.79-4-1.18-6.42t-.51-3.54q-.11-1.15-.11-2a4.3,4.3,0,0,1,1.29-3.25,5.36,5.36,0,0,1,6.84,0,4.3,4.3,0,0,1,1.29,3.25q0,.84-.11,2T54.1,40q-.39,2.38-1.18,6.42T50.79,57.1H49.21Q47.87,50.48,47.08,46.44Zm-.34,15.88a4.6,4.6,0,0,1,7.85,3.25,4.16,4.16,0,0,1-1.35,3.17,4.82,4.82,0,0,1-6.51,0,4.16,4.16,0,0,1-1.35-3.17A4.43,4.43,0,0,1,46.75,62.31Z"></path>
        </svg>
      </div>


      <p class="text-center">
        <small>Unable to process request.</small>
      </p>
    </div>
  </div>
  <div *ngIf="!paymentHasBeenProcessed">
    <div class="paytype-switch" style="width: 100%;position: relative;z-index: 10;background: white; overflow: auto">
      <a class="paystack-switch-confirm active">Book Savings/Investment</a>
    </div>

    <div>
      <div class="panel-body animated">
        <div *ngIf="loading">
          <div *ngIf="loading && !paymentHasBeenProcessed">
            <div class="text-center" style="padding: 20px 0px; padding-bottom: 0px">
              <a href="javascript:;" style="font-size:40px">
                <i class="fa fa-hourglass fa-spin"></i>
              </a>
              <span style="display: block;">processing...</span>

            </div>

          </div>
          <div *ngIf="loading && paymentHasBeenProcessed">

            <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px">
              <div>
                <a href="javascript:;" style="font-size:40px">
                  <i class="fa fa-check"></i>
                </a>
                <span style="display: block;">Request successful</span>
              </div>

              <button type="button" class="btn btn-white" (click)="paymentProcessDone()">View Request</button>
            </div>
          </div>
        </div>
        <div *ngIf="!loading">
          <form [formGroup]="postSavingsForm" (ngSubmit)="doBookSavings(postSavingsForm.value)">
            <div class="form-group" [ngClass]="{'has-error':!postSavingsForm.controls['SAVINGS_PRODUCT_ID'].valid && postSavingsForm.controls['SAVINGS_PRODUCT_ID'].touched}"
              style="margin-bottom:15px">
              <label for="exampleInputEmail1">Product</label>
              <select class="form-control" [formControl]="postSavingsForm.controls['SAVINGS_PRODUCT_ID']" name="SAVINGS_PRODUCT_ID" id="SAVINGS_PRODUCT_ID">
                <option value="0"></option>
                <option *ngFor="let product of savings_products?.products" [ngValue]="product">{{product.INVESTMENT_TITLE}}</option>
              </select>
            </div>
            <div class="form-group" [ngClass]="{'has-error':!postSavingsForm.controls['PRINCIPAL'].valid && postSavingsForm.controls['PRINCIPAL'].touched}">
              <label for="exampleInputEmail1">Principal</label>
              <input type="text" class="form-control" [formControl]="postSavingsForm.controls['PRINCIPAL']" name="PRINCIPAL" id="PRINCIPAL">
            </div>
            <div class="input-group " style="margin-bottom:15px">
              <label for="exampleInputEmail1">Tenor</label>
              <input type="text" class="form-control" [formControl]="postSavingsForm.controls['TENOR']" name="TENOR" id="TENOR">
              <input type="hidden" class="form-control" [formControl]="postSavingsForm.controls['REQUEST_PERIOD_ID']" name="REQUEST_PERIOD_ID"
                id="REQUEST_PERIOD_ID">
              <input type="hidden" class="form-control" [formControl]="postSavingsForm.controls['REQUEST_RATE_PERIOD_ID']" name="REQUEST_RATE_PERIOD_ID"
                id="REQUEST_RATE_PERIOD_ID">
              <div class="input-group-btn">
                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown" style="margin-top:26px">
                  <span class="sel_cur">{{loan_duration}}</span>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right">
                  <li *ngFor="let d of loan_durations;let i=index;">
                    <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="input-group " style="margin-bottom:15px">
              <label for="exampleInputEmail1">Rate</label>
              <input type="text" class="form-control" [formControl]="postSavingsForm.controls['INTEREST']" name="INTEREST" id="INTEREST">

              <div class="input-group-btn">
                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown" style="margin-top:26px">
                  <span class="sel_cur">{{interest_duration}}</span>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right">
                  <li *ngFor="let d of loan_durations;let i=index;">
                    <a (click)="changeDuration(i,2)">{{d.LOAN_DURATION}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group" [ngClass]="{'has-error':!postSavingsForm.controls['FUNDS_RECEIVED_DATE'].valid && postSavingsForm.controls['FUNDS_RECEIVED_DATE'].touched}"
              style="margin-bottom:15px">
              <label class="control-label">Date</label><br>
              <my-date-picker [selDate]="selDate_" name="REPAYMENT_STARTED_WHEN" [options]="myDatePickerOptions" [formControl]="postSavingsForm.controls['FUNDS_RECEIVED_DATE']"
                name="FUNDS_RECEIVED_DATE" id="FUNDS_RECEIVED_DATE"></my-date-picker>
            </div>
            <div class="row">
    
                <div class="col-md-12">
                  <button class="btn btn-block btn-white" type="submit">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                  </button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>