import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService, LoansService } from 'src/app/_services';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent implements OnInit {

  @Input() loan;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() reload = new EventEmitter();
  loading = false;
  currentUser: any;

  constructor(
    public toastr: ToastrService,
    public storageService: StorageService,
    public loansService: LoansService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }

  changeProfilePic() {
    this.fileInput.nativeElement.click()
  }

  fileChangeEvent(event: any): void {
    this.loading = true;
    const uploadLogo = new FormData();
    uploadLogo.append('img', event.target.files[0], event.target.files[0].name);
    uploadLogo.append('token', this.currentUser.token);
    uploadLogo.append('request_id', this.loan.request_id);

    this.loansService.updateBorrowerPhoto(uploadLogo).subscribe((data) => {
      this.loading = false;
      this.toastr.success('Profile picture changed succesfully', 'Success!');
      this.reload.emit();
    }, err => {
      this.loading = false;
      const res = JSON.parse(err._body);
      this.toastr.error(res.message.error || 'An error occured', 'Error!');
    });
  }
}
