import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService, } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OptionsserviceService } from '../../_services/optionsservice.service';

@Component({
  selector: 'app-bulkdisbursementdownload',
  templateUrl: './bulkdisbursementdownload.component.html',
  styleUrls: ['./bulkdisbursementdownload.component.css']
})
export class BulkdisbursementdownloadComponent implements OnInit {

  @Input('currentUser') currentUser:any;
  @Input('disbursements') disbursements:any;
  loading = false;
  payment = {
    'SECURITY_QUESTION_ANSWER':''
  }

  constructor(public toastr: ToastrService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService,
    public storageService: StorageService, public optionsService: OptionsserviceService, public dataService: DataService) {

  }

  ngOnInit() {
    this.downLoadBulkDisbursements();
  }

  downLoadBulkDisbursements(){
    this.loading = true;
    this.operationsService.downLoadBulkDisbursements(this.currentUser.token, this.disbursements)
      .subscribe(data => {
        this.loading = false; 
        window.open(data.message);
        if (data.status === true) {
          this.showSuccess('Data Successfully exported. Download would start automatically.')
        } else {
          this.showError(data.message)
        } 
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
}
