<div class="panel mb-1">
  <div class="panel-body padding-regular">
    <div class="flex">
      <div class="flex-3">
        <div class="section-title color-orange mb-4">Do something about this request</div>
        <ul class="decList bigger">
          <li><span>Name</span><span>{{oldest_approval?.LEGAL_NAME}}</span></li>
          <li><span>Principal</span><span>{{oldest_approval?.request_principal}}</span></li>
          <li><span>Duration</span><span>{{request_tenor}}</span></li>
          <li><span>TAT</span><span>{{oldest_approval?.tat}}</span></li>
        </ul>
        <div class="text-left mt-4">
          <app-button (click)="open_requests(oldest_approval?.request_id)" class="small outline" label="View">
          </app-button>
        </div>
      </div>
      <div class="flex-2 ml-4 flex-center">
        <div class="image-avatar large">
          <img
            [src]="'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/'+oldest_approval?.file_name">
        </div>
      </div>
    </div>
  </div>
</div>