<div class="panel padding-regular">
  <div class="flex flex-1">
    <div class="flex-1 flex-center" (click)="is_individual = true">
      <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': is_individual }">
        <div class="text">Default checklist</div>
      </div>
    </div>
    <div class="flex-1 flex-center" (click)="is_individual = false">
      <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': !is_individual }">
        <div class="text">Product checklist</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="is_individual" class="mt-3">
  <app-loanchecklist class="flex-1" [where]="2" [parentRouteId]="parentRouteId"></app-loanchecklist>
</div>
<div *ngIf="!is_individual">
  <app-productchecklist [loading]="loading" (saveProductChecklists)="saveProductChecklists($event)"
    [product_checklist]='loan?.loan_checklist' [from_where]="'loan'"></app-productchecklist>
</div>