import { Component, OnInit, Input } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, FormBuilder, Validators } from '@angular/forms'; 
import { UserService,DataService, OperationsService, AuthenticationService, StorageService } from '../_services/index';

@Component({
  selector: 'app-portfoliocalendar',
  templateUrl: './portfoliocalendar.component.html',
  styleUrls: ['./portfoliocalendar.component.css']
})
export class PortfoliocalendarComponent implements OnInit {
  @Input('show_for_account_officer') show_for_account_officer=false;
  @Input('title') title='Requests';
  @Input('title_') title_='Cash Flow Analysis';
  @Input('showFilterOptions') showFilterOptions = false;
  state: any;
  schedule_type = false;
  record_type = '1';
  
  default_date: any;
  currentUser: any;
  
  calendarOptions: any;
  calendarOptions_: any;
  
  datechosen: any;
  overlayOpen = false;
  showSwitch = true;
  filterType = '1';
  constructor(public dataService:DataService,public fb: FormBuilder, public operationsService: OperationsService, public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');

  }

  ngOnInit() {
    //this.getCurrentRepaymentCalendar();
    this.calendarOptions = {
      height: '1000',
      fixedWeekCount: false,
      defaultDate: this.default_date,
      editable: true,
      eventLimit: true,
      eventClick: (calEvent, jsEvent, view) => {
        this.showdetails(calEvent);
      },
      events: (start, end, timezone, callback) => {
        this.operationsService.getCurrentRepaymentCalendarWithAccountOfficerWithDuration(this.currentUser.token, '1', false, this.show_for_account_officer, start, end, this.filterType)
          .subscribe(data => {
            this.default_date = data.default_date;
            callback(data.events)
            
            this.initiateOtherCalendar();
          });

      }
    };
    
  }
  initiateOtherCalendar(){
    this.calendarOptions_ = {
      height: '1000',
      fixedWeekCount: false,
      defaultDate: this.default_date,
      editable: true,
      eventLimit: true,
      eventClick: (calEvent, jsEvent, view) => {
        this.showdetails_(calEvent);
      },
      events: (start, end, timezone, callback) => {
        this.operationsService.getCurrentRepaymentCalendarWithAccountOfficerWithDuration(this.currentUser.token, '1', false, this.show_for_account_officer, start, end, '2')
          .subscribe(data => {
            this.default_date = data.default_date;
            callback(data.events) 
          });
      }
    };
  }
  changeFilter(event){
    this.filterType = event.records
  }
  getCurrentRepaymentCalendar() {
    this.operationsService.getCurrentRepaymentCalendarWithAccountOfficer(this.currentUser.token, this.record_type, this.schedule_type, this.show_for_account_officer)
      .subscribe(data => {
        this.state = data;
        this.default_date = data.default_date;
      });
  }
  showdetails(calEvent) {
    this.datechosen = calEvent.start._i; 
    this.dataService.openCalendarBulkRepayments.emit({date_chosen:this.datechosen});
    //this.dataService.showPortfolioCalendarDetails.emit({date_chosen:this.datechosen});
  } 
  showdetails_(calEvent) { 
    console.log(calEvent)
    this.datechosen = calEvent.start._i; 
    this.dataService.openTheCashFlowForTheDay.emit({date_chosen:this.datechosen});
  } 
  closeOverlay() {
    this.overlayOpen = false;
    //console.log(1)
  }

}
