import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataService, StorageService } from '../../_services/index';

@Component({
  selector: 'app-analyticscontractheader',
  templateUrl: './analyticscontractheader.component.html',
  styleUrls: ['./analyticscontractheader.component.css', './style.css', './boot-outline-btn.css']
})
export class AnalyticscontractheaderComponent implements OnInit {

  @Input('request_id') request_id = '0';
  @Input('loan') loan: any;
  request_id_: any;
  @Input('text') text = 'More';
  enable_peer_to_peer = '0';
  @Input('nooptions') nooptions = false;
  backToSummary = false;

  constructor(private router: Router, private dataService: DataService, public storageService: StorageService) {
    this.backToSummary = this.router.url.startsWith('/applications');
    this.enable_peer_to_peer = this.storageService.read<any>('enable_peer_to_peer');
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.request_id;
    if (request_id) {
      this.request_id_ = request_id.currentValue;
    }

  }
  openContract() {
    this.router.navigate(['./applications/pending/' + this.request_id_]);
  }
  openProfile() {
    this.router.navigate(['./applications/profile/' + this.request_id_]);
  }
  openAnalytics() {
    this.router.navigate(['./applications/analytics/' + this.request_id_]);
  }
  openTimeline() {
    this.router.navigate(['./applications/timeline/' + this.request_id_]);
  }
  openEligibility() {
    this.router.navigate(['./applications/eligibility/' + this.request_id_]);
  }
  openAprovals() {
    this.dataService.loadApprovals.emit();
    this.router.navigate(['./applications/approvals']);
  }
  openRequests() {
    this.dataService.loadRequests.emit();
    this.router.navigate(['./applications/dashboard']);
  }

}
