import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  DataService,
  OptionsserviceService,
  UserService,
  OperationsService
} from '../_services/index';
import {
  LoansService,
  AuthenticationService,
  StorageService,
  CustomerService
} from '../_services/index';
import { AnalyticsService } from '../_services/index';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/_services/products.service';

import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './style.css', 'boot-outline-btn.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  summary:any;
  text = 'Invite a Customer';
  modifying_bureau_settings = false;
  externalsettingopen = '';
  pictureshown = 'profile';
  sideview = 'master';
  bsrequest = {
    password: '',
    duration: '6',
    recent_check: true,
    url: ''
  };
  go_live_details = {
    interest_rate:'',
    interest_rate_period_id:'',
    max_amount:'0',
    plan:'1'
  }
  statement: any;
  min = 0;
  max = 100;
  min_ = 0;
  max_ = 10000000;
  filteringLoans = false;
  bureau_selected = '1';
  loan_: any;
  appKey = '';
  miniSearch = false;
  searchView = false;
  loading = false;
  currentUser: any;
  sample_user: any;
  loans: any;
  overlayOpen = false;
  loan: any;
  request_id: any;
  overlayModalSection = '';
  simulateSection = 'default';
  isedit = '1';
  sub: any;
  adding_more = false;
  kyc_type_id = '1';
  repayment_mode = '0';
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  appKeys = localStorage.getItem('appKey');
  activeRequestId = null;
  goLiveSection = "home";

  public durations = [
    { display: '0 - 1', checked: false, min: 0, max: 30 },
    { display: '0 - 3', checked: false, min: 31, max: 90 },
    { display: '4 - 6', checked: false, min: 91, max: 180 },
    { display: '7 - 12', checked: false, min: 181, max: 365 },
    { display: '1+ Years', checked: false, min: 366, max: 3650 }
  ];
  public adays = [
    { display: '1 - 3', checked: false, min: 0, max: 3 },
    { display: '4 - 10', checked: false, min: 4, max: 10 },
    { display: '> 10', checked: false, min: 10, max: 365 }
  ];

  public incomes = [
    { display: '< 50K', checked: false, min: 0, max: 50000 },
    { display: '51 - 100k', checked: false, min: 50001, max: 100000 },
    { display: '101k - 250k', checked: false, min: 100001, max: 250000 },
    { display: '250k - 500k', checked: false, min: 250001, max: 500000 },
    { display: '500k - 1m', checked: false, min: 500001, max: 1000000 },
    { display: '> 1m', checked: false, min: 1000001, max: 120000000 }
  ];
  public ages = [
    { display: '18 - 24', checked: false, min: 18, max: 24 },
    { display: '25 - 34', checked: false, min: 25, max: 34 },
    { display: '35 - 45', checked: false, min: 35, max: 45 },
    { display: 'above 45', checked: false, min: 46, max: 100 }
  ];
  choosingKYC = false;
  public request_date = [
    { display: 'Today', checked: false, min: 0, max: 1 },
    { display: '2 - 7 days', checked: false, min: 2, max: 7 },
    { display: '2 - 4 weeks', checked: false, min: 8, max: 30 },
    { display: '1+ months', checked: false, min: 31, max: 100000 }
  ];
  public statuses = [
    // { value: '-2', display: 'All' },
    { value: '-6', display: 'Terminated' },
    { value: '-4', display: 'Disbursements' },
    { value: '5', display: 'Rejected' },
    { value: '-3', display: 'Ineligible' },
    { value: '6', display: 'Contract Created' }
  ];
  magic_filter = {
    repayment_status: '0',
    repayment_mode: '0',
    customer_category: '0',
    company_id: '',
    product_id: '',
    peer_to_peer: '',
    reset: false,
    my_approvals: false,
    account_officer: false,
    start: 0,
    funding: '100',
    token: '',
    min: 0,
    max: 10000000,
    loan_status: '1',
    searchText: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    funding_amount_one: 1,
    funding_amount_two: 1,
    funding_amount_three: false,
    funding_status_disbursement: false,
    funding_status_contract_created: false,
    funding_status_applied: false,
    funding_status_funded: false,
    funding_status: false,
    amount: false,
    approval_level: false,
    rating: false,
    sector: false,
    date: false
  };
  sectors: any;
  companies: any;
  approval_levels: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  cards = 'cards';
  accountdebit = 'accountdebit';
  open_approval = false;
  approving = false;
  rejecting = false;
  kyc_request = {
    KYC_COMMENTS: '',
    KYC_TYPE_ONE: false,
    KYC_TYPE_TWO: false,
    KYC_PACKAGE: '',
    KYC_TYPE_ID: '1',
    REQUEST_ID: '0'
  };
  financials_request = {
    HOW_MANY_MONTHS: '',
    BANK_STATEMENTS: false,
    PAYSLIPS: false
  };
  guarantors_request = {
    HOW_MANY_GUARANTORS: ''
  };
  pickup = {
    KYC_COMMENTS: '',
    PICKUP_ADDRESS_TYPE: '1',
    CUSTOM_PICKUP_ADDRESS: '',
    PICKUP_PACKAGE: ''
  };
  rejection = {
    MESSAGE: '',
    SEND_REJECTION_MAIL: false
  };
  reopen = {
    MESSAGE: '',
    SEND_REOPEN_MAIL: false,
    SUSPEND_CUSTOMER: false,
    SUSPEND_FOR_HOW_LONG: '24'
  };
  public other_docs = [
    {
      display: 'Valid ID Card',
      value: '1',
      checked: false
    },
    {
      display: 'Other ID Card',
      value: '15',
      checked: false
    },
    {
      display: 'Payslips',
      value: '6',
      checked: false
    },
    {
      display: 'Bank Statements',
      value: '7',
      checked: false
    },
    {
      display: 'Employment Letter',
      value: '8',
      checked: false
    },
    {
      display: 'Purchase Order',
      value: '9',
      checked: false
    },
    {
      display: 'Invoices',
      value: '10',
      checked: false
    },
    {
      display: 'Certificate of Incorporation',
      value: '11',
      checked: false
    },
    {
      display: 'Company Profile',
      value: '12',
      checked: false
    },
    {
      display: 'Allotment of Shares',
      value: '13',
      checked: false
    },
    {
      display: 'Particulars of Directors',
      value: '14',
      checked: false
    },
    {
      display: 'Work ID Card',
      value: '16',
      checked: false
    },
    {
      display: 'Utility Bill',
      value: '17',
      checked: false
    },
    {
      display: 'National ID',
      value: '18',
      checked: false
    },
    {
      display: 'Voter ID',
      value: '19',
      checked: false
    },
    {
      display: 'International Passport',
      value: '20',
      checked: false
    },
    {
      display: 'Offer Letter (Previous Loan)',
      value: '21',
      checked: false
    },
    {
      display: 'Confirmation Letter',
      value: '22',
      checked: false
    }
  ];
  public bureaus = [
    {
      display: 'CRC',
      checked: true,
      value: '1'
    },
    {
      display: 'First Central(XDS)',
      checked: false,
      value: '2'
    },
    {
      display: 'CRServices',
      checked: false,
      value: '3'
    }
  ];
  products: any;
  docpickups: any;
  doctypes: any;
  public level: any;
  public prev_levels: any;
  public model_r = {
    ilo: 0,
    reject_reason: '',
    reject_action: '',
    approval_notes: '',
    reject_level: '',
    wtd: 0,
    request_id: '',
    level: ''
  };
  public model_a = {
    chk_acts: [],
    past_one: 1,
    approval_all_waivers: 1,
    approval_all_checklist: 1,
    is_waiver_level: 0,
    has_waiver: 0,
    ilo: 0,
    istd: 0,
    approval_notes: '',
    wtd: 1,
    request_id: '',
    level: ''
  };
  disbursing_request = false;
  disburse: any;
  islarger = true;
  public is_done = '0';
  banks: any;
  childModal = { location: '', data: {} };
  account_for_direct_debit: any;
  overlayEditModalSection = '';
  nodes_and_scores: any;
  selected_analytics = '1';
  analytics_last_done = '';
  changingofficer = false;
  attachment_for_analysis: any;
  date_chosen: any;
  show_approval_calendar = false;
  view_status = 'requests';
  calendar_from = '0';
  calendar_type = '0';
  analysing_statement = false;
  where = '';
  showPicture = false;
  sms_value: any;
  guarantor_value: any;
  call_logs_value: any;
  cards_value: any;
  profile_value: any;
  social_value: any;
  accounts_value: any;
  dd_value: any;
  repayment_value: any;
  lender_id: any;
  borrower_id: any;
  principal: any;
  maturity_date: any;
  account_card_id: any;
  loan_request_id: any;
  card_id: any;
  passed_analytics: any;
  failed_analytics: any;
  number_passed: number;
  number_failed: number;
  analytics_score: number;
  profile_pic: any;
  occupations: any;
  designations: any;
  platform_summary: any;
  public marital_statuses = [
    // { value: '-2', display: 'All' },
    { value: '1', display: 'Single' },
    { value: '2', display: 'Married' },
    { value: '3', display: 'Divorced' },
    { value: '4', display: 'Widowed' },
  ];
  invitingCustomer = false;
  invitingMultipleCustomer = false;
  bvn_value: any;
  appsummary:any;
  showing_due_loans = false;
  show_portfolio_calendar_bulk_payment = false;
  calltype = '1';
  drawer = false; 
  drawer_request = false;
  drawer_portfolio = false;
  repayment:any;
  constructor(
    public toastr: ToastrService,
    public storageService: StorageService,
    public analyticsService: AnalyticsService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    private router: Router,
    private dataService: DataService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private operationsService: OperationsService,
    private productService: ProductsService,
    private http: HttpClient
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.sample_user = this.storageService.read<any>('sample_user');
    
    this.dataService.loanHomeRequestSideView.subscribe(res => {
      this.loan = res.loan;
      this.activeRequestId = res.loan.REQUEST_ID;
      this.drawer_request = true;
    })
    this.dataService.loanHomePortfolioSideView.subscribe(res => {
      this.loan = res.loan;
      this.activeRequestId = res.loan.REQUEST_ID;
      this.drawer_portfolio = true;
    })
    this.dataService.openCalendarBulkRepayments.subscribe(res => {

      this.showing_due_loans = false;
      this.date_chosen = res.date_chosen;
      this.show_portfolio_calendar_bulk_payment = true;
      this.calltype = res.type;
    })
    this.dataService.platformSummaryLoaded.subscribe(res => {
      this.platform_summary = res.platform_summary;
    });
    this.dataService.closeAnalyticsModal.subscribe(res => {
      this.overlayEditModalSection = '';
    });

    this.dataService.onRequestLoadingFromBackend.subscribe(res => {
      // this.loan = res.loan;
      // this.sideview = 'child';
    });
    this.dataService.showMasterSideBar.subscribe(res => {
      this.activeRequestId = null;
      this.sideview = 'master';
    });
    
    this.dataService.onRequestLoadingEndFromBackend.subscribe(res => {
      this.sideview = 'child';
      this.loan = res.loan;
    });
    this.dataService.onLoadPreviousLoad.subscribe(res => {
      this.loan_ = res.loan;
    });
    this.dataService.showProfilePic.subscribe(res => {
      this.profile_pic = res.pic;
      this.showPicture = true;
      this.pictureshown = 'profile';
    });
    this.dataService.showIDCard.subscribe(res => {
      this.profile_pic = res.pic;
      this.showPicture = true;
      this.pictureshown = 'idcard';
    });
    this.dataService.loadApprovals.subscribe(res => {

      this.getApprovals();
    });
    this.dataService.analyseastatement.subscribe(res => {
      this.analysing_statement = true;
      this.statement = res.statement;
      this.loan = res.loan;
      this.bsrequest.url =
        '/pub/attachments/' + res.statement.FILE_NAME;
    });
    this.dataService.loadRequests.subscribe(res => {
      this.view_status = 'requests';
      this.getLoans();
    });
    this.dataService.showCalendarDetails.subscribe(res => {
      this.date_chosen = res.date_chosen;
      this.show_approval_calendar = true;
      this.calendar_from = res.from_where;
      this.calendar_type = res.type;
    });
    this.dataService.updatingSettingsExternally.subscribe(res => {
      console.log('Dashboard',res)
      this.bureau_selected = res.bureau;
      this.externalsettingopen = res.section;
    });
    this.dataService.onChangeLoanOfficer.subscribe(res => {
      this.loan = res.loan;
      this.changingofficer = true;
    });
    this.dataService.inviteNewCustomer.subscribe(res => {
      // this.invitingCustomer = true;

      this.invitingCustomer = false;
      this.invitingMultipleCustomer = true;
    });
    this.dataService.inviteMultipleCustomers.subscribe(res => {
      this.invitingCustomer = false;
      this.invitingMultipleCustomer = true;
    });
    // this.dataService.openContractModal.subscribe(res => {
    //   this.overlayOpen = true;
    //   this.request_id = res.request_id;
    //   this.loan = res.loan;
    // })
    this.dataService.onOpenApplicationsModal.subscribe(res => {
      this.overlayModalSection = res.section;
      this.request_id = res.request_id;
      this.loan = res.loan;
    });
    this.dataService.onEditApplicationsModal.subscribe(res => {
      this.overlayEditModalSection = res.section;
      this.request_id = res.request_id;
      this.nodes_and_scores = res.nodes_and_scores;
      this.selected_analytics = res.selected_analytics;
      this.analytics_last_done = res.analytics_last_done;
      this.isedit = '1';
    });
    this.dataService.openWorkflowModal.subscribe(res => {
      this.overlayModalSection = 'workflow';
      this.request_id = res.request_id;
      this.loan = res.loan;
    });

    this.dataService.openGoLive.subscribe(res => {
      this.overlayModalSection = 'golive';
    });
    this.dataService.openOnboardSimulator.subscribe(res => {
      this.simulateSection = 'default';
      this.overlayModalSection = 'onboard';
    });
    this.dataService.approvingRequest.subscribe(res => {
      this.open_approval = true;
      this.level = res.level;
      this.prev_levels = res.prev_levels;
      this.approving = true;
    });
    this.dataService.rejectingRequest.subscribe(res => {
      this.open_approval = true;
      this.level = res.level;
      this.prev_levels = res.prev_levels;
      this.approving = false;
      this.rejecting = true;
    });
    // this.dataService.disbursingRequest.subscribe(res => {
    //   this.disbursing_request = true;
    //   this.disburse = res.disburse;
    //   this.loan = res.new_loan;
    // });
    this.dataService.closeAnalyticsModal.subscribe(res => {
      this.overlayEditModalSection = '';
    });
    this.dataService.userhomeloaded.subscribe(res => {
      this.summary = res.summary;
      this.appsummary = res.appsummary;
    });
    this.dataService.onOpenLoanChildModal.subscribe(res => {
      this.rejection.MESSAGE = '';
      this.rejection.SEND_REJECTION_MAIL = false;
      this.reopen.MESSAGE = '';
      this.reopen.SEND_REOPEN_MAIL = false;
      this.islarger = false;
      this.kyc_request.KYC_TYPE_ONE = false;
      this.kyc_request.KYC_TYPE_TWO = false;
      this.kyc_request.KYC_COMMENTS = '';
      this.pickup.PICKUP_ADDRESS_TYPE = '1';
      this.pickup.KYC_COMMENTS = '';
      this.financials_request.BANK_STATEMENTS = false;
      this.financials_request.PAYSLIPS = false;
      this.financials_request.HOW_MANY_MONTHS = '3';
      this.pickup.CUSTOM_PICKUP_ADDRESS = '';
      this.loading = false;
      this.is_done = '0';
      this.childModal = res;
      this.choosingKYC = true;
      this.islarger = true;
      if (res.location == 'request_docs_mod') {
        this.doctypes = res.docpickups;
      }
      if (res.location == 'request_kyc_mod') {
        this.kyc_request.KYC_TYPE_ID = res.kyc_type_id;
        this.kyc_request.REQUEST_ID = res.request_id;
      }
      if (res.location == 'run_credit_check') {
        this.loan = res.loan;
        this.request_id = res.loan.REQUEST_ID;
      }
      if (res.location == 'delete_request_mod') {
        this.loan = res.data;
        this.reopen.SUSPEND_CUSTOMER = res.data.SUSPEND_IF_FAIL_ELIGIBILITY;
        this.reopen.SUSPEND_FOR_HOW_LONG = res.data.SUSPEND_FOR_HOW_LONG;
      }
      if (res.location == 'request_to_get_paid') {
        this.loan = res.data;
      } else {
        this.loan = res.data;
      }

      if (res.location == 'set_default_repayment_mode') {
        this.loan = res.data;
        this.repayment_mode = res.repayment_mode;
      }
      if (res.location == 'reopenrequest') {
        this.loan = res.data;
      }
      if (res.location == 'cancel_debit_mandate') {
        this.account_for_direct_debit = res.acc;
      }
      if (res.location == 'resend_debit_mandate') {
        this.account_for_direct_debit = res.acc;
      }
      if (res.location == 'setup_debit_mandate') {
        this.getBanksListForCustomer(this.loan);
      }
      if (res.location == 'setup_debit_mandate_') {
        this.account_for_direct_debit = res.acc;
      }
      if (res.location == 'stop_direct_debit') {
        this.account_for_direct_debit = res.acc;
      }
      if (res.location == 'upload_bank_statement') {
        this.attachment_for_analysis = res.attachment;
        this.request_id = res.request_id;
        this.overlayModalSection = res.location;
      }
    });
    
  }
  ngOnInit() {
    // let showpopup = this.storageService.read<any>('test_mode');
    // if (showpopup === 1) {
    //   this.simulateSection = 'default';
    //   this.overlayModalSection = 'onboard';
    // }
    // this.optionsService
    //   .getOccupation(2)
    //   .subscribe(sectors => (this.sectors = sectors));
    // this.optionsService
    //   .getOccupation(1)
    //   .subscribe(sectors => (this.occupations = sectors));
    // this.optionsService
    //   .getOccupation(4)
    //   .subscribe(sectors => (this.designations = sectors));
    // this.optionsService
    //   .getCompanies(this.currentUser.token)
    //   .subscribe(companies => (this.companies = companies));
    // this.productService
    //   .getLoanProducts(this.currentUser.token)
    //   .subscribe(companies => (this.products = companies));
    // this.getLoans();
    // this.checkActivation();
  }
  getLoanLink(status) {
    this.filteringLoans = true;
    this.magic_filter.loan_status = status;
    this.getLoans();
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  selectPlan(plan){
    this.go_live_details.plan = plan;
  }
  doingDisbursement(event) {

    event.new_loan = this.loan;
    console.log(event)
    this.dataService.disbursingRequest.emit(event);
  }
  openModal(section) {
    if (section == 'location') {
      if (this.test_mode == '1') {
        this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode', request_id: this.loan.request_id, loan: this.loan });
      }
    } else {
      this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.loan.request_id, loan: this.loan });
      console.log(this.loan)
    }
  }
  chooseKYCPlan() {
    this.choosingKYC = true;
    this.islarger = true;
  }
  getBanksListForCustomer(loan) {
    this.loansService
      .getBanksListForCustomer(this.currentUser.token, this.request_id)
      .subscribe(data => {
        this.banks = data.banks;
      });
  }
  displayCondition(i) {
    // if (i <= 2) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  }
  checkLevel(sector, event, index) {
    this.durations[index]['checked'] = event;
  }
  checkADay(sector, event, index) {
    this.adays[index]['checked'] = event;
  }
  sendKYCRequest(KYC_PACKAGE) {
    this.kyc_request.KYC_PACKAGE = KYC_PACKAGE;
    this.loading = true;
    this.loansService
      .sendKYCRequest(
        this.currentUser.token,
        this.kyc_request.REQUEST_ID,
        this.kyc_request
      )
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
        this.dataService.kycRequestFinished.emit();
      });
  }
  checkActivation() {
    const lender_activated = this.storageService.read<any>('lender_activated');
    const test_mode = this.storageService.read<any>('test_mode');
    if (test_mode == '0' && lender_activated == '0') {
      this.overlayModalSection = 'awaiting_documents';
    }
    if (test_mode == '1') {
      this.getSlimAppSettings();
    }
  }
  showProfilePic(pic) {
    this.dataService.showProfilePic.emit({ pic: pic });
  }
  getSlimAppSettings() {
    this.operationsService
      .getSlimAppSettings(this.currentUser.token)
      .subscribe(data => {
        this.banks = data.banks;
      });
  }
  onScrollDown(ev) {
    if(this.loans.complete == false){
      this.loadMoreRecords(this.magic_filter.start);
    }
    
  }
  closeChildModal() {
    this.childModal.location = '';
  }
  loadMoreRecordsAll(start){
    this.filteringLoans = true;
    this.magic_filter.reset = true;
    this.magic_filter.start = start;
    // this.getLoans()
    this.searchView = false;
    this.magic_filter.token = this.currentUser.token;
    this.adding_more = true;
    if (this.view_status == 'requests') {
      this.loansService
        .filtering_loans(
          this.incomes,
          this.designations,
          this.occupations,
          this.adays,
          this.currentUser.token,
          this.magic_filter,
          this.sectors,
          this.approval_levels,
          this.statuses,
          this.durations,
          this.request_date,
          this.ages,
          this.marital_statuses
        )
        // this.loansService.getLoans(this.magic_filter)
        .subscribe(customers => {
          this.adding_more = false;
          this.loans = customers;
          this.magic_filter.start = customers.start;
        });
    } else if (this.view_status === 'approvals') {
      this.loansService
        .getMyApprovals(this.currentUser.token, this.magic_filter)
        // this.loansService.getLoans(this.magic_filter)
        .subscribe(customers => {
          this.adding_more = false;
          this.loans = customers;
          this.magic_filter.start = customers.start;
        });
    }
  }
  loadMoreRecords(start) {
    this.magic_filter.start = start;
    // this.getLoans()
    this.searchView = false;
    this.magic_filter.token = this.currentUser.token;
    this.adding_more = true;
    if (this.view_status == 'requests') {
      this.loansService
        .filtering_loans(
          this.incomes,
          this.designations,
          this.occupations,
          this.adays,
          this.currentUser.token,
          this.magic_filter,
          this.sectors,
          this.approval_levels,
          this.statuses,
          this.durations,
          this.request_date,
          this.ages,
          this.marital_statuses
        )
        // this.loansService.getLoans(this.magic_filter)
        .subscribe(customers => {
          this.adding_more = false;
          this.loans = customers;
          this.magic_filter.start = customers.start;
        });
    } else if (this.view_status === 'approvals') {
      this.loansService
        .getMyApprovals(this.currentUser.token, this.magic_filter)
        // this.loansService.getLoans(this.magic_filter)
        .subscribe(customers => {
          this.adding_more = false;
          this.loans = customers;
          this.magic_filter.start = customers.start;
        });
    }
  }
  getLoans() {
    this.searchView = false;
    this.loading = true;
    this.magic_filter.token = this.currentUser.token;
    this.loansService
      .filtering_loans(
        this.incomes,
        this.designations,
        this.occupations,
        this.ages,
        this.currentUser.token,
        this.magic_filter,
        this.sectors,
        this.approval_levels,
        this.statuses,
        this.durations,
        this.request_date,
        this.adays,
        this.marital_statuses
      )

      .subscribe(customers => {
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
        this.miniSearch = false;
        this.searchView = false;
      });
  }
  getApprovals() {
    this.view_status = 'approvals';
    this.searchView = false;
    this.loading = true;
    this.magic_filter.token = this.currentUser.token;
    this.loansService
      .getMyApprovals(this.currentUser.token, this.magic_filter)

      .subscribe(customers => {
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
      });
  }
  openSearchView() { }
  enter() { }
  leave() { }
  searchForLoans() {
    this.loading = true;
    this.loansService
      .searchForLoans(this.currentUser.token, this.magic_filter)
      .subscribe(customers => {
        this.loading = false;
        this.loans = customers;
      });
    // this.getLoans();
  }
  loadRequest(request) {
    let url = this.router.url;
    this.activeRequestId = request.request.REQUEST_ID;
    if (
      url == '/applications/dashboard' ||
      url == '/applications/advancesearch' ||
      url == '/applications/approvals'
    ) {
      this.router.navigate([
        './applications/pending/' + request.request.REQUEST_ID
      ]);
    } else {
      const tokens = url.split('/');
      if(tokens.indexOf('analytics') != -1){
        url = './applications/analytics/' + request.request.REQUEST_ID;
        this.router.navigate([url]);
      }else{
        tokens.splice(-1, 1);
        url = '.' + tokens.join('/') + '/' + request.request.REQUEST_ID;
        this.router.navigate([url]);
      }
      
    }
  }
  openRequest(request) {
    this.show_approval_calendar = false;
    this.loadRequest(request)
  }
  goLiveNow() {
    this.loading = true;
    this.authService.goLiveNow(this.currentUser.token, this.go_live_details).subscribe(customers => {
      this.loading = false;
      localStorage.setItem('test_mode', '0');
      this.overlayModalSection = '';
      window.location.reload();
    });
  }
  clearBVN(event) {
    this.customerService
      .retryBVNValidation(this.currentUser.token, event.customer)
      .subscribe(data => {
        this.loading = false;
      });
  }
  retryBVN(event) {
    this.customerService
      .retryBVNValidation(this.currentUser.token, event.customer)
      .subscribe(data => {
        this.loading = false;
      });
  }
  rejectThisRequest(event) {
    this.model_r = event.model_r;
    this.model_r.request_id = this.loan.request_id;
    this.model_r.level = this.loan.approval_level;
    this.loading = true;
    this.model_r.level = this.loan.approval_level;
    this.loansService
      .rejectThisRequest(this.currentUser.token, event.model_r)
      .subscribe(loans => {
        this.open_approval = !this.open_approval;
        this.loading = false;

        this.dataService.refreshPage.emit();
      });

  }
  cancelThisApproval() {
    this.open_approval = false;
  }
  approveThisRequest(event) {
    this.model_a = event.model_a;
    this.model_a.request_id = this.loan.request_id;
    this.model_a.level = this.loan.approval_level;
    this.loansService
      .approveThisRequest(this.currentUser.token, this.model_a)
      .subscribe(loans => {
        this.dataService.refreshPage.emit();
        this.dataService.approvalDone.emit();
        this.loading = false;
        if (loans.status) {
          this.open_approval = !this.open_approval;
          this.loading = false;

          this.open_approval = !this.open_approval;
        } else {
          if (loans.message) {
            this.showError(loans.message)
          }
        }
        this.open_approval = !this.open_approval;
      });
  }
  navigateToWebSimulate() {
    //let appKey = this.storageService.read<any>('appKey');
    window.open(
      'http://redirect.700lenders.com/?src='+this.appKeys+'&CC.wc_lc=launch-widget',
      '_blank'
    );
  }
  navigateToUssdSimulate() {
    let appKey = this.storageService.read<any>('appKey');
    window.open(
      'http://redirect.700lenders.com/?src='+appKey+'&CC.wc_lc=launch-widget',
      '_blank'
    );
  }
  navigateToChatBotSimulate() {
    this.loansService.getAppKey(this.currentUser.token).subscribe(data => {
      window.open(
        'https://cb.creditclan.com/?src=' + data.data.appKey,
        '_blank'
      );
    });
  }
  acceptBorrowerContract() {
    this.loading = true;
    this.loansService
      .acceptContract(this.currentUser.token, this.loan.request_id)
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
      });
  }
  runCreditCheck() {
    this.loading = true;

    // this.sub = this.route.params.subscribe(params => {
    //   const parentRouteId = +params['id'];
    //   this.loading = true;

    // });
    this.loansService
      .runCreditCheck(
        this.currentUser.token,
        this.request_id,
        this.bureau_selected
      )
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        // if (data.status == false) {
        //   this.showError(data.message)
        // } else {
        //   this.showSuccess('Successful');
        // }
        // this.DataService.reloadCreditCheck.emit();
      });
  }
  sendBVN() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';

    this.operationsService.sendBVNRequest(
      this.currentUser.token,
      this.loan.request_id
    );
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  requestCard() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendCardRequest(
      this.currentUser.token,
      this.loan.request_id
    );
  }
  inviteForAnalytics() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.inviteForAnalytics(
      this.currentUser.token,
      this.loan.request_id
    );
  }
  sendForOtherDocuments() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendForOtherDocuments(
      this.currentUser.token,
      this.loan.request_id,
      this.other_docs
    );
  }
  sendForGuarantors() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendForGuarantors(
      this.currentUser.token,
      this.loan.request_id,
      this.guarantors_request
    );
  }
  confirmBankAccount() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendAccountConfirmationRequest(
      this.currentUser.token,
      this.loan.request_id
    );
  }
  deleteRequest() {
    this.loading = true;
    this.loansService
      .sendDeleteRequest(
        this.currentUser.token,
        this.loan.request_id,
        this.rejection
      )
      .subscribe(data => {
        this.loading = false;
        this.is_done = '1';
        this.closeChildModal();
      });
  }
  resetFilters() {
    this.filteringLoans = true;
    this.magic_filter.reset = true;
    this.getLoans();
  }
  update(slider, event) {
    slider.onUpdate = event;
    this.magic_filter.min = event.from;
    this.magic_filter.max = event.to;
  }
  update_(slider, event) {
    this.magic_filter.funding = event.from;
  }
  update__(slider, event) {
    this.magic_filter.min = this.min_;
    this.magic_filter.max = event.from;
  }
  continueBSAnalysis() {
    // this.loading = true;
    // this.analyticsService
    //   .doBankStatementAnalysis(
    //     this.currentUser.token,
    //     this.loan,
    //     this.bsrequest,
    //     this.statement
    //   )
    //   .subscribe(data => {
    //     this.loading = false;
    //     if (data.status == true) {
    //       this.showSuccess(data.message);
    //       this.dataService.analysisComplete.emit();
    //     } else {
    //       this.showError(data.message);
    //     }
    //   });
  }
  changeSmsValue() {
    this.sms_value = this.sms_value ? '1' : '';
  }
  changeCallLogsValue() {
    this.call_logs_value = this.call_logs_value ? '1' : '';
  }
  changeSocialValue() {
    this.social_value = this.social_value ? '1' : '';
  }
  changeProfileValue() {
    this.profile_value = this.profile_value ? '1' : '';
  }
  changeGuarantorValue() {
    this.guarantor_value = this.guarantor_value ? '1' : '';
  }
  changeRepaymentValue() {
    this.repayment_value = this.repayment_value ? '1' : '';
  }
  changeCardValue() {
    this.cards_value = this.cards_value ? '1' : '';
  }
  changeAccountValue() {
    this.accounts_value = this.accounts_value ? '1' : '';
  }
  changeDdValue() {
    this.dd_value = this.dd_value ? '1' : '';
  }
  runAnalytics() {
   
  }
  getFullAnalytics() {
    
  }
  ngOnDestroy(): void {
    this.activeRequestId = null;
  };
  openSettingsModel(bureau){
    this.dataService.updatingSettingsExternally.emit({bureau:bureau,section:bureau});
  }
  closeDrawer() {
    this.drawer_request =false;
    this.drawer_portfolio =false;
    this.drawer = false;
    this.activeRequestId = null;
    this.loan = null;
  }
   
  approveThisLoan(event) {
    event.loan = this.loan;
    this.dataService.approvingRequest.emit(event);
  } rejectThisLoan(event) {
    event.loan = this.loan;
    this.dataService.rejectingRequest.emit(event);
  }
   
}


 