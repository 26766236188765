<div>
  <ul>
    <li class="simulate_sidebar">
      <a [routerLink]="['/simulate/web']">Web Request</a>
    </li>
    <li class="simulate_sidebar">
      <a [routerLink]="['/simulate/mobile']">Mobile App </a>
    </li>
    <li class="simulate_sidebar"><a [routerLink]="['/simulate/ussd']">USSD </a></li>
    <li class="simulate_sidebar">
      <a [routerLink]="['/simulate/backend']">Admin Backend </a>
    </li>
  </ul>
</div>
