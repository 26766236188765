import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomersService } from '../../_services/customers.service';
import { DataService, AuthenticationService } from '../../_services/index';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-customerotherrequestss',
    templateUrl: './customerotherrequestss.component.html',
    styleUrls: ['./customerotherrequestss.component.css']
})
export class CustomerotherrequestssComponent implements OnInit {

    public canViewModule = true;
    @Input('loans') loans = [];
    @Input('resp') resp = {};
    sub; // Instance of the route subscription
    @Input('userType') userType; // Type of user
    @Input('userId') userId; // User Id
    @Input('request_id') request_id; // User Id
    @Input('external') external = false;
    start = 0;
    data: any;
    @Input('loading') loading = false;
    @Input() activeRequestId = null;

    constructor(public authService: AuthenticationService, public route: ActivatedRoute,
        public DataService: DataService,
        private location: Location,
        protected customersSrvc: CustomersService, private router: Router) { }

    // Load the basic information on navigation to this page
    ngOnInit() {
        if(!this.external){

            // if (!this.authService.canViewModule('1,3,5,1026')) {
            //     this.canViewModule = false;
            // }
            this.loading = true;
            this.sub = this.route.parent.params.subscribe(params => {
                this.loading = false;
                this.userType = params["type"];
                this.userId = params["id"];
                this.load_records();
            });

        }else{
            this.load_records();
        }
        
    }

    openRequest(id) {
        this.activeRequestId = id;
    }

    closeRequest() {
        this.activeRequestId = null;
    }

    about_contract(request_id) {
        this.router.navigate(['../statement/' + request_id + '/schedule']);
    }

    load_records() {
        this.loading = true;
        this.customersSrvc.getLoansForCustomers(this.userId, this.start, this.request_id).subscribe(data => {
            this.loading = false;
            this.resp = data;
            this.loans = data.loans;
            this.data = data;

            // Publish section
            this.DataService.onProfileNav.emit({ 'location': 'loans', 'data': data });
        });
    }
    loadMoreRecords(start) {
        this.start = start;
        this.load_records();
    }
    bookLoan() {
        this.DataService.bookanewloan.emit();
    }
    openPending(request_id) {
        this.router.navigate([
            './applications/pending/' + request_id
        ]);
    }
    openPortfolio(request_id) {
        this.router.navigate([
            './portfolio/request/' + request_id
        ]);
    }
    goBack() {
        this.location.back();
    }
}
