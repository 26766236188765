import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.css']
})
export class AddmemberComponent implements OnInit, AfterViewInit {
  view: any = '';
  alias: string;
  primary_email: any = JSON.parse(localStorage.getItem('PHONE'));
  email: string;
  phone_number: string;
  primary_phone_number: any = JSON.parse(localStorage.getItem('PHONE'));
  role: any;
  name: string;
  errorMessage: any;


  constructor(
    private router: Router,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.getSavedItems();
    //console.log('hello');
  }

  ngAfterViewInit(): void {
    this.getSavedItems();
    //console.log('hello world');
  }

  getSavedItems() {
    const items = localStorage.getItem('setup');
    this.primary_phone_number = items;
    this.primary_email = items;
    //console.log(items, this.primary_email, this.primary_phone_number);
  }

  next() {
    this.view = 'add_member';
  }

  sendInvite() {
    this.view = 'add_more';
    //console.log('sent');
  }

  submit() {
    this.router.navigate(['/bookloan']);
  }
}
