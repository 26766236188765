import { Component, OnInit, Input } from '@angular/core';
import { LoansService, StorageService } from 'src/app/_services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pastloans',
  templateUrl: './pastloans.component.html',
  styleUrls: ['./pastloans.component.css']
})
export class PastloansComponent implements OnInit {
  @Input() reqid;
  @Input() people_id;
  @Input() showing;
  loading = false;
  currentUser: any;
  data: any;
  fcn:any;
  constructor(
    public loansService: LoansService,
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.fetchPastLoans();
  }

  fetchPastLoans() {
    this.loading = true;
    this.loansService.getPastLoans(this.currentUser.token, this.people_id).subscribe((data) => {
      this.data = data.data;
      this.loading = false;
    })
  }
  fetchFCN() {
    this.loading = true;
    this.loansService.fetchFCN(this.currentUser.token, this.people_id).subscribe((data) => {
      this.fcn = data.data;
      this.loading = false;
    })
  }

}
