<div class="row">


    <div class="col-md-4">
        <img alt="" src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{customer?.FILE_NAME | imagenotfound}}"
            style=" width: 150px; border-radius: 50%; display: block; margin: 30px auto 0; ">
    </div>


    <div class="col-md-8">

        <div class="pop_nav">
            <ul class="list-inline text-right">
                <li>
                    <a (click)="viewing='default'" [ngClass]="{'active': viewing=='default'}"> Profile</a>
                </li>
                <li>
                    <a (click)="viewing='work_info'" [ngClass]="{'active': viewing=='work_info'}"> Income</a>
                </li>
                <li>
                    <a (click)="viewing='others'" [ngClass]="{'active': viewing=='others'}"> Others</a>
                </li>
                <li>
                    <a (click)="viewing='ineligible'" *ngIf="loan_status=='-3'" [ngClass]="{'active': viewing=='ineligible'}">
                    Ineligibility Reasons</a>
                </li>
            </ul>
        </div>
        <div class="address_info" *ngIf="viewing=='ineligible'">
            <div style="">
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.address_state==true">
                    <span class="pull-left text-primary">Address State</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.address_state==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.marital_status==true">
                    <span class="pull-left text-primary">Marital Status</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.marital_status==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                 
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.min_age==true">
                    <span class="pull-left text-primary">Minimum Age</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.min_age==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.max_age==true">
                    <span class="pull-left text-primary">Maximum Age</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.max_age==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.salary==true">
                    <span class="pull-left text-primary">Salary</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.salary==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.occupation==true">
                    <span class="pull-left text-primary">Occupation</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.sector==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group" *ngIf="customer?.ineligibility_reasons.profile.sector==true">
                    <span class="pull-left text-primary">Sector</span>
                    <span class="pull-right">
                        {{customer?.ineligibility_reasons.profile.sector==true?'failed':''}}
                    </span>
                    <div class="clearfix"></div>
                </div> 
            </div>
        </div>
        <div class="address_info" *ngIf="viewing=='default'">
            <div style="">
                <div class="form-group">
                    <span class="pull-left text-primary">Fullname</span>
                    <span class="pull-right">
                        {{customer?.LEGAL_NAME}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group">
                    <span class="pull-left text-primary">Age</span>
                    <span class="pull-right">
                        {{customer?.AGE}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <span class="pull-left text-primary">Gender</span>
                    <span class="pull-right">
                        {{customer?.GENDER}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group">
                    <span class="pull-left text-primary">Marital Status</span>
                    <span class="pull-right">
                        {{customer?.MARITAL_STATUS}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">National</span>
                    <span class="pull-right">
                        {{customer?.NATION}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary">Email</span>
                    <span class="pull-right">
                        {{customer?.EMAIL}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary">Phone</span>
                    <span class="pull-right">
                        {{customer?.PHONE}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary">Address</span>
                    <span class="pull-right">
                        {{customer?.HOME_ADDRESS}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="address_info" *ngIf="viewing=='work_info'">
            <div style="">
                <div class="form-group">
                    <span class="pull-left text-primary">Occupation</span>
                    <span class="pull-right">
                        {{customer?.DESCRIPTION}}
                    </span>
                    <div class="clearfix"></div>
                </div>
                <div class="form-group">
                    <span class="pull-left text-primary">Sector</span>
                    <span class="pull-right">
                        {{customer?.WORK_SECTOR_D}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Net Monthly Income</span>
                    <span class="pull-right">
                        NGN{{customer?.NET_MONTHLY_INCOME|number:'.2-2'}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary">Work Address</span>
                    <span class="pull-right">
                        {{customer?.WORK_ADDRESS}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary">Company</span>
                    <span class="pull-right">
                        {{customer.COMPANY_OF_WORK == null ? 'No Data' : customer.COMPANY_OF_WORK}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="address_info" *ngIf="viewing=='others'">
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Next of Kin Name</span>
                    <span class="pull-right">
                        {{customer.NOK_NAME == null ? 'No Data' : customer.NOK_NAME}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Next of kin phone</span>
                    <span class="pull-right">
                        {{customer.NOK_PHONE == null ? 'No Data' : customer.NOK_PHONE}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Education</span>
                    <span class="pull-right">{{customer?.EDUCATION_INSTITUTION}}</span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Year</span>
                    <span class="pull-right">{{customer?.QUALIFICATION_YEAR}}</span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Cards</span>
                    <span class="pull-right">0</span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Accounts</span>
                    <span class="pull-right">0</span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                <div class="form-group">
                    <span class="pull-left text-primary ">Guarantors</span>
                    <span class="pull-right">0</span>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

    </div>

</div>