import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OperationsService, StorageService, LoansService, DecisionService, DataService } from '../../_services/index';
import { OptionsserviceService } from '../../_services/optionsservice.service';
import { ProductsService } from '../../_services/products.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-requestloanproduct',
  templateUrl: './requestloanproduct.component.html',
  styleUrls: ['./requestloanproduct.component.css']
})
export class RequestloanproductComponent implements OnInit {
  @Input() loan: any;
  products: any;
  currentUser: any;
  DISBURSEMENT_DATE = '';
  transaction_id = '';
  LOAN_PRODUCT_IDD = '';
  constructor(public toastr: ToastrService, public productsService: ProductsService,
    public loansService: LoansService, public optionsService: OptionsserviceService,
    private router: Router, public storageService: StorageService, private dataService: DataService,
    public operationsService: OperationsService,
    public decisionService: DecisionService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.refreshPage.subscribe(() => {
      this.getProducts();
    });

  }

  ngOnInit() {
    this.getProducts(); 
  }
  getProducts() {
    this.productsService.getLoanProductsWithoutDefault(this.currentUser.token)
      .subscribe(data => {

        this.products = data.products;
      });
  }
  saveBio() {
    this.loansService.changeLoanrequestProduct(this.currentUser.token, this.loan)
    .subscribe(data => {
      this.showSuccess("Request Successful")
    });


  }
  saveBios() {
    this.loansService.changeLoanrequestProducts(this.currentUser.token, this.loan, this.LOAN_PRODUCT_IDD)
    .subscribe(data => {
      this.showSuccess("Request Successful")
    });


  }
  saveCard(){
    this.loansService.saveCard(this.currentUser.token, this.loan, this.transaction_id)
    .subscribe(data => {
      this.showSuccess("Request Successful")
    });


  }
  saveDD() {
    this.loansService.updateDD(this.currentUser.token, this.loan)
    .subscribe(data => {
      this.showSuccess("Request Successful")
    });


  }
  showSuccess(message) {
    this.toastr.success(message, 'Success');
  }
  showError(message) {
    this.toastr.error(message, 'Error');
  }
}
