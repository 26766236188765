<div *ngIf="data?.loan?.IS_CLOSED != '1'">
  <div *ngIf="data?.TOTAL_INDEBTED_DEBIT > 0">
    <!-- <p>Due: {{ data?.TOTAL_INDEBTED_DEBIT | number: '.2-2' }}</p> -->
    <ul *ngIf="!buttonOnly">
      <li class="ellipsize"><i class="fa fa-money mr-2"></i>Due: {{ data?.TOTAL_INDEBTED_DEBIT | number: '.2-2' }}</li>
    </ul>
    <app-button (click)="doPayDue()" label="Pay Due" class="small teal" [loading]="loading" [disabled]="loading">
    </app-button>
    <!-- <app-button (click)="initiatestatement()" [loading]="loading_statement" class="small green mb-1 mr-1" label="Initiate Statement"></app-button>  -->
    <app-button (click)="getpayload()" [loading]="loading_statement" class="small green mb-1 mr-1" label="Get Payload"></app-button> 
  </div>
</div> 