import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

import { RemitaService } from '../../_services/remita.service';
@Component({
  selector: 'app-remitainflight',
  templateUrl: './remitainflight.component.html',
  styleUrls: ['./remitainflight.component.css']
})
export class RemitainflightComponent implements OnInit {
  phonetype = '';
  sending_account_request = false;
  has_default_card = false;
  @Input('parentRouteId') parentRouteId: any;
  @Input('loan') loan: any;
  @Input('where') where = 'remita';
  @Input('token') token: any;
  @Input() scroll = true;
  accounts: any;
  accounts_added = 0;
  is_done = '0';
  loading = false;
  account: any;
  sending_direct_request = false;
  PASSWORD = '';
  checking_mandate_status = false;
  resending_link = false;
  cancelling_dd = false;
  loadingData = false;
  currentUser: any;
  customer: any;
  showSchedule = false;
  refreshingInfo = false;
  has_remita_loan = false;
  constructor(private toastr: ToastrService, private DataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public router: Router,
    public remitaService: RemitaService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() { 
    this.getCustomerDetails();
  }
  getCustomerDetails() {
    this.loadingData = true;
    this.loansService.getLoanRemitaCustomer(this.currentUser.token, this.loan.people_id, this.loan.REQUEST_ID)
      .subscribe(data => {
        this.loadingData = false;
        this.customer = data.data;
        this.has_remita_loan = data.data.has_remita_loan;
        this.loading = false;
      });
  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  refreshRemitaInformation() {
    this.loading = true;
    this.remitaService.getNewRemitaRecords_(this.currentUser.token, this.loan.REQUEST_ID, this.phonetype)
      .subscribe(data => {
        this.loading = false;
        if(data.status){
          if(data.status == true){
            this.showSuccess("Remita information refreshed");
            this.loading = false;
            this.refreshingInfo = false;
            this.getCustomerDetails();
          }else{
            this.showError("Error processing request");
          }
        }else{

          if(!data.response){
            this.showError("Error processing request");
          }else{
  
            if(!data.response.hasData){
              this.showError(data.response.responseMsg);
            }
            this.loading = false;
            this.refreshingInfo = false;
            this.getCustomerDetails();
  
          }

        }
        
        

      });
  }
}
