import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';

@Component({
  selector: 'app-applicationsindebtedness',
  templateUrl: './applicationsindebtedness.component.html',
  styleUrls: ['./applicationsindebtedness.component.css']
})
export class ApplicationsindebtednessComponent implements OnInit {

  where_from = 'offer_letter'
  view = 'master';
  text = 'Offer Letter';
  @Input() requestId;
  currentUser: any;
  parentRouteId: any;
  sub: any;
  loan: any;
  loan_: any;
  creating_contract = false;
  has_workflow = false;
  printing: boolean;
  updateSection = '';
  offerLetter = null;
  loaded = false;
  loading = false;
  preview = false;
  constructor(private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    this.getSummary();
  }
  getSummary() {
    this.view = 'master';
    this.loan = this.loan_;
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
      this.getLoan();
    });

  }
  sendContractDocs() {
    this.dataService.sendIndebtednessLetter.emit({ 'location': 'send_contract_mod', data: this.loan });
  }
  getLoan() {
    this.loading = true;
    this.loansService.loan_contract(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loan = data.loan;
        this.handleOfferLetter(data)
        //this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });
        this.loading = false;
      });
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId });
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.dataService.disbursingRequest.emit(event);
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  printTheOfferLetter() {
    this.printing = true;
    this.currentUser = this.storageService.read<any>('currentUser');

    this.loansService.printTheOfferLetter(this.currentUser.token, this.parentRouteId, this.loan)
      .subscribe(data => {
        this.printing = false;
        window.open(data.link);
      });
  }
  gotomaster(event) {
    this.view = 'master';
  }
  editsec(event) {
    this.view = 'slave';
    this.updateSection = event.section;
  }
  handleOfferLetter(data) {
    this.loaded = true;
    this.offerLetter = data;
  }

}
