<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{ pr_0 : embeded }">
    <app-contractheader *ngIf="from_where =='self' && !requestId" [loan]="loan" [request_id]="parentRouteId">
    </app-contractheader>
    <div class="panel padding-regular" *ngIf="from_where == 'self'">
      <div class="flex justify-between align-center">
        <h3 class="section-title">Collateral</h3>
        <app-button (click)="showAddModal()" icon="fa fa-plus" label="Add New" class="small outline blue"></app-button>
      </div>
    </div>
    <div *ngIf="loading" style="height: 100vh">
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="panel padding-regular" *ngIf="!has_data">
        <div class="h-group center mb-4 padded empty-state">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title">No collateral</h2>
          <p class="subtitle">There is no collateral to show at the moment</p>
          <div class="text-center mt-2">
            <app-button label="New collateral" class="small outline" (click)="showAddModal()" icon="fa fa-plus">
            </app-button>
          </div>
        </div>
      </div>

      <div class="panel" *ngIf="has_data">
        <div class="customers">
          <div *ngFor="let collateral of collaterals">
            <div class="customer">
              <div class="header">
                <!-- <a class="img-wrap cursor-pointer">
                  <img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{customer?.FILE_NAME | imagenotfound}}">
                </a> -->
                <div class="content">
                  <div class="name cursor-pointer">{{ collateral.request_data.other_name }}</div>
                  <div class="type">{{collateral.collateral_type_id|collateral}}</div>
                </div>
                <div data-target="test" class="toggle flex-center">
                  <!-- <div>
                    <span class="tag red small">Tag</span>
                  </div> -->
                  <button class="icon-button ml-2" type="button" data-toggle="collapse"
                    [attr.data-target]="'#'+collateral.LOAN_COLLATERAL_DOCS_ID" aria-expanded="false"
                    aria-controls="collapseExample">
                    <i class="fa fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="body collapse padding-regular" [ngClass]="from_where != 'self'? 'collapse in': ''"
              [id]="collateral.LOAN_COLLATERAL_DOCS_ID">
              <div *ngIf="collateral.collateral_type_id == '1'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Documents Held</span>
                          <span>{{collateral.request_data.land_document_held}}</span>
                        </li>
                        <li>
                          <span>Ownership Type</span>
                          <span>{{collateral.request_data.land_owner_type}}</span>
                        </li>
                        <li>
                          <span>Name of Owner</span>
                          <span>{{collateral.request_data.land_owner_name}}</span>
                        </li>

                        <li>
                          <span>Address</span>
                          <span>{{collateral.request_data.land_owner_address}}</span>
                        </li>
                        <li>
                          <span>State</span>
                          <span>{{collateral.request_data.land_owner_state}}</span>
                        </li>
                        <li>
                          <span>Price Bought</span>
                          <span>{{collateral.request_data.land_owner_price|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>No of Plots</span>
                          <span>{{collateral.request_data.no_of_plots}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.land_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.land_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>
                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="collateral.collateral_type_id == '2'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Documents Held</span>
                          <span>{{collateral.request_data.document_held}}</span>
                        </li>
                        <li>
                          <span>Ownership Type</span>
                          <span>{{collateral.request_data.house_owner_type}}</span>
                        </li>
                        <li>
                          <span>Name of Owner</span>
                          <span>{{collateral.request_data.house_owner_name}}</span>
                        </li>

                        <li>
                          <span>Address</span>
                          <span>{{collateral.request_data.house_owner_address}}</span>
                        </li>
                        <li>
                          <span>State</span>
                          <span>{{collateral.request_data.house_owner_state}}</span>
                        </li>
                        <li>
                          <span>Price Bought</span>
                          <span>{{collateral.request_data.house_owner_price|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>House Type</span>
                          <span>{{collateral.request_data.house_owner_type}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.house_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.house_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>
                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="collateral.collateral_type_id == '3'">
                <div>
                  <form>
                    <div class="panel-body padding-regular" *ngIf="collateral.has_inspection">
                      <div class="flex">
                        <div class="flex width_0 vertical-center" style="flex-grow: 2">
                          <div class="h-group">
                            <h4 class="title large color-teal" style="font-size: 2.5rem !important;">Inspection</h4>
                          </div>
                        </div>
                        <div class="width_0 pt_0" style="flex-grow: 3">
                          <ul class="decList bigger">
                            <li>
                              <span class="text-primary">Date</span>
                              <span>{{collateral.INSPECTION_DETAILS.inspection_application.inspection_date}}</span>

                            </li>
                            <li>
                              <span class="text-primary">Time</span>
                              <span>{{collateral.INSPECTION_DETAILS.inspection_application.inspection_start_time}} -
                                {{collateral.INSPECTION_DETAILS.inspection_application.inspection_end_time}}</span>

                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li *ngIf="collateral.other_is_verified=='1'">
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Car Value(Min)</span>
                          <span>{{collateral.request_data.est_current_value|| collateral?.request_data?.vehicle_estimated_worth_below|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Car Value(Avg)</span>
                          <span>{{collateral?.request_data?.vehicle_estimated_worth_average|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Car Value(Max)</span>
                          <span>{{collateral.request_data.est_current_value_max|| collateral?.request_data?.vehicle_estimated_worth_above|number:'.2-2'}}</span>
                        </li>
                        <li *ngIf="collateral.request_data.vin">
                          <span>Vin</span>
                          <span>{{collateral.request_data.vin}}</span>
                        </li>
                        <li>
                          <span>Mileage</span>
                          <span>{{collateral.request_data.mileage}}</span>
                        </li>
                        <li>
                          <span>Year</span>
                          <span>{{collateral.request_data.year||collateral.request_data.vehicle_year}}</span>
                        </li>
                        <li>
                          <span>Make</span>
                          <span>{{collateral.request_data.make||collateral.request_data.vehicle_make}}</span>
                        </li>
                        <li>
                          <span>Model</span>
                          <span>{{collateral.request_data.model||collateral.request_data.vehicle_model}}</span>
                        </li>
                        <li>
                          <span>Trim</span>
                          <span>{{collateral.request_data.trim||collateral.request_data.vehicle_trim}}</span>
                        </li>
                        <li>
                          <span>Registration Number</span>
                          <span>{{collateral.request_data.reg_number||collateral.request_data.vehicle_registration_number}}</span>
                        </li>
                        <li>
                          <span>Registered Owner</span>
                          <span
                            *ngIf="collateral.request_data.reg_owner">{{collateral.request_data.reg_owner=='1' ? 'Yes' : 'No'}}</span>
                          <span
                            *ngIf="collateral.request_data.registered_owner">{{collateral.request_data.registered_owner=='1' ? 'Yes' : 'No'}}</span>
                        </li>
                        <li
                          *ngIf="collateral.request_data.state||collateral.request_data.vehicle_registration_state_id">
                          <span>Registered State</span>
                          <span>{{collateral.request_data.state||collateral.request_data.vehicle_registration_state_id}}</span>
                        </li>
                        <li *ngIf='collateral.request_data.vehicle_license'>
                          <span>License</span>
                          <span>{{collateral.request_data.vehicle_license}}</span>
                        </li>
                        <li *ngIf='collateral.request_data.vehicle_license_name'>
                          <span>License Name</span>
                          <span>{{collateral.request_data.vehicle_license_name}}</span>
                        </li>
                        <li
                          *ngIf='collateral.request_data.vehicle_license_expiry_date||collateral.request_data.lic_expiry'>
                          <span>License Expiry</span>
                          <span>{{collateral.request_data.lic_expiry||collateral.request_data.vehicle_license_expiry_date}}</span>
                        </li>
                        <li *ngIf='collateral.request_data.insurance'>
                          <span>Insurance Type</span>
                          <span>{{collateral.request_data.insurance|insurance}}</span>
                        </li>
                        <li *ngIf='collateral.request_data.vehicle_insurance_expiry_date'>
                          <span>Insurance Expiry</span>
                          <span>{{collateral.request_data.insurance_exp||collateral?.request_data?.vehicle_insurance_expiry_date}}</span>
                        </li>
                        <li *ngIf='collateral.request_data.ownership_type'>
                          <span>Ownership Type</span>
                          <span>{{collateral.request_data.owner_type||collateral?.request_data?.ownership_type}}</span>
                        </li>
                        <li
                          *ngIf="collateral.request_data.company_name||collateral?.request_data?.vehicle_company_name">
                          <span>Company</span>
                          <span>{{collateral.request_data.company_name||collateral?.request_data?.vehicle_company_name}}</span>
                        </li>
                        <li *ngIf="collateral.request_data.company_cac||collateral?.request_data?.vehicle_company_name">
                          <span>Company CAC</span>
                          <span>{{collateral.request_data.company_cac||collateral?.request_data?.vehicle_company_name}}</span>
                        </li>
                        <li *ngIf="collateral.request_data.depreciation_factor">
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.depreciation_factor}}</span>
                        </li>
                        <li *ngIf="collateral.request_data.comment">
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Vehicle</span>
                          <span>{{collateral.vin_lookup.vehicle}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>VIN</span>
                          <span>{{collateral.vin_lookup.vin}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Vehicle</span>
                          <span>{{collateral.vin_lookup.vehicle}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>VIN</span>
                          <span>{{collateral.vin_lookup.vin}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Mileage</span>
                          <span>{{collateral.vin_lookup.mileage}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Mean</span>
                          <span>{{collateral.vin_lookup.mean}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Certainty</span>
                          <span>{{collateral.vin_lookup.certainty}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Price(Above)</span>
                          <span>{{collateral.vin_lookup.prices.above|number:'.2-2'}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Price(Average)</span>
                          <span>{{collateral.vin_lookup.prices.average|number:'.2-2'}}</span>
                        </li>
                        <li *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <span>Price(Below)</span>
                          <span>{{collateral.vin_lookup.prices.below|number:'.2-2'}}</span>
                        </li>
                        <ng-container *ngIf="collateral.vin_lookup && collateral.vin_lookup.success">
                          <li *ngFor="let duration of collateral.vin_lookup.period">
                            <span>Period</span>
                            <span>{{duration}}</span>
                          </li>
                        </ng-container>

                      </ul>
                      <div class="flex justify-between mt-4">
                        <div class="flex">
                          <app-button class="small outline mr-1" (click)="updateCollateral(collateral)" label="Update"
                            type="button"></app-button>
                          <app-button class="small outline mr-1"
                            (click)="checkCarValuation(collateral.LOAN_COLLATERAL_DOCS_ID)" label="Check Car Valuation"
                            type="button"></app-button>
                        </div>
                        <!-- <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div> -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="collateral.collateral_type_id == '6'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Stock Owner</span>
                          <span>{{collateral.request_data.stock_owner}}</span>
                        </li>
                        <li>
                          <span>Companies in portfolio</span>
                          <span>{{collateral.request_data.companies_in_portfolio}}</span>
                        </li>
                        <li>
                          <span>Potfolio Amount</span>
                          <span>{{collateral.request_data.portfolio_amount|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Lien on sale</span>
                          <span>{{collateral.request_data.lien_on_sale|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.project_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.project_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>
                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
              <div *ngIf="collateral.collateral_type_id == '7'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Project Name</span>
                          <span>{{collateral.request_data.project_name}}</span>
                        </li>
                        <li>
                          <span>Project Owner</span>
                          <span>{{collateral.request_data.project_owner}}</span>
                        </li>
                        <li>
                          <span>Project Amount</span>
                          <span>{{collateral.request_data.project_amount|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>About Project</span>
                          <span>{{collateral.request_data.about_project}}</span>
                        </li>
                        <li>
                          <span>Cost of Implementation</span>
                          <span>{{collateral.request_data.cost_of_implementation}}</span>
                        </li>

                        <li>
                          <span>Net Amount</span>
                          <span>{{collateral.request_data.net_amount|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Maturity Date</span>
                          <span>{{collateral.request_data.maturity_date}}</span>
                        </li>
                        <li>
                          <span>Payment Date</span>
                          <span>{{collateral.request_data.payment_date}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.project_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.project_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>

                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="collateral.collateral_type_id == '8'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.other_is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Jewellry Owner</span>
                          <span>{{collateral.request_data.jewellry_owner}}</span>
                        </li>
                        <li>
                          <span>Jewellry Amount</span>
                          <span>{{collateral.request_data.jewellry_amount|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Lien on sale</span>
                          <span>{{collateral.request_data.jewellry_lien_on_sale|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.jewellry_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.jewellry_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>

                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="collateral.collateral_type_id == '9'">
                <div>
                  <form>
                    <div>
                      <ul class="decList bigger mb-4">
                        <li>
                          <span>Is Verified</span>
                          <span>{{collateral.is_verified == '1' ? 'Yes':'No'}}</span>
                        </li>
                        <li>
                          <span>Verified By</span>
                          <span>{{collateral.legal_name}}</span>
                        </li>
                        <li>
                          <span>Estimated Amount</span>
                          <span>{{collateral.request_data.other_estimated_amount|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Name</span>
                          <span>{{collateral.request_data.other_name}}</span>
                        </li>
                        <li>
                          <span>Lien on sale</span>
                          <span>{{collateral.request_data.other_lien_on_sale|number:'.2-2'}}</span>
                        </li>
                        <li>
                          <span>Picture Url</span>
                          <span>{{collateral.request_data.other_pic}}</span>
                        </li>
                        <li>
                          <span>Depreciation</span>
                          <span>{{collateral.request_data.other_depreciation_factor}}</span>
                        </li>
                        <li>
                          <span>Comments</span>
                          <span>{{collateral.request_data.comment}}</span>
                        </li>
                      </ul>

                      <div class="flex justify-between mt-4">
                        <!-- <div class="flex">
                          <app-button class="small outline mr-1" label="Assign Officer" type="button"></app-button>
                          <app-button class="small outline mr-1" label="Change Officer" type="button"></app-button>
                        </div> -->
                        <div>
                          <app-button (click)="updateCollateral(collateral)" class="small outline mr-1" label="Update"
                            type="button"></app-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="addModalVisible">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeAddModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding floating-title wide" role="document">
    <!-- <div class="title" *ngIf="!is_update">New collateral</div>
    <div class="title" *ngIf="is_update">Update collateral</div> -->
    <div class="overpanel-scroll padding-regular">
      <div class="overpanel-center">
        <div class="grid three" *ngIf="view === 'select'">
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('2')">
            <div class="icon color-red"><i class="fas fa-home"></i></div>
            <div class="text">House</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('3')">
            <div class="icon color-blue"><i class="fas fa-car"></i></div>
            <div class="text">Car</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('1')">
            <div class="icon color-green"><i class="fas fa-map-signs"></i></div>
            <div class="text">Land</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('6')">
            <div class="icon color-yellow"><i class="fas fa-adjust"></i></div>
            <div class="text">Shares/Stock/Bonds</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('7')">
            <div class="icon color-purple"><i class="fas fa-book"></i></div>
            <div class="text">Invoice</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('8')">
            <div class="icon color-orange"><i class="fas fa-ring"></i></div>
            <div class="text">Jewellry</div>
          </div>
          <div class="panel icon-text medium cursor-pointer pd_15" (click)="select('9')">
            <div class="icon color-teal"><i class="fas fa-lock"></i></div>
            <div class="text">Other securities</div>
          </div>
        </div>

        <form #rsvpForm="ngForm" *ngIf="view === 'form'" (ngSubmit)="handleSubmit(rsvpForm)">
          <button class="btn btn-outline-secondary btn-sm mb-4" (click)="view = 'select'"><i
              class="fa fa-arrow-left mr-2"></i>Select type</button>
          <input type="hidden" class="form-control" name="LOAN_COLLATERAL_DOCS_ID" #LOAN_COLLATERAL_DOCS_ID="ngModel"
            [(ngModel)]="collateral.LOAN_COLLATERAL_DOCS_ID">
          <div *ngIf="collateral.collateral_type == '3'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Car Value (Min)</span>
                <input type="text" class="form-control" name="vehicle_estimated_worth_below"
                  #est_current_value="ngModel" [(ngModel)]="collateral.vehicle_estimated_worth_below">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Car Value (Avg)</span>
                <input type="text" class="form-control" name="vehicle_estimated_worth_average"
                  #vehicle_estimated_worth_average="ngModel" [(ngModel)]="collateral.vehicle_estimated_worth_average">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Car Value (Max)</span>
                <input type="text" class="form-control" name="vehicle_estimated_worth_above"
                  #vehicle_estimated_worth_above="ngModel" [(ngModel)]="collateral.vehicle_estimated_worth_above">

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">VIN</span>
                <input type="text" class="form-control" name="vin" #vin="ngModel" [(ngModel)]="collateral.vin">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Mileage</span>
                <input type="text" class="form-control" name="mileage" #mileage="ngModel"
                  [(ngModel)]="collateral.mileage">

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Make</span>
                <!-- <input  type="text" class="form-control" name="make" #make="ngModel"
                  [(ngModel)]="collateral.make"> -->
                <select class="form-control" (change)="getCarModels($event)" name="make" #make="ngModel"
                  [(ngModel)]="collateral.make">
                  <option value=""></option>
                  <option *ngFor="let make of makes; let i=index" [value]="make.brand">{{ make.brand }}</option>

                </select>
              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Model</span>
                <select class="form-control" name="model" #model="ngModel" [(ngModel)]="collateral.model">
                  <option [value]="0"></option>
                  <option *ngFor="let model of models; let i=index" [value]="model">{{ model }}</option>
                </select>
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Year</span>
                <!-- <input  type="text" class="form-control" name="year" #year="ngModel"
                  [(ngModel)]="collateral.year"> -->
                <select class="form-control" name="year" #year="ngModel" [(ngModel)]="collateral.year">

                  <option [value]="0"></option>
                  <option *ngFor="let key of years | caryear" [value]="key">{{key}}
                  </option>

                </select>
              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Trim</span>
                <input type="text" class="form-control" name="trim" #trim="ngModel" [(ngModel)]="collateral.trim">

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Reg Number</span>
                <input type="text" class="form-control" name="reg_number" #reg_number="ngModel"
                  [(ngModel)]="collateral.reg_number">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Reg State</span>
                <select class="form-control" name="state" #state="ngModel" [(ngModel)]="collateral.state">
                  <option [value]='0'></option>
                  <option *ngFor="let state of states" [value]='state.state_name'>{{state.state_name}}</option>
                </select>

              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Insurance</span>
              <select class="form-control" name="insurance" #insurance="ngModel" [(ngModel)]="collateral.insurance">
                <option [value]='0'></option>
                <option [value]='1'>Comprehensive</option>
                <option [value]='2'>Third Party</option>
              </select>
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Lic Expiry</span>
                <input type="date" [value]="collateral.lic_expiry" class="form-control" name="lic_expiry"
                  #lic_expiry="ngModel" [(ngModel)]="collateral.lic_expiry">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Insurance Exp</span>
                <input type="date" [value]="collateral.insurance_exp" class="form-control" name="insurance_exp"
                  #insurance_exp="ngModel" [(ngModel)]="collateral.insurance_exp">

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Registered Owner</span>
                <input type="text" class="form-control" name="reg_owner" #reg_owner="ngModel"
                  [(ngModel)]="collateral.reg_owner">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Ownership Type</span>
                <select class="form-control" name="owner_type" #owner_type="ngModel"
                  [(ngModel)]="collateral.owner_type">
                  <option [value]='0'></option>
                  <option [value]='personal'>Person</option>
                  <option [value]='company'>Company</option>
                </select>

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Company Name)</span>
                <input type="text" class="form-control" name="company_name" #company_name="ngModel"
                  [(ngModel)]="collateral.company_name">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Company CAC</span>
                <input type="text" class="form-control" name="company_cac" #company_cac="ngModel"
                  [(ngModel)]="collateral.company_cac">

              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Dep/App</span>
              <input class="form-control" type="text" name="depreciation_factor" #depreciation_factor="ngModel"
                [(ngModel)]="collateral.depreciation_factor">
            </label>
          </div>
          <div *ngIf="collateral.collateral_type == '2'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Document Held</span>
                <input type="text" class="form-control" name="document_held" #document_held="ngModel"
                  [(ngModel)]="collateral.document_held">

              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Ownership Type</span>
                <select class="form-control" name="house_owner_type" #house_owner_type="ngModel"
                  [(ngModel)]="collateral.house_owner_type">
                  <option [value]='0'></option>
                  <option [value]='self'>Self</option>
                  <option [value]='family'>Family</option>
                  <option [value]='business'>Business</option>
                  <option [value]='others'>Others</option>
                </select>

              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Name of Owner</span>
                <input type="text" class="form-control" name="house_owner_name" #house_owner_name="ngModel"
                  [(ngModel)]="collateral.house_owner_name">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">State</span>
                <select class="form-control" name="house_owner_state" #house_owner_state="ngModel"
                  [(ngModel)]="collateral.house_owner_state">
                  <option [value]='0'></option>
                  <option *ngFor="let state of states" [value]='state.state_name'>{{state.state_name}}</option>
                </select>
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Address</span>
              <textarea type="text" class="form-control" name="house_owner_address" #house_owner_address="ngModel"
                [(ngModel)]="collateral.house_owner_address"></textarea>
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Price Bought</span>
                <input type="text" class="form-control" name="house_owner_price" #house_owner_price="ngModel"
                  [(ngModel)]="collateral.house_owner_price">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">House Type</span>
                <input type="text" class="form-control" name="house_owner_type" #house_owner_type="ngModel"
                  [(ngModel)]="collateral.house_owner_type">
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="house_pic" #house_pic="ngModel"
                  [(ngModel)]="collateral.house_pic">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Dep/App</span>
                <input class="form-control" type="text" name="house_depreciation_factor"
                  #house_depreciation_factor="ngModel" [(ngModel)]="collateral.house_depreciation_factor">
              </label>
            </div>
          </div>
          <div *ngIf="collateral.collateral_type == '1'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Document Held</span>
                <input type="text" class="form-control" name="land_document_held" #land_document_held="ngModel"
                  [(ngModel)]="collateral.land_document_held">

              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Ownership Type</span>
                <select class="form-control" name="land_owner_type" #land_owner_type="ngModel"
                  [(ngModel)]="collateral.land_owner_type">
                  <option [value]='0'></option>
                  <option [value]='personal'>Person</option>
                  <option [value]='company'>Company</option>
                </select>
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Name of Owner</span>
                <input type="text" class="form-control" name="land_owner_name" #land_owner_name="ngModel"
                  [(ngModel)]="collateral.land_owner_name">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">State</span>
                <select class="form-control" name="land_owner_state" #land_owner_state="ngModel"
                  [(ngModel)]="collateral.land_owner_state">
                  <option [value]='0'></option>
                  <option *ngFor="let state of states" [value]='state.state_name'>{{state.state_name}}</option>
                </select>
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Address</span>
              <textarea type="text" class="form-control" name="land_owner_address" #land_owner_address="ngModel"
                [(ngModel)]="collateral.land_owner_address"></textarea>
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Price Bought</span>
                <input type="text" class="form-control" name="land_owner_price" #land_owner_price="ngModel"
                  [(ngModel)]="collateral.land_owner_price">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">No of Plots</span>
                <input type="text" class="form-control" name="no_of_plots" #no_of_plots="ngModel"
                  [(ngModel)]="collateral.no_of_plots">
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="land_pic" #land_pic="ngModel"
                  [(ngModel)]="collateral.land_pic">
              </label>
            </div>
            <label class="form-group flex-1 ml-1">
              <span class="control-label normal">Dep/App</span>
              <input class="form-control" type="text" name="land_depreciation_factor"
                #land_depreciation_factor="ngModel" [(ngModel)]="collateral.land_depreciation_factor">
            </label>
          </div>
          <div *ngIf="collateral.collateral_type == '7'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Project Name</span>
                <input type="text" class="form-control" name="project_name" #project_name="ngModel"
                  [(ngModel)]="collateral.project_name">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Project Owner</span>
                <input type="text" class="form-control" name="project_owner" #project_owner="ngModel"
                  [(ngModel)]="collateral.project_owner">
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Project Amount</span>
                <input type="text" class="form-control" name="project_amount" #project_amount="ngModel"
                  [(ngModel)]="collateral.project_amount">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Company of Project</span>
                <input type="text" class="form-control" name="company_of_project" #company_of_project="ngModel"
                  [(ngModel)]="collateral.company_of_project">
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">About Project</span>
              <textarea class="form-control" name="about_project" #about_project="ngModel"
                [(ngModel)]="collateral.about_project"></textarea>
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Cost of Implementation</span>
                <input type="text" class="form-control" name="cost_of_implementation" #cost_of_implementation="ngModel"
                  [(ngModel)]="collateral.cost_of_implementation">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Net Amount</span>
                <input type="text" class="form-control" name="net_amount" #net_amount="ngModel"
                  [(ngModel)]="collateral.net_amount">
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Lien on Account</span>
              <input type="text" class="form-control" name="lien_on_account" #lien_on_account="ngModel"
                [(ngModel)]="collateral.lien_on_account">
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Maturity Date</span>
                <input type="date" class="form-control" name="maturity_date" #maturity_date="ngModel"
                  [(ngModel)]="collateral.maturity_date">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Payment Date</span>
                <input type="date" class="form-control" name="payment_date" #payment_date="ngModel"
                  [(ngModel)]="collateral.payment_date">
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="project_pic" #project_pic="ngModel"
                  [(ngModel)]="collateral.project_pic">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Dep/App</span>
                <input class="form-control" type="text" name="project_depreciation_factor"
                  #project_depreciation_factor="ngModel" [(ngModel)]="collateral.project_depreciation_factor">
              </label>
            </div>
          </div>
          <div *ngIf="collateral.collateral_type == '6'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Stock Owner</span>
                <input type="text" class="form-control" name="stock_owner" #stock_owner="ngModel"
                  [(ngModel)]="collateral.stock_owner">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Companies in Portfolio</span>
                <input type="text" class="form-control" name="companies_in_portfolio" #companies_in_portfolio="ngModel"
                  [(ngModel)]="collateral.companies_in_portfolio">
              </label>
            </div>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Portfolio Amount</span>
                <input type="text" class="form-control" name="portfolio_amount" #portfolio_amount="ngModel"
                  [(ngModel)]="collateral.portfolio_amount">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Lien on Sale</span>
                <input type="text" class="form-control" name="lien_on_sale" #lien_on_sale="ngModel"
                  [(ngModel)]="collateral.lien_on_sale">
              </label>
            </div>

            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="stock_pic" #stock_pic="ngModel"
                  [(ngModel)]="collateral.stock_pic">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Dep/App</span>
                <input class="form-control" type="text" name="stock_depreciation_factor"
                  #stock_depreciation_factor="ngModel" [(ngModel)]="collateral.stock_depreciation_factor">
              </label>
            </div>
          </div>
          <div *ngIf="collateral.collateral_type == '8'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Jewellry Owner</span>
                <input type="text" class="form-control" name="jewellry_owner" #jewellry_owner="ngModel"
                  [(ngModel)]="collateral.jewellry_owner">
              </label>
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Jewellry Amount</span>
                <input type="text" class="form-control" name="jewellry_amount" #jewellry_amount="ngModel"
                  [(ngModel)]="collateral.jewellry_amount">
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Lien on Sale</span>
              <input type="text" class="form-control" name="jewellry_lien_on_sale" #jewellry_lien_on_sale="ngModel"
                [(ngModel)]="collateral.jewellry_lien_on_sale">
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="jewellry_pic" #jewellry_pic="ngModel"
                  [(ngModel)]="collateral.jewellry_pic">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Dep/App</span>
                <input class="form-control" type="text" name="jewellry_depreciation_factor"
                  #jewellry_depreciation_factor="ngModel" [(ngModel)]="collateral.jewellry_depreciation_factor">
              </label>
            </div>
          </div>
          <div *ngIf="collateral.collateral_type == '9'">
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Name</span>
                <input type="text" class="form-control" name="other_name" #other_name="ngModel"
                  [(ngModel)]="collateral.other_name">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Estimated Amount</span>
                <input type="text" class="form-control" name="other_estimated_amount" #other_estimated_amount="ngModel"
                  [(ngModel)]="collateral.other_estimated_amount">
              </label>
            </div>
            <label class="form-group">
              <span class="control-label normal">Lien on Sale</span>
              <input type="text" class="form-control" name="other_lien_on_sale" #other_lien_on_sale="ngModel"
                [(ngModel)]="collateral.other_lien_on_sale">
            </label>
            <div class="flex">
              <label class="form-group flex-1 mr-1">
                <span class="control-label normal">Picture Url</span>
                <input type="text" class="form-control" name="other_pic" #other_pic="ngModel"
                  [(ngModel)]="collateral.other_pic">
              </label>
              <label class="form-group flex-1 ml-1">
                <span class="control-label normal">Dep/App</span>
                <input class="form-control" type="text" name="other_depreciation_factor"
                  #other_depreciation_factor="ngModel" [(ngModel)]="collateral.other_depreciation_factor">
              </label>
            </div>
          </div>
          <div class="flex">
            <label class="form-group flex-1 mr-1">
              <span class="control-label normal">Is Verified</span>
              <select class="form-control" name="is_verified" #is_verified="ngModel"
                [(ngModel)]="collateral.is_verified">
                <option [value]='0'>No</option>
                <option [value]='1'>Yes</option>
              </select>
            </label>
            <label class="form-group flex-1 ml-1">
              <span class="control-label normal">Verified By</span>
              <select class="form-control" name="verified_by" #verified_by="ngModel"
                [(ngModel)]="collateral.verified_by">
                <option [value]='0'></option>
                <option *ngFor="let emp of employees" [value]='emp.people_people_id'>{{emp.legal_name}}</option>
              </select>
            </label>
          </div>
          <label class="form-group">
            <span class="control-label normal">Comment</span>
            <textarea class="form-control" name="comment" #comment="ngModel"
              [(ngModel)]="collateral.comment"></textarea>
          </label>
          <app-button type="submit" [loading]="loading" *ngIf="!is_update" class="small mt-2" label="Add"></app-button>
          <app-button type="submit" [loading]="loading" *ngIf="is_update" class="small mt-2" label="Update">
          </app-button>
        </form>
      </div>
    </div>
  </div>
</div>