<div class="card__container">
  <div class="title mb-4">{{formLevel.LEVEL_NAME}} <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
  </div>
  <div class="panel-body">
    <div style="padding-top: 20px; width: 80%; margin: 0px auto;">
      <div class="form-area animated">
        <form role="form animated" (ngSubmit)="onSubmit()" #rsvpForm="ngForm">

          <div class="form-group row">

            <label class="control-label col-md-6">Level Name</label>
            <div class="col-md-6">
              <input type="text" #LEVEL_NAME="ngModel" [(ngModel)]="formLevel.LEVEL_NAME" class="form-control" id="LEVEL_NAME" name="LEVEL_NAME"
                required>
              <div *ngIf="LEVEL_NAME.errors && LEVEL_NAME.dirty" class="small text-danger formErrors">
                <div [hidden]="!LEVEL_NAME.errors.required">
                  Level Name is
                  <strong>required</strong>.
                </div>
              </div>
            </div>

          </div>
          <div class="form-group row">

            <label class="control-label col-md-6">Level Description</label>
            <div class="col-md-6">
              <textarea #APPROVAL_LEVEL_DESCRIPTION="ngModel" [(ngModel)]="formLevel.APPROVAL_LEVEL_DESCRIPTION" class="form-control"
                id="APPROVAL_LEVEL_DESCRIPTION" name="APPROVAL_LEVEL_DESCRIPTION"></textarea>

            </div>

          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="" class="control-label">
                Executors
              </label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6" *ngFor="let emp of employees;let i = index" style="padding-right:5px !important">
                <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{emp?.LEGAL_NAME}}</span>
                    <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                            <label>
                                <input type="checkbox" name="emp_{{emp?.PEOPLE_PEOPLE_ID}}"  [(ngModel)]="emp.checked">

                            </label>
                        </div>
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="form-group row"> 
          <div class="col-md-6"> <label>Loan Officer </label> 
            <div class="checkbox pull-right"> <label> <input type="checkbox" [(ngModel)]="formLevel.ILO" name="ILO" id="ILO"> </label> </div>
          </div>
          <div class="col-md-6"> <label>Disbursement </label> 
            <div class="checkbox pull-right"> <label> <input type="checkbox" [(ngModel)]="formLevel.ISTD" name="ISTD" id="ISTD"> </label> </div>
          </div>
        </div>
        <div class="form-group row"> 
          <div class="col-md-6"> <label>Send Notification (Email) </label> 
            <div class="checkbox pull-right"> <label> <input type="checkbox" [(ngModel)]="formLevel.SNE" name="SNE" id="SNE"> </label> </div>
          </div>
           
        </div>
          <div class="form-group row">
            <div class="col-md-6">
              &nbsp;
            </div>
            <div class="col-md-6">
              <button type="submit" class="btn btn-white btn-block" [disabled]="!rsvpForm.form.valid || loading">Save <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
            </div>
          </div>




        </form>
      </div>
    </div>

  </div>
 
</div> 