import { Component, OnInit, Input, Output } from '@angular/core';
import { ConstantsService } from '../../../_services/constants.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; 
import {AuthenticationService, DataService, LoansService, OperationsService, StorageService} from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accountmodal',
  templateUrl: './accountmodal.component.html',
  styleUrls: ['./accountmodal.component.css']
})
export class AccountmodalComponent implements OnInit {
  account_name: any;
  account_number: any;
  accounts: any = [];
  accounts_added: any;
  bank_name: any;
  direct_debit_exists: boolean;
  @Input('request_id') request_id: any;
  @Input('token') token: any;
  @Input('customer_id') customer_id = '0';
  @Input('from') from = '0';
  sub: any;
  masterClosed = '0';
  slaveOpen = '0';
  loading = false;
  is_done = '0';
  sending_account_request = false;
  currentUser:any;
  nigerian_banks:any;
  userId:any;
  account_state = 'start';
  public showForm = true;
  public showSearch = false; 
  public VERIFY_STATUS = false;
  public charge_account = false;
  public destination = false;
  public bank = { LENDER_BANK_BANK_NAME: '', LENDER_BANK_ACCOUNT_NAME: '', LENDER_BANK_CODE: '', LENDER_BANK_ACCOUNT_ID: '', LENDER_ACCOUNT_NUMBER: '', LENDER_BANK_ID: '', LENDER_ACCOUNT_GL: '', LENDER_ID: '', ADDED_BY: '', DATE_ADDED: '', PARENT_GL_ID: '', DATE_MODIFIED: '' };
  public credit = { DEST_BANK_ID: '', DEST_BANK_CODE: '', DEST_ACCOUNT_NUMBER: '' };
  public otp = { CONFIRM_OTP_CODE: '', flutterChargeReference: '' };
  public fresponse;
  public confirming = true;
  public otpConfirmed = false;
  public otpHBSFC = false;
  public confirmingOTP = true;
  public lenderbanksaved = false;
  public lbHBSFS = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  private loan: any;
  link:any;
  statement_message = '';
  loading_statement = false;
  constructor(
    private toastr: ToastrService,public loansService: LoansService,
    public route: ActivatedRoute,
    private constant: ConstantsService,
    private http: HttpClient,
    private operationsService: OperationsService,
    private dataService: DataService,
    public storageService: StorageService,  private authService: AuthenticationService) {
      this.currentUser = this.storageService.read<any>('currentUser');
      this.operationsService.getNigerianBanks(this.currentUser.token).subscribe(nigerian_banks => this.nigerian_banks = nigerian_banks);
  }
  linkGenerated(data){ 
    this.link = data.url;
  }
  saveLenderBank() {

    this.loading = true; 
    this.operationsService.saveCustomerBank(this.currentUser.token, this.bank, this.userId)
      .subscribe(status => {
        this.lbHBSFS = true; 
        this.loading = false;

        if (status.status == '1') {
          this.lenderbanksaved = true;
          this.loading = false;
          this.confirming = true;
          this.confirmingOTP = false;
          this.otpConfirmed = false
          this.otpHBSFC = false
          this.lenderbanksaved = false;
          this.lbHBSFS = false;
          this.showForm = false;
          return this.getAccounts();
        } else {
          this.lenderbanksaved = false;
        }
      });
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  openSlave(REQUEST_ID) {

    this.masterClosed = REQUEST_ID;
    this.slaveOpen = REQUEST_ID;
  }
  ngOnInit() {
    return this.getAccounts();

  }

  getAccounts() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/accounts`, {
      request_id: this.request_id,
      token: this.token,from: this.from,
      customer_id: this.customer_id
    }).subscribe(response => {
      this.loading = false;
      this.accounts_added = response.count;
      this.accounts = response.data;
      this.userId = response.user_id;
      this.account_state = 'start';
    }, error => {
      //console.log(error);
    });
  }
  deleteAccount(account_card_id, loan_id) {
    this.loading = true;
    this.operationsService.deleteConnectedCard(this.token, account_card_id, loan_id)
      .subscribe(status => {
        this.loading = false;
        this.getAccounts();
      });
  }
  setAsConnectedAccount(loan_id, account_card_id) {
    this.loading = true;
    this.operationsService.setAsConnectedAccount(this.token, account_card_id, loan_id)
      .subscribe(status => {
        this.loading = false;
        this.getAccounts();
      });
  }
  confirmBankAccount() {

    this.loading = true;
    this.loading = false;
        this.is_done = '1';
    this.operationsService.sendAccountConfirmationRequest(this.token, this.request_id);
  }
  cancelOperation() {
    this.loading = false;
    this.confirming = true;
    this.confirmingOTP = false;
    this.otpConfirmed = false
    this.otpHBSFC = false
    this.lenderbanksaved = false;
    this.lbHBSFS = false;
  }
  verifyAccount(bank) {
    this.bank = bank;
    this.loading = true;
    this.VERIFY_STATUS = false; 
    this.operationsService.confirmBankAccount(this.currentUser.token, this.bank)
      .subscribe(status => { 
        this.loading = false;

        if (status.status == "success") {
          this.VERIFY_STATUS = false;
          this.charge_account = true;
          this.bank.LENDER_BANK_ACCOUNT_NAME = status.data.account_name;
        } else {
          this.VERIFY_STATUS = true;
        }
      });
  }
 
  changeBankCode(event) {
    if (event.status == true) {
      this.charge_account = false;
      this.VERIFY_STATUS = false;
      this.loading = false;
      this.destination = false;
      this.bank.LENDER_BANK_CODE = event.event.BANK_CODE;
      this.bank.LENDER_BANK_BANK_NAME = event.event.BANK_NAME;
      this.bank.LENDER_BANK_ID = event.event.BANK_ID;
      this.bank.LENDER_BANK_ACCOUNT_NAME = "";
      this.bank.LENDER_ACCOUNT_NUMBER = "";
    } else {
      this.charge_account = false;
      this.VERIFY_STATUS = false;
      this.loading = false;
      this.credit.DEST_BANK_CODE = event.event.BANK_CODE;
    }

  }
  addAccountClick() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({'location': 'test_mode',  request_id: this.loan.request_id, loan: this.loan})
    } else {
      this.account_number = 'add_manually';
    }
  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  pullstatement(account_card_id,request_id){
    this.statement_message = '';
    this.loading_statement = true
    this.loansService.initiatestatements(this.currentUser.token, request_id, account_card_id)
      .subscribe(loans => {
        this.loading_statement = false;
        if (loans[1].status == true) {
          this.showSuccess("Statement pulled successfully")
          this.showSuccess("Account Balance")
        } else {
          this.showError("Unable to get statement ")
          this.statement_message = loans[1].message.message
 
        }
    });
  }
  checkbalance(account_card_id,request_id){
    this.statement_message = '';
    this.loading_statement = true
    this.loansService.checkbalance(this.currentUser.token, request_id, account_card_id)
      .subscribe(loans => {
        this.loading_statement = false;
        if (loans[1].status == true) {
          this.showSuccess("Statement pulled successfully")
        } else {
          this.showError("Unable to get statement ")
          this.statement_message = loans[1].message.message
 
        }
    });
  }
}
