<div class="flex">
  <div class="col-md-12 main-center pl_0" [ngClass]="{'no-padding': from === 'customer' || from === 'drawer'}">
    <app-contractheader *ngIf="from !== 'customer' && from !== 'drawer'" [text]="text" [request_id]="request_id">
    </app-contractheader>
    <div *ngIf="from === 'customer'">
      <div class="panel padding-regular" *ngIf="kycs && kycs.count == 0">
        <section class="fill-h fill-v">
          <div class="h-group center mb-4 padded empty-state">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title">No kyc requests</h2>
          </div>
        </section>
      </div>
      <div *ngIf="kycs && kycs.count > 0">
        <div class="panel mb-1 no-padding" *ngFor="let loan of kycs.data">
          <div class="alert padding-regular bg-off-white">

            <div class="icon"><i class="fa fa-exclamation-circle"></i></div>

          </div>


        </div>


      </div>
    </div>
    <div *ngIf="!from || from === 'drawer'">
      <div *ngIf="!loading">


        <div class="panel padding-regular flex">
          <div class="flex-1 flex-center" (click)="view = 'home'">
            <div class="icon-text medium cursor-pointer" [ngClass]="{'active': view === 'home'}">
              <div class="icon color-red"><i class="fas fa-home"></i></div>
              <div class="text">Home Verification</div>
            </div>
          </div>
          <div class="flex-1 flex-center" (click)="view = 'office'">
            <div class="icon-text medium cursor-pointer" [ngClass]="{'active': view === 'office'}">
              <div class="icon color-green"><i class="fas fa-building"></i></div>
              <div class="text">Office Verification</div>
            </div>
          </div>
        </div>

        <div *ngIf="view === 'home'">
          <div *ngIf="loading">
            <div class="panel mb-1 no-padding">
              <div class="alert padding-regular bg-off-white">
                <div class="texts">
                  <div class="title skeleton mb-1">This is a sample text</div>
                  <p class="subtitle skeleton">This is a sample subtext</p>
                </div>
                <div class="action flex flex-column align-end">
                  <p class="default-text-size mb-1 skeleton">Sample date</p>
                  <p class="default-text-size no-margin skeleton">Sample loan count</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading">
            <div *ngIf="no_home_request">
              <div class="panel padding-regular">
                <app-help-popover [content]="[
                                  {
                                    title: 'Address Verification',
                                    img: 'assets/images/home-address.svg',
                                    text:
                                      '
                                    <div>
                                      <p>Home/work address not verified.
                                      </p>
                                    </div>'
                                  }
                                ]"></app-help-popover>
                <div class="flex" style="min-height: 270px">
                  <div class="flex-1">
                    <div class="section-poster">
                      <img src="assets/images/incognito.svg" alt="incognito Icon">
                    </div>
                  </div>
                  <div class="flex-1 flex flex-column justify-end">
                    <div class="h-group flex flex-column align-end text-right">
                      <h3 class="title w-75" style="font-size: 1.9rem">Home address not yet verified</h3>
                      <p class="subtitle fill-w">{{loan?.home_address}}</p>
                      <div class="mt-3">
                        <app-button (click)="requestKYC(1)" label="Request AVS" class="medium"></app-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!no_home_request">
              <div *ngIf="!home_verification_view">
                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1">
                      <div class="section-poster width_1_2 text-center">
                        <img src="assets/images/home.svg" alt="incognito Icon">
                      </div>
                    </div>

                    <div class="flex-1 flex flex-column justify-end">

                      <ul class="decList bigger">
                        <li class="text-right"><span class="title color-orange">Address verification</span></li>
                        <li><span>Type</span><span>Home verification</span></li>
                        <li><span>Address</span><span>{{ loan?.home_address }}</span></li>
                        <li><span>Phone</span><span>{{home_data?.CUSTOMER_PHONE_NUMBER}}</span></li>
                      </ul>
                      <div class="flex justify-between align-center mt-4"
                        *ngIf="home_data.verification.KYC_STATUS == '0'">
                        <app-button class="small outline" label="Edit" icon="fa fa-edit"></app-button>
                        <app-button class="small outline red" label="Delete" icon="fa fa-trash"></app-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1">
                      <div class="section-poster width_1_2 text-center">
                        <img src="assets/images/ongoing.svg" alt="incognito Icon">
                      </div>
                    </div>
                    <div class="flex-1 flex flex-column justify-end">

                      <ul class="decList bigger">
                        <li><span class="title color-orange">Ongoing VCS Details</span></li>
                        <li><span>Hours left</span><span>{{ getHoursLeftFromNow(home_data?.REQUEST_END_DATE) }}</span>
                        </li>
                        <li><span>Date added</span><span>{{ home_data?.DATE_ADDED | date }}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="home_verification_view">
                <div class="panel padding-regular">
                  <app-userlocation [loan]="loan" [latitude]="home_lat" [longitude]="home_lng" [latitude_]="LAT_"
                    [longitude_]="LNG_">
                  </app-userlocation>

                </div>


                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1 mr-4">

                      <ul class="decList bigger">
                        <li><span class="title color-orange">Field agent feedbank</span></li>
                        <li *ngIf="home_result.ACCOUNT_NAME != null"><span>Customer
                            Name</span><span>{{home_result.ACCOUNT_NAME}}</span></li>
                        <li *ngIf="home_result.ADDRESS != null"><span>Address</span>
                          <span>{{home_result.ADDRESS}}</span>
                        </li>
                        <li *ngIf="home_result.ADDRESS_EXISTS != null"><span>Address Exist</span> <span>
                            {{home_result.ADDRESS_EXISTS}}</span></li>
                        <li *ngIf="home_result.GEO_TAGGED_ADDRESS != null"><span>Geotagged Address</span> <span>
                            {{home_result.GEO_TAGGED_ADDRESS}}</span>
                        </li>
                        <li *ngIf="home_result.TYPED_ADDRESS"><span>Typed Address</span> <span>
                            {{home_result.TYPED_ADDRESS}}</span></li>
                        <li *ngIf="home_result.HOUSE_COLOR != null"><span>House Color</span> <span>
                            {{home_result.HOUSE_COLOR}}</span></li>
                        <li *ngIf="home_result.BUILDING_TYPE != null"><span>Building Type</span>
                          <span>{{home_result.BUILDING_TYPE}}</span>
                        </li>
                        <li *ngIf="home_result.BUILDING_STATE != null"><span>Building State</span> <span>
                            {{home_result.BUILDING_STATE}}</span></li>
                        <li *ngIf="home_result.BUILDING_DETAILS != null"><span>Building Details</span>
                          <span>{{home_result.BUILDING_DETAILS}}</span>
                        </li>
                        <li *ngIf="home_result.RELATIONSHIP_WITH_OWNER != null"><span>Relationship with Owner</span>
                          <span>{{home_result.RELATIONSHIP_WITH_OWNER}}</span>
                        </li>
                        <li *ngIf="home_result.RELOCATION_PLAN != null"><span>Relocation Plan</span>
                          <span>{{home_result.RELOCATION_PLAN}}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="section-poster text-right">
                      <img src="assets/images/incognito.svg" alt="Incognito">
                    </div>
                  </div>
                </div>

                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="section-poster text-left">
                      <img src="assets/images/camera.svg" alt="Incognito">
                    </div>
                    <div class="flex-1 ml-4">
                      <div class="section-title mb-4">Pictures taken by field agent</div>
                      <div class="grid two">
                        <div *ngFor="let img of home_img_arr" class="image-thumb cursor-pointer"
                          style="min-height: initial" (click)="previewSrc = img">
                          <img src="{{img}}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel padding-regular" *ngIf="home_notes_arr.length">
                  <div class="timeline mt-5">
                    <article class="timeline-item" *ngFor="let note of home_notes_arr; let idx = index"
                      [ngClass]="{'alt':idx % 2 > 0}">
                      <div class="timeline-desk" *ngIf="idx % 2 > 0">
                        <div class="panel">
                          <div class="pd_15">
                            <span class="arrow"></span>
                            <span class="timeline-icon red"></span>

                            <p>{{note}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-desk" *ngIf="idx % 2 == 0">
                        <div class="panel">
                          <div class="pd_15">
                            <span class="arrow"></span>
                            <span class="timeline-icon green"></span>

                            <p>{{note}}</p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="view == 'office'">
          <div *ngIf="loading">
            <div class="panel mb-1 no-padding">
              <div class="alert padding-regular bg-off-white">
                <div class="texts">
                  <div class="title skeleton mb-1">This is a sample text</div>
                  <p class="subtitle skeleton">This is a sample subtext</p>
                </div>
                <div class="action flex flex-column align-end">
                  <p class="default-text-size mb-1 skeleton">Sample date</p>
                  <p class="default-text-size no-margin skeleton">Sample loan count</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading">
            <div *ngIf="no_office_request">
              <div class="panel padding-regular">
                <div class="flex" style="min-height: 270px">
                  <div class="flex-1">
                    <div class="section-poster">
                      <img src="assets/images/incognito.svg" alt="incognito Icon">
                    </div>
                  </div>
                  <div class="flex-1 flex flex-column justify-end">
                    <div class="h-group flex flex-column align-end text-right">
                      <h3 class="title w-75" style="font-size: 1.9rem">Work address not yet verified</h3>
                      <p class="subtitle fill-w">{{loan?.work_address}}</p>
                      <div class="mt-3">
                        <app-button (click)="requestKYC(2)" label="Request AVS" class="medium"></app-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!no_office_request">
              <div *ngIf="!office_verification_view">
                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1">
                      <div class="section-poster width_1_2 text-center">
                        <img src="assets/images/office.svg" alt="incognito Icon">
                      </div>
                    </div>
                    <div class="flex-1 flex flex-column justify-end">

                      <ul class="decList bigger">
                        <li><span class="title color-orange">Address verification</span></li>
                        <li><span>Type</span><span>Office verification</span></li>
                        <li><span>Address</span><span>{{ loan?.work_address }}</span></li>
                        <li><span>Phone</span><span>{{office_data?.CUSTOMER_PHONE_NUMBER}}</span></li>
                      </ul>
                      <div class="flex justify-between align-center mt-4"
                        *ngIf="office_data.verification.KYC_STATUS == '0'">
                        <app-button class="small outline" label="Edit" icon="fa fa-edit"></app-button>
                        <app-button class="small outline red" label="Delete" icon="fa fa-trash"></app-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1">
                      <div class="section-poster width_1_2 text-center">
                        <img src="assets/images/ongoing.svg" alt="incognito Icon">
                      </div>
                    </div>
                    <div class="flex-1 flex flex-column justify-end">

                      <ul class="decList bigger">
                        <li><span class="title color-orange">Ongoing VCS Details</span></li>
                        <li><span>Hours Left</span><span>{{ getHoursLeftFromNow(office_data?.REQUEST_END_DATE) }}</span>
                        </li>
                        <li><span>Date Added</span><span>{{ office_data?.DATE_ADDED | date }}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="office_verification_view">
                <div class="panel padding-regular">
                  <app-userlocation [loan]="loan" [latitude]="office_lat" [longitude]="office_lng" [latitude_]="LAT_"
                    [longitude_]="LNG_">
                  </app-userlocation>
                </div>
                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="flex-1 mr-4">

                      <ul class="decList bigger">
                        <li><span class="title color-orange">Field agent feedbank</span></li>
                        <li *ngIf="office_result.ACCOUNT_NAME != null"><span>Customer
                            Name</span><span>{{home_result.ACCOUNT_NAME}}</span></li>
                        <li *ngIf="office_result.ADDRESS != null"><span>Address</span>
                          <span>{{office_result.ADDRESS}}</span>
                        </li>
                        <li *ngIf="office_result.ADDRESS_EXISTS != null"><span>Address Exist</span> <span>
                            {{home_result.ADDRESS_EXISTS}}</span></li>
                        <li *ngIf="office_result.GEO_TAGGED_ADDRESS != null"><span>Geotagged Address</span> <span>
                            {{home_result.GEO_TAGGED_ADDRESS}}</span>
                        </li>
                        <li *ngIf="office_result.TYPED_ADDRESS"><span>Typed Address</span> <span>
                            {{home_result.TYPED_ADDRESS}}</span></li>
                        <li *ngIf="office_result.HOUSE_COLOR != null"><span>House Color</span> <span>
                            {{home_result.HOUSE_COLOR}}</span></li>
                        <li *ngIf="office_result.BUILDING_TYPE != null"><span>Building Type</span>
                          <span>{{home_result.BUILDING_TYPE}}</span>
                        </li>
                        <li *ngIf="office_result.BUILDING_STATE != null"><span>Building State</span> <span>
                            {{home_result.BUILDING_STATE}}</span></li>
                        <li *ngIf="office_result.BUILDING_DETAILS != null"><span>Building Details</span>
                          <span>{{home_result.BUILDING_DETAILS}}</span>
                        </li>
                        <li *ngIf="office_result.RELATIONSHIP_WITH_OWNER != null"><span>Relationship with Owner</span>
                          <span>{{home_result.RELATIONSHIP_WITH_OWNER}}</span>
                        </li>
                        <li *ngIf="office_result.RELOCATION_PLAN != null"><span>Relocation Plan</span>
                          <span>{{home_result.RELOCATION_PLAN}}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="section-poster text-right">
                      <img src="assets/images/incognito.svg" alt="Incognito">
                    </div>
                  </div>
                </div>
                <div class="panel padding-regular">
                  <div class="flex">
                    <div class="section-poster text-left">
                      <img src="assets/images/camera.svg" alt="Incognito">
                    </div>
                    <div class="flex-1 ml-4">
                      <div class="section-title mb-4">Pictures taken by field agent</div>
                      <div class="grid two">
                        <div *ngFor="let img of office_img_arr" class="image-thumb cursor-pointer"
                          style="min-height: initial" (click)="previewSrc = img">
                          <img src="{{img}}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel padding-regular" *ngIf="office_notes_arr && office_notes_arr.length">
                  <div class="timeline mt-5">
                    <article class="timeline-item" *ngFor="let note of office_notes_arr; let idx = index"
                      [ngClass]="{'alt':idx % 2 > 0}">
                      <div class="timeline-desk" *ngIf="idx % 2 > 0">
                        <div class="panel">
                          <div class="pd_15">
                            <span class="arrow"></span>
                            <span class="timeline-icon red"></span>

                            <p>{{note}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-desk" *ngIf="idx % 2 == 0">
                        <div class="panel">
                          <div class="pd_15">
                            <span class="arrow"></span>
                            <span class="timeline-icon green"></span>

                            <p>{{note}}</p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading">
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="previewSrc">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="previewSrc = ''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content no-padding animated zoomIn minimal avatar-preview" role="document"
    style="min-height: initial">
    <img [src]="previewSrc" alt="Customer's profile" />
  </div>
</div>