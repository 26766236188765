import { Component, OnInit, Input } from '@angular/core';
import { StorageService, LoansService, AuthenticationService } from '../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-requestlastactivity',
  templateUrl: './requestlastactivity.component.html',
  styleUrls: ['./requestlastactivity.component.css']
})
export class RequestlastactivityComponent implements OnInit {
  @Input() loan:any;
  currentUser:any;
  timeline:any=[];
  lastact:any;
  constructor(private storageService: StorageService,
    private loansService: LoansService,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute, private authService: AuthenticationService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getRequestTimeline();
  }
  grantRight(right: String) {
    return this.authService.userHasRights(right);
  }
  getRequestTimeline() {
    this.loansService.getRequestTimeline(this.currentUser.token, this.loan.request_id)
    .subscribe(result => {
      if (result.status === true) {
        this.timeline = result.data;
        this.lastact = this.timeline[this.timeline.length-1]
      }
    });
  } 

}
