import { LoggingService } from '../_services/logging.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
};
@Injectable()
export class AnalyticsService {
  private postsUrl = 'https://creditclanapis.creditclan.com/api/v2/';
  private analyticsUrl = 'https://loanstest.creditclan.com/';
  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
  ) { }
  public populateTertiaryFilter(
    token: any,
    secondary_filter: any
  ): Observable<any> {
    return this.http.post(
      this.postsUrl + 'dashboard/getTertiaryFilter',
      JSON.stringify({ token: token, secondary_filter: secondary_filter })
    );
  }
  public getSingleBankStatementAnalysis(
    token: any,
    parentRouteId: any,
    statement: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'analytics/getSingleBankStatementAnalysis',
      JSON.stringify({
        token: token,
        parentRouteId: parentRouteId,
        statement: statement
      })
    );
  }
  // public doBankStatementAnalysis(
  //   token: any,
  //   loan: any,
  //   data: any,
  //   statement: any
  // ): Observable<any> {
  //   return this.http.post(
  //     this.analyticsUrl + 'analytics/bankstatement',
  //     JSON.stringify({
  //       token: token,
  //       loan: loan,
  //       data: data,
  //       statement: statement
  //     })
  //   );
  // } 
  public doBankStatementAnalysisPassworded(statement: any, password: any, token: any): Observable<any> {
    return this.http.post(this.postsUrl + 'services/sendStatementForAnalysisPassword',
      JSON.stringify({ statement: statement, token: token, password: password })
    );
  }
  public doBankStatementAnalysis(statement: any, token: any): Observable<any> {
    return this.http.post(this.postsUrl + 'services/sendStatementForAnalysis',
      JSON.stringify({ statement: statement, token: token })
    );
  }
  public runEmployeePerformance(token: any, emp_perf: any): Observable<any> {
    return this.http.post(
      this.postsUrl + 'operations/runEmployeePerformance',
      JSON.stringify({ token: token, emp_perf: emp_perf })
    );
  }
  getPortfolioSnapshot(token: any): Observable<any> {
    return this.http.post(
      'https://creditclanapis.creditclan.com/api/v2/dashboard/getPortolioSnapshot',
      JSON.stringify({ token: token })
    );
  }
  getBranchPortfolioSnapshot(token: string, branch_id: any): Observable<any> {
    return this.http.post(
      'https://creditclanapis.creditclan.com/api/v2/dashboard/getPortolioSnapshot',
      JSON.stringify({ token, branch_id })
    );
  }
  public getPendingApprovals(token: any): Observable<any> {
    return this.http.post(
      'https://creditclanapis.creditclan.com/api/v2/dashboard/pendingApprovals',
      JSON.stringify({ token: token })
    );
  }
  public runCompanyPlatformSummary(token: any, company_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/platformsummary',
      JSON.stringify({ token: token, company_id: company_id })
    );
  }
  public runPlatformSummary(token: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/platformsummary',
      JSON.stringify({ token: token })
    );
  }
  public runPlatformEarnings(token: any, from_where: any, group_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/portfolioearning',
      JSON.stringify({ token: token, source: from_where, group_id: group_id })
    );
  }
  public runPortfolioSummary(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/runportfoliosummary',
      JSON.stringify({ token: token })
    );
  }
  public runRequestSummary(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/runrequestsummary',
      JSON.stringify({ token: token })
    );
  }
  runBranchPlatformSummaryRequest(token: string): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/summary',
      JSON.stringify({ token: token })
    );
  }
  runBranchPlatformSummary(token: string, branch_id): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/summary',
      JSON.stringify({ token, branch_id })
    );
  }
  public runPerformanceSnapshot(token: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/performancesnapshot',
      JSON.stringify({ token: token })
    );
  }
  runBranchPerformanceSnapshot(token: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/performancesnapshot',
      JSON.stringify({ token: token, branch_id })
    );
  }
  public getPortfolioTarget(token: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/portfoliotarget',
      JSON.stringify({ token: token })
    );
  }
  getBranchPortfolioTarget(token: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/portfoliotarget',
      JSON.stringify({ token: token, branch_id })
    );
  }
  public runStaffPerformance(token: any, staff_performance: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/staffsummary',
      JSON.stringify({ token: token, staff_performance: staff_performance })
    );
  }
  runBranchStaffPerformance(token: any, staff_performance: any, branch_id): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/staffsummary',
      JSON.stringify({ token: token, staff_performance: staff_performance, branch_id })
    );
  }
  public getSimplePortfolioSummary(
    token: any,
    data: any,
    is_amount: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getSimplePortfolioSummary',
      JSON.stringify({ token: token, data: data, is_amount: is_amount })
    );
  }
  public getComparisonPortfolioSummary(
    token: any,
    trend_one: any,
    trend_two: any,
    is_amount: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getComparisonPortfolioSummary',
      JSON.stringify({
        token: token,
        trend_one: trend_one,
        trend_two: trend_two,
        is_amount: is_amount
      })
    );
  }
  getBranchSimplePortfolioSummary(
    token: any,
    data: any,
    is_amount: any,
    branch_id: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getSimplePortfolioSummary',
      JSON.stringify({ token: token, data: data, is_amount: is_amount, branch_id })
    );
  }
  public getBranchComparisonPortfolioSummary(
    token: any,
    trend_one: any,
    trend_two: any,
    is_amount: any,
    branch_id: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getComparisonPortfolioSummary',
      JSON.stringify({
        token: token,
        trend_one: trend_one,
        trend_two: trend_two,
        is_amount: is_amount,
        branch_id
      })
    );
  }
  public getDefaultComparisonPortfolioSummary(token: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getDefaultComparisonPortfolioSummary',
      JSON.stringify({
        token: token
      })
    );
  }
  public getSimpleSummary(
    token: any,
    data: any,
    is_amount: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getSimpleSummary',
      JSON.stringify({ token: token, data: data, is_amount: is_amount })
    );
  }
  getBranchSimpleSummary(
    token: any,
    data: any,
    is_amount: any,
    branch_id: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getSimpleSummary',
      JSON.stringify({ token: token, data: data, is_amount: is_amount, branch_id })
    );
  }
  public getComparisonSummary(
    token: any,
    trend_one: any,
    trend_two: any,
    is_amount: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getComparisonSummary',
      JSON.stringify({
        token: token,
        trend_one: trend_one,
        trend_two: trend_two,
        is_amount: is_amount
      })
    );
  }
  getBranchComparisonSummary(
    token: any,
    trend_one: any,
    trend_two: any,
    is_amount: any,
    branch_id: any
  ): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/getComparisonSummary',
      JSON.stringify({
        token: token,
        trend_one: trend_one,
        trend_two: trend_two,
        is_amount: is_amount,
        branch_id
      })
    );
  }
  public runPlatformAnalysis(token: any, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/loanperformance',
      JSON.stringify({ token: token, data: data })
    );
  }
  runBranchPlatformAnalysis(token: string, data: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/loanperformance',
      JSON.stringify({ token: token, data: data, branch_id })
    );
  }
  public reunAgeingAnalysis(token: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/ageing',
      JSON.stringify({ token: token })
    );
  }
  rerunBranchAgeingAnalysis(token: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/ageing',
      JSON.stringify({ token: token, branch_id })
    );
  }
  public runPiePerformance(token: any, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/distribution',
      JSON.stringify({ token: token, data: data })
    );
  }
  runBranchPiePerformance(token: string, data: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/distribution',
      JSON.stringify({ token, data, branch_id })
    );
  }
  public runPiePerformanceForProducts(token: any, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/distributionbyproducts',
      JSON.stringify({ token: token, data: data })
    );
  }
  runLoanDistributionByBranches(token: string, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/loandistributionbybranches',
      JSON.stringify({ token: token, data: data })
    );
  }
  runCustomerDistributionByBranches(token: string, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/customerdistributionbybranches',
      JSON.stringify({ token: token, data: data })
    );
  }
  runRepaymentSourceDistribution(token: any, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/repaymentdistribution',
      JSON.stringify({ token: token, data: data })
    );
  }
  runBranchRepaymentSourceDistribution(token: any, data: any, branch_id: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/repaymentdistribution',
      JSON.stringify({ token: token, data: data, branch_id })
    );
  }
  runPiePerformancePortfolio(token: any, data: any): Observable<any> {
    return this.http.post(
      this.analyticsUrl + 'loan/portfoliodistribution',
      JSON.stringify({ token: token, data: data })
    );
  }
  runPPSPerformance(token: any, data: any): Observable<any> {
    return this.http.post(
      this.postsUrl + 'operations/runPPSPerformance',
      JSON.stringify({ token: token, data: data })
    );
  }
  startAnalysis(
    token: any,
    data: any,
    settings: any,
    total: any
  ): Observable<any> {
    return this.http.post(
      this.postsUrl + 'analytics/startAnalysis',
      JSON.stringify({
        token: token,
        magic_filter: data,
        settings: settings,
        total: total
      })
    );
  }
  runAnalysisForAdjustedSettings(
    token: any,
    loan: any,
    settings: any
  ): Observable<any> {
    return this.http.post(
      this.postsUrl + 'analytics/runAnalysisForAdjustedSettings',
      JSON.stringify({ token: token, loan: loan, settings: settings })
    );
  }
  getRequestParams(request_id: any): Observable<any> {
    return this.http.post(
      `https://eligibilitytest.creditclan.com/public/requestparams`,
      { request_id: request_id }
    );
  }
  getFullAnalytics(
    lender_id,
    borrower_id,
    principal,
    maturity_date,
    account_card_id,
    loan_request_id,
    card_id,
    smslogs,
    calllogs,
    profile,
    guarantor,
    card,
    account,
    direct_debit,
    repayments,
    social,
    bvn
  ): Observable<any> {
    return this.http.post(
      `https://eligibilitytest.creditclan.com/public/fullanalytics`,
      {
        lender_id: lender_id,
        borrower_id: borrower_id,
        principal: principal,
        maturity_date: maturity_date,
        account_card_id: account_card_id,
        loan_request_id: loan_request_id,
        card_id: card_id,
        smslogs: smslogs,
        calllogs: calllogs,
        profile: profile,
        guarantor: guarantor,
        card: card,
        account: account,
        direct_debit: direct_debit,
        repayments: repayments,
        social: social,
        bvn: bvn
      }
    );
  }
  getFullAnalyticsPreFetch(
    lender_id,
    borrower_id,
    principal,
    maturity_date,
    account_card_id,
    loan_request_id,
    card_id,
    smslogs,
    calllogs,
    profile,
    guarantor,
    card,
    account,
    direct_debit,
    repayments,
    social,
    prefetch_value
  ): Observable<any> {
    return this.http.post(
      `https://eligibilitytest.creditclan.com/public/fullanalytics`,
      {
        lender_id: lender_id,
        borrower_id: borrower_id,
        principal: principal,
        maturity_date: maturity_date,
        account_card_id: account_card_id,
        loan_request_id: loan_request_id,
        card_id: card_id,
        smslogs: smslogs,
        calllogs: calllogs,
        profile: profile,
        guarantor: guarantor,
        card: card,
        account: account,
        direct_debit: direct_debit,
        repayments: repayments,
        social: social,
        prefetch_value: prefetch_value
      }
    );
  }
  getFullAnalytics_(
    lender,
    customer,
    guarantor,
    loan,
    request_guarantor
  ): Observable<any> {
    return this.http.post(
      `https://smsanalytics.creditclan.com/analytics/guarantor`,
      {
        lender: lender,
        customer: customer,
        guarantor: guarantor,
        loan: loan,
        request_guarantor: request_guarantor

      }
    );
  }
  getFullAnalytics__(
    lender,
    customer,
    direct_debit
  ): Observable<any> {
    return this.http.post(
      `https://smsanalytics.creditclan.com/analytics/direct_debit`,
      {
        lender: lender,
        customer: customer,
        direct_debit: direct_debit

      }
    );
  }
  getFullAnalytics___(
    lender,
    customer,
    sms_logs
  ): Observable<any> {
    return this.http.post(
      `https://smsanalytics.creditclan.com/analytics/sms`,
      {
        lender: lender,
        customer: customer,
        sms_logs: sms_logs

      }
    );
  }
  getFullAnalyticsGuarantor(
    lender,
    customer,
    guarantor,
    request_guarantor,
    loan
  ): Observable<any> {
    return this.http.post(
      `https://smsanalytics.creditclan.com/analytics/guarantor`,
      {
        lender: lender,
        customer: customer,
        guarantor,
        request_guarantor,
        loan
      }
    );
  }

  analyze(filename, password) {
    const url = `${filename}`
    return this.http.post('https://app.bankstatement.ai/analytics', { url, password });
  }


  analyzenew(filename, password, full_name, start_date, end_date, repayment) {
    const file_url = `${filename}`
    return this.http.post('https://lendnode.creditclan.com/bs/api/extraction/upload/pdf_url', { file_url, password, full_name, start_date, end_date, repayment }, httpOptions);
  }

  analyzeSMS(filename:any, token:any) : Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/analyse',
      JSON.stringify({ token: token, sms_record_id: filename })
    );
  }

  checkStatus(transaction_id) {
    return this.http.get(`https://app.bankstatement.ai/analytics/status/${transaction_id}`);
  }

  getAnalysisResult(transaction_id) {
    return this.http.get(`https://view.statementanalysis.co/index.php/data_analysis/getdata/${transaction_id}`);
  }

  sendBSAnalysisToBackend(data) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/updateAnalysisForAttachment', data);
  }

  runIdCheck(data, token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` })
    };
    return this.http.post('https://crediblebackend.creditclan.com/public/index.php/api/verify', data, httpOptions);
  }

  getIdChecks(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` })
    };
    return this.http.get('https://crediblebackend.creditclan.com/public/index.php/api/verification', httpOptions);
  }

  getTokenForIdCheck(data) {
    return this.http.post('https://crediblebackend.creditclan.com/public/index.php/api/alternatelogin', data);
  }

  getAnalysisStatus(token, request_id) {
    return this.http.post('https://loanstest.creditclan.com/loan/loanchecklist', { token, request_id });
  }

  runRepaymentSnapshot(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/repaymentsnapshot',
      JSON.stringify({ token: token })
    );
  }
}
