<div class="cont">
  <div class="sect-wrap">
    <div class="left-container">
      <div class="onb-title">
        <!-- <h3>Make sense of your borrowers' data with our robust backend.</h3> -->
        <h3>Robust, Next-generation Loan mgt Backend</h3>
      </div>
      <div class="onb-content">
        <p>If you find any loan administration, analytics tool on this side of the atlantic better than this, you are
          guaranteed one
          week in Ibiza 2035.</p>
        <p>Seriously, this is where everything comes together, with our:</p>
        <ul class="bullet-list">
          <li>Multi-level workflow and timeline</li>
          <li>Multi-product dynamic setup</li>
          <li>Loans to serviced companies</li>
          <li>Disbursement and recollection</li>
        </ul>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container text-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/dashboard.svg" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
