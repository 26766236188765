<app-requestheader style="margin-bottom:16px" [loan]="loan">
</app-requestheader>
<div *ngIf="approving">
  <div class="padding-regular">
    <div class="flex">
      <div class="section-poster">
        <div class="image flex-center">
          <img src="assets/images/share.svg" alt="">
        </div>
      </div>
      <div class="profile_area ml-3 flex-1">
        <section>
          <div class="decList bigger">
            <form #f="ngForm" novalidate>
              <h3 class="section-title mb-3">Review your position on the request</h3>
              <div class="form-group">
                <label class="control-label normal">Notes</label>
                <textarea name="approval_notes" class="form-control" [(ngModel)]="model_a.approval_notes"
                  approval_notes></textarea>
              </div>
              <label class="form-group" *ngIf="loan?.LOAN_PRODUCT_ID=='29942'  && level.ILO=='1'">
                <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="model_a.TRANSFER_ALL_ACCOUNT">
                <span class="control-label normal">Send message to customer</span>
              </label>
              <div class="text-left mt-4">
                <app-button (click)="approveRequest()" [disabled]="loading" class="small" [loading]="loading"
                  label="Approve">
                </app-button>
                <app-button (click)="cancelApproval()" class="ml-1 small outline red" label="Cancel">
                </app-button>
              </div>
            </form>

          </div>
        </section>
      </div>
    </div>
  </div>

</div>
<div *ngIf="rejecting">
  <div class="padding-regular">
    <div class="flex">
      <div class="section-poster">
        <div class="image flex-center">
          <img src="assets/images/share.svg" alt="">
        </div>
      </div>
      <div class="profile_area ml-3 flex-1">
        <section>
          <div class="decList bigger">
            <!-- <form #f="ngForm" novalidate>
                  <h3>Review your position on the request</h3>
                  <div class="form-group">
                    <label class="control-label normal">Notes</label>
                    <textarea name="approval_notes" class="form-control" [(ngModel)]="model_a.approval_notes"
                      approval_notes></textarea>
                  </div>
    
                  <div class="text-left mt-4">
                    <app-button (click)="approveRequest()" [disabled]="loading" class="medium"
                      [loading]="loading" label="Send Invite">
                    </app-button>
                    <app-button (click)="cancelApproval()" class="ml-1 medium" label="Cancel">
                    </app-button>
                  </div>
                </form> -->
            <div class="form-group">
              <label class="control-label normal">Reason</label>
              <select class="form-control" required="" name="reject_reason" [(ngModel)]="model_r.reject_reason">
                <option value="0">select</option>
                <option value="1">Irreconciliable Documents</option>
                <option value="2">Unverifyable Documents</option>
              </select>
            </div>
            <div class="form-group">
              <label for="" class="control-label normal">Reject Action</label>
              <select name="reject_action" required="" class="form-control" [(ngModel)]="model_r.reject_action">
                <option value="0">select</option>
                <option value="1" *ngIf="loan?.approval_level > '1'">Return to level</option>
                <option value="3" *ngIf="!loan?.approval_level && loan?.added_to_payment_queue=='1'">Return to Requests</option>
                <option value="2">Move to rejected requests</option>
              </select>
            </div>
            <div class="form-group" *ngIf="model_r.reject_action=='1'">
              <label for="" class="control-label normal">Return Level</label>
              <select name="reject_level" required class="form-control" [(ngModel)]="model_r.reject_level">

                <option *ngFor="let l of prev_levels" [value]="l.LEVEL_ID">
                  {{l.LEVEL_NAME}}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="" class="control-label normal">Notes</label>
              <textarea name="approval_notes" class="form-control" [(ngModel)]="model_r.approval_notes"
                approval_notes></textarea>
            </div>
            <div class="text-left mt-4">
              <app-button class="small" [disabled]="loading" (click)="rejectRequest()" [loading]="loading"
                label="Reject"></app-button>
              <app-button (click)="cancelApproval()" class="small red outline" label="Cancel">Cancel
              </app-button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>