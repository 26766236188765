<div
    style="min-width:100%;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;font-size:16px;line-height:19px;width:100% !important">
    <div class="preheader"
        style="visibility:hidden;opacity:0;color:transparent;height:0;width:0;font-size:0px;mso-hide:all;display:none !important">
    </div>
    <table class="body"
        style="border-spacing:0;border-collapse:collapse;vertical-align:top;hyphens:none;-moz-hyphens:none;-webkit-hyphens:none;-ms-hyphens:none;height:100%;width:100%;color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;font-size:16px;line-height:19px;margin-bottom:0px !important;background-color: #f1f1f1;">
        <tr style="padding:0;vertical-align:top;text-align:left">
            <td class="center" align="center" valign="top"
                style="word-wrap:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;hyphens:auto;vertical-align:top;color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;font-size:16px;line-height:19px;border-collapse:collapse !important">
                <div style="width:100%;min-width:100%; text-align:center">
                    <!--[if mso]>
                  <table class="container" width="580">
                  <![endif]-->
                    <table class="container"
                        style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;background:#fff;width:100%;text-align:inherit;max-width:100%;margin: 30px 0">
                        <!-- <![endif]-->
                        <tr style="padding:0;vertical-align:top;text-align:left">
                            <td
                                style="word-wrap:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;hyphens:auto;vertical-align:top;color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;font-size:16px;line-height:19px;border-collapse:collapse !important">
                                <div class="section" (click)="openemail()">
                                    <table class="row"
                                        style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;padding:0;width:100%;position:relative;display:table">
                                        <tr class="" style="padding:0;vertical-align:top;text-align:left">
                                            <th class="columns first large-12 last small-12"
                                                style="color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;text-align:left;font-size:16px;line-height:19px;margin:0 auto;padding-bottom:16px;width:564px;padding-left:16px;padding-right:16px">
                                                <a *ngIf="vendor"
                                                    style="font-weight:normal;padding:0;margin:0;text-align:left;line-height:1.3;color:#2199e8;text-decoration:none">
                                                    <img align="center" alt="lulapay" class="center standard-header"
                                                        height="50" [src]="vendor?.LOGO_IMAGE_URL"
                                                        style="outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;width:auto;max-width:100%;clear:both;display:block;border:none;padding-bottom:16px;padding-top:48px;max-height:50px">
                                                </a>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="section"  (click)="openemail()">
                                    <table class="row"
                                        style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;padding:0;width:100%;position:relative;display:table">
                                        <tr class="" style="padding:0;vertical-align:top;text-align:left">
                                            <th class="columns first large-12 last small-12"
                                                style="color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;text-align:left;font-size:16px;line-height:19px;margin:0 auto;padding-bottom:16px;width:564px;padding-left:16px;padding-right:16px">
                                                <p [innerHtml]="mailSubjectContent"
                                                    class="headline headline-lg  heavy max-width-485"
                                                    style='padding:0;margin:0;text-align:left;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;max-width:485px;font-weight:700;color:#030d54;word-break:normal;hyphens:none;-moz-hyphens:none;-webkit-hyphens:none;-ms-hyphens:none;font-size:32px;line-height:1.3;margin-bottom:0 !important;'>

                                                </p>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div style="padding-bottom:5px" class="section" (click)="openemail()">
                                    <table class="row"
                                        style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;padding:0;width:100%;position:relative;display:table">
                                        <tr class="" style="padding:0;vertical-align:top;text-align:left">
                                            <th class="columns first large-12 last small-12"
                                                style="color:#0a0a0a;font-family:'Circular', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;text-align:left;font-size:16px;line-height:19px;margin:0 auto;padding-bottom:16px;width:564px;padding-left:16px;padding-right:16px">
                                                <p [innerHtml]="mailBodyContent"
                                                    class="body  body-lg  body-link-rausch light text-left"
                                                    style='padding:0;margin:0;font-family:"Circular", "Helvetica", Helvetica, Arial, sans-serif;font-weight:400;color:#484848;hyphens:none;-moz-hyphens:none;-webkit-hyphens:none;-ms-hyphens:none;font-size:18px;line-height:1.4;text-align:left;margin-bottom:0px !important;'>

                                                </p>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="account-alert-card-button-container"
                                    style="padding-top:16px;padding-bottom:16px;padding-left:0px;padding-right:0px">
                                    <table class="row"
                                        style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;padding:0;position:relative;width:100%;display:table">
                                        <tr style="padding:0;vertical-align:top;text-align:left">
                                            <th class="col-pad-left-2 col-pad-right-2"
                                                style="color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;font-size:16px;line-height:19px;padding-left:16px;padding-right:16px">
                                                <a class="btn-primary btn-sm btn-rausch btn-full-width"
                                                    style="font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;margin:0;text-align:left;line-height:1.3;color:#2199e8;text-decoration:none;background-color:#D8634B;-webkit-border-radius:4px;border-radius:4px;display:block;padding:19px 24px 19px 24px;">
                                                    <p *ngIf="email_type=='14'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Activate your account
                                                    </p>
                                                    <!-- <p *ngIf="email_type=='899'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Reset Pin
                                                    </p> -->
                                                    <p *ngIf="email_type=='6001'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Activate Direct Debit
                                                    </p>
                                                    <p *ngIf="email_type=='897'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Validate Work Email
                                                    </p>
                                                    <p *ngIf="email_type=='33'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Accept Offer
                                                    </p>
                                                    <p *ngIf="email_type=='2001'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Add Guarantors
                                                    </p>
                                                    <p *ngIf="email_type=='93'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Add Card
                                                    </p>
                                                    <p *ngIf="email_type=='91'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Add Bank
                                                    </p>
                                                    <p *ngIf="email_type=='335'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Add Attachments
                                                    </p>
                                                    <p *ngIf="email_type=='92'" class="text-center"
                                                        style='font-weight:normal;padding:0;margin:0;font-size:16px;line-height:19px;text-align:center;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:white;margin-bottom:0px !important;'>
                                                        Add BVN
                                                    </p>
                                                </a>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="show-for-large" (click)="updateimageadvert()">
                                    <table
                                        style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;width: 100%">
                                        <tbody>
                                            <tr style="padding:0;vertical-align:top;text-align:left;width: inherit">
                                                <td>
                                                    <img *ngIf="!vendor?.email_advert_logo" src="assets/img/register-bg.jpg" style="width: 100%;height:345px;">
                                                    <img *ngIf="vendor?.email_advert_logo" [src]="vendor?.email_advert_logo" style="width: 100%;height:345px">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <div class="" style="padding-bottom:16px">
                                    </div>
                                </div>

                                <div>
                                    <div class="" style="padding-bottom:8px">
                                    </div>
                                </div>
                                <div>
                                    <div>


                                    </div>
                                    <div style="padding-top:0">
                                        <table class="row"
                                            style="border-spacing:0;border-collapse:collapse;vertical-align:top;text-align:left;padding:0;width:100%;position:relative;display:table">
                                            <tr class="" style="padding:0;vertical-align:top;text-align:left">
                                                <th class="columns first large-12 last small-12 standard-footer-padding"
                                                    style="color:#0a0a0a;font-family:'Cereal', Helvetica, Arial, sans-serif;font-weight:normal;padding:0;text-align:left;font-size:16px;line-height:19px;margin:0 auto;padding-bottom:16px;width:564px;padding-left:16px;padding-right:16px">
                                                    <div class="">
                                                        <hr class="standard-footer-hr"
                                                            style="max-width:580px;border-right:0;border-top:0;border-bottom:1px solid #cacaca;border-left:0;clear:both;background-color:#dbdbdb;height:1px;width:100%;border:none;margin:auto">
                                                        <div class="row-pad-bot-4" style="padding-bottom:22px"></div>
                                                        <p class="standard-footer-text "
                                                            style='padding:0;margin:0;text-align:left;margin-bottom:10px;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:#9ca299;font-size:14px;text-shadow:0 1px #fff;font-weight:300;line-height:1.4'>
                                                        </p>
                                                        <p class="standard-footer-text center "
                                                            style='padding:0;margin:0;text-align:left;margin-bottom:10px;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:#9ca299;font-size:14px;text-shadow:0 1px #fff;font-weight:300;line-height:1.4'>
                                                            Sent with ♥ from {{vendor?.LEGAL_NAME}}.
                                                        </p>
                                                        <p class="standard-footer-text social-links"
                                                            style='padding:0;margin:0;text-align:left;margin-bottom:10px;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:#9ca299;font-size:14px;text-shadow:0 1px #fff;font-weight:300;line-height:1.4'>
                                                            <a
                                                                href="https://www.facebook.com/lulapay">Facebook</a>&nbsp;•
                                                            <a href="https://www.twitter.com/lulapay">Twitter</a>&nbsp;•
                                                            <a href="https://www.instragram.com/lulapay">Instagram</a>
                                                        </p>
                                                        <p class="standard-footer-text "
                                                            style='padding:0;margin:0;text-align:left;margin-bottom:10px;font-family:"Cereal", "Helvetica", Helvetica, Arial, sans-serif;color:#9ca299;font-size:14px;text-shadow:0 1px #fff;font-weight:300;line-height:1.4'>
                                                        </p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </td>
        </tr>
    </table>
</div>