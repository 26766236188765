import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, FormBuilder, Validators } from '@angular/forms';
import { DataService, OperationsService, OptionsserviceService, UserService } from '../../_services/index';
import { LoansService, AuthenticationService, StorageService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-makingpayment',
  templateUrl: './makingpayment.component.html',
  styleUrls: ['./makingpayment.component.css']
})
export class MakingpaymentComponent implements OnInit {
  newloading = false;
  @Input('loan_viewed') loan_viewed: any;
  public currentUser: any;
  @Input('makingPayment') makingPayment = false;
  @Output() paymentHasBeenQueued = new EventEmitter();
  default_bank: any;
  public pay_opt = [
    { value: '1' },
    { value: '2' },
    { value: '3' }
  ]
  state: any;
  payment = { LENDER_BANK_ACCOUNT_ID:'',SEND_PAYMENT_RECEIVED: false, PAY_WITH: '0', SECURITY_QUESTION_ANSWER: '', LENDER_ACCOUNT_ID: 0, REQUEST_ID: 0, PAYMENT_DATE: '', AMOUNT_TO_PAY: '0', PAYMENT_OPTION: '1' };
  loading = false;
  payment_done = false;
  payment_status = { status: '0', AMOUNT: '0' };
  choosePayment = false;
  chosenPayment = false;
  banks:any;
  constructor(public operationsService: OperationsService,public toastr: ToastrService, private DataService: DataService, public optionsService: OptionsserviceService,
    public fb: FormBuilder, public loansService: LoansService, public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.optionsService.getDefaultBank(this.currentUser.token).subscribe(bank => {
      this.default_bank = bank;
      this.payment.LENDER_ACCOUNT_ID = bank.LENDER_ACCOUNT_GL;
    });
  }
  backtoloansummary() {
    this.DataService.onBackToLoanSummary.emit(true);
  }
  getBanks() {
    this.operationsService.getLenderBanks(this.currentUser.token)
      .subscribe(banks => {
        this.banks = banks.banks;
      });
  }
  ngOnInit() {
    this.loading = true;
    this.loansService.getLoanRepaymentData(this.currentUser.token, this.loan_viewed)
      .subscribe(customers => {
        this.loading = false;
        this.state = customers;
        this.payment.REQUEST_ID = this.loan_viewed;
        this.payment.PAYMENT_OPTION = this.pay_opt[0].value;
      });
      this.getBanks();
  }
  closeOverlay() {

  }
  paywithwallet() {
    this.payment.PAY_WITH = '1';
    this.chosenPayment = true;
    this.loading = true;
    this.loansService.makepayment(this.currentUser.token, this.payment)
      .subscribe(result => {
        this.payment_done = true;
        this.loading = false;
        this.payment_status.status = result.status;
        this.payment_status.AMOUNT = result.AMOUNT;
        this.DataService.breakLoanSentReloadRequest.emit();
      });
  }
  paywithbank() {
    this.newloading = true;
    this.payment.PAY_WITH = '2';
    this.chosenPayment = true;
    this.loading = true;
    this.loansService.makepaymentoperationsreviewed(this.currentUser.token, this.payment)
      .subscribe(result => {
        this.loading = false;
        this.newloading = false;
        if (result.status === '1') {
          this.payment_done = true;
          this.loansService.completemakepaymentoperationsreviewed(this.currentUser.token, this.payment);
          if (result.send_payments_to_queue === '0') {
            //   this.paymentHasBeenQueued.emit(result);

          } else {


          }
          this.payment_done = true;
          this.loading = false;
          this.payment_status.status = result.status;
          this.payment_status.AMOUNT = result.AMOUNT;
          this.DataService.breakLoanSentReloadRequest.emit();
        } else {
          this.showError(result.message);
        }
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  makepayment() {
    this.makingPayment = true;
    this.choosePayment = true;

  }

}
