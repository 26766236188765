import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class DataService {
  onStartInvestmentTopUp: EventEmitter<any> = new EventEmitter();
  onStartInvestmentTerminate: EventEmitter<any> = new EventEmitter();
  onStartInvestmentWithdrawal: EventEmitter<any> = new EventEmitter();
  inviteNewGuarantor: EventEmitter<any> = new EventEmitter();
  showDueLoansList: EventEmitter<any> = new EventEmitter();
  requestingfordata: EventEmitter<any> = new EventEmitter();
  requestingfordataforcustomer: EventEmitter<any> = new EventEmitter();
  onTweakScores: EventEmitter<any> = new EventEmitter();
  onSaveFqscore: EventEmitter<any> = new EventEmitter();
  getFqscore: EventEmitter<any> = new EventEmitter();
  onChangeRequestRoute: EventEmitter<any> = new EventEmitter();
  onInitiateRecollection: EventEmitter<any> = new EventEmitter();
  paymentHasBeenProcessedFinally: EventEmitter<any> = new EventEmitter();
  onRollbackPaymentFromStatement: EventEmitter<any> = new EventEmitter();
  onGetData: EventEmitter<any> = new EventEmitter();
  onGetLoan: EventEmitter<any> = new EventEmitter();
  onGetCustomer: EventEmitter<any> = new EventEmitter();
  onCancelPayment: EventEmitter<any> = new EventEmitter();
  onRequestCreated: EventEmitter<any> = new EventEmitter();
  onViewLoan: EventEmitter<any> = new EventEmitter();
  onpreviewLoan: EventEmitter<any> = new EventEmitter();
  onProfileNav: EventEmitter<any> = new EventEmitter();
  onScheduleNav: EventEmitter<any> = new EventEmitter();
  onBackToLoanSummary: EventEmitter<any> = new EventEmitter();
  onBreakLoan: EventEmitter<any> = new EventEmitter();
  onEditCredit: EventEmitter<any> = new EventEmitter();
  onEditDebit: EventEmitter<any> = new EventEmitter();
  onOpenLedger: EventEmitter<any> = new EventEmitter();
  onOpenLoanChildModal: EventEmitter<any> = new EventEmitter();
  onConfirmaLoanToMarket: EventEmitter<any> = new EventEmitter();
  onConfirmAcceptOffer: EventEmitter<any> = new EventEmitter();
  onConfirmRejectOffer: EventEmitter<any> = new EventEmitter();
  onConfirmMakeOffer: EventEmitter<any> = new EventEmitter();
  onConfirmRemoveLoan: EventEmitter<any> = new EventEmitter();
  onMakePaymentFromStatement: EventEmitter<any> = new EventEmitter();
  onBreakingLoan: EventEmitter<any> = new EventEmitter();
  onTakeOwnership: EventEmitter<any> = new EventEmitter();
  onchangeOwnership: EventEmitter<any> = new EventEmitter();
  onRequestRoute: EventEmitter<any> = new EventEmitter();
  showListView: EventEmitter<any> = new EventEmitter();
  showGridView: EventEmitter<any> = new EventEmitter();
  filterLoans: EventEmitter<any> = new EventEmitter();
  onCheckLoan: EventEmitter<any> = new EventEmitter();
  approveTheSavingsRequest: EventEmitter<any> = new EventEmitter();
  onOpenMarketLoan: EventEmitter<any> = new EventEmitter();
  onSearchForLoans: EventEmitter<any> = new EventEmitter();
  onLoanMore: EventEmitter<any> = new EventEmitter();
  onConfirmCancelContract: EventEmitter<any> = new EventEmitter();
  openSearchView: EventEmitter<any> = new EventEmitter();
  closeSearchView: EventEmitter<any> = new EventEmitter();
  onResetFilters: EventEmitter<any> = new EventEmitter();
  showCalendarDetails: EventEmitter<any> = new EventEmitter();
  showRequestCalendarDetails: EventEmitter<any> = new EventEmitter();
  onConfirmLenderHasMadeOffer: EventEmitter<any> = new EventEmitter();
  onConfirmLenderHasMadeOffers: EventEmitter<any> = new EventEmitter();
  refreshPage: EventEmitter<any> = new EventEmitter();
  reloadCustomers: EventEmitter<any> = new EventEmitter();
  makeOfferToMarketLoan: EventEmitter<any> = new EventEmitter();
  borrowerChange: EventEmitter<any> = new EventEmitter();
  openContractForEdit: EventEmitter<any> = new EventEmitter();
  onDeleteCustomer: EventEmitter<any> = new EventEmitter();
  customerDeleted: EventEmitter<any> = new EventEmitter();
  resendWelcomeMail: EventEmitter<any> = new EventEmitter();
  reloadCustomer: EventEmitter<any> = new EventEmitter();
  addNewCustomerEmployee: EventEmitter<any> = new EventEmitter();
  enableBulkDisbursements: EventEmitter<any> = new EventEmitter();
  prepareBulkPay: EventEmitter<any> = new EventEmitter();
  updateTotalBulkDisbursements: EventEmitter<any> = new EventEmitter();
  onReverseTransaction: EventEmitter<any> = new EventEmitter();
  doCheckWalletTransactionStatuses: EventEmitter<any> = new EventEmitter();
  onOpenCustomerStatement: EventEmitter<any> = new EventEmitter();
  unauthorizedPageView: EventEmitter<any> = new EventEmitter();
  hideCustomerCategoryFilter: EventEmitter<any> = new EventEmitter();
  initiateBulAnalytics: EventEmitter<any> = new EventEmitter();
  viewAnalyticsResults: EventEmitter<any> = new EventEmitter();
  viewTheLoan: EventEmitter<any> = new EventEmitter();
  geotagCustomerAddress: EventEmitter<any> = new EventEmitter();
  adjustTheAnalyticsWeight: EventEmitter<any> = new EventEmitter();
  runCreditCheck: EventEmitter<any> = new EventEmitter();
  initiateDebitInstruction: EventEmitter<any> = new EventEmitter();
  initiateCheckDebitInstruction: EventEmitter<any> = new EventEmitter();
  initiateDebitInstructionCancel: EventEmitter<any> = new EventEmitter();
  stopDebitMandate: EventEmitter<any> = new EventEmitter();
  reloadCreditCheck: EventEmitter<any> = new EventEmitter();
  deleteBankAccount: EventEmitter<any> = new EventEmitter();
  runOperationsTest: EventEmitter<any> = new EventEmitter();
  acceptBorrowerPayment: EventEmitter<any> = new EventEmitter();
  rejectBorrowerPayment: EventEmitter<any> = new EventEmitter();
  loadingRoute: EventEmitter<any> = new EventEmitter();
  onFileUploaded: EventEmitter<any> = new EventEmitter();
  onreloadAccountsAndCards: EventEmitter<any> = new EventEmitter();
  onChangeDefaultPayment: EventEmitter<any> = new EventEmitter();
  onChangeLoanOfficer: EventEmitter<any> = new EventEmitter();
  onCreditLoanStatement: EventEmitter<any> = new EventEmitter();
  onSuspendFines: EventEmitter<any> = new EventEmitter();
  cancelationDone: EventEmitter<any> = new EventEmitter();
  oninitiateReverseTransaction: EventEmitter<any> = new EventEmitter();
  initStopRemita: EventEmitter<any> = new EventEmitter();
  onInitiateClearFines: EventEmitter<any> = new EventEmitter();
  remitaLoanNotification: EventEmitter<any> = new EventEmitter();
  refreshRemitaDetails: EventEmitter<any> = new EventEmitter();
  renewRemitaDetails: EventEmitter<any> = new EventEmitter();
  initLoanBuyBack: EventEmitter<any> = new EventEmitter();
  onCreateSavingsContract: EventEmitter<any> = new EventEmitter();
  requestLoad: EventEmitter<any> = new EventEmitter();
  openContractModal: EventEmitter<any> = new EventEmitter();
  onSavingsContractCreated: EventEmitter<any> = new EventEmitter();
  onSavingsPosted: EventEmitter<any> = new EventEmitter();
  onMakeWintdrawalFromStatement: EventEmitter<any> = new EventEmitter();
  onBreakInvestment: EventEmitter<any> = new EventEmitter();
  onSendInvestmentAccountStatement: EventEmitter<any> = new EventEmitter();
  onRequestLoadingFromBackend: EventEmitter<any> = new EventEmitter();
  onRequestLoadingEndFromBackend: EventEmitter<any> = new EventEmitter();
  onOpenApplicationsModal: EventEmitter<any> = new EventEmitter();
  onChangeSettingsView: EventEmitter<any> = new EventEmitter();
  openWorkflowModal: EventEmitter<any> = new EventEmitter();
  changePortfolioView: EventEmitter<any> = new EventEmitter();
  openOnboardSimulator: EventEmitter<any> = new EventEmitter();
  openGoLive: EventEmitter<any> = new EventEmitter();
  openmailevent: EventEmitter<any> = new EventEmitter();
  approvingRequest: EventEmitter<any> = new EventEmitter();
  approvingTreasuryRequest: EventEmitter<any> = new EventEmitter();
  rejectingTreasuryRequest: EventEmitter<any> = new EventEmitter();
  rejectingRequest: EventEmitter<any> = new EventEmitter();
  disbursingRequest: EventEmitter<any> = new EventEmitter();
  onEditApplicationsModal: EventEmitter<any> = new EventEmitter();
  onLoadPreviousLoad: EventEmitter<any> = new EventEmitter();
  onStartNewRepaymentFlow: EventEmitter<any> = new EventEmitter();
  onViewExpandedSchedule: EventEmitter<any> = new EventEmitter();
  showScheduleDetails: EventEmitter<any> = new EventEmitter();
  loadApprovals: EventEmitter<any> = new EventEmitter();
  showCollateralModal: EventEmitter<any> = new EventEmitter();
  showContactsModal: EventEmitter<any> = new EventEmitter();
  addGuarantorModal: EventEmitter<any> = new EventEmitter();
  loadRequests: EventEmitter<any> = new EventEmitter();
  contractHasBeenCreated: EventEmitter<any> = new EventEmitter();
  kycRequestFinished: EventEmitter<any> = new EventEmitter();
  analyseastatement: EventEmitter<any> = new EventEmitter();
  analysisComplete: EventEmitter<any> = new EventEmitter();
  showProfilePic: EventEmitter<any> = new EventEmitter();
  showIDCard: EventEmitter<any> = new EventEmitter();
  cardHasBeenTokennized: EventEmitter<any> = new EventEmitter();
  showPortfolioCalendarDetails: EventEmitter<any> = new EventEmitter();
  showPortfolioTarget: EventEmitter<any> = new EventEmitter();
  emitAnalytics: EventEmitter<any> = new EventEmitter();
  openContractConfirm: EventEmitter<any> = new EventEmitter();
  modifyContractModal: EventEmitter<any> = new EventEmitter();
  inviteNewCustomer: EventEmitter<any> = new EventEmitter();
  bookanewloan: EventEmitter<any> = new EventEmitter();
  inviteMultipleCustomers: EventEmitter<any> = new EventEmitter();
  breakLoanSentReloadRequest: EventEmitter<any> = new EventEmitter();
  postingBulkPayments: EventEmitter<any> = new EventEmitter();
  emitJournals: EventEmitter<any> = new EventEmitter();
  changeJournalView: EventEmitter<any> = new EventEmitter();
  reloadChartOfAccounts: EventEmitter<any> = new EventEmitter();
  removeTransactions: EventEmitter<any> = new EventEmitter();
  showMasterSideBar: EventEmitter<any> = new EventEmitter();
  platformSummaryLoaded: EventEmitter<any> = new EventEmitter();
  portfolioPlatformSummaryLoaded: EventEmitter<any> = new EventEmitter();
  portfolioRequestHasBeenLoaded: EventEmitter<any> = new EventEmitter();
  closeAnalyticsModal: EventEmitter<any> = new EventEmitter();
  approvalDone: EventEmitter<any> = new EventEmitter();
  modifyOfferTemplate: EventEmitter<any> = new EventEmitter();
  updateBureauSettings: EventEmitter<any> = new EventEmitter();
  updatingSettingsExternally: EventEmitter<any> = new EventEmitter();
  openRollOverActionModal: EventEmitter<any> = new EventEmitter();
  newBulkPayments: EventEmitter<any> = new EventEmitter();
  approvingPrepayment: EventEmitter<any> = new EventEmitter();
  breakingBulkLoans: EventEmitter<any> = new EventEmitter();
  previewScheduleDetails: EventEmitter<any> = new EventEmitter();
  acceptingContractIsDone: EventEmitter<any> = new EventEmitter();
  showProfileAnalysisModal: EventEmitter<any> = new EventEmitter();
  closeOverlayModal: EventEmitter<any> = new EventEmitter();
  openCalendarBulkRepayments: EventEmitter<any> = new EventEmitter();
  openTheCashFlowForTheDay: EventEmitter<any> = new EventEmitter();
  doOpenCustomerReportedPayments: EventEmitter<any> = new EventEmitter();
  enableBranches: EventEmitter<any> = new EventEmitter();
  disbaleBranches: EventEmitter<any> = new EventEmitter();
  enableLenderBranches: EventEmitter<any> = new EventEmitter();
  disbaleLenderBranches: EventEmitter<any> = new EventEmitter();
  branchesEnabled: EventEmitter<any> = new EventEmitter();
  addNewBranch: EventEmitter<any> = new EventEmitter();
  enableLoanProducts: EventEmitter<any> = new EventEmitter();
  disbaleLoanProducts: EventEmitter<any> = new EventEmitter();
  enableLenderProducts: EventEmitter<any> = new EventEmitter();
  disbaleLenderProducts: EventEmitter<any> = new EventEmitter();
  productsEnabled: EventEmitter<any> = new EventEmitter();
  addNewProduct: EventEmitter<any> = new EventEmitter();
  openUnderConstruction: EventEmitter<any> = new EventEmitter();
  closeCurrentlyOpenModal: EventEmitter<any> = new EventEmitter();
  closePAnalysisModal: EventEmitter<any> = new EventEmitter();
  uploadBulkPayment: EventEmitter<any> = new EventEmitter();
  downloadingBulkPayment: EventEmitter<any> = new EventEmitter();
  userhomeloaded: EventEmitter<any> = new EventEmitter();
  investmentHasLoaded: EventEmitter<any> = new EventEmitter();
  closeDisbursementModal:  EventEmitter<any> = new EventEmitter();
  doDisbursementConfirmation: EventEmitter<any> = new EventEmitter();
  reloadOfferLetter: EventEmitter<any> = new EventEmitter();
  refreshRequestList: EventEmitter<any> = new EventEmitter();
  openLenderAccountStatement: EventEmitter<any> = new EventEmitter();
  customerOpenOverlaySection: EventEmitter<any> = new EventEmitter();
  onOpenSendSMS: EventEmitter<any> = new EventEmitter();
  onOpenSendEmail: EventEmitter<any> = new EventEmitter();
  eligibilitySettingsChanged: EventEmitter<any> = new EventEmitter();
  emailTemplateUpdate: EventEmitter<any> = new EventEmitter();
  openmailadvertevent: EventEmitter<any> = new EventEmitter();
  sendForCarValuation: EventEmitter<any> = new EventEmitter();
  carVinLookupCompleted: EventEmitter<any> = new EventEmitter();
  getLoanFromTheDashboard: EventEmitter<any> = new EventEmitter();
  getInvestmentFromTheDashboard: EventEmitter<any> = new EventEmitter();
  sendBackLoanToCaller: EventEmitter<any> = new EventEmitter();
  sendMailToCustomer: EventEmitter<any> = new EventEmitter();
  openReminderMessageContent: EventEmitter<any> = new EventEmitter();
  emailMessageContentHasBeenUpdated: EventEmitter<any> = new EventEmitter();
  smsMessageContentHasBeenUpdated: EventEmitter<any> = new EventEmitter();
  openSMSReminderMessageContent: EventEmitter<any> = new EventEmitter();
  assignCollectionAgent: EventEmitter<any> = new EventEmitter();
  makeOfferForLoan: EventEmitter<any> = new EventEmitter();
  processBulkDisbursementHistory: EventEmitter<any> = new EventEmitter();
  confirmBulkPaymentApprovals: EventEmitter<any> = new EventEmitter();
  sendIndebtednessLetter: EventEmitter<any> = new EventEmitter();
  confirmBulkPaymentApprovalsDone: EventEmitter<any> = new EventEmitter();
  investmentScheduleCreated: EventEmitter<any> = new EventEmitter();
  approvingInvestmentRequest: EventEmitter<any> = new EventEmitter();
  viewCustomerStatement: EventEmitter<any> = new EventEmitter();
  showClusterAgentPayment: EventEmitter<any> = new EventEmitter();
  showClusterAgentReports: EventEmitter<any> = new EventEmitter();
  postTheAgentCollection: EventEmitter<any> = new EventEmitter();
  showClusterAgentCollections: EventEmitter<any> = new EventEmitter();
  createANewAgent: EventEmitter<any> = new EventEmitter();
  getDueRepaymentByDate: EventEmitter<any> = new EventEmitter();
  openSidemenuForDueLoan: EventEmitter<any> = new EventEmitter();
  viewBalanceGLStatement: EventEmitter<any> = new EventEmitter();
  closeTheNewInvestmentRequestForm: EventEmitter<any> = new EventEmitter();
  viewBalanceGLCustomerWallets: EventEmitter<any> = new EventEmitter();
  newinvestmentapproal: EventEmitter<any> = new EventEmitter();
  initiateInvestmentWithdrawal: EventEmitter<any> = new EventEmitter();
  deleteBUploadQueue: EventEmitter<any> = new EventEmitter();
  bulkUploadRecordCleared: EventEmitter<any> = new EventEmitter();
  updateAppLevel: EventEmitter<any> = new EventEmitter();
  launchReopenRequestModal: EventEmitter<any> = new EventEmitter();
  sendSMSToCustomer: EventEmitter<any> = new EventEmitter();
  requestForWorkEmail: EventEmitter<any> = new EventEmitter();
  refreshAgentsCollectionsList: EventEmitter<any> = new EventEmitter();
  runEligibility: EventEmitter<any> = new EventEmitter();
  eligibilitycriteriarefreshed: EventEmitter<any> = new EventEmitter();
  loadHomeCustomerSideview: EventEmitter<any> = new EventEmitter();
  loanHomeRequestSideView: EventEmitter<any> = new EventEmitter();
  loanHomePortfolioSideView: EventEmitter<any> = new EventEmitter();
  multipleInvitesSent: EventEmitter<any> = new EventEmitter();
  createANewCustomer: EventEmitter<any> = new EventEmitter();
  modifyLoanContract: EventEmitter<any> = new EventEmitter();
  sendloanstatement: EventEmitter<any> = new EventEmitter();
  sendstatementtocustomer: EventEmitter<any> = new EventEmitter();
  modifyLoanSchedule: EventEmitter<any> = new EventEmitter();
  pushingToMarket: EventEmitter<any> = new EventEmitter();
  restartWorkflowModal: EventEmitter<any> = new EventEmitter();
  queueForPartners: EventEmitter<any> = new EventEmitter();
  unqueueForPartners: EventEmitter<any> = new EventEmitter();
  openPartnerForm: EventEmitter<any> = new EventEmitter();
  queuefordisbnpl: EventEmitter<any> = new EventEmitter();
  loadPortfolioFromExcessRepayment: EventEmitter<any> = new EventEmitter();
  openSchedule: EventEmitter<any> = new EventEmitter();
  returnToRequests: EventEmitter<any> = new EventEmitter();
  loadportfoliorecords: EventEmitter<any> = new EventEmitter();
  loadrequestrecords: EventEmitter<any> = new EventEmitter();
  
  viewMerchant: EventEmitter<any> = new EventEmitter();
}
