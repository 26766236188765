import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-underconstruction',
  templateUrl: './underconstruction.component.html',
  styleUrls: ['./underconstruction.component.css']
})
export class UnderconstructionComponent implements OnInit {
  @Input('endDate') endDate = '1560812400000';
  constructor() { }

  ngOnInit() {
  }

}
