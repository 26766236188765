<div class="profile_area animated fadeIn" *ngIf="grantRight('26')">

  <div class="title flex justify-between">
    <span>Request Location</span>
    <button class="btn btn-outline-light"
      (click)="addressList=!addressList">{{addressList ? 'Map' : 'Address List'}}</button>
  </div>
  <!--<section class="panel contract_area hideable_area" style="margin-bottom: 10px">-->
  <!--<header class="panel-heading">-->
  <!--<span class="tools pull-right">-->
  <!--<ul class="list-inline">-->
  <!--<li *ngIf="where ==='1'">-->
  <!--<a [routerLink]="['../contract']">Summary</a>-->
  <!--</li>-->
  <!--<li>-->
  <!--<a (click)="addressList=!addressList">{{addressList ? 'Map' : 'Address List'}}</a>-->
  <!--</li>-->
  <!--<li *ngIf="where ==='1'">-->
  <!--<a [routerLink]="['../more']">More</a>-->
  <!--</li>-->
  <!--</ul>-->
  <!--</span>-->
  <!--&lt;!&ndash;<span>Request Location</span>&ndash;&gt;-->
  <!--</header>-->
  <!--</section>-->
  <section class="pd_10" *ngIf="addressList">

    <div class="alert">
      <div class="icon color-blue">
        <i class="fa fa-home"></i>
      </div>
      <div class="texts">
        <h5 class="title no-float">Home</h5>
        <p class="subtitle" *ngIf="!addresses?.home_address.has_home">None provided</p>
        <div *ngIf="addresses?.home_address.has_home">
          <p class="subtitle">{{addresses?.home_address.address.ADDRESS}}</p>
          <p class="subtitle">{{addresses?.home_address.address.LGA}}|{{addresses?.home_address.address.STATE_NAME}}
          </p>
        </div>
      </div>
    </div>
    <div class="alert">
      <div class="icon color-orange">
        <i class="fa fa-building"></i>
      </div>
      <div class="texts">
        <h5 class="title no-float">Office</h5>
        <p class="subtitle" *ngIf="!addresses?.work_address.has_work">None provided</p>
        <div *ngIf="addresses?.work_address.has_work">
          <p class="subtitle">{{addresses?.work_address.address.ADDRESS}}</p>
          <p class="subtitle">{{addresses?.work_address.address.STATE_NAME}}</p>
        </div>
      </div>
    </div>
    <div class="alert">
      <div class="icon color-green">
        <i class="fa fa-mobile"></i>
      </div>
      <div class="texts">
        <h5 class="title no-float">Request</h5>
        <p class="subtitle" *ngIf="loan?.REQUEST_LOCATION_UPDATED!=1">None provided</p>
        <div *ngIf="loan?.REQUEST_LOCATION_UPDATED=='1'">
          <p class="subtitle">{{loan?.REQUEST_ADDRESS}}</p>
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="!addressList">
    <div class="float-alert" *ngIf="loan?.WHERE_FROM=='2' || loan?.WHERE_FROM == '5'">
      <div class="alert alert-danger pd_15" *ngIf="loan?.NOLOCATION=='0'">No Location Found</div>
    </div>
    <app-userlocation [loan]="loan" (updateRequestAddress)="updateRequestAddress($event)"
      [latitude]="sendVal(loan?.LAT)" [longitude]="sendVal(loan?.LNG)" [latitude_]="LAT_" [longitude_]="LNG_">
    </app-userlocation>

    <section class="panel" *ngIf="loan?.WHERE_FROM=='4'">
      <div class="panel-body">
        <div class="text-center" style="padding: 66px 0 106px">
          <img src="assets/images/location.PNG">
          <div>
            <p class="t_l4"
              style="padding-bottom: 5px; display: inline-block; border-bottom: 1px solid #ccc; margin-top: 10px">
              Request Location Unknown</p>
            <p class="t_l4 t_cool">Applied by USSD</p>
          </div>
        </div>
      </div>
    </section>
    <section class="panel" *ngIf="loan?.WHERE_FROM=='3'">

      <div class="panel-body">
        <div class="text-center" style="padding: 66px 0 106px">
          <img src="assets/images/location.PNG">
          <div>
            <p class="t_l4"
              style="padding-bottom: 5px; display: inline-block; border-bottom: 1px solid #ccc; margin-top: 10px">
              Request Location Unknown</p>
            <p class="t_l4 t_cool">Applied by Back Office</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<div class="profile_area animated fadeIn" *ngIf="!grantRight('26')">
  <div>You have no right  to view this page</div>
</div>
