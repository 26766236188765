
<section class="panel padding-regular timeline-panel">
  <div class="flex justify-between align-center mb-5">
    <h3 class="section-title">Comments</h3>
    
    <div class="flex align-center">

      <app-button (click)="adding_comment = true" class="small outline teal ml-1" label="Comment"
        *ngIf="!adding_comment && !adding_task" icon="fa fa-plus">
      </app-button>

      <app-button (click)="adding_task = false; adding_comment = false" class="small outline red ml-1"
        *ngIf="adding_task || adding_comment" label="Close" icon="fa fa-times">
      </app-button>
    </div>
  </div>

  <div *ngIf="adding_comment">
    <form #ols="ngForm" novalidate>
      <div class="form-group">
        <textarea name="comment" [(ngModel)]="comment" cols="80" rows="10"></textarea>
      </div>
      
      <app-button type="submit" (click)="saveComment(ols.value, ols.valid)" [disabled]="loading || !comment" class="small outline orange"
        [loading]="loading" label="Send">
      </app-button>
      <hr>
    </form>
  </div>

  <div class="skeleton-children dark" *ngIf="isloading">
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
    <div class="panel" style="min-height: 130px; width: 100%">
    </div>
  </div>


  <div class="timeline mt-5">

    <article class="timeline-item" *ngFor="let item of comments; let idx = index" [ngClass]="{ alt: idx % 2 > 0 }">

      <div class="timeline-desk" *ngIf="idx % 2 > 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow"></span>
            <span class="timeline-icon red"></span>
            <span class="timeline-date">{{
              item?.created_at | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-orange default-text-size">
              {{ item?.created_at | amDateFormat: "D" }}
              {{ item?.created_at | amDateFormat: "MMMM" }} |
              {{ item?.created_at | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.name }} <br> <br>
              <span [innerHTML]="item?.message"></span>
            </p>
          </div>
        </div>

      </div>
      <div class="timeline-desk" *ngIf="idx % 2 == 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow-alt"></span>
            <span class="timeline-icon green"></span>
            <span class="timeline-date">{{
              item?.created_at  | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-teal default-text-size">
              {{ item?.created_at | amDateFormat: "D" }}
              {{ item?.created_at | amDateFormat: "MMMM" }} |
              {{ item?.created_at | amAdd: 1:"hours" | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.name }} <br> <br>

              <span [innerHTML]="item?.message"></span>
            </p>
          </div>
        </div>
      </div>
      
    </article>



  </div>

  <h3 *ngIf="!isloading && !comments">This request has no comment</h3>

</section>