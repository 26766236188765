import { Component, OnInit, ViewContainerRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OperationsService, StorageService, LoansService, DecisionService,AuthenticationService } from '../../_services/index';
import { OptionsserviceService } from '../../_services/optionsservice.service';
import { Observable } from 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';
import { LevelModel } from '../../_models/level.model';
import { IonRangeSliderComponent } from 'ng2-ion-range-slider';

@Component({
  selector: 'app-scoredbasedinterestrate',
  templateUrl: './scoredbasedinterestrate.component.html',
  styleUrls: ['./scoredbasedinterestrate.component.css']
})
export class ScoredbasedinterestrateComponent implements OnInit {
  @Input('where_from') where_from = 'self';
  @Input('interest_bands') interest_bands:any;
  @Input('product') product:any;
  @Output() changeView: EventEmitter<any> = new EventEmitter();
  is_edit = false;
  band = {
    "LENDER_ID": "",
    "LOWER_BAND": "",
    "HIGHER_BAND": "",
    "MINIMUM_RATE": "",
    "MAXIMUM_RATE": "",
    "MINIMUM_AMOUNT": "",
    "MAXIMUM_AMOUNT": "",
    "MINIMUM_DURATION": "",
    "MAXIMUM_DURATION": "",
    "LOAN_DURATION": "",
    "INTEREST_DURATION": "",
    "INTRREST_RATE_BAND_ID": ""
  }
  INTRREST_RATE_BAND_ID = 0;
  loading = false;
  currentUser:any;
  constructor(public authService:AuthenticationService,public toastr: ToastrService, vcr: ViewContainerRef, public loansService: LoansService, 
    public optionsService: OptionsserviceService,
    private router: Router, public storageService: StorageService,
    public operationsService: OperationsService,
    public decisionService: DecisionService) {
    this.currentUser = this.storageService.read<any>('currentUser');  

  }
  closeSettings(){
    this.changeView.emit();
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }

  ngOnInit() {
    this.operationsService.getInterestBands(this.currentUser.token)
      .subscribe(data => {
        
        this.interest_bands = data.interest_bands;
        this.band.HIGHER_BAND = '0';
        this.band.LOWER_BAND = '0';
        this.band.MINIMUM_RATE = '0';
        this.band.MAXIMUM_RATE = '0';
        this.band.MINIMUM_AMOUNT = '0';
        this.band.MAXIMUM_AMOUNT = '0';
        this.band.MINIMUM_DURATION = '0';
        this.band.MAXIMUM_DURATION = '0';
        this.band.INTEREST_DURATION = '2';
        this.band.LOAN_DURATION = '2';
        this.band.INTRREST_RATE_BAND_ID = '0';
      });
  }
  editBand(band) {
    this.is_edit = true;
    this.INTRREST_RATE_BAND_ID = band.INTRREST_RATE_BAND_ID;
    this.band = band;
  }
  deleteBand(band) {
    this.loading = true;
    this.decisionService.deleteBand(this.currentUser.token, band)
      .subscribe(data => {
        this.loading = false;
        if (data.status === true) {
          this.showSuccess(data.message)
          this.interest_bands = data.data.bands
        } else {
          this.showError(data.message)
        }
      });
  }
  saveInterestRateBand(value, valid) {
    this.loading = true;
    this.decisionService.addBand(this.currentUser.token, value, this.INTRREST_RATE_BAND_ID)
      .subscribe(data => {
        this.loading = false;
        if (data.status === true) {
          this.showSuccess(data.message)
          this.interest_bands = data.data.bands
        } else {
          this.showError(data.message)
        }
      });
  }
  saveReferral(value, valid) {
    this.loading = true;
    this.operationsService.saveReferral(this.currentUser.token, value)
      .subscribe(data => {
        this.loading = false;
        if (data.status === '1') {
          this.showSuccess(data.message)
        } else {
          this.showError(data.message)
        }
      });
  }

}
