import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help-popover',
  templateUrl: './help-popover.component.html',
  styleUrls: ['./help-popover.component.css']
})
export class HelpPopoverComponent implements OnInit {

  @Input() content: { title: string, text: string, img?: string }[];
  @Input() center = false;

  index = 0;
  showOverlay = false;
  showHelp = false;

  constructor() { }

  ngOnInit() {
    this.update();
    window.addEventListener('options', () => this.update());
  }

  update() {
    const options = JSON.parse(localStorage.getItem('options')) || { showHelp: false, autoVisible: false };
    this.showOverlay = options.autoVisible;
    this.showHelp = options.showHelp;
  }

  openOverlay() {
    this.showOverlay = true;
  }

  closeOverlay() {
    this.showOverlay = false;
    this.index = 0;
  }

  next() {
    this.index++;
  }

  previous() {
    this.index--;
  }
}
