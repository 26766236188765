<app-navbar></app-navbar>
<app-header></app-header>
<div class="cont">
  <div class="container">
    <div class="col-md-3">
      <div class="left-container">
        <app-sidebar></app-sidebar>
      </div>
    </div>
    <div class="col-md-4">
      <div class="middle-container">
        <div class="">
          <img src="assets/images/app.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="right-container">
        <h2 class="onb-title">Mobile Request</h2>
        <div class="" style="font-size: 17px">
          <div class="radio__flex">
            <div class="radio-inline">Start with a fantastic app
            </div>
            <label>
              <input type="radio" name="main_app" checked>
            </label>
          </div>
          <br>
          <div class="radio__flex" style="border-bottom: 1px solid #ccc; padding-bottom: 10px">
            <div class="radio-inline">Own a stand alone app
            </div>
            <input type="radio" name="main_app" onselect="changeView()">
          </div>
          <div style="padding-top: 10px">
            <ol>
              <li>To Simulate, Download the 700 lenders app</li>
              <li>Sign In with <span class="colored_text">femiapps@gmail.com</span></li>
              <li>Use the pin; <span class="colored_text">1234</span></li>
              <li>Go and make request</li>
              <li>Get ready to go live</li>
            </ol>
          </div>
          <hr/>
          <button
            (click)="simulate()"
            class="btn btn-block btn-default padded orange-btn"
          >
            Download 700 lenders
          </button>
          <div style="text-align: center;
    padding: 10px;
    font-size: 18px;"><a href="javascript:">Configure the settings</a></div>
        </div>

        <div class="" style="font-size: 17px" *ngIf="view == 'stand_alone'">
          <div>
            <ol>
              <li>Send an email to support@creditclan.com</li>
              <li>A reusable commitment cash is made</li>
              <li>We build your app in 4 weeks</li>
              <li>Test and ready to go live</li>
            </ol>
          </div>
          <hr/>
        </div>

      </div>
    </div>
  </div>
</div>
