import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataService, OperationsService, StorageService} from '../../../_services/index';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-approvalcalendar',
  templateUrl: './approvalcalendar.component.html',
  styleUrls: ['./approvalcalendar.component.css']
})
export class ApprovalcalendarComponent implements OnInit {
  loading = false;
  approval_history_count=false;
 url: string = 'https://creditclanapis.creditclan.com/api/v2/dashboard';
  state: any;
  schedule_type = false;
  record_type = '1';

  default_date: any;
  currentUser: any;

  calendarOptions: any;

  datechosen: any;
  overlayOpen = false;
@Input('token') token:any;
@Input('approval_history') approval_history:any;

  constructor (public dataService: DataService, public fb: FormBuilder, 
    public operationsService: OperationsService, public storageService: StorageService, private http: HttpClient) {
    this.currentUser = this.storageService.read<any>('currentUser');

  }

  ngOnInit () { 
    this.getCurrentApprovalCalendar();
    this.calendarOptions = {
      height: '1000',
      fixedWeekCount: false,
      // defaultDate: this.default_date,
      editable: true,
      eventLimit: true,
      eventClick: (calEvent, jsEvent, view) => {
        this.showdetails(calEvent);
      },
      events: (start, end, timezone, callback) => {
        this.http.post<any>(`${this.url}/getCurrentApprovalCalendar`, {token: this.token})
          .subscribe(data => {
            // this.default_date = data.default_date;
            //console.log(data);
            callback(data.events)
            
          });

      }
    };

  }

  getCurrentApprovalCalendar () {
    this.loading = true;
    this.http.post<any>(`${this.url}/getCurrentApprovalCalendar`, {token: this.token})
      .subscribe(data => {
        this.approval_history = data.approval_history;
        this.approval_history_count = data.has_data;
        this.loading = false;
      });
  }

  showdetails (calEvent) {
    this.datechosen = calEvent.start._i; 
    this.dataService.showCalendarDetails.emit({date_chosen: this.datechosen,from_where:'1',type:'0'});
  }

  closeOverlay () {
    this.overlayOpen = false; 
  }
}
