<div>
  <div class="list-item" (click)="openLoan(request)" [ngClass]="{'active': active}">
      <div class="image" [ngClass]="{'skeleton rounded': loading}">
          <img class="{{request | requeststatus}}" src="{{request?.FILE_NAME | imagenotfound}}">
      </div>
      <div class="body">
          <h3 class="title ellipsize" [ngClass]="{'skeleton': loading}">{{request?.LEGAL_NAME}}</h3>
          <h5 class="subtitle ellipsize mb-1" [ngClass]="{'hidden': loading}">{{request?.EMAIL}}</h5>
          <p [ngClass]="{'skeleton mt-1': loading}">
              <span class="first">{{request?.WORKSECTOR}}</span> 
          </p>
      </div>
      <div class="align-end flex flex-column icons justify-between ml-2">
          <div class="flex inline align-center"> 
              <span class="tag smaller blue ml-1" [ngClass]="{
          'skeleton fit-content': loading}" style="width: 75px !important;
          font-size: 10px !important;">
        {{request?.DATE_ADDED | amTimeAgo}}
      </span>
          </div> 
      </div>
  </div>
</div>