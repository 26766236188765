import { OnChanges, SimpleChanges, SimpleChange,Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  DataService,
  OptionsserviceService,
  LoansService,
  OperationsService,
  StorageService,
  AuthenticationService
} from '../../_services/index';

@Component({
  selector: 'app-repaymentschedule',
  templateUrl: './repaymentschedule.component.html',
  styleUrls: ['./repaymentschedule.component.css', './style.css', './boot-outline-btn.css']
})
export class RepaymentscheduleComponent implements OnInit,OnChanges {
  @Input('request_id') request_id: any;
  @Input('currentUser') currentUser: any;
  @Input('loan') loan: any;
  @Input('view_from') view_from = 'request';
  @Output() openContarct = new EventEmitter();
  @Output() invest = new EventEmitter();
  schedules: any;
  request_id_:any;
  OPEN_SCHEDULE = false;
  @Input('from_where') from_where = '1';
  public schedule: any;
  @Input('parentRouteId') parentRouteId: number;
  @Input('where') where = '1';
  public sub: any;
  public loading = false;
  public status = false;
  @Output() closePopUp = new EventEmitter();
  @Output() modifyContract = new EventEmitter();
  canEditSchedule = false;
  loan_status:any;

  constructor(private loansService: LoansService, private dataService:DataService, private authService: AuthenticationService,  private storageService: StorageService) {
    // this.dataService.requestLoad.subscribe(res => {
    //   this.getLoanSchedules();
    // });

  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.request_id;
    if (request_id.currentValue) {
      this.request_id_ = request_id.currentValue; 
      this.getLoanSchedules()

    }

  }
  ngOnInit() {
    //this.getLoanSchedules();
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  newContract(){
    this.openContarct.emit();
  }
  doInvest(){
    this.invest.emit();
  }
  getLoanSchedules(){
    // this.loansService.getLoanContractSimplest(this.currentUser.token, this.request_id_)
    //   .subscribe(data => {
        
    //   });
  //this.loan = data.loan;
  this.loansService.getLoanRepaymentsSchedule(this.currentUser.token, this.request_id_)
  .subscribe(repayments => {
    this.status = repayments.status;
    if (repayments.status) {
      this.schedule = repayments.data;
      this.canEditSchedule = repayments.can_modify_contract;
      this.loan_status = repayments.loan_status;
    } else {

    }

  });
  }
  getTotal(key, schedule) {
    if (schedule === undefined || schedule === null) { } else {
      let total = 0;
      if (key === 'OUTSTANDING_PRINCIPAL') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.OUTSTANDING_PRINCIPAL); }, 0);
      }
      if (key === 'TERM_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.TERM_REPAYMENT); }, 0);
      }
      if (key === 'PRINCIPAL_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.PRINCIPAL_REPAYMENT); }, 0);
      }
      if (key === 'HOW_MUCH_PAID') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.HOW_MUCH_PAID); }, 0);
      }
      if (key === 'HOW_MUCH_REMAINING') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.HOW_MUCH_REMAINING) }, 0);
      }
      if (key === 'INTEREST_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.INTEREST_REPAYMENT); }, 0);
      }
      return total;
    }
    //

  }
  showFullView() {
    this.dataService.showScheduleDetails.emit({request_id:this.request_id_,loan_status: this.loan_status, schedule: this.schedule, canEditSchedule:this.canEditSchedule});
  }
}
