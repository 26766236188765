<app-main-navigation *ngIf="!external"></app-main-navigation>
<section id="main-content">
    <div [ngClass]="{'app-container navbar-margin': !external, '': external}">
        <div [ngClass]="{'': !external, 'internal': external}">
            <div class="col-md-12" [ngClass]="{'pad0': !external, '': external}">
                <div id="ajax_view">
                    <div class="row">
                        <div [ngClass]="{'col-md-9 pright': !external, '': external}">
                            <div class="upload-form hide animated">
                                <h3>Uploading customers</h3>
                                <p>Download the excel recommended format for upoading customers</p>
                                <p></p>
                                <div class="btn-group">
                                    <button class="btn btn-white" type="button">
                                        <i class="fa fa-table"></i> Customers sample</button>
                                    <button data-toggle="dropdown" class="btn btn-white" type="button">
                                        <span class="fa fa-download"></span>
                                    </button>
                                </div>
                                <p></p>
                                <div style="margin:10px 0px">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" id="ATTACHMENT_ID" class="form-control "> </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void(0)" style="cursor:pointer;"
                                                class="staff-education_add_ btn btn-white btn-block">
                                                <i class="fa fa-upload"></i> Upload</a>
                                            <input class="uploadType" type="hidden" value="31">
                                            <input type="file" class="att-add-file" style="display:none"> </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="error file_up_error alert alert-block alert-danger fade in hide"
                                                style="margin:10px 0px !important"> Only excel files are
                                                allowed(.xls,.xlsx). </div>
                                        </div>
                                    </div>
                                    <div class="onboard_prev_ text-center hide animated" style="padding-top:20px">
                                        <div class="cssload-container">
                                            <div class="cssload-lt"></div>
                                            <div class="cssload-rt"></div>
                                            <div class="cssload-lb"></div>
                                            <div class="cssload-rb"></div>
                                        </div>
                                        <p>
                                            <i>loading...</i>
                                        </p>
                                    </div>
                                    <div class="onboard_prev" style="overflow:auto"> </div>
                                </div>
                            </div>
                            <div class="new-emp animated">
                                <section class="panel padding-regular" id="_profile_edit_parent">
                                    <div class="mb-5 flex align-center justify-between" *ngIf="!external">
                                        <a [routerLink]="['/customer/', userType, userId ]"
                                            class="btn btn-outline-secondary"><i class="fa fa-arrow-left mr-2"></i>Back to
                                                profile</a>
                                        <!-- Switch between Leads and Main Customer -->
                                        <!-- Open for editing and save -->
                                        <div>
                                            <!--  <a class="btn btn-white btn-xs open-for-editing" data-toggle="collapse" data-parent="#_profile_edit_parent" href="._profile_edit_toogle" aria-expanded="false" aria-controls="_profile_edit_toogle" (click)="toggleForm()">Open for Editing</a> -->
                                            <button class="btn btn-outline-secondary open-for-editing mr-2"
                                                data-toggle="collaps" data-parent="#_profile_edit_parent"
                                                aria-controls="_profile_edit_toogle" (click)="toggleForm()">Open for
                                                Editing</button>
                                            <a [routerLink]="['/customer/', userType, userId ]"
                                                class="btn btn-orange">Done <i
                                                    class="fa fa-spin fa-spinner hide"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Individual Profile Editing Section -->
                                    <ul class="" *ngIf="userType == 'individual'">
                                        <li class="panel pd_15">
                                            <div class="flex justify-between align-center">
                                                <h3 class="section-title">Basic Details</h3>
                                                <div>
                                                    <a (click)="toogleView('basicInfo')" data-sub="basic"
                                                        class="btn btn-link btn-sm">
                                                        <i class="fa fa-chevron-down"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="sub basic animated" *ngIf="model.basicInfo.open">
                                                <!-- Information preview side -->
                                                <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                    <div class="panel-body bio">
                                                        <div class="col-md-4">
                                                            <div class="form-group row profile-nav text-center"
                                                                style="margin-bottom:0;">
                                                                <input #file style="display: none"
                                                                    (change)="onFilesAdded($event)" type="file"
                                                                    accept=".png,.jpg,.jpeg">
                                                                <div class="user-heading round"
                                                                    style="background:none;padding-top: 0px !important;">
                                                                    <a class="staff-education_add"
                                                                        style="cursor:pointer;">
                                                                        <img class="prev_img_1"
                                                                            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507989748/{{model.dump.profile_photo == null ? 'avatar-mini.jpg' : model.dump.profile_photo}}">
                                                                    </a>
                                                                </div>
                                                                <button *ngIf="!external"
                                                                    style="margin-top:16px; margin-left:24px;"
                                                                    [disabled]="uploadingfile" class="btn btn-white"
                                                                    (click)="openUploadDialog()">{{btnText}}</button>
                                                            </div>
                                                        </div>
                                                        <div class="bio-d" style="overflow:hidden">
                                                            <div class="pro_head" style="    padding-top: 15px;">
                                                                <h4 class="no-margin blue_c"
                                                                    style=" color: blue; word-wrap:break-word">
                                                                    {{model.basicInfo.prev["LEGAL_NAME"]}}</h4>
                                                                <p>{{model.basicInfo.prev["EMAIL"]}}</p>
                                                                <div
                                                                    style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px; border-top: 1px solid #d9d9d9 ">
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Details</span>
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.prev["PHONE"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.prev["BIRTH_DAY"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.data["BIRTH_YEAR"]}}/{{model.basicInfo.data["BIRTH_MONTH"]}}/{{model.basicInfo.data["BIRTH_DAY"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.data["NATION"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.prev["GENDER"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.prev["MARITAL_STATUS"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- editable form -->
                                                <div *ngIf="openForm"
                                                    class="_profile_edit_toogle panel-body collapse then in ">
                                                    <div class="col-md-4">
                                                        <div class="form-group row profile-nav "
                                                            style="margin-bottom:0;">
                                                            <div class=" user-heading round"
                                                                style="background:none;padding-top: 0px !important;">
                                                                <a class="staff-education_add" style="cursor:pointer;">
                                                                    <img class="prev_img_1"
                                                                        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507989748/{{model.dump.profile_photo == null ? 'avatar-mini.jpg' : model.dump.profile_photo}}"
                                                                        style=""> </a>
                                                                <input type="hidden" name="ATTACHMENT1_ID"
                                                                    id="ATTACHMENT1_ID" value="">
                                                                <input type="hidden" name="PEOPLE_ID" id="PEOPLE_ID"
                                                                    value="6757">
                                                                <input type="hidden" id="IS_EDIT" value="1">
                                                                <input type="hidden" name="CUSTOMER_TYPE"
                                                                    id="CUSTOMER_TYPE" value="1">
                                                                <input class="uploadType" type="hidden" value="200">
                                                                <input type="file" class="att-add-file"
                                                                    style="display:none">
                                                                <input type="hidden" id="is_edit" value="1">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8 ">
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <label for="" class="control-label">Fullname</label>
                                                                <input type="text" class="form-control" id="LEGAL_NAME"
                                                                    name="LEGAL_NAME"
                                                                    [(ngModel)]="model.basicInfo.data['LEGAL_NAME']"
                                                                    placeholder="">
                                                                <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important"> Please enter your
                                                                    fullname </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <label for="" class="control-label">Email</label>
                                                                <input type="email" class="form-control" id="EMAIL"
                                                                    name="EMAIL"
                                                                    [(ngModel)]="model.basicInfo.data['EMAIL']"
                                                                    placeholder="">
                                                                <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important"> Please enter your
                                                                    email </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <label class="control-label">Phone</label>
                                                            </div>
                                                        </div>
                                                        <div class="input-group" style="margin-bottom:5px">
                                                            <div class="input-group-btn">
                                                                <button
                                                                    style="padding:0px; padding-left:5px; padding-right:5px"
                                                                    type="button" class="btn btn-white">
                                                                    <a class="f32 ext_sh" href="javascript:;">
                                                                        <span class="flag ng">(+234)</span>
                                                                    </a>
                                                                    <!--<span class="caret"></span>-->
                                                                </button>
                                                                <button style="padding:6px;" data-toggle="dropdown"
                                                                    class="btn btn-white dropdown-toggle" type="button">
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <!-- <ul class="dropdown-menu f32" style="padding:5px">
                                                
                                            </ul> -->
                                                            </div>
                                                            <input type="hidden" name="COUNTRY_CODE" id="COUNTRY_CODE"
                                                                value="+234">
                                                            <input type="hidden" name="MAX_LENGTH" id="MAX_LENGTH"
                                                                value="11">
                                                            <input type="text" name="PHONE" id="PHONE"
                                                                [(ngModel)]="model.basicInfo.data['PHONE']"
                                                                class="form-control"
                                                                style="border:1px solid #F0F0F0; height:32px">
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <div class="error phone_error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row" style="margin-bottom:5px;">
                                                            <div class="col-md-12 ">
                                                                <label class="control-label" style="padding:0;"> Date of
                                                                    birth </label>
                                                            </div>
                                                            <div class="col-md-4" style="padding-right:5px">
                                                                <select class="form-control" id="DAY" name="BIRTH_DAY"
                                                                    [(ngModel)]="model.basicInfo.data['BIRTH_DAY']">
                                                                    <option *ngFor="let day of model.dump.days"
                                                                        [value]="day">{{day}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-4" style="padding:0 5px;">
                                                                <select class="form-control" id="MONTH"
                                                                    name="BIRTH_MONTH"
                                                                    [(ngModel)]="model.basicInfo.data['BIRTH_MONTH']">
                                                                    <option *ngFor="let month of months"
                                                                        [value]="month.value">{{month.display}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-4" style="padding-left:5px;">
                                                                <select class="form-control" id="YEAR" name="BIRTH_YEAR"
                                                                    [(ngModel)]="model.basicInfo.data['BIRTH_YEAR']">
                                                                    <option *ngFor="let year of model.dump.years"
                                                                        [value]="year">{{year}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <div class="error rent_error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important"> </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row " style="margin-bottom:5px;">
                                                            <div class="col-md-6">
                                                                <label for="" class="control-label">Nationality</label>
                                                                <select class="form-control" id="NATIONALITY"
                                                                    name="NATIONALITY"
                                                                    [(ngModel)]="model.basicInfo.data['NATIONALITY']">
                                                                    <option value="0"></option>
                                                                    <option *ngFor="let country of model.dump.countries"
                                                                        [value]="country['COUNTRY_ID']">
                                                                        {{country["COUNTRY_NAME"]}} </option>
                                                                </select>
                                                                <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important"> Please select
                                                                    your
                                                                    nationality </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="" class="control-label">Gender</label>
                                                                <select class="form-control" id="GENDER" name="GNEDER"
                                                                    [(ngModel)]="model.basicInfo.data['GENDER']">
                                                                    <option *ngFor="let sex of gender"
                                                                        [value]="sex.value">
                                                                        {{sex.display}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row" style="margin-bottom:5px;">
                                                            <div class="col-md-12">
                                                                <label for="" class="control-label">Marital
                                                                    Status</label>
                                                                <select class="form-control" id="MARITAL_STATUS"
                                                                    name="MARITAL_STATUS"
                                                                    [(ngModel)]="model.basicInfo.data['MARITAL_STATUS']">
                                                                    <option *ngFor="let status of marital_status"
                                                                        [value]="status.value">{{status.display}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                <button (click)="updateBasicInfo()" type="button"
                                                                    [disabled]="loading"
                                                                    class="pull-right btn btn-white btn-block"
                                                                    id="updateBasicIdentity" style="padding: 6px 12px">
                                                                    <span *ngIf="!loading">Save</span>
                                                                    <i class="fa fa-spinner fa-spin"
                                                                        *ngIf="loading"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="panel pd_15">
                                            <div class="flex justify-between align-center">
                                                <div class="section-title">Address</div>
                                                <!-- <a href="javascript:;" data-sub="address" class="btn btn-outline-secondary">
                                                    <i class="fa fa-chevron-up"></i>
                                                </a> -->
                                                <a (click)="toogleView('address')" data-sub="address"
                                                    class="btn btn-link btn-sm co-panel_">
                                                    <i class="fa fa-chevron-down"></i>
                                                </a>
                                            </div>
                                            <div class="sub address animated mt-4" *ngIf="model.address.open">
                                                <!-- Address Preview -->
                                                <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                    <div class="panel-body bio">
                                                        <div class="bio-d" style="overflow:hidden">
                                                            <div class="pro_head" style="    padding-top: 15px;">
                                                                <div
                                                                    style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Address</span>
                                                                            <span
                                                                                class="pull-right">{{model.address.prev["HOME_ADDRESS"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Country</span>
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.data["HOME_COUNTRY"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">State/LGA</span>
                                                                            <span
                                                                                class="pull-right">{{model.basicInfo.data["HOME_STATE"]}}/{{model.basicInfo.data["HOME_LGA"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9; margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span class="pull-left text-primary">Proof
                                                                                of
                                                                                address</span>
                                                                            <span class="pull-right">Utility
                                                                                Bills</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div style="margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span class="pull-left text-primary">Nature
                                                                                of
                                                                                Accomodation</span>
                                                                            <span
                                                                                class="pull-right">{{model.address.prev["NOK"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.address.prev["TACA"]}}
                                                                                Years</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Address Form -->
                                                <div *ngIf="openForm"
                                                    class="_profile_edit_toogle panel-body collapse then in ">
                                                    <div class="form-group row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <input type="hidden" name="ADDRESS_ID" id="ADDRESS_ID"
                                                                value="">
                                                            <label class="control-label" style="padding:0;">
                                                                Country
                                                            </label>
                                                            <select id="COUNTRY_ID" name="COUNTRY_ID"
                                                                class="form-control" name="COUNTRY_ID"
                                                                [(ngModel)]="model.address.data['COUNTRY_ID']">
                                                                <option value="">select a country</option>
                                                                <option *ngFor="let country of model.dump.countries"
                                                                    [value]="country['COUNTRY_ID']">
                                                                    {{country["COUNTRY_NAME"]}} </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hide cty_error e">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="error alert alert-block alert-danger fade in  "
                                                                style="margin:5px 0px !important">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <label class="control-label" style="padding:0;">
                                                                State
                                                            </label>
                                                            <select id="STATE_ID" name="STATE_ID" class="form-control"
                                                                [(ngModel)]="model.address.data['STATE_ID']">
                                                                <option value="">Select an option</option>
                                                                <option value="1">Abia </option>
                                                                <option value="2">Adamawa </option>
                                                                <option value="3">Akwa Ibom </option>
                                                                <option value="4">Anambra </option>
                                                                <option value="5">Bauchi </option>
                                                                <option value="6">Bayelsa </option>
                                                                <option value="7">Benue </option>
                                                                <option value="8">Borno </option>
                                                                <option value="9">Cross River </option>
                                                                <option value="10">Delta </option>
                                                                <option value="11">Ebonyi </option>
                                                                <option value="12">Edo </option>
                                                                <option value="13">Ekiti </option>
                                                                <option value="14">Enugu </option>
                                                                <option value="15">FCT</option>
                                                                <option value="16">Gombe </option>
                                                                <option value="17">Imo </option>
                                                                <option value="18">Jigawa </option>
                                                                <option value="19">Kaduna </option>
                                                                <option value="20">Kano </option>
                                                                <option value="21">Katsina </option>
                                                                <option value="22">Kebbi </option>
                                                                <option value="23">Kogi </option>
                                                                <option value="24">Kwara </option>
                                                                <option value="25">Lagos </option>
                                                                <option value="26">Nasarawa </option>
                                                                <option value="27">Niger </option>
                                                                <option value="28">Ogun </option>
                                                                <option value="29">Ondo </option>
                                                                <option value="30">Osun </option>
                                                                <option value="31">Oyo </option>
                                                                <option value="32">Plateau </option>
                                                                <option value="33">Rivers </option>
                                                                <option value="34">Sokoto </option>
                                                                <option value="35">Taraba </option>
                                                                <option value="36">Yobe </option>
                                                                <option value="37">Zamfara </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hide st_error e">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="error alert alert-block alert-danger fade in  "
                                                                style="margin:5px 0px !important">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <label class="control-label" style="padding:0;">
                                                                Address
                                                            </label>
                                                            <label class="pull-right item-text-counter2">160</label>
                                                            <textarea id="ADDRESS" name="ADDRESS" class="form-control"
                                                                [(ngModel)]='model.address.data["ADDRESS"]'>{{model.address.data["ADDRESS"]}}</textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hide add_error e">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="error alert alert-block alert-danger fade in  "
                                                                style="margin:5px 0px !important">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <label class="control-label" style="padding:0;">
                                                                Landmark
                                                            </label>
                                                            <input class="form-control" id="LANDMARK" name="LANDMARK"
                                                                [(ngModel)]='model.address.data["LANDMARK"]'>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hide ld_error e">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="error alert alert-block alert-danger fade in  "
                                                                style="margin:5px 0px !important">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="hideAT">
                                                        <div class="form-group row">
                                                            <div class="col-md-3 col-md-offset-2">
                                                                <label class="control-label" style="padding:0;">
                                                                    Nature of Accom.
                                                                </label>
                                                                <select class="form-control"
                                                                    name="NATURE_OF_ACCOMODATION"
                                                                    [(ngModel)]='model.address.data["NATURE_OF_ACCOMODATION"]'>
                                                                    <option [value]="0">select nature of accomodation
                                                                    </option>
                                                                    <option [value]="1">Owned</option>
                                                                    <option [value]="2">Rent</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-5 hideAT ">
                                                                <label class="control-label" style="padding:0;">
                                                                    Time at Current Address (Years)
                                                                </label>
                                                                <input class="form-control" id="TIME_AT_CURRENT_ADDRESS"
                                                                    name="TIME_AT_CURRENT_ADDRESS"
                                                                    [(ngModel)]='model.address.data["TIME_AT_CURRENT_ADDRESS"]'>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row hide taca_error e">
                                                            <div class="col-md-8 col-md-offset-2">
                                                                <div class="error alert alert-block alert-danger fade in  "
                                                                    style="margin:5px 0px !important">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group hide hideExpiry row">
                                                            <div class="col-md-8 col-md-offset-2">
                                                                <label class="control-label" style="padding:0;">
                                                                    Expiry Date
                                                                </label>
                                                            </div>
                                                            <div class="hide">
                                                                <select class="form-control" id="DAY" name="DAY"
                                                                    [(ngModel)]="model.address.data['DAY']">
                                                                    <option *ngFor="let day of model.dump.days"
                                                                        [value]="day">{{day}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-4 col-md-offset-2 ">
                                                                <select class="form-control" id="MONTH" name="MONTH"
                                                                    [(ngModel)]="model.address.data['MONTH']">
                                                                    <option *ngFor="let month of months"
                                                                        [value]="month.value">{{month.display}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-4 ">
                                                                <select class="form-control" id="YEAR" name="YEAR"
                                                                    [(ngModel)]="model.address.data['YEAR']">
                                                                    <option *ngFor="let year of model.dump.years"
                                                                        [value]="year">{{year}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hideAT hide">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <label class="control-label" style="padding:0;">
                                                                Proof of address
                                                            </label>
                                                            <select class="form-control"
                                                                [(ngModel)]="model.address.data['ATTACHMENT_TYPE']">
                                                                <option [value]="1">Utility Bill</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="pp hide">
                                                        <div class="form-group row">
                                                            <div class="col-md-8 col-md-offset-2">
                                                                <label class="control-label" style="padding:0;">
                                                                    Upload file
                                                                </label>
                                                                <div
                                                                    style=" background:rgba(243,243,243,1.00); padding:5px; width:auto">
                                                                    <a href="javascript:void(0)"
                                                                        style="cursor:pointer;border-radius:0px"
                                                                        class="staff-education_add_2">
                                                                        <i class="fa fa-file"
                                                                            style="padding-right:5px"></i>
                                                                        <span class="bst_">Choose file</span>
                                                                    </a>
                                                                    <input type="hidden" name="ATTACHMENT_ID"
                                                                        id="ATTACHMENT_ID" value="">
                                                                    <input class="uploadType" type="hidden" value="8">
                                                                    <input type="file" class="att-add-file"
                                                                        style="display:none">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row hide p_error e">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="error alert alert-block alert-danger fade in  "
                                                                style="margin:5px 0px !important">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <button (click)="updateAddress()" type="button"
                                                                [disabled]="loading"
                                                                class="pull-right btn btn-white btn-block"
                                                                id="updateBasicIdentity" style="padding: 6px 12px">
                                                                <span *ngIf="!loading">Save</span>
                                                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                            </button>
                                                            <!-- <a (click)="updateAddress()" id="addAddress" class="btn btn-block btn-blue btn-primary addAddress " style="border-radius: 3px; padding: 6px 12px">Submit <i class="fa fa-spinner fa-spin hide"></i></a> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <!--<li class="title">Work Information
                                            <a href="javascript:;" data-sub="work" class="pull-right co-panel hide" style="color:#000"><i class="fa fa-chevron-up"></i></a>
                                            <a (click)="toogleView('work')" data-sub="work" class="pull-right co-panel_ fadeIn" style="color:#000"><i class="fa fa-chevron-down"></i></a>
                                        </li>
                                         <li class="sub work animated" *ngIf="model.work.open">
                                             Work Information Preview -->
                                        <!-- <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                <div class="panel-body bio">
                                                    <div class="bio-d" style="overflow:hidden">
                                                        <div class="pro_head" style="    padding-top: 15px;">
                                                            <div style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Occupation</span>
                                                                        <span class="pull-right">{{model.work.prev["OCCUPATION"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Sector</span>
                                                                        <span class="pull-right">{{model.work.prev["SECTOR"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Details</span>
                                                                        <span class="pull-right">{{model.work.prev["COMPANY_OF_WORK_ID"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <span class="pull-right">{{model.work.prev["WORK_ADDRESS"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                                    
                                                                    <div class="form-group">
                                                                        <span class="pull-right">{{model.work.prev["COUNTRY_NAME"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Started</span>
                                                                        <span class="pull-right">{{model.work.prev["WORK_END_DATE"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Salary</span>
                                                                        <span class="pull-right">{{model.work.prev["NET_MONTHLY_INCOME"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        <!-- Work Information -->
                                        <!-- <div *ngIf="openForm" class="_profile_edit_toogle panel-body collapse then in ">
                                                <div class="row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <section class="panel vvi vviwic" style="background:none">
                                                            <style type="text/css">
                                                            .form-group {
                                                                margin-bottom: 5px !important;
                                                            }
                                                            
                                                            hr {
                                                                margin: 10px 0px !important;
                                                                border-color: #eaeaea !important;
                                                            }
                                                            
                                                            .control-label {
                                                                color: #0b426d;
                                                                font-size: 13px;
                                                                padding-top: 0px !important;
                                                            }
                                                            
                                                            .form-control {
                                                                font-size: 12px;
                                                            }
                                                            
                                                            .control-label {
                                                                margin-bottom: 0px;
                                                            }
                                                            
                                                            .panel-heading .nav a.ftab {
                                                                color: #618cae;
                                                            }
                                                            
                                                            .panel-heading.nav li .active a.ftab {
                                                                background: #618cae;
                                                                color: #fff !important;
                                                            }
                                                            
                                                            .panel-heading .nav li .active a.ftab:hover {
                                                                background: #618cae;
                                                                color: #fff !important;
                                                            }
                                                            </style>
                                                            <div class="form-group row occupation">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        Occupation
                                                                    </label>
                                                                    <select id="OCCUPATION_ID" name="OCCUPATION_ID" class="form-control" [(ngModel)]="model.work.data['OCCUPATION_ID']">
                                                                        <option value=""></option>
                                                                        <option *ngFor="let key of keysGetter(model.dump.occupation)" [value]="model.dump.occupation[key]['OCCUPATION_ID']"> {{model.dump.occupation[key]["DESCRIPTION"]}} </option>
                                                                    </select>
                                                                    <input type="hidden" name="PEOPLE_WORK_ID" id="PEOPLE_WORK_ID" [(ngModel)]="model.work.data['PEOPLE_WORK_ID']">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row income not_for_un for_ret ">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        <span> Approximate Monthly Income</span>
                                                                    </label>
                                                                    <div class="input-group ">
                                                                        <input type="text" name="NET_MONTHLY_INCOME" id="NET_MONTHLY_INCOME" class="form-control fmoney" [(ngModel)]="model.work.data['NET_MONTHLY_INCOME']">
                                                                        <input type="hidden" name="COUNTRY_CODE" id="COUNTRY_CODE" value="">
                                                                        <div class="input-group-btn">
                                                                            <button type="button" style="color:#000" class="btn btn-white dropdown-toggle f16" data-toggle="dropdown" aria-expanded="false"><a class="ext_sh" href="javascript:;"><span class=""> ₦</span></a> <span class="caret"></span></button>
                                                                            <ul class="dropdown-menu" style="min-width:89px">
                                                                                <li><a href="javascript:;" data-ext="ng" data-curr="1" data-tt="₦" class="flagger"> ₦</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group not_for_un not_for_ret row company ">
                                                                        <div class="col-md-12">
                                                                            <label class="control-label" style="padding:0;">
                                                                                I work in
                                                                            </label>
                                                                            <select class="form-control" name="WORK_SECTOR" [(ngModel)]="model.work.data['WORK_SECTOR']">
                                                                                <option></option>
                                                                                <option *ngFor="let key of keysGetter(model.dump.sector)" [value]="model.dump.sector[key]['OCCUPATION_ID']"> {{model.dump.sector[key]["DESCRIPTION"]}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group not_for_un not_for_ret row company ">
                                                                        <div class="col-md-12">
                                                                            <label class="control-label" style="padding:0;">
                                                                                Designation
                                                                            </label>
                                                                            <select class="form-control" name="WORK_DESIGNATION" [(ngModel)]="model.work.data['WORK_DESIGNATION']">
                                                                                <option></option>
                                                                                <option *ngFor="let work of model.dump.work_desig" [value]="work['OCCUPATION_ID']"> {{work["DESCRIPTION"]}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group not_for_un not_for_ret row institution  hide">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        I school in
                                                                    </label>
                                                                    <select class="form-control" name="EDU_SECTOR" [(ngModel)]="model.work.data['EDU_SECTOR']">
                                                                        <option></option>
                                                                        <option *ngFor="let key of keysGetter(model.dump.edusector)" [value]="model.dump.edusector[key]['OCCUPATION_ID']"> {{model.dump.edusector[key]["DESCRIPTION"]}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-group not_for_un for_ret row company ">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        Company
                                                                    </label>
                                                                    <input type="text" name="COMPANY_OF_WORK_ID" id="COMPANY_OF_WORK_ID" class="form-control " [(ngModel)]="model.work.data['COMPANY_OF_WORK_ID']" style="">
                                                                </div>
                                                            </div>
                                                            <div class="form-group not_for_un not_for_ret row institution hide">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        Institution Name
                                                                    </label>
                                                                    <input type="text" name="INSTITUTION_ID" id="INSTITUTION_ID" class="form-control " [(ngModel)]="model.work.data['INSTITUTION_ID']" style="">
                                                                </div>
                                                            </div>
                                                            <div class="form-group company institution row not_for_un for_ret ">
                                                                <input type="hidden" name="ADDRESS_ID" id="ADDRESS_ID" value="">
                                                                <div class="col-md-6">
                                                                    <label class="control-label" style="padding:0;">
                                                                        Country
                                                                    </label>
                                                                    <select id="COUNTRY_ID_" name="COUNTRY_ID_" class="form-control" [(ngModel)]="model.work.data['COUNTRY_ID_']">
                                                                        <option value="0"></option>
                                                                        <option *ngFor="let country of model.dump.countries" [value]="country['COUNTRY_ID']"> {{country["COUNTRY_NAME"]}} </option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label class="control-label" style="padding:0;">
                                                                        State
                                                                    </label>
                                                                    <select id="STATE_ID_" name="STATE_ID_" class="form-control" [(ngModel)]="model.work.data['STATE_ID_']">
                                                                        <option value="">Select an option</option>
                                                                        <option value="1">Abia </option>
                                                                        <option value="2">Adamawa </option>
                                                                        <option value="3">Akwa Ibom </option>
                                                                        <option value="4">Anambra </option>
                                                                        <option value="5">Bauchi </option>
                                                                        <option value="6">Bayelsa </option>
                                                                        <option value="7">Benue </option>
                                                                        <option value="8">Borno </option>
                                                                        <option value="9">Cross River </option>
                                                                        <option value="10">Delta </option>
                                                                        <option value="11">Ebonyi </option>
                                                                        <option value="12">Edo </option>
                                                                        <option value="13">Ekiti </option>
                                                                        <option value="14">Enugu </option>
                                                                        <option value="15">FCT</option>
                                                                        <option value="16">Gombe </option>
                                                                        <option value="17">Imo </option>
                                                                        <option value="18">Jigawa </option>
                                                                        <option value="19">Kaduna </option>
                                                                        <option value="20">Kano </option>
                                                                        <option value="21">Katsina </option>
                                                                        <option value="22">Kebbi </option>
                                                                        <option value="23">Kogi </option>
                                                                        <option value="24">Kwara </option>
                                                                        <option value="25">Lagos </option>
                                                                        <option value="26">Nasarawa </option>
                                                                        <option value="27">Niger </option>
                                                                        <option value="28">Ogun </option>
                                                                        <option value="29">Ondo </option>
                                                                        <option value="30">Osun </option>
                                                                        <option value="31">Oyo </option>
                                                                        <option value="32">Plateau </option>
                                                                        <option value="33">Rivers </option>
                                                                        <option value="34">Sokoto </option>
                                                                        <option value="35">Taraba </option>
                                                                        <option value="36">Yobe </option>
                                                                        <option value="37">Zamfara </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-group company institution row not_for_un for_ret  ">
                                                                <div class="col-md-12">
                                                                    <label class="control-label" style="padding:0;">
                                                                        Address
                                                                    </label>
                                                                    <label class="pull-right item-text-counter2">160</label>
                                                                    <textarea id="ADDRESS_" name="ADDRESS_" class="form-control" [(ngModel)]="model.work.data['ADDRESS_']">{{model.work.data['ADDRESS_']}}</textarea>
                                                                </div>
                                                            </div>
                                                            <div class="not_for_un form-group row for_ret  hide">
                                                                <div class="col-md-6">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <label for="" class="control-label">Retire Month</label>
                                                                            <select id="WORK_RETIRED_DATE" name="WORK_RETIRED_DATE" class="form-control" [(ngModel)]="model.work.data['WORK_RETIRED_DATE']">
                                                                                <option value=""></option>
                                                                                <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-md-6 ">
                                                                            <label for="" class="control-label">Retire Year</label>
                                                                            <select id="WORK_RETIRED_YEAR" name="WORK_RETIRED_YEAR" class="form-control" [(ngModel)]="model.work.data['WORK_RETIRED_YEAR']">
                                                                                <option value=""></option>
                                                                                <option *ngFor="let year of model.dump.years" [value]="year">{{year}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <label for="" class="control-label">Years of service</label>
                                                                    <input type="text" class="form-control" id="YEARS_OF_SERVICE" [(ngModel)]="model.work.data['YEARS_OF_SERVICE']">
                                                                </div>
                                                            </div>
                                                            <div class="not_for_un form-group institution income row not_for_ret  ">
                                                                <div class="col-md-6">
                                                                    <label for="" class="control-label">Start Month</label>
                                                                    <select id="WORK_START_DATE" name="WORK_START_DATE" class="form-control" [(ngModel)]="model.work.data['WORK_START_DATE']">
                                                                        <option value=""></option>
                                                                        <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-6 ">
                                                                    <label for="" class="control-label">Start Year</label>
                                                                    <select id="WORK_END_DATE" name="WORK_END_DATE" class="form-control" [(ngModel)]="model.work.data['WORK_END_DATE']">
                                                                        <option value=""></option>
                                                                        <option *ngFor="let year of model.dump.years" [value]="year">{{year}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <a href="javascript:;" (click)="updateWork()" class="btn btn-blue btn-primary btn-block" style="padding: 6px 12px">Save <i class="fa fa-spinner fa-spin hide"></i></a>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div> 
                                        </li> -->
                                        <!-- <li class="title">Identity Cards
                                            <a href="javascript:;" data-sub="idcard" class="pull-right co-panel hide" style="color:#000"><i class="fa fa-chevron-up"></i></a>
                                            <a (click)="toogleView('id')" data-sub="idcard" class="pull-right co-panel_ fadeIn" style="color:#000"><i class="fa fa-chevron-down"></i></a>
                                        </li> -->
                                        <!-- <li class="sub idcard animated" *ngIf="model.id.open"> -->
                                        <!-- ID Card Preview -->
                                        <!-- <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                <div class="panel-body bio">
                                                    <div class="col-md-3" style="margin-right: 20px">
                                                        <div class="round" style="background:none;padding-top: 0px !important;">
                                                            <a class="staff-education_add" style="cursor:pointer;">
                                                                <img class="prev_img_1" src="http://app.creditclan.com/creditclan_demo/pub/attachments/front-of-id-2d997d24ce644eb963bdb7c7500c1cb4.jpg">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="bio-d" style="overflow:hidden">
                                                        <div class="pro_head">
                                                            <h4 class="no-margin blue_c" style=" color: blue; word-wrap:break-word">{{model.id.prev["NAME_ON_ID"]}}</h4>
                                                            <p>{{model.id.prev["IDENTY_TYPE"]}}</p>
                                                            <p class="no-margin">{{model.id.prev["IDENTITY_CODE"]}}</p>
                                                            <p class="no-margin">{{model.id.prev["EXP"]}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        <!-- ID Card Form -->
                                        <!-- <div *ngIf="openForm" class="_profile_edit_toogle panel-body collapse then in  row">
                                                <div class="col-md-4">
                                                    <div class="form-group row profile-nav " style="margin-bottom:0;"> -->
                                        <!--<label for="" class="col-md-3 col-sm-3 control-label">Profile Photo</label> -->
                                        <!-- <div class=" user-heading" style="background:none;padding-top: 0px !important;">
                                                            <a class="staff-education_add" style="cursor:pointer;">
                                                                <img class="prev_img_101" style=" border-radius:0px !important; width: 75px; border:2px solid #5294C6;   height: 75px;" src="http://app.creditclan.com/creditclan_demo/pub/attachments/front-of-id-2d997d24ce644eb963bdb7c7500c1cb4.jpg" alt="Front of id">
                                                            </a>
                                                            <input type="hidden" name="ATTACHMENT101_ID" id="ATTACHMENT101_ID" value="">
                                                            <input class="uploadType" type="hidden" value="201">
                                                            <input type="file" class="att-add-file" style="display:none">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label class="control-label" style="">
                                                                Identity Type
                                                            </label>
                                                            <select class="form-control" name="IDENTY_TYPE_ID" id="IDENTY_TYPE_ID" [(ngModel)]="model.id.data['IDENTY_TYPE_ID']">
                                                                <option [value]="1" selected="">International Passport</option>
                                                                <option [value]="2">Driver's License</option>
                                                                <option [value]="3">National ID Card</option>
                                                                <option [value]="4">Voters Card</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label class="control-label" style=""> Full Name on Id
                                                            </label>
                                                            <input type="text" class="form-control" name="LEGAL_NAME" id="LEGAL_NAME" [(ngModel)]="model.id.data['LEGAL_NAME']">
                                                            <input type="hidden" name="IDENTITY_ID" id="IDENTITY_ID" value="">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" style="">
                                                        <div class="col-md-12">
                                                            <label class="control-label"><span class="idtype">ID Number</span></label>
                                                            <input type="text" class="form-control" name="IDENTITY_CODE" id="IDENTITY_CODE" [(ngModel)]="model.id.data['IDENTITY_CODE']">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12 ">
                                                            <label class="control-label" style="padding:0;">
                                                                Expiry Date
                                                            </label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control" name="IDMONTH" [(ngModel)]="model.id.data['MONTH']">
                                                                <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control" name="IDYEAR" [(ngModel)]="model.id.data['YEAR']">
                                                                <option *ngFor="let a of maxDuration; let index = index" [value]="startDate + index">{{ startDate + index }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <a (click)="updateId()" id="updateID" class="  btn btn-block btn-blue btn-primary updateID" style="padding: 6px 12px">Submit <i class="fa fa-spinner fa-spin hide"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> -->
                                        <li class="panel pd_15">
                                            <div class="flex justify-between align-center">
                                                <h3 class="section-title">Next of Kin Information and Dependants</h3>
                                                <!-- <a href="javascript:;" data-sub="nextofkin" class="pull-right co-panel hide"
                                                    style="color:#000">
                                                    <i class="fa fa-chevron-up"></i>
                                                </a> -->
                                                <a (click)="toogleView('nok')" data-sub="nextofkin"
                                                    class="btn btn-link btn-sm co-panel_">
                                                    <i class="fa fa-chevron-down"></i>
                                                </a>
                                            </div>
                                            <div class="sub nextofkin animated" *ngIf="model.nok.open">
                                                <!-- Next of Kin Preview -->
                                                <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                    <div class="panel-body bio">
                                                        <div class="bio-d" style="overflow:hidden">
                                                            <div class="pro_head" style="    padding-top: 15px;">
                                                                <div
                                                                    style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span class="pull-left text-primary">Next of
                                                                                Kin</span>
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NOK_NAME"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Details</span>
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NOK_PHONE"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NOK_ADDRESS"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NOK_EMAIL"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NOK_RELATIONSHIP"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div style="margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span class="pull-left text-primary">Number
                                                                                of
                                                                                Dependants</span>
                                                                            <span
                                                                                class="pull-right">{{model.nok.prev["NUMBER_OF_DEPENDANTS"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Next of Kin Form -->
                                                <div *ngIf="openForm"
                                                    class="_profile_edit_toogle panel-body collapse then in ">
                                                    <div class="row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="">
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label" for="">Name</label>
                                                                        <input name="NOK_NAME" class="form-control"
                                                                            id="NOK_NAME" type="text" placeholder=""
                                                                            [(ngModel)]="model.nok.data['NOK_NAME']">
                                                                        <div class="error nok_error alert alert-block alert-danger fade in hide"
                                                                            style="margin:5px 0px !important">
                                                                            Enter the next of kin name
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label" for="">Next Of Kin
                                                                            Phone</label>
                                                                        <input name="NOK_PHONE" class="form-control"
                                                                            id="NOK_PHONE" type="text" placeholder=""
                                                                            [(ngModel)]="model.nok.data['NOK_PHONE']">
                                                                        <div class="error nop_error alert alert-block alert-danger fade in hide"
                                                                            style="margin:5px 0px !important">
                                                                            Enter the next of kin phone
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label" for="">Next Of
                                                                            Address</label>
                                                                        <textarea class="form-control"
                                                                            name="NOK_ADDRESS" id="NOK_ADDRESS"
                                                                            cols="30" rows="3"
                                                                            [(ngModel)]="model.nok.data['NOK_ADDRESS']"></textarea>
                                                                        <div class="error noad_error alert alert-block alert-danger fade in hide"
                                                                            style="margin:5px 0px !important">
                                                                            Enter the next of kin address
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="error noe__error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important">
                                                                    Enter the next of kin email
                                                                </div>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label" for="">Next Of Kin
                                                                            Email</label>
                                                                        <input name="NOK_EMAIL" class="form-control"
                                                                            id="NOK_EMAIL" type="text" placeholder=""
                                                                            [(ngModel)]="model.nok.data['NOK_EMAIL']">
                                                                        <div class="error noe_error alert alert-block alert-danger fade in hide"
                                                                            style="margin:5px 0px !important">
                                                                            Enter the next of kin email
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="error noe__error alert alert-block alert-danger fade in hide"
                                                                    style="margin:5px 0px !important">
                                                                    Enter the next of kin email
                                                                </div>
                                                                <hr>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label" for="">Number of
                                                                            Dependants</label>
                                                                        <input name="NUMBER_OF_DEPENDANTS"
                                                                            class="form-control"
                                                                            id="NUMBER_OF_DEPENDANTS" type="text"
                                                                            placeholder=""
                                                                            [(ngModel)]="model.nok.data['NUMBER_OF_DEPENDANTS']">
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <button (click)="updateNok()" type="button"
                                                                            [disabled]="loading"
                                                                            class="pull-right btn btn-white btn-block"
                                                                            id="updateBasicIdentity"
                                                                            style="padding: 6px 12px">
                                                                            <span *ngIf="!loading">Save</span>
                                                                            <i class="fa fa-spinner fa-spin"
                                                                                *ngIf="loading"></i>
                                                                        </button>
                                                                        <!-- <a (click)="updateNok()" id="addNextOfKin" class="  btn btn-block btn-blue btn-primary addNextOfKin" style="padding: 6px 12px">Submit <i class="fa fa-spinner fa-spin hide"></i></a> -->

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="panel pd_15">
                                            <div class="flex justify-between align-center">

                                                <h3 class="section-title">Education Information</h3>
                                                <!-- <a href="javascript:;" data-sub="edu" class="pull-right co-panel hide"
                                                style="color:#000">
                                                <i class="fa fa-chevron-up"></i>
                                            </a> -->
                                                <a (click)="toogleView('edu')" data-sub="education"
                                                    class="btn btn-link btn-sm co-panel_ fadeIn">
                                                    <i class="fa fa-chevron-down"></i>
                                                </a>
                                            </div>
                                            <div class="sub education animated" *ngIf="model.edu.open">
                                                <!-- Next of Kin Preview -->
                                                <div *ngIf="!openForm" class="_profile_edit_toogle collapse in">
                                                    <div class="panel-body bio">
                                                        <div class="bio-d" style="overflow:hidden">
                                                            <div class="pro_head" style="padding-top: 15px;">
                                                                <div
                                                                    style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Institution</span>
                                                                            <span
                                                                                class="pull-right">{{model.edu.prev["EDUCATION_INSTITUTION"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Level</span>
                                                                            <span class="pull-right">
                                                                                <span *ngFor="let level of edu_levels">
                                                                                    <span
                                                                                        *ngIf="level.EDUCATION_LEVEL_ID == model.edu.prev['EDUCATION_LEVEL_ID']">{{level.EDUCATION_LEVEL}}</span>
                                                                                </span>
                                                                            </span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="pull-left text-primary">Qualification</span>
                                                                            <span
                                                                                class="pull-right">{{model.edu.prev["EDUCATION_QUALIFICATION"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                        <div class="form-group">
                                                                            <span class="pull-left text-primary">Other
                                                                                Qualification</span>
                                                                            <span
                                                                                class="pull-right">{{model.edu.prev["SPECIFY_QUALIFICATIONS"]}}</span>
                                                                            <div class="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Next of Kin Form -->
                                                <div *ngIf="openForm"
                                                    class="_profile_edit_toogle panel-body collapse then in ">
                                                    <div class="row">
                                                        <div class="col-md-8 col-md-offset-2">
                                                            <div class="">
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label"
                                                                            for="">Institution</label>
                                                                        <textarea class="form-control"
                                                                            name="NOK_ADDRESS" id="NOK_ADDRESS"
                                                                            cols="30" rows="3"
                                                                            [(ngModel)]="model.edu.data['EDUCATION_INSTITUTION']"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label"
                                                                            for="">Level</label>
                                                                        <select class="form-control" id="MONTH"
                                                                            name="BIRTH_MONTH"
                                                                            [(ngModel)]="model.edu.data['EDUCATION_LEVEL_ID']">
                                                                            <option *ngFor="let level of edu_levels"
                                                                                [value]="level.EDUCATION_LEVEL_ID">
                                                                                {{level.EDUCATION_LEVEL}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row">
                                                                    <div class="col-md-12">
                                                                        <label class="control-label"
                                                                            for="">Qualification</label>
                                                                        <input name="NOK_NAME" class="form-control"
                                                                            id="NOK_NAME" type="text" placeholder=""
                                                                            [(ngModel)]="model.edu.data['EDUCATION_QUALIFICATION']">
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <a (click)="updateEducation()" id="addNextOfKin"
                                                                            class="  btn btn-block btn-blue btn-primary addNextOfKin"
                                                                            style="padding: 6px 12px">Submit
                                                                            <i class="fa fa-spinner fa-spin hide"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <!-- Corporate Profile Editing Section -->
                                    <ul class="frm" *ngIf="userType == 'corporate'">
                                        <li class="title basic_title">Basic details
                                            <a href="javascript:;" data-sub="basic" class="pull-right co-panel hide"
                                                style="color:#000">
                                                <i class="fa fa-chevron-up"></i>
                                            </a>
                                            <a (click)="toogleView('basicInfo')" href="javascript:;" data-sub="basic"
                                                class="pull-right co-panel_ fadeIn" style="color:#000">
                                                <i class="fa fa-chevron-down"></i>
                                            </a>
                                        </li>
                                        <li class="sub basic animated" *ngIf="model.basicInfo.open">
                                            <!-- Information preview side -->
                                            <div *ngIf="!openForm" class="_profile_edit_toogle collapse in"
                                                aria-expanded="true">
                                                <div class="panel-body bio">
                                                    <div class="bio-d" style="overflow:hidden">
                                                        <div class="pro_head" style="    padding-top: 15px;">
                                                            <div
                                                                style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <h4 class="no-margin blue_c"
                                                                            style=" color: blue; word-wrap:break-word">
                                                                            {{model.basicInfo.prev["LEGAL_NAME"]}}</h4>
                                                                        <p>{{model.basicInfo.prev["BUSINESS_REG_NUMBER"]}}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span
                                                                            class="pull-left text-primary">Email</span>
                                                                        <span
                                                                            class="pull-right">{{model.basicInfo.prev["EMAIL"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9; margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span
                                                                            class="pull-left text-primary">Website</span>
                                                                        <span
                                                                            class="pull-right">{{model.basicInfo.prev["WEBSITE"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9; margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Nature of
                                                                            Business</span>
                                                                        <span
                                                                            class="pull-right">{{model.basicInfo.prev["NATURE_OF_BUSINESS"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9; margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Tax ID
                                                                            Number</span>
                                                                        <span
                                                                            class="pull-right">{{model.basicInfo.prev["TAX_ID_NUMBER"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Phone
                                                                            Number</span>
                                                                        <span
                                                                            class="pull-right">{{model.basicInfo.prev["PHONE"]}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="openForm"
                                                class="_profile_edit_toogle panel-body collapse then in "
                                                aria-expanded="false" style="height: 30px;">
                                                <!-- Main Form -->
                                                <div class="col-md-8 col-md-offset-2"
                                                    style="border-bottom: 1px solid #ccc; padding-bottom: 20px; margin-bottom: 20px;">
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Company Name</label>
                                                            <input type="text" class="form-control" id="LEGAL_NAME"
                                                                name="LEGAL_NAME"
                                                                [(ngModel)]="model.basicInfo.data['LEGAL_NAME']"
                                                                placeholder="">
                                                            <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                style="margin:5px 0px !important">
                                                                Please enter the company name
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Registeration
                                                                Number</label>
                                                            <input type="text" class="form-control"
                                                                id="BUSINESS_REG_NUMBER" name="BUSINESS_REG_NUMBER"
                                                                [(ngModel)]="model.basicInfo.data['BUSINESS_REG_NUMBER']"
                                                                placeholder="">
                                                            <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                style="margin:5px 0px !important">
                                                                Please enter the business registeration number
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Email</label>
                                                            <input type="email" class="form-control" id="EMAIL"
                                                                name="EMAIL" [(ngModel)]="model.basicInfo.data['EMAIL']"
                                                                placeholder="">
                                                            <input type="hidden" name="PEOPLE_ID" id="PEOPLE_ID"
                                                                [(ngModel)]="model.basicInfo.data['PEOPLE_ID']">
                                                            <input type="hidden" name="CUSTOMER_TYPE" id="CUSTOMER_TYPE"
                                                                [(ngModel)]="model.basicInfo.data['CUSTOMER_TYPE']">
                                                            <input type="hidden" name="IS_EDIT" id="IS_EDIT" value="1">
                                                            <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                style="margin:5px 0px !important">
                                                                Please enter email
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Website</label>
                                                            <input type="text" class="form-control" id="WEBSITE"
                                                                name="WEBSITE"
                                                                [(ngModel)]="model.basicInfo.data['WEBSITE']"
                                                                placeholder="">
                                                            <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                style="margin:5px 0px !important">
                                                                Please enter website
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Nature of
                                                                Business</label>
                                                            <input type="text" class="form-control"
                                                                id="NATURE_OF_BUSINESS" name="NATURE_OF_BUSINESS"
                                                                [(ngModel)]="model.basicInfo.data['NATURE_OF_BUSINESS']"
                                                                placeholder="">
                                                            <div class="error otp_error alert alert-block alert-danger fade in hide"
                                                                style="margin:5px 0px !important">
                                                                Please enter nature of business
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <label for="" class="control-label">Tax ID Number</label>
                                                            <input type="text" class="form-control" id="TAX_ID_NUMBER"
                                                                name="TAX_ID_NUMBER"
                                                                [(ngModel)]="model.basicInfo.data['TAX_ID_NUMBER']"
                                                                placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12">
                                                            <button (click)="updateBasicInfo()" type="button"
                                                                class="btn btn-white btn-blue btn-block white_c"
                                                                style="padding: 6px 12px" id="updateBasicIdentity">Save
                                                                <i class="fa fa-spinner fa-spin hide"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Phone Number Section -->
                                                <link
                                                    href="http://app.creditclan.com/creditclan_demo/pub/css/flags16.css"
                                                    rel="stylesheet" type="text/css">
                                                <link
                                                    href="http://app.creditclan.com/creditclan_demo/pub/css/flags32.css"
                                                    rel="stylesheet" type="text/css">
                                                <style>
                                                    ul.f16 {
                                                        max-width: 320px;
                                                        display: block;
                                                        vertical-align: top;
                                                        font-size: 12px;
                                                    }

                                                    ;

                                                    ul.f32 {
                                                        display: block;
                                                    }

                                                    .f16 .flag,
                                                    .f32 .flag {
                                                        width: auto;
                                                        clear: right;
                                                    }

                                                    .f16 .flag {
                                                        padding-left: 24px;
                                                    }

                                                    .f32 .flag {
                                                        padding-left: 44px;
                                                    }

                                                    .f16 abbr,
                                                    .f16 i {
                                                        display: inline-block;
                                                        width: 24px;
                                                    }

                                                    .f32 abbr,
                                                    .f32 i {
                                                        display: inline-block;
                                                        width: 32px;
                                                        line-height: 32px;
                                                        vertical-align: bottom;
                                                    }

                                                    .f16 i {
                                                        margin-left: -48px;
                                                        margin-right: 24px;
                                                        color: silver;
                                                    }

                                                    .f32 i {
                                                        margin-left: -76px;
                                                        margin-right: 44px;
                                                        color: silver;
                                                    }

                                                    .flag.deprecated {
                                                        color: silver;
                                                    }

                                                    .flag.island {
                                                        color: navy;
                                                    }
                                                </style>
                                                <div class="form-group row" style="margin-bottom: 0;">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label class="control-label">Phone 1</label>
                                                        <div class="input-group m-bot15">
                                                            <div class="input-group-btn">
                                                                <button style="padding:6px" type="button"
                                                                    class="btn btn-white">
                                                                    <a class="f32 ext_sh" href="javascript:;">
                                                                        <span class="flag ng">(+234)</span>
                                                                    </a>
                                                                    <!--<span class="caret"></span>-->
                                                                </button>
                                                                <button style="padding:12px" data-toggle="dropdown"
                                                                    class="btn btn-white dropdown-toggle" type="button">
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <ul class="dropdown-menu f32 hide" style="padding:5px">
                                                                    <li>
                                                                        <a href="javascript:;" data-max_length="11"
                                                                            data-ext="ng" data-ext_="+234"
                                                                            data-ce="(+234)"
                                                                            class="active flagger flag ng">(+234)
                                                                            Nigeria</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" data-max_length=""
                                                                            data-ext="us" data-ext_="+001"
                                                                            data-ce="(+001)"
                                                                            class="active flagger flag us">(+001)
                                                                            USA</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <input type="hidden" name="PEOPLE_PHONE_ID"
                                                                id="PEOPLE_PHONE_ID" value="">
                                                            <input type="hidden" name="USER_ID" id="USER_ID"
                                                                [(ngModel)]="model.phone.data['USER_ID']">
                                                            <input type="hidden" name="COUNTRY_CODE" id="COUNTRY_CODE"
                                                                value="+234">
                                                            <input type="hidden" name="MAX_LENGTH" id="MAX_LENGTH"
                                                                value="11">
                                                            <input type="text" value="" name="PHONE" id="PHONE"
                                                                class="form-control"
                                                                style="border:1px solid #F0F0F0; height:46px"
                                                                [(ngModel)]="model.phone.data['PHONE']">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row hide" style="margin-bottom: 0;">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label class="control-label">Phone 2</label>
                                                        <div class="input-group m-bot15">
                                                            <div class="input-group-btn">
                                                                <button style="padding:6px" type="button"
                                                                    class="btn btn-white">
                                                                    <a class="f32 ext_sh" href="javascript:;">
                                                                        <span class="flag ng">(+234)</span>
                                                                    </a>
                                                                    <!--<span class="caret"></span>-->
                                                                </button>
                                                                <button style="padding:12px" data-toggle="dropdown"
                                                                    class="btn btn-white dropdown-toggle" type="button">
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <ul class="dropdown-menu f32" style="padding:5px">
                                                                    <li>
                                                                        <a href="javascript:;" data-max_length="11"
                                                                            data-ext="ng" data-ext_="+234"
                                                                            data-ce="(+234)"
                                                                            class="active flagger flag ng">(+234)
                                                                            Nigeria</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" data-max_length=""
                                                                            data-ext="us" data-ext_="+001"
                                                                            data-ce="(+001)"
                                                                            class="active flagger flag us">(+001)
                                                                            USA</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <input type="hidden" name="MAX_LENGTH" id="MAX_LENGTH"
                                                                value="11">
                                                            <input type="text" name="PHONE_TWO" id="PHONE_TWO"
                                                                class="form-control"
                                                                style="border:1px solid #F0F0F0; height:46px">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row" style="margin-bottom: 0;">
                                                    <div class="col-md-12">
                                                        <div class="error phone_error alert alert-block alert-danger fade in hide"
                                                            style="margin:5px 0px !important">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2" style=" ">
                                                        <a (click)="updatePhone()" id="confirm_by_sms"
                                                            class="btn btn-white btn-block btn-blue white_c"
                                                            style=" padding: 6px 12px;">Submit
                                                            <i class="fa fa-spinner fa-spin hide pull-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <link rel="stylesheet" type="text/css"
                                                href="http://app.creditclan.com/creditclan_demo/pub/assets/bootstrap-fileupload/bootstrap-fileupload.css">
                                            <script type="text/javascript"
                                                src="http://app.creditclan.com/creditclan_demo/pub/assets/bootstrap-fileupload/bootstrap-fileupload.js"></script>
                                            <script type="text/javascript"
                                                src="http://app.creditclan.com/creditclan_demo/emblem/js/jquery.formatCurrency-1.4.0.js"></script>
                                            <script
                                                src="http://app.creditclan.com/creditclan_demo/pub/js/core/upload_actions.js"></script>
                                        </li>
                                        <li class="title basic_dir">Directors
                                            <a href="javascript:;" data-sub="dir" class="pull-right co-panel hide"
                                                style="color:#000">
                                                <i class="fa fa-chevron-up"></i>
                                            </a>
                                            <a (click)="toogleView('director')" data-sub="dir"
                                                class="pull-right co-panel_ fadeIn" style="color:#000">
                                                <i class="fa fa-chevron-down"></i>
                                            </a>
                                        </li>
                                        <li class="sub dir animated" *ngIf="model.director.open">
                                            <!-- Directors -->
                                            <div *ngIf="!openForm" class="_profile_edit_toogle collapse in"
                                                aria-expanded="true">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Surname</th>
                                                            <th>BVN</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let dir of model.dump.directors">
                                                            <td>{{dir["DIR_NAME"]}}</td>
                                                            <td>{{dir["DIR_SURNAME"]}}</td>
                                                            <td>{{dir["BVN"]}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- Edit Directors List -->
                                            <div *ngIf="openForm"
                                                class="_profile_edit_toogle panel-body collapse then in "
                                                aria-expanded="false" style="height: 30px;">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Surname</th>
                                                            <th>BVN</th>
                                                            <th>
                                                                <a href="javascript:;"
                                                                    class="btn btn-white btn-xs btn-add-one"
                                                                    data-form="debit">Add</a>
                                                                <a href="javascript:;"
                                                                    class="btn btn-white btn-xs btn-remove-one hide"
                                                                    data-form="debit">Close</a>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="drec">
                                                        <tr class="drecd0"
                                                            *ngFor="let dir of model.director.data; let index = index">
                                                            <td>
                                                                <input type="text" class="form-control" id="DIR_NAME"
                                                                    name="DIR_NAME"
                                                                    [(ngModel)]="model.director.data[index]['DIR_NAME']">
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" id="DIR_SURNAME"
                                                                    name="DIR_SURNAME"
                                                                    [(ngModel)]="model.director.data[index]['DIR_SURNAME']">
                                                                <input type="hidden" id="KEY" value="">
                                                            </td>
                                                            <td>
                                                                <input type="text" id="BVN" name="BVN"
                                                                    class="form-control"
                                                                    [(ngModel)]="model.director.data[index]['BVN']">
                                                            </td>
                                                            <td>
                                                                <a data-form="debit" data-w="drecd0" data-k="0"
                                                                    data-rec="drec"
                                                                    class="btn btn-rounded btn-outline btn-equal  editEntry_"
                                                                    (click)="updateDirector(index)">
                                                                    <i class="fa fa-save"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a (click)="deleteDirector(index)" data-form="debit"
                                                                    data-rec="drec" data-w="drec0" data-k="0"
                                                                    class="btn btn-rounded btn-outline btn-equal  removeEntry_"
                                                                    href="javascript:void(0);">
                                                                    <i class="fa fa-minus"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr class="debit">
                                                            <td>
                                                                <input type="text" class="form-control" id="DIR_NAME"
                                                                    name="DIR_NAME" [(ngModel)]="director['DIR_NAME']">
                                                            </td>
                                                            <td>
                                                                <input type="text" class="form-control" id="DIR_SURNAME"
                                                                    [(ngModel)]="director['DIR_SURNAME']">
                                                                <input type="hidden" id="KEY">
                                                            </td>
                                                            <td>
                                                                <input type="text" id="BVN" class="form-control"
                                                                    [(ngModel)]="director['BVN']">
                                                            </td>
                                                            <td>
                                                                <a (click)="registerDirector()" data-form="debit"
                                                                    data-rec="drec" data-type="1"
                                                                    class="btn btn-rounded btn-outline btn-equal addEntry_">
                                                                    <i class="fa fa-plus"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="panel-body">
                                                    <div class="form-group row">
                                                        <div class="col-md-4 col-md-offset-8">
                                                            <button (click)="addDirector()" type="button"
                                                                class="btn btn-white btn-blue btn-block white_c"
                                                                style="padding: 6px 12px" id="updateDirectors">Save
                                                                <i class="fa fa-spinner fa-spin hide"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="title address_title">Address
                                            <a href="javascript:;" data-sub="address" class="pull-right co-panel hide"
                                                style="color:#000">
                                                <i class="fa fa-chevron-up"></i>
                                            </a>
                                            <a (click)="toogleView('address')" data-sub="address"
                                                class="pull-right co-panel_ fadeIn" style="color:#000">
                                                <i class="fa fa-chevron-down"></i>
                                            </a>
                                        </li>
                                        <li class="sub address animated" *ngIf="model.address.open">
                                            <!-- Address Preview -->
                                            <div *ngIf="!openForm" class="_profile_edit_toogle collapse in"
                                                aria-expanded="true">
                                                <div class="panel-body bio">
                                                    <div class="bio-d" style="overflow:hidden">
                                                        <div class="pro_head" style="    padding-top: 15px;">
                                                            <div
                                                                style="width: 100%;max-width: 450px;margin: 20px auto; padding-top: 10px;">
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Contact
                                                                            Person</span>
                                                                        <span
                                                                            class="pull-right">{{model.address.prev['CONTACT_PERSON']}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span
                                                                            class="pull-left text-primary">Address</span>
                                                                        <span
                                                                            class="pull-right">{{model.address.prev['WORK_ADDRESS']}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <span class="pull-right">
                                                                            <option selected="" value="1">
                                                                                {{model.address.prev['COUNTRY']}}
                                                                            </option>
                                                                        </span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <span
                                                                            class="pull-right">{{model.address.prev['STATE']}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div style="margin-bottom: 10px;">
                                                                    <div class="form-group">
                                                                        <span class="pull-left text-primary">Fax
                                                                            Number</span>
                                                                        <span
                                                                            class="pull-right">{{model.address.prev['FAX_NUMBER']}}</span>
                                                                        <div class="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="openForm"
                                                class="_profile_edit_toogle panel-body collapse then in "
                                                aria-expanded="false" style="height: 30px;">
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <input type="hidden" name="ADDRESS_ID" id="ADDRESS_ID" value="">
                                                        <label class="control-label" style="padding:0;">Country</label>
                                                        <select id="COUNTRY_ID" name="COUNTRY_ID" class="form-control"
                                                            [(ngModel)]="model.address.data['COUNTRY_ID']">
                                                            <option *ngFor="let country of model.dump.countries"
                                                                [value]="country['COUNTRY_ID']">
                                                                {{country["COUNTRY_NAME"]}} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row hide cty_error e">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <div class="error alert alert-block alert-danger fade in  "
                                                            style="margin:5px 0px !important">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label class="control-label" style="padding:0;">
                                                            State
                                                        </label>
                                                        <select id="STATE_ID" name="STATE_ID" class="form-control"
                                                            [(ngModel)]="model.address.data['STATE_ID']">
                                                            <option value=""></option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row hide st_error e">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <div class="error alert alert-block alert-danger fade in  "
                                                            style="margin:5px 0px !important">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label class="control-label" style="padding:0;">
                                                            Business Address
                                                        </label>
                                                        <label class="pull-right item-text-counter2">160</label>
                                                        <textarea id="ADDRESS" name="WORK_ADDRESS" class="form-control"
                                                            [(ngModel)]="model.address.data['WORK_ADDRESS']"></textarea>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label for="" class="control-label">Fax Number</label>
                                                        <input type="text" class="form-control" id="FAX_NUMBER"
                                                            name="FAX_NUMBER"
                                                            [(ngModel)]="model.address.data['FAX_NUMBER']"
                                                            placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <label for="" class="control-label">Contact Person(s)</label>
                                                        <input type="text" class="form-control" id="CONTACT_PERSON"
                                                            name="CONTACT_PERSON"
                                                            [(ngModel)]="model.address.data['CONTACT_PERSON']"
                                                            placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group row hide p_error e">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <div class="error alert alert-block alert-danger fade in  "
                                                            style="margin:5px 0px !important">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-8 col-md-offset-2">
                                                        <a href="javascript:;" id="addAddress"
                                                            class="btn btn-white btn-blue btn-block white_c"
                                                            style="padding: 6px 12px">Submit
                                                            <i class="fa fa-spinner fa-spin hide"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="flex justify-end align-center mt-3">
                                        <!-- <a class="btn btn-white btn-xs open-for-editing" data-toggle="collapse" data-parent="#_profile_edit_parent" href="._profile_edit_toogle" aria-expanded="false" aria-controls="_profile_edit_toogle" (click)="toggleForm()">Open for Editing</a>  -->
                                        <button class="btn btn-outline-secondary open-for-editing mr-2"
                                            data-toggle="collaps" data-parent="#_profile_edit_parent"
                                            aria-expanded="false" aria-controls="_profile_edit_toogle"
                                            (click)="toggleForm()">Open for Editing</button>
                                        <a [routerLink]="['/customer/', userType, userId ]"
                                            class="btn btn-orange">Done
                                            <i class="fa fa-spin fa-spinner hide"></i>
                                        </a>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div *ngIf="!external" class="col-md-3" style="padding:0px">
                            <div class="panel box mb-1">
                                <div class="panel-body text-center">
                                    <div class="h-group">
                                        <span class="color-yellow icon large title"><i class="fa fa-clock"></i></span>
                                        <h3 class="title">Last Updated</h3>
                                        <p class="subtitle mb-3">{{ last_updated | amTimeAgo }}</p>
                                        <a *ngIf="enable_peer=='1'" class="btn btn-outline-secondary"
                                        [routerLink]="['/customer/',userType,userId]">Back</a>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="text-center">
                                <div style=" margin-bottom: 10px;">
                                    <img style="width:60% !important" src="assets/images/time.PNG">
                                </div>
                                <div style="margin-bottom: 25px">
                                    <p class="txt t_lh1" style="font-size: 14px">
                                        <strong>
                                            <br /></strong>
                                    </p>
                                </div>
                                <div>
                                    <a class="btn btn-white btn-full egded-box wd_btn"
                                        style="display: inline-block; width: auto"
                                        >Back</a>
                                </div>
                            </div> -->


                            <section class="panel">
                                <div class="panel-body padding-regular">
                                    <ul class="decList bigger">
                                        <li>
                                            <a [routerLink]="['/customer/',userType,userId,'financials']"
                                                class="no-padding">Financials</a>
                                        </li>
                                        <!-- <li>
                                        <a [routerLink]="['/customer/',userType,userId,'verifications']"  class="no-padding">Verification</a>
                                      </li> -->
                                        <!-- <li>
                                        <a href="javascript:;" class="no-padding">Verification</a>
                                      </li> -->
                                        <li>
                                            <a class="no-padding"
                                                [routerLink]="['/customer/',userType,userId,'guarantors']">Guarantors</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/customer/',userType,userId,'messages']"
                                                class="no-padding">Messages</a>
                                        </li>

                                        <li>
                                            <a [routerLink]="['/customer/',userType,userId,'attachments']"
                                                class="no-padding">Attachments</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/customer/',userType,userId,'photos']"
                                                class="no-padding">Photos</a>
                                        </li>
                                    </ul>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>