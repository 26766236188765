import { RecommendationsComponent } from './../applications/recommendations/recommendations.component';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  FormArray,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  RouterModule,
  Routes
} from '@angular/router';
// import {CalendarComponent} from 'angular2-fullcalendar/src/calendar/calendar';
import { CalendarComponent } from 'ap-angular2-fullcalendar/src/calendar/calendar';

import { MomentModule } from 'angular2-moment';

import { ColorPickerModule } from 'ngx-color-picker';
import { DateinvalidPipe } from '../_pipes/dateinvalid.pipe';
import { SafehtmlPipe } from '../_pipes/safehtml.pipe';
import { LoanStatusTextPipe } from '../_pipes/loan-status-text.pipe';
import { LoanStatusPipe } from '../_pipes/loan-status.pipe';
import { OfferstatusPipe } from '../_pipes/offerstatus.pipe';
import { PeertopeerPipe } from '../_pipes/peertopeer.pipe';
import { SafePipe } from '../_pipes/safe.pipe';
import { CollateralPipe } from '../_pipes/collateral.pipe';
import { LoandurationPipe } from '../_pipes/loanduration.pipe';
import { RequestdurationPipe } from '../_pipes/requestduration.pipe';
import { AoffPipe } from '../_pipes/aoff.pipe';

import { BorroweridComponent } from '../borrowerid/borrowerid.component';
import { BreakloanComponent } from '../breakloan/breakloan.component';
import { CalendardetailsComponent } from '../calendardetails/calendardetails.component';
import { ChangeproductComponent } from '../changeproduct/changeproduct.component';
import { CustomerFinanceComponent } from '../customers/customer-finance/customer-finance.component';
import { CustomerInvestmentsComponent } from '../customers/customer-investments/customer-investments.component';
import { CustomerLoansComponent } from '../customers/customer-loans/customer-loans.component';
import { CustomerUpdateComponent } from '../customers/customer-update/customer-update.component';
import { CustomersummaryComponent } from '../customersummary/customersummary.component';
import { ApprovalviewComponent } from '../approvalview/approvalview.component';
import { LoancontractComponent } from '../loancontract';
import { LoanpeertopeerComponent } from '../loanpeertopeer/loanpeertopeer.component';
import { LoancontractformComponent } from '../loancontractform/loancontractform.component';
import { LoandocumentComponent } from '../loandocument';
import { LoanlendersComponent } from '../loanlenders';
import { LoanlogsComponent } from '../loanlogs/loanlogs.component';
import { LoanprofileComponent } from '../loanprofile/loanprofile.component';
import { LoansummaryComponent } from '../loansummary/loansummary.component';
import { LoanchatComponent } from '../loanchat/loanchat.component';
import { PaymentrollbackComponent } from '../paymentrollback/paymentrollback.component';
import { MakepaymentComponent } from '../makepayment/makepayment.component';
import { PayscheduleComponent } from '../payschedule/payschedule.component';
import { RepayComponent } from '../repay/repay.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { UserlocationComponent } from '../userlocation/userlocation.component';
import { WalletdetailsComponent } from '../walletdetails/walletdetails.component';
import { MyDatePickerModule } from 'mydatepicker';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
// import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';

import { NgxTypeaheadModule } from 'ngx-typeahead';
import { SearchanalysissettingPipe } from '../_pipes/searchanalysissetting.pipe';
import { MaritalstatusPipe } from '../_pipes/maritalstatus.pipe';
import { FilterpipePipe } from '../_pipes/filterpipe.pipe';
import { ImagenotfoundPipe } from '../_pipes/imagenotfound.pipe';
import { JournalapprovedPipe } from '../_pipes/journalapproved.pipe';
import { LimittoPipe } from '../_pipes/limitto.pipe';

import { LoanstatementComponent } from '../loanstatement/loanstatement.component';

import { NoaPipe } from '../_pipes/noa.pipe';
import { MoneyPipe } from '../_pipes/money.pipe';
import { CalendarviewComponent } from '../calendarview/calendarview.component';
import { LedgerComponent } from '../ledger/ledger.component';
import { LoanchargesComponent } from '../loancharges/loancharges.component';
import { LoanfeesComponent } from '../loanfees/loanfees.component';
import { LoanformComponent } from '../loanform/loanform.component';
import { MainNavigationComponent } from '../main-navigation/main-navigation.component';
import { RatingComponent } from '../rating/rating.component';
import { EmpsearchComponent } from '../operations/empsearch/empsearch.component';
import { PaymentComponent } from '../payment/payment.component';
import { UiSwitchModule } from '../../../node_modules/ngx-ui-switch';
import { NewbankformComponent } from '../newbankform/newbankform.component';
import { LoanchecklistComponent } from '../loanchecklist/loanchecklist.component';
import { LoanmoreComponent } from '../loanmore/loanmore.component';
import { LoanguarantorsComponent } from '../loanguarantors';
import { LoanlocationComponent } from '../loanlocation/loanlocation.component';
import { LoanchequeComponent } from '../loancheque/loancheque.component';
import { LoanphotosComponent } from '../loanphotos/loanphotos.component';
import { LoanacctsComponent } from '../loanaccts/loanaccts.component';
import { LoancollateralComponent } from '../loancollateral/loancollateral.component';
import { LoananalysisComponent } from '../loananalysis/loananalysis.component';
import { ViewguarantorComponent } from '../viewguarantor/viewguarantor.component';
import { LoanapprovalsComponent } from '../loanapprovals';
import { LoanworkflowComponent } from '../loanworkflow/loanworkflow.component';
import { QueuecancelComponent } from '../operations/queuecancel/queuecancel.component';
import { CustomeranalysisComponent } from '../customers/customeranalysis/customeranalysis.component';
import { NewempformComponent } from '../newempform/newempform.component';
import { RequestformComponent } from '../requestform/requestform.component';
import { LoancustomerformComponent } from '../loancustomerform/loancustomerform.component';
import { BulkpaymentComponent } from '../operations/bulkpayment/bulkpayment.component';
import { AgefilterPipe } from '../_pipes/agefilter.pipe';
import { MycurrencyPipe } from '../_pipes/mycurrency.pipe';
import { NgxEditorModule } from 'ngx-editor';
import { PercentageFundedPipe } from '../_pipes/percentage-funded.pipe';
import { SelectModule } from 'ng2-select';
import { BackendsettingsComponent } from '../backend/backendsettings/backendsettings.component';
import { LevelformComponent } from '../backend/levelform/levelform.component';
import { MobileComponent } from '../mobile/mobile/mobile.component';
import { WebUssdComponent } from '../web-ussd/web-ussd.component';
import { GeneralformComponent } from '../operations/generalform/generalform.component';
import { InterestformComponent } from '../operations/interestform/interestform.component';
import { LoanamountComponent } from '../operations/loanamount/loanamount.component';
import { OriginationfeeComponent } from '../operations/originationfee/originationfee.component';
import { InterestratefloorComponent } from '../operations/interestratefloor/interestratefloor.component';
import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';
import { NewlevelformComponent } from '../backend/newlevelform/newlevelform.component';
import { CustomerRemitaComponent } from '../customer-remita/customer-remita.component';

import { KycdetailsComponent } from '../kyc/kycdetails/kycdetails.component';
import { CustomerkycComponent } from '../customerkyc/customerkyc.component';
import { AdjustweightsComponent } from '../loan/adjustweights/adjustweights.component';
import { IncludedcompaniesComponent } from '../decision/includedcompanies/includedcompanies.component';
import { LoanrepaymentComponent } from '../loanrepayment/loanrepayment.component';
import { DirectdebitComponent } from '../loan/directdebit/directdebit.component';
import { CreditcheckComponent } from '../loan/creditcheck/creditcheck.component';
import { DirectdebitinitiateComponent } from './directdebitinitiate/directdebitinitiate.component';
import { DirectdebitstatusComponent } from './directdebitstatus/directdebitstatus.component';
import { DirectdebitcancelComponent } from './directdebitcancel/directdebitcancel.component';
import { StopmandateComponent } from './stopmandate/stopmandate.component';
import { CreditcheckdetailsComponent } from './creditcheckdetails/creditcheckdetails.component';
import { ContractofferComponent } from './contractoffer/contractoffer.component';
import { AllcustomersComponent } from './allcustomers/allcustomers.component';
import { BankslistComponent } from './bankslist/bankslist.component';
import { CompaniesComponent } from './companies/companies.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UploadModule } from '../upload/upload.module';
import { ChangepaymentComponent } from './changepayment/changepayment.component';
import { ChangeloanofficerComponent } from './changeloanofficer/changeloanofficer.component';
import { CreditingstatementComponent } from './creditingstatement/creditingstatement.component';
import { SmslogsComponent } from './smslogs/smslogs.component';
import { MakeloanpaymentComponent } from './makeloanpayment/makeloanpayment.component';
import { CardcollectionComponent } from './cardcollection/cardcollection.component';
import { ClearingfinesComponent } from './clearingfines/clearingfines.component';
import { CustomerwithdrawalComponent } from './customerwithdrawal/customerwithdrawal.component';
import { StopremitaComponent } from './stopremita/stopremita.component';
import { NotifyremitaComponent } from './notifyremita/notifyremita.component';
import { RefreshremitaComponent } from './refreshremita/refreshremita.component';
import { SavingsNavigationComponent } from '../savings-navigation/savings-navigation.component';
import { SavingsgeneralsettingsComponent } from '../savings/savingsgeneralsettings/savingsgeneralsettings.component';
import { SavingsinterestsettingsComponent } from '../savings/savingsinterestsettings/savingsinterestsettings.component';
import { SavingsprematuritybreakingComponent } from '../savings/savingsprematuritybreaking/savingsprematuritybreaking.component';
import { SavingswithdrawalssettingsComponent } from '../savings/savingswithdrawalssettings/savingswithdrawalssettings.component';
import { SavingsapplicationrequirementsComponent } from '../savings/savingsapplicationrequirements/savingsapplicationrequirements.component';
import { SavingsapprovalsComponent } from '../savings/savingsapprovals/savingsapprovals.component';
import { BuybackloanComponent } from './buybackloan/buybackloan.component';
import { PostsavingsComponent } from './postsavings/postsavings.component';
import { BreakinvestmentComponent } from './breakinvestment/breakinvestment.component';
import { InvestmentwithdrawalComponent } from './investmentwithdrawal/investmentwithdrawal.component';
import { BooksavingsComponent } from './booksavings/booksavings.component';
import { ButtonComponent } from '../components/button/button.component';
import { InputComponent } from '../components/input/input.component';
import { NewcollateralComponent } from './newcollateral/newcollateral.component';
import { PhonenumbersComponent } from './phonenumbers/phonenumbers.component';
import { ItemComponent } from '../applications/item/item.component';
import { PortfoliocalendarComponent } from '../portfoliocalendar/portfoliocalendar.component';
import { PortfolioComponent } from '../portfolio/portfolio.component';
import { StatementscheduleComponent } from '../statementschedule/statementschedule.component';
import { CountdownModule } from 'ngx-countdown';
import { LoanactionComponent } from './loanaction/loanaction.component';
import { SecurityComponent } from '../setting-module/security/security.component';
import { ProductbasicComponent } from '../product/productbasic/productbasic.component';
import { ProductsettingsComponent } from '../product/productsettings/productsettings.component';
import { ProductapprovalsComponent } from '../product/productapprovals/productapprovals.component';
import { ProductsComponent } from '../products/products/products.component';
import { ProductSettingComponent } from '../setting-module/product-setting/product-setting.component';

import { WelcomeEmailComponent } from '../email-template/welcome-email/welcome-email.component';
import { PhoneOtpComponent } from '../email-template/phone-otp/phone-otp.component';
import { RestPinComponent } from '../email-template/rest-pin/rest-pin.component';
import { DirectDebitEmailComponent } from '../email-template/direct-debit-email/direct-debit-email.component';
import { ValidationWorkEmailComponent } from '../email-template/validation-work-email/validation-work-email.component';
import { LoanRejectionEmailComponent } from '../email-template/loan-rejection-email/loan-rejection-email.component';
import { PaymentReceivedComponent } from '../email-template/payment-received/payment-received.component';
import { BorrowerReceivedOfferEmailComponent } from '../email-template/borrower-received-offer-email/borrower-received-offer-email.component';
import { LenderMakeOfferComponent } from '../email-template/lender-make-offer/lender-make-offer.component';
import { BorrowerAccpectedOfferLetterComponent } from '../email-template/borrower-accpected-offer-letter/borrower-accpected-offer-letter.component';
import { WithdrawalEmailComponent } from '../email-template/withdrawal-email/withdrawal-email.component';
import { SentToMarketComponent } from '../email-template/sent-to-market/sent-to-market.component';
import { GuarantorRequestEmailComponent } from '../email-template/guarantor-request-email/guarantor-request-email.component';
import { CardRequestEmailComponent } from '../email-template/card-request-email/card-request-email.component';
import { BankAccountConfirmationEmailComponent } from '../email-template/bank-account-confirmation-email/bank-account-confirmation-email.component';
import { RequestForAttachmentsComponent } from '../email-template/request-for-attachments/request-for-attachments.component';
import { BvnVerificationEmailComponent } from '../email-template/bvn-verification-email/bvn-verification-email.component';
import { RepaymentReminderEmailComponent } from '../email-template/repayment-reminder-email/repayment-reminder-email.component';
import { RunbankstatementanalysisComponent } from './runbankstatementanalysis/runbankstatementanalysis.component';
import { LoanportfolioactionComponent } from './loanportfolioaction/loanportfolioaction.component';
import { WebsiteSettingsComponent } from '../email-template/website-settings/website-settings.component';
import { BookloanformComponent } from '../bookloanform/bookloanform.component';

import { EmailSettingComponent } from '../../app/email-setting/email-setting.component';
import { RequestcalendarComponent } from './requestcalendar/requestcalendar.component';
import { SchedulemodalComponent } from '../applications/schedulemodal/schedulemodal.component';
import { GuarantorscoverComponent } from '../guarantorscover/guarantorscover.component';
import { GuarantorcontactsComponent } from '../guarantorcontacts/guarantorcontacts.component';
import { AddGuarantorComponent } from './add-guarantor/add-guarantor.component';
import { ContractheaderComponent } from '../applications/contractheader/contractheader.component';
import { WalletaccountComponent } from './walletaccount/walletaccount.component';
import { WalletcardComponent } from './walletcard/walletcard.component';
import { WalletfundingcardsComponent } from '../operations/walletfundingcards/walletfundingcards.component';
import { PopupcalendarComponent } from '../applications/applicationsapprovals/popupcalendar/popupcalendar.component';
import { PortfoliotargetmodalComponent } from '../applications/modal/portfoliotargetmodal/portfoliotargetmodal.component';
import { RoundPipe } from '../_pipes/round.pipe';
import { NewloanformComponent } from '../newloanform/newloanform.component';
import { PartnerloanformComponent } from '../partnerloanform/partnerloanform.component';

import { ApplicationspendingComponent } from '../applications/applicationspending/applicationspending.component';
import { PendingapprovalsComponent } from '../applications/pendingapprovals/pendingapprovals.component';
import { BvnmodalComponent } from '../applications/modal/bvnmodal/bvnmodal.component';
import { CalendarpopoutmodalComponent } from '../applications/modal/calendarpopoutmodal/calendarpopoutmodal.component';
import { CarddebittrylogsmodalComponent } from '../applications/modal/carddebittrylogsmodal/carddebittrylogsmodal.component';
import { CardmodalComponent } from '../applications/modal/cardmodal/cardmodal.component';
import { CardnumbermodalComponent } from '../applications/modal/cardnumbermodal/cardnumbermodal.component';
import { CardrecollectionmodalComponent } from '../applications/modal/cardrecollectionmodal/cardrecollectionmodal.component';
import { ChequesetupmodalComponent } from '../applications/modal/chequesetupmodal/chequesetupmodal.component';
import { DefaultrepaymentmodalComponent } from '../applications/modal/defaultrepaymentmodal/defaultrepaymentmodal.component';
import { DirectdebitsettingsComponent } from '../applications/modal/directdebitsettings/directdebitsettings.component';
import { DisbursementmodalComponent } from '../applications/modal/disbursementmodal/disbursementmodal.component';
import { DocumentsmodalComponent } from '../applications/modal/documentsmodal/documentsmodal.component';
import { EnlargeimageComponent } from '../applications/modal/enlargeimage/enlargeimage.component';
import { FundlenderComponent } from '../applications/modal/fundlender/fundlender.component';
import { InflightdetailsComponent } from '../applications/modal/inflightdetails/inflightdetails.component';
import { LoandetailsComponent } from '../applications/modal/loandetails/loandetails.component';

import { MakepaymentmodalComponent } from '../applications/modal/makepaymentmodal/makepaymentmodal.component';
import { PaymentmodemodalComponent } from '../applications/modal/paymentmodemodal/paymentmodemodal.component';
import { RecollectionmodeComponent } from '../applications/modal/recollectionmode/recollectionmode.component';
import { RecollectionsettingspasswordmodalComponent } from '../applications/modal/recollectionsettingspasswordmodal/recollectionsettingspasswordmodal.component';
import { RejectlenderofferComponent } from '../applications/modal/rejectlenderoffer/rejectlenderoffer.component';
import { RemovemarketofferComponent, } from '../applications/modal/removemarketoffer/removemarketoffer.component';
import { RepaymentfooterComponent } from '../applications/modal/repaymentfooter/repaymentfooter.component';
import { RunfullanalyticsmodalComponent } from '../applications/modal/runfullanalyticsmodal/runfullanalyticsmodal.component';
import { SendtomarketComponent } from '../applications/modal/sendtomarket/sendtomarket.component';
import { UserheaderComponent } from '../applications/modal/userheader/userheader.component';
import { ApplicationscontractComponent } from '../applications/applicationscontract/applicationscontract.component';
import { ContractdetailsComponent } from '../applications/contractdetails/contractdetails.component';
import { CustomerdetailsComponent } from '../applications/customerdetails/customerdetails.component';
import { RepaymentscheduleComponent } from '../applications/repaymentschedule/repaymentschedule.component';
import { CreaterequestmodalComponent } from '../applications/createrequestmodal/createrequestmodal.component';
import { AccountmodalComponent } from '../applications/modal/accountmodal/accountmodal.component';
import { ApplicationstimelineComponent } from '../applications/applicationstimeline/applicationstimeline.component';
import { CurrentapprovallevelComponent } from '../applications/currentapprovallevel/currentapprovallevel.component';
import { RequestheaderComponent } from '../applications/requestheader/requestheader.component';
import { RequestfooterComponent } from '../applications/requestfooter/requestfooter.component';
import { CarddebitsetupComponent } from '../applications/carddebitsetup/carddebitsetup.component';
import { AccountdebitComponent } from '../applications/accountdebit/accountdebit.component';
import { HomeComponent } from '../applications/analytics/home/home.component';
import { AnalyticshomeComponent } from '../applications/analytics/analyticshome/analyticshome.component';
import { AnalyticsheaderComponent } from '../applications/analytics/analyticsheader/analyticsheader.component';
import { PeertopeerComponent } from '../applications/peertopeer/peertopeer.component';
import { SelectedanalyticsComponent } from '../applications/analytics/selectedanalytics/selectedanalytics.component';
import { RunselectedanalyticsmodalComponent } from '../applications/analytics/runselectedanalyticsmodal/runselectedanalyticsmodal.component';
import { AnalyticssmssummaryComponent } from '../applications/analytics/analyticssmssummary/analyticssmssummary.component';
import { AnalyticssmsanalysisComponent } from '../applications/analytics/analyticssmsanalysis/analyticssmsanalysis.component';
import { AnalyticssmslogsComponent } from '../applications/analytics/analyticssmslogs/analyticssmslogs.component';
import { AnalyticssmslogsdataComponent } from '../applications/analytics/analyticssmslogsdata/analyticssmslogsdata.component';
import { AnalyticscallanalysisComponent } from '../applications/analytics/calls/analyticscallanalysis/analyticscallanalysis.component';
import { AnalyticscalllogsdataComponent } from '../applications/analytics/calls/analyticscalllogsdata/analyticscalllogsdata.component';
import { AnalyticscalllogsstatsComponent } from '../applications/analytics/calls/analyticscalllogsstats/analyticscalllogsstats.component';
import { AnalyticsphonesummaryComponent } from '../applications/analytics/calls/analyticsphonesummary/analyticsphonesummary.component';
import { AnalyticssocialdataComponent } from '../applications/analytics/social/analyticssocialdata/analyticssocialdata.component';
import { AnalyticssocialstatsComponent } from '../applications/analytics/social/analyticssocialstats/analyticssocialstats.component';
import { AnalyticsfacebooksummaryComponent } from '../applications/analytics/social/analyticsfacebooksummary/analyticsfacebooksummary.component';
import { AnalyticslinkedinsummaryComponent } from '../applications/analytics/social/analyticslinkedinsummary/analyticslinkedinsummary.component';
import { AnalyticstwittersummaryComponent } from '../applications/analytics/social/analyticstwittersummary/analyticstwittersummary.component';
import { AnalyticsloanrepaymentComponent } from '../applications/analytics/repayment/analyticsloanrepayment/analyticsloanrepayment.component';
import { AnalyticsloanrepaymentcomparismComponent } from '../applications/analytics/repayment/analyticsloanrepaymentcomparism/analyticsloanrepaymentcomparism.component';
import { AnalyticsloanrepaymenthistoryComponent } from '../applications/analytics/repayment/analyticsloanrepaymenthistory/analyticsloanrepaymenthistory.component';
import { AnalyticsloanrepaymentstatsComponent } from '../applications/analytics/repayment/analyticsloanrepaymentstats/analyticsloanrepaymentstats.component';
import { AnalyticsloanperformancelogComponent } from '../applications/analytics/repayment/analyticsloanperformancelog/analyticsloanperformancelog.component';
import { ProfileanalysisComponent } from '../applications/analytics/profile/profileanalysis/profileanalysis.component';
import { AnalyticstatsComponent } from '../applications/analytics/profile/analyticstats/analyticstats.component';
import { ProfileanalysisnodeComponent } from '../applications/analytics/profile/profileanalysisnode/profileanalysisnode.component';
import { AnalyticscomparismComponent } from '../applications/analytics/profile/analyticscomparism/analyticscomparism.component';
import { AnalyticsspiderserviceComponent } from '../applications/analytics/loancrawler/analyticsspiderservice/analyticsspiderservice.component';
import { AnalyticsmenuComponent } from '../applications/analytics/loancrawler/analyticsmenu/analyticsmenu.component';
import { CreditbureauComponent } from '../applications/analytics/creditcheck/creditbureau/creditbureau.component';
import { AnalyticscreditbureauComponent } from '../applications/analytics/creditcheck/analyticscreditbureau/analyticscreditbureau.component';
import { AnalyticscreditbureaudetailsComponent } from '../applications/analytics/creditcheck/analyticscreditbureaudetails/analyticscreditbureaudetails.component';
import { CreditbureaudetailsComponent } from '../applications/analytics/creditcheck/creditbureaudetails/creditbureaudetails.component';
import { ApplicationscollateralComponent } from '../applications/applicationscollateral/applicationscollateral.component';
import { ApplicationsguarantorsComponent } from '../applications/applicationsguarantors/applicationsguarantors.component';
import { ApplicationsguarantorComponent } from '../applications/applicationsguarantor/applicationsguarantor.component';
import { ApplicationscreditcheckComponent } from '../applications/applicationscreditcheck/applicationscreditcheck.component';
import { ApplicationsrequestsComponent } from '../applications/applicationsrequests/applicationsrequests.component';
import { ApplicationsofferletterComponent } from '../applications/applicationsofferletter/applicationsofferletter.component';
import { DisbursementComponent } from '../applications/disbursement/disbursement.component';
import { TopupwalletComponent } from '../applications/topupwallet/topupwallet.component';
import { SplitpaymentComponent } from '../applications/splitpayment/splitpayment.component';
import { ProfilemenuComponent } from '../applications/analytics/profile/profilemenu/profilemenu.component';
import { RemitainflightComponent } from '../applications/remitainflight/remitainflight.component';
import { AdvancesearchComponent } from '../applications/advancesearch/advancesearch.component';
import { LocationdetailsComponent } from '../applications/locationdetails/locationdetails.component';
import { ApplicationseligibilityComponent } from '../applications/applicationseligibility/applicationseligibility.component';
import { ApplicationsrejectedComponent } from '../applications/applicationsrejected/applicationsrejected.component';
import { CreditregistryComponent } from '../applications/creditregistry/creditregistry.component';
import { ApplicationsapprovalsComponent } from '../applications/applicationsapprovals/applicationsapprovals.component';
import { TatComponent } from '../applications/applicationsapprovals/tat/tat.component';
import { PendingApprovalsComponent } from '../applications/applicationsapprovals/pending-approvals/pending-approvals.component';
import { OldestapprovalComponent } from '../applications/applicationsapprovals/oldestapproval/oldestapproval.component';
import { ApprovalcalendarComponent } from '../applications/applicationsapprovals/approvalcalendar/approvalcalendar.component';
import { RemitacardbankanalyticsComponent } from '../applications/analytics/remitacardbankanalytics/remitacardbankanalytics.component';
import { InitiatebankstatementanalysisComponent } from '../applications/initiatebankstatementanalysis/initiatebankstatementanalysis.component';
import { OthersComponent } from '../applications/analytics/others/others.component';
import { ContractstatusComponent } from '../applications/contractstatus/contractstatus.component';
import { LoanDisbursedEmailComponent } from '../email-template/loan-disbursed-email/loan-disbursed-email.component';
import { EmailSettingsLayoutComponent } from '../email-template/email-settings-layout/email-settings-layout.component';
import { RejectedrequestComponent } from './rejectedrequest/rejectedrequest.component';
import { IneligiblerequestComponent } from './ineligiblerequest/ineligiblerequest.component';
import { InvitingnewcustomerComponent } from './invitingnewcustomer/invitingnewcustomer.component';
import { OtherheaderComponent } from './otherheader/otherheader.component';
import { CardcollectiontimesComponent } from './cardcollectiontimes/cardcollectiontimes.component';
import { AutodebithistoryComponent } from './autodebithistory/autodebithistory.component';
import { InvitemultiplecustomersComponent } from './invitemultiplecustomers/invitemultiplecustomers.component';
import { BreakingloanComponent } from './breakingloan/breakingloan.component';
import { LoancontractsectionComponent } from './loancontractsection/loancontractsection.component';
// import {AlljournalsComponent} from '../operations/alljournals/alljournals.component';

import { NewaccountComponent } from './newaccount/newaccount.component';
import { ApplicationportfolioheaderComponent } from '../applicationportfolio/applicationportfolioheader/applicationportfolioheader.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { ApproveloanComponent } from './approveloan/approveloan.component';
import { GolivemodalComponent } from './golivemodal/golivemodal.component';
import { BrodcastComponent } from './brodcast/brodcast.component';
import { CreditbureausComponent } from '../setting-module/creditbureaus/creditbureaus.component';

import { ProfileAnalysisComponent } from '../setting-module/profile-analysis/profile-analysis.component';
import { GeneralComponent } from '../setting-module/general/general.component';
import { LenderTypePipe } from '../_pipes/lender-type.pipe';
import { LoanpurposePipe } from '../_pipes/loanpurpose.pipe';
import { EmailHeaderContentComponent } from '../email-template/email-header-content/email-header-content.component';
import { EmailFooterContentComponent } from '../email-template/email-footer-content/email-footer-content.component';

import { PostingbulkpaymentsComponent } from '../operations/postingbulkpayments/postingbulkpayments.component';
import { CellComponent } from './cell/cell.component';
import { TicTacToeComponent } from './tic-tac-toe/tic-tac-toe.component';
import { AlljournalsComponent } from '../accounting/alljournals/alljournals.component';
import { NewjournalComponent } from '../accounting/newjournal/newjournal.component';
import { TransactionformComponent } from '../transactionform/transactionform.component';
import { FundyourwalletComponent } from './fundyourwallet/fundyourwallet.component';
import { ApprovingprepaymentComponent } from './approvingprepayment/approvingprepayment.component';
import { TrialbalanceComponent } from '../reports/trialbalance/trialbalance.component';
import { IncomestatementComponent } from '../reports/incomestatement/incomestatement.component';
import { FinancialpositionComponent } from '../reports/financialposition/financialposition.component';
import { GeneralledgerComponent } from '../reports/generalledger/generalledger.component';
import { PlgroupingComponent } from '../reports/plgrouping/plgrouping.component';
import { BsgroupingComponent } from '../reports/bsgrouping/bsgrouping.component';
import { JournalreportsComponent } from '../reports/journalreports/journalreports.component';
import { PostingbreakloansComponent } from './postingbreakloans/postingbreakloans.component';
import { PreviewrepaymentscheduleComponent } from './previewrepaymentschedule/previewrepaymentschedule.component';
import { ProfileanalysismodalComponent } from './profileanalysismodal/profileanalysismodal.component';
import { RequestitemComponent } from './requestitem/requestitem.component';
import { AgeingComponent } from './ageing/ageing.component';
import { PostingbulkpaymentscalendarComponent } from './postingbulkpaymentscalendar/postingbulkpaymentscalendar.component';
import { CalendarcashflowComponent } from './calendarcashflow/calendarcashflow.component';
import { AllinvitesComponent } from './allinvites/allinvites.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { CustomerreportedpaymentsComponent } from './customerreportedpayments/customerreportedpayments.component';
import { TimerComponent } from './timer/timer.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { UnderconstructionComponent } from './underconstruction/underconstruction.component';
import { InvestmentdashboardComponent } from './investmentdashboard/investmentdashboard.component';
import { InvestmentdetailsComponent } from './investmentdetails/investmentdetails.component';
import { HelpPopoverComponent } from './help-popover/help-popover.component';
import { OfferLetterComponent } from '../setting-module/offer-letter/offer-letter.component';
import { IndebtednessletterComponent } from '../setting-module/indebtednessletter/indebtednessletter.component';
import { UploadpaymentsComponent } from '../operations/uploadpayments/uploadpayments.component';
import { ScoredbasedinterestrateComponent } from './scoredbasedinterestrate/scoredbasedinterestrate.component';

import { DemoNumberPipe } from '../_pipes/demo-number.pipe';
import { RequeststatusPipe } from '../_pipes/requeststatus.pipe';
import { GlcategoryPipe } from '../_pipes/glcategory.pipe';
import { NoRightComponent } from './no-right/no-right.component';
import { BulkdisbursementdownloadComponent } from './bulkdisbursementdownload/bulkdisbursementdownload.component';
import { InvestmentitemComponent } from './investmentitem/investmentitem.component';
import { InvestmentcontractComponent } from './investmentcontract/investmentcontract.component';
import { InvestmentscheduleComponent } from './investmentschedule/investmentschedule.component';
import { InvestmentstatementComponent } from './investmentstatement/investmentstatement.component';
import { InvestmentloancontractComponent } from './investmentloancontract/investmentloancontract.component';
import { SmsLatenessNotificationComponent } from './sms-lateness-notification/sms-lateness-notification.component';
import { SmsDueReminderComponent } from './sms-due-reminder/sms-due-reminder.component';
import { DueReminderComponent } from '../email-template/due-reminder/due-reminder.component';
import { PerformancesnapshotComponent } from './performancesnapshot/performancesnapshot.component';
import { PortfolioageinganalysisComponent } from './portfolioageinganalysis/portfolioageinganalysis.component';
import { PortfoliostaffperformanceComponent } from './portfoliostaffperformance/portfoliostaffperformance.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { NoAnalyticsDataComponent } from './no-analytics-data/no-analytics-data.component';
import { OfferlettertemplateComponent } from './offerlettertemplate/offerlettertemplate.component';
import { WidgetPagesSettingsComponent } from './widget-pages-settings/widget-pages-settings.component';
import { QnaComponent } from './qna/qna.component';
import { ChartsModule } from 'ng2-charts';
import { LenderaccountstatementComponent } from './lenderaccountstatement/lenderaccountstatement.component';
import { EmailsenderComponent } from './emailsender/emailsender.component';
import { SmssenderComponent } from './smssender/smssender.component';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { TemplatedesignComponent } from './templatedesign/templatedesign.component';
import { EmailtemplatedesignComponent } from '../setting-module/emailtemplatedesign/emailtemplatedesign.component';
import { EmailtagsComponent } from './emailtags/emailtags.component';
import { CaryearPipe } from '../_pipes/caryear.pipe';
import { CollateralComponent } from '../applications/collateral/collateral.component';
import { AnalyticscontractheaderComponent } from './analyticscontractheader/analyticscontractheader.component';
import { UpdateemployeeComponent } from './updateemployee/updateemployee.component';
import { BorrowedbasedamountComponent } from './borrowedbasedamount/borrowedbasedamount.component';
import { LinkgeneratorComponent } from './linkgenerator/linkgenerator.component';
import { ProductchecklistComponent } from './productchecklist/productchecklist.component';
import { ApplicationschecklistComponent } from './applicationschecklist/applicationschecklist.component';
import { GenderPipe } from '../_pipes/gender.pipe';

import { InsurancePipe } from '../_pipes/insurance.pipe';
import { PlatformearningsComponent } from './platformearnings/platformearnings.component';
import { NewbookloanformComponent } from './newbookloanform/newbookloanform.component';
import { LoanactionsComponent } from './loanactions/loanactions.component';
import { CardiframeComponent } from './cardiframe/cardiframe.component';
import { HtmlpipePipe } from '../_pipes/htmlpipe.pipe';
import { InviteguarantorsComponent } from './inviteguarantors/inviteguarantors.component';
import { AccountofficerComponent } from './accountofficer/accountofficer.component';
import { IndebtednessComponent } from './indebtedness/indebtedness.component';
import { AssigncollectionagentComponent } from './assigncollectionagent/assigncollectionagent.component';
import { InvestmentnavigationComponent } from './investmentnavigation/investmentnavigation.component';
import { ApplicationsoffersComponent } from './applicationsoffers/applicationsoffers.component';
import { InvestmentsportfolioheaderComponent } from './investmentsportfolioheader/investmentsportfolioheader.component';
import { MarketplaceofferComponent } from './marketplaceoffer/marketplaceoffer.component';
import { InterestdurationPipe } from '../_pipes/interestduration.pipe';
import { BankstatementuploadmodalComponent } from '../applications/analytics/bankstatement/bankstatementuploadmodal/bankstatementuploadmodal.component';
import { RequsetSideviewComponent } from '../applications/requset-sideview/requset-sideview.component';
import { ApprovinginvestmentComponent } from './approvinginvestment/approvinginvestment.component';
import { CustomerotherrequestssComponent } from './customerotherrequestss/customerotherrequestss.component';
import { DueloansbydateComponent } from './dueloansbydate/dueloansbydate.component';
import { NewinvestmentrequestformComponent } from './newinvestmentrequestform/newinvestmentrequestform.component';
import { CustomerwalletsglComponent } from './customerwalletsgl/customerwalletsgl.component';
import { SavingsactionComponent } from '../customersavings/savingsaction/savingsaction.component';
import { SavingsdisbursementComponent } from '../customersavings/savingsdisbursement/savingsdisbursement.component';
import { SavingsinterestPipe } from '../_pipes/savingsinterest.pipe';
import { RecollectionoptionsComponent } from './recollectionoptions/recollectionoptions.component';
import { AnalysisAreaOfFocusComponent } from './analysis-area-of-focus/analysis-area-of-focus.component';
import { BankStatementAnalyzeComponent } from './bank-statement-analyze/bank-statement-analyze.component';
import { IdentityCheckComponent } from './identity-check/identity-check.component';
import { BlockchainAnalysisComponent } from './blockchain-analysis/blockchain-analysis.component';
import { RequestQuickviewComponent } from './request-quickview/request-quickview.component';
import { AttachmentsCardComponent } from './attachments-card/attachments-card.component';
import { BankStatementAnalysisModalComponent } from './bank-statement-analysis-modal/bank-statement-analysis-modal.component';
import { AccountandcardsCardComponent } from './accountandcards-card/accountandcards-card.component';
import { BvndetailsCardComponent } from './bvndetails-card/bvndetails-card.component';
import { PastloansComponent } from './pastloans/pastloans.component';
import { BankStatementAnalysisResultComponent } from './bank-statement-analysis-result/bank-statement-analysis-result.component';
import { AgentsRepaymentModeComponent } from './agents-repayment-mode/agents-repayment-mode.component';
import { BreakingActiveLoanComponent } from './breaking-active-loan/breaking-active-loan.component';
import { CollectionsComponent } from './collections/collections.component';
import { MiniPerfomanceSnapshotComponent } from './mini-perfomance-snapshot/mini-perfomance-snapshot.component';
import { RequestNotificationComponent } from './request-notification/request-notification.component';
import { UnderContructionComponent } from './under-contruction/under-contruction.component';
import { ApplicationsPortfolioComponent } from '../applicationportfolio/applications/applications.component';
import { PortfoliotrendsComponent } from '../applicationportfolio/portfoliotrends/portfoliotrends.component';
import { PortfoliodistributionComponent } from '../applicationportfolio/portfoliodistribution/portfoliodistribution.component';
import { RepaymentsourcedistributionComponent } from '../applicationportfolio/repaymentsourcedistribution/repaymentsourcedistribution.component';
import { TotalInvestmentsComponent } from './total-investments/total-investments.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { UpdatingapprovallevelComponent } from './updatingapprovallevel/updatingapprovallevel.component';
import { RepaymentsnapshotComponent } from './repaymentsnapshot/repaymentsnapshot.component';
import { RequestHeroComponent } from './request-hero/request-hero.component';
import { AssignaccountofficerComponent } from './assignaccountofficer/assignaccountofficer.component';
import { RequestingdataComponent } from './requestingdata/requestingdata.component';
import { SmsTransactionsAnalysisComponent } from './sms-transactions-analysis/sms-transactions-analysis.component';
import { InitiatesmstransactionanalysisComponent } from './initiatesmstransactionanalysis/initiatesmstransactionanalysis.component';
import { SmsViewTransactionsModalComponent } from './sms-view-transactions-modal/sms-view-transactions-modal.component';
import { MicrocreditsummaryComponent } from './microcreditsummary/microcreditsummary.component';
import { MicrocreditresultComponent } from './microcreditresult/microcreditresult.component';
import { NinbyphonenumberComponent } from './ninbyphonenumber/ninbyphonenumber.component';
import { IppisdetailsComponent } from './ippisdetails/ippisdetails.component';
import { RemitainfodetailsComponent } from './remitainfodetails/remitainfodetails.component';
import { YeargeneratorPipe } from '../_pipes/yeargenerator.pipe';
import { EligibilitycriteriaComponent } from './eligibilitycriteria/eligibilitycriteria.component';
import { RequestlastactivityComponent } from './requestlastactivity/requestlastactivity.component';
import { EligibilityanalysismodalComponent } from './eligibilityanalysismodal/eligibilityanalysismodal.component';
import { HomecustomersideviewComponent } from './homecustomersideview/homecustomersideview.component';
import { CustomerinfoonlyComponent } from './customerinfoonly/customerinfoonly.component';
import { DuerepaymentsComponent } from '../applicationportfolio/duerepayments/duerepayments.component';
import { PortfoliorequestComponent } from '../applicationportfolio/portfoliorequest/portfoliorequest.component';
import { PortfoliotargetComponent } from '../applicationportfolio/portfoliotarget/portfoliotarget.component';
import { ChooseoptionComponent } from '../applicationportfolio/repaymentflow/chooseoption/chooseoption.component';
import { BorrowerdetailsComponent } from '../applicationportfolio/borrowerdetails/borrowerdetails.component';
import { RepaymentflowComponent } from '../applicationportfolio/repaymentflow/repaymentflow.component';
import { ConfirmpaymentComponent } from '../applicationportfolio/repaymentflow/confirmpayment/confirmpayment.component';
import { CardpaymentComponent } from '../applicationportfolio/repaymentflow/cardpayment/cardpayment.component';
import { RemitaComponent } from '../applicationportfolio/repaymentflow/remita/remita.component';
import { PortfolioheaderComponent } from '../applicationportfolio/portfolioheader/portfolioheader.component';
import { FlowsummaryComponent } from '../applicationportfolio/flowsummary/flowsummary.component';
import { MakingpaymentComponent } from '../applicationportfolio/makingpayment/makingpayment.component';
import { PrepaymentComponent } from '../applicationportfolio/prepayment/prepayment.component';
import { PortfolioSideviewComponent } from '../applicationportfolio/portfolio-sideview/portfolio-sideview.component';
import { ApplicationsindebtednessComponent } from '../applicationportfolio/applicationsindebtedness/applicationsindebtedness.component';
import { CustomerScheduleComponent } from '../customers/customer-schedule/customer-schedule.component';
import { CustomerTimelineComponent } from '../customers/customer-timeline/customer-timeline.component';
import { HomenavigationComponent } from '../homenavigation/homenavigation.component';
import { CustomerAccountSettingsComponent } from '../customers/customer-account-settings/customer-account-settings.component';
import { CreateanewcustomerComponent } from './createanewcustomer/createanewcustomer.component';
import { CustomerCreateComponent } from '../customers/customer-create/customer-create.component';
import { IndcustomerComponent } from './indcustomer/indcustomer.component';
import { ModifyloanformComponent } from './modifyloanform/modifyloanform.component';
import { OkratransactionsviewComponent } from './okratransactionsview/okratransactionsview.component';
import { CardtokennizationComponent } from './cardtokennization/cardtokennization.component';
import { MonotransactionsviewComponent } from './monotransactionsview/monotransactionsview.component';
import { IppischeckerComponent } from './ippischecker/ippischecker.component';
import { BankstatementsettingsComponent } from './bankstatementsettings/bankstatementsettings.component';
import { NibbsdebitComponent } from './nibbsdebit/nibbsdebit.component';
import { PushingtomarketComponent } from './pushingtomarket/pushingtomarket.component';
import { RequestloanproductComponent } from '../applications/requestloanproduct/requestloanproduct.component';
import { RequestSideviewMorePicturesComponent } from '../others/request-sideview-more-pictures/request-sideview-more-pictures.component';
import { SquareBoxComponent } from '../others/square-box/square-box.component';
import { FraudtestComponent } from './fraudtest/fraudtest.component';
import { UpfrontpaymentComponent } from './upfrontpayment/upfrontpayment.component';
import { AccountbalanceComponent } from './accountbalance/accountbalance.component';
import { FcnComponent } from './fcn/fcn.component';
import { CyberpaypaymentsComponent } from './cyberpaypayments/cyberpaypayments.component';
import { PaystackpaymentsComponent } from './paystackpayments/paystackpayments.component';
import { KyccheckerComponent } from './kycchecker/kycchecker.component';
import { DuplicatecheckerComponent } from './duplicatechecker/duplicatechecker.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { ExcesspaymentComponent } from './excesspayment/excesspayment.component';
import { PossibledoubledebitsComponent } from './possibledoubledebits/possibledoubledebits.component';
import { MandatestatusComponent } from './mandatestatus/mandatestatus.component';
import { LoanschedulemodalComponent } from './loanschedulemodal/loanschedulemodal.component';
import { WemapaymentsComponent } from './wemapayments/wemapayments.component';
import { AtaglanceComponent } from './ataglance/ataglance.component';
import { EatTableComponent } from '../summary/summary/tables/eat-table/eat-table.component';
import { UpdateModalComponent } from '../summary/modals/update-modal/update-modal.component';
import { NewRecordComponent } from '../summary/modals/new-record/new-record.component';
import { SideitemComponent } from '../summary/sideitem/sideitem.component';
import { SummaryComponent } from '../summary/summary/summary.component';
import { SummaryRequestitemComponent } from '../summary-requestitem/summary-requestitem.component';
import { WemaPaymentsComponent } from '../wema-payments/wema-payments.component';
import { RequestssummaryComponent } from './requestssummary/requestssummary.component';
import { LedgerreportsComponent } from '../reports/ledgerreports/ledgerreports.component';
import { CommentComponent } from './comment/comment.component';
import { RsummaryComponent } from './rsummary/rsummary.component';
import { CheckpartnerstatusComponent } from './checkpartnerstatus/checkpartnerstatus.component';
import { StatementlinkComponent } from './statementlink/statementlink.component';

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, ReactiveFormsModule, MyDatePickerModule,
    IonRangeSliderModule, MomentModule, UiSwitchModule, NgxEditorModule, ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCWvWygNM2UBh-WY8FMilv2GghJmtSRt0k'
    }),
    OrderModule, UploadModule, NguiDatetimePickerModule, InfiniteScrollModule,
    NgxTypeaheadModule, CountdownModule, ColorPickerModule
  ],
  entryComponents: [ChangepaymentComponent],
  declarations: [
    AtaglanceComponent,LedgerreportsComponent,
    HomenavigationComponent,InsurancePipe, AlljournalsComponent, GlcategoryPipe, CaryearPipe, CollateralComponent, GenderPipe,RequestSideviewMorePicturesComponent,
    RequeststatusPipe, LimittoPipe, SafePipe, LoanpurposePipe, DemoNumberPipe, ApplicationportfolioheaderComponent,SquareBoxComponent,
    RoundPipe, LoandurationPipe, RequestdurationPipe, LoanStatusPipe, OfferstatusPipe, PeertopeerPipe, SafehtmlPipe,
    MaritalstatusPipe, ComingSoonComponent, AllinvitesComponent, DateinvalidPipe, PercentageFundedPipe, AgefilterPipe,
    MycurrencyPipe, ProductapprovalsComponent, SearchanalysissettingPipe, FilterpipePipe, ImagenotfoundPipe, PayscheduleComponent,
    BackendsettingsComponent, LevelformComponent, MobileComponent, JournalapprovedPipe, MoneyPipe, MainNavigationComponent, LoanapprovalsComponent,
    LoanformComponent, LoanfeesComponent, LoanchargesComponent, LoanchecklistComponent, WebUssdComponent,
    LoanguarantorsComponent, LoanpeertopeerComponent, NewempformComponent, CreditbureausComponent,
    RequestformComponent, LoancustomerformComponent, SavingsNavigationComponent,CustomerScheduleComponent,CustomerTimelineComponent,
    RequsetSideviewComponent, SavingsdisbursementComponent,
    CalendarComponent, UnauthorizedComponent, ProductsComponent, ProductSettingComponent,
    ViewguarantorComponent, LoananalysisComponent, LoanworkflowComponent, ProfileAnalysisComponent,
    CalendarviewComponent, CalendardetailsComponent, LoanmoreComponent,
    RatingComponent, BorroweridComponent, BreakloanComponent, LoansummaryComponent, PaymentrollbackComponent,
    MakepaymentComponent, CustomersummaryComponent, ScheduleComponent, RepayComponent, LoanprofileComponent,
    CustomerFinanceComponent, CustomerInvestmentsComponent, InterestdurationPipe,
    CustomerLoansComponent, LoancontractComponent, LoandocumentComponent,
    BankstatementuploadmodalComponent, UpdatingapprovallevelComponent,
    ChangeproductComponent, LoanlogsComponent, LoancontractformComponent,
    UserlocationComponent, LoancontractformComponent, LoanlendersComponent,
    LoanStatusTextPipe, WalletdetailsComponent, EmpsearchComponent, LoanlocationComponent, LoanchequeComponent, LoanphotosComponent,
    LoandocumentComponent, LoanacctsComponent, LoancollateralComponent,
    LoanlogsComponent, PaymentComponent, ApprovalviewComponent, NewbankformComponent, QueuecancelComponent,
    LoanchatComponent, NoaPipe, GeneralComponent,
    NewloanformComponent,PartnerloanformComponent,
    CustomeranalysisComponent, BulkpaymentComponent, CustomerUpdateComponent, LedgerComponent, GeneralformComponent,
    InterestformComponent, LoanamountComponent, InterestratefloorComponent, OriginationfeeComponent, NewlevelformComponent,
    CustomerRemitaComponent, KycdetailsComponent, CustomerkycComponent, AdjustweightsComponent,
    IncludedcompaniesComponent, LoanrepaymentComponent, DirectdebitComponent, CreditcheckComponent,
    DirectdebitinitiateComponent, DirectdebitstatusComponent, DirectdebitcancelComponent,
    StopmandateComponent, CreditcheckdetailsComponent, ContractofferComponent,
    AllcustomersComponent, BankslistComponent, CompaniesComponent,
    NotificationsComponent, TimelineComponent, ChangepaymentComponent, ChangeloanofficerComponent,
    CreditingstatementComponent, ContractheaderComponent,
    SmslogsComponent, MakeloanpaymentComponent, CardcollectionComponent, ClearingfinesComponent,
    CustomerwithdrawalComponent,
    StopremitaComponent,
    NotifyremitaComponent, PopupcalendarComponent,
    RefreshremitaComponent, SavingsgeneralsettingsComponent, SavingsinterestsettingsComponent, SavingsprematuritybreakingComponent,
    SavingswithdrawalssettingsComponent, SavingsapplicationrequirementsComponent, SavingsapprovalsComponent, BuybackloanComponent,
    PostsavingsComponent, BreakinvestmentComponent, InvestmentwithdrawalComponent, BooksavingsComponent, ButtonComponent, InputComponent,
    LoanstatementComponent, NewcollateralComponent, PhonenumbersComponent, ItemComponent,
    PortfoliocalendarComponent, PortfolioComponent, StatementscheduleComponent, LoanactionComponent, SecurityComponent,
    ProductbasicComponent, ProductsettingsComponent,
    WelcomeEmailComponent, PhoneOtpComponent, RestPinComponent,
    DirectDebitEmailComponent, ValidationWorkEmailComponent,
    LoanRejectionEmailComponent, PaymentReceivedComponent, LenderMakeOfferComponent,
    BorrowerAccpectedOfferLetterComponent, BorrowerReceivedOfferEmailComponent,
    WithdrawalEmailComponent, SentToMarketComponent, GuarantorRequestEmailComponent,
    CardRequestEmailComponent, BankAccountConfirmationEmailComponent,
    RequestForAttachmentsComponent, BvnVerificationEmailComponent, WebsiteSettingsComponent, EmailSettingComponent,
    RepaymentReminderEmailComponent, LoanDisbursedEmailComponent, RunbankstatementanalysisComponent,
    LoanportfolioactionComponent, BookloanformComponent, RequestcalendarComponent, SchedulemodalComponent,
    GuarantorscoverComponent, GuarantorcontactsComponent, AddGuarantorComponent, WalletaccountComponent, WalletcardComponent,
    WalletfundingcardsComponent, PortfoliotargetmodalComponent,
    CalendarpopoutmodalComponent,
    OldestapprovalComponent, PostingbulkpaymentsComponent,
    PendingApprovalsComponent, DueReminderComponent,
    TatComponent,
    ApplicationspendingComponent,
    PendingapprovalsComponent,
    BvnmodalComponent,
    CarddebittrylogsmodalComponent,
    CardmodalComponent,
    CardnumbermodalComponent,
    CardrecollectionmodalComponent,
    ChequesetupmodalComponent,
    DefaultrepaymentmodalComponent,
    DirectdebitsettingsComponent,
    DisbursementmodalComponent,
    DocumentsmodalComponent,
    EnlargeimageComponent,
    FundlenderComponent,
    InflightdetailsComponent,
    LoandetailsComponent,
    MakepaymentmodalComponent,
    PaymentmodemodalComponent,
    RecollectionmodeComponent,
    RecollectionsettingspasswordmodalComponent,
    RejectlenderofferComponent,
    RemovemarketofferComponent,
    RepaymentfooterComponent,
    RunfullanalyticsmodalComponent,
    SendtomarketComponent,
    UserheaderComponent,
    ApplicationscontractComponent,
    ContractdetailsComponent,
    CustomerdetailsComponent,
    RepaymentscheduleComponent,
    CreaterequestmodalComponent, SavingsactionComponent,
    AccountmodalComponent,
    ApplicationstimelineComponent,
    CurrentapprovallevelComponent,
    RequestheaderComponent,
    RequestfooterComponent,
    CarddebitsetupComponent,
    AccountdebitComponent,
    HomeComponent,
    EatTableComponent,
    UpdateModalComponent,
    NewRecordComponent,
    SideitemComponent,
    SummaryComponent,
    SummaryRequestitemComponent,
    AnalyticshomeComponent,
    AnalyticsheaderComponent,
    PeertopeerComponent,
    SelectedanalyticsComponent,
    RunselectedanalyticsmodalComponent,
    AnalyticssmssummaryComponent,
    AnalyticssmsanalysisComponent,
    AnalyticssmslogsComponent,
    AnalyticssmslogsdataComponent,
    AnalyticscallanalysisComponent,
    AnalyticscalllogsdataComponent,
    AnalyticscalllogsstatsComponent,
    AnalyticsphonesummaryComponent,
    AnalyticssocialdataComponent,
    AnalyticssocialstatsComponent,
    AnalyticsfacebooksummaryComponent,
    AnalyticslinkedinsummaryComponent,
    AnalyticstwittersummaryComponent,
    AnalyticsloanrepaymentComponent,
    AnalyticsloanrepaymentcomparismComponent,
    AnalyticsloanrepaymenthistoryComponent,
    AnalyticsloanrepaymentstatsComponent,
    AnalyticsloanperformancelogComponent,
    ProfileanalysisComponent,
    AnalyticstatsComponent,
    ProfileanalysisnodeComponent,
    NewaccountComponent,
    AnalyticscomparismComponent,
    AnalyticsspiderserviceComponent,
    AnalyticsmenuComponent,
    CreditbureauComponent,
    CreditbureaudetailsComponent,
    AnalyticscreditbureauComponent,
    AnalyticscreditbureaudetailsComponent,
    ApplicationscollateralComponent,
    ApplicationsguarantorsComponent,
    ApplicationsguarantorComponent,
    ApplicationscreditcheckComponent,
    ApplicationsrequestsComponent,
    ApplicationsofferletterComponent,
    DisbursementComponent,
    TopupwalletComponent,
    SplitpaymentComponent,
    ProfilemenuComponent, LocationdetailsComponent,
    RemitainflightComponent, AdvancesearchComponent,
    ApplicationseligibilityComponent, ApplicationsrejectedComponent, CreditregistryComponent,
    ApplicationsapprovalsComponent, ApprovalcalendarComponent,
    RemitacardbankanalyticsComponent, InitiatebankstatementanalysisComponent, OthersComponent, ContractstatusComponent,
    WalletfundingcardsComponent, PortfoliotargetmodalComponent, TransactionformComponent
    , EmailSettingsLayoutComponent, RejectedrequestComponent, IneligiblerequestComponent, InvitingnewcustomerComponent,
    OtherheaderComponent, CardcollectiontimesComponent, AutodebithistoryComponent, InvitemultiplecustomersComponent, BrodcastComponent,
    BreakingloanComponent, LoancontractsectionComponent, BroadcastComponent, ApproveloanComponent, GolivemodalComponent,
    NewjournalComponent, LenderTypePipe, EmailHeaderContentComponent, EmailFooterContentComponent, CellComponent,
    TicTacToeComponent, FundyourwalletComponent, ApprovingprepaymentComponent,
    TrialbalanceComponent,
    IncomestatementComponent, OfferLetterComponent,
    FinancialpositionComponent,
    GeneralledgerComponent,
    PlgroupingComponent,
    BsgroupingComponent,
    ComingSoonComponent,
    AllinvitesComponent, UploadpaymentsComponent,
    JournalreportsComponent,
    WalletfundingcardsComponent, PortfoliotargetmodalComponent, TransactionformComponent
    , EmailSettingsLayoutComponent, RejectedrequestComponent, IneligiblerequestComponent, InvitingnewcustomerComponent,
    OtherheaderComponent, CardcollectiontimesComponent, AutodebithistoryComponent, InvitemultiplecustomersComponent, BrodcastComponent,
    BreakingloanComponent, LoancontractsectionComponent, BroadcastComponent, ApproveloanComponent, GolivemodalComponent,
    NewjournalComponent, LenderTypePipe, EmailHeaderContentComponent, EmailFooterContentComponent, CellComponent,
    TicTacToeComponent, FundyourwalletComponent, ApprovingprepaymentComponent, PostingbreakloansComponent, PreviewrepaymentscheduleComponent,
    ProfileanalysismodalComponent, RequestitemComponent, AgeingComponent, PostingbulkpaymentscalendarComponent, CalendarcashflowComponent,
    CustomerreportedpaymentsComponent, TimerComponent, PlaceholderComponent, UnderconstructionComponent,
    InvestmentdashboardComponent, InvestmentdetailsComponent, HelpPopoverComponent, ScoredbasedinterestrateComponent, NoRightComponent, BulkdisbursementdownloadComponent, InvestmentitemComponent
    , InvestmentcontractComponent, InvestmentscheduleComponent, InvestmentstatementComponent, InvestmentloancontractComponent, SmsLatenessNotificationComponent,
    SmsDueReminderComponent,
    PerformancesnapshotComponent,
    PortfolioageinganalysisComponent,
    PortfoliostaffperformanceComponent,
    PageLoadingComponent,
    NoAnalyticsDataComponent,
    OfferlettertemplateComponent,
    WidgetPagesSettingsComponent, EmailtemplatedesignComponent,
    QnaComponent, LenderaccountstatementComponent, EmailsenderComponent,
    SmssenderComponent, CollateralPipe, TemplatedesignComponent,
    CustomerInfoComponent,
    EmailtagsComponent,
    AnalyticscontractheaderComponent,
    UpdateemployeeComponent,
    BorrowedbasedamountComponent,
    LinkgeneratorComponent,
    ProductchecklistComponent,
    ApplicationschecklistComponent,
    PlatformearningsComponent,
    NewbookloanformComponent, SavingsinterestPipe,
    CardiframeComponent,
    HtmlpipePipe,
    InviteguarantorsComponent,
    LoanactionsComponent,
    AccountofficerComponent,
    RecommendationsComponent,
    IndebtednessComponent, IndebtednessletterComponent, AssigncollectionagentComponent, InvestmentnavigationComponent,
    ApplicationsoffersComponent, InvestmentsportfolioheaderComponent, MarketplaceofferComponent, ApprovinginvestmentComponent,
    CustomerotherrequestssComponent, DueloansbydateComponent, NewinvestmentrequestformComponent, CustomerwalletsglComponent, RecollectionoptionsComponent, AnalysisAreaOfFocusComponent, BankStatementAnalyzeComponent, IdentityCheckComponent, BlockchainAnalysisComponent, RequestQuickviewComponent, AttachmentsCardComponent, BankStatementAnalysisModalComponent, AccountandcardsCardComponent, BvndetailsCardComponent, PastloansComponent, BankStatementAnalysisResultComponent, AgentsRepaymentModeComponent, BreakingActiveLoanComponent, CollectionsComponent, MiniPerfomanceSnapshotComponent,
    RequestNotificationComponent,
    UnderContructionComponent,
    ApplicationsPortfolioComponent,
    PortfoliotrendsComponent,
    PortfoliodistributionComponent,
    RepaymentsourcedistributionComponent,
    TotalInvestmentsComponent,
    ProfilePictureComponent,YeargeneratorPipe,
    RepaymentsnapshotComponent,
    RequestHeroComponent,
    AoffPipe,
    WemaPaymentsComponent,
    CustomerAccountSettingsComponent,
    AssignaccountofficerComponent,
    RequestingdataComponent,SmsTransactionsAnalysisComponent, InitiatesmstransactionanalysisComponent, SmsViewTransactionsModalComponent, 
    MicrocreditsummaryComponent, MicrocreditresultComponent, NinbyphonenumberComponent, IppisdetailsComponent, 
    RemitainfodetailsComponent, EligibilitycriteriaComponent, RequestlastactivityComponent, EligibilityanalysismodalComponent, HomecustomersideviewComponent, CustomerinfoonlyComponent,
    DuerepaymentsComponent, PortfoliorequestComponent,
    CustomerCreateComponent,CheckpartnerstatusComponent,
    RequestloanproductComponent,
    PortfoliotargetComponent, BorrowerdetailsComponent,
    RepaymentflowComponent, ChooseoptionComponent, ConfirmpaymentComponent, CardpaymentComponent,
    RemitaComponent, PortfolioheaderComponent, FlowsummaryComponent, MakingpaymentComponent, PrepaymentComponent, PortfolioSideviewComponent, ApplicationsindebtednessComponent, CreateanewcustomerComponent, 
    IndcustomerComponent, ModifyloanformComponent, OkratransactionsviewComponent, CardtokennizationComponent, MonotransactionsviewComponent, IppischeckerComponent, 
    BankstatementsettingsComponent, NibbsdebitComponent, PushingtomarketComponent, FraudtestComponent, UpfrontpaymentComponent, AccountbalanceComponent, FcnComponent, CyberpaypaymentsComponent, 
    PaystackpaymentsComponent, KyccheckerComponent, DuplicatecheckerComponent, PasswordStrengthBarComponent, ExcesspaymentComponent, PossibledoubledebitsComponent, 
    MandatestatusComponent, LoanschedulemodalComponent, WemapaymentsComponent, RequestssummaryComponent, CommentComponent, RsummaryComponent, StatementlinkComponent
  ],
  exports: [
    LedgerreportsComponent,PossibledoubledebitsComponent,StatementlinkComponent,ExcesspaymentComponent,PlatformearningsComponent,SquareBoxComponent,RequestSideviewMorePicturesComponent, AccountbalanceComponent,PasswordStrengthBarComponent,
    RequestloanproductComponent,YeargeneratorPipe, IndebtednessComponent, EligibilityanalysismodalComponent, IndebtednessletterComponent, ApprovinginvestmentComponent, CustomerotherrequestssComponent,AssignaccountofficerComponent,
    RequsetSideviewComponent, HtmlpipePipe, CardiframeComponent, NewbookloanformComponent, ApplicationsoffersComponent, DueloansbydateComponent, RequestlastactivityComponent, KyccheckerComponent,
    InsurancePipe, ModifyloanformComponent, OkratransactionsviewComponent,UpfrontpaymentComponent, FcnComponent, CyberpaypaymentsComponent, PaystackpaymentsComponent,
    CustomerCreateComponent, MonotransactionsviewComponent,PushingtomarketComponent, DuplicatecheckerComponent, MandatestatusComponent,
    CustomerAccountSettingsComponent, EmailtagsComponent, ProductchecklistComponent, GenderPipe, ApplicationschecklistComponent, LinkgeneratorComponent, BorrowedbasedamountComponent,
    WidgetPagesSettingsComponent, CollateralComponent, AnalyticscontractheaderComponent, UpdateemployeeComponent, AssigncollectionagentComponent, IndcustomerComponent,
    AlljournalsComponent, EmailtemplatedesignComponent, SmssenderComponent, PerformancesnapshotComponent, OfferlettertemplateComponent, LenderaccountstatementComponent,
    BankstatementuploadmodalComponent, UpdatingapprovallevelComponent,SmsTransactionsAnalysisComponent, SmsViewTransactionsModalComponent, RemitainfodetailsComponent,
    RequestingdataComponent, InitiatesmstransactionanalysisComponent, MicrocreditsummaryComponent, MicrocreditresultComponent, NinbyphonenumberComponent, IppisdetailsComponent,
    RequeststatusPipe, ColorPickerModule, DemoNumberPipe, CaryearPipe, LoancontractsectionComponent, InvestmentloancontractComponent, CustomerwalletsglComponent,
    InvestmentdashboardComponent, BulkdisbursementdownloadComponent, EmailsenderComponent, InviteguarantorsComponent, InvestmentnavigationComponent, BankstatementsettingsComponent,
    InvestmentdetailsComponent, UnderconstructionComponent, ApplicationportfolioheaderComponent, LoandurationPipe, SmsLatenessNotificationComponent,CreateanewcustomerComponent,
    RequestdurationPipe, LimittoPipe, RequestssummaryComponent, SafePipe, RoundPipe, GlcategoryPipe, TemplatedesignComponent, InvestmentsportfolioheaderComponent, IppischeckerComponent, CustomerinfoonlyComponent,
    PortfolioageinganalysisComponent, LoanStatusPipe, OfferstatusPipe, LoanStatusTextPipe, TimelineComponent, InvitemultiplecustomersComponent, CardtokennizationComponent,
    PeertopeerPipe, WebUssdComponent, SafehtmlPipe, MaritalstatusPipe, SearchanalysissettingPipe, BreakingloanComponent, PlaceholderComponent, SmsDueReminderComponent,
    FilterpipePipe, ImagenotfoundPipe, PayscheduleComponent, DateinvalidPipe, LoanworkflowComponent, CreditingstatementComponent, CalendarcashflowComponent,FraudtestComponent,
    JournalapprovedPipe, RouterModule, IonRangeSliderModule, ChartsModule, BackendsettingsComponent, LevelformComponent, MobileComponent, ApproveloanComponent,
    MoneyPipe, MyDatePickerModule, LoananalysisComponent, LoanapprovalsComponent, PreviewrepaymentscheduleComponent, MarketplaceofferComponent,
    RequestformComponent, SavingsNavigationComponent, NoaPipe, ProductSettingComponent, ApprovingprepaymentComponent, RequestitemComponent,
    MomentModule, FormsModule, ReactiveFormsModule, MainNavigationComponent, ViewguarantorComponent, FundyourwalletComponent, InterestdurationPipe,
    LoanformComponent, LoanfeesComponent, LoanchargesComponent, EmpsearchComponent, LoanchatComponent, MakeloanpaymentComponent,
    PaymentComponent, NgxEditorModule, SmslogsComponent, ProductapprovalsComponent, PostingbreakloansComponent, CustomerreportedpaymentsComponent,
    UiSwitchModule, CountdownModule, LoanguarantorsComponent, LoanlocationComponent, LoanchequeComponent, LoanphotosComponent, PostingbulkpaymentscalendarComponent,
    LoandocumentComponent, LoanacctsComponent, LoancollateralComponent, LoanlogsComponent, NewempformComponent, EligibilitycriteriaComponent,
    CalendarComponent, UnauthorizedComponent, CardcollectionComponent, ClearingfinesComponent, ProductbasicComponent,
    ApprovalviewComponent, NewbankformComponent, ChangepaymentComponent, CreditbureausComponent, AgeingComponent, HomecustomersideviewComponent,
    NguiDatetimePickerModule, CustomerwithdrawalComponent, CardcollectiontimesComponent, ProfileAnalysisComponent,
    NewloanformComponent,PartnerloanformComponent, NewaccountComponent, GeneralComponent, LoanpurposePipe, InvestmentcontractComponent, InvestmentscheduleComponent, InvestmentstatementComponent,
    CalendarviewComponent, LoanchecklistComponent, ScoredbasedinterestrateComponent, LoanmoreComponent,
    CalendardetailsComponent, RatingComponent, BorroweridComponent, NewlevelformComponent,CustomerTimelineComponent,
    HomenavigationComponent,
    BreakloanComponent, LoansummaryComponent, PaymentrollbackComponent, EmailHeaderContentComponent,
    MakepaymentComponent, CustomersummaryComponent, ScheduleComponent, RepayComponent, LoanprofileComponent,
    CustomerFinanceComponent, CustomerInvestmentsComponent, CustomerLoansComponent,
    LoancontractComponent, LoandocumentComponent, WalletdetailsComponent, EmailSettingComponent,
    ChangeproductComponent, LoanlogsComponent, LoancontractComponent, UserlocationComponent,
    LoancontractformComponent, LoanlendersComponent, OrderModule, ContractheaderComponent, InvestmentitemComponent,
    QueuecancelComponent, LoanpeertopeerComponent, KycdetailsComponent, IncludedcompaniesComponent,
    CustomeranalysisComponent, BulkpaymentComponent, CustomerUpdateComponent, SelectModule, LedgerComponent, GeneralformComponent,
    InterestformComponent, LoanamountComponent, InterestratefloorComponent, OriginationfeeComponent,
    CustomerRemitaComponent, LoanrepaymentComponent, BankslistComponent,
    AdjustweightsComponent, DirectdebitComponent, CreditcheckComponent, PopupcalendarComponent, OtherheaderComponent,
    DirectdebitinitiateComponent, DirectdebitstatusComponent, DirectdebitcancelComponent, ProductsettingsComponent,
    StopmandateComponent, CreditcheckdetailsComponent, ContractofferComponent, AllcustomersComponent, CompaniesComponent,
    NotificationsComponent, ChangeloanofficerComponent, StopremitaComponent, DueReminderComponent,
    NotifyremitaComponent, ProductsComponent, PostingbulkpaymentsComponent,
    RefreshremitaComponent, SavingsgeneralsettingsComponent, SavingsinterestsettingsComponent, SavingsprematuritybreakingComponent,
    SavingswithdrawalssettingsComponent, SavingsapplicationrequirementsComponent, SavingsapprovalsComponent, InfiniteScrollModule,
    BuybackloanComponent, PostsavingsComponent, BreakinvestmentComponent, InvestmentwithdrawalComponent,
    NgxTypeaheadModule, ButtonComponent, NewcollateralComponent, PhonenumbersComponent, ItemComponent, PortfoliocalendarComponent,
    LoanstatementComponent, PortfolioComponent, StatementscheduleComponent, InputComponent, LoanactionComponent, SecurityComponent,
    WelcomeEmailComponent, PhoneOtpComponent, RestPinComponent,
    DirectDebitEmailComponent, ValidationWorkEmailComponent,
    LoanRejectionEmailComponent, PaymentReceivedComponent, LenderMakeOfferComponent,
    BorrowerAccpectedOfferLetterComponent, BorrowerReceivedOfferEmailComponent,
    WithdrawalEmailComponent, SentToMarketComponent, GuarantorRequestEmailComponent,
    CardRequestEmailComponent, BankAccountConfirmationEmailComponent, WalletfundingcardsComponent,
    RequestForAttachmentsComponent, BvnVerificationEmailComponent, WebsiteSettingsComponent, RepaymentReminderEmailComponent,
    LoanDisbursedEmailComponent, LoanportfolioactionComponent, RequestcalendarComponent, SchedulemodalComponent, GuarantorscoverComponent,
    GuarantorcontactsComponent, AddGuarantorComponent, WalletaccountComponent, WalletcardComponent, PortfoliotargetmodalComponent,
    CalendarpopoutmodalComponent,
    OldestapprovalComponent, OfferLetterComponent,
    PendingApprovalsComponent,
    TatComponent, UploadpaymentsComponent,
    ApplicationspendingComponent,
    PendingapprovalsComponent, NewinvestmentrequestformComponent,
    BvnmodalComponent,
    WemaPaymentsComponent,
    CarddebittrylogsmodalComponent,
    CardmodalComponent,
    CardnumbermodalComponent,
    CardrecollectionmodalComponent,
    ChequesetupmodalComponent,
    DefaultrepaymentmodalComponent,
    DirectdebitsettingsComponent,
    DisbursementmodalComponent,
    DocumentsmodalComponent,
    EnlargeimageComponent,
    FundlenderComponent,
    InflightdetailsComponent,
    LoandetailsComponent,
    MakepaymentmodalComponent,
    PaymentmodemodalComponent,
    RecollectionmodeComponent,
    RecollectionsettingspasswordmodalComponent,
    RejectlenderofferComponent,
    RemovemarketofferComponent,
    RepaymentfooterComponent,
    RunfullanalyticsmodalComponent,
    SendtomarketComponent,
    EatTableComponent,
    NewRecordComponent,
    UpdateModalComponent,
    SideitemComponent,
    SummaryRequestitemComponent,
    SummaryComponent,
    UserheaderComponent,
    ApplicationscontractComponent,
    ContractdetailsComponent,
    CustomerdetailsComponent,
    RepaymentscheduleComponent,
    CreaterequestmodalComponent,
    AccountmodalComponent,
    ApplicationstimelineComponent,
    CurrentapprovallevelComponent,
    RequestheaderComponent,
    RequestfooterComponent,
    CarddebitsetupComponent,
    AccountdebitComponent,
    HomeComponent,
    CommentComponent,
    AnalyticshomeComponent,
    AnalyticsheaderComponent,
    PeertopeerComponent,
    SelectedanalyticsComponent,
    RunselectedanalyticsmodalComponent,
    AnalyticssmssummaryComponent,
    AnalyticssmsanalysisComponent,
    AnalyticssmslogsComponent,
    AnalyticssmslogsdataComponent,
    AnalyticscallanalysisComponent,
    AnalyticscalllogsdataComponent,
    AnalyticscalllogsstatsComponent,
    AnalyticsphonesummaryComponent,
    AnalyticssocialdataComponent,
    AnalyticssocialstatsComponent,
    AnalyticsfacebooksummaryComponent,
    AnalyticslinkedinsummaryComponent,
    AnalyticstwittersummaryComponent,
    AnalyticsloanrepaymentComponent,
    AnalyticsloanrepaymentcomparismComponent,
    AnalyticsloanrepaymenthistoryComponent,
    AnalyticsloanrepaymentstatsComponent, SavingsactionComponent,
    AnalyticsloanperformancelogComponent,
    ProfileanalysisComponent,
    AnalyticstatsComponent,
    ProfileanalysisnodeComponent,
    AnalyticscomparismComponent,
    AnalyticsspiderserviceComponent,
    AnalyticsmenuComponent,
    CreditbureauComponent,
    CreditbureaudetailsComponent,
    AnalyticscreditbureauComponent,
    AnalyticscreditbureaudetailsComponent,
    ApplicationscollateralComponent,
    ApplicationsguarantorsComponent,
    ApplicationsguarantorComponent,
    ApplicationscreditcheckComponent,
    ApplicationsrequestsComponent,
    ApplicationsofferletterComponent,
    DisbursementComponent,
    TopupwalletComponent,
    SplitpaymentComponent,
    ProfilemenuComponent, LocationdetailsComponent,
    RemitainflightComponent, AdvancesearchComponent,
    ApplicationseligibilityComponent, ApplicationsrejectedComponent, CreditregistryComponent,
    ApplicationsapprovalsComponent, ApprovalcalendarComponent,
    RemitacardbankanalyticsComponent, InitiatebankstatementanalysisComponent, OthersComponent, ContractstatusComponent,
    CardRequestEmailComponent, BankAccountConfirmationEmailComponent, WalletfundingcardsComponent,
    RequestForAttachmentsComponent, BvnVerificationEmailComponent, WebsiteSettingsComponent, RepaymentReminderEmailComponent,
    LoanDisbursedEmailComponent, LoanportfolioactionComponent, RequestcalendarComponent, SchedulemodalComponent, GuarantorscoverComponent,
    GuarantorcontactsComponent, AddGuarantorComponent, WalletaccountComponent, WalletcardComponent, PortfoliotargetmodalComponent,
    RejectedrequestComponent,
    IneligiblerequestComponent, InvitingnewcustomerComponent, BroadcastComponent, GolivemodalComponent, BrodcastComponent,
    LenderTypePipe, EmailFooterContentComponent, CellComponent,
    NewjournalComponent, TicTacToeComponent, TransactionformComponent,
    TrialbalanceComponent,
    IncomestatementComponent,
    FinancialpositionComponent,
    GeneralledgerComponent,
    PlgroupingComponent, 
    BsgroupingComponent,
    JournalreportsComponent,
    ComingSoonComponent,
    AllinvitesComponent,
    TimerComponent,
    HelpPopoverComponent,
    UpdateModalComponent,
    NoRightComponent,
    PageLoadingComponent,
    NoAnalyticsDataComponent,
    QnaComponent,
    SmssenderComponent,
    CollateralPipe,
    CustomerInfoComponent,
    LoanactionsComponent,
    AccountofficerComponent,
    SavingsdisbursementComponent,
    RecommendationsComponent,
    SavingsinterestPipe,
    AgentsRepaymentModeComponent,
    BreakingActiveLoanComponent,
    CollectionsComponent,
    RequestNotificationComponent,
    ApplicationsPortfolioComponent,
    PortfoliotrendsComponent,
    PortfoliodistributionComponent,
    RepaymentsourcedistributionComponent,
    RepaymentsnapshotComponent,
    RequestHeroComponent,AtaglanceComponent,
    AoffPipe,CustomerScheduleComponent,CheckpartnerstatusComponent,
    DuerepaymentsComponent, PortfoliorequestComponent,
    PortfoliotargetComponent, BorrowerdetailsComponent, RsummaryComponent,
    RepaymentflowComponent, ChooseoptionComponent, ConfirmpaymentComponent, CardpaymentComponent,
    RemitaComponent, PortfolioheaderComponent, FlowsummaryComponent, MakingpaymentComponent, PrepaymentComponent, PortfolioSideviewComponent, ApplicationsindebtednessComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [AgmCoreModule]
    };
  }
}
