import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OperationsService } from 'src/app/_services';
import { CustomerService, StorageService } from '../../_services/index';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.css']
})
export class RecommendationsComponent implements OnInit {
  @Input() requestId;
  @Input() embeded = false;
  loan: any;
  request_id: any;
  addModalVisible = false;
  token: any;
  loading = false;
  adding = false;
  deleting = '';
  recommendations = [];
  loggedInUser = '0';
  loan_durations = [
    { 'LOAN_INTEREST_DURATION_ID': '1', 'LOAN_DURATION': 'days', 'INTEREST_DURATION': 'day', 'ADJECTIVAL': 'Daily', 'ABBREV': 'd' },
    { 'LOAN_INTEREST_DURATION_ID': '2', 'LOAN_DURATION': 'months', 'INTEREST_DURATION': 'month', 'ADJECTIVAL': 'Monthly', 'ABBREV': 'Mo' },
    { 'LOAN_INTEREST_DURATION_ID': '3', 'LOAN_DURATION': 'years', 'INTEREST_DURATION': 'year', 'ADJECTIVAL': 'Yearly', 'ABBREV': 'Yr' },
    { 'LOAN_INTEREST_DURATION_ID': '4', 'LOAN_DURATION': 'weeks', 'INTEREST_DURATION': 'week', 'ADJECTIVAL': 'Weekly', 'ABBREV': 'Wk' }
  ];

  constructor(
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    private operationsService: OperationsService,
    public storageService: StorageService,
  ) {
  }
  
  ngOnInit() {
    const { token } = this.storageService.read<any>('currentUser');
    this.token = token;
    this.route.params.subscribe(params => {
      console.log('Here', +params['id'], this.requestId);
      this.request_id = +params['id'] || this.requestId;
      this.load();
    });
    this.loggedInUser = this.storageService.read<any>('user_id');
  }

  load() {
    this.loading = true;
    this.operationsService.getRecommendations({ token: this.token, request_id: this.request_id }).subscribe((res) => {
      this.recommendations = res.data;
      this.loading = false;
    });
  }

  showAddModal = () => {
    this.addModalVisible = true;
  }

  closeAddModal = () => {
    this.addModalVisible = false;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.adding = true;
    const fields = Array.from(e.target).reduce((obj, el: HTMLInputElement) => {
      if (el.name) obj[el.name] = el.value;
      return obj;
    }, {});
    this.operationsService.saveRecommendations({ token: this.token, recommendation: { ...fields, request_id: this.request_id } }).subscribe((res) => {
      if (res.status) this.toastr.success(res.message, 'Success!');
      else this.toastr.error('An unknown error occurred, please try again!', 'Error');
      this.adding = false;
      this.load();
    });
  }

  handleDelete(recommendation_id) {
    this.deleting = recommendation_id;
    this.operationsService.deleteRecommendation({ token: this.token, request_id: this.request_id, recommendation_id }).subscribe((res) => {
      if (res.status) this.toastr.success(res.message || 'Recommendation deleted!', 'Deleted');
      else this.toastr.error('An unknown error occurred, please try again!', 'Error');
      this.deleting = '';
      this.recommendations = [...this.recommendations].filter(r => r.recommendation_id !== recommendation_id)
    });
  }
}
