import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'

import { Loan } from '../_models/index';
import { Loan_ } from '../_models/index';
import { AuthenticationService } from './index';

@Injectable()
export class LoansService {
  saveProductChecklists(token: any, checklist: any, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/save_product_checklist', JSON.stringify({ token: token, request_id: request_id, checklist: checklist }))
      .map((response: Response) => response.json());
  }
  saveIPPISInfoForCustomer(token, request_id, people_id, ippis_number, category): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveIPPISInfoForCustomer', JSON.stringify({ token: token, request_id: request_id, people_id: people_id, ippis_number: ippis_number, category: category }))
      .map((response: Response) => response.json());
  }
  getCustomerKYcs(token: any, customer_id: string) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getCustomerKycs', JSON.stringify({ token: token, customer_id: customer_id }))
      .map((response: Response) => response.json());
  }
  private postsUrl = 'https://creditclanapis.creditclan.com/api/v2/';
  private analyticsUrl = 'https://loanstest.creditclan.com/';
  constructor(
    public http: Http,
  ) { }
  viewStatement(token: any, attachment_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/viewStatement', JSON.stringify({ token: token, attachment_id: attachment_id }))
      .map((response: Response) => response.json());
  }
  getMandateDates(token: any, parentRouteId: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getMandateDates', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getMoreRecords(token: any, url: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getMoreRecords', JSON.stringify({ token: token, url: url }))
      .map((response: Response) => response.json());
  }
  getMoreRecordsOkra(token: any, url: any, id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getMoreRecordsOkra', JSON.stringify({ token: token, url: url, id: id }))
      .map((response: Response) => response.json());
  }
  runStatementAnalysis(token: any, attachment_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/runStatementAnalysis', JSON.stringify({ token: token, attachment_id: attachment_id }))
      .map((response: Response) => response.json());
  }
  checkAccountBalance(token: any, attachment_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/checkAccountBalance', JSON.stringify({ token: token, attachment_id: attachment_id }))
      .map((response: Response) => response.json());
  }

  saveBandForCustomer(token: any, people_id: any, band: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/saveBandForCustomer', JSON.stringify({ token: token, people_id: people_id, band: band }))
      .map((response: Response) => response.json());
  }
  getAllBankStatementAnalysis(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getbankstatementanalysis', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getAllSMSTransactionAnalysis(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/smsbankstatementanalysis', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getNINByPhoneNumber(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/getNINByPhoneNumber', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  useSign(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/useSign', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getUpfrontStatus(token: any, request_id: any) {
    return this.http.post('https://wema.creditclan.com/api/v3/wema/getUpfrontStatus', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getFraudResult(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/run_fraud_check', JSON.stringify({ token: token, id: request_id }))
      .map((response: Response) => response.json());
  }
  removeNINByPhoneNumber(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/removeNINByPhoneNumber', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getPayslipDetails(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getPayslipDetails', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getALlMicrocreditcheck(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getLoanMicroCreditCheck', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendRequestToCustomerForStatementAnalysis(token: any, request_id: any, statement_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendRequestToCustomerForStatementAnalysis', JSON.stringify({ request_id: request_id, token: token, statement_request: statement_request }))
      .map((response: Response) => response.json());
  }
  sendInitiateMBSTicket(token: any, request_id: any, statement_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendInitiateMBSTicket', JSON.stringify({ request_id: request_id, token: token, statement_request: statement_request }))
      .map((response: Response) => response.json());
  }
  submitTicket(token, transaction_id, password, parentRouteId, ticket_id, statement_request) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/submitTicket', JSON.stringify({ request_id: parentRouteId, token: token, password: password, transaction_id: transaction_id, ticket_id: ticket_id, statement_request: statement_request }))
      .map((response: Response) => response.json());
  }
  generateLinkForCustomerForCardTokennization(token: any, request_id: any, statement_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/generateLinkForCustomerForCardTokennization', JSON.stringify({ request_id: request_id, token: token, statement_request: statement_request }))
      .map((response: Response) => response.json());
  }
  sendRequestToCustomerForCardTokennizationNibbs(token: any, request_id: any, statement_request: any, password: any, start_date: any, end_date: any, amount: any, first_payment_date: any, frequency: any,source:any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendRequestToCustomerForCardTokennizationNibbs', JSON.stringify({ source:source,start_date: start_date, end_date: end_date, amount: amount, request_id: request_id, token: token, statement_request: statement_request, password: password, first_payment_date: first_payment_date, frequency: frequency }))
      .map((response: Response) => response.json());
  }
  sendRequestToCustomerForCardTokennizationNibbsFile(token: string, request_id: any, account: any, password: any, start_date: any, end_date: any, amount: any, first_payment_date: any, frequency:any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendRequestToCustomerForCardTokennizationNibbsFile', JSON.stringify({ start_date: start_date, end_date: end_date, amount: amount, token: token, request_id: request_id, password: password, account: account, first_payment_date: first_payment_date, frequency: frequency }))
      .map((response: Response) => response.json());
  }
  sendRequestToCustomerForCardTokennizationNibbsFileNew(token: string, request_id: any, account: any, password: any, start_date: any, end_date: any, amount: any, first_payment_date: any, frequency:any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendRequestToCustomerForCardTokennizationNibbsFileNew', JSON.stringify({ start_date: start_date, end_date: end_date, amount: amount, token: token, request_id: request_id, password: password, account: account, first_payment_date: first_payment_date, frequency: frequency }))
      .map((response: Response) => response.json());
  }
  sendRequestForDirectDebitOnAccountNibbsAttachment(token: string, request_id: any, account: any, password: any, attachment: any, start_date: any, end_date: any, amount: any, first_payment_date: any, frequency:any, source:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/setupDirectDebitWithAttachment', JSON.stringify({ start_date: start_date, end_date: end_date, amount: amount, attachment: attachment, token: token, request_id: request_id, password: password, account: account, first_payment_date: first_payment_date, frequency: frequency, source:source }))
      .map((response: Response) => response.json());
  }
  sendRequestToCustomerForCardTokennization(token: any, request_id: any, statement_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendRequestToCustomerForCardTokennization', JSON.stringify({ request_id: request_id, token: token, statement_request: statement_request }))
      .map((response: Response) => response.json());
  }
  getTheCustomerSMSTransactions(token: any, request_id: any, phone_type: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/getSMSTransactionSMSes', JSON.stringify({ token: token, request_id: request_id, phone_type: phone_type }))
      .map((response: Response) => response.json());
  }
  runMicroCreditReport(token: any, request_id: any, phone_type: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/runMicroCreditCheck', JSON.stringify({ token: token, request_id: request_id, phone_type: phone_type }))
      .map((response: Response) => response.json());
  }
  getStatementLink(token: string, phone: any): Observable<any> {
    // get users from api
    return this.http.get('https://wasapnodeserver.herokuapp.com/getPdf/'+phone)
      .map((response: Response) => response.json());
  }
  getMerchantStatus(batch_id: any, partner_request_id:any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cdl/getMerchantStatus',
      { batch_id: batch_id,partner_request_id:partner_request_id })
      .map((response: Response) => response.json());
  }
  getNibbsInstantMandateDebitBalance(rec: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getNibbsInstantMandateDebitBalance',
      { rec: rec })
      .map((response: Response) => response.json());
  }
  getLoanCheckList(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/loanchecklist', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  runNINByPhoneNumber(token: any, request_id: any, phone_type: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/runNINByPhoneNumber', JSON.stringify({ token: token, request_id: request_id, phone_type: phone_type }))
      .map((response: Response) => response.json());
  }
  runNINByNINNumber(token: any, request_id: any, nin: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credequity/runNINByNINNumber', JSON.stringify({ token: token, request_id: request_id, nin: nin }))
      .map((response: Response) => response.json());
  }
  getEligibilityStatus(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/eligibilitystatus', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  rerunEligibility(token: any, request_id: any, leaves: any, is_default: any, criteria: any) {
    return this.http.post(this.analyticsUrl + 'loan/rerunEligibility', JSON.stringify({ token: token, request_id: request_id, leaves: leaves, is_default: is_default, criteria: criteria }))
      .map((response: Response) => response.json());
  }
  analyseCustomerForEligibility(token: any, request_id: any, leaves: any, is_default: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/analyseCustomerForEligibility', JSON.stringify({ token: token, request_id: request_id, leaves: leaves, is_default: is_default }))
      .map((response: Response) => response.json());
  }
  getLoanCustomer(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getLoanCustomer', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }

  getProfileAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getprofileanalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getSpiderData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getspiderdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getRepaymentAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getrepaymentanalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getRemitaCardBankAnalysis(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getsmsanalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getSMSAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getsmsanalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getCalllogsAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getcalllogsanalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getSocialAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getSocialAnalyticsRecords', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getGuarantorAnalyticsData(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getguarantoranalyticsdata', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getCardAnalyticsRecords(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getCardAnalyticsRecords', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getBVNAnalyticsRecords(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getBVNAnalyticsRecords', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getAccountAnalyticsRecords(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getAccountAnalyticsRecords', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getDirectDebitAnalyticsRecords(token, parentRouteId) {
    return this.http.post(this.analyticsUrl + 'analytics/getDirectDebitAnalyticsRecords', JSON.stringify({ token: token, request_id: parentRouteId }))
      .map((response: Response) => response.json());
  }
  getAnalyticsNodesAndScores(token: any, selectedanalyticsgroup: any, loan: any) {
    return this.http.post(this.analyticsUrl + 'analytics/nodesandscores', JSON.stringify({ token: token, selectedanalyticsgroup: selectedanalyticsgroup, loan: loan }))
      .map((response: Response) => response.json());
  }
  saveSMSAnalysisData(token, request_id, nodes_and_scores, data) {
    return this.http.post(this.analyticsUrl + 'analytics/save_sms_analytics', JSON.stringify({ token: token, request_id: request_id, nodes_and_scores: nodes_and_scores, data: data }))
      .map((response: Response) => response.json());
  }
  saveProfileAnalysisData(token, request_id, nodes_and_scores, data) {
    return this.http.post(this.analyticsUrl + 'analytics/save_sms_analytics', JSON.stringify({ token: token, request_id: request_id, nodes_and_scores: nodes_and_scores, data: data }))
      .map((response: Response) => response.json());
  }
  runTheProfileAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.analyticsUrl + 'analytics/profile_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  runTheSMSAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.analyticsUrl + 'analytics/sms_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  runTheSocialAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.analyticsUrl + 'analytics/social_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  runTheStatementAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.analyticsUrl + 'analytics/statement_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  runTheCallLogsAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.analyticsUrl + 'analytics/calllogs_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  runTheRepaymentAnalytics(token: any, selectedanalytics: any, loan: any, nodesandscores: any) {
    return this.http.post(this.postsUrl + 'analytics/repayment_analytics', JSON.stringify({ token: token, nodesandscores: nodesandscores, loan: loan, selectedanalytics: selectedanalytics }))
      .map((response: Response) => response.json());
  }
  getLoanContract(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getloanContract', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  loan_contract(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/loan_contract', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanPortfolioDetails(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/loanportfoliodetails', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanDetails(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/loandetails', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  queryPayslipDetails(token: any, ippis_number: any, category: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/payslip/getPayslipDetails', JSON.stringify({ category: category, token: token, ippis_number: ippis_number }))
      .map((response: Response) => response.json());
  }

  retrieveLoanDetails(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/request_details', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanContractSimple(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/loan', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getInvestmentContractSimple(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'savings/savings', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanContractSimplest(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getloanContractSimplest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanContractForAnalysis(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getloanForAnalysis', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanForCustomer(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getLoanForCustomer', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  doCustomerAddCard(token: any, request_id: any, datas: any) {
    return this.http.post(this.analyticsUrl + 'loan/doCustomerAddCard', JSON.stringify({ token: token, request_id: request_id, data: datas }))
      .map((response: Response) => response.json());
  }
  getLoanForCustomerSlim(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getLoanForCustomerSlim', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanCustomerDetail(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getLoanForCustomer', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanOffers(token: any, request_id: any) {
    return this.http.post(this.postsUrl + 'loan/getLoanOffers', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanForTimeline(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getLoanForTimeline', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getInvestmentForTimeline(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'savings/getInvestmentForTimeline', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanPortfolio(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getloanportfolioSlim', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getInvestmentPortfolio(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'savings/getinvestmentportfolioSlim', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }

  getLoanPortfolioSub(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/getloanportfoliosub', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanContractCustomer(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'customer/getcustomer', JSON.stringify({ token: token, people_id: request_id }))
      .map((response: Response) => response.json());
  }
  getSavingsContractFromCustomer(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'customer/getcustomerfromsaving', JSON.stringify({ token: token, people_id: request_id }))
      .map((response: Response) => response.json());
  }

  getLoanContractCustomerFromLoan(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'customer/getcustomerfromloan', JSON.stringify({ token: token, people_id: request_id }))
      .map((response: Response) => response.json());
  }
  getCustomerFromHome(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'customer/getcustomerfromhome', JSON.stringify({ token: token, people_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanWorkflowLevel(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/workflowlevel', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getInvestmentWorkflowLevel(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'savings/workflowlevel', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanRemitaCustomer(token: any, people_id: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'customer/getremitacustomer', JSON.stringify({ token: token, people_id: people_id, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanSchedules(token: any, request_id: any) {
    return this.http.post(this.analyticsUrl + 'loan/schedule', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  displayanalysis(token: any, statement: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/getCustomerAnalytics', JSON.stringify({ token: token, formdata: statement }))
      .map((response: Response) => response.json());
  }
  getCustomerAnalyticsRecords(token: any, statement: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/getCustomerAnalyticsRecords', JSON.stringify({ token: token, formdata: statement }))
      .map((response: Response) => response.json());
  }

  displayanalysisrequest(token: any, data: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/getCustomerAnalyticsRequest', JSON.stringify({ token: token, formdata: data }))
      .map((response: Response) => response.json());
  }
  getLoanCards(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanCards', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getRequestTimeline(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getRequestTimeline', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getInvestmentRequestTimeline(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/investment/getInvestmentRequestTimeline', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getCustomerTimeline(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/getTimeline', JSON.stringify({ token: token, customer_id: id }))
      .map((response: Response) => response.json());
  }
  queueP(token: string, id: number, partner:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueP', JSON.stringify({ token: token, customer_id: id, partner: partner}))
      .map((response: Response) => response.json());
  }
  unqueueP(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/unqueueP', JSON.stringify({ token: token, customer_id: id }))
      .map((response: Response) => response.json());
  }
  saveNotes(token: string, id: number, note: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/timeline/save_note', JSON.stringify({ token: token, id: id, note: note }))
      .map((response: Response) => response.json());
  }
  analysisAmount(token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/getAnalyticsAmount', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getBanksListForCustomer(token: any, loan_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getBanksListForCustomer', JSON.stringify({ token: token, request: loan_request }))
      .map((response: Response) => response.json());
  }
  saveSocialRequest(token: any, value: any, loan_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/saveSocialRequest', JSON.stringify({ token: token, data: value, request_id: loan_request }))
      .map((response: Response) => response.json());
  }
  saveAnlysisRequest(token: any, value: any, loan_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/saveAnalyticsRequest', JSON.stringify({ token: token, data: value, request: loan_request }))
      .map((response: Response) => response.json());
  }

  getSocialAnalysis(people_id: any) {
    return this.http.get('https://social.creditclan.com/rest/customer?id=' + people_id)
      .map((response: Response) => response.json());
  }

  saveFqscore(token: any, value: any, loan_request: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/saveFqScore', JSON.stringify({ token: token, fqscore: value, request: loan_request }))
      .map((response: Response) => response.json());
  }
  saveAllCountries(callingCodes: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/saveAllCountries', JSON.stringify({ callingCodes: callingCodes }))
      .map((response: Response) => response.json());
  }
  queueStraightForDisbursement(token: any, request: any, selectedCustomer: any, loan_product: any, request_bank: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/queueStraightForDisbursement', JSON.stringify({ request_bank: request_bank, loan_product: loan_product, token: token, request: request, selectedCustomer: selectedCustomer }))
      .map((response: Response) => response.json());
  }
  initializeNewLoan(token: any, company_id: any, staff_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/initializeNewLoan', JSON.stringify({ token: token, company_id: company_id, staff_id: staff_id }))
      .map((response: Response) => response.json());
  }
  exportDisburseList(token: any, bulkpayrequests: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/exportDisburseList', JSON.stringify({ token: token, bulkpayrequests: bulkpayrequests }))
      .map((response: Response) => response.json());
  }
  confirmPayment(token: any, payment: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/confirm_payment', JSON.stringify({ token: token, formdata: payment }))
      .map((response: Response) => response.json());
  }
  makepayment(token: any, payment: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/bulk_pay', JSON.stringify({ token: token, formdata: payment }))
      .map((response: Response) => response.json());
  }
  makepaymentoperations(token: any, payment: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/bulk_pay', JSON.stringify({ token: token, formdata: payment }))
      .map((response: Response) => response.json());
  }
  makepaymentoperationsreviewed(token: any, payment: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/bulk_pay_reviewed', JSON.stringify({ token: token, formdata: payment }))
      .map((response: Response) => response.json());
  }
  completemakepaymentoperationsreviewed(token: any, payment: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/complete_bulk_pay_reviewed', JSON.stringify({ token: token, formdata: payment }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  completeLoanContract(token: any, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/completePreviewLoan', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  rollbackpayment(token: any, payment: any, transaction: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/rollback', JSON.stringify({ token: token, formdata: payment, transaction: transaction }))
      .map((response: Response) => response.json());
  }
  // previewLoan(token: any, loan_request: any, selectedCustomer: any, loan_product: any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/previewLoan', JSON.stringify({ token: token, loan_request: loan_request, selectedCustomer: selectedCustomer, loan_product: loan_product }))
  //     .map((response: Response) => response.json());
  // }  
  previewLoanAndCreateSchedule(token: any, loan_request: any, selectedCustomer: any, loan_product: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/previewLoanAndCreateSchedule', JSON.stringify({ token: token, loan_request: loan_request, selectedCustomer: selectedCustomer, loan_product: loan_product }))
      .map((response: Response) => response.json());
  }
  previewInvestmentAndCreateSchedule(token: any, loan_request: any, selectedCustomer: any, loan_product: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/investment/previewLoanAndCreateSchedule', JSON.stringify({ token: token, loan_request: loan_request, selectedCustomer: selectedCustomer, loan_product: loan_product }))
      .map((response: Response) => response.json());
  }
  previewLoan(token: any, loan_request: any, selectedCustomer: any, loan_product: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/previewLoan', JSON.stringify({ token: token, loan_request: loan_request, selectedCustomer: selectedCustomer, loan_product: loan_product }))
      .map((response: Response) => response.json());
  }
  startRequest(token: any, loan_request: any, selectedCustomer: any, loan_product: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/startRequest', JSON.stringify({ token: token, loan_request: loan_request, selectedCustomer: selectedCustomer, loan_product: loan_product }))
      .map((response: Response) => response.json());
  }
  createLoan(token: any, loan_request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/create_loan', JSON.stringify({ token: token, loan_request: loan_request, analytics: true }))
      .map((response: Response) => response.json());
  }
  make_new_market_offer(token: any, data: any, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/make_new_market_offer', JSON.stringify({ token: token, offer: data, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  filterLoans(ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/filterLoans', JSON.stringify({ marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  getLenderPortfolio(ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any, percentrepaid: any, breaking: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLenderPortfolio', JSON.stringify({ marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date, percentrepaid: percentrepaid, breaking: breaking }))
      .map((response: Response) => response.json());
  }
  getAllLenderPortfolio(ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any, percentrepaid: any, breaking: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getAllLenderPortfolio', JSON.stringify({ marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date, percentrepaid: percentrepaid, breaking: breaking }))
      .map((response: Response) => response.json());
  }
  getBranchPortfolio(ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any, percentrepaid: any, breaking: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLenderPortfolio', JSON.stringify({ marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date, percentrepaid: percentrepaid, breaking: breaking, branch_id }))
      .map((response: Response) => response.json());
  }
  filtering_loans(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/filterLoans', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filtering_customer_savings(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/getSavings', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  getLenderSavingsPortfolio(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/getSavingsPortfolio', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  filtering_loans_new(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLoans', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filtering_loans_new_filter(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getAllLoans', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filtering_loans_new_new(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLoanRequests', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filtering_loans_new_new_new(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getAllPendingLoanRequests', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filtering_Branchloans_new(incomes: any, designations: any, occupations: any, ages: any, token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any, adays: any, marital_statuses: any, branch_id): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLoans', JSON.stringify({ incomes: incomes, designations: designations, occupations: occupations, marital_statuses: marital_statuses, adays: adays, ages: ages, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date, branch_id }))
      .map((response: Response) => response.json());
  }
  getMyApprovals(token: any, magic_filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getMyLatestApprovals', JSON.stringify({ token: token, magic_filter: magic_filter }))
      .map((response: Response) => response.json());
  }
  getBranchApprovals(token: any, magic_filter: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getMyLatestApprovals', JSON.stringify({ token: token, magic_filter: magic_filter }))
      .map((response: Response) => response.json());
  }
  runFilterLoans(token: any, magic_filter: any, sectors: any, approval_levels: any, statuses: any, durations: any, request_date: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/filterLoans', JSON.stringify({ analytics: true, token: token, magic_filter: magic_filter, sectors: sectors, approval_levels: approval_levels, statuses: statuses, durations: durations, request_date: request_date }))
      .map((response: Response) => response.json());
  }
  filterActiveLoans(token: any, magic_filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/filterLoans', JSON.stringify({ token: token, magic_filter: magic_filter }))
      .map((response: Response) => response.json());
  }
  modify_repay_date(loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/modify_repay_date', JSON.stringify(loan))
      .map((response: Response) => response.json());
  }
  modify_repay_dated(loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/modify_repay_dated', JSON.stringify(loan))
      .map((response: Response) => response.json());
  }
  getLoans(filters: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoans', JSON.stringify(filters))
      .map((response: Response) => response.json());
  }
  getActiveLoans(filters: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getActiveLoans', JSON.stringify(filters))
      .map((response: Response) => response.json());
  }
  getLoansSummary(filters: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoansSummary', JSON.stringify(filters))
      .map((response: Response) => response.json());
  }
  sendToLoanMarket(token: string, id: number, notify_all_lenders: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendToLoanMarket', JSON.stringify({ token: token, request_id: id, notify_all_lenders: notify_all_lenders }))
      .map((response: Response) => response.json());
  }
  getInvestments(token: string, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getInvestments', JSON.stringify({ token: token, magic_filter: data }))
      .map((response: Response) => response.json());
  }
  getInvestmentsNew(token: string, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getInvestmentsNew', JSON.stringify({ token: token, magic_filter: data }))
      .map((response: Response) => response.json());
  }
  getRepaymentDetails(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanStatementForRepaymet', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanRepaymentData(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanRepaymentData', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getRepaymentData(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getRepaymentData', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getTheUploadedFile(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://dataupload.creditclan.com/api/v2/loan/getTheUploadedFile', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getRepaymentDetails_(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanDueSimple', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  updatePlatformOffer(token: string, offer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updatePlatformOffer', JSON.stringify({ token: token, offer: offer }))
      .map((response: Response) => response.json());
  }
  rejectThisRequest(token: string, reject: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/reject_loan_request', JSON.stringify({ token: token, reject: reject }))
      .map((response: Response) => response.json());
  }
  getLevelSummary(token: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLevelSummary', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  rejectTreasuryRequest(token: string, reject: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/rejectThisRequest', JSON.stringify({ token: token, reject: reject }))
      .map((response: Response) => response.json());
  }
  cancelContract(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/cancelContract', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  approveThisRequest(token: string, reject: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/approveThisRequest', JSON.stringify({ token: token, reject: reject }))
      .map((response: Response) => response.json());
  }
  approveThisTreasuryRequest(token: string, reject: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/approveThisRequest', JSON.stringify({ token: token, reject: reject }))
      .map((response: Response) => response.json());
  }
  getLoanSchools(token: any) {
    return this.http.get('https://sellbackend.creditclan.com/parent/index.php/external/onboardedloanschools')
      .map((response: Response) => response.json());
  }
  getLoanRestaurants(token: any) {
    return this.http.get('https://sellbackend.creditclan.com/parent/index.php/globalrequest/onboardedrestaurants')
      .map((response: Response) => response.json());
  }
  checkWalletTStatus(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/loan_disbursed', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  queueForDisbursement(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/queueForDisbursement', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  qdbnpl(token: string, id: any, loan:any, send_message:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/queueForDisbursement', JSON.stringify({ token: token, id: id, loan:loan,send_message:send_message }))
      .map((response: Response) => response.json());
  }
  checkqdbnpl(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cdl/checkrequestcorrectnesss', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  moveToPortfolio(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/moveToPortfolio', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  rerunEligibilitys(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/rerunEligibility', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getEligibilityPayload(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getEligibilityPayload', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  initiatestatement(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/createbs', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  initiatestatements(token: string, id: any, account_card_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/initiatestatements', JSON.stringify({ token: token, request_id: id, account_card_id: account_card_id }))
      .map((response: Response) => response.json());
  }
  checkbalance(token: string, id: any, account_card_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/checkbalance', JSON.stringify({ token: token, request_id: id, account_card_id: account_card_id }))
      .map((response: Response) => response.json());
  }
  queueForPartners(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueforpartners', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  removeFromLoanMarket(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/removeFromLoanMarket', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  getLoan(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  setupmandate(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/setupDirectDebit', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  getLoanCheques(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanCheques', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  saveOLSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveOL', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  sendContract(token: any, value: any, offer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendContract', JSON.stringify({ token: token, data: value, offer: offer }))
      .map((response: Response) => response.json());
  }
  sendIndebtednessLetter(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendInContract', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  getLoanOffer(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanOffer', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanRejections(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanRejections', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getAddresses(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/getAddresses', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  updateRequestLocation(token: any, id: number, address: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updateRequestLocation', JSON.stringify({ token: token, id: id, address: address }))
      .map((response: Response) => response.json());
  }
  getLoanCollateral(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanCollateral', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getInvestment(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getInvestment', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getInvestmentBorrower(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getInvestmentBorrower', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  breakLoan(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/breakLoan', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  breakingLoan(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/breakLoan', JSON.stringify({ token: token, request_id: id }))
      .map((response: Response) => response.json());
  }
  sendBreakLoan(token: string, id: number, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendBreakLoan', JSON.stringify({ token: token, request_id: id, break_loan: break_loan }))
      .map((response: Response) => response.json());
  }
  approveBreakLoan(token: string, id: number, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/inipproveBreakLoan', JSON.stringify({ token: token, request_id: id, break_loan: break_loan }))
      .map((response: Response) => response.json());
  }
  completeApproveBreakLoan(token: string, id: number, break_loan: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/finalizeBreakLoan', JSON.stringify({ token: token, request_id: id, break_loan: break_loan }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  adjustTheCurrentBalanceByInterestChange(token: string, id: number, breaking_date: any, close_action: any, interest_calculation: any, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/adjustTheCurrentBalanceByInterestChange', JSON.stringify({ break_loan: break_loan, interest_calculation: interest_calculation, token: token, request_id: id, BREAKING_DATE: breaking_date, CLOSE_ACTION: close_action }))
      .map((response: Response) => response.json());
  }
  generateBVNLink(token: any, request_id: any, link_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/generateBVNLink', JSON.stringify({ token: token, request_id: request_id, link_type: link_type }))
      .map((response: Response) => response.json());
  }
  generateBVNLinkForCustomer(token: any, people_id: any, link_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/generateBVNLinkForCustomer', JSON.stringify({ token: token, people_id: people_id, link_type: link_type }))
      .map((response: Response) => response.json());
  }
  generateGuarantorLink(token: any, request_id: any, guarantor_request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/generateLinkForGuarantors', JSON.stringify({ token: token, request_id: request_id, guarantor_request: guarantor_request }))
      .map((response: Response) => response.json());
  }
  submitBreakLoan(token: string, id: number, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/submitBreakLoan', JSON.stringify({ token: token, request_id: id, break_loan: break_loan }))
      .map((response: Response) => response.json());
  }
  paytheinterest(token: string, schedule: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/paytheinterest', JSON.stringify({ token: token, schedule: schedule }))
      .map((response: Response) => response.json());
  }
  adjustTheCurrentBalance(token: string, id: number, breaking_date: any, close_action: any, interest_calculation: any, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/adjustTheCurrentBalance', JSON.stringify({ break_loan: break_loan, interest_calculation: interest_calculation, token: token, request_id: id, BREAKING_DATE: breaking_date, CLOSE_ACTION: close_action }))
      .map((response: Response) => response.json());
  }
  adjustTheCurrentBalancePenalty(token: string, id: number, breaking_date: any, close_action: any, interest_calculation: any, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/adjustTheCurrentBalanceByPenalty', JSON.stringify({ interest_calculation: interest_calculation, token: token, request_id: id, BREAKING_DATE: breaking_date, CLOSE_ACTION: close_action, break_loan: break_loan }))
      .map((response: Response) => response.json());
  }
  adjustTheCurrentBalanceByInterest(token: string, id: number, breaking_date: any, close_action: any, interest_calculation: any, break_loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/adjustTheCurrentBalanceByInterest', JSON.stringify({ break_loan: break_loan, interest_calculation: interest_calculation, token: token, request_id: id, BREAKING_DATE: breaking_date, CLOSE_ACTION: close_action }))
      .map((response: Response) => response.json());
  }
  getStatementSchedule(token: string, id: number, statement_type: any, type_of_entry: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/schedule/getLoanRequestSchedule', JSON.stringify({ type_of_entry: type_of_entry, token: token, id: id, statement_type: statement_type }))
      .map((response: Response) => response.json());
  }
  checkBankStatementAnalysisStatus(token: any, statement: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/checkBankStatementAnalysisStatus', JSON.stringify({ statement: statement, token: token }))
      .map((response: Response) => response.json());
  }
  getCustomerStatementSchedule(token: string, id: number, statement_type: any, type_of_entry: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/getPortfolioInvestmentRepaymentsSchedule', JSON.stringify({ type_of_entry: type_of_entry, token: token, id: id, statement_type: statement_type }))
      .map((response: Response) => response.json());
  }
  getStatement(token: string, id: number, statement_type: any, type_of_entry: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanRequestStatement', JSON.stringify({ type_of_entry: type_of_entry, token: token, id: id, statement_type: statement_type }))
      .map((response: Response) => response.json());
  }
  getInvStatement(token: string, id: number, statement_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getInvStatement', JSON.stringify({ token: token, id: id, statement_type: statement_type }))
      .map((response: Response) => response.json());
  }
  getLoanForPreview(token: string, id: number, dontshownext: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan', JSON.stringify({ dontshownext: dontshownext, token: token, id: id, enext: 1 }))
      .map((response: Response) => response.json());
  }
  getLoanForPreview__(token: string, id: number, dontshownext: any, is_approval: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan', JSON.stringify({ is_approval: is_approval, dontshownext: dontshownext, token: token, id: id, enext: 1 }))
      .map((response: Response) => response.json());
  }
  getLoanForPreview_(token: string, id: number, dontshownext: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan', JSON.stringify({ dontshownext: dontshownext, token: token, id: id }))
      .map((response: Response) => response.json());
  }
  lookforlenders(token: string, lender_name: any, loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/searchForNewLenders', JSON.stringify({ loan: loan, token: token, searchText: lender_name }))
      .map((response: Response) => response.json());
  }
  makeNewOffer(token: string, platform_offer: any, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/makeNewOffer', JSON.stringify({ token: token, offer: platform_offer, id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanAnalysis(token: string, id: number, lender_name: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanAnalysis', JSON.stringify({ token: token, id: id, searchText: lender_name }))
      .map((response: Response) => response.json());
  }
  updateAnalysis(token: string, id: number, analysis: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updateAnalysis', JSON.stringify({ token: token, id: id, analysis: analysis }))
      .map((response: Response) => response.json());
  }
  saveLoanCheques(token: string, id: number, cheques: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveLoanCheques', JSON.stringify({ token: token, id: id, cheques: cheques }))
      .map((response: Response) => response.json());
  }
  saveNewRepayment(token: string, id: number, repayments: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveNewRepayment', JSON.stringify({ token: token, id: id, repayments: repayments }))
      .map((response: Response) => response.json());
  }
  saveCollaterals(token: string, id: number, cheques: any, loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveCollaterals', JSON.stringify({ token: token, id: id, cheques: cheques, loan: loan }))
      .map((response: Response) => response.json());
  }
  getLoanLenders(token: string, id: number, lender_name: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanLenders', JSON.stringify({ token: token, id: id, searchText: lender_name }))
      .map((response: Response) => response.json());
  }
  getLoanCardsAndAccounts(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanCardsAndAccounts', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getConnectedLoanCardsAndAccounts(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getConnectedLoanCardsAndAccounts', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getCustomerAccountAndCard(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCustomerCardsAndAccounts', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  // getLoanAttachments(token: string, id: number): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanAttachments', JSON.stringify({ token: token, id: id }))
  //     .map((response: Response) => response.json());
  // }
  getInvestmentSummary(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://savingsapi.creditclan.com/api/v2/customer/getInvestmentSummary', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  // getLoanAttachments_(token: string, id: number, customer_id: string, from: string): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanAttachments', JSON.stringify({ token: token, id: id, customer_id: customer_id, from: from }))
  //     .map((response: Response) => response.json());
  // }
  getLoanAttachments(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan_Attachments', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanAttachments_(token: string, id: number, customer_id: string, from: string): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoan_Attachments', JSON.stringify({ token: token, id: id, customer_id: customer_id, from: from }))
      .map((response: Response) => response.json());
  }
  restartRequestWorkflow(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/restartRequestWorkflow', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getUserLogs(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getUserLogs', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getUserSMSLogs(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getSMSLogs', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getCallLogs(token: string, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCallLogs', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanGuarantors(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanGuarantors', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanSchedule(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanSchedule', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanSchedulePartner(token: string, id: number, is_partner:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanSchedule', JSON.stringify({ token: token, id: id, is_partner:is_partner }))
      .map((response: Response) => response.json());
  }
  getLoanRepaymentsSchedule(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getLoanRepaymentsSchedule', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getInvestmentRepaymentsSchedule(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/getInvestmentRepaymentsSchedule', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanEIRSchedule(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanEIRSchedule', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  viewLoanGuarantor(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/viewLoanGuarantor', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanGuarantorProfile(token: string, id: number, category: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanGuarantorProfile', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanGuarantorDetails(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getLoanGuarantorDetails', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanApprovals(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanApprovals', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getApprovalQueue(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getApprovalQueue', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getRequestApprovalQueue(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getApprovalQueue', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getSavingsApprovalQueue(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/getSavingsApprovalQueue', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanApprovalQueue(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getApprovalQueue', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getASingleLoan(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/get_single_loan', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanComments(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanComments', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  reverseContract(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/reverseContract', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  acceptOffer(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/acceptOffer', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  rejectOffer(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/rejectOffer', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  sendGuarantorInvites(token: string, id: number, items: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendGuarantorInvites', JSON.stringify({ token: token, id: id, items: items }))
      .map((response: Response) => response.json());
  }
  getLoan_(token: string, id: string): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanN', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoan__(token: string, id: string): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/get_loan_contract_omni', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  uploadFile(file: any, description: any, parentRouteId: any) {

  }
  createNewCustomer(token: string, formdata: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/createNewCustomer', JSON.stringify({ token: token, formdata: formdata }))
      .map((response: Response) => response.json());
  }
  save_contract(token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<Loan_> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/save_contract', JSON.stringify({ SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  updateTheLoanContract(token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updateTheLoanContract', JSON.stringify({ SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  save_contract_new_partner(funding_partner:any,token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/save_contract_new_new_new_partner', JSON.stringify({ funding_partner:funding_partner, SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  check_partner_data(funding_partner:any,token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/check_partner_data', JSON.stringify({ funding_partner:funding_partner, SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  save_contract_new(token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/save_contract_new_new_new', JSON.stringify({ SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  save_contract_new_modify(token: string, formdata: any, allfeesqueue: any, allchargesqueue: any, fees: any, charges: any, paidfeesandcharges: any, loan_request: any, SEND_CONTRACT_DOCS: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/save_contract_new_new_new_modify', JSON.stringify({ SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan_request: loan_request, token: token, formdata: formdata, allfeesqueue: allfeesqueue, allchargesqueue: allchargesqueue, fees: fees, charges: charges, PAID_FEES_AND_CHARGES: paidfeesandcharges }))
      .map((response: Response) => response.json());
  }
  doInvest(token: string, formdata: any): Observable<Loan_> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/doInvest', JSON.stringify({ token: token, formdata: formdata }))
      .map((response: Response) => response.json());
  }
  save_contract_(token: string, loan: any, SEND_CONTRACT_DOCS: any): Observable<Loan_> {
    // get users from api
    return this.http.post('https://loanstest.creditclan.com/loan/save_contract', JSON.stringify({ SEND_CONTRACT_DOCS: SEND_CONTRACT_DOCS, loan: loan, token: token }))
      .map((response: Response) => response.json());
  }
  modifyloanschedule(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/modifyloanschedule', JSON.stringify({ request_id: request_id, token: token }))
      .map((response: Response) => response.json());
  }
  finishRollover(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/finishRollover', JSON.stringify({ request_id: request_id, token: token }))
      .map((response: Response) => response.json());
  }
  moveToRequests(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/moveToRequests', JSON.stringify({ request_id: request_id, token: token }))
      .map((response: Response) => response.json());
  }
  finishTopUp(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/finishTopup', JSON.stringify({ request_id: request_id, token: token }))
      .map((response: Response) => response.json());
  }
  getLoanFeesAndCharges(token: string, loanid: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getLoanFeesAndCharges', JSON.stringify({ token: token, id: loanid }))
      .map((response: Response) => response.json());
  }
  searchForLoans(token: string, searchText: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/searchForLoans', JSON.stringify({ token: token, searchText: searchText }))
      .map((response: Response) => response.json());
  }
  searchBranchLoans(token: string, searchText: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/searchForLoans', JSON.stringify({ token: token, searchText: searchText, branch_id }))
      .map((response: Response) => response.json());
  }
  searchForLoansNew(token: string, searchText: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/searchForLoans', JSON.stringify({ token: token, searchText: searchText }))
      .map((response: Response) => response.json());
  }
  searchForBranchLoansNew(token: string, searchText: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/searchForLoans', JSON.stringify({ token: token, searchText: searchText, branch_id }))
      .map((response: Response) => response.json());
  }
  getCustomerLoans(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCustomerLoans', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  getLoanProducts(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/getlpult', JSON.stringify({ token: token, LOAN_TYPE: id }))
      .map((response: Response) => response.json());
  }
  getInvestmentProducts(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/getinvestmentproducts', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  applyForLoan(token: string, loan_request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/applyForLoan', JSON.stringify({ token: token, loan_request: loan_request }))
      .map((response: Response) => response.json());
  }
  changeLoanProduct(token: string, model: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/changeProduct', JSON.stringify({ token: token, model: model }))
      .map((response: Response) => response.json());
  }
  changeLoanrequestProduct(token: string, model: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/change_request_product', JSON.stringify({ token: token, model: model }))
      .map((response: Response) => response.json());
  }
  changeLoanrequestProducts(token: string, loan:any, offer_letter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/change_request_products', JSON.stringify({ loan:loan,token: token, offer_letter: offer_letter }))
      .map((response: Response) => response.json());
  }
  saveCard(token: string, loan: any, trans_id:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/saveCard', JSON.stringify({ token: token, loan: loan, trans_id: trans_id }))
      .map((response: Response) => response.json());
  }
  updateDD(token: string, model: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updateDD', JSON.stringify({ token: token, model: model }))
      .map((response: Response) => response.json());
  }
  acceptContract(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/acceptContract', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  acceptBorrowerGetPaid(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/acceptBorrowerGetPaid', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendBVNRequest(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendBVNRequest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendForGuarantors(token: string, request_id: any, financial_docs: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendForGuarantors', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
      .map((response: Response) => response.json());
  }
  sendForCancelAutoDebit(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendForCancelAutoDebit', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendForReactivateAutoDebit(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendForReactivateAutoDebit', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendForFinancialDocuments(token: string, request_id: any, financial_docs: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendForFinancialDocuments', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
      .map((response: Response) => response.json());
  }
  sendForOtherDocuments(token: string, request_id: any, financial_docs: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendForOtherDocuments', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
      .map((response: Response) => response.json());
  }
  generateLinkForOtherDocuments(token: string, request_id: any, financial_docs: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/generateLinkForOtherDocuments', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
      .map((response: Response) => response.json());
  }
  sendCardRequest(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendCardRequest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendSuspendFines(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendSuspendFines', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  requestGuarantorCard(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/guarantors/requestGuarantorCard', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendAccountConfirmationRequest(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendAccountConfirmationRequest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendKYCRequest(token: string, request_id: any, kyc_request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/sendKYCRequest', JSON.stringify({ token: token, request_id: request_id, kyc_request: kyc_request }))
      .map((response: Response) => response.json());
  }
  sendDocumentPickupRequest(token: string, request_id: any, pick_up: any, doctypes: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendDocumentPickupRequest', JSON.stringify({ pick_up: pick_up, doctypes: doctypes, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  previewPrepayment(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/preview_prepayment', JSON.stringify({ token: token, formdata: value }))
      .map((response: Response) => response.json());
  }
  uploadTheLoanPhotoCustomer(token: any, id: any, filesUploaded: any, doctype: any, description: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/uploadTheLoanPhotoCustomer', JSON.stringify({ token: token, id: id, filesUploaded: filesUploaded, doctype: doctype, description: description }))
      .map((response: Response) => response.json());
  }
  completeloansetup(token: any, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/completeLoanSetup', JSON.stringify({ token: token, id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  submitPrepayment(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/save_prepayment', JSON.stringify({ token: token, formdata: value }))
      .map((response: Response) => response.json());
  }
  sendDeleteRequest(token: string, request_id: any, rejection: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendDeleteRequest', JSON.stringify({ token: token, request_id: request_id, rejection: rejection }))
      .map((response: Response) => response.json());
  }
  sendCancelTopUpRequest(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/sendCancelTopUpRequest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendContractDocumentRequest(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendContractDocumentRequest', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  reopenRequest(token: string, request_id: any, reopen: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/reopenRequest', JSON.stringify({ token: token, request_id: request_id, reopen: reopen }))
      .map((response: Response) => response.json());
  }
  sendForDirectDebit(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/setupDirectDebit', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendForDirectDebitOnAccount(token: string, request_id: any, account: any, password: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/setupDirectDebit', JSON.stringify({ token: token, request_id: request_id, password: password, account: account }))
      .map((response: Response) => response.json());
  }
  sendRequestForDirectDebitOnAccount(token: string, request_id: any, account: any, password: any, channel: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/setupDirectDebit', JSON.stringify({ channel: channel, token: token, request_id: request_id, password: password, account: account }))
      .map((response: Response) => response.json());
  }

  cancelCustomerNotification(token: string, request_id: any, notification: any, password: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/rejectNotification', JSON.stringify({ token: token, request_id: request_id, password: password, notification: notification }))
      .map((response: Response) => response.json());
  }
  sendForStopDirectDebitMandate(token: string, request_id: any, account: any, password: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doStopDirectDebitMandate', JSON.stringify({ token: token, request_id: request_id, password: password, account: account }))
      .map((response: Response) => response.json());
  }
  sendForDirectDebitStatus(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/sendForDirectDebitStatus', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }
  sendForDirectDebitStatusNIBBS(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/sendForDirectDebitStatusNIBBS', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }
  sendForCancelDirectDebitStatus(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/sendForCancelDirectDebitStatus', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }
  sendForCancelDirectDebitStatusNibbs(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/sendForCancelDirectDebitStatusNibbs', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }
  skipNextDebit(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/skipNextDebit', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }
  sendForResendDirectDebitStatus(token: string, request_id: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/sendForResendDirectDebitStatus', JSON.stringify({ token: token, request_id: request_id, account: account }))
      .map((response: Response) => response.json());
  }

  runCreditCheck(token: string, request_id: any, bureau_selected: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/runCreditCheck', JSON.stringify({ bureau_selected: bureau_selected, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getCustomerPayments(token: string, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCustomerPayments', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  recalculateFees(token: string, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/recalculateFees', JSON.stringify({ token: token, value: value }))
      .map((response: Response) => response.json());
  }
  doUpload(files: any): Observable<any> {
    // get users from api
    const formData: FormData = new FormData();
    formData.append('file', files, files.name);
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/uploadDoc', formData)
      .map((response: Response) => response.json());
  }
  doUploads(files: any): Observable<any> {
    // get users from api
    const formData: FormData = new FormData();
    formData.append('file', files, files.name);
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/uploadMandate', formData)
      .map((response: Response) => response.json());
  }
  uploadTheLoanPhoto(token: any, id: any, filesUploaded: any, doctype: any, description: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/uploadTheLoanPhoto', JSON.stringify({ token: token, id: id, filesUploaded: filesUploaded, doctype: doctype, description: description }))
      .map((response: Response) => response.json());
  }
  doUploadPhoto(files: any): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('X-API-KEY', 'z2BhpgFNUA99G8hZiFNv77mHDYcTlecgjybqDACv');
    const options = new RequestOptions({ headers: headers });
    // get users from api
    const formData: FormData = new FormData();
    formData.append('file', files, files.name);
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/uploadImage', formData)
      .map((response: Response) => response.json());
  }
  printTheOfferLetter(token: string, request_id: any, offer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/printTheOfferLetter', JSON.stringify({ token: token, request_id: request_id, offer: offer }))
      .map((response: Response) => response.json());
  }
  getAppKey(token: string): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/getKey', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getGuarantorsContacts(token: string, id: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/guarantors/numbers', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  addTasks(token: string, request_id: any, task: any, task_end_date: any,
    taskStatus: any, created_by: any, staffIds: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/addTask',
      {
        token: token, request_id: request_id, staff_ids: staffIds, task_end_date: task_end_date,
        created_by: created_by, task: task, task_status: taskStatus
      })
      .map((response: Response) => response.json());
  }


  myTasks(token: string, staff_ids: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/myTasks',
      { token: token, staff_ids: staff_ids })
      .map((response: Response) => response.json());
  }

  myCreatedTasks(token: string, staff_ids: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getMyCreatedTasks',
      { token: token, created_by: staff_ids })
      .map((response: Response) => response.json());
  }


  countMyTasks(token: string, staff_ids: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/countMyTasks',
      { token: token, staff_ids: staff_ids })
      .map((response: Response) => response.json());
  }

  countOpenTasks(token: string, created_by: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/countTasks',
      { token: token, created_by: created_by })
      .map((response: Response) => response.json());
  }

  getCompletedTasks(token: string, created_by: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getCompletedTasks',
      { token: token, created_by: created_by })
      .map((response: Response) => response);
  }

  getTasksByStatus(token: string, created_by: any, taskStatus: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getTasksByStatus',
      { token: token, created_by: created_by, status: taskStatus })
      .map((response: Response) => response.json());
  }

  getStaff(token: string, lender_id: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getStaff',
      { token: token, lender_id: lender_id })
      .map((response: Response) => response.json());
  }

  getUndoneTasks(token: string, created_by: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getUndoneTasks',
      { token: token, created_by: created_by })
      .map((response: Response) => response.json());
  }


  staffUpdateTask(token: string, task_id: any, task_comment: any, perform_by: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/staffUpdateTask',
      { token: token, task_id: task_id, task_comment: task_comment, perform_by: perform_by })
      .map((response: Response) => response.json());
  }

  deleteTask(token: string, task_id: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/deleteTask',
      { token: token, task_id: task_id })
      .map((response: Response) => response.json());
  }
  updateBorrowerPhoto(resource) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/updateBorrowerPhoto', resource)
      .map(response => response.json())
  }
  createSavingsContract(token: string, formdata: any, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/save_contract', JSON.stringify({ token: token, formdata: formdata, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getServicedCompanyPortfolio(token: any, company_id: any, start: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getServicedCompanyPortfolio', JSON.stringify({ token: token, company_id: company_id, start: start }))
      .map((response: Response) => response.json());
  }

  getPastLoans(token: any, people_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/getLoanSummary', { token, people_id }).map(r => r.json());
  }
  getCyberpayPayments(start_date: any, end_date: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/isItCCs', { start_date, end_date }).map(r => r.json());
  }
  getCyberpayMandates() {
    return this.http.get('https://creditclanapis.creditclan.com/api/v2/dashboard/getMandates').map(r => r.json());
  }
  isItCCs(email: any, token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/isItCCs', { email, token }).map(r => r.json());
  }
  getPaystackPayments(start_date: any, end_date: any, token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getPaystackPayments', { token, start_date, end_date }).map(r => r.json());
  }
  initiatembsticket(data: object) {
    return this.http.post('https://app.bankstatement.ai/mbs/initialize', data).map(r => r.json());
  }
  fetchFCN(token: any, people_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/fetchFCN', { token, people_id }).map(r => r.json());
  }
  getPortfoliActionsReports(token) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/getPortfoliActionsReports', { token }).map(r => r.json());
  }

  getPaymentCollections(token) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/getPaymentCollections', { token }).map(r => r.json());
  }
  getVirtualPaymentCollection(token, account_number) {
    return this.http.post('https://wema.creditclan.com/api/v3/wema/getaccountrecords', { token, account_number }).map(r => r.json());
  }
  getRequestNotifications(token, request_id) {
    return this.http.post('https://eligibilitytest.creditclan.com/public/readmessagessingle', { token, request_id }).map(r => r.json());
  }

  getPendingRequestsLength(token) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/requests', { token })
      .map((response: Response) => response.json());
  }
  getParentData(creditclan_request_id: any): Observable<any> {
    return this.http.get('https://sellbackend.creditclan.com/schoolcredit/public/index.php/api/loan/' + creditclan_request_id + '/info').map((response: Response) => response.json());
  }
  getTheMerchantRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheMerchantRequest', data)
      .map((response: Response) => response.json());
  }
  getTheMerchantLoan(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheMerchantLoan', data)
      .map((response: Response) => response.json());
  }
  getTheEatRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheEatRequest', data)
      .map((response: Response) => response.json());
  }
  getTheRentRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheRentRequest', data)
      .map((response: Response) => response.json());
  }
  getTheParentRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheParentRequest', data)
      .map((response: Response) => response.json());
  }
  getTheSchoolData(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/external/getSchoolData', data)
      .map((response: Response) => response.json());
  }
  post(request_id: any): Observable<any> {
    // get users from api
    return this.http.get('https://creditclanapis.creditclan.com/api/v2/services/passLoanToBank/' + request_id)
      .map((response: Response) => response.json());
  }
  updatebnploffer(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/updatebnploffer', data)
      .map((response: Response) => response.json());
  }
  getUpfrontAccount(request_id: any, amount: any): Observable<any> {


    let data = {
      "amount": amount,
      "merchant_name": "Creditclan",
      "narration": "Bla Bla",
      "phone": "08179264890",
      "request_id": request_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-API-KEY', 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://wema.creditclan.com/generate/account', data)
      .map((response: Response) => response.json());
  }
  getExcessPayments(token) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/getExcessPayments', { token }).map(r => r.json());
  }
  getWemaPayments(start_date: any, end_date: any, token: any, account_number:any, account_name:any, start:any) {
    return this.http.post('https://wema.creditclan.com/api/v3/wema/wematransactions/', { token, start_date, end_date, start, account_number, account_name}).map(r => r.json());
  }
  getataglance(token, loan_product_id, general_lender): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getataglance', JSON.stringify({ general_lender:general_lender,token: token, loan_product_id:loan_product_id }))
      .map((response: Response) => response.json());
  }

  getWemaLivetransactions(start_date: any,account_number:any, amount:any, session_id:any) {
    return this.http.post('http://ec2-3-83-252-100.compute-1.amazonaws.com/statement.php', { start_date, account_number, amount, session_id}).map(r => r.json());
  }

  recoveryComment(id: number): Observable<any> {
    // get users from api
    
    return this.http.post('https://lendnode.creditclan.com/get_comments',{request_id: id })
      .map((response: Response) => response.json());
  }

  SaveComment(id: number, message:any, agent_id:any): Observable<any> {
    // get users from api
    
    return this.http.post('https://lendnode.creditclan.com/comment',{request_id: id , message:message, agent_id:agent_id})
      .map((response: Response) => response.json());
  }
}
