<app-requestheader [loan]="loan" [where]='where'></app-requestheader>

<h5 class="title mb-4">Remita Inflight</h5>
<div *ngIf="!loadingData" [ngClass]="{'overpanel-scroll padding-regular': scroll}">
  <div *ngIf="customer?.remita.has_remita">
    <div class="flex">
      <div class="section-poster flex-2">
        <div class="image flex-center">
          <div class="flex flex-column flex-center">
            <img onerror="this.src='assets/img/avatar-mini.png';" src="assets/images/inflight.svg">
            <div class="w-60 mt-2">
              <img onerror="this.src='assets/img/avatar-mini.png';" src="assets/images/remita.svg">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="refreshingInfo" class="flex-3">
        <div class="fill-w fill-h flex-center flex-column">
          <div class="h-group center mb-4 padded">
            <h2 class="title no-float">Refresh Remita Information</h2>
            <p class="subtitle">Customer remita information will be refreshed</p>
          </div>
          <div class="text-left mt-2">
            <app-button (click)="refreshRemitaInformation()" [disabled]="loading" class="small" [loading]="loading"
              label="Submit">
            </app-button>
            <app-button (click)="refreshingInfo =!refreshingInfo" [disabled]="loading" class="small red outline ml-1"
              label="Cancel">
            </app-button>
          </div>
        </div>
      </div>
      <div *ngIf="!refreshingInfo" class="ml-4 flex-3">
        <div *ngIf="!showSchedule && !showLoan">
          <ul class="decList bigger">
            <li *ngIf="has_remita_loan"><span class="title no-float">Loan Information</span>
            </li>
            <li *ngIf="has_remita_loan"><span>Mandate Reference</span>
              <span>{{customer?.remita_loans?.MANDATE_REF || 'Not provided'}}</span></li>
            <li *ngIf="has_remita_loan"><span>Authorization Code</span>
              <span>{{customer?.remita_loans?.REMITA_RESPONSE?.data?.authorisationCode || 'Not provided'}}</span></li>
            <li><span class="title no-float">Remita Information</span>
              <span>
                <button class="btn btn-outline-secondary btn-xs mb-1" (click)="showSchedule = !showSchedule">
                  Show schedule</button>
              </span>
            </li>

            <li><span>Remita</span>
              <span>{{customer?.remita?.data?.CUSTOMER_ID || 'Not provided'}}</span></li>
            <li><span>Name</span>
              <span>{{customer?.remita?.data?.CUSTOMER_NAME || 'Not provided'}}</span></li>
            <li><span>Account Number</span>
              <span>{{customer?.remita?.data?.ACCOUNT_NUMBER || 'Not provided'}}</span></li>
            <li><span>Bank</span>
              <span>{{customer?.remita?.data?.bank_name || 'Not provided'}}</span></li>
            <li><span>Company</span>
              <span>{{customer?.remita?.data?.COMPANY_NAME || 'Not provided'}}</span></li>

            <!-- Information preview side -->
          </ul>
          <ul class="decList bigger mt-4">
            <li><span>Salary</span><span>{{customer?.remita?.data?.SALARY}}</span></li>
            <li><span>Last Pay Date</span><span>{{customer?.remita?.data?.LAST_PAY_DATE}}</span></li>
            <li><span class="title no-float">&nbsp;</span>
              <span>
                <button class="btn btn-outline-secondary btn-xs mb-1"
                  (click)="showSchedule = false; refreshingInfo = false; showLoan = true;">
                  Show Loan Information</button>
              </span>
            </li>
            <li><span class="title no-float">&nbsp;</span>
              <span>
                <button class="btn btn-outline-secondary btn-xs mb-1"
                  (click)="refreshingInfo = true; showSchedule = false; showLoan = false;">
                  Refresh Remita Information</button>
              </span>
            </li>
          </ul>
        </div>
        <div *ngIf="showSchedule && !showLoan">
          <div class="text-right mb-2">
            <button class="btn btn-outline-secondary btn-xs" (click)="showSchedule = !showSchedule">Show
              Remita information</button>
          </div>
          <div class="panel pd_15">
            <table class="table small">
              <thead>
                <th>Date</th>
                <th>Amount</th>
              </thead>
              <tbody>
                <tr *ngFor="let sal of customer?.remita?.data?.SALARY_PAYMENT_DETAILS">
                  <td>{{sal.paymentDate}}</td>
                  <td>{{sal.amount|number:'.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right mb-2">
            <button class="btn btn-outline-secondary btn-xs" (click)="showLoan = true;showSchedule=false">Show
              Loan information</button>
          </div>
        </div>
        <div *ngIf="!showSchedule && showLoan">
          <div class="text-right mb-2">
            <button class="btn btn-outline-secondary btn-xs" (click)="showSchedule = false; showLoan = false">Show
              Remita information</button>
          </div>
          <div class="panel pd_15">
            <table class="table small">
              <thead>
                <th>Amount</th>
                <th>Date</th>
                <th>Provider</th>
                <th>Out Amt</th>
                <th>Repay</th>
                <th>Freq</th>
                <th>Status</th>
              </thead>
              <tbody>
                <tr *ngFor="let sal of customer?.remita?.data?.CURRENT_LOAN_REPAYMENTS">
                  <td>{{sal.loanAmount|number:'.2-2'}}</td>
                  <td>{{sal.loanDisbursementDate}}</td>
                  <td>{{sal.loanProvider}}</td>
                  <td>{{sal.outstandingAmount|number:'.2-2'}}</td>
                  <td>{{sal.repaymentAmount|number:'.2-2'}}</td>
                  <td>{{sal.repaymentFreq}}</td>
                  <td>{{sal.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!customer?.remita.has_remita">
    <div class="h-group center mb-4 padded empty-state">
      <div class="icon"><i class="fa fa-meh-o"></i></div>
      <h2 class="title">No Remitta Inflight</h2>
      <div class="text-center mt-4">
        <!-- <app-button (click)="refreshRemitaInformation()" [disabled]="loading" class="medium mr-2" [loading]="loading"
          label="Get Remita Information"> -->
        <div class="form-group">
          <label class="control-label">Choose phone number</label>
          <select class="form-control" name="phonetype" [(ngModel)]="phonetype">
            <option></option>
            <option value='1'>BVN Phone Number</option>
            <option value='2'>Profile Phone Number</option>
          </select>
        </div>
        <app-button [disabled]="phonetype ==''" (click)="refreshRemitaInformation()" [disabled]="loading"
          class="medium mr-2" [loading]="loading" label="Get Remita Information">
        </app-button>
        <!-- <app-button (click)="refreshingInfo =!refreshingInfo" [disabled]="loading"
          class="medium" label="Cancel">
        </app-button> -->
      </div>
    </div>
  </div>
</div>