<form>
  <div class="cont">
    <div class="sect-wrap">
      <div class="left-container">
        <div class="onb-title">
          <h3>Activate your account</h3>
          <p>Enter activation code sent to your email address to activate your account. You can reach our WhatsApp team
            on 09053535333 if you have any issues</p>
        </div>
        <div class="form-group">
          <label class="label">
            <input type="text" name="activation_code" [(ngModel)]="activation_code" class="form-control custom"
              placeholder="Enter Activation code">
          </label>
        </div>
        <div class="mt-3">
          <app-button (click)="activate()" label="Activate" [loading]="loading"></app-button>
        </div>
      </div>
      <div class="right-container text-center flex-center">
        <div class="img-wrap">
          <img src="assets/images/finish_line.svg" alt="Illustration">
          <p class="welcome-text">Almost there..</p>
        </div>
        <!--<div class="shadow_container">-->
        <!--<h3>Welcome to 700 Lenders</h3>-->
        <!--<div>-->
        <!--<img src="assets/images/alt-logo.png" alt="logo" style="height: 200px; width: 200px;">-->
        <!--</div>-->
        <!--<div class="text-center">-->
        <!--<p>In this clan <br> We are related by interest</p>-->
        <!--</div>-->
        <!--</div>-->
      </div>
    </div>
  </div>
</form>
