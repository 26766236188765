import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setupeligibility',
  templateUrl: './setupeligibility.component.html',
  styleUrls: ['./setupeligibility.component.css']
})
export class SetupeligibilityComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  displayNext() {
    this.router.navigate(['/address']);
  }
}
