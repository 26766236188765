<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Nibbs Instant Mandate Debit</h3>
      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              
              <th>Customer</th>
              <th>Account</th>
              <!-- <th>Mandate ID</th> -->
              <th>Due Amount</th>
              <th>Next Due Amount</th>
              <th>Last Due Date</th>
              <th>Next Due Date</th>
              <th>Account Balance</th>
              <th>Debit</th>
              <th></th>
            </thead>
           
            <tbody>
              <tr *ngFor="let record of data">
               
                <td>{{record?.legal_name}}</td>
                <td>{{record?.last_four_digits}} / {{record?.bank_name}}</td>
                <!-- <td>{{record?.DD_MANDATE_ID}}</td> -->
                <td>{{record?.due|number:'.2-2'}}</td>
                <td>{{record?.nextdueamount|number:'.2-2'}}</td>
                <td>{{record?.lastdue}}</td>
                <td>{{record?.nextdue}}</td>
                <td><app-checkmandatebalance [mandate]="record"></app-checkmandatebalance></td>
                <td><input type="text" [(ngModel)]="record.amount_to_debit"></td>
                <td><app-button (click)="debitAmount(record)" [loading]="loading" class="small green mb-1 mr-1" label="Make Payment"></app-button></td>
                
              </tr>
            </tbody>
             
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>