<div class="panel panel-default setting">
  <div class="panel-heading flex align-center justify-between" role="tab" id="headingBSB">
    <h4 class="panel-title">
      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseBSB" aria-expanded="true"
        aria-controls="collapseBSB">
        Borrower Score Based Interest Rates
      </a>
    </h4>
    <span class="tools" *ngIf="where_from !='self'"><button (click)="closeSettings()"
        class="btn btn-outline-danger btn-sm">Close</button></span>
  </div>
  <div id="collapseBSB" class="panel-collapse collapse" [ngClass]="where_from!='self'? 'in': ''" role="tabpanel"
    aria-labelledby="headingBSB">
    <div class="panel-body">
      <form #interestBandForm="ngForm" novalidate>
        <div class="flex">
          <div class="flex-1 mr-1">
            <div class="form-group">
              <label class="control-label normal">Lower Band</label>
              <input type="text" class="form-control" name="LOWER_BAND" [(ngModel)]="band.LOWER_BAND">
            </div>
          </div>
          <div class="flex-1 ml-1">
            <label class="control-label normal">Higher Band</label>
            <input type="text" class="form-control" name="HIGHER_BAND" [(ngModel)]="band.HIGHER_BAND">
            <input type="hidden" name="INTEREST_DURATION" [(ngModel)]="band.INTEREST_DURATION">
            <input type="hidden" name="LOAN_DURATION" [(ngModel)]="band.LOAN_DURATION">
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 mr-1">
            <div class="form-group">
              <label class="control-label normal">Rate Factor</label>
              <!-- <input type="text" class="form-control" name="MAXIMUM_RATE" [(ngModel)]="band.MAXIMUM_RATE"> -->
              <div class="input-group btn-block">
                <input type="text" class="form-control btn-block" name="MAXIMUM_RATE" [(ngModel)]="band.MAXIMUM_RATE">
                <!-- <div class="input-group-btn">
                    <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                      <span class="sel_cur">{{interest_duration}}</span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right">
                      <li *ngFor="let d of loan_durations;let i=index;">
                        <a (click)="changeDuration(i,1)">{{d.INTEREST_DURATION}}</a>
                      </li>
                    </ul>
                  </div> -->
              </div>
            </div>
          </div>
          <div class="flex-1 ml-1">
            <label class="control-label normal">Amount Factor</label>
            <input type="text" class="form-control" name="MAXIMUM_AMOUNT" [(ngModel)]="band.MAXIMUM_AMOUNT">
          </div>
        </div>
        <div class="flex">
          <div class="flex-1">
            <div class="form-group">
              <label class="control-label normal">Duration Duration</label>
              <!-- <input type="text" class="form-control" name="MAXIMUM_DURATION" [(ngModel)]="band.MAXIMUM_DURATION"> -->
              <div class="input-group btn-block">
                <input type="text" class="form-control btn-block" name="MAXIMUM_DURATION"
                  [(ngModel)]="band.MAXIMUM_DURATION">
                <!-- <div class="input-group-btn">
                    <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                      <span class="sel_cur">{{loan_duration}}</span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu pull-right">
                      <li *ngFor="let d of loan_durations;let i=index;">
                        <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
                      </li>
                    </ul>
                  </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
                      <label class="control-label normal">Max Amount</label>
                      <input type="text" class="form-control" name="MAXIMUM_AMOUNT" [(ngModel)]="band.MAXIMUM_AMOUNT">
                  </div> -->
        </div>
        <app-button label="Submit" [loading]="loading" class="small"
          (click)="saveInterestRateBand(interestBandForm.value, interestBandForm.valid)" [disabled]="loading">
        </app-button>
      </form>
    </div>
    <div class="">
      <table class="table table-striped">
        <thead>
          <th>Lower</th>
          <th>Higher</th>
          <th>Principal Factor</th>
          <th>Rate Factor</th>
          <th>Duration Factor</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let band of interest_bands">
            <td>{{ band?.LOWER_BAND }}</td>
            <td>{{ band?.HIGHER_BAND }}</td>
            <td>{{ band?.MAXIMUM_AMOUNT }}</td>
            <td>{{ band?.MAXIMUM_RATE }}</td>
            <td>{{ band?.MAXIMUM_DURATION }}</td>
            <td>
              <button class="btn btn-outline-info btn-xs" (click)="editBand(band)">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn btn-outline-danger btn-xs ml-1" (click)="deleteBand(band)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>