<section class="panel padding-regular" *ngIf="where == 'loan'">
  <h3 class="section-title">
    Accounts & Cards <small>Enjoy the benefit of easy transfer, stay safe.</small>
  </h3>
  <div class="flex mt-5">
    <div class="cards fill-w" *ngIf="!loadingCards">
      <div class="card light has-overlay" *ngIf="account.LAST_FOUR_DIGITS">
        <div class="top">
          <div class="name">{{ account.bank_name }}</div>
        </div>
        <div class="middle">
          <div class="number">{{ account.LAST_FOUR_DIGITS }}</div>
        </div>
        <div class="bottom">
          <div class="items flex justify-between">
            <div class="item">
              <span class="label">{{ account.CARD_NAME }}</span>
              <span class="value"></span>
            </div>
          </div>
        </div>
        <div class="overlay">
          <app-button label="Manage accounts" class="small outline ml-2" (click)="open.emit('account')">
          </app-button>
        </div>
      </div>
      <div class="card card-light flex-1 flex-center" *ngIf="!account.LAST_FOUR_DIGITS">
        <div (click)="openModal('accounts')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60" style="color:#fff">No Account Added</div>
        </div>
      </div>

      <div class="card has-overlay" *ngIf="card.LAST_FOUR_DIGITS">
        <div *ngIf="card.CARD_VALIDATED_THROUGH=='1'">
          <div class="top">
            <div class="name color-white">{{ card.CARD_CHECK_ID.card.issuer }}</div>
          </div>
          <div class="middle">
            <div class="number">{{card.CARD_CHECK_ID.card.first_6digits}}** *** {{ card.CARD_CHECK_ID.card.last_4digits }}</div>
          </div>
          <div class="bottom">
            <div class="items flex justify-between">
              <div class="item">
                <span class="label">Exp</span>
                <span class="value">{{ card.CARD_CHECK_ID.card.expiry }}</span>
              </div>
              <div class="item">
                <img style="width:20%" *ngIf="card.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                    class="card__img">
                  <img style="width:20%" *ngIf="card.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                    class="card__img">
                  <img style="width:20%" *ngIf="card.brand === 'visa'" src="assets/images/visa_card.png" alt="card type" class="card__img">
              </div>
            </div>
          </div>
          <div class="overlay">
            <app-button label="Manage cards" class="small outline ml-2" (click)="open.emit('card')">
            </app-button>
          </div> 
        </div>
        <div *ngIf="card.CARD_VALIDATED_THROUGH=='2'">
          <div class="top">
            <div class="name color-white">{{ card.bank }}</div>
          </div>
          <div class="middle">
            <div class="number">{{ card.bin }}** *** {{ card.LAST_FOUR_DIGITS }}</div>
          </div>
          <div class="bottom">
            <div class="items flex justify-between">
              <div class="item">
                <span class="label">Exp</span>
                <span class="value">{{ card.expiry_month }}/{{ card.expiry_year }}</span>
              </div>
              <div class="item">
                <img style="width:20%" *ngIf="card.brand === 'mastercard'" src="assets/images/mastercard.png" alt="card type"
                    class="card__img">
                  <img style="width:20%" *ngIf="card.brand === 'visacard'" src="assets/images/visa_card.png" alt="card type"
                    class="card__img">
                  <img style="width:20%" *ngIf="card.brand === 'visa'" src="assets/images/visa_card.png" alt="card type" class="card__img">
              </div>
            </div>
          </div>
          <div class="overlay">
            <app-button label="Manage cards" class="small outline ml-2" (click)="open.emit('card')">
            </app-button>
          </div>
        </div>
      </div>
      <div class="card flex-1 flex-center" *ngIf="!card.LAST_FOUR_DIGITS">
        <div (click)="openModal('cards')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60 color-white">No Card Added</div>
        </div>
      </div>
    </div>
    <div class="cards fill-w" *ngIf="loadingCards">
      <div class="card light skeleton fit-content"></div>
      <div class="card light skeleton fit-content"></div>
    </div>
  </div>
</section>
<section class="panel padding-regular"  *ngIf="where == 'customer'">
  <h3 class="section-title">
    Accounts & Cards <small>Enjoy the benefit of easy transfer, stay safe.</small>
  </h3>
  <div class="flex mt-5">
    <div class="cards fill-w" *ngIf="!loadingCards"> 
      <div class="card card-light flex-1 flex-center">
        <div (click)="open.emit('account')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60" style="color:#fff">View Accounts</div>
        </div>
      </div>
 
      <div class="card flex-1 flex-center">
        <div (click)="open.emit('card')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60 color-white">View Cards</div>
        </div>
      </div>
    </div>
    <div class="cards fill-w" *ngIf="loadingCards">
      <div class="card light skeleton fit-content"></div>
      <div class="card light skeleton fit-content"></div>
    </div>
  </div>
</section>