import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chooseoption',
  templateUrl: './chooseoption.component.html',
  styleUrls: ['./chooseoption.component.css', './style.css', './boot-outline-btn.css']
})
export class ChooseoptionComponent implements OnInit {
  @Output() chosenPayment = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  choosePaymentOption(type) {
    this.chosenPayment.emit({ payment_type: type })
  }
}
