import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contractdetails',
  templateUrl: './contractdetails.component.html',
  styleUrls: ['./contractdetails.component.css', './style.css', './boot-outline-btn.css']
})
export class ContractdetailsComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input('currentUser') currentUser: any;
  @Input('loan') loan: any;
  @Input('loading') loading = false;
  @Input() embedModal = false;
  @Output() creatingContract = new EventEmitter();
  @Output() editingContract = new EventEmitter();
  @Output() makeOffer = new EventEmitter();
  constructor(private router: Router) { }

  ngOnInit() {
  }
  createContract() {
    this.creatingContract.emit({ request_id: this.request_id, currentUser: this.currentUser, loan: this.loan })
  }
  editContract() {
    this.editingContract.emit({ request_id: this.request_id, currentUser: this.currentUser, loan: this.loan })
  }
  makeOfferForMarketLoan() {
    this.makeOffer.emit({ request_id: this.request_id, currentUser: this.currentUser, loan: this.loan })
  }
  viewOffers() {
    let url = 'applications/offers/' + this.request_id;
    this.router.navigate([url]);
  }
}
