import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, DataService, LoansService, OperationsService, StorageService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-guarantorscover',
  templateUrl: './guarantorscover.component.html',
  styleUrls: ['./guarantorscover.component.css']
})
export class GuarantorscoverComponent implements OnInit {
  @Input() requestId;
  @Input() embedded = false;
  loading = false;
  value = 100000;
  provided: any;
  verified: any;
  estimated_value = 100000;
  depreciation_factor = 4;
  liquidation_value = 150000;
  edit_mode = false;
  edit_collateral: any;
  currentUser: any;
  request_id: any;
  sub: any;
  guarantors: any;
  guarantor_count;
  parentRouteId: any;
  loan: any;
  showPicture = false;
  contacts: any;
  display_contacts: any;
  hashed_contacts: any;
  home_address: any;
  office_address: any;
  token: any;
  _request_id: any = 33851;
  view = 'master';
  profile: any;
  address: any;
  work: any;
  card: any;
  bank: any;
  phone: any;
  has_bank = false;
  has_card = false;
  constructor(
    public dataService: DataService,
    private loanService: LoansService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private loansService: LoansService,
    public router: Router,
    private operationService: OperationsService,
    private authService: AuthenticationService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
      this.getLoan();
      this.loadGuarantorsData();
      this.getGuarantorsContacts();
    });
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  getLoan() {
    this.loansService.getLoanContract(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loan = data.loan;
        this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });
      });
  }
  requestGuarantors() {
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
  }
  loadGuarantorsData() {
    this.loanService.getLoanGuarantors(this.currentUser.token, this.parentRouteId).subscribe(res => {
      this.guarantors = res.data;
      this.guarantor_count = res.gua_count;
    });
  }
  saveNewCollateralDetails() {

  }
  openCollateralModal() {
    this.dataService.showCollateralModal.emit();
  }
  showGuarantorContacts() {
    this.dataService.showContactsModal.emit({ contacts: this.contacts });
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan });
  }
  addGuarantors() {
    this.dataService.inviteNewGuarantor.emit({ 'location': 'request_guarantor_mod', data: this.loan });
  }
  openProfile(request_id) {
    this.router.navigate(['./applications/profile/' + request_id]);
  }
  openGuarantors(request_id) {
    this.router.navigate(['./applications/guarantors/' + request_id]);
  }
  openLoanSpider(request_id) {
    this.router.navigate(['applications/analytics/' + request_id + '/spider']);
  }
  opentimeline(request_id) {
    this.router.navigate(['./applications/timeline/' + request_id]);
  }
  openAnalytics(request_id) {
    this.router.navigate(['./applications/analytics/' + request_id]);
  }
  openCreditCheck(request_id) {
    this.router.navigate(['./applications/creditcheck/' + request_id]);
  }
  openBankStatement(request_id) {
    this.router.navigate(['./applications/bankstatements/' + request_id]);
  }
  openP2P(request_id) {
    this.router.navigate(['./applications/p2p/' + request_id]);
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.dataService.disbursingRequest.emit(event);
  }
  openPreviousRequests(request_id) {
    this.router.navigate(['./applications/requests/' + request_id]);
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  getGuarantorsContacts() {
    this.operationService.getGuarantorContacts('13126').subscribe(res => {
      //console.log(res);
      this.contacts = res.result.frequent_nameless_calls.filter(contact => contact != '');
      // const filtered_data = this.contacts.filter(contact => contact != '');
      this.display_contacts = this.contacts.filter((contact, id) => id < 2);
      this.hashed_contacts = this.replaceAsterisks(this.display_contacts);
      this.home_address = res.result.adresses.home_address;
      this.office_address = res.result.adresses.office_address;
    });
  }
  replaceAsterisks(numberArray) {
    return numberArray.map((number) => {
      let start = number.substring(0, 4), end = number.substring(7);
      return `${start}***${end}`;
    });
  }
  viewGuarantor(guarantor) {
    this.view = 'detail';
    this.loading = true;
    this.loansService.getLoanGuarantorDetails(this.currentUser.token, guarantor.RG_ID)
      .subscribe(data => {
        this.loading = false;
        if (data.status == true) {
          this.profile = data.data.guarantor.details.basic;
          this.home_address = data.data.guarantor.details.address;
          this.work = data.data.guarantor.details.work;
          this.card = data.data.guarantor.details.card;
          this.bank = data.data.guarantor.details.bank;
          this.phone = data.data.guarantor.details.phone;
          this.has_bank = data.data.guarantor.details.has_bank;
          this.has_card = data.data.guarantor.details.has_card;
        }
        // //console.log(data);
        // let details = data.data.guarantor.details;
        // this.loading = false;

        // // Store in the model to be viewed on the front
        // this.model.basicInfo.prev = details.basic;
        // this.model.address.prev = details.address;
        // this.model.work.prev = details.work;
        // this.gprofile = data;

      });
  }
}
