import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-pushingtomarket',
  templateUrl: './pushingtomarket.component.html',
  styleUrls: ['./pushingtomarket.component.css']
})
export class PushingtomarketComponent implements OnInit {

  terms = false;
  @Input('loan') loan: any;
  @Input('currentUser') currentUser: any;
  loading = false;
  change_payment_form = {
    REQUEST_ID: '',
    PARTNER_ID: '',
    MARKET_OPTION:'',
    SECURITY_QUESTION_ANSWER:''
  };
  partners:any;
  constructor(public toastr: ToastrService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService, public storageService: StorageService) {

  }

  ngOnInit() {
    this.change_payment_form.REQUEST_ID = this.loan.request_id;
    this.change_payment_form.PARTNER_ID = '';
    this.loadALLSpecialPartners()
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }

  changeTheDefaultPayment(value, valid) {

    this.loading = true;
    this.operationsService.sendRequestToMarket(this.currentUser.token, value)
      .subscribe(data => {
        this.loading = false;
        if (data.status === true) {
          this.showSuccess(data.message)
        } else {
          this.showError(data.message)
        }
      });
  }
  loadALLSpecialPartners(){
    this.loading = true;
    this.operationsService.loadALLSpecialPartners(this.currentUser.token)
      .subscribe(data => {
        this.loading = false;
        this.partners = data.data;
      });
  }
}
