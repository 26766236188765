import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DataService,
  LoansService,
  OptionsserviceService,
  StorageService
} from '../../_services';

@Component({
  selector: 'app-previewrepaymentschedule',
  templateUrl: './previewrepaymentschedule.component.html',
  styleUrls: ['./previewrepaymentschedule.component.css']
})
export class PreviewrepaymentscheduleComponent implements OnInit {
  @Input('goandgetschedule') goandgetschedule = '0';
  @Input('OPEN_SCHEDULE') OPEN_SCHEDULE = false;
  @Input('from_where') from_where = '1';
  @Input('schedule') schedule: any;
  @Input('parentRouteId') parentRouteId: number;
  @Input('where') where = '1';
  request_id_: any;
  public sub: any;
  public loading = false;
  public currentUser: any;
  public status = false;
  @Input('loan_status')  loan_status='';
  @Output() closePopUp = new EventEmitter();
  @Output() modifyContract = new EventEmitter();
  @Input('canEditSchedule') canEditSchedule = false;
  constructor(
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public dataService: DataService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    console.log(this.loan_status,'Schedule Modal');
    // this.currentUser = this.storageService.read<any>('currentUser');
    this.getAllSchedule();
    //console.log(this.currentUser.token, this.parentRouteId);
  }
  saveNewRepayment() {
    this.loading = true;
    this.loansService.saveNewRepayment(this.currentUser.token, this.parentRouteId, this.schedule)
      .subscribe(analysis => {
        this.loading = false;
        this.getAllSchedule();

      });
  }
  getAllSchedule() {
    if(this.goandgetschedule == '1'){
    //  this.parentRouteId = +params['id'];
    this.loansService.getLoanSchedule(this.currentUser.token, this.parentRouteId)
      .subscribe(repayments => {
        this.status = repayments.status;
        if (repayments.status) {
          this.schedule = repayments.data;
          this.canEditSchedule = repayments.can_modify_contract;
          this.loan_status = repayments.loan_status;
        } else {

        }

      });
    }
  }
  getTotal(key, schedule) {
    if (schedule === undefined) { } else {
      let total = 0;
      if (key === 'INITIAL_PRINCIPAL') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.INITIAL_PRINCIPAL); }, 0);
      }
      if (key === 'PRINCIPAL_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.PRINCIPAL_REPAYMENT); }, 0);
      }
      if (key === 'INTEREST_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.INTEREST_REPAYMENT); }, 0);
      }
      if (key === 'FEES') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.FEES) + parseInt(o.VAT_ON_FEES); }, 0);
      }
      if (key === 'TERM_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.TERM_REPAYMENT); }, 0);
      }
      return total;
    }
    //

  }
  closeTheLoanFormPopUp() {
    this.closePopUp.emit();
  }
  modifyTheContract() {
    this.modifyContract.emit();
  }

}
