<section>
  <div class="panel padding-regular flex justify-around">
    <!-- <app-help-popover
      [content]="[
        {
          title: 'Credit check',
          img: 'assets/images/success.svg',
          text:
            '
        <div>
          <p>Select the type of Credit Bureau you are comfortable with.
          </p>
        </div>'
        }
      ]"
    ></app-help-popover> -->
    <div class="form-group no-margin">
      <input type="radio" name="credit_bureau" value="crc" (change)="onChange($event)">
      <span class="control-label normal">CRC</span>
    </div>
    <div class="form-group no-margin">
      <input type="radio" value="xds" name="credit_bureau" checked (change)="onChange($event)">
      <span class="control-label normal">First Central</span>
    </div>
    <div class="form-group no-margin">
      <input type="radio" value="cr_services" name="credit_bureau" (change)="onChange($event)">
      <span class="control-label normal">CRC Services</span>
    </div>
  </div>

  <div *ngIf="view == 'crc'" class="panel padding-regular">
    <app-help-popover [content]="[
      {
        title: 'Credit search',
        img: 'assets/images/search.svg',
        text:
          '
        <div>
          <p>No credit search carried out yet.</p>
        </div>'
      }
    ]"></app-help-popover>
    <div class="flex" style="min-height: 270px">
      <div class="flex-1">
        <div class="w-75">
          <img src="assets/images/crc.png" alt="Credit Registry Icon">
        </div>
      </div>
      <div *ngIf="!result?.crc?.has_data" class="flex-1 flex flex-column justify-end">
        <div class="h-group flex flex-column align-end text-right">
          <h3 class="title w-75" style="font-size: 1.9rem">No credit search carried out yet</h3>
          <p class="subtitle fill-w">Result will be displayed as pdf which can be downloaded here.</p>
          <div class="mt-3" *ngIf="from_where=='1'">
            <app-button *ngIf="access?.has_crc" (click)="showRerunModal = true" label="Request Search" class="medium">
            </app-button>
            <app-button *ngIf="!access?.has_crc" (click)="openSettingsModel('1')" label="Modify Settings"
              class="medium"></app-button>
          </div>
        </div>
      </div>
      <div *ngIf="result?.crc?.has_data" class="flex-1 flex flex-column justify-end">
        <ul class="decList bigger">
          <li><span>Run</span><span>{{crc_data.date_added}}</span></li>
          <li><span>By</span><span>{{crc_data.user}}</span>
          </li>
        </ul>
        <div class="mt-3">
          <a [href]="crc_data.link" target="_blank" class="btn btn-outline-info btn-sm mr-2">Download</a>
          <button class="btn btn-outline-secondary btn-sm" (click)="showRerunModal = true">
            Rerun Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="view == 'xds'" class="panel padding-regular">
    <app-help-popover [content]="[
      {
        title: 'Credit search',
        img: 'assets/images/search.svg',
        text:
          '
        <div>
          <p>No credit search carried out yet.</p>
        </div>'
      }
    ]"></app-help-popover>
    <div class="flex" style="min-height: 270px">
      <div class="flex-1">
        <div class="w-75">
          <img src="assets/images/xds.jpg" alt="Credit Registry Icon">
        </div>
      </div>
      <div *ngIf="!result?.xds?.has_data" class="flex-1 flex flex-column justify-end">
        <div class="h-group flex flex-column align-end text-right">
          <h3 class="title w-75" style="font-size: 1.9rem">No credit search carried out yet</h3>
          <p class="subtitle fill-w">Result will be displayed as pdf which can be downloaded here.</p>
          <div class="mt-3 flex" *ngIf="from_where=='1'">
            <app-button label="Request for analysis" class="medium outline mr-1" disabled></app-button>
            <app-button *ngIf="access?.has_xds" (click)="showRerunModal = true" label="Request Search" class="medium">
            </app-button>
            <app-button *ngIf="!access?.has_xds" (click)="openSettingsModel('2')" label="Modify Settings"
              class="medium"></app-button>
          </div>
        </div>
      </div>
      <div *ngIf="result?.xds?.has_data" class="flex-1 flex flex-column justify-end">
        <ul class="decList bigger">
          <li><span class="text-primary">Run</span><span class="pull-right">{{xds_data.date_added}}</span></li>
          <li><span class="text-primary">By</span><span class="pull-right">{{xds_data.user}}</span>
          </li>
        </ul>
        <div class="mt-4">
          <a class="btn btn-outline-info btn-sm mr-2 " [href]="xds_data.link" target="_blank"
            class="btn btn-default">Download</a>
          <button class="btn btn-outline-secondary btn-sm" (click)="showRerunModal = true">Request Search</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="view == 'cr_services'" class="panel padding-regular">
    <app-help-popover [content]="[
      {
        title: 'Credit search',
        img: 'assets/images/search.svg',
        text:
          '
        <div>
          <p>No credit search carried out yet.</p>
        </div>'
      }
    ]"></app-help-popover>
    <div class="flex" style="min-height: 270px">
      <div class="flex-1">
        <div class="w-75">
          <img src="assets/images/creditregistry.png" alt="Credit Registry Icon">
        </div>
      </div>
      <div *ngIf="!result?.cr_services?.has_data" class="flex-1 flex flex-column justify-end">
        <div class="h-group flex flex-column align-end text-right">
          <h3 class="title w-75" style="font-size: 1.9rem">No credit search carried out yet</h3>
          <p class="subtitle fill-w">Result will be displayed as pdf which can be downloaded here.</p>
          <div class="mt-3" *ngIf="from_where=='1'">
            <app-button *ngIf="access?.has_crservices" (click)="showRerunModal = true" label="Request Search"
              class="medium"></app-button>
            <app-button *ngIf="!access?.has_crservices" (click)="openSettingsModel('3')" label="Modify Settings"
              class="medium"></app-button>
          </div>
        </div>
      </div>
      <div *ngIf="result?.cr_services?.has_data" class="flex-1 flex flex-column justify-end">
        <ul class="decList bigger">
          <li><span class="text-primary">Run</span><span class="pull-right">{{cr_services_data.date_added}}</span></li>
          <li><span class="text-primary">By</span><span class="pull-right">{{cr_services_data.user}}</span>
          </li>
        </ul>
        <div class="mt-4">
          <a class="btn btn-outline-info btn-sm mr-2" [href]="cr_services_data.link"
            class="btn btn-default">Download</a>
          <button class="btn btn-outline-secondary btn-sm" (click)="showRerunModal = true">Request Search</button>
        </div>
      </div>
    </div>
  </div>
  <div class="panel bg-off-white" *ngIf="from_where=='1'">
    <div class="alert">
      <div class="texts no-padding">
        <div class="title">Settings</div>
        <div class="subtitle">Modify your credit bureau connection settings</div>
      </div>
      <div class="action">
        <app-button class="small outline" label="Start" (click)="openSettingsModel('0')"></app-button>
      </div>
    </div>
  </div>
  <app-microcreditsummary (openMoreResult)="openMoreResult($event)" (openMore)="openMore.emit('microattachment')" [reqid]="request_id" [from_where]="1"></app-microcreditsummary>
</section>
<!-- <div class="panel mb-1">
    <div class="panel-body flex align-start">
  
      <div class="section-poster flx-2" style="max-width: none">
        <app-timer [endDate]='endDate'></app-timer>
      </div>
  
      <div class="flex-2 ml-3" style="margin-left:100px !important">
        <div class="label-value-list mb-2">
          <h4 class="section-title mb-5">Features</h4>
          <div class="item">
            <span class="value pl_0 ">Your Credit bureau search put together in one central portal for great conveniences.</span> 
          </div>
          <div class="separator"></div>
          <div class="item">
            <span class="value pl_0">You can check the credit history of defaulters, Awesome.</span> 
          </div>
          <div class="separator"></div>
          <div class="item">
            <span class="value pl_0">You can find out borrowers who are already owing other lenders.</span> 
          </div>
        </div> 
      </div>
    </div>
  </div> -->
<div class="overlay_ app_overlay animated fadeIn" *ngIf="showRerunModal">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="showRerunModal = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <!-- [ngClass]="{'larger': islarger, 'smaller': !islarger}" -->
  <div class="overpanel-content animated zoomIn floating-title tall short" role="document" id="credit_registry">
    <h3 class="title">Credit Search</h3>
    <div class="padding-regular">
      <!-- <img src="" alt="profile image"> -->
      <ul class="decList bigger" *ngIf="view=='crc'">
        <li><span>Bureau</span><span>CRC Credit Bureau</span></li>
        <!-- <li><span>Cost</span><span>#500</span> 
        </li>-->
      </ul>
      <ul class="decList bigger" *ngIf="view=='xds'">
        <li><span>Bureau</span><span>First Central</span></li>
        <!-- <li><span>Cost</span><span>#500</span> 
          </li>-->
      </ul>
      <ul class="decList bigger" *ngIf="view=='cr_services'">
        <li><span>Bureau</span><span>CR Services</span></li>
        <!-- <li><span>Cost</span><span>#500</span> 
            </li>-->
      </ul>
      <div class="mt-4">
        <app-button (click)="requestForCreditBureau()" [disabled]="loading" class="small ml-1" label="Send"
          [loading]="loading"></app-button>
        <app-button (click)="showRerunModal = false" [disabled]="loading" class="small" label="Cancel"></app-button>
      </div>
    </div>
  </div>
</div>