import {Component, Input, OnInit} from '@angular/core';
import {LoansService, OperationsService, StorageService} from '../../_services/index';

@Component({
  selector: 'app-add-guarantor',
  templateUrl: './add-guarantor.component.html',
  styleUrls: ['./add-guarantor.component.css']
})
export class AddGuarantorComponent implements OnInit {
@Input('parentRouteID') parentRouteId: number;
currentUser: any;
loading: boolean = false;
loan: any;
is_done = '0';
items = [];
  guarantors_request = {
    HOW_MANY_GUARANTORS: '',
  }
  constructor(
    private loanService: LoansService,
    private operationsService: OperationsService,
    private storageService: StorageService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }
  sendForGuarantors() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendForGuarantors(this.currentUser.token, this.parentRouteId, this.guarantors_request).then(res => {
      //console.log(res);
    }).catch(err => {
      //console.log(err);
    });
  }

}
