import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-recollectionoptions',
  templateUrl: './recollectionoptions.component.html',
  styleUrls: ['./recollectionoptions.component.css']
})
export class RecollectionoptionsComponent implements OnInit {
  @Input() requestId;
  @Input() loan;
  @Input() embedModal = false;
  @Output() openModalChild = new EventEmitter();
  more = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));

  constructor(
    public dataService: DataService,
    public authService: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  grantRight(right) {
    return this.authService.userHasRights(right);
  }

  openModal(section) {
    if (section === 'setup_cheques') {
      if (!this.grantRight('27')) {
        section = 'no_right';
      }
    }
    if (section === 'setup_cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'account_debit') {
      if (!this.grantRight('94')) {
        section = 'no_right';
      }
    }
    if (section === 'remita_inflight') {
      if (!this.grantRight('81')) {
        section = 'no_right';
      }
    }
    if (this.embedModal) return this.openModalChild.emit(section);
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.requestId, loan: this.loan });
  }

  setDefaultRepaymentMode(loan, repayment_mode) {
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'set_default_repayment_mode', data: this.loan, repayment_mode: repayment_mode });
  }

  acceptContract() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'accept_contract_mod', data: this.loan });
    }
  }

  sendBVN() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'send_bvn_mod', data: this.loan });
    }
  }

  requestCard() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('39')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
    }
  }

  sendContract() {
    if (this.test_mode == '0') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
    } else if (!this.grantRight('47')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }

  requestToGetPaid() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_to_get_paid', data: this.loan });
    }
  }

  requestOthers() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('40')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_others_mod', data: this.loan });
    }
  }

  confirmAccount() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('44')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'confirm_account_mod', data: this.loan });
    }
  }

  requestGuarantors() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('41')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
    }
  }

  sendEmailToCustomer() {
    this.dataService.sendMailToCustomer.emit({ data: this.loan.customer });
  }

  assignLoanOfficer() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('45')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onChangeLoanOfficer.emit({ loan: this.loan });
    }
  }

  deleteRequest() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('46')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'delete_request_mod', data: this.loan });
    }
  }
}
