import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StorageService, AnalyticsService, DataService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sms-view-transactions-modal',
  templateUrl: './sms-view-transactions-modal.component.html',
  styleUrls: ['./sms-view-transactions-modal.component.css']
})
export class SmsViewTransactionsModalComponent implements OnInit {

  @Input() statement: any;
  @Output() cancel = new EventEmitter();
  @Output() done = new EventEmitter();
  @Output() viewResult = new EventEmitter();
  @Input() reqid = '';
  @Input() from_where = '1';
  loading = false;
  password: string;
  currentUser: any;
  transaction_id: string;
  result: any;
  error = null;

  constructor(
    public storageService: StorageService,
    public analyticsService: AnalyticsService,
    public toastr: ToastrService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.statement.smstransactions = JSON.parse(this.statement.smstransactions);
  }

  loadResult() {
    this.viewResult.emit({ ...this.statement, ATTACHMENT_ANALYSIS: this.transaction_id });
  }

  analyze() {
    this.error = null;
    this.loading = true;
    this.analyticsService.analyze(this.statement.FILE_NAME, this.password).subscribe(async (data: any) => {
      this.transaction_id = data.transaction_id;
      this.checkStatus();
    });
  }

  checkStatus(count = 0) {
    if (count === 30) return false;
    this.analyticsService.checkStatus(this.transaction_id).subscribe((data: any) => {
      if (!data.analytics_pdf) return this.checkStatus(++count);
      this.analysisComplete(data);
    }, (err) => {
      this.analysisFailed(err.error && err.error.error);
    })
  }

  analysisFailed(err) {
    this.error = err;
    this.result = null;
    this.loading = false;
  }

  analysisComplete(data) {
    this.result = data;
    this.sendToBackend();
  }

  sendToBackend() {
    this.analyticsService.sendBSAnalysisToBackend({
      token: this.currentUser.token,
      request_id: this.reqid,
      attachment_id: this.statement.ATTACHMENT_ID,
      ...this.result,
      analysis_pdf: this.result.analytics_pdf
    }).subscribe(({ status, data }: any) => {
      if (status) this.done.emit(data);
      this.loading = false;
    }, err => {
      this.analysisFailed('An unknown error occurred, please try again');
    })
  }
}
