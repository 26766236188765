import { Component, Input, OnInit } from '@angular/core';
import { LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  currentUser: any;
  collections :any;
  loading = false;
  @Input() loan:any;
  constructor(
    private loanService: LoansService,
    public storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  } 

  ngOnInit() {
    this.getCollections();
  }

  getCollections() {
    console.log(this.loan)
    this.loading = true;
    if(this.loan){ 
      this.loanService.getVirtualPaymentCollection(this.currentUser.token, this.loan).subscribe(({ data }) => {
        this.loading = false;
        this.collections = data;
        console.log(this.collections,'1');
      }, err => {
        this.loading = false;
      })
    }else{ 
      this.loanService.getPaymentCollections(this.currentUser.token).subscribe(({ data }) => {
        this.loading = false;
        this.collections = data;
        console.log(this.collections,'2');
      }, err => {
        this.loading = false;
      })
    }
  }
}
