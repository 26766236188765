<fieldset class="rating" style="margin-left:25px">
  <input type="radio" [name]="inpustName" value="5" [checked]="rating==='5'" />
  <label title="">5 stars</label>

  <input type="radio" [name]="inpustName" value="4" [checked]="rating==='4'" />
  <label title="">4 stars</label>

  <input type="radio" [name]="inpustName" value="3" [checked]="rating==='3'" />
  <label title="">3 stars</label>

  <input type="radio" [name]="inpustName" value="2" [checked]="rating==='2'" />
  <label title="">2 stars</label>

  <input type="radio" [name]="inpustName" value="1" [checked]="rating==='1'" />
  <label title="">1 star</label>
</fieldset>