<div class="flex">
  <div class="col-md-8 pl_0">
    <div class="row">
      <div class="col-md-12" style="padding-right: 0px">

        <app-homenavigation></app-homenavigation>



        <app-portfoliocalendar [show_for_account_officer]=true></app-portfoliocalendar>



        <div class="panel padding-regular">
          <div class="flex">
            <div class="flex-2">
              <h3 class="section-title mb-5">Staff performance</h3>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Disbursement</div>
                  <div class="subtitle">Total disbursements</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.disbursements | money}}</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Pre Terminations</div>
                  <div class="subtitle">Pre Terminations</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.pre_terminations}}%</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Repayment this month</div>
                  <div class="subtitle">Timely Repayments</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.repaid_this_month | money}}</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Repayments this month</div>
                  <div class="subtitle">Late Repayments</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.late_this_month | money}}</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Collections this month</div>
                  <div class="subtitle">Due Repayments</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.due_this_month | money}}</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Requests</div>
                  <div class="subtitle">NPL</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.bad_loans}}%</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Requests</div>
                  <div class="subtitle">PAR 60</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.bad_loans_}}%</div>
                </div>
              </div>
              <div class="alert border-bottom pl_0 pr_0">
                <div class="texts no-padding">
                  <div class="title">Requests</div>
                  <div class="subtitle">PAR 90</div>
                </div>
                <div class="action">
                  <div class="default-text-size">{{staff_performance_result?.bad_loans__}}%</div>
                </div>
              </div>
            </div>
            <div class="flex-1 flex justify-end">
              <div class="section-poster flex justify-end">
                <div class="image text-right">
                  <img src="assets/images/performance.svg" alt="Hightlight">
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-allinvites></app-allinvites>
      </div>

    </div>
    <!-- <app-openrequest *ngIf="view=='sub'"></app-openrequest> -->
    <!-- <div class="panel mb-1">
        <div class="panel-body flex align-start">
      
          <div class="section-poster flx-2" style="max-width: none">
            <app-timer [endDate]='endDate'></app-timer>
          </div>
      
          <div class="flex-2 ml-3" style="margin-left:100px !important">
            <div class="label-value-list mb-2">
              <h4 class="section-title mb-5">Features</h4>
              <div class="item">
                
                <span class="value pl_0 ">View  requests of your customers</span> 
              </div>
              <div class="separator"></div>
              <div class="item">
                
                <span class="value pl_0">Check your customer schedule</span> 
              </div>
              <div class="separator"></div>
              <div class="item">
                
                <span class="value pl_0">Track your customers portfolio</span> 
              </div>
              <div class="separator"></div>
               
               
      
            </div> 
          </div>
        </div>
      </div> -->
  </div>
  <div class="col-md-4">
    <div *ngIf="!miniSearch && !searchView" class="req_panl animated fadeInDown panel sticky">

      <div class="search-box">
        <input type="text" name="searchText" [(ngModel)]="magic_filter.searchText" #searchText="ngModel"
          (keyup.enter)="searchForLoans()" class="form-control custom" placeholder="Search..">
      </div>

      <div>
        <section infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
          class="item-area no-margin" style="border-top: 0; border-bottom: 0" slimScroll width="auto" height="270px"
          size="7px" *ngIf="!loading">
          <app-item *ngFor="let request of loans?.loans" [active]="activeRequestId === request.REQUEST_ID"
              [request]='request' (loadPortfolio)="loadPortfolio($event)"
              [active]="activeRequestId === request.REQUEST_ID">
            </app-item>

          <div class="h-group center mt-4 empty-state mt-5" *ngIf="!loans?.loans.length">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title no-float">No {{ view_status === 'requests' ? 'requests' : 'approvals' }}</h2>
          </div>

          <div style="padding:10px 0px; text-align:center" *ngIf="adding_more">
            <span><i class="fa fa-spinner fa-spin"></i></span>
          </div>
        </section>
        <section *ngIf="loading">
          <div style="padding:10px 0px; text-align:center">
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
          </div>
        </section>
        <div class="filter-box">
          <div class="flex align-center">
            <button type="button" (click)="miniSearch=true" class="btn btn-block btn-outline-info mr-1"><span
                class="fa fa-filter mr-2"></span>Filter
            </button>
            <button type="button" class="btn btn-outline-info mr-1" (click)="resetFilters()"><i
                class="fa fa-refresh"></i></button>
            <div class="dropup">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                <ng-container *ngFor="let status of statuses; let i=index">
                  <li *ngIf="displayCondition(i)">
                    <a (click)="getLoanLink(status.value)">{{status.display}}</a>
                  </li>
                </ng-container>
                <li class="divider" role="separator">

                </li>
                <ng-container *ngFor="let rmode of rmodes; let i=index">
                  <li>
                    <a (click)="getLoanRepaymentMode(rmode.value)">{{rmode.display}}</a>
                  </li>
                </ng-container>
                <li class="divider" role="separator">

                </li>
                <li>
                  <a (click)="loadMoreRecordsAll(-1)">Show All</a>
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>

      <div class="req_frm animated fadeInUp" *ngIf="miniSearch && !searchView">
        <div class="panel mb-1 pd_15">
          <button class="btn btn-outline-info btn-block" (click)="miniSearch=false">
            <i class="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
        <div id="accordion" role="tablist" class="panel-group filter-items no-margin">
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingPerPaid">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapsePerPaid" aria-expanded="false"
                  aria-controls="collapsePerPaid">
                  <span>Percent Paid</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapsePerPaid" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingPerPaid">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let ms of percentrepaid;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{ms.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="ms.checked" name="ms_{{ms.value}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingportfoliostatuses">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseportfoliostatuses" aria-expanded="false"
                  aria-controls="collapseportfoliostatuses">
                  <span>Repayment Status</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseportfoliostatuses" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingportfoliostatuses">
              <div class="panel-body">
                <select name="portfoliostatuses" class="form-control" [(ngModel)]="magic_filter.portfoliostatuses">
                  <option [value]=''></option>
                  <option *ngFor="let product of portfoliostatuses" [value]="product.value">
                    {{product.display}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingBreaking">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseBreaking" aria-expanded="false"
                  aria-controls="collapseBreaking">
                  <span>Breaking</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseBreaking" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingBreaking">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let sector of breaking;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{sector?.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="sector.value" name="section_{{sector?.value}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingRestructuring">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseRestructuring" aria-expanded="false"
                  aria-controls="collapseRestructuring">
                  <span>Restructuring</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseRestructuring" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingRestructuring">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">Restructured</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="magic_filter.restructured"
                              [checked]="magic_filter.restructured">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default mbot0 edged_box">
            <div class="panel-heading" id="headingRM" role="tab">
              <h4 class="panel-title">
                <a aria-controls="collapseRM" aria-expanded="true" class="" data-parent="#accordion"
                  data-toggle="collapse" href="#collapseRM" role="button">
                  Repayment Mode
                  <span class="pull-right">
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </a>
              </h4>
            </div>
            <div aria-labelledby="headingRM" class="panel-collapse collapse in" id="collapseRM" role="tabpanel"
              aria-expanded="true" style="">
              <div class="panel-body">
                <select (change)="getLoans()" name="magic_filter.repayment_mode" class="form-control"
                  [(ngModel)]="magic_filter.repayment_mode">
                  <option [value]='0'></option>
                  <option [value]='1'>Remita Inflight</option>
                  <option [value]='3'>Direct Debit</option>
                  <option [value]='2'>Cards</option>
                  <option [value]='5'>Cheques</option>
                  <option [value]='4'>Others</option>
                  <option [value]='6'>FG</option>
                  <option [value]='6'>State Govt</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingOne">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <span>Amount</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
              <div class="panel-body">
                <ion-range-slider [from_min]="0" [min]="min_" [max]="max_" (onChange)="update__(simpleSlider_, $event)">
                </ion-range-slider>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <span>Duration</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6" *ngFor="let duration of durations;let i = index" style="">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{duration.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" name="duration_{{duration.value}}"
                              (change)="checkLevel(duration,$event.target.checked,i)">
                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingADays">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseADays" aria-expanded="false" aria-controls="collapseADays">
                  <span>Application Days</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseADays" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingADays">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6" *ngFor="let aday of adays;let i = index" style="">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{aday.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" name="aday_{{aday.value}}" [(ngModel)]="aday.checked">
                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingAges">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseAges" aria-expanded="false" aria-controls="collapseAges">
                  <span>Age Range</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseAges" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingAges">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6" *ngFor="let age of ages;let i = index" style="">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{age.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" name="age_{{age.value}}" [(ngModel)]="age.checked">
                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingFour">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <span>Customer Type</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
              <div class="panel-body">
                <select (change)="getLoans()" name="customer_category" class="form-control"
                  [(ngModel)]="magic_filter.customer_category">
                  <option [value]="0">All</option>
                  <option [value]="1">New</option>
                  <option [value]="2">Repeat</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingRequestCategory">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseRequestCategory" aria-expanded="false"
                  aria-controls="collapseRequestCategory">
                  <span>Request Category</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseRequestCategory" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingRequestCategory">
              <div class="panel-body">
                <select (change)="getLoans_()" name="loan_status" class="form-control"
                  [(ngModel)]="magic_filter.loan_status">
                  <option *ngFor="let status of statuses" [value]="status.value">{{status.display}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingFunding">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseFunding" aria-expanded="false"
                  aria-controls="collapseFunding">
                  <span>Funding</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseFunding" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFunding">
              <div class="panel-body">
                <select name="peer_to_peer" class="form-control" [(ngModel)]="magic_filter.peer_to_peer">
                  <option [value]=""></option>
                  <option [value]="0">Not Peer To Peer</option>
                  <option [value]="1">Peer To Peer</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingMS">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseMS" aria-expanded="false" aria-controls="collapseMS">
                  <span>Marital Status</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseMS" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingMS">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let ms of marital_statuses;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{ms.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="ms.checked" name="ms_{{ms.value}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingOccupation">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseOccupation" aria-expanded="false"
                  aria-controls="collapseOccupation">
                  <span>Occupation</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseOccupation" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingOccupation">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let occupation of occupations;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{occupation?.DESCRIPTION}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="occupation.checked" [checked]="occupation.checked"
                              name="occupation_{{occupation?.OCCUPATION_ID}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingSector">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseSector" aria-expanded="false" aria-controls="collapseSector">
                  <span>Sector</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseSector" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSector">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let sector of sectors;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{sector?.DESCRIPTION}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="sector.checked" [checked]="sector.checked"
                              name="section_{{sector?.OCCUPATION_ID}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingDesignation">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseDesignation" aria-expanded="false"
                  aria-controls="collapseDesignation">
                  <span>Designations</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseDesignation" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingDesignation">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let designation of designations;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{designation?.DESCRIPTION}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="designation.checked" [checked]="designation.checked"
                              name="designation_{{designation?.OCCUPATION_ID}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingIncome">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseIncome" aria-expanded="false" aria-controls="collapseIncome">
                  <span>Income</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseIncome" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingIncome">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6" *ngFor="let income of incomes;let i = index" style="">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{income.display}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" name="income_{{income.value}}" [(ngModel)]="income.checked">
                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingFive">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  <span>Companies</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
              <div class="panel-body">
                <select (change)="getLoans()" name="company_id" class="form-control"
                  [(ngModel)]="magic_filter.company_id">
                  <option [value]='-1'>All</option>
                  <option [value]='0'>Individual</option>
                  <option *ngFor="let company of companies?.a" [value]="company.PEOPLE_CUSTOMERS_ID">
                    {{company.LEGAL_NAME}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default" *ngIf="brances">
            <div class="panel-heading" role="tab" id="headingBranch">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseBranch" aria-expanded="false" aria-controls="collapseBranch">
                  <span>Branches</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseBranch" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingBranch">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let branch of branches;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{branch?.branch_name}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="branch.checked" [checked]="branch.checked"
                              name="branch_{{branch?.branch_id}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default" *ngIf="products?.count > 0">
            <div class="panel-heading" role="tab" id="headingSix">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  <span>Products</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
              <div class="panel-body">
                <select (change)="getLoans()" name="product_id" class="form-control"
                  [(ngModel)]="magic_filter.product_id">
                  <option [value]='-1'>All</option>
                  <option *ngFor="let product of products?.products" [value]="product.LOAN_PRODUCT_ID">
                    {{product.LOAN_TITLE}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default" *ngIf="branches?.count > 0">
            <div class="panel-heading" role="tab" id="headingBranches">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseBranches" aria-expanded="false"
                  aria-controls="collapseBranches">
                  <span>Products</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseBranches" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingBranches">
              <div class="panel-body">
                <select (change)="getLoans()" name="branch_id" class="form-control"
                  [(ngModel)]="magic_filter.branch_id">
                  <option [value]='-1'>All</option>
                  <option *ngFor="let branch of branches?.branches" [value]="branch.branch_id">
                    {{branch.branch_name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel panel-default" *ngIf="state">
            <div class="panel-heading" role="tab" id="headingSOR">
              <h4 class="panel-title">
                <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                  data-parent="#accordion" href="#collapseSOR" aria-expanded="false" aria-controls="collapseSOR">
                  <span>State of Origin</span><button class="btn btn-link btn-sm text-muted"><i
                      class="fa fa-chevron-down"></i></button>
                </a>
              </h4>
            </div>
            <div id="collapseSOR" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSOR">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12" *ngFor="let state of states;let i = index"
                    style="padding-right:5px !important">
                    <div class="form-group" style="margin-bottom:0px">
                      <span class="pull-left text-primary">{{state?.STATE_ID}}</span>
                      <span class="pull-right">
                        <div class="checkbox" style="margin:0px !important">
                          <label>
                            <input type="checkbox" [(ngModel)]="state.checked" [checked]="state.checked"
                              name="state_{{state?.STATE_ID}}">

                          </label>
                        </div>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-buttons">
          <app-button (click)="getLoans()" [loading]="loading" [disabled]="loading" class="medium block"
            label="Apply Filter"></app-button>
        </div>
      </div>

    </div>

    <!-- Display Filter - mini -->
    <div class="req_frm animated fadeInUp" *ngIf="miniSearch && !searchView">
      <div class="panel mb-1 pd_15">
        <button class="btn btn-outline-info btn-block" (click)="miniSearch=false"><i class="fa fa-arrow-left mr-2"></i>
          Back
        </button>
      </div>

      <div id="accordion" role="tablist" class="panel-group filter-items no-margin">
        <div class="panel panel-default" *ngIf="products">
          <div class="panel-heading" role="tab" id="headingSix">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span>Products</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
            <div class="panel-body">
              <select (change)="getLoans()" name="product_id" class="form-control"
                [(ngModel)]="magic_filter.product_id">
                <option [value]='-1'>All</option>
                <option *ngFor="let product of products?.products" [value]="product.LOAN_PRODUCT_ID">
                  {{product.LOAN_TITLE}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOne">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span>Amount</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            <div class="panel-body">
              <ion-range-slider [from_min]="0" [min]="min_" [max]="max_" (onChange)="update__(simpleSlider_, $event)">
              </ion-range-slider>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingTwo">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span>Duration</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let duration of durations;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{duration.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="duration_{{duration.value}}"
                            (change)="checkLevel(duration,$event.target.checked,i)">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingADays">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseADays" aria-expanded="false" aria-controls="collapseADays">
                <span>Application Days</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseADays" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingADays">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let aday of adays;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{aday.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="aday_{{aday.value}}" [(ngModel)]="aday.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default mbot0 edged_box">
          <div class="panel-heading" id="headingRM" role="tab">
            <h4 class="panel-title">
              <a aria-controls="collapseRM" aria-expanded="true" class="" data-parent="#accordion"
                data-toggle="collapse" href="#collapseRM" role="button">
                Repayment Mode
                <span class="pull-right">
                  <i class="fa fa-chevron-down"></i>
                </span>
              </a>
            </h4>
          </div>
          <div aria-labelledby="headingRM" class="panel-collapse collapse in" id="collapseRM" role="tabpanel"
            aria-expanded="true" style="">
            <div class="panel-body">
              <select (change)="getLoans()" name="magic_filter.repayment_mode" class="form-control"
                [(ngModel)]="magic_filter.repayment_mode">
                <option [value]='0'></option>
                <option [value]='-1'>All</option>
                <option [value]='1'>Remita Inflight</option>
                <option [value]='6'>FG</option>
                <option [value]='6'>State Govt</option>
                <option [value]='-1'>Others</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingAges">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseAges" aria-expanded="false" aria-controls="collapseAges">
                <span>Age Range</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseAges" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingAges">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let age of ages;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{age.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="age_{{age.value}}" [(ngModel)]="age.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFour">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span>Customer Type</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
            <div class="panel-body">
              <select (change)="getLoans()" name="customer_category" class="form-control"
                [(ngModel)]="magic_filter.customer_category">
                <option [value]="0">All</option>
                <option [value]="1">New</option>
                <option [value]="2">Repeat</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingRequestCategory">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseRequestCategory" aria-expanded="false"
                aria-controls="collapseRequestCategory">
                <span>Request Category</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseRequestCategory" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingRequestCategory">
            <div class="panel-body">
              <select (change)="getLoans_()" name="loan_status" class="form-control"
                [(ngModel)]="magic_filter.loan_status">
                <option *ngFor="let status of statuses" [value]="status.value">{{status.display}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFunding">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFunding" aria-expanded="false" aria-controls="collapseFunding">
                <span>Funding</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFunding" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFunding">
            <div class="panel-body">
              <select name="peer_to_peer" class="form-control" [(ngModel)]="magic_filter.peer_to_peer">
                <option [value]=""></option>
                <option [value]="0">Not Peer To Peer</option>
                <option [value]="1">Peer To Peer</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingMS">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseMS" aria-expanded="false" aria-controls="collapseMS">
                <span>Marital Status</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseMS" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingMS">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let ms of marital_statuses;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{ms.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="ms.checked" name="ms_{{ms.value}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOccupation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseOccupation" aria-expanded="false"
                aria-controls="collapseOccupation">
                <span>Occupation</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseOccupation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingOccupation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let occupation of occupations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{occupation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="occupation.checked" [checked]="occupation.checked"
                            name="occupation_{{occupation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSector">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSector" aria-expanded="false" aria-controls="collapseSector">
                <span>Sector</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSector" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSector">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let sector of sectors;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{sector?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="sector.checked" [checked]="sector.checked"
                            name="section_{{sector?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingDesignation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseDesignation" aria-expanded="false"
                aria-controls="collapseDesignation">
                <span>Designations</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseDesignation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingDesignation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let designation of designations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{designation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="designation.checked" [checked]="designation.checked"
                            name="designation_{{designation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingIncome">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseIncome" aria-expanded="false" aria-controls="collapseIncome">
                <span>Income</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseIncome" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingIncome">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let income of incomes;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{income.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="income_{{income.value}}" [(ngModel)]="income.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFive">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span>Companies</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
            <div class="panel-body">
              <select (change)="getLoans()" name="company_id" class="form-control"
                [(ngModel)]="magic_filter.company_id">
                <option [value]='-1'>All</option>
                <option [value]='0'>Individual</option>
                <option *ngFor="let company of companies?.a" [value]="company.PEOPLE_CUSTOMERS_ID">
                  {{company.LEGAL_NAME}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingStates">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseStates" aria-expanded="false" aria-controls="collapseStates">
                <span>States</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseStates" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingStates">
            <div class="panel-body">
              <select (change)="getLoans()" name="state_id" class="form-control" [(ngModel)]="magic_filter.state_id">
                <option [value]='-1'>All</option>
                <option *ngFor="let state of states" [value]="state.state_id">
                  {{state.state_name}}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- <div class="panel panel-default" *ngIf="brances">
          <div class="panel-heading" role="tab" id="headingBranch">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseBranch" aria-expanded="false" aria-controls="collapseBranch">
                <span>Branches</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseBranch" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingBranch">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let branch of branches;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{branch?.branch_name}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="branch.checked" [checked]="branch.checked"
                            name="branch_{{branch?.branch_id}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="panel panel-default" *ngIf="state">
          <div class="panel-heading" role="tab" id="headingSOR">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSOR" aria-expanded="false" aria-controls="collapseSOR">
                <span>State of Origin</span>
                <button class="btn btn-link btn-sm text-muted">
                  <i class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSOR" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSOR">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let state of states;let i = index" style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{state?.STATE_ID}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="state.checked" [checked]="state.checked"
                            name="state_{{state?.STATE_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-buttons">
        <app-button (click)="getLoans()" [loading]="loading" [disabled]="loading" class="medium block"
          label="Apply Filter"></app-button>
      </div>
    </div>
  </div>
</div>