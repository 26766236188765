import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { DataService } from '../app/_services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  loadingRouteConfig: boolean;
  showMobile = false;

  constructor(private router: Router, private dataService: DataService) {
    const { width } = window.screen;
    this.showMobile = width < 800;
    if (width <= 1024 && width > 800) document.body.classList.add('zoom-out');
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: true });
      } else if (event instanceof RouteConfigLoadEnd) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: false });
      }
    });
  }
}
