<app-contractheader [loan]="loan" [request_id]="loan?.request_id"></app-contractheader>
<app-loanaction [view]="view" [loan_]="loan" [request_id]="loan?.request_id">
</app-loanaction>
<app-applicationscontract [view_from]="view"></app-applicationscontract>
<section class="panel">
    <div class="panel-body">
        <section class="mb-1 grid four">
            <div class="decIcon panel" (click)="openModal('bvn')">
              <p class="icon color-teal"><span class="fas fa-fingerprint"></span></p>
              <p class="text">BVN</p>
            </div>
            <div class="decIcon panel" (click)="openModal('cards')">
              <p class="icon color-orange"><span class="fa fa-credit-card"></span></p>
              <p class="text">Cards</p>
            </div>
            <div class="decIcon panel" (click)="openModal('accounts')">
              <p class="icon color-blue"><span class="fas fa-user-alt"></span></p>
              <p class="text">Account</p>
            </div>
            <div class="decIcon panel" (click)="openModal('documents')" *ngIf="grantRight('28')">
              <p class="icon color-yellow"><span class="fa fa-folder"></span></p>
              <p class="text">Attachment</p>
            </div>
          </section>
    </div>
</section>
<app-applicationstimeline [view_from]="view"></app-applicationstimeline>

