<div class="section-title mb-4 cursor-pointer" (click)="close.emit()">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>
<!-- <div class="row" *ngIf="loading">
  <div class="col-md-7 pr_0">
    <div class="panel padding-regular fill-w skeleton" style="min-height: 100px;"></div>
  </div>
  <div class="col-md-5">
    <div class="panel padding-regular fill-w skeleton" style="min-height: 100px;"></div>
  </div>
</div> -->

<div>
  <div>

    <div>
      <div class="table-heading">
        <h4>Customer Details</h4>
      </div>
      <table class="table table-sm table-bordered table-striped">
        <tr>
          <th></th>
          <th></th> 
        </tr> 
        <tr>
          <td>BVN</td>
          <td>{{ summary?.CREDITCHECK_DATA?.Bvn }}</td> 
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>{{ summary?.CREDITCHECK_DATA?.DateOFBirth }}</td> 
        </tr>
        <tr>
          <td>Deliquency</td>
          <td>{{ summary?.CREDITCHECK_DATA?.Delinquency }}</td> 
        </tr>
        <tr>
          <td>Fullname</td>
          <td>{{ summary?.CREDITCHECK_DATA?.FullName }}</td> 
        </tr>
        <tr>
          <td>Gender</td>
          <td>{{ summary?.CREDITCHECK_DATA?.Gender }}</td> 
        </tr>
        <tr>
          <td>Is Loan</td>
          <td>{{ summary?.CREDITCHECK_DATA?.IsLoan }}</td> 
        </tr>
        <tr>
          <td>Last Updated Date</td>
          <td>{{ summary?.CREDITCHECK_DATA?.LastUpdatedDate }}</td> 
        </tr>
        <tr>
          <td>Linked Accounts</td>
          <td>{{ summary?.CREDITCHECK_DATA?.LinkedAccounts }}</td> 
        </tr>
        <tr>
          <td>Linked Emails</td>
          <td>{{ summary?.CREDITCHECK_DATA?.LinkedEmail }}</td> 
        </tr>
        <tr>
          <td>Loan Amounts</td>
          <td>{{ summary?.CREDITCHECK_DATA?.LoanAmounts }}</td> 
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>{{ summary?.CREDITCHECK_DATA?.Phonenumber }}</td> 
        </tr>
        <tr>
          <td>Score</td>
          <td>{{ summary?.CREDITCHECK_DATA?.Score }}</td> 
        </tr>
      </table>

      <div class="table-heading">
        <h4 class="bg-red">Loan Highlight</h4>
      </div>
      <table class="table table-sm table-bordered table-striped">
        <tr>
          <th>Acco Bal</th>
          <th>App Amount</th>
          <th>Prod ID</th>
          <th>Dis Cheques</th>
          <th>Fac Count</th>
          <th>Ins Name</th>
          <th>Non Perf Fac</th>
          <th>Overdue Amt</th>
          <th>Perf Facility</th>
 
        </tr>
        <tr *ngFor="let loan of summary?.CREDITCHECK_DATA?.PerformanceSummary">
          <td>{{loan.ACCOUNT_BALANCE}}</td>
          <td>{{ loan.APPROVED_AMOUNT }}</td>
          <td>{{ loan.DATA_PRDR_ID }}</td>
          <td>{{ loan.DISHONORED_CHEQUES_COUNT }}</td>
          <td>{{ loan.FACILITIES_COUNT }}</td>
          <td>{{ loan.INSTITUTION_NAME }}</td>
          <td>{{ loan.NONPERFORMING_FACILITY }}</td>
          <td>{{ loan.OVERDUE_AMOUNT }}</td>
          <td>{{ loan.PERFORMING_FACILITY }}</td>
        </tr>
         
      </table>

      
    </div> 
  </div> 
</div>