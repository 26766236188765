<div *ngIf="view=='request'  && lender?.PEOPLE_ID !='40245'">
  <div *ngIf="loan?.IS_REJECTED=='1'">
    <button type="button" (click)="confirmReopenRequest()"
      class="btn btn-warning btn-block edged_box btn-orange wd_btn mr-1">Reopen Request</button>
  </div>

  <div>
    <div *ngIf="queue_disbursement=='2'&&loan?.IS_CANCELLED=='0'&&loan?.IS_REJECTED=='0'">
      <app-button (click)="approveRequest()" class="small green mb-1 mr-1" label="Approve"></app-button>
      <app-button (click)="confirmRejectRequest()" class="small danger outline mr-1" label="Reject"></app-button>
    </div>
    <div *ngIf="queue_disbursement=='1'&&loan?.IS_CANCELLED=='0' && canDisburse">
      <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP=='0'">
        <app-button class="small mr-1" [loading]="loading" label="Queue for Disbursement"
          *ngIf="pay_from_loan!='1' && loan?.ADDED_TO_PAYMENT_QUEUE != '1'" (click)="queueForDisbursement()">
        </app-button>
        <app-button class="default small mb-1 mr-1" label="Pay" *ngIf="pay_from_loan==='1'"
          (click)="payBorrowerFromLoan()">
        </app-button>
        <app-button class="danger small outline ml-1 mr-1" label="Cancel Pay" *ngIf="pay_from_loan==='1'"
          (click)="confirmRejectRequest()"></app-button>
      </div>
      <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP!='0'">
        <app-button class="default small mb-1 mr-1" label="Approve  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}"
          (click)="payBorrowerFromLoan()"></app-button>
        <app-button class="danger small outline mr-1" label="Cancel  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}"
          (click)="confirmRejectRequest()"></app-button>

      </div>
      <div *ngIf="loan?.PENDING_WALLET_DISBURSE==1">
        <app-button (click)="doCheckWalletTransactionStatus()" label="Check transaction" class="small mr-1"
          [loading]="loading" [disabled]="loading"></app-button>
      </div>
      <!-- <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE!='4'  && canDisburse && lender?.PEOPLE_ID =='40245'"
        (click)="queueForPartners()" class="small green mb-1 mr-1" label="Queue For Partners"></app-button> -->
      <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE=='4'  && canDisburse && lender?.PEOPLE_ID =='40245'"
        (click)="unqueueForPartners()" class="small green mb-1 mr-1" label="Unqueue For Partners"></app-button>

      <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE!='4'  && canDisburse && lender?.PEOPLE_ID =='40245'"
        (click)="createPartnerRequest()" class="small green mb-1 mr-1" label="Create Partner Request"></app-button>
      <app-button *ngIf="lender?.PEOPLE_ID =='40245'" (click)="rerunEligibility()" [loading]="eliloading"
        class="small green mb-1 mr-1" label="Rerun Eligibility"></app-button>
      <app-button *ngIf="lender?.PEOPLE_ID =='40245'" (click)="initiatestatement()" [loading]="loading_statement"
        class="small green mb-1 mr-1" label="Initiate Statement"></app-button>
      <p *ngIf="statement_message !='' && lender?.PEOPLE_ID =='40245'">{{statement_message}}</p>
      
    </div>

  </div>
</div>
<div *ngIf="view=='approvals'">
  <div class="panel box decSection edged_box mb_10">
    <div class="panel-body flex justify-between align-center">
      <div class="user">
        <div style="width: 50px !important; height: 50px !important" class="image" (click)="showProfilePic()">
          <img style="width: 50px !important; height: 50px !important"
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.FILE_NAME | imagenotfound}}">
        </div>
        <div class="content">
          <div class="title">Customer's name here</div>
        </div>
      </div>

      <!-- <app-button (click)="takeOwnership(loan?.customer)" *ngIf="loan?.customer?.account_officer ==''"
        class="medium outline" label="Assign Officer" type="button"></app-button>
      <app-button (click)="changeOwnership(loan?.customer)" *ngIf="loan?.customer?.account_officer!=''"
        class="medium outline" label="Change Officer" type="button"></app-button> -->


      <!-- 
        <div *ngIf="loan?.IS_REJECTED=='1'">
          <button type="button" (click)="confirmReopenRequest()"
            class="btn btn-warning btn-block edged_box btn-orange wd_btn">Reopen Request</button>
        </div> -->

      <div>
        <div *ngIf="queue_disbursement=='2'&&loan?.IS_CANCELLED=='0'&&loan?.IS_REJECTED=='0'">
          <app-button (click)="approveRequest()" class="small mb-1" label="Approve"></app-button>
          <app-button (click)="confirmRejectRequest()" class="small danger outline ml-1" label="Reject"></app-button>
        </div>
        <div *ngIf="queue_disbursement=='1'&&loan?.IS_CANCELLED=='0' && canDisburse">
          <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP=='0'">
            <app-button class="small" label="Queue for Disbursement"
              *ngIf="pay_from_loan!='1' && loan?.ADDED_TO_PAYMENT_QUEUE != '1'" (click)="queueForDisbursement()">
            </app-button>
            <app-button class="default small" label="Pay" *ngIf="pay_from_loan==='1'" (click)="payBorrowerFromLoan()">
            </app-button>
            <app-button class="danger small outline ml-1" label="Cancel Pay" *ngIf="pay_from_loan==='1'"
              (click)="confirmRejectRequest()"></app-button>
          </div>
          <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP!='0'">
            <!-- <app-button class="small" label="Queue {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}" *ngIf="pay_from_loan!='1' && loan?.ADDED_TO_PAYMENT_QUEUE != '1'"
                (click)="queueForDisbursement()"></app-button>  -->
            <app-button class="default small mb-1" label="Approve  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}"
              (click)="payBorrowerFromLoan()">
            </app-button>
            <app-button class="danger small outline ml-1"
              label="Cancel  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}" (click)="confirmRejectRequest()">
            </app-button>

          </div>
          <div *ngIf="loan?.PENDING_WALLET_DISBURSE==1">
            <app-button (click)="doCheckWalletTransactionStatus()" label="Check transaction" class="small"
              [loading]="loading" [disabled]="loading"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="view=='request' && lender?.PEOPLE_ID =='40245'">
  <div>
    <div>

      
      <div *ngIf="queue_disbursement=='1'&&loan?.IS_CANCELLED=='0'" style="margin: 10px 10px 10px 0px;">
        <!-- <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE!='4'  && canDisburse" (click)="queueForPartners()"
          class="small green mb-1 mr-1" label="Queue For Partners"></app-button> -->
        <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE=='4'  && canDisburse" (click)="unqueueForPartners()"
          class="small green mb-1 mr-1" label="Unqueue For Partners"></app-button>

        <app-button *ngIf="loan?.PENDING_WALLET_DISBURSE!='4'  && canDisburse" (click)="createPartnerRequest()"
          class="small green mb-1 mr-1" label="Create Partner Request"></app-button>

        <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP=='0' && loan?.DISBURSED=='0'">
          <app-button class="small" label="Queue for Disbursement" *ngIf="pay_from_loan!='1' && loan?.DP != '1'"
            (click)="queueForDisbursement()">
          </app-button>
        </div>
        <div *ngIf="loan?.PENDING_WALLET_DISBURSE==1 && canDisburse">
          <app-button (click)="doCheckWalletTransactionStatus()" label="Check transaction" class="small"
            [loading]="loading" [disabled]="loading"></app-button>
        </div>
        <div *ngIf="loan?.DISBURSED=='1'">
          <h1 style="color:red">DISBURSED</h1>
          <div *ngIf="canDisburse">
            <app-button (click)="moveToPortfolio()" label="Move To Portfolio" class="small"
              [loading]="loading" [disabled]="loading"></app-button>
          </div>
        </div>
        <div *ngIf="loan?.PENDING_WALLET_DISBURSE!=1 && loan?.IS_TOP_UP!='0'">
          <app-button class="default small mb-1 mr-1" label="Approve  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}"
            (click)="payBorrowerFromLoan()"></app-button>
          <app-button class="danger small outline mr-1" label="Cancel  {{loan?.IS_TOP_UP=='8' ? 'Top Up' : 'Rollover'}}"
            (click)="confirmRejectRequest()"></app-button>
  
        </div>
      </div>

      <app-button (click)="rerunEligibility()" [loading]="eliloading" class="small green mb-1 mr-1"
        label="Rerun Eligibility"></app-button>
      <app-button (click)="initiatestatement()" [loading]="loading_statement" class="small green mb-1 mr-1"
        label="Initiate Statement"></app-button>
      <p *ngIf="statement_message !=''">{{statement_message}}</p>
    </div>
  </div>
</div>
<!-- <app-button (click)="pushToPartners()" class="small green mb-1 mr-1" label="Push To Partners"></app-button>  -->