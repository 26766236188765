import { Component, OnInit } from '@angular/core';
import { DataService, OperationsService, StorageService } from '../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nibbsmandate',
  templateUrl: './nibbsmandate.component.html',
  styleUrls: ['./nibbsmandate.component.css']
})
export class NibbsmandateComponent implements OnInit {

  currentUser:any;
  loading = false;
  data:any;
  start = '0';
  loan_product_id = '';
  products:any;
  loadingRouteConfig = false;
  constructor(
    public toastr: ToastrService,
    public router: Router,
    private dataService: DataService, private operationsService: OperationsService, private storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.loadingRoute.subscribe(res => {
      this.loadingRouteConfig = res.loadingRouteConfig;
    });
  }

  ngOnInit() {
    this.getRecords();
  }
  checkStatus(record){
    
  }
  getRecords(){
    this.loading = true;
    this.operationsService.getNibbsInstantMandate(this.currentUser.token)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        // this.start = result.start;
        // this.products = result.products;
      });
  }
  getRecords_(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getNibbsInstantMandate(this.currentUser.token)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start;
        this.products = result.products;
      });
  }
  getTotal(key, schedule) {
    if (schedule === undefined || schedule === null) { } else {
      let total = 0;
      if (key === 'request_principal') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.request_principal); }, 0);
      }
      if (key === 'principal_paid') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_paid); }, 0);
      }
      if (key === 'principal_balance') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_balance); }, 0);
      }
      if (key === 'principal_due') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_due){return cnt + parseInt(o.principal_due);} }, 0);
      }
      if (key === 'principal_arrears') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_arrears){return cnt + parseInt(o.principal_arrears);} }, 0);
      }
      
      return total;
    }
    //

  }
}
