import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  DataService,
  LoansService,
  OperationsService,
  OptionsserviceService,
  StorageService
} from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-applicationscontract',
  templateUrl: './applicationscontract.component.html',
  styleUrls: ['./applicationscontract.component.css', './style.css', './boot-outline-btn.css']
})
export class ApplicationscontractComponent implements OnInit {
  default_loan: any;
  @Input('view_from') view_from = 'contract';
  currentUser: any;
  parentRouteId: any;
  sub: any;
  loan: any;
  docpickups: any;
  creating_contract = false;
  view = 'cheques';
  showPicture = false;
  showActionsModal = false;
  showAccountConfirmation = false;
  loading = false;
  createdContract = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  showTestModeModal = false;
  isAllowed: boolean;
  constructor(private DataService: DataService,
    public toastr: ToastrService, public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public authService: AuthenticationService,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.DataService.requestLoad.subscribe(res => {
      this.creating_contract = false;
      this.parentRouteId = res.request_id;
      this.getSummary();
    })
    this.DataService.acceptingContractIsDone.subscribe(res => {
      this.getSummary();
    })
    this.DataService.refreshRequestList.subscribe(res => {
      this.getSummary();
    })
    
  }
  doInvest() {
    this.loading = true;
    this.loansService.doInvest(this.currentUser.token, this.loan)
      .subscribe(res => {
        this.loading = false;
        this.createdContract = true;
        this.showSuccess('Contract Update');
        this.dataService.contractHasBeenCreated.emit();
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }
  
  showError(message) {
    this.toastr.error(message, 'Error');
  }
  showProfilePic(pic) {
    this.dataService.showProfilePic.emit({ pic: pic });
  }
  creatingContract() {
    this.creating_contract = true;
  }
  editingContract() {
    this.DataService.openContractModal.emit({ currentUser: this.currentUser, request_id: this.parentRouteId, loan: this.loan });
  }
  openUnderConstruction() {
    this.DataService.openUnderConstruction.emit({ endDate: '1561849200000' });
  }
  openContractModal() {
    if (this.test_mode == '0') {
      this.DataService.openContractModal.emit({ currentUser: this.currentUser.token, request_id: this.parentRouteId, loan: this.loan });
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  modifyContractModal() {
    this.DataService.modifyContractModal.emit({ currentUser: this.currentUser.token, request_id: this.parentRouteId, loan: this.loan });
  }
  openContractConfirm() {
    this.DataService.openContractConfirm.emit({ currentUser: this.currentUser.token, request_id: this.parentRouteId, loan: this.loan, request_step: '5', });
  }
  ngOnInit() {
    this.getSummary();
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  getSummary() {
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.getLoan();
    });

  }
  getLoan() {
    this.loan = this.default_loan;
    this.creating_contract = false;
    this.loading = true;
    this.loansService.retrieveLoanDetails(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loading = false;
        this.loan = data.loan;
        //a this.dataService.onRequestLoadingEndFromBackend.emit({loan:this.loan});
      });
  }
  saveApplicationChecklist(event) {
    this.loading = true;
    this.loansService.saveProductChecklists(this.currentUser.token, event.product_checklist, this.parentRouteId)
      .subscribe(data => {
        this.loading = false;
        if (data.status === true) {
          this.showSuccess(data.message)
        } else {
          this.showError(data.message)
        }
      });
  }
  openProfile(request_id) {
    this.router.navigate(['./applications/profile/' + request_id]);
  }
  openGuarantors(request_id) {
    this.router.navigate(['./applications/guarantors/' + request_id]);
  }
  openLoanSpider(request_id) {
    if (this.test_mode == '1') {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.router.navigate(['applications/analytics/' + request_id + '/spider']);
    }
  }
  opentimeline(request_id) {
    this.router.navigate(['./applications/timeline/' + request_id]);
  }
  openAnalytics(request_id) {
    this.router.navigate(['./applications/analytics/' + request_id]);
  }
  openCreditCheck(request_id) {
    this.router.navigate(['./applications/creditcheck/' + request_id]);
  }
  openBankStatement(request_id) {
    this.router.navigate(['./applications/bankstatements/' + request_id]);
  }
  openP2P(request_id) {
    this.router.navigate(['./applications/p2p/' + request_id]);
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.DataService.disbursingRequest.emit(event);
  }
  openPreviousRequests(request_id) {
    this.router.navigate(['./applications/requests/' + request_id]);
    //this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan });
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  openModal(section) {
    if (section === 'setup_cheques') {
      if (!this.grantRight('27')) {
        section = 'no_right';
      }
    }
    if (section === 'setup_cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'account_debit') {
      if (!this.grantRight('94')) {
        section = 'no_right';
      }
    }
    if (section === 'remita_inflight') {
      if (!this.grantRight('81')) {
        section = 'no_right';
      }
    }
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan });

  }
  acceptContract() {
    if (this.test_mode == '1') {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'accept_contract_mod', data: this.loan });
    }
  }
  requestFinancials() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_financial_mod', data: this.loan });
  }
  requestOthers() { 
   
    if (this.test_mode == '0') {
      if(!this.grantRight('40')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_others_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  requestGuarantors() {
    
    if (this.test_mode == '0') {
      if(!this.grantRight('41')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  requestToGetPaid() {
    if (this.test_mode == '1') {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_to_get_paid', data: this.loan });
    }
  }

  requestKYC() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_kyc_mod', data: this.loan });
  }

  requestDocs() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_docs_mod', data: this.loan, docpickups: this.docpickups });
  }

  deleteRequest() {
    
    if (this.test_mode == '0') {
      if(!this.grantRight('46')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'delete_request_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  sendBVN() {
    if (this.test_mode == '1') {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_bvn_mod', data: this.loan });
    }
  }

  requestCard() {
  
    if (this.test_mode == '0') {
      if(!this.grantRight('39')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }

  sendContract() {
    if (this.test_mode == '0') {
      if(!this.grantRight('47')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }

  }

  confirmAccount() {
 
    if (this.test_mode == '0') {
      if(!this.grantRight('44')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'confirm_account_mod', data: this.loan });
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  sendEmailToCustomer() {
    this.DataService.sendMailToCustomer.emit({ customer_id: this.loan.people_id, is_multiple: false });
  }
  setupdirectdebit() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'setup_debit_mandate', data: this.loan });
  }
  checkdirectdebitstatus() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'check_debit_mandate', data: this.loan });
  }
  assignLoanOfficer() {
   

    if (this.test_mode == '0') {
      if(!this.grantRight('45')){
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }else{
        //.DataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
        this.DataService.onChangeLoanOfficer.emit({loan: this.loan});
      }
      
    } else {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }
  setDefaultRepaymentMode(loan, repayment_mode) {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'set_default_repayment_mode', data: this.loan, repayment_mode: repayment_mode });
  }
  takeOwnership(customer) {
    if (!this.grantRight('3')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.DataService.onTakeOwnership.emit(customer);
    }
    //this.action = '1';
    //this.ownershipCustomer = customer;
    //this.owningStaff = this.currentUser;


  }
  changeOwnership(customer) {
    if (!this.grantRight('3')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.DataService.onchangeOwnership.emit(customer);
    }
    //this.ownershipCustomer = customer;
    // this.owningStaff = this.currentUser;

  }
  makeOfferForMarketLoan(event){
    this.DataService.makeOfferForLoan.emit(event);

  }
}
