<div class="list-item" (click)="openLoan(request)" [ngClass]="{'active': active}">
  <div class="image ring" onerror="this.src='assets/img/avatar-mini.png';" [ngClass]="{'green': request?.due <= 0, 
    'red': request?.due > 0, 'skeleton rounded': loading}">
    <img onerror="this.src='assets/img/avatar-mini.png';"
      src="{{request?.FILE_NAME | imagenotfound}}">
  </div>
  <div class="body">
    <h3 class="title ellipsize" [ngClass]="{'skeleton': loading}">{{request?.REQUEST_PRINCIPAL | money}} |
      {{request?.REQUEST_TENOR}}{{request?.LOAN_DURATION}}({{request?.REQUEST_ID}})</h3>
    <h5 class="subtitle ellipsize" [ngClass]="{'hidden': loading}">{{request?.LEGAL_NAME}}</h5>
    <p [ngClass]="{'skeleton mt-1': loading}">Amount due: {{request?.DUE | money}}</p>
    <div *ngIf="request?.LOAN_STATUS=='3'" class="progress mt-2 mb-1" style="height: 4px"
      [ngClass]="{'hidden': loading}">
      <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100"
        [ngStyle]="{'width': request?.PERCENT_PAID+'%'}"
        [ngClass]="{'progress-bar-primary': request?.PERCENT_PAID >= 100, 'progress-bar-danger': request?.PERCENT_PAID < 100}">
      </div>
      <!-- <span class="sr-only">{{request?.PERCENT_PAID}}% Complete</span> -->
    </div>
  </div>
  <div class="align-end flex flex-column icons justify-between">
    <!-- <span class="icon" *ngIf="!request_loading"><i class="fa fa-bell"></i></span> -->
    <!-- <span class="icon" *ngIf="request_loading"><i class="fa fa-spinner fa-spin"></i></span> -->
    <!-- <span *ngIf="request?.LOAN_STATUS!='3'"
      [ngClass]="{'skeleton fit-content': loading}">{{request?.TIME_SPENT}}d</span> -->
    <div *ngIf="request?.REPAYMENT_MODE == '1'" class="remita" [ngClass]="{'hidden': loading}">
      <img src="assets/images/remita.svg" alt="Remita Logo">
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '2'" class="medium-text-size color-blue icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/creditcard-icon.svg" alt="Credit card Icon"> -->
      <i class="fas fa-credit-card"></i>
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '3'" class="medium-text-size color-teal icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/debit.svg" alt="Direct debit Icon"> -->
      <i class="fas fa-hand-holding-usd"></i>
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '4'" class="medium-text-size color-green icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/cheque.svg" alt="Cheque Icon"> -->
      <i class="fas fa-money-check-alt"></i>
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '5'" class="medium-text-size color-orange icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/ellipsis.svg" alt="Others Icon"> -->
      <i class="fas fa-ellipsis-h"></i>
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '6'" class="medium-text-size color-red icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/cheque.svg" alt="Cheque Icon"> -->
      <img style="width: 70% !important;" src="assets/images/coat_of_arms_of_nigeria.svg" alt="Payslip Logo">
    </div>
    <div *ngIf="request?.REPAYMENT_MODE == '10'" class="medium-text-size color-red icon-button"
      [ngClass]="{'hidden': loading}">
      <!-- <img src="assets/images/cheque.svg" alt="Cheque Icon"> -->
      <img style="width: 70% !important;" src="../../../assets/images/nibss-logo.png" alt="Payslip Logo">
    </div>
    <!-- <div *ngIf="request?.REPAYMENT_MODE=='3'" class="icon" [ngClass]="{'hidden': loading}">
      <img src="assets/images/others.svg" alt="other icon">
    </div> -->
  </div>
</div>