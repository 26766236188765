<h3 class="title">Features</h3>
<div class="panel mb-1" style="margin-bottom:0px !important">
  <div class="panel-body flex align-start">

    <div class="section-poster flx-2" style="max-width: none">
      <app-timer [endDate]='endDate'></app-timer>
    </div>

    <div class="flex-2 ml-3" style="margin-left:100px !important;height: 338px;">
      <div class="label-value-list mb-2">
        <h4 class="section-title mb-5">Features</h4>
        <div class="item">

          <span class="value pl_0 ">Send invites to your customer</span>
        </div>
        <div class="item">

          <span class="value pl_0">Send invites to your customer</span>
        </div>
        <div class="item">

          <span class="value pl_0">Send invites to your customer</span>
        </div>
        <div class="separator"></div>
        <div class="item">

          <span class="value pl_0">Send invites to your customer</span>
        </div>
        <div class="item">

          <span class="value pl_0">Send invites to your customer</span>
        </div>
        <div class="separator"></div>
        <div class="item">

          <span class="value pl_0">Send invites to your customer</span>
        </div>


      </div>
    </div>
  </div>
</div>