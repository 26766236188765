<!-- <div class="col-md-12 main-center pl_0 sticky" style="padding-left : 0px !important" [ngClass]="{'pr_0 pl_0': view_from=='approvals', 'main-center pl_0':  view_from!='approvals'}"> -->

<section class="panel mb-1" *ngIf="!external">
  <div class="panel-body padding-regular flex align-center">
    <button class="btn btn-outline-secondary btn-xs mr-4" (click)="goBack()">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h4 class="section-title">Timeline</h4>
  </div>
</section>
<app-timeline *ngIf="userId" [request_id]="userId" [where]='3'></app-timeline>
<div class="panel loading" style="height: 100px;" *ngIf="!userId"></div>