<div class="flex">
  <div class="col-md-12 main-center">
    <app-analyticscontractheader [request_id]="parentRouteId" [text]="title" *ngIf="showHeader">
    </app-analyticscontractheader>
    <div *ngIf="loading">
      <app-page-loading></app-page-loading>
    </div>
    <div *ngIf="!loading">

      <section class="panel ">
        <div class="data_flex">
          <div class="document_icon">
            <img src="assets/images/timefile.png" alt="Document Icon">
          </div>
          <div>
            <h5 class="profile_data_heading">Analysing Loan Spider Service</h5>
            <p>Submitted data compared against 134 other borrowers</p>
            <div class="flex align-center">
              <button *ngIf="showHeader" (click)="goBack()" class="btn btn-outline-secondary btn-sm mr-1"><i
                  class="fa fa-arrow-left mr-2"></i>Back</button>
              <app-button (click)="modifyAnalytics()" buttonclass="small outline teal" label="Modify"></app-button>
            </div>
          </div>
        </div>
      </section>
      <section class="panel padding-regular">
        <div class="spider_flex">
          <div class="spider_flex_1">
            <div class="spider_flex_item reg_nos">
              <span class="span_font">{{details?.reqistrations?.length || 0}}</span>
              <span>Registrations</span>
            </div>
            <div class="spider_flex_item active_nos">
              <span class="span_font">{{details?.loan_performance?.length || 0}}</span>
              <span>Active</span>
            </div>
          </div>
          <div class="">
            <div class="spider_flex_item closed_nos">
              <span class="span_font">{{details?.loan_rejections?.length || 0}}</span>
              <span>Closed</span>
            </div>
            <div class="spider_flex_item pending_nos">
              <span class="span_font">{{details?.loan_requests?.length || 0}}</span>
              <span>Pending</span>
            </div>
          </div>
        </div>
      </section>
      <div class="panel mb_5 box edged_box" *ngIf="details?.loan_performance?.length > 0">
        <div class="panel-body">
          <div class="pull-left">
            <h4 class="t_l4 no-margin"><span>Loan Performance log</span><br>
              <small>Analyzed from a list of {{details?.loan_performance?.length}} records.</small>
            </h4>
          </div>
        </div>
        <div class="panel-body">
          <table class="table">
            <tbody>
              <tr>
                <td>Lender</td>
                <td>Principal</td>
                <td>Duration(d)</td>
                <td>Rate</td>
                <td>Outstanding</td>
                <td>Status</td>
              </tr>
              <tr *ngFor="let accepted of details?.loan_performance">
                <td>{{accepted.lender_type|lenderType}}</td>
                <td>{{accepted.principal|number:'.2-2'}}</td>
                <td>{{accepted.rate}}</td>
                <td>{{accepted.duration}} {{accepted.request_period_id|loanduration}}</td>
                <td>{{accepted.outstanding|number:'.2-2'}}</td>
                <td>{{accepted.status}}</td>
              </tr>
            </tbody>
          </table>
          <button (click)="viewMore()" class="view_more_btn">View More</button>
        </div>
      </div>
      <section class="panel padding-regular">
        <div>
          <div>
            <img src="" alt="">
          </div>
          <div>
            <h3>Extended Search to other lenders</h3>
            <p>We are connection to Bureaus in your country</p>
            <button (click)="comingSoon()">View</button>
          </div>
        </div>
      </section>
      <div class="panel mb_5 box edged_box" *ngIf="details?.loan_requests?.length > 0">
        <div class="panel-body">
          <div class="pull-left">
            <h4 class="t_l4 no-margin"><span>Pending Requests log</span><br>
              <small>We are connecting with bureaus in your country</small>
            </h4>
          </div>
        </div>
        <div class="panel-body">
          <table class="table">
            <tbody>
              <tr>
                <td>Lender Category</td>
                <td>Principal</td>
                <td>Duration</td>
                <td>Purpose</td>
                <td>Applied when</td>
                <td>TAT(d)</td>
              </tr>
              <tr *ngFor="let accepted of details?.loan_requests">
                <td>{{accepted.lender_type|lenderType}}</td>
                <td>{{accepted.principal|number:'.2-2'}}</td>
                <td>{{accepted.duration}} {{accepted.request_period_id|loanduration}}</td>
                <td>{{accepted.loan_purpose_meaning || 'Not available'}}</td>
                <td>{{accepted.date_added | date: 'medium'}}</td>
                <td>{{accepted.TAT}}</td>
              </tr>
            </tbody>
          </table>
          <button (click)="viewMorePending()" class="view_more_btn">View More</button>
        </div>
      </div>
      <div class="panel mb_5 box edged_box" *ngIf="details?.closed_requests?.length > 0">
        <div class="panel-body">
          <div class="pull-left">
            <h4 class="t_l4 no-margin"><span>Close Requests</span><br>
              <small>We are connecting with bureaus in your country</small>
            </h4>
          </div>
        </div>
        <div class="panel-body">
          <table class="table">
            <tbody>
            <tbody>
              <tr>
                <td>Category</td>
                <td>Lender Location</td>
                <td>Principal</td>
                <td>Duration</td>
                <td>Reason</td>
                <td>Closed when</td>
              </tr>
              <tr *ngFor="let rejected of details?.closed_requests">
                <td>{{rejected.lender_type|lenderType}}</td>
                <td>{{rejected.lender_location|| 'Not available'}}</td>
                <td>{{rejected.principal | number: '.2-2' }}</td>
                <td>{{rejected.duration }} {{rejected.request_period_id|loanduration}}</td>
                <td>{{rejected.loan_purpose_meaning|| 'Not available'}}</td>
                <td>{{rejected.closed_when || 'Not available'}}</td>
              </tr>
            </tbody>
          </table>
          <button (click)="viewMoreClosed()" class="view_more_btn">View More</button>
        </div>
      </div>
      <div class="panel mb_5 box edged_box" *ngIf="details?.reqistrations?.length > 0">
        <div class="panel-body">
          <div class="pull-left">
            <h4 class="t_l4 no-margin"><span>Registrations</span><br>
              <small>We are connecting with bureaus in your country</small>
            </h4>
          </div>
        </div>
        <div class="panel-body">
          <table class="table">
            <tbody>
              <tr>
                <td>Registered as</td>
                <td>Lender Category</td>
                <td>Date Registered</td>
                <td>Loan</td>
              </tr>
              <tr *ngFor="let lender of details?.reqistrations; let i=index">
                <td>{{lender.LEGAL_NAME}}</td>
                <td>{{lender.LENDER_TYPE | lenderType}}</td>
                <td>{{lender.DATE_ADDED | date: 'medium'}}</td>
                <td>Loan</td>
              </tr>
            </tbody>
          </table>
          <button (click)="viewMoreRegs()" class="view_more_btn">View More</button>
        </div>
      </div>
      <section class="panel padding-regular">
        <div class="text-center">
          <h5>Related Annalytics</h5>
          <p>We are connecting with Bureau in your country</p>
        </div>
        <div class="col-sm-4">
          <div class="orange_ring round_box">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}"
              alt="User Icon" style="border-radius: 50%">
            <div>Call Logs Analysis</div>
          </div>
        </div>
        <div class="col-sm-4">
          <a [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'sms'
                      ]">
            <div class="orange_ring round_box">
              <img src="assets/img/comment.png" alt="User Icon" style="border-radius: 50%">
              <div>SMS Analysis</div>
            </div>
          </a>
        </div>
        <div class="col-sm-4">
          <a [routerLink]="[
                        '/applications/analytics',
                        parentRouteId,
                        'profile'
                      ]">
            <div class="orange_ring round_box">
              <img src="assets/img/comment.png" alt="User Icon" style="border-radius: 50%">
              <div>Profile Analysis</div>
            </div>
          </a>
        </div>
      </section>
      <div class="panel box padding-regular">
        <div class="flex align-center mt-5 mb-5">
          <div class="section-poster flex-1">
            <div class="image flex-center">
              <img class="w-60" src="assets/images/specific.svg" />
            </div>
          </div>
          <div class="h-group flex-1">
            <h3 class="title no-margin">Run Specific Analytics</h3>
            <p class="subtitle fill-w mb-2">
              Everything you want in an analytics of your platform is right
              available for you anyway you want it to play
            </p>
            <button type="button" [disabled]="!loan" (click)="openModal('specific_analytics')"
              class="btn btn-outline-secondary mt-5">
              Run Analytics
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div *ngIf="view_more">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="view_more = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wider overpanel-scroll" role="document">
      <div class="panel-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Lender</td>
              <td>Principal</td>
              <td>Duration(d)</td>
              <td>Rate</td>
              <td>Outstanding</td>
              <td>Status</td>
            </tr>
            <tr *ngFor="let accepted of details?.loan_performance">
              <td>{{accepted.lender_type|lenderType}}</td>
              <td>{{accepted.principal|number:'.2-2'}}</td>
              <td>{{accepted.rate}}</td>
              <td>{{accepted.duration}} {{accepted.request_period_id|loanduration}}</td>
              <td>{{accepted.outstanding|number:'.2-2'}}</td>
              <td>{{accepted.status}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="view_more_pending">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="view_more_pending = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wider overpanel-scroll" role="document">
      <div class="panel-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Lender Category</td>
              <td>Principal</td>
              <td>Duration</td>
              <td>Purpose</td>
              <td>Applied when</td>
              <td>TAT(d)</td>
            </tr>
            <tr *ngFor="let accepted of details?.loan_requests">
              <td>{{accepted.lender_type|lenderType}}</td>
              <td>{{accepted.principal|number:'.2-2'}}</td>
              <td>{{accepted.duration}} {{accepted.request_period_id|loanduration}}</td>
              <td>{{accepted.loan_purpose_meaning || 'Not available'}}</td>
              <td>{{accepted.date_added | date: 'medium'}}</td>
              <td>{{accepted.TAT}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="view_more_closed">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="view_more_closed = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wider overpanel-scroll" role="document">
      <div class="panel-body">
        <table class="table">
          <tbody>
          <tbody>
            <tr>
              <td>Category</td>
              <td>Lender Location</td>
              <td>Principal</td>
              <td>Duration</td>
              <td>Reason</td>
              <td>Closed when</td>
            </tr>
            <tr *ngFor="let rejected of details?.closed_requests">
              <td>{{rejected.lender_type|lenderType}}</td>
              <td>{{rejected.lender_location|| 'Not available'}}</td>
              <td>{{rejected.principal | number: '.2-2' }}</td>
              <td>{{rejected.duration }} {{rejected.request_period_id|loanduration}}</td>
              <td>{{rejected.loan_purpose_meaning|| 'Not available'}}</td>
              <td>{{rejected.closed_when || 'Not available'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="view_more_regs">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="view_more_regs = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wider overpanel-scroll" role="document">
      <div class="panel-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Registered as</td>
              <td>Lender Category</td>
              <td>Date Registered</td>
              <td>Loan</td>
            </tr>
            <tr *ngFor="let lender of details?.reqistrations; let i=index">
              <td>{{lender.LEGAL_NAME}}</td>
              <td>{{lender.LENDER_TYPE | lenderType}}</td>
              <td>{{lender.DATE_ADDED | date: 'medium'}}</td>
              <td>Loan</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="coming_soon">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="coming_soon = false;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wider" role="document">
      <div class="panel-body">
        <div>Coming Soon</div>
        <ul>
          <li>Coming soon</li>
          <li>Coming soon</li>
          <li>Coming soon</li>
          <li>Coming soon</li>
          <li>Coming soon</li>
        </ul>
      </div>
    </div>
  </div>
</div>