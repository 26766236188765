<section id="main-content">
  <div class="container panl" style="min-height:600px">
    <div class="row" style="margin-top:50px;">
      <div class="col-md-12" style="padding:0;">
        <div id="ajax_view">



          <div *ngIf="preloading">
            <div class="dashboard-container" style="margin: 0px auto;margin-top: 0px;width: 100%;">
              <div class="row">
                <div class="col-md-3 sPad">
                  <section class="panel sd_bt">
                    <div class="panel-body spBody">

                    </div>
                  </section>
                  <section class="panel sd_bt">
                    <div class="panel-body">

                    </div>
                  </section>
                </div>
                <div class="col-md-9 sPad">
                  <div class="row">
                    <div class="col-md-9 sPad">
                      <section class="panel sd_bt">
                        <div class="panel-body">

                        </div>
                      </section>
                      <section class="panel sd_bt">
                        <div class="panel-body">
                        </div>
                      </section>
                    </div>
                    <div class="col-md-3 sPad">
                      <section class="panel sd_bt">
                        <div class="panel-body pdLeft40 pdRight10">

                        </div>
                      </section>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 sPad">

                      <section class="panel sd_bt">
                        <div class="panel-body">

                        </div>
                      </section>

                      <section class="panel sd_bt">
                        <div class="panel-body">

                        </div>
                      </section>

                    </div>
                    <div class="col-md-8 sPad">
                      <section class="panel sd_bt">
                        <div class="panel-body">

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!preloading && !is_company_staff">
            <div class="col-md-2 col">
              <section class="panel b_grey">
                <div class="panel-body" style="height: 228px; position: relative;">
                  <div style="padding: 25px 0px; text-align: center">
                    <h1 style="margin-bottom: 0px" class="t_cool">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[4]}}</h1>
                    <span style="display: block">Loan Request</span>
                  </div>
                  <a [routerLink]="['/applications/dashboard']" class="btn btn-normal btn-block ld_btn_portfolio" style="padding: 10px; position: absolute; left: 15px; right: 15px; bottom: 15px; width: auto;">View
                    Requests</a>
                </div>
              </section>

              <section class="panel">
                <div class="panel-body b_grey" *ngIf="!showingAvgLRate" (mouseenter)="showingAvgLRate=!showingAvgLRate">
                  <article class="media pad0" style="height: 40px; overflow: hidden;">
                    <div class="pull-left thumb">
                      <a style="font-size: 22px; vertical-align: top">
                        <i class="fa fa-money"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[8]}}%</p>
                      <p class="txt t_dull">Avg Lending Rate</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body text-center" *ngIf="showingAvgLRate" (mouseleave)="showingAvgLRate=!showingAvgLRate">
                  <a [routerLink]="['../../operations/queue']" class="btn btn-white btn-full egded-box" style="height: 40px; line-height: 25px">View
                    Reports</a>
                </div>
              </section>
              <section class="panel">
                <div class="panel-body b_grey" *ngIf="!showingLInv" (mouseenter)="showingLInv=!showingLInv">
                  <article class="media pad0" style="height: 40px; overflow: hidden;">
                    <div class="pull-left thumb">
                      <a style="font-size: 22px; vertical-align: top">
                        <i class="fa fa-money"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[9]}}</p>
                      <p class="txt t_dull">Investments</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body text-center" *ngIf="showingLInv" (mouseleave)="showingLInv=!showingLInv">
                  <a [routerLink]="['../../investments']" class="btn btn-white btn-full egded-box" style="height: 40px; line-height: 25px">View</a>
                </div>
              </section>
              <section class="panel">
                <div class="panel-body b_grey" *ngIf="!showingDisburseSummary" (mouseenter)="showingDisburseSummary=!showingDisburseSummary">
                  <article class="media pad0" style="height: 40px; overflow: hidden;">
                    <div class="pull-left thumb">
                      <a style="font-size: 22px; vertical-align: top">
                        <i class="fa fa-money"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;">{{DISBURSEMENTS?.total|money}}</p>
                      <p class="txt t_dull">Disbursements</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body text-center" *ngIf="showingDisburseSummary" (mouseleave)="showingDisburseSummary=!showingDisburseSummary">
                  <a [routerLink]="['../../operations/queue']" class="btn btn-white btn-full egded-box" style="height: 40px; line-height: 25px">View</a>
                </div>
              </section>
            </div>
            <div class="col-md-6 col">
              <section class="panel" style="max-height: 90px">
                <div class="panel-body b_grey" style="padding: 25px">
                  <div class="dashboard-greeting">
                    <div class="dashboard-greeting-text">
                      <h4 class="today-date txt t_cool t_l3" style="line-height: 1.2; font-size: 18px">Loan Request
                        Dashboard</h4>
                      <h5 class="today-sub">Today, {{DASHBOARD_TM?.CURRENT_DATE}}</h5>
                    </div>
                    <div class="dashboard-greeting-filter">
                      <div class="form-group">
                        <label class="control-label" style="margin-right: 10px">
                          Filter by me:
                        </label>
                        <div class="checkboxFive">
                          <input type="checkbox" name="isActive" [(ngModel)]="dashboardFilter.byMe">
                          <label for="checkboxFiveInput"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="panel" style="max-height: 400px; overflow: hidden; margin-bottom: 0 !important">
                <div class="panel-body" style="padding: 25px">
                  <section class="panel">
                    <div class="panel-body no-outline" style="padding: 0">
                      <!-- <h1 style="margin: 0px" class="t_hot" counto [step]="200" [countTo]=myCount [countFrom]="0" [duration]="2" (countoChange)="counto = $event" (countoEnd)="onCountoEnd()">{{counto | currency:'NGN':false:'1.2-2'}}</h1> -->
                      <h1 style="margin: 0px" class="t_hot">{{counto | currency:'NGN':false:'1.2-2'}}</h1>
                      <span style="display: block;">unfunded</span>

                    </div>
                  </section>
                  <div class="row">
                    <div class="col-md-6">
                      &nbsp;
                    </div>
                    <div class="col-md-6">
                      <ul class="ld_list quicksquares" style="padding-top: 80px">
                        <li>
                          <span class="unfunded-square squares pull-left">&nbsp;</span>
                          <a class="pull-right">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[0]|money}} unfunded</a>
                        </li>
                        <li>
                          <span class="ongoing-square squares pull-left">&nbsp;</span>
                          <a class="pull-right">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[1]|money}} ongoing</a>
                        </li>
                        <li>
                          <span class="begin-payment-square squares pull-left">&nbsp;</span>
                          <a class="pull-right">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[2]|money}} Full</a>
                        </li>
                        <li>
                          <span class="disburse-square squares pull-left">&nbsp;</span>
                          <a class="pull-right">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[3]|money}} Disbursement</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-2 col">
              <section class="panel">
                <div class="panel-body" style="height: 310px">
                  <app-duerepayment [currentUser]='currentUser'></app-duerepayment>
                </div>
              </section>

              <section class="panel">
                <div class="panel-body b_grey" *ngIf="!showingWalletSummary" (mouseenter)="showingWalletSummary=!showingWalletSummary">
                  <article class="media pad0" style="height: 40px; overflow: hidden;">
                    <div class="pull-left thumb">
                      <a style="font-size: 40px; vertical-align: top">
                        <i class="fa fa-money"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">{{dashboarddata?.DASHBOARD_BL.WALLET_BALANCES.CUSTOMERS_WALLET_BALANCE|money}}</p>
                      <p class="txt t_dull" style="font-size:10px">Customer Wallet</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body b_grey" *ngIf="showingWalletSummary" (mouseleave)="closeWalletSummary()">
                  <a [routerLink]="['../../operations/wallet']" class="btn btn-white btn-block egded-box" style="height: 40px; line-height: 25px">View
                    Wallet</a>
                </div>
              </section>
              <section class="panel">
                <div class="panel-body b_grey" *ngIf="!showinglandb" (mouseenter)="showinglandb =!showinglandb">
                  <article class="media pad0" style="height: 40px; overflow: hidden;">
                    <div class="pull-left thumb">
                      <a style="font-size: 32px">
                        <i class="fa fa-user-secret"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">{{DASHBOARD_TM?.DASHBOARD_TM.TM}}</p>
                      <p class="txt t_dull" style="font-size:10px">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[6]}} Lenders
                        | {{DASHBOARD_TM?.DASHBOARD_TLT.CUSTOMERS_SUM.TOTAL_CUSTOMERS}}
                        Bor...</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body b_grey" *ngIf="showinglandb" (mouseleave)="showinglandb =!showinglandb">
                  <a [routerLink]="['../../crm/customers']" class="btn btn-white btn-block egded-box" style="height: 40px; line-height: 25px">View
                    Customers</a>
                </div>
              </section>
            </div>
            <div class="col-md-2 col">
              <section class="panel">
                <div class="panel-body text-center b_grey" style="padding-bottom:33px;height: 208px;" *ngIf="!showingAvgPTime"
                  (mouseenter)="showingAvgPTime=!showingAvgPTime">

                  <div style="text-align: center;padding:15px 0px; margin-bottom: 30px">
                    <img style="width:75px; height:75px;" src="assets/img/naira_shade.png">
                  </div>
                  <p class="txt t_l3 t_lh1 t_cool">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[7]}}</p>
                  <p class="txt">Active Loans</p>
                </div>
                <div class="panel-body text-center" style="padding-bottom:0px; height: 208px" *ngIf="showingAvgPTime"
                  (mouseleave)="showAvgPTime()">

                  <div class="text-center" style="padding:15px 0px; margin-top: 50px">
                    <p class="txt t_l3 t_lh1">Active Loans</p>
                    <p class="txt t_dull">Review breakdown</p>
                  </div>
                  <a [routerLink]="['../../applications/requests']" class="btn btn-white btn-full egded-box">View</a>

                </div>
              </section>
              <section class="panel">
                <div class="panel-body" *ngIf="!showingPInv" (mouseenter)="showingPInv=!showingPInv">
                  <article class="media pad0" style="height: 40px;">
                    <div class="pull-left thumb">
                      <a style="font-size: 40px; vertical-align: top">
                        <i class="fa fa-money"></i>
                      </a>
                    </div>
                    <div class="pull-left">
                      <p class="txt t_l3 t_lh1" style="word-wrap:break-word;">{{DASHBOARD_TM?.DASHBOARD_TM.LOANS_SUM[10]}}</p>
                      <p class="txt">Portfolio</p>

                    </div>

                  </article>
                </div>
                <div class="panel-body text-center" *ngIf="showingPInv" (mouseleave)="showingPInv=!showingPInv">
                  <a [routerLink]="['../../investments']" class="btn btn-white btn-full egded-box" style="height: 40px; line-height: 25px">View</a>
                </div>
              </section>
              <section class="panel">
                <div class="panel-body" style="padding: 10px 25px">
                  <ul class="list list_border">
                    <li>
                      <a class="txt_l4">Settings</a>
                    </li>
                    <li>
                      <a class="txt_l4" [routerLink]="['../../requests']">Repayments</a>
                    </li>
                    <li>
                      <a class="txt_l4">Reports</a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>

<!-- Bar Chart -->
<div *ngIf="!preloading && !is_company_staff" style="background: #fff; padding: 50px 70px 70px">

  <div class="container">
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-md-6">
        <h2 style="margin: 0">Platform Analysis</h2>
      </div>
      <div class="col-md-6 text-right">
        <div class="form-inline">
          <div class="form-group">
            <div class="search_box">
              <select name="BAR_STYLE" [(ngModel)]="bar_form.BAR_STYLE" style="border: 0px; background: #fafafa; width: 100%">
                <option [value]="0">Count</option>
                <option [value]="1">Total Amount</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="search_box">
              <select name="BAR_CATEGORY" [(ngModel)]="bar_form.BAR_CATEGORY" style="border: 0px; background: #fafafa; width: 100%">
                <option *ngFor="let cat of bar_categories" [ngValue]="cat">{{cat.display}}</option>
              </select>
            </div>
          </div>
          <button class="btn btn-default edged_box" (click)="runPlatformAnalysis()" style="padding: 11px 20px">Filter</button>
        </div>


      </div>
    </div>
    <div style="display: block">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
        [chartType]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
    </div>
  </div>
</div>

<!-- Doughnut Chart -->
<div style="padding: 50px" *ngIf="!preloading && !is_company_staff">
  <div class="container">
    <div>
      <h2 class="text-center" style="margin-bottom: 30px">Platform Analysis</h2>
    </div>
    <section class="panel">
      <div class="panel panel-default box">
        <div class="panel-heading" style="background: rgba(240,240,240,.5);">
          <span style="line-height: 2.2em">&nbsp;</span>
          <div class="form-inline pull-right">
            <div class="form-group hide">
              <select (change)="changePieFilter($event)" class="form-control" id="FILTER_TYPE" [(ngModel)]="piefilterStatus">

                <option *ngFor="let opt of pie_filter_types" [value]="opt.value">{{opt.display}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="">Date Range</label>
            </div>
            <div class="input-group" *ngIf="piefilterStatus=='date_duration'">
              <input type="text" name="PIETPDATE" class="form-control" [(ngModel)]="pie_perf.PIETPDATE"
                ngui-datetime-picker date-only="true" />
              <span class="input-group-addon">To</span>
              <input type="text" name="PIETPDATE_" class="form-control" [(ngModel)]="pie_perf.PIETPDATE_"
                ngui-datetime-picker date-only="true" />
            </div>
            <div class="form-group">
              <select (change)="changePieFilterCategory($event)" class="form-control" id="CATEGORY" [(ngModel)]="pie_perf.CATEGORY">
                <option value="">select</option>
                <option *ngFor="let cust of pie_report_categories" [value]="cust.value">{{cust?.display}}</option>
              </select>
            </div>
            <div class="form-group" *ngIf="piefilterStatusCategory=='2'">
              <label class="control-label">Request Type</label>
              <select class="form-control" id="SUBCATEGORY" [(ngModel)]="pie_perf.SUBCATEGORY">
                <option value="">select</option>
                <option *ngFor="let cust of pie_report_subcategories" [ngValue]="cust">{{cust?.display}}</option>
              </select>
            </div>
            <div class="form-group" *ngIf="piefilterStatusCategory=='3'&&piefilterStatus=='distribution'">
              <label class="control-label">Report Type</label>
              <select class="form-control" id="SUBSTAFFCATEGORY" [(ngModel)]="pie_perf.SUBSTAFFCATEGORY">
                <option value="">select</option>
                <option *ngFor="let cust of pie_staff_subcategories" [ngValue]="cust">{{cust?.display}}</option>
              </select>
            </div>
            <button (click)="runPiePerformance()" [disabled]="pie_perf.PIETPDATE==''||pie_perf.PIETPDATE_==''" class="btn btn-primary btn-tp edged_box">Submit</button>
          </div>
        </div>
      </div>
    </section>
    <div class="row">

      <div class="col-md-5 col-md-offset-1">
        <div *ngIf="pie_perf.CATEGORY=='1'">
          <ul class="list list_border no_pad">
            <li>Repaid
              <span class="right">{{platform_performance?.data[0]}}</span>
            </li>
            <li>Late
              <span class="right"> {{platform_performance?.data[1]}}</span>
            </li>
            <li>Deliquent
              <span class="right"> {{platform_performance?.data[2]}}</span>
            </li>
            <li>Default
              <span class="right"> {{platform_performance?.data[3]}}</span>
            </li>

          </ul>
        </div>
        <div *ngIf="pie_perf.CATEGORY=='2'">
          <ul class="list list_border no_pad">
            <li *ngFor="let d of platform_performance?.labels; let i=index">{{d}}
              <span class="right">{{platform_performance?.data[i]}}</span>
            </li>

          </ul>
        </div>
      </div>
      <div class="col-md-6" style="padding: 37px 0; border-radius: 20px">
        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
          [options]="doughnutChartOptions" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
      </div>
    </div>
  </div>
</div>

<!-- Platform Performance Snapshot -->
<div style="padding: 50px; background: #fff" class="" *ngIf="!preloading && !is_company_staff">
  <div class="container">
    <h2 class="text-center" style="margin-bottom: 40px">Platform Performance Snapshot</h2>
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="row">
          <div class="col-md-4" style="padding-right: 0">
            <div>
              <div style="padding: 20px 20px 44px;background: #52658C;" class="performance_snap_list">
                <ul class="list no_pad">
                  <li>
                    <div class="search_box">
                      <input type="text" name="PPSTPDATE" class="form-control" [(ngModel)]="pps_perf.PPSTPDATE"
                        ngui-datetime-picker date-only="true" />

                    </div>
                  </li>
                  <li>
                    <div class="search_box">
                      <input type="text" name="PPSTPDATE_" class="form-control" [(ngModel)]="pps_perf.PPSTPDATE_"
                        ngui-datetime-picker date-only="true" />
                    </div>
                  </li>
                  <li>
                    <button class="btn btn-normal btn-orange btn-block edged_box" style="padding: 15px;width: auto;"
                      (click)="runPPSPerformance()">View Requests</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4" style="padding-right: 5px; padding-left: 10px">
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0" style="height: 40px; overflow: hidden;">
                  <p class="txt t_l3 t_lh1 text-right" style="word-wrap:break-word;font-size:30px">23</p>
                </article>
              </div>
            </section>
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0" style="height: 40px; overflow: hidden;">
                  <div class="pull-left thumb">
                    <a style="font-size: 40px; vertical-align: top">
                      <i class="fa fa-money"></i>
                    </a>
                  </div>
                </article>
              </div>
            </section>
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0" style="height: 40px; overflow: hidden;">
                  <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:30px">18.9k</p>
                </article>
              </div>
            </section>
          </div>
          <div class="col-md-4" style="padding-left: 5px">
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0" style="height: 40px; overflow: hidden;">
                  <div class="pull-right thumb">
                    <a style="font-size: 40px; vertical-align: top">
                      <i class="fa fa-money"></i>
                    </a>
                  </div>
                </article>
              </div>
            </section>
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0" style="height: 40px; overflow: hidden;">
                  <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">8.3M</p>
                  <p class="txt t_dull" style="font-size:10px">Current YTD Lending Profit</p>
                </article>
              </div>
            </section>
            <section class="panel box">
              <div class="panel-body b_grey">
                <article class="media pad0 text-right" style="height: 40px; overflow: hidden;">
                  <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">1.3%</p>
                  <p class="txt t_dull" style="font-size:10px">YTD Non-performing Loans</p>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Platform Performance Snapshot -->
<div style="padding: 50px; background: #fff" *ngIf="!preloading && !is_company_staff">
  <div class="container">
    <h2 class="text-center" style="margin-bottom: 40px">Platform Performance Snapshot</h2>
    <div class="row">
      <div class="col-md-3 col-md-offset-1">
        <div style="padding: 20px 20px 44px;background: #52658C;" class="performance_snap_list">
          <ul class="list no_pad">
            <li>
              <div class="search_box">
                <input type="text" name="PPSTPDATE" class="form-control" [(ngModel)]="pps_perf.PPSTPDATE"
                  ngui-datetime-picker date-only="true" />

              </div>
            </li>
            <li>
              <div class="search_box">
                <input type="text" name="PPSTPDATE_" class="form-control" [(ngModel)]="pps_perf.PPSTPDATE_"
                  ngui-datetime-picker date-only="true" />
              </div>
            </li>
            <li>
              <button [disabled]="pps_perf.PPSTPDATE==''||pps_perf.PPSTPDATE_==''" class="btn btn-normal btn-full btn-orange btn-block edged_box"
                style="padding: 15px;width: auto;" (click)="runPPSPerformance()">View Requests</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-7">
        <ul class="list list_border no-padding-right request_stat" *ngIf="platform_analysis_view=='first'">
          <li style="padding-top: 0">
            Borrowers(Total/Active)
            <div class="pull-right">{{pps_performance?.borrowers?.total_customers}}/{{pps_performance?.borrowers?.total_active_customers}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Current Lending Profit
            <div class="pull-right"></div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Current Overall Profit
            <div class="pull-right"></div>
            <div class="clear_fix"></div>
          </li>
          <li>
            NPL
            <div class="pull-right"></div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Average Rate of Recovery
            <div class="pull-right"></div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Tenor Average
            <div class="pull-right">{{pps_performance?.tenor_average|number}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Principal Average
            <div class="pull-right">{{pps_performance?.p_average|number}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>

            <div class="pull-right">
              <button class="btn btn-white" (click)="platform_analysis_view='second'">More</button>
            </div>
            <div class="clear_fix"></div>
          </li>
        </ul>
        <ul class="list list_border no-padding-right request_stat" *ngIf="platform_analysis_view=='second'">
          <li style="padding-top: 0">
            Total Loans
            <div class="pull-right">{{pps_performance?.loans.total_loans.total_principal|money}}/{{pps_performance?.loans.total_loans.total_loans}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Total Collections
            <div class="pull-right">{{pps_performance?.collections.total_collections.total_paid|money}}/{{pps_performance?.collections.total_collections.total_repayments_count}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Open Loans Outstanding
            <div class="pull-right">{{pps_performance?.open_loans.total_loans_amount|money}}/{{pps_performance?.open_loans.total_loans}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Principal Outstanding
            <div class="pull-right">{{pps_performance?.principal.outstanding_principal|money}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Interest Outstanding
            <div class="pull-right">{{pps_performance?.interest.outstanding_principal|money}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Fully Paid Loans
            <div class="pull-right">{{pps_performance?.fully_paid.total_loans.total_principal|money}}/{{pps_performance?.fully_paid.total_loans.total_loans}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>
            Default Loans
            <div class="pull-right">{{pps_performance?.default.total_loans.total_principal|money}}/{{pps_performance?.default.total_loans.total_loans}}</div>
            <div class="clear_fix"></div>
          </li>
          <li>

            <div class="pull-right">
              <button class="btn btn-white" (click)="platform_analysis_view='first'">Prev</button>
            </div>
            <div class="clear_fix"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Employee Performance Snapshot -->
<div style="padding: 50px;" *ngIf="!preloading && !is_company_staff">
  <div class="container">
    <h2 class="text-center" style="margin-bottom: 40px">Employee Performance Snapshot</h2>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-8">
            <section class="panel">
              <div class="panel panel-default box">
                <div class="panel-heading" style="background: rgba(240,240,240,.5);">
                  <span style="line-height: 2.2em">&nbsp;</span>
                  <div class="form-inline pull-right">
                    <div class="form-group">
                      <select (change)="changeFilter($event)" class="form-control" id="FILTER_TYPE" [(ngModel)]="filterStatus">

                        <option *ngFor="let opt of filter_types" [value]="opt.value">{{opt.display}}</option>
                      </select>
                    </div>
                    <div class="input-group" *ngIf="filterStatus=='date_duration'">
                      <input type="text" name="TPDATE" class="form-control" [(ngModel)]="emp_perf.TPDATE"
                        ngui-datetime-picker date-only="true" />
                      <span class="input-group-addon">To</span>
                      <input type="text" name="TPDATE_" class="form-control" [(ngModel)]="emp_perf.TPDATE_"
                        ngui-datetime-picker date-only="true" />
                    </div>
                    <div class="form-group" *ngIf="filterStatus=='category'">
                      <select class="form-control" id="CATEGORY" [(ngModel)]="emp_perf.CATEGORY">
                        <option value="">select</option>
                        <option *ngFor="let cust of report_categories" [ngValue]="cust">{{cust?.display}}</option>
                      </select>
                    </div>

                    <button (click)="runEmployeePerformance()" [disabled]="emp_perf.TPDATE==''||emp_perf.TPDATE_==''"
                      class="btn btn-primary btn-tp edged_box">Submit</button>
                  </div>
                </div>
              </div>
            </section>
            <section class="">
              <div class="row">
                <div class="col-md-6" style="padding-right: 0">
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0" style="height: 40px; overflow: hidden;">
                        <p class="txt t_l3 t_lh1 text-right" style="word-wrap:break-word;font-size:30px">23</p>
                      </article>
                    </div>
                  </section>
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0" style="height: 40px; overflow: hidden;">
                        <div class="pull-left thumb">
                          <a style="font-size: 40px; vertical-align: top">
                            <i class="fa fa-money"></i>
                          </a>
                        </div>
                      </article>
                    </div>
                  </section>
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0" style="height: 40px; overflow: hidden;">
                        <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:30px">18.9k</p>
                      </article>
                    </div>
                  </section>
                </div>
                <div class="col-md-6" style="padding-right: 5px; padding-left: 10px">
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0" style="height: 40px; overflow: hidden;">
                        <div class="pull-right thumb">
                          <a style="font-size: 40px; vertical-align: top">
                            <i class="fa fa-money"></i>
                          </a>
                        </div>
                      </article>
                    </div>
                  </section>
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0" style="height: 40px; overflow: hidden;">
                        <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">8.3M</p>
                        <p class="txt t_dull" style="font-size:10px">Current YTD Lending Profit</p>
                      </article>
                    </div>
                  </section>
                  <section class="panel box">
                    <div class="panel-body b_grey">
                      <article class="media pad0 text-right" style="height: 40px; overflow: hidden;">
                        <p class="txt t_l3 t_lh1" style="word-wrap:break-word;font-size:23px">1.3%</p>
                        <p class="txt t_dull" style="font-size:10px">YTD Non-performing Loans</p>
                      </article>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
          <div class="col-md-4" style="padding-left: 5px">
            <div style="padding: 20px 20px 24px;" class="performance_snap_list box">
              <ul class="list no_pad">
                <li>
                  <div class="search_box">
                    <select name="PEOPLE_PEOPLE_ID" [(ngModel)]="emp_perf.PEOPLE_PEOPLE_ID" style="border: 0px; background: #fafafa; width: 100%">
                      <option value=""></option>
                      <option [ngValue]="emp" *ngFor="let emp of employees">{{emp?.LEGAL_NAME}}</option>
                    </select>
                  </div>
                </li>
                <li class="text-center" style="padding: 15px 0 0;">
                  <img class="" src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg"
                    style=" width: 120px; border-radius: 50%;">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Upcoming Features -->
<div style="padding: 50px" class="" *ngIf="!preloading && !is_company_staff">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <h2>Upcoming Features</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-md-offset-2">
        <div>
          <ul class="list list_border no_pad">
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="sq_box" style="background: #c3d7e0">26</div>
        <div class="sq_box" style="background: #ED3237">&nbsp;</div>
        <br>
        <div class="sq_box" style="background: #00A859">&nbsp;</div>
        <div class="sq_box" style="background: #fff">May</div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="!preloading && !is_company_staff && summaryOpen" style="background:#fff" class="panl">
  <app-loans [limited]=true></app-loans>
</div> -->


<div *ngIf="approvalsOpen && canViewModule">
  <app-loansummary [pending_approvals]="approvals_size" [is_approval]=true [loan_viewed]="loan_viewed" [view_state]="0"
    [dontshownext]="0" (modalClosed)="closeShowInterest($event)"></app-loansummary>
</div>
