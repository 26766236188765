import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../_services/constants.service';
import { StorageService, InvestmentService, LoansService, OptionsserviceService, OperationsService } from '../../_services/index';
import { UserService, CustomerService, AuthenticationService } from '../../_services/index';
import { DataService } from 'src/app/_services';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  current_view = 'terms';
  currentUser: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private constant: ConstantsService,
    public dataService: DataService,
    public storageService: StorageService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }

  agreeMethod() {
    this.current_view = 'creating_profile';
    return this.http.post<any>(`${this.constant.read('api_base')}onboard/accept_terms`, {
      token: this.currentUser.token
    }).subscribe(res => {
      if (res.status) {
        localStorage.setItem('test_mode', '1');
        //this.dataService.openOnboardSimulator.emit();
        this.router.navigate(['/applications/dashboard']);
      }
    });
  }

}
