import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LenderModel } from '../../_models/lender.model';

@Component({
  selector: 'app-setupuser',
  templateUrl: './setupuser.component.html',
  styleUrls: ['./setupuser.component.css']
})
export class SetupuserComponent implements OnInit {
  formLender: LenderModel;
  loading = false;
  currentView = 'setup_user';
  details: any;
  monthly_disbursement: any;
  address: any;
  interest_rate: any;
  license: any;
  email: any;
  password: any = '';
  errorMessage: any;
  request_rate_period_id = '2';

  setup = {
    web: '0',
    ussd: '0',
    mobile: '0',
    details: '0',
    website: '',
    lender_type: null,
    LOANS_MONTHLY: null,
    STATE_OF_OPERATION: null,
    MAX_AMOUNT: '',
    TENOR: null,
    MAX_TENOR: '',
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.getSavedData();
    this._setFormLender();
    this.email = this.details.email;
  }

  getSavedData() {
    this.details = JSON.parse(localStorage.getItem('setup'));
  }

  _setFormLender() {
    this.formLender = new LenderModel('', '');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }

  signup() {
    if (!this.password.trim()) {
      return this.showError('Password field is required');
    }
    this.loading = true;
    this.setup['legal_name'] = this.details.legal_name;
    this.setup['email'] = this.details.email || this.email;
    this.setup['phone'] = this.details.phone;
    this.setup['address'] = this.address;
    this.setup['int_rate'] = this.details.interest_rate;
    this.setup['license'] = this.details.license;
    this.setup['monthly_disbursement'] = this.monthly_disbursement;
    this.setup['password'] = this.password;
    this.setup['request_rate_period_id'] = this.request_rate_period_id;
    this.authenticationService.submitSetup(this.setup)
      .subscribe(result => {
        if (result.status) {
          const token = result.token;
          const account_id = result.account_id;
          const role = result.role;
          const photo = result.photo;
          const enable_peer_to_peer = result.enable_peer;
          const enable_accounting = result.enable_accounting;
          const icon = result.icon;
          const legal_name = result.legal_name;
          const roles = result.roles;
          const platform = result.platform;
          const user_id = result.account_id;
          const is_company_staff = result.is_company_staff;
          const company_id = result.company_id;
          const test_mode = result.test_mode;
          const decision_type = result.decision_type;
          const has_remita = result.has_remita;
          const lender_activated = result.lender_activated;
          const timeLeft = result.timeleft;
          const appKey = result.app_key;
          const sample_user = result.sample_user;
          if (token) {
            // set token property
            // this.token = token;
            // this.account_id = account_id;
            // this.role = role;
            // this.legal_name = lender.LEGAL_NAME;
            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('sample_user', JSON.stringify(sample_user));
            localStorage.setItem('currentUser', JSON.stringify({
              icon: icon,
              photo: photo,
              email: this.details.email,
              token: token,
              role: role,
              account_id: account_id,
              legal_name: this.details.legal_name
            }));
            localStorage.setItem('token', token);
            localStorage.setItem('id_token', token);
            localStorage.setItem('enable_peer_to_peer', enable_peer_to_peer);
            localStorage.setItem('enable_accounting', enable_accounting);
            localStorage.setItem('roles', roles);
            localStorage.setItem('platform', platform);
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('is_company_staff', is_company_staff);
            localStorage.setItem('company_id', company_id);
            localStorage.setItem('cooperative_id', '0');
            localStorage.setItem('test_mode', test_mode);
            localStorage.setItem('lender_activated', lender_activated);
            localStorage.setItem('has_remita', has_remita);
            localStorage.setItem('decision_type', decision_type);
            localStorage.setItem('timeLeft', timeLeft);
            localStorage.setItem('appKey', appKey);
            const type_of_view = localStorage.getItem('type_of_view');
            if (!type_of_view) {
              localStorage.setItem('type_of_view', '1');
            }
            const expiresAt = JSON.stringify((60 * 60 * 1000) + Date.now());
            localStorage.setItem('expires_at', expiresAt);
          }
          this.currentView = 'creating_profile';
          this.loading = false;
          this.router.navigate(['/activate']);
        } else {
          this.loading = false;
          this.showError(result.message);
        }
      }, error => {
        this.loading = false;
        this.errorMessage = error;
        this.showError(this.errorMessage);
      });
  }
}
