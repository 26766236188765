<h3 class="section-title flex align-center justify-between">
  Selection
  <div>
    <!-- <app-button class="outline white small" *ngIf="currentView == '0'" (click)="currentView='1'" label="Advanced">
    </app-button> -->
    <app-button class="outline white small ml-1" *ngIf="currentView == '1'" (click)="currentView='0'" label="Basic">
    </app-button>
    <span class="">{{ counter }} / {{total_default}}</span>
    <app-button class="small ml-1" [disabled]="loading" (click)="submitGlobalAnalysis()" label="Save"
      [loading]="loading">
    </app-button>
   
  </div>
  
</h3>
<div [ngClass]="{'overpanel-scroll padding-regular-y': embedModal}">
  <div class="alert alert-danger" *ngIf="counter > 50">You can only select 50</div>
  <br />
  <div class="col-md-12" *ngIf="showProfile">
    <h3 class="section-title mb-3">Profile Analysis Setting</h3>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div *ngFor="let order of orders | searchanalysissetting : searchText; let i = index">
        <label *ngIf="orders[i].leave_type == currentView" class="form-group" formArrayName="orders">
          <input  (change)="checkBox($event,orders,i,'profile');" type="checkbox" [formControlName]="i" [name]="orders[i].description"
            [checked]="orders[i].checked" [(ngModel)]="orders[i].checked">
          <span class="control-label normal">{{orders[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <!-- <div class="col-md-12" *ngIf="showCalllogs">
    <h3 class="section-title mb-3">Call Log Analysis Setting</h3>
    <form [formGroup]="callLogform">
      <div *ngFor="let callLog of callLogs | searchanalysissetting : searchText; let i = index"> 
        <label *ngIf="callLogs[i].leave_type == currentView" class="form-group" formArrayName="callLogs"
          (change)="checkBox($event,callLogs,i);">
          <input type="checkbox" [formControlName]="i" [name]="callLogs[i].description"
            [checked]="callLogs[i].checked" [(ngModel)]="callLogs[i].checked">
          <span class="control-label normal">{{callLogs[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->
 
  <!-- <div class="col-md-12" *ngIf="showSocial">
    <h3 class="section-title mb-3">Social Analysis Setting</h3>
    <form [formGroup]="socialform">
      <div *ngFor="let soc of social | searchanalysissetting : searchText; let i = index">
        <label *ngIf="social[i].leave_type == currentView" class="form-group" formArrayName="social"
          (change)="checkBox($event,social,i);">
          <input type="checkbox" [formControlName]="i" [name]="social[i].description"
            [checked]="social[i].checked" [(ngModel)]="social[i].checked">
          <span class="control-label normal">{{social[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <!-- <div class="col-md-12" *ngIf="showSMS">
    <h3 class="section-title mb-3">Sms Analysis Setting</h3>
    <form [formGroup]="smsform">
      <div *ngFor="let smse of phonesms | searchanalysissetting : searchText; let i = index">
        <label *ngIf="phonesms[i].leave_type == currentView" class="form-group" formArrayName="phonesms"
          (change)="checkBox($event,phonesms,i);">
          <input type="checkbox" [formControlName]="i" [name]="phonesms[i].description"
            [checked]="phonesms[i].checked" [(ngModel)]="phonesms[i].checked">
          <span class="control-label normal">{{phonesms[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->



  <div class="col-md-12" *ngIf="showAccount">
    <h3 class="section-title mb-3">Account Analysis Setting</h3>
    <form [formGroup]="accountform">
      <div *ngFor="let acc of account | searchanalysissetting : searchText; let i = index">
        <label *ngIf="account[i].leave_type == currentView" class="form-group" formArrayName="account"
          (change)="checkBox($event,account,i);">
          <input type="checkbox" [formControlName]="i" [name]="account[i].description"
            [checked]="account[i].checked" [(ngModel)]="account[i].checked">
          <span class="control-label normal">{{account[i].description}}</span>
        </label>
      </div>
    </form>
  </div>

  <div class="col-md-12"  *ngIf="showBVN">
    <h3 class="section-title mb-3">BVN Analysis Setting</h3>
    <form [formGroup]="bvnform">
      <div *ngFor="let carde of bvn | searchanalysissetting : searchText; let i = index">
        <label *ngIf="bvn[i].leave_type == currentView" class="form-group" formArrayName="bvn"
          (change)="checkBox($event,bvn,i);">
          <input type="checkbox" [formControlName]="i" [name]="bvn[i].description" [checked]="bvn[i].checked" [(ngModel)]="bvn[i].checked">
          <span class="control-label normal">{{bvn[i].description}}</span>
        </label>
      </div>
    </form>
  </div>


  <!-- <div class="col-md-12" *ngIf="showGuarantor">
    <h3 class="section-title mb-3">Guarantor Analysis Setting</h3>
    <form [formGroup]="guarantorform">
      <div *ngFor="let guarantore of guarantor | searchanalysissetting : searchText; let i = index">
        <label *ngIf="guarantor[i].leave_type == currentView" class="form-group" formArrayName="guarantor"
          (change)="checkBox($event,guarantor,i);">
          <input type="checkbox" [formControlName]="i" [name]="guarantor[i].description"
            [checked]="guarantor[i].checked" [(ngModel)]="guarantor[i].checked">
          <span class="control-label normal">{{guarantor[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <div class="col-md-12" *ngIf="showRepayment">
    <h3 class="section-title mb-3">Repayment Analysis Setting</h3>
    <form [formGroup]="repaymentform">
      <div *ngFor="let repayment of repayments | searchanalysissetting : searchText; let i = index">
        <label *ngIf="repayments[i].leave_type == currentView" class="form-group" formArrayName="repayments"
          (change)="checkBox($event,repayments,i);">
          <input type="checkbox" [formControlName]="i" [name]="repayments[i].description"
            [checked]="repayments[i].checked" [(ngModel)]="repayments[i].checked">
          <span class="control-label normal">{{repayments[i].description}}</span>
        </label>
      </div>
    </form>
  </div>



  <div class="col-md-12"  *ngIf="showCard">
    <h3 class="section-title mb-3">Card Analysis Setting</h3>
    <form [formGroup]="cardform">
      <div *ngFor="let carde of atmcard | searchanalysissetting : searchText; let i = index">
        <label *ngIf="atmcard[i].leave_type == currentView" class="form-group" formArrayName="atmcard"
          (change)="checkBox($event,atmcard,i);">
          <input type="checkbox" [formControlName]="i" [checked]="atmcard[i].checked"
            [name]="atmcard[i].description" [checked]="atmcard[i].checked" [(ngModel)]="atmcard[i].checked">
          <span class="control-label normal">{{atmcard[i].description}}</span>
        </label>
      </div>
    </form>
  </div>

  <!-- <div class="col-md-12"  *ngIf="showCard">
    <h3 class="section-title mb-3">Card Analysis Setting</h3>
    <form [formGroup]="cardform">
      <div *ngFor="let carde of atmcard | searchanalysissetting : searchText; let i = index">
        <label *ngIf="atmcard[i].leave_type == currentView" class="form-group" formArrayName="atmcard"
          (change)="checkBox($event,atmcard,i);">
          <input type="checkbox" [formControlName]="i" [checked]="atmcard[i].checked"
            [name]="atmcard[i].description" [checked]="atmcard[i].checked" [(ngModel)]="atmcard[i].checked">
          <span class="control-label normal">{{atmcard[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <!-- <div class="col-md-12"  *ngIf="showDirectDebit">
    <h3 class="section-title mb-3">Direct Debit Analysis Setting</h3>
    <form [formGroup]="directdebitform">
      <div *ngFor="let directdebite of directdebit | searchanalysissetting : searchText;let i = index">
        <label *ngIf="directdebit[i].leave_type == currentView" class="form-group" formArrayName="directdebit"
          (change)="checkBox($event,directdebit,i);">
          <input [disabled]="counter === 50 && !directdebit[i].checked" type="checkbox" [formControlName]="i" [name]="directdebit[i].description"
            [checked]="directdebit[i].checked" [(ngModel)]="directdebit[i].checked">
          <span class="control-label normal">{{directdebit[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->

  <div class="col-md-12">
    <h3 class="section-title mb-3">Credit Bureau Analysis</h3>
    <form [formGroup]="creditbureauform">
      <div *ngFor="let creditbureaue of creditbureau | searchanalysissetting : searchText;let i = index">
        <label *ngIf="creditbureau[i].leave_type == currentView" class="form-group" formArrayName="creditbureau"
          (change)="checkBox($event,creditbureau,i);">
          <input [disabled]="counter === 50 && !creditbureau[i].checked" type="checkbox" [formControlName]="i" [name]="creditbureau[i].description"
            [checked]="creditbureau[i].checked" [(ngModel)]="creditbureau[i].checked">
          <span class="control-label normal">{{creditbureau[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <!-- <div class="col-md-12">
    <h3 class="section-title mb-3">Loan Spider Analysis</h3>
    <form [formGroup]="loanspiderform">
      <div *ngFor="let loanspidere of loanspider | searchanalysissetting : searchText;let i = index">
        <label *ngIf="loanspider[i].leave_type == currentView" class="form-group" formArrayName="loanspider"
          (change)="checkBox($event,loanspider,i);">
          <input [disabled]="counter === 50 && !loanspider[i].checked" type="checkbox" [formControlName]="i" [name]="loanspider[i].description"
            [checked]="loanspider[i].checked" [(ngModel)]="loanspider[i].checked">
          <span class="control-label normal">{{loanspider[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->
  <div class="col-md-12">
    <h3 class="section-title mb-3">Identity Analysis</h3>
    <form [formGroup]="identityform">
      <div *ngFor="let identitye of identity | searchanalysissetting : searchText;let i = index">
        <label *ngIf="identity[i].leave_type == currentView" class="form-group" formArrayName="identity"
          (change)="checkBox($event,identity,i);">
          <input [disabled]="counter === 50 && !identity[i].checked" type="checkbox" [formControlName]="i" [name]="identity[i].description"
            [checked]="identity[i].checked" [(ngModel)]="identity[i].checked">
          <span class="control-label normal">{{identity[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12">
    <h3 class="section-title mb-3">Bank Statement Analysis</h3>
    <form [formGroup]="bankstatementform">
      <div *ngFor="let bankstatemente of bankstatement | searchanalysissetting : searchText;let i = index">
        <label *ngIf="bankstatement[i].leave_type == currentView" class="form-group" formArrayName="bankstatement"
          (change)="checkBox($event,bankstatement,i);">
          <input [disabled]="counter === 50 && !bankstatement[i].checked" type="checkbox" [formControlName]="i" [name]="bankstatement[i].description"
            [checked]="bankstatement[i].checked" [(ngModel)]="bankstatement[i].checked">
          <span class="control-label normal">{{bankstatement[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <!-- <button class="btn btn-primary pull-right" style="margin-right:15px" [disabled]="loading"
        (click)="submitGlobalAnalysis()">
        <span *ngIf="!loading">Submit settings</span>
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button> -->
</div>