<section>
  <div class="panel box edged_box mb_10">
    <div class="panel-body padding-regular" *ngIf="data">
      <div class="flex">
        
        <div class="flex-3 mb-2 ml-4">
          <h3 class="section-title flex align-center justify-between mb-4">
            Upfront Payment Details
          </h3>
          <ul class="decList bigger">
            
            <li>
              <span>amount</span>
              <span *ngIf="!loading">{{data?.amount}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Bank</span>
              <span *ngIf="!loading">{{data?.bankname}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Account Number</span>
              <span *ngIf="!loading">{{data?.originatoraccountnumber}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Account Name</span>
              <span *ngIf="!loading">{{data?.originatorname}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Reference</span>
              <span *ngIf="!loading">{{data?.paymentreference}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Session ID</span>
              <span *ngIf="!loading">{{data?.sessionid}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
            <li>
              <span>Virtual Account</span>
              <span *ngIf="!loading">{{data?.craccount}}</span>
              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
            </li>
          </ul>

        </div>
      </div>

    </div>
    <div class="panel-body padding-regular" *ngIf="!data">
       <p>No upfront paid</p>
    </div>
  </div>
</section>