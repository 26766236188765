<div class="padding-regular animated fadeIn" *ngIf="grantRight('28')">
  <div class="title mb-4">Files
    <button class="fab bottom floating" *ngIf="view == 'photo'" (click)="addingphoto()">
      <i class="fa fa-plus"></i>
    </button>
    <button class="fab bottom floating" *ngIf="view == 'attach'" (click)="file_type='1';open('attach_form')">
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <div *ngIf="loading">
    <div class="thumbs-wrapper one">
      <div>
        <div class="thumb tile attachment">
          <div class="icon skeleton">
            <i class="fa fa-file"></i>
          </div>
          <div class="texts no-margin">
            <div class="tile-title skeleton mb-1">Title</div>
            <div class="tile-subtitle skeleton mb-1">This is a sample date</div>
          </div>
          <!-- <div class="action flex-center">
                <a class="icon-button" target="_blank"
                  href="{{attach?.FILE_NAME}}"><i
                    class="fa fa-download"></i></a>
              </div> -->
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <ul class="mini-nav">
      <li [ngClass]="{'active': view=='attach' || view == 'attach_form'}">
        <button (click)="open('attach')">Attachment</button>
      </li>
      <li [ngClass]="{'active': view=='photo' || view == 'photo_form'}">
        <button (click)="open('photo')">Photos</button>
      </li>
    </ul>

    <section *ngIf="view == 'photo'">
      <div *ngIf="attachments.length > 0" class="thumbs-wrapper photos four">
        <div *ngFor="let photo of photos">
          <div class="thumb" *ngIf="photo?.FILE_TYPE=='2'&&photo?.FILE_NAME!=''">
            <a target="_blank" href="{{photo?.FILE_NAME}}">
              <img src="{{photo?.FILE_NAME}}">
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="!photos.length">
        <div class="h-group center mb-4 padded empty-state mt-5">
          <div class="icon"><i class="fa fa-image"></i></div>
          <h2 class="title no-float">No Photo</h2>
        </div>
      </div>
    </section>
    <section *ngIf="view == 'attach'" id="uploadedAttachment">
      <div *ngIf="attachments.length > 0" class="thumbs-wrapper one">
        <div *ngFor="let attach of attachments">
          <div class="thumb tile attachment">
            <div class="icon">
              <i class="fa fa-file"></i>
            </div>
            <div class="texts">
              <div class="tile-title">{{attach?.COMMENTS}}</div>
              <div class="tile-subtitle">{{attach?.DATE_ADDED | amDateFormat:'LL'}}</div>
            </div>
            <div class="action flex-center" *ngIf="attach?.CONTRACT_PDF !='1'">
              <a class="icon-button" target="_blank"
                href="{{attach?.FILE_NAME}}"><i
                  class="fa fa-download"></i></a>
            </div>
            <div class="action flex-center" *ngIf="attach?.CONTRACT_PDF =='1'">
              <a class="icon-button" target="_blank"
                href="https://creditclanapis.creditclan.com/pub/attachments/{{attach?.FILE_NAME}}"><i
                  class="fa fa-download"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!attachments.length">
        <div class="h-group center mb-4 padded empty-state mt-5">
          <div class="icon"><i class="fa fa-paperclip"></i></div>
          <h2 class="title no-float">No Attachment</h2>
        </div>
      </div>
    </section>
    <section class="panel padding-regular" *ngIf="view == 'photo_form'">
      <div class="flex justify-between">
        <h4 class="form-title">Add New Photo</h4>
        <button class="icon-button danger" (click)="open('photo')"><i class="fa fa-times"></i></button>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Document Type</label>
          <select class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type1">
            <option *ngFor="let doc of doc_types" [ngValue]="doc">{{doc?.display}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label" for="description1">Description</label>
          <input type="text" name="description" [(ngModel)]="description" class="form-control custom small"
            id="description1">
        </div>
      </div>
      <div class="form-group" *ngIf="photoHasBeenUploaded && filesUploaded.length">
        <ul class="file-list">
          <li *ngFor="let d of filesUploaded">{{d}}</li>
        </ul>
      </div>
      <div class="form-group">
        <input #file style="display: none" (change)="onFilesAdded($event)" type="file"
          accept=".png,.jpg,.jpeg,.txt,.doc,.docx,.xls,.xlsx">
        <button class="btn btn-white" [disabled]="uploadingfile || loading" (click)="openUploadDialog()">
          Add Files <i class="fa fa-spinner fa-spin" *ngIf="uploadingfile"></i>
        </button>
      </div>

      <app-button class="small default mr-2" label="Uplod Document" (click)="uploadThePhoto()"
        [disabled]="description==''|| loading || uploadingfile || filesUploaded.length==0" [loading]="loading"></app-button>
    </section>
    <section class="panel padding-regular" *ngIf="view == 'attach_form'">
      <div class="flex justify-between">
        <h4 class="form-title">Add New Attachment</h4>
        <button class="icon-button danger" (click)="open('attach')"><i class="fa fa-times"></i></button>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type2">Document Type</label>
          <select class="form-control custom small" name="DOCTYPE" [(ngModel)]="DOCTYPE" id="type2">
            <option *ngFor="let doc of doc_types" [ngValue]="doc">{{doc?.display}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label" for="description2">Description</label>
          <input type="text" name="description" [(ngModel)]="description" class="form-control custom small"
            id="description2">
        </div>
      </div>
      <div class="form-group" *ngIf="photoHasBeenUploaded && filesUploaded.length">
        <ul class="file-list">
          <li *ngFor="let d of filesUploaded">{{d}}</li>
        </ul>
      </div>
      <div class="form-group">
        <input #file style="display: none" (change)="onFilesAdded($event)" type="file"
          accept=".png,.jpg,.jpeg,.txt,.doc,.docx,.xls,.xlsx">
        <button class="btn btn-white" [disabled]="uploadingfile||loading" (click)="openUploadDialog()">
          Add Files <i class="fa fa-spinner fa-spin" *ngIf="uploadingfile"></i></button>
      </div>
      <app-button class="small default" label="Uplod Document" (click)="uploadTheFile()"
        [disabled]="description==''|| loading || uploadingfile  || filesUploaded.length==0" [loading]="loading"></app-button>
    </section>
  </div>
</div>
<div class="profile_area animated fadeIn" *ngIf="!grantRight('28')">
  <div>You don't have the right to perform operation on this page</div>
</div>