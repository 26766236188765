import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { StorageService, AnalyticsService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bank-statement-analysis-result',
  templateUrl: './bank-statement-analysis-result.component.html',
  styleUrls: ['./bank-statement-analysis-result.component.css']
})
export class BankStatementAnalysisResultComponent implements OnInit {

  @Input() statement;
  @Output() close = new EventEmitter();
  @Input() from_where = '1';
  currentUser: any;
  analysis: any = null;
  loading = false;
  result:any;
  transaction_id:any;
  tid:any;
  unique_id:any;
  constructor(
    public storageService: StorageService,
    public analyticsService: AnalyticsService,
    public toastr: ToastrService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() { 
    if(this.from_where=='1'){
      if (this.statement.ATTACHMENT_ANALYSIS) this.getData(this.statement.ATTACHMENT_ANALYSIS);
    }else{
      if(this.statement.has_been_analyzed=='1'){
        this.getData(this.statement.analysis_transaction_id);
      }
    }
    
  }

  getData(transaction_id) {
    this.loading = true;
    this.analyticsService.getAnalysisResult(transaction_id).subscribe(({ analytics_data,transaction_id,unique_id }: any) => {
      this.analysis = analytics_data;
     this.tid = transaction_id;
     this.unique_id = unique_id;
      this.loading = false;
    })
  }

  computeBonusDate(bonuses) {
    if (!bonuses) return 0;
    const dates = bonuses.map(({ date }) => date);
    return `${dates[0]} + ${dates.length - 1} date`;
  }

  computeAverageLoans(loans) {
    if (!loans) return 0;
    const total = loans.reduce((acc, loan) => acc + parseFloat(loan.amount), 0);
    return total / loans.length;
  }
}
