<div>
  <div class="col-md-12 main-center pl_0" [ngClass]="{'no-padding': embedModal}">
    <div *ngIf="pageLoading" style="height: 100vh">
      <div class="panel padding-regular flex align-center justify-between">
        <h3 class="section-title">Portfolio</h3>
        <ul class="list-inline skeleton" *ngIf="!requestId">
          <li><a>Contract</a></li>
          <li><a>Profile</a></li>
        </ul>
      </div>
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!pageLoading">
      <div *ngIf="!changingCriteria">
        <app-contractheader [text]="'Eligibility'" *ngIf="loan?.loan_status < 3 && !requestId"
          [request_id]="parentRouteId">
        </app-contractheader>
        <app-applicationportfolioheader *ngIf="loan?.loan_status >= 3 && !requestId" [loan]="loan"
          [request_id]="parentRouteId">
        </app-applicationportfolioheader>
        <div class="panel box mb_5" *ngIf="status">
          <div class="panel-body padding-regular">
            <div class="flex justify-between align-center">
              <div class="icon-text">
                <div class="icon" *ngIf="eligibility?.output?.eligible_status == 'eligible'"><i
                    class="fa fa-check color-green"></i></div>
                <div class="icon" *ngIf="eligibility?.output?.eligible_status == 'not eligible'"><i
                    class="fa fa-times color-red"></i></div>
                <p class="text">Eligibility</p>
              </div>
              <div class="icon-text">
                <div class="color-green" style="font-weight: 600">
                  <span class="icon">{{eligibility?.output?.total_eligibilty_score|number:'.2-2'}}%</span>
                </div>
                <p class="text">Eligibility Score</p>
              </div>
              <div>
                <app-button [loading]="rerunning" (click)="rerunEligibility()" class="small outline" label="Rerun">
                </app-button>

                <app-button [loading]="rerunning" (click)="analyseCustomer()" class="small outline"
                  label="Change Criteria">
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="view=='details'">
          <div *ngIf="!status">
            <div class="panel padding-regular">
              <app-help-popover [content]="[
                {
                  title: 'No eligibility',
                  img: 'assets/images/slash.svg',
                  text:
                    '
            <div>
              <p> No eligibility has been done
              </p>
            </div>'
                }
              ]"></app-help-popover>
              <div class="flex" style="min-height: 270px">
                <div class="flex-1">
                  <div class="section-poster">
                    <img src="assets/images/ban.svg" alt="incognito Icon">
                  </div>
                </div>
                <div class="flex-1 flex flex-column justify-end">
                  <div class="h-group flex flex-column align-end text-right">
                    <h3 class="title w-75" style="font-size: 1.9rem">No Eligibility check has been done</h3>
                    <div class="mt-3">
                      <app-button label="Analyse" class="medium" [loading]="rerunning" (click)="analyseCustomer()">
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="panel box edged_box mb-1">
            <div class="alert padding-regular bg-off-white">
              <div class="texts no-padding">
                <h4 class="title">Defined Eligibility Settings</h4>
                <p class="subtitle">Adjust your eligibility criteria</p>
              </div>
              <div class="action">
                <app-button label="Settings" (click)="view='settings';doRefresh = false;" buttonclass="small outline">
                </app-button>
              </div>
            </div>
          </div> -->
            <!-- <div class="panel box edged_box mb-1">
            <div class="alert padding-regular bg-off-white">
              <div class="texts no-padding">
                <h4 class="title">Global Eligibility Settings</h4>
                <p class="subtitle">Adjust your eligibility criteria</p>
              </div>
              <div class="action">
                <app-button label="View Settings" (click)="openSettings()" buttonclass="small outline"></app-button>
              </div>
            </div>
          </div> -->
          </div>
          <div *ngIf="currentview=='settings'">
            <div id="accordion" role="tablist" class="mb-1">
              <section class="filter-items">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingTwo">
                    <h4 class="panel-title">
                      <a class="collapsed flex flex-row justify-between align-center" role="button"
                        data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        <span>Repayment Card</span><button class="btn btn-link btn-sm text-muted"><i
                            class="fa fa-chevron-down"></i></button>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-6" *ngFor="let leaf of leaves?.card;let i = index">
                          <div class="form-group" style="margin-bottom:0px">
                            <span class="pull-left text-primary">{{leaf.description}}</span>
                            <span class="pull-right">
                              <div class="checkbox" style="margin:0px !important">
                                <label>
                                  <input type="checkbox" [(ngModel)]="leaf.enabled">
                                </label>
                              </div>
                            </span>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div *ngIf="status">
            <div class="panel mb-1">
              <app-help-popover [content]="[
                {
                  title: 'Eligibility scoring',
                  img: 'assets/images/eligibility.svg',
                  text:
                    '
            <div>
              <p>Eligibility scoring scores a customer on bank account, BVN, repayment, profile,
              </p>
            </div>'
                }
              ]"></app-help-popover>
              <div class="panel-body padding-regular">
                <div class="flex">
                  <div class="section-poster">
                    <div class="image flex-center">
                      <img src="assets/images/eligibility.svg">
                    </div>
                  </div>
                  <div class="flex-3 mb-2 ml-4">
                    <div class="panel pd_15 mb-3">
                      <h4 class="section-title mb-2 border-bottom medium-text-size">Legend</h4>
                      <ul class="legend_flex no-margin">
                        <li class="medium-text-size"><i class="fa fa-check color-green mr-1"></i><span>Passed</span>
                        </li>
                        <li class="medium-text-size"><i class="fa fa-check color-red mr-1"></i><span>Failed</span></li>
                        <li class="medium-text-size"><i class="fas fa-question-circle mr-1"></i>
                          <span>Not specified in your settings</span>
                        </li>
                      </ul>
                    </div>
                    <ul class="decList bigger" *ngIf="eligibility != null">
                      <ng-container *ngIf="data_status?.account">
                        <li><span class="title">Bank Account</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.account_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.card">
                        <li><span class="title">Repayment Card</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.card_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.bvn">
                        <li><span class="title">BVN</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.bvn_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.repayments">
                        <li><span class="title">Repayments</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.repayments_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.profile">
                        <li><span class="title">Profile</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.profile_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.guarantor">
                        <li><span class="title">Guarantor</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.guarantor_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.direct_debit">
                        <li><span class="title">Direct Debit</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.direct_debit_">
                          <span class="text-primary analysis_text">{{analyse.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.calllogs">
                        <li><span class="title">Call Logs</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.calllogs_">
                          <span class="text-primary analysis_text">{{analyse.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.social">
                        <li><span class="title">Social</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.social_">
                          <span class="text-primary analysis_text">{{analyse.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.identity">
                        <li><span class="title">Identity</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.identity_">
                          <span class="text-primary analysis_text">{{analyse.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.credit_bureau">
                        <li><span class="title">Credit Bureau</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.credit_bureau_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.bankstatement">
                        <li><span class="title">Bank Statement Analysis</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.bankstatement_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="data_status?.loanspider">
                        <li><span class="title">Loan Spider</span></li>
                        <li *ngFor="let analyse of eligibility?.description?.loanspider_">
                          <span class="text-primary analysis_text">{{analyse?.display}}</span>
                          <span *ngIf="analyse?.status == 'passed'">
                            <i class="fa fa-check color-green"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'failed'">
                            <i class="fa fa-times color-red"></i>
                          </span>
                          <span *ngIf="analyse?.status == 'undefined'">
                            <i class="fas fa-question-circle"></i>
                          </span>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel mb-1" *ngIf="status">
              <app-help-popover [content]="[
                {
                  title: 'Recommended Offer',
                  img: 'assets/images/offer.svg',
                  text:
                    '
                  <div>
                    <p>Recommended offer, based on your Lenders settings.
                    </p>
                  </div>'
                }
              ]"></app-help-popover>
              <div class="panel-body padding-regular">
                <div *ngIf="!editingBands" class="flex">
                  <div class="flex-3 mb-2 mr-4" *ngIf="recommended?.status==true">
                    <ul class="decList bigger">
                      <li><span class="title">Recommended Offer</span></li>
                      <li><span class="text-primary">Principal</span><span>N{{recommended?.band?.MAXIMUM_AMOUNT}}</span>
                      </li>
                      <li><span class="text-primary">Duration</span><span>{{recommended?.band?.LOAN_DURATION}}
                          Months</span>
                      </li>
                      <li><span class="text-primary">Interest</span><span>{{recommended?.band?.MAXIMUM_RATE}}%</span>
                      </li>
                    </ul>
                    <div class="mt-3">
                      <app-button (click)="saveBandForCustomer(loan?.people_id,recommended?.band)"
                        class="small green mr-2" label="Save for Customer">
                      </app-button>
                      <app-button (click)="editingBands=!editBands" class="small green" label="Settings">
                      </app-button>
                    </div>
                  </div>
                  <div class="flex-3 mb-2 mr-4" *ngIf="recommended?.status==false">
                    <p>No offer</p>
                    <div class="mt-3" *ngIf="loan?.loan_status > 0">
                      <app-button (click)="reopenRequest(loan?.request_id)" class="small green" label="Reopen Request">
                      </app-button>
                    </div>
                  </div>
                  <div class="section-poster">
                    <div class="image flex-center">
                      <img src="assets/images/offer.svg">
                    </div>
                  </div>
                </div>
                <app-scoredbasedinterestrate [where_from]="where_from" (changeView)="editingBands=!editingBands"
                  *ngIf="editingBands"></app-scoredbasedinterestrate>
              </div>
            </div>

            <div class="panel">
              <div class="alert padding-regular bg-off-white">
                <div class="texts no-padding">
                  <h4 class="title">Settings</h4>
                  <p class="subtitle">Adjust your eligibility criteria</p>
                </div>
                <div class="action">
                  <app-button label="Settings" (click)="view='settings';doRefresh = false;" buttonclass="small outline">
                  </app-button>
                </div>
              </div>
            </div>
            <!--<div class="panel box edged_box mb-1">-->
            <!--<div class="alert padding-regular bg-off-white">-->
            <!--<div class="texts no-padding">-->
            <!--<h4 class="title">View profile of {{loan?.customer?.legal_name}}</h4>-->
            <!--<p class="subtitle">&nbsp;</p>-->
            <!--</div>-->
            <!--<div class="action">-->
            <!--<app-button (click)="reopenRequest(loan?.request_id)" label="View" buttonclass="small outline"></app-button>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>

        <div *ngIf="view === 'settings'">
          <app-profile-analysis [where_from]='where_from' (changeView)="view='details'"
            (manualEligibity)="manualEligibity($event)" [request_id]="loan?.request_id" [doRefresh]="doRefresh">
          </app-profile-analysis>
        </div>

        <div *ngIf="view !== 'settings'">
          <!-- <div class="panel box edged_box mb-1">
            <div class="alert padding-regular bg-off-white">
              <div class="texts no-padding">
                <h4 class="title">Analytics</h4>
                <h4 class="title">Defined eligibility settings</h4>
                <p class="subtitle">Run analytics on {{loan?.customer?.legal_name}}</p>
              </div>
              <div class="action">
                <app-button label="View" (click)="view = 'settings'" buttonclass="small outline">
                </app-button>
              </div>
            </div>
          </div> -->
          <div class="panel box edged_box mb-1">
            <div class="alert padding-regular bg-off-white">
              <div class="texts no-padding">
                <h4 class="title">20+ Eligibility Points</h4>
                <h4 class="title">Global eligibility settings</h4>
                <p class="subtitle">Adjust your eligibility criteria</p>
              </div>
              <div class="action">
                <app-button label="View Settings" (click)="openSettings()" buttonclass="small outline"></app-button>
              </div>
            </div>
          </div>
          <div class="panel box edged_box mb_5">
            <app-help-popover [content]="[
            {
              title: 'invite for eligibility',
              img: 'assets/images/love-letter.svg',
              text:
                '
        <div>
          <p>
          </p>
        </div>'
            }
          ]"></app-help-popover>

            <!-- <div class="panel-body padding-regular" style="padding-top: 4rem!important">
            <app-help-popover [content]="[
              {
                title: 'invite for eligibility',
                img: 'assets/images/love-letter.svg',
                text:
                  '
          <div>
            <p>
            </p>
          </div>'
              }
            ]"></app-help-popover>
            <div class="flex align-center mt-5 mb-5">
              <div class="section-poster flex-1">
                <div class="image flex-center"><img src="http://700lenders.com/images/crediblesmockup.png"
                    style="position: absolute; bottom: 0; width: 150px!important; left: -5px;opacity: 1;">
                </div>
              </div>
              <div class="h-group flex-1">
                <h3 class="title no-margin">Invite for Eligibility</h3>
                <p class="subtitle fill-w mb-2">Everything you want in an analytics of your platform is right available
                  for
                  you anyway you want it to play</p>
                <button type="button" [disabled]="!loan" (click)="openModal('invite_for_eligibilty')"
                  class="btn btn-outline-secondary mt-5">Send Invite
                </button>
              </div>
            </div>
          </div> -->

          </div>
        </div>
      </div>
      <div *ngIf="changingCriteria">
        <div class="padding-regular animated fadeIn">
          <div class="title mb-4">Accounts
          </div>
          <div>
        
            <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.account?.status == true">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="type1">Select account to analyse</label>
                  <select class="form-control custom small" name="account_to_analyse" [(ngModel)]="criteria.account_to_analyse" id="type1">
                    <option [value]="0"></option>
                    <option [value]="acc.account_card_id" *ngFor="let acc of eligibilitycriteria?.criteria?.account?.data">{{acc.last_four_digits}}/{{acc.card_name}}/{{acc.bank_name}}</option> 
                  </select>
                  <div class="subtitle"><label for="skip_account"><input type="checkbox" name="skip_account" [(ngModel)]="criteria.skip_account"> Skip</label></div>
                </div>
        
              </div>
            </section>
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.account?.status == false">
              <div class="alert">
                <div class="texts no-padding">
                  <div class="title">No account found</div>
                  <div class="subtitle">Send account request</div>
                  <div class="subtitle"><label for="skip_account"><input type="checkbox" name="skip_account" [(ngModel)]="criteria.skip_account"> Skip</label></div>
                </div>
                <div class="action">
                  <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send Account Request" (click)="confirmAccount()"></app-button> 
                </div>
              </div>
            </div>
          </div>
          <div class="title mb-4">Cards
          </div>
          <div>
        
            <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.card?.status == true">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="type1">Select card to analyse</label>
                  <select class="form-control custom small" name="card_to_analyse" [(ngModel)]="criteria.card_to_analyse" id="type1">
                    <option [value]="0"></option>
                    <option [value]="acc.ACCOUNT_CARD_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.card?.data">**** **** **** {{acc.LAST_FOUR_DIGITS}}|{{acc.EXPIRY_DATE}}|{{getCardBrand(acc)}}</option> 
                  </select>
                  <div class="subtitle"><label for="skip_card"><input type="checkbox" name="skip_card" [(ngModel)]="criteria.skip_card"> Skip</label></div>
                </div>
        
              </div>
            </section>
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.card?.status == false">
              <div class="alert">
                <div class="texts no-padding">
                  <div class="title">No card found</div>
                  <div class="subtitle">Send card request</div>
                  <div class="subtitle"><label for="skip_card"><input type="checkbox" name="skip_card" [(ngModel)]="criteria.skip_card"> Skip</label></div>
                </div>
                <div class="action">
                  <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send Card Request" (click)="requestCard()"></app-button> 
                </div>
              </div>
            </div>
          </div>
          <div class="title mb-4">Identity
          </div>
          <div>
        
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.nin?.status == false">
              <div class="alert">
                <div class="texts no-padding">
                  <div class="title">No identity details found</div>
                  <div class="subtitle">Send request for nin details</div>
                  <div class="subtitle"><label for="skip_identity"><input type="checkbox" name="skip_identity" [(ngModel)]="criteria.skip_identity"> Skip</label></div>
                </div>
                <div class="action">
                  <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send NIN Request" (click)="requestNIN()"></app-button> 
                </div>
              </div>
            </div> 
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.nin?.status == true">
              <div class="padding-regular flex justify-between align-center">
                <div class="user"> 
                  <div class="image">
                    <img
                      src="{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.Image}}">
                  </div>
                  <div class="content">
                    <h4 class="title color-teal">{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.LastName}} {{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.FirstName}}</h4>
                    <p class="subtitle">{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.DOB}}</p>
                    
                  </div>
                </div>
                <div class="subtitle"><label for="skip_identity"><input type="checkbox" name="skip_identity" [(ngModel)]="criteria.skip_identity"> Skip</label></div>
              </div>
            </div>
          </div>
          <div class="title mb-4">Credit Bureau
          </div>
          <div>
        
            <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.cb?.status == true">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="type1">Select credit bureau to analyse</label>
                  <select class="form-control custom small" name="credit_bureau_to_analyse" [(ngModel)]="criteria.credit_bureau_to_analyse" id="type1">
                    <option [value]="0"></option>
                    <option [value]="acc.CREDITCHECK_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.cb?.data">{{acc.DATE_ADDED}}</option> 
                  </select>
                </div>
                <div class="subtitle"><label for="skip_credit_bureau"><input type="checkbox" name="skip_credit_bureau" [(ngModel)]="criteria.skip_credit_bureau"> Skip</label></div>
              </div>
            </section>
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.cb?.status == false">
              <div class="alert">
                <div class="texts no-padding">
                  <div class="title">No credit bureau details found</div>
                  <div class="subtitle">Send credit bureau request</div>
                  <div class="subtitle"><label for="skip_credit_bureau"><input type="checkbox" name="skip_credit_bureau" [(ngModel)]="criteria.skip_credit_bureau"> Skip</label></div>
                </div>
                <div class="action">
                  <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Run Credit Check" (click)="requestCB()"></app-button> 
                </div>
              </div>
            </div>
          </div> 
          <div class="title mb-4">Bank Statement
          </div>
          <div>
        
            <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.bs?.status == true">
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="control-label" for="type1">Select bank statement to analyse</label>
                  <select class="form-control custom small" name="bankstate_to_analyse" [(ngModel)]="criteria.bankstate_to_analyse" id="type1">
                    <option [value]="0"></option>
                    <option [value]="acc.ATTACHMENT_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.bs?.data">{{acc.COMMENTS}}|{{acc.DATE_ADDED}}</option> 
        
                  </select>
                </div>
                <div class="subtitle"><label for="skip_bank_statement"><input type="checkbox" name="skip_bank_statement" [(ngModel)]="criteria.skip_bank_statement"> Skip</label></div>
              </div>
            </section>
            <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.bs?.status == false">
              <div class="alert">
                <div class="texts no-padding">
                  <div class="title">No bank statements found</div>
                  <div class="subtitle">Send bank statement request</div>
                  <div class="subtitle"><label for="skip_bank_statement"><input type="checkbox" name="skip_bank_statement" [(ngModel)]="criteria.skip_bank_statement"> Skip</label></div>
                </div>
                <div class="action">
                  <app-button *ngIf="!loading" class="small outline mr-1" label="Run Bank Statement" (click)="requestBS()"></app-button> 
                </div>
              </div>
            </div>
          </div> 
          <div>
        
            <section class="panel padding-regular">
             
          
              <app-button class="small default mr-2" label="Run Eligibility" (click)="rerunEligibility()"  [loading]="loading"></app-button>
            </section>
          </div> 
        </div>
      </div>
    </div>


  </div>
</div>