<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[CC_NUMBER]')" href="javascript:;">CC Number</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[LEGAL_NAME]')" href="javascript:;">Legal Name</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[ACCOUNT_NUMBER]')" href="javascript:;">Account Number</a>
</li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[ADDRESS]')" href="javascript:;">Address</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[CURRENT_DATE]')" href="javascript:;">Current Date</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[EMAIL]')" href="javascript:;">Email</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[BVN_NUMBER]')" href="javascript:;">Bvn Number</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[LENDER]')" href="javascript:;">Lender</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[OBLIGOR]')" href="javascript:;">Obligor</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FACILITY_TYPE]')" href="javascript:;">Facility Type</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FACILITY_AMOUNT]')" href="javascript:;">Facility Amount</a>
</li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[PURPOSE]')" href="javascript:;">Purpose</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[TENURE]')" href="javascript:;">Tenure</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FIRST_REPAYMENT_DATE]')" href="javascript:;">First repayment
    date</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[CC_NUMBER]')" href="javascript:;">CC Number</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[LEGAL_NAME]')" href="javascript:;">Legal Name</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[ACCOUNT_NUMBER]')" href="javascript:;">Account Number</a>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[ACCOUNT_NUMBER]')" href="javascript:;">Account Number</a>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[ACCOUNT_NUMBER]')" href="javascript:;">Account Number</a>
</li>
<li><a style="padding: 3px 20px" style="padding: 3px 20px" (click)="insertTextAtCursor('[ADDRESS]')" href="javascript:;">Address</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[CURRENT_DATE]')" href="javascript:;">Current Date</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[EMAIL]')" href="javascript:;">Email</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[BVN_NUMBER]')" href="javascript:;">Bvn Number</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[LENDER]')" href="javascript:;">Lender</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[OBLIGOR]')" href="javascript:;">Obligor</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FACILITY_TYPE]')" href="javascript:;">Facility Type</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FACILITY_AMOUNT]')" href="javascript:;">Facility Amount</a>
</li>
<li><a style="padding: 3px 20px" style="padding: 3px 20px" (click)="insertTextAtCursor('[PURPOSE]')" href="javascript:;">
    Purpose</a>
</li>
<li>
  <a style="padding: 3px 20px" (click)="insertTextAtCursor('[AMOUNT_PAID]')" href="javascript:;">
    Amount Paid</a>
</li>
<li>
  <a style="padding: 3px 20px" (click)="insertTextAtCursor('[DATE_PAID]')" href="javascript:;">
    Date Paid</a>
</li>
<li>
  <a style="padding: 3px 20px" (click)="insertTextAtCursor('[TRN_REF]')" href="javascript:;">
    TRN Ref</a>
</li>
<li>
  <a style="padding: 3px 20px" (click)="insertTextAtCursor('[LOAN_ID]')" href="javascript:;">
    Loan ID</a>
</li>
<li>
  <a style="padding: 3px 20px" (click)="insertTextAtCursor('[DATE_PAID]')" href="javascript:;">
    Date Paid</a>
</li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[TENURE]')" href="javascript:;">Tenure</a></li>
<li><a style="padding: 3px 20px" (click)="insertTextAtCursor('[FIRST_REPAYMENT_DATE]')" href="javascript:;">First repayment
    date</a></li>
    