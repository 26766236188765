<!-- <div class="header"> -->
<!-- <div class="user">
    <div class="image">
      <img
        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">
    </div>
    <div class="content">
      <h4 class="title">{{loan?.customer?.legal_name}}</h4>
      <p class="subtitle" *ngIf="loan?.is_top_up !='8'">{{loan?.request_principal | money}} | {{loan?.request_tenor}}{{loan?.loan_duration}}</p>
      <p class="subtitle" *ngIf="loan?.is_top_up =='8'">{{loan?.top_up_amount | money}} | {{loan?.request_tenor}}{{loan?.loan_duration}}</p> 
    </div>
  </div> -->

<!-- <template [ngSwitch]="where">
    <template *ngSwitchCase="'cards'">
      <h3 class="title">Cards</h3>
    </template>
    <template *ngSwitchCase="'accountdebit'">
      <h3 class="title">Account debit</h3>
    </template>
    <div *ngSwitchDefault></div>
  </template> -->

<h5 class="title flex justify-between align-center" *ngIf="where == 'cards'">Card debit
  <div class="input-group">
    <select class="form-control" name="ACCOUNT_CARD_ID" [(ngModel)]="card_selected"
      (change)="changeTheDefaultCard($event)">
      <option selected disabled>Select Card</option>
      <option *ngFor="let card of cards" [ngValue]="card">{{card?.brand}}-{{card?.bin}}</option>
    </select>
    <div class="input-group-btn">
      <button type="button" class="btn dropdown-toggle border-teal" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
      <ul class="dropdown-menu dropdown-menu-right" role="menu">
        <!-- <li><a>Default Repayment</a></li> -->
        <li (click)="requestForNewCard_()"><a>Add Card Request</a></li>
        <!-- <li><a>Change Cards</a></li> -->
        <li *ngIf="!card_selected?.default" (click)="setCardAsDefault()"><a>Set as default</a></li>
        <li *ngIf="loan?.loan_status==3" (click)="showHistory()"><a>Autodebit History</a></li>
      </ul>
    </div>
  </div>
</h5>



<h5 class="title flex justify-between align-center" *ngIf="where == 'accountdebit'">Direct Debit Account

  <app-button (click)="setUpDD()" class="small green mb-1 mr-1" label="Set up Direct Debit"></app-button>

<!-- <button (click)="setUpDD()" class="btn-md btn-success">Set up Direct Debit</button> -->

  <!-- <div class="input-group">
    <select class="form-control" name="ACCOUNT_CARD_ID" [(ngModel)]="account_selected"
      (change)="changeTheDefaultAccount($event)">
      <option selected disabled>--select--</option>
      <option *ngFor="let account of accounts" [ngValue]="account">{{account?.bank_name}}
        -{{account?.account_number}}</option>
    </select>
    <div class="input-group-btn">
      <button type="button" class="btn dropdown-toggle border-teal" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
      <ul class="dropdown-menu dropdown-menu-right" role="menu">
        <li class="" (click)="requestForNewAccount_()"><a>Send Account Request</a></li>
        <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE !='1'"
          (click)="checkDDStatus()"><a>Check Status</a></li>
        <li *ngIf="account?.direct_debit_exists" (click)="cancelDD()"><a>Cancel</a></li>
        <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE !='1'"
          (click)="resendLink()"><a>Resend Link</a></li>
        <li ><a></a></li>
      </ul>
    </div>
  </div> -->

</h5>