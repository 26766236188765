import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-emailtags',
  templateUrl: './emailtags.component.html',
  styleUrls: ['./emailtags.component.css']
})
export class EmailtagsComponent implements OnInit {
  @Output() iTAC = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  insertTextAtCursor(text){
    this.iTAC.emit(text);
  }
}
