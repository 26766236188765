<div *ngIf="has_data">
  <div class="panel box mb_5 edged_box">
    <div class="panel-body">
      <div class="flex">
        <div class="flex-3">
          <div class="h-group mb-4">
            <h3 class="title">{{request_duration}} days is the longest TAT</h3>
            <p class="subtitle">Do something about this request</p>
          </div>
          <ul class="decList bigger">
            <!-- <li><span class="text-muted">Do something about this request</span><span></span></li> -->
            <li><span class="text-primary">Principal</span><span
                class="pull-right">{{tat_data?.request_principal}}</span></li>
            <li><span class="text-primary">Duration</span><span class="pull-right">{{request_tenor}}</span>
            </li>
            <li><span class="text-primary">TAT</span><span class="pull-right">{{tat_data?.duration}} days</span></li>
          </ul>
        </div>
        <div class="ml-4 flex-1 flex-center">
          <img
            [src]="'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/'+tat_data?.file_name"
            width="100px" class="tat_img">
        </div>
      </div>
    </div>
  </div>
</div>