<div class="section-title mb-4 cursor-pointer" (click)="cancel.emit()" *ngIf="!loading">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>


<div>
  <div class="panel padding-regular flex">
    <div class="panel-body padding-regular">
      <h3 class="section-title mb-4">Transactions</h3>
      <div class="contract-table">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Naration</th>
              <th>Type</th>
              <th>Balance</th> 

              
            </tr>
          </thead>
         
          <tbody> 
            <tr *ngFor="let trans of statement?.data">
              <td>{{trans.date}}</td>
              <td>{{(trans.amount/100)|number:'.2-2'}}</td>
              <td>{{trans.narration}}</td>
              <td>{{trans.type}}</td>
              <td>{{(trans.balance/100)|number:'.2-2'}}</td> 
            </tr>
           
        </table>
      </div>  
    </div>
  </div>
</div>

<div *ngIf="statement.paging.previous" class="section-title mb-4 cursor-pointer pull-left" (click)="getMoreRecords(statement.paging.previous)">
  <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
</div>

<div *ngIf="statement.paging.next" class="section-title mb-4 cursor-pointer pull-right" (click)="getMoreRecords(statement.paging.next)">
  <i class="fas fa-long-arrow-alt-right mr-3"></i> Next
</div>
