import { Component, OnInit } from '@angular/core';
import { DataService, LoansService, OperationsService, StorageService } from '../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wemainflows',
  templateUrl: './wemainflows.component.html',
  styleUrls: ['./wemainflows.component.css']
})
export class WemainflowsComponent implements OnInit {
  currentUser:any;
  loading = false;
  data:any;
  selectedDate=''
  constructor(
    public toastr: ToastrService,
    public router: Router,
    private dataService: DataService,private loansService: LoansService, private operationsService: OperationsService, private storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
     
  }

  ngOnInit() {
     
  }
  getRecords(){
     
    this.loading = true;
    this.operationsService.getWemaInflows(this.currentUser.token,this.selectedDate)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
         
      });
  }
}
