import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { DataService } from 'src/app/_services';

@Component({
  selector: 'app-emailtemplatedesign',
  templateUrl: './emailtemplatedesign.component.html',
  styleUrls: ['./emailtemplatedesign.component.css']
})
export class EmailtemplatedesignComponent implements OnInit {
  viewTitle = 'Welcome Email';
  email_type = '14';
  mailBodySection = '3';
  loadingRouteConfig: boolean;
  location='welcome_email';
  constructor(private router: Router, private dataService: DataService) { 
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: true });
      } else if (event instanceof RouteConfigLoadEnd) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: false });
      }
    });
  }

}
