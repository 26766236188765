import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoansService } from '../_services/loans.service';
import { StorageService } from '../_services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { Router } from '@angular/router';
@Component({
  selector: 'app-created-task',
  templateUrl: './created-task.component.html',
  styleUrls: ['./created-task.component.css']
})
export class CreatedTaskComponent implements OnInit {

  tasks: any;
  perform_by = localStorage.getItem('user_id');
  currentUser: any;
  request_id: any;
  comment: string;
  loading: boolean;
  passed_task_id: any;
  createdTasks: any;
  showTask: boolean;
  taskCount: number;
  taskSubscription: Subscription;
  countTaskSubscription: Subscription;
  updateTaskSubscription: Subscription;

  constructor(public toastr: ToastrService,
    private storageService: StorageService,
    public route: ActivatedRoute,
    private loansService: LoansService) { 
      this.currentUser = this.storageService.read<any>('currentUser');
      this.request_id = route.snapshot.parent.params['id'];
    }

  ngOnInit() {
    this.countTasks();
    this.myCreatedTasks();
  }


  myCreatedTasks() {
    this.loading = true;
    this.taskSubscription = this.loansService.myCreatedTasks(this.currentUser.token, this.perform_by)
    .subscribe(result => {
      
      this.loading = false;
        if(result.status==false){
          this.tasks = [];
        }else{
          this.tasks = result;
        }
    });
  }

  countTasks() {
    this.loansService.countOpenTasks(this.currentUser.token, this.perform_by)
    .subscribe(result => {
        this.taskCount = result;
    });
  }

  deleteTask(task_id) {
    const confirmDelete = confirm("Are you sure you want to delete task?");
    if (confirmDelete) {
      this.loansService.deleteTask(this.currentUser.token, task_id)
      .subscribe(result => {
          this.taskCount = this.taskCount - 1;
          this.myCreatedTasks();
      });
    }
  }
  
  updateComment() {
    this.loading = true;
    this.updateTaskSubscription = this.loansService.staffUpdateTask(this.currentUser.token, this.passed_task_id,
      this.comment, this.perform_by)
    .subscribe(result => {
        this.toastr.success('Success', 'Comment saved!');
        this.loading = false;
        this.tasks = result;
    });
  }
  
  addComment(task_id) {
    this.showTask = true;
    this.passed_task_id = task_id;
  }

  ngOnDestroy() {
    // this.taskSubscription.unsubscribe();
    // this.updateTaskSubscription.unsubscribe();
  }

}
