
<!-- Individual Profile Editing Section -->
<ul class="panel padding-regular">
  <li class="flex justify-between align-center">
    <h3 class="section-title">Basic details</h3>
    <div>
      <!-- <a data-sub="basic" class="icon-button hide">
        <i class="fa fa-chevron-up"></i>
      </a>
      <a (click)="toogleView('basicInfo')" data-sub="basic" class="icon-button">
        <i class="fa fa-chevron-down"></i>
      </a> -->
    </div>
  </li>

  <li class="mt-4">
    <!-- editable form -->
    <!-- <div class="_profile_edit_toogle panel-body"> -->
    <form #f="ngForm" novalidate class="row">
      <div class="col-md-9 no-padding">
        <div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label normal">Fullname</label>
              <input type="text" class="form-control" name="LEGAL_NAME" [(ngModel)]="user.LEGAL_NAME">
              <div class="error otp_error alert alert-block alert-danger fade in hide">
                Please enter your fullname
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label normal">Email</label>
              <input [disabled]="user.USE_AUTOMATED_EMAIL" type="email" class="form-control" name="EMAIL" [(ngModel)]="user.EMAIL">
              <label><input type="checkbox" [(ngModel)]="user.USE_AUTOMATED_EMAIL" name="USE_AUTOMATED_EMAIL"> No email</label>
              <div class="error otp_error alert alert-block alert-danger fade in hide"> Please
                enter your email </div>
            </div>
          </div>
        </div>
        <div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label normal">Phone</label>
              <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-white"><span
                      class="">({{selectedCountryCode}})</span></button>
                  <button style="padding:6px;" data-toggle="dropdown"
                    class="btn btn-white dropdown-toggle" type="button">
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" style="padding:5px">
                    <li *ngFor="let c of countries">
                      <a href="javascript:;" (click)="changeCurrency(c)">+{{c?.callingCode[0]}}</a>
                    </li>
                  </ul>
                </div>
                <input type="text" name="PHONE" id="PHONE" [(ngModel)]="user.PHONE" class="form-control">
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
          <div class="form-group">
            <div class="error phone_error alert alert-block alert-danger fade in hide">
            </div>
          </div>
        </div> -->
          <div class="col-md-6 ">
            <div class="form-group">
              <label class="control-label normal">Date of birth </label>
              <input type="text" class="form-control" name="DATE_OF_BIRTH"
                [(ngModel)]="user.DATE_OF_BIRTH" ngui-datetime-picker date-only="true">
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12">
          <div class="form-group">
            <div class="error rent_error alert alert-block alert-danger fade in hide"> </div>
          </div>
        </div> -->
        <div>
          <div class="col-md-6">
            <!-- <div class="col-md-5">
                  <label class="control-label normal">Nationality</label>
                  <select class="form-control" name="NATIONALITY" [(ngModel)]="user.NATIONALITY">
                              <option value="0"></option>
                              <option *ngFor="let country of countries" [value]="country.calling_code[0]"> {{country.name['common']}} </option>
                  </select>
                <div class="error otp_error alert alert-block alert-danger fade in hide"> Please select your nationality </div>
               -->
            <div class="form-group">
              <label class="control-label normal">Gender</label>
              <select class="form-control" name="GNEDER" [(ngModel)]="user.GENDER">
                <option *ngFor="let sex of gender" [value]="sex.value">{{sex.display}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label normal">Marital Status</label>
              <select class="form-control" name="MARITAL_STATUS" [(ngModel)]="user.MARITAL_STATUS">
                <option *ngFor="let status of marital_status" [value]="status.value">
                  {{status.display}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label normal">Referrer Code</label>
            <input class="form-control" name="REFEREE_ID" [(ngModel)]="user.REFEREE_ID">
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="form-group">
            <input type="checkbox" name="SEND_WELCOME_EMAIL" [(ngModel)]="user.SEND_WELCOME_EMAIL">
            <label class="control-label normal">Send Welcome email</label>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <app-button (click)="updateBasicInfo(f.value, f.valid)" type="button"
              [disabled]="loading||user.LEGAL_NAME==''||user.EMAIL==''||user.PHONE==''" class="small"
              [loading]="loading" [label]="actionText"></app-button>
          </div>
        </div>
        <div *ngIf="saveCustomerFirst" class="alert alert-block alert-danger fade in">
          Please save the basic details
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group profile-nav">
          <div class="flex justify-end mt-5">
            <a class="image-avatar large">
              <img class="prev_img_1"
                src="http://app.creditclan.com/creditclan_demo/pub/attachments/avatar-mini.jpg">
            </a>
            <input type="hidden" name="ATTACHMENT1_ID" [(ngModel)]="user.ATTACHMENT1_ID">
            <input type="hidden" name="PEOPLE_ID" [(ngModel)]="user.PEOPLE_ID">
            <input type="hidden" name="IS_EDIT" [(ngModel)]="user.IS_EDIT">
            <input type="hidden" name="CUSTOMER_TYPE" [(ngModel)]="user.CUSTOMER_TYPE" value="1">
            <input type="file" class="att-add-file" style="display:none">
          </div>
        </div>
      </div>
    </form>
    <!-- </div> -->
  </li>
</ul> 