<div class="padding-regular-y" [ngClass]="{'overpanel-scroll': scroll}">
  <section class="panel contract_area hideable_area" style="margin-bottom: 10px" *ngIf="where=='1'">
    <header class="panel-heading">
      <span class="tools pull-right">
        <ul class="list-inline">
          <li>
            <a [routerLink]="['../contract']">Summary</a>
          </li>
          <li>
            <a [routerLink]="['../repayments']">Schedule</a>
          </li>
          <li>
            <a [routerLink]="['../more']">More</a>
          </li>
        </ul>
      </span>
      <span>Cheque Lodgement</span>
    </header>
  </section>
  <div *ngIf="!loadingData">
    <section class="fill-h fill-v" *ngIf="loan?.SCHEDULE_HAS_BEEN_SAVED == '0'">
      <div class="h-group center mb-4 padded empty-state">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title">No Schedule</h2>
        <p class="subtitle">Schedule has not been generated</p>
      </div>
    </section>

    <section *ngIf="loan?.SCHEDULE_HAS_BEEN_SAVED == '1'">
      <div class="flex justify-end mb-2" *ngIf="loan?.LOAN_STATUS < '3'">
        <app-button class="small green" [disabled]="loading" (click)="saveCheques()" [loading]="loading" label="Save">
        </app-button>
      </div>
      <!-- <p><label><input type="checkbox" name=""> Check number is incremental</label></p> -->
      <table class="table table-striped mt-2">
        <thead>
          <tr>
            <th>Repay Date</th>
            <th>Cheque No.</th>
            <th>Cheque Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cheque of cheques">
            <td>
              <input class="form-control" type="text" disabled name="REPAYMENT_DATE" [(ngModel)]="cheque.REPAYMENT_DATE"
                ngui-datetime-picker date-only="true" />
            </td>
            <td>
              <input class="form-control" type="text" name="CHEQUE_NO" [(ngModel)]="cheque.CHEQUE_NO">
            </td>
            <td>
              <input class="form-control" type="text" name="CHEQUE_DATE" [(ngModel)]="cheque.CHEQUE_DATE"
                ngui-datetime-picker date-only="true" />
            </td>
            <td>
              <input class="form-control text-right" type="text" name="CHEQUE_AMOUNT"
                [(ngModel)]="cheque.CHEQUE_AMOUNT">
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</div>