import { Component, OnInit } from '@angular/core';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { CustomerService, StorageService } from '../../_services/index';

@Component({
  selector: 'app-postingbreakloans',
  templateUrl: './postingbreakloans.component.html',
  styleUrls: ['./postingbreakloans.component.css']
})
export class PostingbreakloansComponent implements OnInit {
  endDate = '1561071540000';
  is_done = false;
  currentUser: any;
  repayments:any;
  loading = false;
  has_data = false;
  searchString = '';
  payment_date='';
  param = {
    'search_string':''
  }
  adding = true;
  added_requests = [];
  send_payments_to_queue = '0';
  constructor(private operationsService: OperationsService,public storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
   //this.getDueRepayments();
  }
  searchLoanPortfolio(){
    this.loading = true;
    this.operationsService.searchPortfolio(this.currentUser.token,this.param)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        this.repayments = result.message;
        this.send_payments_to_queue = result.send_payments_to_queue;
      });
  }
  getDueRepayments(){
    this.loading = true;
    this.operationsService.getBulkRepayments(this.currentUser.token,this.param)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        this.repayments = result.message;
        this.send_payments_to_queue = result.send_payments_to_queue;
      });
  }
  postDuePayments(){
    this.loading = true;
    this.loading = false; 
    this.is_done = true;
    this.operationsService.postBreakLoans(
      this.currentUser.token,
      this.added_requests,
      this.payment_date
    );
    // this.loading = true;
    // this.operationsService.postDuePayments(this.currentUser.token,this.repayments,this.payment_date)
    //   .subscribe(result => {
    //     this.loading = false; 
    //   });
  }
  addRecord(due,i){
    if(this.added_requests.indexOf(due)==-1){
      this.repayments[i].checked = true;
      this.added_requests.push(due);
    }
    
  }
  removeRecord(due,i){
    if(this.added_requests.indexOf(due)!=-1){
      this.repayments[i].checked = false;
      this.added_requests.splice(due,1);
    }
    
  }

}
