<div class="flex">
  <div class="col-md-12" style="padding-left : 0px !important"
    [ngClass]="{'pr_0 pl_0': view_from=='approvals', 'main-center pl_0':  view_from!='approvals'}">
    <div *ngIf="loan?.loan_status < 0 && view_from!='approvals'">
      <div *ngIf="loan?.is_rejected">
        <app-contractheader [loan]="loan" [request_id]="parentRouteId"></app-contractheader>
        <app-rejectedrequest [loan]="loan"></app-rejectedrequest>
      </div>
      <div *ngIf="loan?.is_eligible=='0'">
        <app-contractheader [loan]="loan" [request_id]="parentRouteId"></app-contractheader>
        <app-ineligiblerequest [loan]="loan" [request_id]="parentRouteId" [recommended]="loan?.recommended"
          [eligibility]="loan?.result"></app-ineligiblerequest>
      </div>
    </div>
    <div *ngIf="loan?.loan_status > 0 || !loan">
      <div *ngIf="creating_contract">
        <app-contractheader [loan]="loan" [request_id]="parentRouteId"></app-contractheader>
        <div *ngIf="creating_contract && loan?.schedule_has_been_created">
        </div>
        <div *ngIf="creating_contract && !loan?.schedule_has_been_created">
          <div class="panel mb-1 padding-regular contract-create">
            <div class="icon-button close-button">
              <button (click)="creating_contract = false"
                class="color-red default-text-size icon-button cursor-pointer"><i class="fa fa-times"></i></button>
            </div>
            <img alt="Contract Background"
              src="https://slack-imgs.com/?c=1&url=https%3A%2F%2Fwww.axialhealthcare.com%2Fwp-content%2Fuploads%2F2016%2F02%2Fheader-hex-bg-blue.jpg">
            <div class="flex">
              <div class="flex-3" style="margin: 20px 0 100px 0">
                <div class="h-group mb-4">
                  <div class="title" style="font-size: 2.3rem">Create contract</div>
                  <p class="subtitle fill-w no-margin">Contract Details</p>
                </div>
                <ul class="decList bigger">
                  <li><span>Name</span><span>{{loan?.customer?.legal_name}}</span></li>
                  <li><span>Principal</span><span>{{loan?.request_principal | money}}</span></li>
                  <li><span>Duration</span><span>{{ loan?.request_tenor}} {{ loan?.loan_duration}}</span></li>
                  <li><span>Interest rate</span><span>{{ loan?.request_rate}} {{ loan?.interest_duration}}</span></li>
                  <li><span>Fees</span><span>{{ loan?.total_fees_and_charges}}</span></li>
                  <li><span>Repayment Frequency</span><span>{{ loan?.installment_cyle}}</span></li>
                  <li><span>Interest Rate Type</span><span>{{ loan?.interest_rate_type}}</span></li>
                  <li><span>Repayment Type</span><span>{{ loan?.repayment_type}}</span></li>
                  <li><span>Disbursement Date</span><span>{{ loan?.disbursement_date}}</span></li>
                  <li><span>First Repayment Date</span><span>{{ loan?.repayment_started_when}}</span></li>
                </ul>
                <div class="text-left mt-4">
                  <!-- <app-button buttonclass="medium mr-1" *ngIf="!createdContract" (click)="doInvest()"
                      label="Create Contract" [loading]="loading"></app-button> -->
                  <!-- <app-button buttonclass="medium mr-1" *ngIf="!createdContract" (click)="openContractConfirm()"
                      label="Create Contract" [loading]="loading"></app-button> -->
                  <app-button label="{{loan?.schedule_has_been_saved ? 'Modify contract' : 'Create Contract'}}"
                    buttonclass="outline medium mr-1" (click)="openContractModal()" [disabled]="!grantRight('17')">
                  </app-button>
                  <app-button class="outline medium" *ngIf="createdContract"
                    (click)="creating_contract=!creating_contract" label="View Schedule"></app-button>
                </div>
              </div>
              <div class="flex-2 flex flex-column justify-end align-end">
                <div class="image-avatar large mb-2">
                  <img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/w_100,h_100,c_scale/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!creating_contract">
        <app-contractheader *ngIf="view_from!='approvals'" [loan]="loan" [request_id]="parentRouteId">
        </app-contractheader>

        <div *ngIf="loan && !loan?.is_general_market">
          <ng-container *ngIf="loan?.schedule_has_been_created && view_from!='approvals'">
            <ng-container *ngIf="!loan?.contract_has_changed">
              <ng-container *ngIf="loan?.contract_doc_sent">
                <section class="panel bg-off-white" *ngIf="loan?.is_accepted=='0'">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract created and has been sent to customer</h3>
                      <p class="subtitle">Borrower is yet to accept contract</p>
                    </div>
                    <div class="action">
                      <app-button class="small outline mr-1" (click)="openContractModal()" label="Edit"></app-button>
                      <app-button class="small outline blue" (click)="sendContract()" label="Send"></app-button>
                    </div>
                  </div>
                </section>
                <section class="panel bg-off-white" *ngIf="loan?.is_accepted=='1'">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract created and has been sent to customer</h3>
                      <p class="subtitle">Borrower has accepted the contract</p>
                      <p class="subtitle">{{loan?.date_accepted}}</p>
                    </div>
                    <div class="action">
                      <app-button class="small outline mr-1" (click)="openContractModal()" label="Edit"></app-button>
                      <app-button class="small outline blue" (click)="sendContract()" label="Send"></app-button>
                    </div>
                  </div>
                </section>
              </ng-container>
              <ng-container *ngIf="!loan?.contract_doc_sent">
                <section class="panel bg-off-white">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract Created</h3>
                      <p class="subtitle">The contract has been created but yet to be sent to the customer</p>
                    </div>
                    <div class="action">
                      <app-button class="small outline mr-1" (click)="openContractModal()" label="Edit"></app-button>
                      <app-button class="small outline blue" (click)="sendContract()" label="Send"></app-button>
                    </div>
                  </div>
                </section>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="loan?.contract_has_changed">
              <ng-container *ngIf="loan?.contract_doc_sent">
                <section class="panel bg-off-white" *ngIf="loan?.is_accepted=='0'">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract changed and has been sent to customer</h3>
                      <p class="subtitle">Borrower is yet to accept contract</p>
                    </div>
                    <div class="action">
                      <app-button class="small outline mr-1" (click)="openContractModal()" label="Edit"></app-button>
                      <app-button class="small outline blue" (click)="sendContract()" label="Send"></app-button>
                    </div>
                  </div>
                </section>
                <section class="panel mb-1 bg-off-white" *ngIf="loan?.is_accepted=='1'">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract changed and has been sent to customer</h3>
                      <p class="subtitle">Borrower has accepted the contract</p>
                      <p class="subtitle">{{loan?.date_accepted}}</p>
                    </div>
                    <div class="action">
                      <app-button class="small outline mr-1" (click)="openContractModal()" label="Edit"></app-button>
                      <app-button class="small outline blue" (click)="sendContract()" label="Send"></app-button>
                    </div>
                  </div>
                </section>
              </ng-container>
              <ng-container *ngIf="!loan?.contract_doc_sent">
                <section class="panel mb-1 bg-off-white border-green">
                  <div class="alert padding-regular bg-off-white">
                    <div class="icon color-green">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="texts">
                      <h3 class="title">Contract changed</h3>
                      <p class="subtitle">The contract has been created but yet to be sent to the customer</p>
                    </div>
                    <div class="action">
                      <app-button class="small yellow outline mr-1" (click)="openContractModal()" label="Edit">
                      </app-button>
                      <app-button class="small green" icon="fas fa-paper-plane" (click)="sendContract()" label="Send">
                      </app-button>
                    </div>
                  </div>
                </section>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!loan?.schedule_has_been_created && view_from!='approvals'">
            <section class="panel bg-off-white border-red">
              <div class="alert padding-regular bg-off-white">
                <div class="icon color-red">
                  <i class="fa fa-exclamation-circle"></i>
                </div>
                <div class="texts">
                  <h3 class="title">No Contract</h3>
                  <p class="subtitle">Contract has not been created</p>
                </div>
                <div class="action">
                  <app-button class="small green outline" label="Create contract" (click)="openContractModal()"
                    [disabled]="!grantRight('17')"></app-button>


                </div>
              </div>
            </section>
          </ng-container>
        </div>

        <app-contractdetails (makeOffer)="makeOfferForMarketLoan($event)" (editingContract)='editingContract($event)'
          (creatingContract)='creatingContract($event)' [loan]="loan" [request_id]="parentRouteId"
          [currentUser]="currentUser" [loading]="loading">
        </app-contractdetails>

        <div class="panel bg-off-white" *ngIf="view_from!='approvals' && loan">
          <div class="alert padding-regular bg-off-white">
            <div class="texts no-padding">
              <h4 class="title color-green">Run eligibility on {{loan?.customer?.legal_name}}</h4>
              <!-- <p class="subtitle">Send documents, ask for cards, accounts, etc..</p> -->
            </div>
            <div class="action">
              <!-- <app-button label="Actions" buttonclass="small yellow mr-2" (click)=" showActionsModal = true;">
                </app-button> -->
              <a class="btn btn-outline-success btn-sm"
                [routerLink]="['../../../applications/eligibility/' + loan?.request_id]">Run</a>
              <!-- <app-button label="Schedule" buttonclass="small outline"></app-button> -->
            </div>
          </div>
        </div>

        <div class="panel bg-off-white" *ngIf="view_from!='approvals' && !loan?.is_general_market">
          <div class="alert padding-regular bg-off-white">
            <div class="texts no-padding">
              <h4 class="title color-yellow">Take loan related actions</h4>
              <p class="subtitle">Send documents, ask for cards, accounts, etc..</p>
            </div>
            <div class="action">
              <app-button label="Actions" buttonclass="small yellow mr-2" (click)=" showActionsModal = true;">
              </app-button>
              <!-- <app-button label="Schedule" buttonclass="small outline"></app-button> -->
            </div>
          </div>
        </div>


        <section *ngIf="grantRight('22')">
          <app-repaymentschedule *ngIf="view_from!='approvals' && loan" [view_from]="view_from" [loan]='loan'
            [currentUser]="currentUser" [request_id]="loan?.request_id" (invest)="doInvest()"
            (openContarct)="openContractModal()">
          </app-repaymentschedule>
        </section>

        <div class="panel box edged_box mb_10" *ngIf="loan?.is_peer_to_peer && view_from!='approvals'">
          <div class="alert padding-regular bg-off-white">
            <div class="icon color-blue">
              <i class="fa fa-users"></i>
            </div>
            <div class="texts">
              <div class="title">Peer to Peer</div>
            </div>
            <div class="action">
              <app-button class="small outline blue" label="Open" (click)="openP2P(loan?.request_id)"></app-button>
            </div>
          </div>
        </div>

        <div class="panel bg-off-white" *ngIf="loan?.customer?.have_something && view_from!='approvals'">
          <div class="alert padding-regular bg-off-white">
            <div class="icon color-teal">
              <i class="fas fa-user-lock"></i>
            </div>
            <div class="texts">
              <div class="title">We know something about <span class="capital">{{loan?.customer?.legal_name}}</span>
              </div>
            </div>
            <div class="action">
              <app-button class="small outline teal" label="Run" (click)="openLoanSpider(loan?.request_id)">
              </app-button>
            </div>
          </div>
        </div>
        <div class="panel bg-off-white" *ngIf="view_from!='approvals' && !loan?.is_general_market">
          <div class="padding-regular flex justify-between align-center">
            <div class="user">
              <div class="image" (click)="showProfilePic()">
                <img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
              </div>
              <div class="content" *ngIf="loan?.customer?.account_officer !=''">
                <h4 class="title">{{loan?.customer?.account_officer?.ACCOUNT_OFF}}</h4>
                <p class="subtitle">Principal account officer</p>
              </div>
              <div class="content" *ngIf="loan?.customer?.account_officer ==''">
                <h4 class="title">No officer assigned</h4>
              </div>
            </div>
            <app-button (click)="takeOwnership(loan?.customer)" *ngIf="loan?.customer?.account_officer ==''"
              class="medium outline block" label="Assign Officer" type="button"></app-button>
            <app-button (click)="changeOwnership(loan?.customer)" *ngIf="loan?.customer?.account_officer!=''"
              class="medium outline block" label="Change Officer" type="button"></app-button>
          </div>
        </div>


        <div class="mb-1" *ngIf="view_from!='approvals' && !loan?.is_general_market">
          <div class="panel flex recollection">
            <app-help-popover [content]="[
                  {
                    title: 'How debit card debit settings works!',
                    img: 'assets/images/creditcard-icon.svg',
                    text:
                      '
                    <div>
                      <p>This setting allows for the debit-card attached by the borrower to be debited when due.</p>
                    </div>'
                  },
                  {
                    title: 'Account debit settings',
                    img: 'assets/images/mastercard.svg',
                    text:
                      '
                    <div>
                      <p> This setting allows for the card attached by the borrower to be debited when due.</p>
                    </div>'
                  },
                  {
                    title: 'Cheque setup',
                    img: 'assets/images/cheque.svg',
                    text:
                      '
                    <div>
                      <p>This setting allows check as repayment option</p>
                    </div>'
                  },
                  {
                    title: 'Borrower actions',
                    img: 'assets/images/report.svg',
                    text:
                      '
                    <div>
                      <p>This allows you to take different actions on the borrowers request 
                        e.g requesting for card, documents, guarantors and also allows you delete request.</p>
                      </div>'
                  },
                  {
                    title: 'Remitta inflight',
                    img: 'assets/images/credit-card.svg',
                    text:
                      '
                    <div>
                      <p>This setting is used to setup a mandate for the borrower if he’s eligible for this repayment method.
                      </p>
                    </div>'
                  }
                ]"></app-help-popover>

            <div class="flex-1 flex flex-column justify-center" *ngIf="view !== 'actions'">
              <div class="icon-text medium tall flex-1 has-overlay">
                <div class="icon color-green"><i class="fa fa-credit-card"></i></div>
                <p class="text">Card Debit Settings</p>
                <div *ngIf="loan?.repayment_mode=='2'" class="tag blue mt-1">Default</div>
                <div class="overlay flex-center flex-column">
                  <app-button label="View" class="small mb-1" [disabled]="!grantRight('35')"
                    (click)="openModal('setup_cards')"></app-button>
                  <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default"
                    (click)="setDefaultRepaymentMode(loan,2)" [disabled]="!grantRight('35')" class="small outline">
                  </app-button>
                </div>
              </div>
              <div class="icon-text medium" (click)="view='actions'">
                <div class="icon color-teal"><i class="fas fa-ellipsis-h"></i></div>
                <span class="text">Borrower Actions</span>
              </div>
            </div>

            <div class="flex-1 flex flex-column" *ngIf="view === 'actions'">
              <ul class="actions" id="no-padding-right">
                <li class="title">Actions</li>
                <li *ngIf="!loan?.is_accepted&&loan?.schedule_has_been_created">
                  <a href="javascript:;" (click)="acceptContract()">Accept
                    Contract</a>
                </li>
                <li *ngIf="loan?.is_peer_to_peer&&loan?.loan_status=='1'&&loan?.percentage_funded>=30">
                  <a href="javascript:;" (click)="requestToGetPaid()">Request To Get Paid</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="sendBVN()">Send BVN</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="requestCard()">Request
                    Card</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="requestOthers()">Request
                    Other Documents</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="requestGuarantors()">Request Guarantors</a>
                </li>
                <!-- <li>
                      <a href="javascript:;" (click)="requestKYC()">{{loan?.KYC_STATUS_}}</a>
                  </li>
                  <li>
                      <a href="javascript:;"
                          (click)="requestDocs()">{{loan?.DOC_STATUS_}}</a>
                  </li> -->

                <li *ngIf="loan?.schedule_has_been_created">
                  <a href="javascript:;" (click)="sendContract()">Send
                    Contract Docs.</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="confirmAccount()">Request Acct.
                    Confimration</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="assignLoanOfficer()">Assign Loan
                    Officer(Underwriter)</a>
                </li>
                <li>
                  <a href="javascript:;" (click)="sendEmailToCustomer()">Send Mail</a>
                </li>
                <li *ngIf="loan?.loan_status=='1'||loan?.loan_status=='2'">
                  <a href="javascript:;" (click)="deleteRequest()">Delete
                    Request</a>
                </li>
                <li>
                  <a (click)="view='cheques'" href="javascript:;">
                    <i class="glyphicon glyphicon-arrow-left"></i> back</a>
                </li>
              </ul>
            </div>

            <div class="flex-1 flex flex-column justify-center" style="border-left: 1px solid rgba(0, 0, 0, .1);">
              <div class="icon-text medium has-overlay flex-1">
                <div class="icon color-teal"><i class="fa fa-bank"></i></div>
                <p class="text"><span>Account Debit Settings</span></p>
                <div *ngIf="loan?.repayment_mode=='3'" class="tag blue mt-1">Default</div>
                <div class="overlay flex-center flex-column">
                  <app-button label="View" buttonclass="small mb-1" [disabled]="!grantRight('35')"
                    (click)="openModal('account_debit')"></app-button>
                  <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default"
                    (click)="setDefaultRepaymentMode(loan,3)" [disabled]="!grantRight('35')" class="small outline">
                  </app-button>
                </div>
              </div>
              <div class="icon-text medium has-overlay">
                <div class="icon color-teal"><i class="fa fa-money"></i></div>
                <p class="text"><span>Cheque Setup</span></p>
                <div *ngIf="loan?.repayment_mode=='3'" class="tag blue mt-1">Default</div>
                <div class="overlay flex-center flex-column">
                  <app-button label="View" buttonclass="small mb-1" [disabled]="!grantRight('27')"
                    (click)="openModal('setup_cheques')"></app-button>
                  <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default"
                    (click)="setDefaultRepaymentMode(loan,5)" [disabled]="!grantRight('27')" class="small outline">
                  </app-button>

                </div>
              </div>
              <div class="icon-text medium has-overlay">
                <div class="icon color-green"><i class="fa fa-paper-plane-o"></i></div>
                <p class="text"><span>Remita Inflight</span></p>
                <div *ngIf="loan?.repayment_mode=='1' && loan?.use_remita=='1'" class="tag blue mt-1">Default</div>
                <div class="overlay flex-center flex-column">
                  <app-button *ngIf="loan?.use_remita=='1'" label="View" [disabled]="!grantRight('81')"
                    buttonclass="small mb-1" (click)="openModal('remita_inflight')"></app-button>
                  <app-button *ngIf="loan?.use_remita=='1' && loan?.repayment_mode=='0'" label="Set as default"
                    (click)="setDefaultRepaymentMode(loan,1)" [disabled]="!grantRight('81')" class="small outline">
                  </app-button>
                  <app-button *ngIf="loan?.use_remita=='0'" label="Get Info" (click)="openModal('remita_inflight')"
                    [disabled]="!grantRight('81')" class="small outline">
                  </app-button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel box mb-1" *ngIf="grantRight('21') && !loan?.is_general_market">
          <app-help-popover [content]="[
                {
                  title: 'What is a Checklist?',
                  img: 'assets/images/checklist1.svg',
                  text:
                    '
            <div>
              <p>This is a list of items required, things to be done, or points to be considered, used as a reminder.</p>
            </div>'
                }
              ]"></app-help-popover>
          <div class="panel-body" style="padding: 3rem!important">
            <div class="" *ngIf="loan">
              <app-applicationschecklist [loading]="loading"
                (saveApplicationChecklist)="saveApplicationChecklist($event)" [loan]='loan' [where]="2"
                [parentRouteId]="parentRouteId"></app-applicationschecklist>

            </div>
          </div>
        </div>

        <div class="panel box mb-4 bg-off-white" *ngIf="view_from!='approvals'">
          <div class="alert padding-regular bg-off-white">
            <div class="icon">
              <i class="fas fa-long-arrow-alt-left"></i>
            </div>
            <div class="texts">
              <div class="title">View Previous Requests</div>
            </div>
            <div class="action">
              <app-button class="small blue" label="View" (click)="openPreviousRequests(loan?.request_id)">
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>



<div class="overlay_ app_overlay animated fadeIn" *ngIf="showActionsModal">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="showActionsModal = false; view = ''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn floating-title" role="document">
    <h3 class="title">Loan Actions</h3>
    <div class="flex recollection">
      <div class="flex-1 flex flex-column justify-center" *ngIf="view=='cheques'">
        <div class="icon-text medium tall flex-1" (click)="showActionsModal = false; openModal('setup_cards')">
          <div class="icon color-green"><i class="fa fa-credit-card"></i></div>
          <p class="text">Card Debit Settings</p>
        </div>
        <div class="icon-text medium" (click)="view='actions'">
          <div class="icon color-teal"><i class="fas fa-ellipsis-h"></i></div>
          <span class="text">Borrower Actions</span>
        </div>
      </div>

      <div class="flex-1 flex flex-column ml-3" *ngIf="view == 'actions'">
        <ul class="actions" id="no-padding-right">
          <li class="title no-float">Actions</li>
          <li *ngIf="!loan?.is_accepted&&loan?.schedule_has_been_created">
            <a href="javascript:;" (click)="showActionsModal = false; acceptContract()">Accept
              Contract</a>
          </li>
          <li *ngIf="loan?.is_peer_to_peer&&loan?.loan_status=='1'&&loan?.percentage_funded>=30">
            <a href="javascript:;" (click)="showActionsModal = false; requestToGetPaid()">Request To Get Paid</a>
          </li>
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; sendBVN()">Send BVN</a>
          </li>
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; requestCard()">Request
              Card</a>
          </li>
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; requestOthers()">Request
              Other Documents</a>
          </li>
          <!-- <li>
              <a href="javascript:;" (click)="showActionsModal = false; sendBVN()">Request Official Email Confirmation</a>
            </li> -->
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; requestGuarantors()">Request Guarantors</a>
          </li>
          <!-- <li>
                  <a href="javascript:;" (click)="showActionsModal = false; requestKYC()">{{loan?.KYC_STATUS_}}</a>
              </li>
              <li>
                  <a href="javascript:;"
                      (click)="showActionsModal = false; requestDocs()">{{loan?.DOC_STATUS_}}</a>
              </li> -->

          <li *ngIf="loan?.schedule_has_been_created && loan?.is_accepted!='1'">
            <a href="javascript:;" (click)="showActionsModal = false; sendContract()">Send
              Contract Docs.</a>
          </li>
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; confirmAccount()">Request Acct.
              Confimration</a>
          </li>
          <li>
            <a href="javascript:;" (click)="showActionsModal = false; assignLoanOfficer()">Assign Loan
              Officer(Underwriter)</a>
          </li>
          <li *ngIf="loan?.loan_status=='1'||loan?.loan_status=='2'">
            <a href="javascript:;" (click)="showActionsModal = false; deleteRequest()">Delete
              Request</a>
          </li>
          <li>
            <a (click)="view='cheques'" href="javascript:;">
              <i class="glyphicon glyphicon-arrow-left"></i> back</a>
          </li>
        </ul>
      </div>

      <div class="flex-1 flex flex-column justify-center">
        <div class="icon-text medium flex-1" (click)="showActionsModal = false; openModal('account_debit')">
          <div class="icon color-teal"><i class="fa fa-bank"></i></div>
          <p class="text"><span>Acccount Debit Settings</span></p>
        </div>
        <div class="icon-text medium" (click)="showActionsModal = false; openModal('setup_cheques')">
          <div class="icon color-teal"><i class="fa fa-money"></i></div>
          <p class="text"><span>Cheque Setup</span></p>
        </div>
        <div (click)="showActionsModal = false; openModal('remita_inflight')" class="icon-text medium">
          <div class="icon color-green"><i class="fa fa-paper-plane-o"></i></div>
          <span class="text">Remita Inflight</span>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="overlay_ app_overlay animated fadeIn flex-center" *ngIf="showAccountConfirmation">
  <div class="overpanel-content no-padding animated zoomIn confirmation" role="document">
    <div class="text mb-2">Are you sure you want to set Account Debit as default? </div>
    <div class="flex justify-end align-center mt-4">
      <app-button class="small green mr-1" label="yes"></app-button>
      <app-button class="small red" label="No" (click)="showAccountConfirmation = false;"></app-button>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn flex-center" *ngIf="view == 'no_right'">
  <div class="overpanel-content no-padding animated zoomIn confirmation" role="document">
    <app-no-right></app-no-right>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn flex-center" *ngIf="showTestModeModal">
  <div class="overpanel-content no-padding animated zoomIn confirmation" role="document">
    <app-golivemodal></app-golivemodal>
  </div>
</div>