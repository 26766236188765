<div *ngIf="referal_code=='1'" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px" class="mb-4">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">Merchant Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (merchant?.merchant?.name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Email</span>
          <span *ngIf="!loading">{{ (merchant?.merchant?.email) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (merchant?.merchant?.phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Item</span>
          <span *ngIf="!loading">{{ (merchant?.item?.name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>

      </ul>
      <div class="section-title color-red mb-4" *ngIf="eligibility_analysis">Eligibility Result</div>
      <ul class="decList bigger" *ngIf="eligibility_analysis">
        <li><span>Loan Amount</span>
          <span *ngIf="!loading">{{ (loan_amount) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Upfront</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.upfront) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>

        <li><span>Credit</span>
          <span *ngIf="!loading">{{ (loan_amount - eligibility_analysis?.upfront) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Interest</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.interest) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Repayment</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.credit) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Effective Income</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.effective_income) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <!-- <li><button class="btn btn-outline-danger btn-xs" (click)="editing = true">Edit Offer</button>
        </li> -->

      </ul>
      <form role="form animated" *ngIf="editing">
        <ul class="decList bigger" *ngIf="eligibility_analysis">
          <li><span>Loan Amount</span>
              <span><input type="text" name="loan_amount" [(ngModel)]="offer.loan_amount"></span>
          </li>
          <li><span>Upfront</span>
            <span><input type="text" name="upfront" [(ngModel)]="offer.upfront"></span>
          </li>

          <!-- <li><span>Credit</span>
            <span><input type="text" name="credit" [(ngModel)]="offer.credit"></span>
          </li>
          <li><span>Interest</span>
            <span><input type="text" name="interest" [(ngModel)]="offer.interest"></span>
          </li> -->
          <li><button class="btn btn-outline-danger btn-xs" (click)="updatebnploffer()">Submit</button>
          </li>

        </ul>
      </form>

    </div>
  </div>
  <div>
    <app-square-box>
      <img src="{{ merchant?.item?.primary_picture || '/assets/images/placeholder-image.webp' }}" alt="" />

    </app-square-box>
  </div>
</div>
<div *ngIf="referal_code=='10'" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px" class="mb-4">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">Restaurant Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (eat?.restaurant?.restaurant_name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (eat?.restaurant?.phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>

      </ul>
    </div>
  </div>
  <div>
    <app-square-box>
      <img src="{{ eat?.picture || '/assets/images/placeholder-image.webp' }}" alt="" />

    </app-square-box>
  </div>
</div>
<div *ngIf="referal_code=='3'" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px" class="mb-4">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">Merchant Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (eat?.merchant?.name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Email</span>
          <span *ngIf="!loading">{{ (eat?.merchant?.email) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (eat?.merchant?.phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>


      </ul>
    </div>
  </div>
  <div>
    <app-square-box>
      <img src="{{ eat?.merchant?.picture || '/assets/images/placeholder-image.webp' }}" alt="" />

    </app-square-box>
  </div>
</div>

<div *ngIf="referal_code=='2'">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">School Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (school?.name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Email</span>
          <span *ngIf="!loading">{{ (school?.email) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (school?.phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Is Anonymous</span>
          <span *ngIf="!loading">{{ (is_anonymous) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
      </ul>
      <div class="section-title color-red mb-4" *ngIf="eligibility_analysis">Eligibility Result</div>
      <ul class="decList bigger" *ngIf="eligibility_analysis">
        <li><span>Loan Amount</span>
          <span *ngIf="!loading">{{ (loan_amount) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Upfront</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.upfront) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Credit</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.credit) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Effective Income</span>
          <span *ngIf="!loading">{{ (eligibility_analysis?.effective_income) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>


      </ul>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px">
    <div *ngFor="let child of children.slice(0, min(children.length, 4))">
      <app-square-box>
        <img [src]="child.picture || '/assets/images/child-avatar.jpg'" alt="" />
      </app-square-box>
    </div>
    <div *ngFor="let child of arrayOfLength(4 - min(children.length, 4))">
      <app-square-box>
        <img src="/assets/images/placeholder-image.webp" alt="" />
      </app-square-box>
    </div>
  </div>
</div>

<div *ngIf="referal_code=='13' && rent_pictures">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">LandLord Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (rent?.landlord_name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (rent?.landlord_phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>

      </ul>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px">
    <div *ngFor="let child of rent_pictures.slice(0, min(rent_pictures.length, 4))">
      <app-square-box>
        <img [src]="child || '/assets/images/child-avatar.jpg'" alt="" />
      </app-square-box>
    </div>
    <div *ngFor="let child of arrayOfLength(4 - min(rent_pictures.length, 4))">
      <app-square-box>
        <img src="/assets/images/placeholder-image.webp" alt="" />
      </app-square-box>
    </div>
  </div>
</div>
<div *ngIf="referal_code=='5'" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px" class="mb-4">
  <div class="panel padding-regular flex">
    <div class="flex-3">
      <div class="section-title color-red mb-4">School Details</div>
      <ul class="decList bigger">
        <li><span>Name</span>
          <span *ngIf="!loading">{{ (eat?.name) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Email</span>
          <span *ngIf="!loading">{{ (eat?.email) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>
        <li><span>Phone</span>
          <span *ngIf="!loading">{{ (eat?.phone) }}</span>
          <span *ngIf="loading">Lorem, ipsum.</span>
        </li>

      </ul>

    </div>

  </div>