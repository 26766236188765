<div class="padding-regular animated fadeIn" *ngIf="self">
  <div *ngIf="view=='default'">
    <div class="title mb-4">Get Payslip Records
    </div>
    <div>

      <section class="panel padding-regular">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Categoty</label>
            <select class="form-control custom small" name="category" [(ngModel)]="category" id="type2">
              <option value="1">Fed Govt(IPPIS)</option>
              <option value="2">Lagos State Govt(Oracle Code)</option>

            </select>
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Payslip Number</label>
            <input class="form-control custom small" name="ippis_number" [(ngModel)]="ippis_number" id="type1">
          </div>

        </div>


        <app-button class="small default mr-2" [disabled]="loading" label="Get Data" (click)="getData()"></app-button>

        <app-button class="small default mr-2" label="Save Details" (click)="uploadTheFile()"
          [disabled]="!has_payslip_details || loading" [loading]="loading"></app-button>
      </section>
    </div>

  </div>
  <div *ngIf="view=='generteaccount'">
    <div class="title mb-4">Generate Account
    </div>
    <div>

      <section class="panel padding-regular">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Account Name</label>
            <input class="form-control custom small" name="account_name" [(ngModel)]="account_name" id="type1">
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Amount</label>
            <input class="form-control custom small" name="amount" [(ngModel)]="amount" id="type2">
          </div>

        </div>


        <app-button class="small default mr-2" [disabled]="loading" label="Get Account" (click)="getMerchantAccount()">
        </app-button>

      </section>
    </div>
  </div>
  <div *ngIf="view=='generteupfrotaccount'">
    <div class="title mb-4">Generate Upfront Account
    </div>
    <div>

      <section class="panel padding-regular">
        <div class="row">
          <!-- <div class="form-group col-md-6">
            <label class="control-label" for="type1">Account Name</label> 
            <input class="form-control custom small" name="account_name" [(ngModel)]="account_name" id="type1">
          </div> -->
          <div class="form-group col-md-6">
            <label class="control-label" for="type1">Amount</label>
            <input class="form-control custom small" name="amount" [(ngModel)]="amount" id="type2">
          </div>

        </div>


        <app-button class="small default mr-2" [disabled]="loading" label="Get Account" (click)="getUpfrontAccount()"></app-button>
        <div *ngIf="upfront_account">
          <p>Account Number - {{upfront_account?.data.account_number}}</p>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="has_payslip_details" class="panel box edged_box mb_10">
    <div class="panel-body padding-regular">
      <div class="flex">
        <div class="flex-3 mr-4">
          <ul class="decList bigger" *ngIf="category=='2'">
            <li><span class="title">Oracle Information</span></li>
            <li><span>0racle Number</span>
              <span>{{payslip?.data?.details?.ippis_number || 'Not provided'}}</span>
            </li>
            <li><span>Name</span>
              <span>{{payslip?.data?.details?.full_name || 'Not provided'}}</span>
            </li>
            <li><span>Ministry</span>
              <span>{{payslip?.data?.details?.ministry || 'Not provided'}}</span>
            </li>
            <li><span>Date of Birth</span>
              <span>{{payslip?.data?.details?.date_of_birth || 'Not provided'}}</span>
            </li>
            <li><span>Date of First Appointment</span>
              <span>{{payslip?.data?.details?.date_of_first_appointment || 'Not provided'}}</span>
            </li>
            <li><span>Grade</span>
              <span>{{payslip?.data?.details?.grade || 'Not provided'}}</span>
            </li>
            <li><span>Level</span>
              <span>{{payslip?.data?.details?.level || 'Not provided'}}</span>
            </li>
            <li><span>Gender</span>
              <span>{{payslip?.data?.details?.gender || 'Not provided'}}</span>
            </li>
            <li><span>Tax State</span>
              <span>{{payslip?.data?.details?.tax_state || 'Not provided'}}</span>
            </li>
            <li><span>Designation</span>
              <span>{{payslip?.data?.details?.designation || 'Not provided'}}</span>
            </li>
            <li><span>Phone</span>
              <span>{{payslip?.data?.details?.phone || 'Not provided'}}</span>
            </li>
            <li><span>Division</span>
              <span>{{payslip?.data?.details?.division || 'Not provided'}}</span>
            </li>
            <li><span>Email</span>
              <span>{{payslip?.data?.details?.email || 'Not provided'}}</span>
            </li>
            <li><span>Location</span>
              <span>{{payslip?.data?.details?.location || 'Not provided'}}</span>
            </li>
            <li><span>Account Number</span>
              <span>{{payslip?.data?.details?.account_number || 'Not provided'}}</span>
            </li>
            <li><span>Bank</span>
              <span>{{payslip?.data?.details?.bank_name || 'Not provided'}}</span>
            </li>

          </ul>

          <ul class="decList bigger" *ngIf="category=='1'">
            <li><span class="title">IPPIS Information</span></li>
            <li><span>IPPIS Number</span>
              <span>{{payslip?.data?.details?.ippis_number || 'Not provided'}}</span>
            </li>
            <li><span>Name</span>
              <span>{{payslip?.data?.details?.full_name || 'Not provided'}}</span>
            </li>
            <li><span>Ministry</span>
              <span>{{payslip?.data?.details?.ministry || 'Not provided'}}</span>
            </li>
            <li><span>Date of Birth</span>
              <span>{{payslip?.data?.details?.date_of_birth || 'Not provided'}}</span>
            </li>
            <li><span>Date of First Appointment</span>
              <span>{{payslip?.data?.details?.date_of_first_appointment || 'Not provided'}}</span>
            </li>
            <li><span>Grade</span>
              <span>{{payslip?.data?.details?.grade || 'Not provided'}}</span>
            </li>
            <li><span>Level</span>
              <span>{{payslip?.data?.details?.level || 'Not provided'}}</span>
            </li>
            <li><span>Gender</span>
              <span>{{payslip?.data?.details?.gender || 'Not provided'}}</span>
            </li>
            <li><span>Tax State</span>
              <span>{{payslip?.data?.details?.tax_state || 'Not provided'}}</span>
            </li>
            <li><span>Designation</span>
              <span>{{payslip?.data?.details?.designation || 'Not provided'}}</span>
            </li>
            <li><span>Phone</span>
              <span>{{payslip?.data?.details?.phone || 'Not provided'}}</span>
            </li>
            <li><span>Division</span>
              <span>{{payslip?.data?.details?.division || 'Not provided'}}</span>
            </li>
            <li><span>Email</span>
              <span>{{payslip?.data?.details?.email || 'Not provided'}}</span>
            </li>
            <li><span>Location</span>
              <span>{{payslip?.data?.details?.location || 'Not provided'}}</span>
            </li>
            <li><span>Account Number</span>
              <span>{{payslip?.data?.details?.account_number || 'Not provided'}}</span>
            </li>
            <li><span>Bank</span>
              <span>{{payslip?.data?.details?.bank_name || 'Not provided'}}</span>
            </li>

          </ul>
        </div>
        <!-- <div class="section-poster flex-center flex-column">
        <div class="image flex-center">
          <img src="assets/images/remita.svg">
        </div>
      </div> -->
      </div>
      <h4 class="section-title mb-3 mt-5">Payslip History</h4>
      <ul class="decList bigger">
        <li class="title">
          <span class="color-blue">Month</span>
          <span class="color-blue">Gross</span>
          <span class="color-blue">Deductions</span>
          <span class="color-blue">Net</span>
        </li>
        <li *ngFor="let sal of payslip?.data?.payslips">
          <span class="color-grey">{{sal.month}}</span>
          <span>{{sal.gross_earnings|number:'.2-2'}}</span>
          <span>{{sal.gross_deductions|number:'.2-2'}}</span>
          <span>{{sal.net_earnings|number:'.2-2'}}</span>
        </li>
      </ul>
    </div>
  </div>