import { ElementRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService, DataService, OperationsService } from '../../_services/index';
import { CustomerService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';
import { LoancontractComponent } from '../../loancontract/loancontract.component';

@Component({
  selector: 'app-loanportfolioaction',
  templateUrl: './loanportfolioaction.component.html',
  styleUrls: ['./loanportfolioaction.component.css']
})
export class LoanportfolioactionComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input() buttonOnly = false;
  loading = false;
  loan: any;
  currentUser: any;
  data: any;
  statement_message = '';
  loading_statement = false;
  eliloading = false;
  @Output() doingDisbursement = new EventEmitter();
  constructor(private toastr: ToastrService, private _elementRef: ElementRef,
    private authService: AuthenticationService,
    public operationsService: OperationsService, private DataService: DataService,
    public router: Router, public route: ActivatedRoute, public loansService: LoansService, public storageService: StorageService) {
    // if (!this.authService.canViewModule('1,2,3,1026')) {
    //   this.router.navigate(['../unauthorized']);
    // }
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    this.getLoanStatement();
  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  getLoanStatement() {
    this.loading = true;
    this.loansService.getRepaymentDetails_(this.currentUser.token, this.request_id)
      .subscribe(loans => {
        this.loading = false;
        this.data = loans;
      });
  }
  doPayDue() {
    this.doingDisbursement.emit({})
  }
  initiatestatement(){
    this.statement_message = '';
    this.loading_statement = true
    this.loansService.initiatestatement(this.currentUser.token, this.request_id)
      .subscribe(loans => {
        this.loading_statement = false;
        if (loans[1].status == true) {
          this.showSuccess("Statement pulled successfully")
        } else {
          this.showError("Unable to get statement ")
          this.statement_message = loans[1].message.message
  
        }
    });
  }
  getpayload(){
    this.eliloading = true;
    this.loansService.getEligibilityPayload(this.currentUser.token, this.request_id)
      .subscribe(loans => {
        this.eliloading = false;
        if (loans.status) {
           
        } else {

 
        }
    });
  }
}
