<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Nibbs Instant Mandate Debit</h3>
      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              
              <th>Amount</th>
              <th>Mandate Account</th>
              <th>Mandate Reference</th>
              <th>Status</th>
              <th>Date</th>
            </thead>
           
            <tbody>
              <tr *ngFor="let record of data">
               
                <td>{{record?.amount}}</td>
                <td>{{record?.mandate_account_name}} / {{record?.mandate_account_number}}</td>
                <td>{{record?.mandate.reference_number}}</td>
                <td>{{record?.status}}</td>
                <td>{{record?.created_on}}</td>
              </tr>
              <tr>
               
                <td colspan="5"><button (click)="getRecords()" class="btn btn-outline-secondary btn-xs" type="button">
                  Get More Records
                </button></td>
                
              </tr>
            </tbody>
             
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>