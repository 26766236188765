import { Component, Input, OnInit } from '@angular/core';
import { DataService, LoansService, OperationsService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-request-sideview-more-pictures',
  templateUrl: './request-sideview-more-pictures.component.html',
  styleUrls: ['./request-sideview-more-pictures.component.css']
})
export class RequestSideviewMorePicturesComponent implements OnInit {

  @Input() creditclan_request_id: any;
  @Input() phone: any;
  @Input() referal_code: any;
  @Input() loan:any;
  children = [];
  currentUser: any;
  min = Math.min;
  merchant:any;
  eat:any;
  rent:any;
  rent_pictures:any;
  loading = false;
  school:any;
  @Input() eligibility_analysis:any;
  @Input() loan_amount:any;
  @Input() is_anonymous:any;
  offer = {
    loan_amount:0,
    upfront:0,
    interest:0,
  }
  editing = false;
  constructor(public feed: OperationsService,public storageService: StorageService, private loansService: LoansService, private dataService: DataService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  arrayOfLength(n) {
    return Array(n).fill(null);
  }

  ngOnInit(): void { 
    this.loading = true;
    if(this.referal_code == '2'){
      this.loansService.getParentData(this.creditclan_request_id)
      .subscribe(result => {
        this.loading = false;
        this.children = result.data.children;
        this.school = result.data.school;
      });
    }
    if(this.referal_code == '1'){ 
      this.loansService.getTheMerchantRequest({ cc_request_id: this.creditclan_request_id })
        .subscribe(result => {
          this.loading = false;
          this.merchant = result.data;
          this.offer.loan_amount = this.loan_amount;
          this.offer.interest = this.eligibility_analysis.interest;
          this.offer.upfront = this.eligibility_analysis.upfront;
        }, err => {
        });
    }
    if(this.referal_code == '10'){
      this.loansService.getTheEatRequest({ cc_request_id: this.creditclan_request_id })
        .subscribe(result => {
          this.loading = false;
          this.eat = result.data;

        }, err => {
        });
    }
    if(this.referal_code == '13'){
      this.loansService.getTheRentRequest({ cc_request_id: this.creditclan_request_id })
        .subscribe(result => { 
          this.loading = false;
          this.rent = result.data;
          this.rent_pictures = result.data.pictures;
        }, err => {
        });

    }
    if(this.referal_code == '3'){
      this.loansService.getTheMerchantLoan({ cc_request_id: this.creditclan_request_id })
        .subscribe(result => { 
          this.loading = false;
          this.eat = result.data;
        }, err => {
        });

    }
    if(this.referal_code == '5'){
      this.loansService.getTheSchoolData({ phone: this.phone })
        .subscribe(result => { 
          this.loading = false;
          this.eat = result;
        }, err => {
        });

    }

  }
  updatebnploffer(){
    this.loansService.updatebnploffer({ token: this.currentUser.token, request_id: this.creditclan_request_id, offer: this.offer })
        .subscribe(result => { 
          this.loading = false;
          this.eat = result;
        }, err => {
        });
  }
}
