<app-navbar></app-navbar>
<app-header></app-header>
<div class="cont">
  <div class="container">
    <div class="col-md-3">
      <div class="left-container">
        <app-sidebar></app-sidebar>
      </div>
    </div>
    <div class="col-md-4">
      <div class="middle-container">
        <div class="">
          <img src="assets/images/ussd.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="right-container">
        <h2 class="onb-title">USSD Request</h2>
        <hr/>
        <div class="onb-content">
          <p>
            Simulate how your customers will be able to reach you even without the internet. We provide USSD codes for
            customers to reach you
          </p>
          <hr/>
          <p>
            Once you go live a code will be assigned to you. Now let's get started.
          </p>
          <hr/>
        </div>
        <button
          (click)="simulate()"
          class="btn btn-block btn-default padded orange-btn"
        >
          Simulate
        </button>
        <div style="text-align: center;
    padding: 10px;
    font-size: 18px;"><a href="javascript:">Configure the settings</a></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="view == 'webussd'">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="webussd=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
         style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-web-ussd></app-web-ussd>
    </div>
  </div>
</div>
