<div class="panel padding-regular">
    <div class="flex align-center mt-5 mb-5">
        <div class="image flex-center justify-start mr-4">
            <img src="assets/images/form.svg" style="max-width: 100px;">
        </div>
        <div class="h-group flex-1 ml-4">
            <div>
                <h3 class="title no-margin">Request Loan Product</h3>
                <p class="subtitle fill-w mb-2">
                    Change request loan product
                </p>
                <ul class="decList bigger">
                    <li><span>Loan Product</span>
                        <span>
                            <select name="LOAN_PRODUCT_ID" [(ngModel)]="loan.LOAN_PRODUCT_ID">
                                <option value=""></option>
                                <option *ngFor="let product of products" value="{{product.LOAN_PRODUCT_ID}}">
                                    {{product.LOAN_TITLE}}</option>
                            </select>
                        </span>
                    </li>

                </ul>
                <app-button class="small outline orange mt-2" label="Change Loan Product" (click)="saveBio()">
                </app-button>
            </div>
            <div>
                <h3 class="title no-margin">Select Offer Letter</h3>
                <p class="subtitle fill-w mb-2">
                    Select Offer Letter
                </p>
                <ul class="decList bigger">
                    <li><span>Offer Letter</span>
                        <span>
                            <select name="LOAN_PRODUCT_IDD" [(ngModel)]="LOAN_PRODUCT_IDD">
                                <option value=""></option>
                                <option  value="30035">No MGT No Equity</option>
                                <option  value="30036">Returning Customers</option>
                                <option  value="30037">New Customers</option>
                                <option  value="30038">Collaterized Loan</option>
                                <option  value="30039">Rollover</option>
                                <option  value="30040">Top Up</option>
                            </select>
                        </span>
                    </li>

                </ul>
                <app-button class="small outline orange mt-2" label="Change Loan Product" (click)="saveBios()">
                </app-button>
            </div>
            <div style="margin-top: 20px;">
                <h3 class="title no-margin">Add Customer Card</h3>
                <p class="subtitle fill-w mb-2">
                    Add a customer card
                </p>
                <ul class="decList bigger">
                    <li><span>Transaction ID</span>
                        <span>
                            <input name="transaction_id" [(ngModel)]="transaction_id">
                                
                        </span>
                    </li>

                </ul>
                <app-button class="small outline orange mt-2" label="Add Customer Card" (click)="saveCard()">
                </app-button>
            </div>
            <div style="margin-top:30px" *ngIf="loan?.referal_code=='13'">
                <h3 class="title no-margin">Update disbursement date</h3>
                <p class="subtitle fill-w mb-2">
                    Update disbursement date
                </p>
                <ul class="decList bigger">
                    <li><span>Disbursement Date </span>
                        <span>
                            <input class="form-control" type="date" name="DISBURSEMENT_DATE" id="DISBURSEMENT_DATE"
                                [(ngModel)]="loan.disbursement_date">
                        </span>
                    </li>

                </ul>
                <app-button class="small outline orange mt-2" label="Update Disbursement Date" (click)="saveDD()">
                </app-button>
            </div>
        </div>


    </div>
</div>