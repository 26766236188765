<div class="header">
  <div class="user">
    <div class="image">
      <img
        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{disburse?.FILE_NAME | imagenotfound}}">
    </div>
    <div class="content">
      <h4 class="title no-float">{{disburse?.LEGAL_NAME}}</h4>
      <p class="subtitle">{{disburse?.REQUEST_PRINCIPAL | number:'.2-2'}}</p>
    </div>
  </div>
</div>

<div class="padding-regular">
  <!-- <div class="width_0 mt_10" style="flex-grow: 1">
        <p class="text-danger text-center" style="font-size: 120px;"><span class="fas fa-ban"></span></p>
      </div> -->
  <form [formGroup]="repaymentConfirmPayment" (ngSubmit)="doPaymentConfirm(repaymentConfirmPayment.value)">
    <div class="h-group mb-4">
      <h4 class="no-margin title">Transaction Details</h4>
      <p class="fill-w subtitle mt-1">Please confirm that the payment has been made.</p>
    </div>
    <div class="form-group"
      [ngClass]="{'has-error':!repaymentConfirmPayment.controls['LENDER_BANK_ACCOUNT_ID'].valid && repaymentConfirmPayment.controls['LENDER_BANK_ACCOUNT_ID'].touched}">
      <label for="exampleInputEmail1" class="control-label normal">Repayment Bank</label>
      <select class="form-control" [formControl]="repaymentConfirmPayment.controls['LENDER_BANK_ACCOUNT_ID']"
        name="LENDER_BANK_ACCOUNT_ID" id="LENDER_BANK_ACCOUNT_ID">
        <option value="0"></option>
        <option *ngFor="let bank of banks" [ngValue]="bank">
          {{bank.LENDER_ACCOUNT_NAME}}|{{bank.LENDER_ACCOUNT_NUMBER}}</option>
      </select>
    </div>
    <div class="form-group"
      [ngClass]="{'has-error':!repaymentConfirmPayment.controls['SECURITY_QUESTION_ANSWER'].valid && repaymentConfirmPayment.controls['SECURITY_QUESTION_ANSWER'].touched}">
      <label class="control-label normal" for="exampleInputEmail1">{{security_question}}</label>
      <input [formControl]="repaymentConfirmPayment.controls['SECURITY_QUESTION_ANSWER']" type="password"
        class="form-control" id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
      <div *ngIf="otpError" class="alert alert-danger fade in mt-2 mb-2">Wrong answer</div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <app-button class="small" [disabled]="!repaymentConfirmPayment.valid" [loading]="loading" type="submit"
          label="Confirm Payment"></app-button>
      </div>
      <!-- <div class="col-md-6">
                  <button class="btn btn-block btn-danger btn-block" type="button"
                    (click)="closeModal()">Cancel</button>
                </div> -->
    </div>
  </form>
</div>