<div class="cont">
  <div class="sect-wrap">
    <div class="left-container" *ngIf="currentView == 'setup_user'">
      <div class="onb-title">
        <h3>Create User</h3>
        <p>Provide your email address and password to complete the sign up process.</p>
      </div>
      <form #signupForm="ngForm">
        <div class="form-group">
          <input type="email" class="form-control custom" [value]="email" placeholder="Email" #LEGAL_NAME="ngModel"
                 [(ngModel)]="email" name="email" required>
        </div>
        <div class="form-group">
          <input type="password" class="form-control custom" placeholder="Password" #EMAIL="ngModel"
                 [(ngModel)]="password" name="password" required>
        </div>
        <div class="mt-3">
          <app-button (click)="signup()" [disabled]="email === '' && password === ''" label="Submit" [loading]="loading"></app-button>
        </div>
      </form>
    </div>
    <div class="left-container" *ngIf="currentView == 'creating_profile'">
      <div class="loading">
        <div class="box">
          <div class="indicator"></div>
          <div class="text">Creating Profile..</div>
          <small>You will receive an email once we've certified you are a lender.</small>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="img-wrap">
        <img src="assets/images/welcome.svg" alt="Illustration">
        <p class="welcome-text">Join our league of technology powered lenders.</p>
      </div>
    </div>
  </div>
  <!--<div>-->
  <!--<div class="container">-->
  <!--<div class="text-center">-->
  <!--<h1>Creating Profile</h1>-->
  <!--<img src="https://media.giphy.com/media/10UeedrT5MIfPG/giphy.gif" alt=""-->
  <!--style="height: 250px; border-radius: 8px">-->
  <!--<p style="padding-top: 30px; font-size: 18px">Thank you for registering <br> We will send a mail to the-->
  <!--email address you provided once we've certified you are a lender</p>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</div>
