<div class="padding-regular" *ngIf="grantRight('35')">
  <div class="title mb-4">Accounts Added ({{ accounts_added }})
    <div style="margin-right: 60px" *ngIf="account_state=='start'" class="fab bottom floating green"
      (click)="account_state='send_request'">
      <i class="fa fa-send"></i>
    </div>
    <div *ngIf="account_state=='start'" class="fab bottom floating" (click)="account_state='add_manually'">
      <i class="fa fa-plus"></i>
    </div>
    <div style="margin-right: 60px" *ngIf="account_state=='send_request'" class="fab bottom floating red"
      (click)="account_state='start'">
      <i class="fa fa-times"></i>
    </div>
    <div *ngIf="account_state=='add_manually'" class="fab bottom floating red" (click)="account_state='start'">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div *ngIf="account_state=='send_request'" class="text-center">
    <div class="h-group center mb-4 padded">
      <h2 class="title no-float">Send Account Request</h2>
      <p class="subtitle">An email will be sent to borrower to add an account</p>
    </div>
    <div>
      <app-button class="mr-1 small" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
        (click)="confirmBankAccount()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
        [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
      </app-button>
      <app-linkgenerator [link_type]='3' [request_id]='request_id' (linkGenerated)="linkGenerated($event)">
      </app-linkgenerator>
    </div>
    <div class="text-center">
      <div class="form-group mt-2">
        <p>{{link}}</p>
      </div>

    </div>

  </div>
  <div *ngIf="account_state=='add_manually'">
    <app-newbankform [hideheader]=true [showTestTransfer]=false (saveTheLenderBank)="saveLenderBank()"
      (changeTheBankCode)="changeBankCode($event)" (verifyTheAccount)="verifyAccount($event)"
      [nigerian_banks]="nigerian_banks" [fresponse]="fresponse" [otpConfirmed]="otpConfirmed" [otpHBSFC]="otpHBSFC"
      [showForm]="showForm" [confirming]="confirming" [loading]="loading" [VERIFY_STATUS]="VERIFY_STATUS"
      [charge_account]="charge_account" [bank]="bank">
    </app-newbankform>
  </div>
  <div *ngIf="account_state=='start'">
    <div *ngIf="loading">
      <div class="cards">
        <div class="card skeleton-children">
          <div class="mb-2">Account name</div>
          <div class="mb-1">Account Number</div>
          <div class="">Account name</div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="cards" *ngIf="accounts.length">
        <div class="card" *ngFor="let account of accounts" [ngClass]="{'blue': account.default}">
          <div>
            <span class="bank">{{account?.bank_name}}  <span class="direct">{{account?.direct_debit_exists ==true ?" (Direct debit exist)" :" (No direct debit)"}}</span> </span>
            <span *ngIf="account.direct_debit_exists === true">DD Mandate</span>
          </div>
          <div class="number">{{account?.account_number}}</div>
          <div class="name">{{account?.account_name}}</div>
          <div class="name">{{account?.account_balance}}</div>
          <div class="overlay">
            <app-button class="default small" label="Make Default" *ngIf="!account.default"
              (click)="setAsConnectedAccount(request_id,account?.account_card_id)"></app-button>
            <app-button class="danger small" label="Delete" *ngIf="account.default"
              (click)="deleteAccount(account?.account_card_id,request_id)"></app-button>
            <!-- <app-button [loading]="loading_statement" class=" small" label="Pull Statement"
              (click)="pullstatement(account?.account_card_id,request_id)"></app-button>
            <app-button class=" small" label="Check Balance"
              (click)="checkbalance(account?.account_card_id,request_id)"></app-button> -->
          </div>
        </div>
      </div>
      <div class="h-group center mb-4 padded empty-state mt-5" *ngIf="!accounts.length">
        <div class="icon"><i class="fa fa-meh-o"></i></div>
        <h2 class="title no-float">No account</h2>
        <p class="subtitle">No account has been added</p>
      </div>
    </div>
  </div>
</div>
<div class="card__container" *ngIf="!grantRight('35')">
  <div>You don't have the right to view this page</div>
</div>