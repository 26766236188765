<div class="qna-wrap" [ngClass]="{ visible: visible }">
  <button class="trigger" (click)="toggleVisible()"><span>Q&A</span></button>
  <div class="qna" data-simplebar>
    <div class="qna-search form-group">
      <input class="form-control" placeholder="Search.." type="search" name="qnaSearch" id="qnaSearch"
        [(ngModel)]="query" (input)="search()" />
    </div>
    <div class="qna-list">
      <iframe [src]="link" style="z-index: 1000; background: rgba(255, 255, 255, 0.99); border: 0px; overflow: hidden; visibility: visible; margin: 0px; padding: 0px; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%; display: block;" allow="geolocation"></iframe>
    </div>
  </div>
</div>