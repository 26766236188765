<div class="offer_form" style="text-align: left; width: 75%; margin: 0px auto;">
  <!-- <h4>Search Employees</h4> -->
  <div>
    <input class="form-control" type="text" name="" placeholder="" (keyup)="lookforlenders()" name="lender_name"
      [(ngModel)]="lender_name" placeholder="Search employees..">
  </div>


  <div *ngIf="slenders.length > 0 && !lender_selected" style="height:300px; overflow-y:scroll; border: 1px solid #ccc">
    <article class="flex align-center pd_10" *ngFor="let lender of slenders" (click)="chooseLender(lender)">
      <div>
        <img
          src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{lender?.FILE_NAME | imagenotfound}}"
          style=" width: 40px; height: 40px; border-radius: 50%; object-fit: cover; object-position: center;">
      </div>
      <div class="ml-2">
        <p style="word-wrap:break-word;">{{lender?.LEGAL_NAME}}</p>
        <!-- <p>{{lender?.EMAIL|limitto:20}}</p> -->
      </div>
    </article>
  </div>


  <div *ngIf="lender_selected" class="selected_inv" style="padding: 20px 0; border-bottom: 1px solid #ccc;">


    <article class="flex align-center" style="height: 40px; overflow: hidden;">
      <div>
        <!-- <img src="assets/attachments/{{selected?.CUSTOMER_TYPE | imagenotfound}}" style=" width: 40px; height: 40px;"> -->
        <img
          src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{selected?.FILE_NAME | imagenotfound}}"
          style=" width: 40px; height: 40px;border-radius: 50%; object-fit: cover; object-position: center;">
      </div>
      <div class="ml-2">
        <p style="word-wrap:break-word;">{{selected?.LEGAL_NAME}}</p>
        <!-- <p>{{lender?.EMAIL|limitto:20}}</p> -->
      </div>
    </article>
  </div>

</div>