import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { LoansService, StorageService, DataService } from 'src/app/_services';

@Component({
  selector: 'app-ninbyphonenumber',
  templateUrl: './ninbyphonenumber.component.html',
  styleUrls: ['./ninbyphonenumber.component.css']
})
export class NinbyphonenumberComponent implements OnInit {
  
  @Input() reqid = '';
  @Output() openMore = new EventEmitter();
  view = 'list';
  currentUser: any;
  loading = false;
  statements: Array<any> = [];
  loan: any;
  statement: any;
  checking = [];
  viewing_transactions = false;
  data:any;
  DOCTYPE = '0';
  @Input() self = true;
  photo:any;
  signature:any;
  TYPE='1';
  constructor(
    private toastr: ToastrService,
    public loansService: LoansService,
    public storageService: StorageService,
    public dataService: DataService,
    private sanitize: DomSanitizer
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    // this.dataService.analysisComplete.subscribe(res => {
    //   this.getAllSMSTransactionAnalysis();
    // });
  }

  ngOnInit() {
    this.getNINByPhoneNumber();
    
  }
  useSign(){
    this.loading = true;
    this.loansService.useSign(this.currentUser.token, this.reqid)
    .subscribe(data => {
      this.loading = false;
     

    });
  }
  runAnalytics(statement) {
    this.statement = statement;
    this.viewing_transactions = false;
  }

  viewTransactions(statement) {
    this.statement = statement;
    this.viewing_transactions = true;
  }

  viewResult(statement) {
    this.statement = statement;
    this.view = 'result';
  }

  done(statements) {
   // this.getAllSMSTransactionAnalysis();
  }

  cancel() {
    this.view = 'list';
    this.statement = null;
    this.viewing_transactions = false;
  }

  checkAnalytics(statement) {
    this.checking = [...this.checking, statement.ATTACHMENT_ID];
    this.loansService.checkBankStatementAnalysisStatus(this.currentUser.token, statement)
      .subscribe(data => {
        console.log(data);
        this.checking = this.checking.filter(s => s.ATTACHMENT_ID === statement.ATTACHMENT_ID);
      });
  }

  getNINByPhoneNumber() {
    this.loading = true;
    this.loansService.getNINByPhoneNumber(this.currentUser.token, this.reqid)
    .subscribe(data => {
      this.loading = false;
     this.data = data.data;
     this.photo = this.sanitize.bypassSecurityTrustResourceUrl('data:image/png;base64,'+this.data.NIN_DETAILS.photo);
     this.signature = this.sanitize.bypassSecurityTrustResourceUrl('data:image/png;base64,'+this.data.NIN_DETAILS.signature);
     this.view = 'list'

    });
  }
  removeNIN(){
    this.loading = true;
    this.loansService.removeNINByPhoneNumber(this.currentUser.token, this.reqid)
    .subscribe(data => {
      this.loading = false;
     this.data = data.data;
     this.photo = this.sanitize.bypassSecurityTrustResourceUrl('data:image/png;base64,'+this.data.NIN_DETAILS.photo);
     this.signature = this.sanitize.bypassSecurityTrustResourceUrl('data:image/png;base64,'+this.data.NIN_DETAILS.signature);
     this.view = 'list'

    });
  }
  uploadThePhoto() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    this.loansService.runNINByPhoneNumber(this.currentUser.token, this.reqid, this.DOCTYPE)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          if(this.self){

            this.getNINByPhoneNumber();

          }else{
            this.dataService.eligibilitycriteriarefreshed.emit()
          }
          
        }else{
          this.showError('Error processing request');
        }
       
      });
    
  }
  uploadThePhoto_() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    this.loansService.runNINByNINNumber(this.currentUser.token, this.reqid, this.DOCTYPE)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          if(this.self){

            this.getNINByPhoneNumber();

          }else{
            this.dataService.eligibilitycriteriarefreshed.emit()
          }
          
        }else{
          this.showError('Error processing request');
        }
       
      });
    
  }
  
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
}
