import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { LoansService, StorageService, DataService } from 'src/app/_services';

@Component({
  selector: 'app-upfrontpayment',
  templateUrl: './upfrontpayment.component.html',
  styleUrls: ['./upfrontpayment.component.css']
})
export class UpfrontpaymentComponent implements OnInit {

  @Input() reqid = '';
  @Output() openMore = new EventEmitter();
  view = 'list';
  currentUser: any;
  loading = false;
  statements: Array<any> = [];
  loan: any;
  statement: any;
  checking = [];
  viewing_transactions = false;
  data:any;
  DOCTYPE = '0';
  @Input() self = true;
  photo:any;
  signature:any;
  TYPE='1';
  constructor(
    private toastr: ToastrService,
    public loansService: LoansService,
    public storageService: StorageService,
    public dataService: DataService,
    private sanitize: DomSanitizer
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    // this.dataService.analysisComplete.subscribe(res => {
    //   this.getAllSMSTransactionAnalysis();
    // });
  }

  ngOnInit() {
    this.getNINByPhoneNumber();
    
  }
  getNINByPhoneNumber() {
    this.loading = true;
    this.loansService.getUpfrontStatus(this.currentUser.token, this.reqid)
    .subscribe(data => {
     this.loading = false;
     if(data.status == true){
      this.data = data.data.transaction_payload;
     }

    });
  }

}
