import { Component, OnInit } from '@angular/core';
import { OperationsService } from '../../_services/operations.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sms-due-reminder',
  templateUrl: './sms-due-reminder.component.html',
  styleUrls: ['./sms-due-reminder.component.css']
})
export class SmsDueReminderComponent implements OnInit {
  loading: boolean;
  vendor_id: any;
  message: string;
  token: any;

  constructor(private operationServces: OperationsService, public toastr: ToastrService) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');

    this.operationServces.getDueSms(this.token)
      .subscribe(msg => {
        this.message = msg.SMS_DUE_REMINDER;
      });
  }

  onSubmitSMS(f) {
    this.loading = true;
    const token = this.vendor_id;
    const formvalue = f.form.value.message;

    this.operationServces.dueSms(this.token, formvalue)
      .subscribe(onUpdateBodyContent => {
        this.loading = false;
        this.toastr.success('Success', 'Success!');
      });
  }

}
