import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoansService, StorageService, DataService } from 'src/app/_services';

@Component({
  selector: 'app-accountbalance',
  templateUrl: './accountbalance.component.html',
  styleUrls: ['./accountbalance.component.css']
})
export class AccountbalanceComponent implements OnInit {

  @Input() reqid = '';
  @Output() openMore = new EventEmitter();
  view = 'list';
  currentUser: any;
  loading = false;
  statements: Array<any> = [];
  loan: any;
  statement: any;
  checking = [];

  constructor(
    private toastr: ToastrService,
    public loansService: LoansService,
    public storageService: StorageService,
    public dataService: DataService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.analysisComplete.subscribe(res => {
      this.getAllBankStatementAnalysis();
    });
  }

  ngOnInit() {
    this.getAllBankStatementAnalysis();
  }

  runAnalytics(statement) {
    this.statement = statement;
  }

  viewResult(statement) {
    this.statement = statement;
    this.view = 'result';
  }

  done(statements) {
    this.statements = statements;
  }

  cancel() {
    this.view = 'list';
    this.statement = null;
  }

  checkAnalytics(statement) {
    this.checking = [...this.checking, statement.ATTACHMENT_ID];
    this.loansService.checkBankStatementAnalysisStatus(this.currentUser.token, statement)
      .subscribe(data => {
        console.log(data);
        this.checking = this.checking.filter(s => s.ATTACHMENT_ID === statement.ATTACHMENT_ID);
      });
  }

  getAllBankStatementAnalysis() {
    this.loading = true;
    this.loansService.getAllBankStatementAnalysis(this.currentUser.token, this.reqid)
      .subscribe(({ data: statements, loan }) => {
        this.loading = false;
        this.statements = statements;
        this.loan = loan;
      });
  }
  viewStatement(statement) {
    this.loading = true;
    this.loansService.viewStatement(this.currentUser.token, statement.ATTACHMENT_ID)
      .subscribe(res => {
        this.loading = false;
        this.statements = res; 
        if(statement.IS_TEST=='2'){
          this.view='okratransactions';
        }
        if(statement.IS_TEST=='4'){
          this.view='monotransactions';
        }
      });
  }
  runStatementAnalysis(statement) {
    this.loading = true;
    this.loansService.runStatementAnalysis(this.currentUser.token, statement.ATTACHMENT_ID)
      .subscribe(res => {
        this.loading = false;
        
      });
  }
  checkAccountBalance(statement) {
    this.loading = true;
    this.loansService.checkAccountBalance(this.currentUser.token, statement.ATTACHMENT_ID)
      .subscribe(res => {
        
        this.loading = false;
        if(statement.IS_TEST=='2'){
          if(res.status == 'success'){
            this.loading = false;
            this.showSuccess(res.data.balance.available_balance)
          }else{
            this.showError("Error retrieving balance");
          }
        }
        if(statement.IS_TEST=='4'){

          if(res.account){
            this.showSuccess(res.account.balance/100)
          }else{
            this.showError("Error retrieving balance");
          }
        }
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
}
