<div class="tags">
  <!-- <div class="title flex justify-between align-center" [ngClass]="where_from!='self'? 'setags_': 'setags'"> -->

  <!-- <div [ngClass]="where_from!='self'? 'panel padd ptop': 'pd'"> -->
  <div class="panel padding-regular loading" *ngIf="loading">
    <div style="height: 100px;"></div>
  </div>
  <div class="panel padding-regular" *ngIf="!loading">
    <div class="flex justify-between align-center mb-4">
      <app-button *ngIf="where_from!='self'" class="small outline" label="Back" icon="fa fa-arrow-left"
        (click)="gobacktoloan()">
      </app-button>
      <div class="btn-group">
        <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">Select option you want to display
          <i class="fa fa-chevron-down ml-2"></i></button>
        <ul class="dropdown-menu">
              <app-emailtags (iTAC)="insertTextAtCursor($event)"></app-emailtags>
        </ul>
      </div>
    </div>


    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='address'">
      <div class="panel-heading no-border" role="tab" id="headingContact1">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact1" aria-expanded="true"
            aria-controls="collapseFIR">
            Address Section
          </a>
        </h4>
      </div>
      <div id="collapseContact1" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <form #addressBodyForm="ngForm" (ngSubmit)="onSubmitAddressLetterBody(addressBodyForm)">
          <div class="panel-body">
            <div class="form-check form-check-inline">
              <div class="form-check form-check-inline">
                <label class="form-check-label " for="name">Customer Name</label>
                <input class="form-check-input pull-right" type="checkbox" name="name"
                  [(ngModel)]="offerLetterObject.name" #name="ngModel">
              </div> 
              <label class="form-check-label" for="date">Current Date</label>
              <input class="form-check-input pull-right" type="checkbox" name="date"
                [(ngModel)]="offerLetterObject.date" #date="ngModel">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label " for="address">Customer Address</label>
              <input class="form-check-input pull-right" type="checkbox" name="address"
                [(ngModel)]="offerLetterObject.address" #address="ngModel">
            </div>
            <app-button type="submit" [disabled]="!addressBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </div>

        </form>
      </div>
    </div>
    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='salutation'">
      <div class="panel-heading no-border" role="tab" id="headingContact2">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact2" aria-expanded="true"
            aria-controls="collapseFIR">
            Salutation
          </a>
        </h4>
      </div>
      <div id="collapseContact2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">

          <form #salutationBodyForm="ngForm" (ngSubmit)="onSubmitSalutation(salutationBodyForm)">
            <app-ngx-editor [config]="editorConfig2" [(ngModel)]='this.offer_salutation' name="Salutation">
            </app-ngx-editor>

            <app-button type="submit" [disabled]="!salutationBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div>

    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='subject'">
      <div class="panel-heading no-border" role="tab" id="headingContact3">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSubject" aria-expanded="true"
            aria-controls="collapseFIR">
            Subject
          </a>
        </h4>
      </div>
      <div id="collapseSubject" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #subjectBodyForm="ngForm" (ngSubmit)="onSubmitSubjectBody(subjectBodyForm)">
            <app-ngx-editor [config]="editorConfig2" [(ngModel)]='this.subject' name="subject">
            </app-ngx-editor>
            <p></p>
            <app-button type="submit" [disabled]="!subjectBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div>

    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='body'">
      <div class="panel-heading no-border" role="tab" id="headingContact3">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact3" aria-expanded="true"
            aria-controls="collapseFIR">
            Body
          </a>
        </h4>
      </div>
      <div id="collapseContact3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #letterBodyForm="ngForm" (ngSubmit)="onSubmitLetterBody(letterBodyForm)">
            <app-ngx-editor [config]="editorConfig" [(ngModel)]='this.body' name="body">
            </app-ngx-editor>
            <p></p>
            <app-button type="submit" [disabled]="!letterBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div> 
    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='signature'">
      <div class="panel-heading no-border" role="tab" id="headingContact5">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact5" aria-expanded="true"
            aria-controls="collapseFIR">
            Remark & Singnatures
          </a>
        </h4>
      </div>
      <div id="collapseContact5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #signatureForm="ngForm" (ngSubmit)="
          (signatureForm)">
            <label class="form-group">
              <span class="control-label normal">Remark</span>
              <select name="rm" class="form-control" [(ngModel)]="remark" #rm="ngModel">
                <option value="" disabled>Select one</option>
                <option [value]="1">Faithfully</option>
                <option [value]="2">Sincerely</option>
              </select>
            </label>
            <div class="form-check form-check-inline">
              <label class="form-check-label " for="name">Enable Left Signature</label>
              <input class="form-check-input pull-right" type="checkbox" name="ls" [(ngModel)]="signatureleft"
                #ls="ngModel">
            </div>
            <div *ngIf="signatureleft">
              <label class="form-group">
                <span class="control-label normal">Name</span>
                <input type="text" class="form-control" placeholder=""
                  [(ngModel)]="offerLetterObject.signature1Name" name="signature1Name" #signature1Name="ngModel">
              </label>
              <label class="form-group">
                <span class="control-label normal">Position</span>
                <input type="text" class="form-control" placeholder="CEO"
                  [(ngModel)]="offerLetterObject.signature1Position" name="signature1Position"
                  #signature1Position="ngModel">
              </label>

              <div class="row">
                <div class="col-sm-8">
                  <div class="form-group">
                    <label for="exampleFormControlFile1" class="control-label normal">Upload Manager Signature </label>
                    <input type="file" class="form-control-file" (change)="onFileChangedSignature1($event)"
                      [(ngModel)]="selectedSignature1" #signaturevalidate1="ngModel" name="signature1">
                  </div>

                  <div *ngIf="!signaturevalidate1.valid && signaturevalidate1.touched">
                    <div class="alert alert-danger" role="alert">
                      Signature 1 is required!
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <img width="120" src="{{signatureimage1}}">
                </div>
              </div>
            </div>
            <hr>
            <app-button type="submit" [disabled]="!signatureForm.form.valid" label="Upload" [loading]="loader"
              class="small mt-2">
            </app-button>
          </form>
        </div>
      </div>
    </div> 
  </div>