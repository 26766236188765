<!-- <div *ngIf="link_type=='4'">
  <app-button [disabled]="disabled" class="small default mr-2" label="Generate Link"
  (click)="generateGuarantorLink()" [disabled]="loading" [loading]="loading"></app-button>
<div class="form-group mt-2">
  <p>{{link}}</p>
</div> 
</div> -->
<div *ngIf="link_type=='5'">
  <app-button [disabled]="disabled" class="small default outline mt-2" label="Generate Link"
    (click)="generateOtherDocsLink()" [disabled]="loading" [loading]="loading"></app-button>
  <div class="form-group mt-2">
    <p>{{link}}</p>
  </div>
</div>
<div *ngIf="link_type !='5'">
  <app-button [disabled]="disabled" class="small default outline mt-2" label="Generate Link"
    (click)="generateBVNLink(bvn_details)" [disabled]="loading" [loading]="loading"></app-button>
  <div class="form-group mt-2">
    <p>{{link}}</p>
  </div>
</div>