import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aoff'
})
export class AoffPipe implements PipeTransform {

  transform(number: any): string {
    if(number){
      //return '('+number.match(/\b\w/g).join('')+')';
      return number;
    }else{
      return "";
    }


  }


}
