<div class="panel flex flex-column flex-center">
  <div class="icon">
    <img src="/assets/images/under-contruction.svg" alt="Under constructon" />
  </div>
  <div class="h-group">
    <p class="subtitle">
      This feature in currently in beta with some partners and will be available in
    </p>
    <h3 class="title">
      {{ timer.days }}d : {{ timer.hours }}h : {{ timer.minutes }}m : {{ timer.seconds }}s
    </h3>
  </div>
</div>