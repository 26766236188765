import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ApprovalsService, DataService, UserService, OperationsService } from '../_services/index';
import { AuthenticationService, StorageService, AnalyticsService } from '../_services/index';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {


  loading = false;
  currentUser:any;
  platform_summary: any;
  constructor(public analytics: AnalyticsService, public approvalService: ApprovalsService, public DataService: DataService,
    public router: Router, public fb: FormBuilder, public operationsService: OperationsService, public authService: AuthenticationService,
    public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.runPlatformSummary();
  }
  runPlatformSummary() {
    this.loading = true;
    this.analytics.runPlatformSummary(this.currentUser.token)
      .subscribe(data => {
        this.loading = false;
        this.platform_summary = data;

      });
  }

}
