import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-otherheader',
  templateUrl: './otherheader.component.html',
  styleUrls: ['./otherheader.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class OtherheaderComponent implements OnInit {
  @Input('text') text:any;
  constructor() { }

  ngOnInit() {
  }

}
