import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blockchain-analysis',
  templateUrl: './blockchain-analysis.component.html',
  styleUrls: ['./blockchain-analysis.component.css']
})
export class BlockchainAnalysisComponent implements OnInit {

  view = 'form';

  constructor() { }

  ngOnInit() {
  }

}
