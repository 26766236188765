<div class="panel padding-regular flex">
  <div class="flex-3">
      <div class="section-title color-red mb-4">Total Investents</div>
      <ul class="decList bigger">
          <li>
              <span>Investments</span>
              <span>{{data?.total_investment_count}}</span>
          </li>
          <li>
              <span>Investments Value</span>
              <span>{{data?.total_investment_value|number:'.2-2'}}</span>
          </li>
      </ul>
  </div>
  <div class="flex-1 flex flex-center">
      <div class="section-poster">
          <img src="/assets/images/money-bag.svg" alt="" style="width: 50px">
      </div>
  </div>
</div>