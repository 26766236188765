<div class="mb-1">
  <div class="panel flex recollection">
    <app-help-popover [content]="[
            {
              title: 'How debit card debit settings works!',
              img: 'assets/images/creditcard-icon.svg',
              text:
                '
              <div>
                <p>This setting allows for the debit-card attached by the borrower to be debited when due.</p>
              </div>'
            },
            {
              title: 'Account debit settings',
              img: 'assets/images/mastercard.svg',
              text:
                '
              <div>
                <p> This setting allows for the card attached by the borrower to be debited when due.</p>
              </div>'
            },
            {
              title: 'Cheque setup',
              img: 'assets/images/cheque.svg',
              text:
                '
              <div>
                <p>This setting allows check as repayment option</p>
              </div>'
            },
            {
              title: 'Borrower actions',
              img: 'assets/images/report.svg',
              text:
                '
              <div>
                <p>This allows you to take different actions on the borrowers request 
                  e.g requesting for card, documents, guarantors and also allows you delete request.</p>
                </div>'
            },
            {
              title: 'Remitta inflight',
              img: 'assets/images/credit-card.svg',
              text:
                '
              <div>
                <p>This setting is used to setup a mandate for the borrower if he’s eligible for this repayment method.
                </p>
              </div>'
            }
          ]"></app-help-popover>

    <div class="flex-1 flex flex-column justify-center">
      <div class="icon-text medium tall flex-1 has-overlay">
        <div class="icon color-blue"><i class="fa fa-credit-card"></i></div>
        <p class="text">Card Debit Settings</p>
        <div *ngIf="loan?.repayment_mode=='2'" class="tag blue mt-1">Default</div>
        <div class="overlay flex-center flex-column">
          <app-button label="View" class="small mb-1" [disabled]="!grantRight('35')" (click)="openModal('setup_cards')">
          </app-button>
          <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default" (click)="setDefaultRepaymentMode(loan,2)"
            [disabled]="!grantRight('35')" class="small outline">
          </app-button>
        </div>
      </div>
      <div class="icon-text medium has-overlay">
        <div class="icon color-grey"><i class="fas fa-building"></i></div>
        <span class="text">Offline collection</span>
        <div class="overlay flex-center flex-column">
          <div class="w-60 text-center">
            Offline collection not available at this point
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 flex flex-column justify-center" style="border-left: 1px solid rgba(0, 0, 0, .1);">
      <div class="icon-text medium has-overlay flex-1">
        <div class="icon color-orange"><i class="fa fa-bank"></i></div>
        <p class="text"><span>Account Debit Settings</span></p>
        <div *ngIf="loan?.repayment_mode=='3'" class="tag blue mt-1">Default</div>
        <div class="overlay flex-center flex-column">
          <app-button label="View" buttonclass="small mb-1" [disabled]="!grantRight('35')"
            (click)="openModal('account_debit')"></app-button>
          <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default" (click)="setDefaultRepaymentMode(loan,3)"
            [disabled]="!grantRight('35')" class="small outline">
          </app-button>
        </div>
      </div>
      <div class="icon-text medium has-overlay">
        <div class="icon color-teal"><i class="fa fa-money"></i></div>
        <p class="text"><span>Cheque Setup</span></p>
        <div *ngIf="loan?.repayment_mode=='5'" class="tag blue mt-1">Default</div>
        <div class="overlay flex-center flex-column">
          <app-button label="View" buttonclass="small mb-1" [disabled]="!grantRight('27')"
            (click)="openModal('setup_cheques')"></app-button>
          <app-button *ngIf="loan?.repayment_mode=='0'" label="Set as default" (click)="setDefaultRepaymentMode(loan,5)"
            [disabled]="!grantRight('27')" class="small outline">
          </app-button>
        </div>
      </div>
      <div class="icon-text medium has-overlay">
        <div class="icon color-green"><i class="fa fa-paper-plane-o"></i></div>
        <p class="text"><span>Remita Inflight</span></p>
        <div *ngIf="loan?.repayment_mode=='1' && loan?.use_remita=='1'" class="tag blue mt-1">Default</div>
        <div class="overlay flex-center flex-column">
          <app-button *ngIf="loan?.use_remita=='1'" label="View" [disabled]="!grantRight('81')" buttonclass="small mb-1"
            (click)="openModal('remita_inflight')"></app-button>
          <app-button *ngIf="loan?.use_remita=='1' && loan?.repayment_mode=='0'" label="Set as default"
            (click)="setDefaultRepaymentMode(loan,1)" [disabled]="!grantRight('81')" class="small outline">
          </app-button>
          <app-button *ngIf="loan?.use_remita=='0'" label="Get Info" (click)="openModal('remita_inflight')"
            [disabled]="!grantRight('81')" class="small outline">
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>