
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { LenderModel } from '../_models/lender.model';
import { ConstantsService } from './constants.service';
import 'rxjs/add/operator/map';

declare global {
  interface Window { OneSignal: any; }
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public token: string;
  public account_id: string;
  public role: string;
  public lender_id: string;
  public legal_name: string;
  public platform_settings: any;

  constructor(private http: Http, private constant: ConstantsService) {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  public getUserRoles(): any {
    return localStorage.getItem('roles');
  }
  getUserRights() {
    return JSON.parse(localStorage.getItem('rights'));
  }

  public canViewModule(roles: any): boolean {
    let isAllowed = false;
    const user = this.getUserRoles().split(',');
    const allowedGroups = roles.split(',');

    if (user !== null && user !== undefined) {
      try {
        if (user !== undefined && user !== null && user.length > 0) {
          try {
            user.forEach((e: any) => {
              if (allowedGroups.indexOf(e) > -1) {
                isAllowed = true;
              }
            });
          } catch (e) {
            if (allowedGroups.indexOf(user) > -1) {
              isAllowed = true;
            }
          }
        }
      } catch (e) {
        isAllowed = false;
      }
    }
    return isAllowed;
  }
  public userHasRights(right: String): boolean {
    const userRights = this.getUserRights();
    // console.log(userRights);
    if (!userRights) return;
    let isAllowed = false;
    if (userRights.indexOf(right) > -1) {
      // console.log(right);
      isAllowed = true;
    }
    return isAllowed;
  }
  changePassword(newpassword: string, confirmpassword: string, token: string) {

    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/changePassword',
      JSON.stringify({ newpassword: newpassword, confirmpassword: confirmpassword, token: token }));
  }

  changeSecurityQuestion(model: any, token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/changeSecurity',
      JSON.stringify({ model: model, token: token }));
  }
  confirmOTP(token: any, otp: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/confirm_otp', JSON.stringify({ token: token, otp: otp }))
      .map((response: Response) => response.json());
  }
  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('rights');
    localStorage.removeItem('is_company_staff');
    localStorage.removeItem('roles');
    localStorage.removeItem('has_remita');

    localStorage.removeItem('timeLeft');
    localStorage.removeItem('lender_activated');
    localStorage.removeItem('enable_accounting');
    localStorage.removeItem('user_id');
    localStorage.removeItem('cooperative_id');
    localStorage.removeItem('has_branches');
    localStorage.removeItem('appKey');
    localStorage.removeItem('test_mode');


    localStorage.removeItem('enable_peer_to_peer');
    localStorage.removeItem('decision_type');
    localStorage.removeItem('company_id');
    localStorage.removeItem('sample_user');
    localStorage.removeItem('platform');
    localStorage.removeItem('type_of_view');

    localStorage.removeItem('kyc_setup');

    var OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.deleteTag("email");
    });
  }

  forgot(email: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/forgot', JSON.stringify({ email: email }))
      .map((response: Response) => response.json());
  }
  authorize(token: any): Observable<boolean | string> {
    console.log("Hiiiiiiiiiiiii",'Hello')
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/authorize_omni', JSON.stringify({
      token: token
    }))
      .map((response: Response) => response.json())
      .map((response: any) => {
        console.log("Hiiiiiiiiiiiii2",'Hello')
        localStorage.removeItem('currentUser');
        // login successful if there's a jwt token in the response
        const token = response.token;
        const account_id = response.account_id;
        const role = response.role;
        const legal_name = response.legal_name;
        const photo = response.photo;
        const icon = response.icon;
        const enable_peer_to_peer = response.enable_peer;
        const enable_accounting = response.enable_accounting;
        const has_branches = response.has_branches;
        const roles = response.roles;
        const rights = response.rights;
        const platform = response.platform;
        const user_id = response.account_id;
        const is_company_staff = response.is_company_staff;
        const company_id = response.company_id;
        const test_mode = response.test_mode;
        const decision_type = response.decision_type;
        const has_remita = response.has_remita;
        const lender_activated = response.lender_activated;
        const timeLeft = response.timeleft;
        const appKey = response.appKey;
        const kyc_setup = response.kyc_setup;
        const sample_user = response.sample_user;
        const push_to_loan_disk = response.push_to_loan_disk;
        const enable_custom_narration = response.enable_custom_narration;
        const has_own_cloudinary = response.has_own_cloudinary;
        const cloudinary_cloud_name = response.cloudinary_cloud_name;
        const cloudinary_version = response.website_logo;
        const showoff = response.showoff;
        const email = response.email;
        console.log("Hiiiiiiiiiiiii3",'Hello')
        if (token) {
          console.log("Hiiiiiiiiiiiii4",'Hello')
          // set token property
          this.token = token;
          this.account_id = account_id;
          this.role = role;
          this.legal_name = legal_name;
          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({
            is_company_staff: is_company_staff,
            company_id: company_id,
            photo: photo,
            email: email,
            token: token,
            role: role,
            account_id: account_id,
            legal_name: legal_name,
            icon: icon,
            platform: platform,
            rights: rights,
            kyc_setup: kyc_setup,
            enable_custom_narration: enable_custom_narration,
            push_to_loan_disk: push_to_loan_disk,
            cloudinary_cloud_name: cloudinary_cloud_name,
            has_own_cloudinary: has_own_cloudinary,
            cloudinary_version: cloudinary_version,
            showoff: showoff
          }));
          localStorage.setItem('sample_user', JSON.stringify(sample_user));
          localStorage.setItem('token', token);
          localStorage.setItem('id_token', token);
          localStorage.setItem('enable_peer_to_peer', enable_peer_to_peer);
          localStorage.setItem('enable_accounting', enable_accounting);
          localStorage.setItem('roles', roles);
          localStorage.setItem('rights', JSON.stringify(rights));
          localStorage.setItem('platform', platform);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('is_company_staff', is_company_staff);
          localStorage.setItem('company_id', company_id);
          localStorage.setItem('cooperative_id', '0');
          localStorage.setItem('test_mode', test_mode);
          localStorage.setItem('lender_activated', lender_activated);
          localStorage.setItem('has_remita', has_remita);
          localStorage.setItem('decision_type', decision_type);
          localStorage.setItem('timeLeft', timeLeft);
          localStorage.setItem('appKey', appKey);
          localStorage.setItem('has_branches', has_branches);
          const type_of_view = localStorage.getItem('type_of_view');
          if (!type_of_view) {
            localStorage.setItem('type_of_view', '1');
          }
          const expiresAt = JSON.stringify((60 * 60 * 1000) + Date.now());
          localStorage.setItem('expires_at', expiresAt);
          // return true to indicate successful login
          return response.landing;
        } else {
          // return false to indicate failed login
          return false;
        }
      });
    
  }
  login(email: string, password: string): Observable<boolean | string> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/authenticate_omni', JSON.stringify({
      email: email,
      password: password,
      grant_type: 'password'
    }))
      .map((response: Response) => response.json())
      .map((response: any) => {
        // var OneSignal = window.OneSignal || [];
        // OneSignal.push(function () {
        //   OneSignal.sendTag("email", email);
        // });

        localStorage.removeItem('currentUser');
        // login successful if there's a jwt token in the response
        const token = response.token;
        const account_id = response.account_id;
        const role = response.role;
        const legal_name = response.legal_name;
        const photo = response.photo;
        const icon = response.icon;
        const enable_peer_to_peer = response.enable_peer;
        const enable_accounting = response.enable_accounting;
        const has_branches = response.has_branches;
        const roles = response.roles;
        const rights = response.rights;
        const platform = response.platform;
        const user_id = response.account_id;
        const is_company_staff = response.is_company_staff;
        const company_id = response.company_id;
        const test_mode = response.test_mode;
        const decision_type = response.decision_type;
        const has_remita = response.has_remita;
        const lender_activated = response.lender_activated;
        const timeLeft = response.timeleft;
        const appKey = response.appKey;
        const kyc_setup = response.kyc_setup;
        const sample_user = response.sample_user;
        const push_to_loan_disk = response.push_to_loan_disk;
        const enable_custom_narration = response.enable_custom_narration;
        const has_own_cloudinary = response.has_own_cloudinary;
        const cloudinary_cloud_name = response.cloudinary_cloud_name;
        const cloudinary_version = response.website_logo;
        const showoff = response.showoff;
        if (token) {
          // set token property
          this.token = token;
          this.account_id = account_id;
          this.role = role;
          this.legal_name = legal_name;
          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({
            is_company_staff: is_company_staff,
            company_id: company_id,
            photo: photo,
            email: email,
            token: token,
            role: role,
            account_id: account_id,
            legal_name: legal_name,
            icon: icon,
            platform: platform,
            rights: rights,
            kyc_setup: kyc_setup,
            enable_custom_narration: enable_custom_narration,
            push_to_loan_disk: push_to_loan_disk,
            cloudinary_cloud_name: cloudinary_cloud_name,
            has_own_cloudinary: has_own_cloudinary,
            cloudinary_version: cloudinary_version,
            showoff: showoff
          }));
          localStorage.setItem('sample_user', JSON.stringify(sample_user));
          localStorage.setItem('token', token);
          localStorage.setItem('id_token', token);
          localStorage.setItem('enable_peer_to_peer', enable_peer_to_peer);
          localStorage.setItem('enable_accounting', enable_accounting);
          localStorage.setItem('roles', roles);
          localStorage.setItem('rights', JSON.stringify(rights));
          localStorage.setItem('platform', platform);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('is_company_staff', is_company_staff);
          localStorage.setItem('company_id', company_id);
          localStorage.setItem('cooperative_id', '0');
          localStorage.setItem('test_mode', test_mode);
          localStorage.setItem('lender_activated', lender_activated);
          localStorage.setItem('has_remita', has_remita);
          localStorage.setItem('decision_type', decision_type);
          localStorage.setItem('timeLeft', timeLeft);
          localStorage.setItem('appKey', appKey);
          localStorage.setItem('has_branches', has_branches);
          const type_of_view = localStorage.getItem('type_of_view');
          if (!type_of_view) {
            localStorage.setItem('type_of_view', '1');
          }
          const expiresAt = JSON.stringify((60 * 60 * 1000) + Date.now());
          localStorage.setItem('expires_at', expiresAt);
          // return true to indicate successful login
          
          return response;
        } else {
          // return false to indicate failed login
          return false;
        }
      });
  }

  serializeParams(obj) {
    let str = '';
    for (var key in obj) {
      if (str != '') {
        str += '&';
      }
      str += key + '=' + obj[key];
    }
    return str;
  }

  signup_(lender: LenderModel): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/onboard', this.serializeParams(lender), options)
      .map((response: Response) => {
        localStorage.removeItem('currentUser');
        // login successful if there's a jwt token in the response
        let status = response.json() && response.json().status;
        if (status === false) {
          return response.json();
        } else {
          let token = response.json() && response.json().token;
          let account_id = response.json() && response.json().account_id;
          let role = response.json() && response.json().role;
          let photo = response.json() && response.json().photo;
          let enable_peer_to_peer = response.json() && response.json().enable_peer;
          let enable_accounting = response.json() && response.json().enable_accounting;
          let icon = response.json() && response.json().icon;
          if (token) {
            // set token property
            this.token = token;
            this.account_id = account_id;
            this.role = role;
            this.legal_name = lender.LEGAL_NAME;
            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify({
              icon: icon,
              photo: photo,
              email: lender.EMAIL,
              token: token,
              role: role,
              account_id: account_id,
              legal_name: lender.LEGAL_NAME
            }));
            localStorage.setItem('token', token);
            localStorage.setItem('id_token', token);
            localStorage.setItem('enable_peer_to_peer', enable_peer_to_peer);
            localStorage.setItem('enable_accounting', enable_accounting);
            let type_of_view = localStorage.getItem('type_of_view');
            if (!type_of_view) {
              localStorage.setItem('type_of_view', '1');
            }
            const expiresAt = JSON.stringify((60 * 60 * 1000) + Date.now());
            localStorage.setItem('expires_at', expiresAt);
            // return true to indicate successful login
            return response.json();
          } else {
            // return false to indicate failed login
            return response.json();
          }
        }

      });
  }

  signup(lender: any): Observable<any> {

    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/onboard', this.serializeParams(lender), options)
      .map((response: Response) => {
        localStorage.removeItem('currentUser');
        // login successful if there's a jwt token in the response
        let status = response.json() && response.json().status;
        if (status === false) {
          return response.json();
        } else {
          let token = response.json() && response.json().token;
          let account_id = response.json() && response.json().account_id;
          let role = response.json() && response.json().role;
          let photo = response.json() && response.json().photo;
          let enable_peer_to_peer = response.json() && response.json().enable_peer;
          let enable_accounting = response.json() && response.json().enable_accounting;
          let icon = response.json() && response.json().icon;
          if (token) {
            // set token property
            this.token = token;
            this.account_id = account_id;
            this.role = role;
            this.legal_name = lender.LEGAL_NAME;
            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify({
              icon: icon,
              photo: photo,
              email: lender.EMAIL,
              token: token,
              role: role,
              account_id: account_id,
              legal_name: lender.LEGAL_NAME
            }));
            localStorage.setItem('token', token);
            localStorage.setItem('id_token', token);
            localStorage.setItem('enable_peer_to_peer', enable_peer_to_peer);
            localStorage.setItem('enable_accounting', enable_accounting);
            let type_of_view = localStorage.getItem('type_of_view');
            if (!type_of_view) {
              localStorage.setItem('type_of_view', '1');
            }
            const expiresAt = JSON.stringify((60 * 60 * 1000) + Date.now());
            localStorage.setItem('expires_at', expiresAt);
            // return true to indicate successful login
            return response.json();
          } else {
            // return false to indicate failed login
            return response.json();
          }
        }

      });

  }

  submitSetup(setup) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/setup', {
      setup
    }).map(res => res.json());
  }
  goLiveNow(token: any, go_live_details: any) {
    return this.http.post(`${this.constant.read('api_base')}onboard/golive`, { token: token, go_live_details: go_live_details }).map(res => res.json());
  }
 
}
