<div *ngIf="!makingFinalPayment">
    <div *ngIf="request_step=='0'" class="padding-regular overpanel-scroll">
        <!-- <div class="padding-regular-x mt-2 mb-3">
        <div class="alert alert-info alert-sm">Loan Balance : {{data?.loan.LOAN_BALANCE| number:'.2-2'}}</div>
    </div> -->
        <div class="overpanel-center">
            <div class="form-group row mb-5 border-bottom">
                <div class="col-md-4" *ngFor="let opt of pay_opt">
                    <label>
                        <input [(ngModel)]="break_loan.IS_TOP_UP" type="radio" name="IS_TOP_UP" id="inlineCheckbox1"
                            [value]="opt.value">
                        <div class="control-label normal">{{opt.display}}</div>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="control-label col-md-6 normal">Breaking Date</label>
                <div class="col-md-6">
                    <my-date-picker [selDate]="selDate" name="BREAKING_DATE" (dateChanged)="onDateChanged($event)"
                        [options]="myDatePickerOptions" [(ngModel)]="break_loan.BREAKING_DATE"></my-date-picker>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="control-label col-md-6 normal">Loan Balance</label>
                <div class="col-md-6">
                    <input name="LOAN_BALANCE" [(ngModel)]="break_loan.LOAN_BALANCE" type="text" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="control-label col-md-6 normal">Interest Calculation</label>
                <div class="col-md-6">
                    <select (change)="onInterestChange($event)" class="form-control" name="INTEREST_CALCULATION"
                        [(ngModel)]="break_loan.INTEREST_CALCULATION">
                        <option [value]="0">As specified on the contract</option>
                        <option [value]="1">1 day equals 1 month</option>
                        <option [value]="2">Prorated</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="control-label col-md-6 normal">Charge all interest</label>
                <div class="col-md-6">
                    <input name="CHARGE_ALL_INTEREST" (change)="onChargeAllInterestChange($event)"
                        [(ngModel)]="break_loan.CHARGE_ALL_INTEREST" type="checkbox">
                </div>
            </div>
            <!-- <div class="form-group row">
                <label class="control-label col-md-6 normal">Penalty(%)</label>
                <div class="col-md-6">
                    <input name="PERCENTAGE_FINE" (change)="onPenaltyChanged($event)"
                        [(ngModel)]="break_loan.PERCENTAGE_FINE" type="text" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="control-label col-md-6 normal">Penalty(Flat)</label>
                <div class="col-md-6">
                    <input name="FLAT_FINE" (change)="onPenaltyChanged($event)" [(ngModel)]="break_loan.FLAT_FINE"
                        type="text" class="form-control">
                </div>
            </div> -->
            <div class="form-group row" *ngIf="break_loan.IS_TOP_UP=='6'">
                <label class="control-label col-md-6 normal">Password</label>
                <div class="col-md-6">
                    <input name="PASSWORD" [(ngModel)]="break_loan.PASSWORD" type="password" class="form-control">
                </div>
            </div>
            
            <div class="form-group animated ro_act tu" *ngIf="break_loan.IS_TOP_UP=='8'">
                <div class="row">
                    <label class="control-label normal col-md-6">Required Financing</label>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="number" (blur)="new_principal($event)" class="form-control"
                                name="REQUEST_PRINCIPAL" [(ngModel)]="break_loan.REQUEST_PRINCIPAL">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                                    <span class="sel_cur">{{loan_currency}}</span>
                                    <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu pull-right">
                                    <li *ngFor="let c of countries">
                                        <a href="javascript:;" (click)="changeCurrency(c)">{{c.currency[0]}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="break_loan.IS_TOP_UP=='8'||break_loan.IS_TOP_UP=='7'">
                <div class="row">
                    <label class="control-label normal col-md-6">Duration</label>
                    <div class="col-md-6">
                        <div class="input-group ">
                            <input type="text" class="form-control" [(ngModel)]="break_loan.REQUEST_TENOR"
                                name="REQUEST_TENOR">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                                    <span class="sel_cur">{{loan_duration}}</span>
                                    <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu pull-right">
                                    <li *ngFor="let d of loan_durations;let i=index;">
                                        <a (click)="changeDuration(i,1)">{{d.LOAN_DURATION}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="break_loan.IS_TOP_UP=='8'">
                <div class="row">
                    <label class="control-label normal col-md-6">New Principal</label>
                    <div class="col-md-6">
                        <div class="input-group ">
                            <input type="text" class="form-control" disabled [(ngModel)]="newprincipal"
                                name="NEW_PRINCIPAL">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                                    <span class="sel_cur">{{loan_currency}}</span>
                                    <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu pull-right">
                                    <li *ngFor="let c of countries">
                                        <a href="javascript:;" (click)="changeCurrency(c)">{{c.currency[0]}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="break_loan.IS_TOP_UP=='7'">
                <label for="" class="control-label col-md-6 normal">Rollover Option</label>
                <div class="col-md-6">
                    <select class="form-control" name="ROLLOVER_OPTION" [(ngModel)]="break_loan.ROLLOVER_OPTION">
                        <option [value]="1">Create a new loan</option>
                        <option [value]="2">Maintain current loan</option>
                    </select>
                </div>
            </div>
            <div class="flex justify-start" *ngIf="break_loan.IS_TOP_UP!='6'">
                <!-- <button class="btn btn-block btn-white" (click)="confirmingAction=!confirmingAction">Confirm</button> -->
                <app-button (click)="sendBreakLoan()"
                    [disabled]="loading || break_loan.IS_TOP_UP=='0'||(break_loan.IS_TOP_UP=='1'&&break_loan.REQUEST_PRINCIPAL=='')"
                    class="small" label="Save" [loading]="loading"></app-button>
                <app-button label="Cancel" class="small outline red ml-1" (click)="closeM()"></app-button>
            </div>
            <div class="flex justify-start" *ngIf="break_loan.IS_TOP_UP=='6'">
                <!-- <button class="btn btn-block btn-white" (click)="confirmingAction=!confirmingAction">Confirm</button> -->
                <div *ngIf="lender?.PEOPLE_ID=='40245'">
                    <app-button (click)="sendBreakLoan()"
                        [disabled]="loading || break_loan.IS_TOP_UP=='0'||(break_loan.IS_TOP_UP=='1'&&break_loan.REQUEST_PRINCIPAL=='')"
                        class="small" label="Save" [loading]="loading"></app-button>
                </div>
                <div *ngIf="lender?.PEOPLE_ID!='40245'">
                    <app-button (click)="approveBreakLoan()"
                        [disabled]="loading || break_loan.PASSWORD=='' || break_loan.IS_TOP_UP=='0'||(break_loan.IS_TOP_UP=='1'&&break_loan.REQUEST_PRINCIPAL=='')"
                        class="small" label="Approve Break Loan" [loading]="loading"></app-button>
                </div>



                <app-button label="Cancel" class="small outline red ml-1" (click)="closeM()"></app-button>
            </div>
        </div>
    </div>
    <div *ngIf="request_step=='1'" class="padding-regular overpanel-scroll">
        <div class="content-wrap">
            <form [formGroup]="complexForm" (ngSubmit)="doPaymentConfirm(complexForm.value)">
                <h4 class="section-title mb-3 mt-3">Approve Break Loan</h4>


                <div class="form-group">
                    <label for="exampleInputEmail1" class="control-label normal">{{security_question}}</label>
                    <input autocomplete="false" [formControl]="complexForm.controls['SECURITY_QUESTION_ANSWER']"
                        type="password" class="form-control" name="SECURITY_QUESTION_ANSWER">
                    <div *ngIf="otpError" class="alert alert-danger mt-2 alert-sm">
                        {{otpmessage}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-block btn-white" [disabled]="!complexForm.valid" type="submit">Confirm
                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <app-button class="small danger block" (click)="cancelPayment()" type="submit" label="Cancel">
                        </app-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="panel-body animated" *ngIf="makingFinalPayment">
    <div>
        <div class="panel-body" style="padding-bottom: 0px">
            <div class="text-center" style="padding: 20px 0px; padding-bottom: 0px"
                *ngIf="makingFinalPayment&&!paymentHasBeenProcessed">
                <a href="javascript:;" style="font-size:40px">
                    <i class="fa fa-hourglass fa-spin"></i>
                </a>
                <span style="display: block;">processing payment...</span>

            </div>

            <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
                *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&!paymentConfirmed">
                <a href="javascript:;" style="font-size:40px">
                    <i class="fa fa-info"></i>
                </a>
                <span style="display: block;">{{response_message}}</span>
            </div>

            <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
                *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&paymentConfirmed">
                <a href="javascript:;" style="font-size:40px">
                    <i class="fa fa-check"></i>
                </a>
                <span style="display: block;">Successful</span>
            </div>
        </div>

    </div>
</div>