<div *ngIf="sub_summary=='3'">
        <section class="panel" *ngIf="!makingPayment">
            <div class="panel-body" style="padding:0px">
                    <div >
                            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                                <div class="form-group">
                                  <span class="pull-left text-primary">Upcoming Repayments</span>
                                  <span class="pull-right"> 
                                  </span>
                                  <div class="clearfix"></div>
                                </div>
                            </div>
                            <!-- <div *ngFor="let repayment of loan?.schedule" style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                               <div class="form-group">
                                 <span class="pull-left text-primary">{{repayment.REPAYMENT_DATE}}</span>
                                 <span class="pull-right"> 
                                   {{repayment.HOW_MUCH_REMAINING}}
                                 </span>
                                 <div class="clearfix"></div>
                               </div>
                           </div> -->
                           <article class="media" *ngFor="let repayment of loan?.schedule">
                                <div class="pull-left thumb" style="padding-right: 20px">
                                  <img src="assets/images/i.PNG">
                                </div>
                                <div class="pull-left" style="margin-top: 5px">
                                  <h5 class="no-margin" style="margin-bottom: 7px">{{repayment?.HOW_MUCH_REMAINING|number:'.2-2'}}</h5>
                                  <p class="no-margin t_lh1 t_l4" style="font-size: 14px">{{repayment?.HOW_MUCH_PAID|number:'.2-2'}}</p> 
                                </div>
                                
                                <div class="pull-right text-right hover_toggle">
                                  <div class="hover_hide">
                                      <h5 class="no-margin" style="margin-bottom: 7px">{{repayment?.REPAYMENT_DATE}}</h5>
                                      <p class="no-margin t_lh1 t_l4 t_cool" style="font-size: 14px">Status: {{repayment?.IS_PAID=='1'?'Paid':'Unpaid'}}</p> 
                                  </div>
                                  <!-- <a class="pull-right btn btn-white wd_btn hover_show" style="border-radius: 0">View</a> -->
  
                                </div>
                                
                            </article>
                        </div>
            </div>
          </section>
    
    </div>
<div *ngIf="sub_summary=='1'">
    <div class="row">
        
        <div class="col-md-12">
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 5px;">

                <div class="form-group">
                    <span class="pull-left text-primary">Loan Summary</span>
                    <span class="pull-right">
                        {{loan?.LOAN_ID}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="form-group">
                    <span class="pull-left text-primary">Received Amount</span>
                    <span class="pull-right">
                       {{loan?.REQUEST_PRINCIPAL | number:'.2-2'}}
                    </span>
                    <div class="clearfix"></div>
                  </div>
                  <div class="form-group">
                        <span class="pull-left text-primary">Duration</span>
                        <span class="pull-right">
                           {{loan?.REQUEST_TENOR}} {{loan?.LOAN_DURATION}}
                        </span>
                        <div class="clearfix"></div>
                      </div>
                      <div class="form-group">
                            <span class="pull-left text-primary">Disburse On</span>
                            <span class="pull-right">
                               {{loan?.DISBURSEMENT_DATE}}
                            </span>
                            <div class="clearfix"></div>
                          </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 5px;">

                    <div class="form-group">
                            <span class="pull-left text-primary ">Total Repayment</span>
                            <span class="pull-right">
                               {{loan?.TOTAL_REPAYMENT | number:'.2-2'}}
                            </span>
                            <div class="clearfix"></div>
                          </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 5px;">

                <div class="form-group">
                    <span class="pull-left text-primary">Loan Balance</span>
                    <span class="pull-right"> 
                            {{loan?.repayment.LOAN_BALANCE}}
                    </span>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 5px;">
                    
                                    <div class="form-group">
                                        <span class="pull-left text-primary"># Repayments</span>
                                        <span class="pull-right">
                                            {{loan?.repayment.count_paid}}
                                        </span>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="form-group">
                                        <span class="pull-left text-primary ">Total Repayment</span>
                                        <span class="pull-right">
                                           {{loan?.repayment.paid | number:'.2-2'}}
                                        </span>
                                        <div class="clearfix"></div>
                                      </div>
                                      <div class="form-group">
                                            <span class="pull-left text-primary ">Total Due</span>
                                            <span class="pull-right">
                                               {{loan?.repayment.debts | number:'.2-2'}}
                                            </span>
                                            <div class="clearfix"></div>
                                          </div>
                                          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;"> 
                                          <div class="form-group">
                                                <span class="pull-left text-primary ">Total Left</span>
                                                <span class="pull-right">
                                                   {{loan?.repayment.rem | number:'.2-2'}}
                                                </span>
                                                <div class="clearfix"></div>
                                              </div>
                                              </div>
                                <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 5px;">
                                        
                                                        <div class="form-group">
                                                            <span class="pull-left text-primary"># Days Overdue</span>
                                                            <span class="pull-right">
                                                                {{loan?.repayment.days_overdue}}
                                                            </span>
                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
        </div>
    </div>
    <!-- <section class="panel" style="border:1px solid #eee" *ngIf="!makingPayment">
        <div class="panel-body" style="padding:35px">
          <div class="row">
            <div class="col-md-5">
                <section class="panel" style="border:1px solid #eee">
                    <div class="panel-body text-center" style="padding:35px; padding-top:15px">
                      <h4 style="margin:0px">{{loan?.LEGAL_NAME}}</h4>
                      <img src="assets/attachments/{{loan?.CUSTOMER_TYPE | imagenotfound}}" style=" width: 75px;   height: 75px; border-radius: 50%; margin:10px 0px; ">
                      
                      <a class="btn btn-pink btn-block" [routerLink]="['/credit/schedule',loan.REQUEST_ID]">
                         View All Schedule
                      </a>
                    </div>
                    <footer class="panel-footer text-center">
                        <div class="row">
                            <div class="col-md-6">
                              <h4>{{loan?.REQUEST_PRINCIPAL|money}}</h4>
                              <p>Principal</p>
                            </div>
                            <div class="col-md-6">
                                <h4>{{loan?.REQUEST_TENOR}} {{loan?.LOAN_DURATION}}</h4>
                                <p>Tenor</p>
                            </div>
                          </div>
                    </footer>
                  </section>
            </div>
            <div class="col-md-7">
                <div >
                    <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Upcoming Repayments</span>
                          <span class="pull-right"> 
                          </span>
                          <div class="clearfix"></div>
                        </div>
                    </div>
                    <div *ngFor="let repayment of loan?.schedule" style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                       <div class="form-group">
                         <span class="pull-left text-primary">{{repayment.REPAYMENT_DATE}}</span>
                         <span class="pull-right"> 
                           {{repayment.HOW_MUCH_REMAINING}}
                         </span>
                         <div class="clearfix"></div>
                       </div>
                   </div>
                   
                </div>
                
            </div>
          </div>
        </div>
      </section> -->

</div>
<div *ngIf="sub_summary=='0'">
    <section id="main-content">
        <div class="container" style="min-height:850px;width:70%;">
            <div class="row" style="margin-top:50px;">
                <div class="col-md-12" style="padding:0;">
                    <section class="panel">
                        <header class="panel-heading">
                            Contractual Schedule
                            <span class="tools pull-right">
                                <!--<button class="btn btn-xs btn-white" id="btnGenerateStatement">Statement</button>-->
                                <a [routerLink]="['/credit/schedule',loan?.REQUEST_ID]">Schedule</a>
                                <a [routerLink]="['/credit/payments',loan?.REQUEST_ID]">Payment</a>
                                <!-- <a [routerLink]="['/credit/statement',loan?.REQUEST_ID]">Statement</a> -->
                                <a class="">Break Loan</a>
                                <a [routerLink]="['/loan',loan?.REQUEST_ID]">Contract</a>
                                <!-- <div class="btn-group">
                                    <button class="btn btn-white btn-xs" type="button">More</button>
                                    <button data-toggle="dropdown" class="btn btn-xs btn-white dropdown-toggle" type="button" aria-expanded="false">
                                        <span class="caret"></span>
                                    </button>
                                    <ul role="menu" class="dropdown-menu">

                                    </ul>
                                </div> -->
                            </span>
                        </header>
                        <div class="panel-body">
                            <div class="row invoice-list">

                                <div class="col-lg-4 col-sm-4">
                                    <h4>CLIENT INFO</h4>
                                    <p>Client : {{loan?.LEGAL_NAME}}</p>
                                </div>
                                <div class="col-lg-4 col-sm-4">
                                    <h4>REQUEST INFO</h4>
                                    <p>Amount : {{loan?.REQUEST_PRINCIPAL| number:'.2-2'}}</p>
                                    <p>Tenor : {{loan?.REQUEST_TENOR}} {{loan?.LOAN_DURATION}}</p>
                                    <p>Rate : {{loan?.REQUEST_RATE}} {{loan?.INTEREST_DURATION}}</p>
                                    <p>Value Date : {{loan?.DISBURSEMENT_DATE}}</p>
                                </div>
                                <div class="col-lg-4 col-sm-4">
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <table class="table table-striped">
                            <thead> 
                                <th>Date</th>
                                <th>Outstanding Principal</th>
                                <th>Principal</th>
                                <th>Interest</th>
                                <th>Recurring Fees</th> 
                                <th>Term Repayment</th>
                                <th>Paid</th>
                                <th>Remaining</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let repayment of loan?.repayment.schedule"> 
                                    <td>{{repayment?.REPAYMENT_DATE}}</td>
                                    <td>{{repayment?.OUTSTANDING_PRINCIPAL| number:'.2-2'}}</td>
                                    <td>{{repayment?.PRINCIPAL_REPAYMENT | number:'.2-2'}}</td>
                                    <td>{{repayment?.INTEREST_REPAYMENT| number:'.2-2'}}</td>
                                    <td>{{repayment?.FEES+repayment?.VAT_ON_FEES| number:'.2-2'}}</td> 
                                    <td>{{repayment?.TERM_REPAYMENT| number:'.2-2'}}</td>
                                    <td>{{repayment?.HOW_MUCH_PAID| number:'.2-2'}}</td>
                                    <td>{{repayment?.HOW_MUCH_REMAINING| number:'.2-2'}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </section>
                </div>
            </div>
        </div>
    </section>
</div>