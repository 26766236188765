import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../../_services/constants.service';
import { ToastrService } from 'ngx-toastr';
import { OperationsService, CustomerService, StorageService, DataService, AuthenticationService, LoansService } from '../../../_services/index';

@Component({
  selector: 'app-bvnmodal',
  templateUrl: './bvnmodal.component.html',
  styleUrls: ['./bvnmodal.component.css']
})
export class BvnmodalComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input('token') token: any;
  @Input('from') from = '0';
  @Input('customer_id') customer_id = '0';
  @Input() viewOnly = false;
  account_state = 'start';
  name: any;
  phone: any;
  date_of_birth: any;
  bvn_validation_date: any;
  bvn: any;
  bvn_details: any;
  loading = false;
  source = '1';
  loan: any;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  @Output() clearBVNEvent = new EventEmitter();
  @Output() retryBVNEvent = new EventEmitter();
  @Input('currentUser') currentUser: any;
  BVN = '';
  link: any;
  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private operationsService: OperationsService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private dataService: DataService,
    private authService: AuthenticationService,
    private loansService: LoansService,
    public toastr: ToastrService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    return this.getBVNDetails();
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  getBVNDetails() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/bvn`, {
      request_id: this.request_id,
      token: this.currentUser.token,
      customer_id: this.customer_id,
      from: this.from
    }).subscribe(response => {
      this.loading = false;
      if (response.status) {
        this.bvn_details = response.data;
        if (response.data.bvn == '') {
          this.account_state = 'send_request';
        }
      }
    }, error => {
      //console.log(error);
    });
  }
  retryBVNValidation(cust) {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode', request_id: this.loan.request_id, loan: this.loan });
    } else {
      this.loading = true;
      //this.retryBVNEvent.emit({customer:cust});
      this.customerService.retryBVNValidation(this.currentUser.token, cust).subscribe(data => {
        this.loading = false;
        this.getBVNDetails();
      });
    }

  }
  validateBVN(cust) {
    cust.bvn = this.BVN;
    this.loading = true;
    this.customerService.retryBVNValidation(this.currentUser.token, cust).subscribe(data => {
      this.loading = false;
      this.getBVNDetails();
    });
  }
  linkGenerated(data) {
    this.link = data.url;
  }

  clearBVNRecord(cust) {
    this.loading = true;
    //this.clearBVNEvent.emit({customer:cust});
    this.customerService.retryBVNValidation(this.currentUser.token, cust).subscribe(data => {
      this.loading = false;
      this.getBVNDetails();
    });
  }
  saveBVN(cust) {
    cust.bvn = this.BVN;
    this.loading = true;
    this.customerService.saveBVN(this.currentUser.token, cust).subscribe(data => {
      this.loading = false;
      this.getBVNDetails();
    });
  }
}
