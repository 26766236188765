<h3 class="title">Register Payment</h3>
<div class="flex recollection">
  <div class="flex-1 flex flex-column justify-center">
    <div class="icon-text medium tall flex-1" (click)="choosePaymentOption('confirm_payment')">
      <div class="icon color-blue">
        <span class="fas fa-check"></span>
      </div>
      <p class="text">Confirm Payment</p>
    </div>
  </div>
  <div class="flex-1 flex flex-column justify-center">
    <div class="icon-text medium flex-1" (click)="choosePaymentOption('direct_debit')">
      <div class="icon color-teal">
        <i class="fas fa-wallet"></i>
      </div>
      <p class="text"><span>Acccount Debit Settings</span></p>
    </div>
    <div class="icon-text medium flex-1" (click)="choosePaymentOption('card_debit')">
      <div class="icon color-green">
        <i class="fas fa-credit-card"></i>
      </div>
      <p class="text">Card Debit Settings</p>
    </div>
  </div>
</div>

<!-- <div style="padding:20px; background: #fff">
  <div style="height: 300px">
    <div class="panel flex height_1 text-center">
      <div class="flex width_1_2 height_1 col">
        <div
          class="height_1 width_1 flex vertical-center outline center"
          style="cursor: pointer"
          (click)="choosePaymentOption('confirm_payment')"
        >
          <div>
            <p class="t_l1 no-margin"><span class="fas fa-clock"></span></p>
            <p class="h5">
              <span>Confirm<br />Payment</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="flex col width_1_2 height_1"
        style="cursor: pointer"
        (click)="choosePaymentOption('direct_debit')"
      >
        <div class="height_1_2 flex vertical-center outline center">
          <div>
            <p class="t_l1 no-margin"><span class="fas fa-wallet"></span></p>
            <p class="h5"><span>Acccount Debit Settings</span></p>
          </div>
        </div>
        <div
          class="height_1_2 flex vertical-center outline center"
          style="cursor: pointer"
          (click)="choosePaymentOption('card_debit')"
        >
          <div>
            <p class="t_l1 no-margin">
              <span class="fas fa-credit-card"></span>
            </p>
            <p class="h5">Card Debit Settings</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->