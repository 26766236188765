<div class="cont">
  <div class="sect-wrap">
    <div class="align-start flex-center flex-column left-container">
      <div class="onb-title">
        <h3>One platform for all lending integrations you will ever need</h3>
      </div>
      <div class="onb-content">
        <p>All the integraitons you will ever need for auto-recollection, analytics, operations, disbursement and
          origination are already here.</p>
        <p>Mastercard, VISA, Paystack, Flutterwave, verve, REMITA, NIBSS, CRC, First Central, CR Services, Telcos, BVN,
          FRSC, NIN, SMS gateways, email, Facebook, Linkedin, Whatsapp, everything</p>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container text-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/integrations.png" alt="Illustration" style="margin-top: 30px">
        </div>
      </div>
    </div>
  </div>
</div>
