import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OperationsService, StorageService, LoansService, DataService } from '../../_services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-creditregistry',
  templateUrl: './creditregistry.component.html',
  styleUrls: ['./creditregistry.component.css']
})
export class CreditregistryComponent implements OnInit {
  endDate = '1561935599000';
  access: any;
  loading = false;
  @Input('request_id') request_id: any;
  @Input('from_where') from_where = '0';

  
  @Output() openMore = new EventEmitter();
  @Output() showResultForMicroCredit = new EventEmitter();
  is_individual = false;
  view = 'xds';
  no_crc: boolean;
  crc_view: boolean;
  cr_services_view: boolean;
  no_cr_services: boolean;
  xds_view: boolean;
  no_xds_view: boolean;
  currentUser: any;
  where: any;
  showRerunModal = false;
  result: any;
  data: any = {
    'status': true,
    'crc': {
      'has_data': true,
      'data': {
        'date_added': '10-12-1993',
        'link': 'https://www.google.com',
        'user': 'Tope Ojewale'
      }
    },
    'xds': {
      'has_data': false,
      'data': {
        'date_added': '',
        'link': '',
        'user': 'Tope Ojewale'
      }
    },
    'crservices': {
      'has_data': false,
      'data': {
        'date_added': '',
        'link': '',
        'user': 'Tope Ojewale'
      }
    }
  };
  crc_data: any;
  xds_data: any;
  cr_services_data: any;
  constructor(
    private storageService: StorageService,
    private operationService: OperationsService,
    private loansService: LoansService,
    public toastr: ToastrService,
    private dataService: DataService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getData();
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  onChange(event) {
    this.where = event.target.value;
    this.view = event.target.value;
  }
  getData() {
    this.operationService.getLoanCreditCheck(this.currentUser.token, this.request_id, this.where).subscribe(res => {
      this.access = res.access;
      if (res.status == true) {
        this.result = res.data;
        if (res.data.crc.has_data) {
          this.crc_view = true;
          this.crc_data = res.data.crc.data;
        } else {
          this.no_crc = true;
        }
        if (res.data.xds.has_data) {
          this.xds_view = true;
          this.xds_data = res.data.xds.data;
        } else {
          this.no_xds_view = true;
        }
        if (res.data.crservices.has_data) {
          this.cr_services_view = true;
          this.cr_services_data = res.data.crservices.data;
        } else {
          this.no_cr_services = true;
        }
      }
    });
  }
  requestForCreditBureau() {
    this.loading = true;
    this.loansService.runCreditCheck(this.currentUser.token, this.request_id, this.view).subscribe(res => {
      this.loading = false;
      if (res.status == true) {
        this.showSuccess("Search complete");
      } else {
        this.showError(res.result.data);
      }
      this.getData();
    });
  }
  openSettingsModel(bureau) {
    console.log('CreditRegistry', bureau);
    this.dataService.updatingSettingsExternally.emit({ bureau: bureau, section: 'creditvureaus' });
  }
  openMoreResult(event){ 
    this.showResultForMicroCredit.emit(event)
  }
}
