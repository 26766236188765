<main *ngIf="!loading" id="app">
  <div class="wrapper" *ngIf="data">
    <div class="strip">
      Hover over sections to modify
    </div>
    <div class="section"> 
    </div>

    <div class="section float-right">
      <!-- <button class="edit-button" (click)="editSection('logo')">
        <i class="fa fa-edit"></i> Edit Logo
      </button> -->
      <section class="logo text-right" style="flex-basis: 40%;">
        <img [src]="data?.settings.offer_letter_logo">

        <section class="address">
          <ul>
            <li class="address">
              <p>{{ data?.lender?.website_address }}</p>
            </li>
            <li class="phone">
              <p>{{ data?.lender?.website_phone }}</p>
            </li> 
          </ul>
        </section>
      </section>
    </div>

    <section class="address">
      <button class="edit-button" (click)="editSection('address')">
        <i class="fa fa-edit"></i> Edit Address
      </button>
      <ul style="width:50% !important">
        <li class="date" *ngIf="options?.date">
          <p>{{ data?.loan?.DATE_ADDED | date }}</p>
        </li>
        <li class="name" *ngIf="options?.na">
          <p>{{ data?.customer?.legal_name }}</p>
        </li>
        <li class="company" *ngIf="options.company">
          <strong></strong>
        </li>
        <li class="address" *ngIf="options.address">
          <p>{{ data?.customer?.home_address }},
            <br>
            {{ data?.customer?.lga }}, {{ data?.customer?.state_name }}
          </p>
        </li> 
      </ul>
    </section>

    <section class="salutation">
      <button class="edit-button" (click)="editSection('salutation')">
        <i class="fa fa-edit"></i> Edit salutation
      </button>
      <div class="content" [innerHTML]="data?.settings.offer_letter_salutation"></div>
    </section>

    <section class="body">
      <button class="edit-button" (click)="editSection('subject')">
        <i class="fa fa-edit"></i> Edit Subject
      </button>
      <h3 class="heading" [innerHTML]="data?.settings.offer_letter_subject"></h3>
    </section>
 

    <section class="body">
      <button class="edit-button" (click)="editSection('body')">
        <i class="fa fa-edit"></i> Edit Introduction
      </button>
      <div class="content" [innerHTML]="introductionHTML"></div>
    </section>
  
 
    <section class="remark">
      <button class="edit-button" (click)="editSection('signature')">
        <i class="fa fa-edit"></i> Edit Remark & Signature
      </button>
      <p>Yours {{ data?.settings?.remark == '1' ? 'Sincerely' : 'Faithfully' }},
        <br>
        For. {{ data?.lender?.LEGAL_NAME }}
      </p>
      <br>
      <div class="signatures-wrap">
        <div class="signatures" *ngIf="signatures.signature1Name">
          <ul>
            <li class="signature">
              <img [src]="signatureimage1">
            </li>
            <li class="name">
              <div>{{ signatures.signature1Name }}</div>
            </li>
            <li class="position">
              <strong>{{ signatures.signature1Position }}</strong>
            </li>
          </ul>
        </div>

     

      </div>
    </section>
 
    <div class="section">
     
    </div>
  </div>
</main> 