<section class="panel mb-1" *ngIf="!external">
    <div class="panel-body padding-regular flex align-center">
        <button class="btn btn-outline-secondary btn-xs mr-4" (click)="goBack()">
            <i class="fa fa-arrow-left"></i>
        </button>
        <h4 class="section-title">Schedule</h4>
    </div>
</section>
<app-calendarview (changeTheScheduleType)="onChange($event)" [calendarOptions___]="calendarOptions___"
    [calendarOptions__]="calendarOptions__" [calendarOptions]="calendarOptions" [calendarOptions_]="calendarOptions_"
    [schedule_type]="schedule_type" [record_type]="record_type"></app-calendarview>