<app-navbar></app-navbar>
<app-header></app-header>
<div class="cont">
  <div class="container">
    <div class="col-md-4 col-md-offset-1">
      <div class="right-container" style="text-align: justify">

        <h2 class="onb-title">It's time to go live </h2>
        <div class="onb-content">
          <div class="live__content">
            This is just the basic settings to get you started, we have a robust settings page
            where you can add
            advance configurations. Let's get` you started
          </div>
        </div>
        <div>
          <button (click)="goLive()" class="btn btn-default padded orange-btn wide-btn" style="width: 100%">Go Live
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="left-container text-center">
        <div class="circle__flex-container">
          <div class="circle"><a href="javascript:" data-toggle="modal" data-target="#myModal1"><span
            class="circle__number">1</span><span>Confirm Contact</span></a></div>
          <div class="circle"><a href="javascript:" data-toggle="modal" data-target="#myModal2"><span
            class="circle__number">2</span><span>Invite Members</span></a>
          </div>
          <div class="circle"><a href="javascript:"><span
            class="circle__number">3</span><span>Fund Your Wallet</span></a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->
<div class="modal fade" id="myModal1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <form class="add__member-form">
          <h3 style="padding-left: 108px;" *ngIf="view == ''">Enter Business Details</h3>
          <div class="form__flex" *ngIf="view == ''">
            <div class="form-group">
              <input name="alias" placeholder="Pick an alias" [(ngModel)]="alias" class="form-control form__input"/>
            </div>
            <div class="form-group">
              <input type="text" name="primary_phone_number" class="form-control form__input"
                     [value]="primary_phone_number"
                     [(ngModel)]="primary_phone_number">
            </div>
            <div class="form-group">
              <input type="email" name="primary_email" class="form-control form__input" [value]="primary_email"
                     [(ngModel)]="primary_email">
            </div>
            <div class="form-group">
              <input type="text" name="primary_address" class="form-control form__input"
                     [(ngModel)]="primary_address">
            </div>
            <button class="btn btn-default padded orange-btn wide-btn" style="width: 61%" (click)="confirm()">Confirm
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- modal 2 -->

<div class="modal fade" id="myModal2">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <form class="add__member-form">
          <div style="padding-left: 108px;"><span><img src="assets/images/user.png"
                                                       class="member__avatar"
                                                       alt=""></span><span
            style="font-size: 18px">Add a Team member</span>
          </div>
          <hr>
          <div class="form__flex">
            <div *ngIf="errorMessage" class="alert-danger">{{errorMessage}}</div>
            <div class="form-group">
              <input type="name" name="name" class="form-control form__input" placeholder="Name" [(ngModel)]="name">
            </div>
            <div class="form-group">
              <input type="email" name="email" class="form-control form__input" placeholder="Email Address"
                     [(ngModel)]="email">
            </div>
            <div class="form-group">
              <select name="role" id="" class="form-control form__input" [(ngModel)]="role">
                <option disabled>Role</option>
                <option value="1">Super Admin</option>
                <option value="2">Approver</option>
                <option value="3">Accounts</option>
                <option value="4">Customer Account Manager</option>
                <option value="5">Admin</option>
                <option value="1026">Credit Officers</option>
                <option value="1027">Sales</option>
              </select>
            </div>
            <div class="form-group">
              <label class="checkbox-inline">
                <input type="checkbox" name="send_mail" [(ngModel)]="send_mail"> Send welcome email to the
                staff
              </label>
            </div>
            <button class="btn btn-default padded orange-btn wide-btn" [disabled]="loading" style="width: 61%"
                    (click)="sendInvite()">Send
              Invite
            </button>
            <div><a href="javacript:">Skip, I am alone</a></div>
          </div>
          <div class="form__flex" *ngIf="view == 'add_more'">
            <h3>Add a team member</h3>
            <div class="circle__flex">
              <div class="circle__member">
                <span class="circle_number">2</span>
                <span class="circle_text">Added</span>
              </div>
              <div class="circle__member">
                <a href="">
                  <img src="assets/images/plus.png" alt="" style="height: 50px;">
                </a>
              </div>
            </div>
            <button class="btn btn-default padded orange-btn wide-btn" style="width: 61%" (click)="submit()">Submit
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
