<div>
  <div class="modl_title" style="margin-bottom: 30px">
    <h2>
      <span class="text-success">Request for Guarantors</span>
      <br>
      <small class="modl_sub">Send an email to the borrower request for guarantors</small>
    </h2>
  </div>

  <div>
    <div style="width: 350px; margin: 0 auto 25px">
      <ul class="list list_border no_pad labels text-left">


        <li>
          <div class="row">
            <div class="col-md-6 t_cool">How many guarantors</div>
            <div class="col-md-6">
              <input name="HOW_MANY_GUARANTORS" type="text" [(ngModel)]="guarantors_request.HOW_MANY_GUARANTORS">
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div style="width: 200px; margin: 0 auto">
      <div style="margin-bottom: 30px">
        <button class="text-center txt t_l4 btn-block" [ngClass]="{'btn-danger': is_done=='0', 'btn-success': is_done=='1'}" style="padding-top: 12px; padding-bottom: 12px"
                (click)="sendForGuarantors()" [disabled]="loading||is_done=='1'||(guarantors_request.HOW_MANY_GUARANTORS=='0')||(guarantors_request.HOW_MANY_GUARANTORS=='')">
          <strong *ngIf="is_done=='0'">Confirm</strong>
          <i class="fa fa-spinner fa-spin" *ngIf="loading && is_done=='0'"></i>
          <i class="fa fa-check" *ngIf="!loading && is_done=='1'"></i>
        </button>

      </div>
    </div>
  </div>
</div>
