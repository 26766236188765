import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  loading = false;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  // setLenderModel() {
  //   this.formLender = new LenderModel('','','');
  // }
  displayNext() {
    this.router.navigate(['/integrations']);
  }
}
