<div class="padding-regular-x mt-2">
    <div style="margin-top: 10px; font-size: 12px">
        <div class="compose__email_div">
          <h4 class="compose__email_h2">Compose SMS</h4>
          <form #f="ngForm" (ngSubmit)="onSubmitSMS(f)">
            <textarea name="message" [(ngModel)]="message" [maxlength]=150 [required]="true" class="form-control"
              rows="6"></textarea>
            <p>{{message?.length}} of 150</p>
  
            <button  [disabled]="loading" class="btn btn-white btn-filter mtop18">Send SMS
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
          </form>
        </div>
      </div>
</div>
