import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-remitainfodetails',
  templateUrl: './remitainfodetails.component.html',
  styleUrls: ['./remitainfodetails.component.css']
})
export class RemitainfodetailsComponent implements OnInit {
  @Input('customer') customer:any;
  constructor() { }

  ngOnInit() {
  }

}
