<div *ngIf="from_where == 'product'">
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingChecklistSettings">
      <h4 class="panel-title">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
          href="#collapseChecklistSettings" aria-expanded="false" aria-controls="collapseChecklistSettings">
          Product Checklist
        </a>
      </h4>
    </div>
    <div id="collapseChecklistSettings" class="panel-collapse collapse" role="tabpanel"
      aria-labelledby="headingChecklistSettings">

      <div class="panel-body">
        <div class="mt-3">
          <div>
            <form #interestBandForm="ngForm" novalidate>

              <div class="row">
                <div class="col-md-9">
                  <div class="form-group">
                    <label class="control-label">Display</label>
                    <input type="text" required class="form-control" name="display" [(ngModel)]="tagModel.display">
                    <input type="hidden" class="form-control" name="value" [(ngModel)]="tagModel.checked">
                    <input type="hidden" class="form-control" name="checked_by" [(ngModel)]="tagModel.checked_by">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                    <button type="submit" (click)="addNewCheckListItem(interestBandForm.value, interestBandForm.valid)"
                      class="btn btn-white btn-block">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div>
            <div class="dw">
              <div class="label-value-list mb-2">
                <div class="item" *ngFor="let ct of product_checklist, let i = index">
                  <span class="label">{{ ct.display }}</span>
                  <span class="value"><button class="chip-close" (click)="removeTag(i)"><i
                        class="fa fa-times"></i></button></span>
                </div>
              </div>
            </div>
            <p></p>
            <app-button (click)="saveProductCheckList()" *ngIf="product_checklist.length > 0" [disabled]="loading"
              label="Submit" [loading]="loading" class="small">
            </app-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="from_where != 'product'">
  <div class="panel">
    <div class="panel-body">
      <div class="mt-3">
        <div class="h-group center mt-4 empty-state mt-5" *ngIf="!product_checklist.length">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title no-float">No data to show</h2>
        </div>

        <div>
          <form #interestBandForm="ngForm" novalidate>
            <div class="row" *ngFor="let checklist of product_checklist; let i=index">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{checklist.display}}</label>
                  <input type="hidden" class="form-control" name="display{{i}}" [(ngModel)]="checklist.display">

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group pull-right">
                  <!-- <label class="control-label">Status</label> -->
                  <input type="checkbox" name="checked{{i}}" [(ngModel)]="checklist.checked">
                </div>
              </div>
            </div>
            <p></p>
            <app-button (click)="saveProductCheckList()" *ngIf="product_checklist.length > 0" [disabled]="loading"
              label="Submit" [loading]="loading" class="small">
            </app-button>
          </form>
        </div>
        <!-- <div>
                    <div class="dw">
                      <div class="label-value-list mb-2">
                        <div class="item" *ngFor="let ct of product_checklist, let i = index">
                          <span class="label">{{ ct.display }}</span> 
                          <span class="value"><button class="chip-close" (click)="removeTag(i)"><i
                                class="fa fa-times"></i></button></span>
                        </div>
                      </div>
                    </div>
                    
                  </div> -->


      </div>
    </div>
  </div>
</div>