import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homenavigation',
  templateUrl: './homenavigation.component.html',
  styleUrls: ['./homenavigation.component.css']
})
export class HomenavigationComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }
  openProfile(){
    this.router.navigate(['../home/summary']);
  }
  openRequests(){
    this.router.navigate(['../home/request']);
  }
  openPortfolio(){
    this.router.navigate(['../home/portfolio']);
  }
  
  openNotifications(){
    this.router.navigate(['../home/notifications']);
  } 
  openTasks(){
    this.router.navigate(['../home/openedtask']);
  }
  openAPp(){
    this.router.navigate(['../home/approval']);
  }
}
