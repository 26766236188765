<app-navbar></app-navbar>
<app-header></app-header>
<div class="cont">
  <div>
    <div class="container">
      <div class="col-md-3">
        <div class="left-container">
          <app-sidebar></app-sidebar>
        </div>
      </div>
      <div class="col-md-4">
        <div class="middle-container">
          <div class="">
            <img src="assets/images/web.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="right-container">
          <h2 class="onb-title">Web Request</h2>
          <hr/>
          <div class="onb-content">
            <p>
              First we encourage you to simulate the experience so as to adjust
              quickly to the flow of our software
            </p>
            <hr/>
            <p>
              We have what many lenders have called an incredible list of
              features. We welcome you to check how it works
            </p>
            <hr/>
          </div>
          <button type="button" (click)="openModal()"
                  class="btn btn-block btn-default padded orange-btn"
          >
            Simulate
          </button>
          <div style="text-align: center;
    padding: 10px;
    font-size: 18px;"><a href="javascript:">Configure the settings</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated ln_mdl" style="overflow:hidden" *ngIf="showModal">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class=" overpanel-content container  animated bounceIn" role="document"
       style="background:rgba(255, 255, 255, 0.95); height:100%; text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden">

    <app-website></app-website>

  </div>
</div>
