import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insurance'
})
export class InsurancePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Comprehensive";
    }
    if (value == "2") {
      return "Third Party";
    } 
  }

}
