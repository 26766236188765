
<div class="row">
  <div class="col-xs-12 col-md-6 pd_15">
  <div class="footerContentsection">
      <div [innerHTML]="mailBodyContent"></div>
  </div>
  </div>
  <div *ngIf="hideFooter" class="col-xs-6 col-md-5">
    <form [formGroup]="updateBodyContentForm" 
    (ngSubmit)="onUpdateBodyContent(this.mailBodyContent)">

     <app-ngx-editor [config]="editorConfig"
      [(ngModel)]='this.mailBodyContent'
      formControlName="bodyContent" 
      [style]="{height: '250px'}"
      [required]="true" 
        >
    </app-ngx-editor>
    <p>
        <button 
        style="margin-top: 20px;" 
        [disabled]="!updateBodyContentForm.valid"
        type="submit" 
        class="btn btn-primary">Update
        <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
        </button>
    </p>
</form>
  </div>
</div>
