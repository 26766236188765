
<div class="panel mb-1">
    <div class="panel-body flex align-start"> 
  
      <div class="section-poster">
        <div class="image flex-center">
          <img src="assets/images/insured.svg" alt="">
        </div>
      </div>
  
      <div class="flex-1 ml-3" *ngIf="is_top_up=='8'">
        <div class="label-value-list mb-2">
          <h4 class="section-title mb-5">Top Up Request Details</h4>
          <div class="item">
            <span class="label">Previous Balance</span>
            <span class="value" *ngIf="!loading">{{ loan?.ref_current_balance | number:'.2-2'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
              <span class="label">Top up Amount</span>
              <span class="value" *ngIf="!loading">{{ loan?.top_up_amount | number:'.2-2'}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
                <span class="label">Required Principal</span>
                <span class="value" *ngIf="!loading">{{ loan?.request_principal | number:'.2-2'}}</span>
                <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
              </div>
          <div class="item">
            <span class="label">Duration</span>
            <span class="value" *ngIf="!loading">{{ loan?.request_tenor}} {{ loan?.loan_duration}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
            <span class="label">Rate</span>
            <span class="value" *ngIf="!loading">{{ loan?.request_rate}} {{ loan?.interest_duration}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="separator"></div>
          <div class="item">
            <span class="label">Fees & Charges</span>
            <span class="value" *ngIf="!loading">{{ loan?.total_fees_and_charges | number:'.2-2' }}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
            <span class="label">Total Interest</span>
            <span class="value" *ngIf="!loading">{{ loan?.total_interest | number:'.2-2'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="separator"></div>
          <div class="item">
            <span class="label">Total Repayment</span>
            <span class="value" *ngIf="!loading">{{ loan?.total_repayment | number:'.2-2'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="separator"></div>
        
  
        </div>
        <div class="label-value-list collapse" id="more">
          <div class="separator"></div>
          <div class="item"><span class="label">Repayment Frequency</span><span
              class="value" *ngIf="!loading">{{ loan?.installment_cyle}}</span></div>
              <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          <div class="item">
            <span class="label">Repayment Type</span>
            <span class="value" *ngIf="!loading">{{ loan?.repayment_type}}</span>
            <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
            <span class="label">Monthly Repayment</span>
            <span class="value" *ngIf="!loading">{{ loan?.monthly_repayment|number:'.2-2'}}</span>
            <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
            <span class="label">First Repayment Date</span>
            <span class="value" *ngIf="!loading">{{ loan?.repayment_started_when}}</span>
            <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div>
          <div class="item">
            <span class="label">Net Disbursment</span>
            <span class="value" *ngIf="!loading">{{ loan?.net_disbursement|number:'.2-2'}}</span>
            <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
          </div> 
        </div>
        <div class="text-right mt-3">
          <button class="btn btn-outline-secondary btn-xs" type="button" data-toggle="collapse" data-target="#more"
            aria-expanded="false" aria-controls="more">More</button>
        </div>
        <div class="row mt-2">
            <div class="col-md-6">
              <app-button buttonclass="medium block mb-1" (click)="openLoanActions()"  label="Take Actions on Topup"></app-button>
            </div>
            <div class="col-md-6">
              <app-button buttonclass="medium block outline" (click)="openContractModal()" label="{{loan?.schedule_has_been_created ? 'Modify Contract' : 'Create Contract'}}"></app-button>
            </div>
          </div>
      </div>
      <div class="flex-1 ml-3" *ngIf="is_top_up=='7'">
          <div class="label-value-list mb-2">
            <h4 class="section-title mb-5">Rollover Request Details</h4>
            <div class="item">
              <span class="label">Previous Balance</span>
              <span class="value" *ngIf="!loading">{{ loan?.ref_current_balance | number:'.2-2'}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div> 
              <div class="item">
                  <span class="label">Required Principal</span>
                  <span class="value" *ngIf="!loading">{{ loan?.request_principal | number:'.2-2'}}</span>
                  <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
                </div>
            <div class="item">
              <span class="label">Duration</span>
              <span class="value" *ngIf="!loading">{{ loan?.request_tenor}} {{ loan?.loan_duration}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
              <span class="label">Rate</span>
              <span class="value" *ngIf="!loading">{{ loan?.request_rate}} {{ loan?.interest_duration}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="separator"></div>
            <div class="item">
              <span class="label">Fees & Charges</span>
              <span class="value" *ngIf="!loading">{{ loan?.total_fees_and_charges | number:'.2-2' }}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
              <span class="label">Total Interest</span>
              <span class="value" *ngIf="!loading">{{ loan?.total_interest | number:'.2-2'}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="separator"></div>
            <div class="item">
              <span class="label">Total Repayment</span>
              <span class="value" *ngIf="!loading">{{ loan?.total_repayment | number:'.2-2'}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="separator"></div>
          
    
          </div>
          <div class="label-value-list collapse" id="more">
            <div class="separator"></div>
            <div class="item"><span class="label">Repayment Frequency</span><span
                class="value" *ngIf="!loading">{{ loan?.installment_cyle}}</span></div>
                <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            <div class="item">
              <span class="label">Repayment Type</span>
              <span class="value" *ngIf="!loading">{{ loan?.repayment_type}}</span>
              <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
              <span class="label">Monthly Repayment</span>
              <span class="value" *ngIf="!loading">{{ loan?.monthly_repayment|number:'.2-2'}}</span>
              <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
              <span class="label">First Repayment Date</span>
              <span class="value" *ngIf="!loading">{{ loan?.repayment_started_when}}</span>
              <span class="value  skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div>
            <div class="item">
              <span class="label">Net Disbursment</span>
              <span class="value" *ngIf="!loading">{{ loan?.net_disbursement|number:'.2-2'}}</span>
              <span class="value skeleton" *ngIf="loading">{{ loan?.request_principal | number:'.2-2'}}</span>
            </div> 
          </div>
          <div class="text-right mt-3">
            <button class="btn btn-outline-secondary btn-xs" type="button" data-toggle="collapse" data-target="#more"
              aria-expanded="false" aria-controls="more">More</button>
          </div>
          <div class="row mt-2">
              <div class="col-md-6">
                <app-button buttonclass="medium block mb-1" (click)="openLoanActions()"  label="Take Actions on Rollover"></app-button>
              </div>
              <div class="col-md-6">
                <app-button *ngIf="!loan?.schedule_has_been_created" buttonclass="medium block outline" (click)="openContractModal()" label="Create Contract"></app-button>
                <app-button *ngIf="loan?.schedule_has_been_created" buttonclass="medium block outline" (click)="openContractModal()" label="Modify Contract"></app-button>

              </div>
            </div>
        </div>
    </div>
  </div>