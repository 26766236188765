import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-createanewcustomer',
  templateUrl: './createanewcustomer.component.html',
  styleUrls: ['./createanewcustomer.component.css']
})
export class CreateanewcustomerComponent implements OnInit {
  view='application';
  @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  closeView(){
    this.close.emit();
  }
  setView(i){
    
  }
}
