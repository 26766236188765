import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ApprovalsService, DataService, UserService, OperationsService } from '../../../_services/index';
import { AuthenticationService, StorageService, AnalyticsService } from '../../../_services/index';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router'; 
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-pending-approvals',
  templateUrl: './pending-approvals.component.html',
  styleUrls: ['./pending-approvals.component.css','./custom_style.css','./boot-outline-btn.css']
})
export class PendingApprovalsComponent implements OnInit {
  url: string = 'https://creditclanapis.creditclan.com/api/v2/dashboard';
  pending_approvals: any;
  numbers_left: number;
  
  repayment:any;
  loan:any;
  loading = false;
  currentUser:any;
  platform_summary: any;
  loading_approvals = false;
  approvals: any;
  loading_portfolio_snapsot = false;
  snapshot:any; 
  approval_history:any;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  constructor(private http: HttpClient,public analytics: AnalyticsService, public approvalService: ApprovalsService, public DataService: DataService,
    public router: Router, public fb: FormBuilder, public operationsService: OperationsService, public authService: AuthenticationService,
    public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit () {
    this.getPendingApprovals()
    this.runPlatformSummary();
  }

  getPendingApprovals () {
    this.loading_approvals = true;
    return this.http.post<any>(`${this.url}/pendingApprovals`, {token: this.currentUser.token}).subscribe(res => {
      this.loading_approvals = false;
      this.pending_approvals = res;
      this.numbers_left = res.remaining; 
    }, err => {
      //console.log(err);
    })
  }
  runPlatformSummary() {
    this.loading = true;
    this.analytics.runPlatformSummary(this.currentUser.token)
      .subscribe(data => {
        this.loading = false;
        this.platform_summary = data;

      });
  }
  opentimeline(request_id) {
    this.router.navigate(['./applications/timeline/' + request_id]);
  }
  openAnalytics(request_id) {
    this.router.navigate(['./applications/analytics/' + request_id]);
  }
  openCreditCheck(request_id) {
    this.router.navigate(['./applications/creditcheck/' + request_id]);
  }
  inviteNewCustomer() {
    if (this.test_mode == '0') {
      this.DataService.inviteNewCustomer.emit({});
    } else {
      this.DataService.onOpenLoanChildModal.emit({'location': 'test_mode'})
    }
  }
}
