<div class="cont">
  <div class="sect-wrap">
    <div class="left-container">
      <div class="onb-title">
        <h3>Algorithm powered Credit Eligibility Scoring</h3>
      </div>
      <div class="onb-content">
        <p>We review every data from:</p>
        <div class="flex">
          <ul class="bullet-list flex-1">
            <li>Bank statement</li>
            <li>Credit bureau</li>
            <li>BVN</li>
            <li>Repayment</li>
            <li>Social</li>
            <li>Bio</li>
          </ul>
          <ul class="bullet-list flex-1">
            <li>Address</li>
            <li>Income</li>
            <li>Expenses</li>
            <li>Guarantor</li>
            <li>Work</li>
            <li>NOK & more..</li>
          </ul>
        </div>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container text-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/eligible.svg" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
