<div class="overpanel-scroll">
    <div class="calendar___popout">
      <h3 class="title">Download</h3>
      <div class="calendar__popout-table padding-regular">


  
        <table class="table medium">
          <thead>
            <tr>
              <th colspan="2">Customer</th>
              <th>Account Number</th>
              <th>Bank Code</th>
              <th>Bank</th>
              <th>Amount</th>
              <th>Narration</th> 
            </tr>
          </thead>
          <tbody> 
            <ng-container *ngFor="let disburse of disbursements">
              <tr>
                <td>
                  <div class="image-avatar smaller">
                    <img
                    src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{disburse?.FILE_NAME | imagenotfound}}">
                  </div>
                </td>
                <td>{{disburse?.LEGAL_NAME}}</td>
                <td>{{disburse?.ACCOUNT_NUMBER}}</td>
                <td>{{disburse?.BANK_CODE}}</td>
                <td>{{disburse?.BANK_NAME}}</td>
                <td>{{disburse?.REQUEST_PRINCIPAL}}</td>
                <td>{{disburse?.NARRATION}}</td> 
              </tr> 
            </ng-container>
          </tbody>
        </table>
      </div>
    </div> 
  </div>
  