import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from '../email.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-footer-content',
  templateUrl: './email-footer-content.component.html',
  styleUrls: ['./email-footer-content.component.css']
})
export class EmailFooterContentComponent implements OnInit, OnDestroy {
  @Input() hideFooter: boolean;

  private AddFooter: Subscription;
  private getContentData: Subscription;

  loading: boolean;
  footerContent: string;
  vendor_id: number;
  token: any;
  content: string;
  mailBodyContent: string;
  hideheader = true;

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '5rem',
    minHeight: '7rem',
    placeholder: 'Enter your text here',
    translate: 'no',
    width: "100%",
    minWidth: "100%"
  };


  updateBodyContentForm = new FormGroup({
    bodyContent: new  FormControl('', [Validators.required]),
  });

  constructor(private fb: FormBuilder,
    public toastr: ToastrService,
    private services: EmailService) { 
      const vendorIdData = localStorage.getItem('platform');
      const userdata = JSON.parse(vendorIdData);
      this.vendor_id = userdata.PEOPLE_ID;
    }

  ngOnInit() {
    this.token = localStorage.getItem('token');

    const resource = {
      'token': this.token,
      'vendor_id': this.vendor_id
    };

    this.getContentData = this.services.getFooter(resource)
    .subscribe(data => {
      this.mailBodyContent = data.email_footer_content;
    });
  }

  onUpdateBodyContent(content){
    this.loading = true;

    const uploadData = {
      'vendor_id': this.vendor_id,
      'content': content,
      'token': this.token
    };

    this.AddFooter = this.services.addFooter(uploadData)
    .subscribe(payload => {
      this.loading = false;
      this.toastr.success('Success', 'Success!');

    });
  }

  ngOnDestroy() {
    this.AddFooter.unsubscribe();
    this.getContentData.unsubscribe();
  }

}
