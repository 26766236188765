<section class="panel" *ngIf="makingPayment">
    <div class="panel-body" style="padding:0px">
      <div style="margin:20px auto; width:75%" *ngIf="choosePayment && !chosenPayment">
        <div class="row">
          <div class="col-md-6">
            <section (click)="paywithwallet()" class="panel text-center" style="border:1px solid #70c041; border-radius:15px; cursor:pointer">
              <div class="panel-body">
                <h4>Wallet</h4>
                <p style="margin:0px 0px 10px 0px">Debit Wallet</p>
                <img src="assets/img/wallet.png" style="width: 115px;
                border: 3px solid #70c041;
                margin: 15px 0px;
                border-radius: 44px;
                padding: 15px; margin-bottom:90px"/>
              </div>
            </section>
          </div>
          <div class="col-md-6">
              <section (click)="paywithbank()" class="panel text-center" style="border:1px solid #53a8f9; border-radius:15px;cursor:pointer">
                  <div class="panel-body">
                    <h4>Bank</h4>
                    <p style="margin:0px 0px 10px 0px">Debit Account</p>
                    <img src="assets/img/bank.png" style="width: 115px;
                    border: 3px solid #53a8f9;
                    margin: 15px 0px;
                    border-radius: 44px;
                    padding: 15px; margin-bottom:90px"/>
                  </div>
                </section>
          </div>
        </div>
      </div>
      <div style="margin:20px auto; width:75%" *ngIf="choosePayment && chosenPayment">
          <div *ngIf="loading" class="text-center">
            <a><i class="fa fa-spinner fa-spin"></i></a>
          </div>
          <div class="row" *ngIf="payment_status?.status=='1'&&!loading">
            <div class="col-md-12">
              <section class="panel text-center">
                <div class="panel-body">
                    <img src="assets/img/check.png" style="width: 170px;margin: 15px 0px;padding: 15px;"/>
                  <h4>{{payment_status?.AMOUNT}}</h4>
                  <p style="margin:0px 0px 10px 0px">Payment Received</p>
                  <a (click)="backtoloansummary()" class="btn btn-white btn-block">Back</a>
                </div>
              </section>
            </div>
            </div>
            <div class="row" *ngIf="payment_status?.status=='0'&&!loading">
            <div class="col-md-12">
                <section class="panel text-center">
                    <div class="panel-body">
                        <img src="assets/img/cancel.png" style="width: 170px;margin: 15px 0px;padding: 15px;"/>
                       
                      <p style="margin:0px 0px 10px 0px">Payment Not Received</p>
                      <a (click)="backtoloansummary()" class="btn btn-white btn-block">Back</a>
                    </div>
                  </section>
            </div>
          </div>
        </div>
      <div style="margin:20px auto; width:75%" *ngIf="!choosePayment">
          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
              <div class="form-group">
                <span class="pull-left text-primary">Pay Loan Balance</span>
                <span class="pull-right"> 
                  <div class="checkbox">
                    <label>
                      {{state?.LOAN_BALANCE|number:'.2-2'}} <input [value]="pay_opt[0].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
                    </label>
                  </div>
                </span>
                <div class="clearfix"></div>
              </div>
          </div>
          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
              <div class="form-group">
                <span class="pull-left text-primary">Pay Total Due</span>
                <span class="pull-right">
                    <div class="checkbox">
                        <label>
                          {{state?.TOTAL_INDEBTED_BALANCE}} <input [value]="pay_opt[1].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
                        </label>
                      </div>
                </span>
                <div class="clearfix"></div>
              </div>
          </div>
          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
              <div class="form-group">
                <span class="pull-left text-primary">Pay Any Amount</span>
                <span class="pull-right"> 
                    <div class="checkbox">
                        <label>
                          <input [value]="pay_opt[2].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
                        </label>
                      </div>
                </span>
                <div class="clearfix"></div>
              </div>
              <div class="form-group row" *ngIf="payment.PAYMENT_OPTION=='3'">
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="AMOUNT_TO_PAY" [(ngModel)]="payment.AMOUNT_TO_PAY">
                  </div>
                </div>
              </div>
          </div>
          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
              <div class="form-group row">
                  <div class="col-md-6">Payment Date</div>
                  <div class="col-md-6">
                    <input type="text" name="PAYMENT_DATE" class="form-control" [(ngModel)]="payment.PAYMENT_DATE" ngui-datetime-picker date-only="true"
                    />
                  </div>
              </div>
          </div>
          <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;" class="hide">
              <div class="form-group">
                <span class="pull-left text-primary">Default Bank</span>
                <span class="pull-right">
                   {{default_bank?.GL_NAME}}
                </span>
                <div class="clearfix"></div>
              </div>
          </div>
          <div class="formgroup row">
            <div class="col-md-6"><a (click)="backtoloansummary()" class="btn btn-white btn-block">Back</a></div>
            <div class="col-md-6"><button (click)="paywithbank()" [disabled]="payment.PAYMENT_DATE==''||(payment.PAYMENT_DATE==''&&(payment.AMOUNT_TO_PAY==='0'||payment.AMOUNT_TO_PAY==='')&&payment.PAYMENT_OPTION=='3')" class="btn btn-white btn-block btn-pink">Pay</button></div>
          </div>
      </div>
    </div>
</section>
<section class="panel" style="border:1px solid #eee" *ngIf="!makingPayment">
  <div class="panel-body" style="padding:35px">
    <div class="row"> 
      <div class="col-md-12">
          <div >
              <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                  <div class="form-group">
                    <span class="pull-left text-primary">Repayment Details</span>
                    <span class="pull-right"> 
                    </span>
                    <div class="clearfix"></div>
                  </div>
              </div>
              <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                 <div class="form-group">
                   <span class="pull-left text-primary">Loan Balance</span>
                   <span class="pull-right"> 
                     {{state?.LOAN_BALANCE}}
                   </span>
                   <div class="clearfix"></div>
                 </div>
             </div>
             <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                 <div class="form-group">
                   <span class="pull-left text-primary">Due</span>
                   <span class="pull-right">
                       {{state?.TOTAL_INDEBTED_DEBIT}}
                   </span>
                   <div class="clearfix"></div>
                 </div>
             </div>
             <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                 <div class="form-group">
                   <span class="pull-left text-primary">Late / Pending</span>
                   <span class="pull-right"> 
                   </span>
                   <div class="clearfix"></div>
                 </div>
             </div>
             <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                 <div class="form-group">
                   <span class="pull-left text-primary">Total Due</span>
                   <span class="pull-right"> 
                       {{state?.TOTAL_INDEBTED_BALANCE}}
                   </span>
                   <div class="clearfix"></div>
                 </div>
             </div>
          </div>
          
      </div>
    </div>
  </div>
</section> 