import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agents-repayment-mode',
  templateUrl: './agents-repayment-mode.component.html',
  styleUrls: ['./agents-repayment-mode.component.css']
})
export class AgentsRepaymentModeComponent implements OnInit {

  view = 'home';

  constructor() { }

  ngOnInit() {
  }

  setView(view: string) {
    this.view = view;
  }
}
