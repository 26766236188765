import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OperationsService, StorageService, AuthenticationService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-brodcast',
  templateUrl: './brodcast.component.html',
  styleUrls: ['./brodcast.component.css']
})
export class BrodcastComponent implements OnInit {
  kyc_setup='0';
  @Input('offer_template') offer_template:any;
  @Input('mobile') mobile:any;
  @Input('currentUser') currentUser:any;
  loading:any;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '5rem',
    minHeight: '7rem',
    placeholder: 'Enter your text here',
    translate: 'no',
    width: "100%",
    minWidth: "100%"
  };
  public ol = {
    LOAN_PRODUCT_ID: '',
    OFFER_LETTER: '' 
  }
  constructor(public authService:AuthenticationService,public toastr: ToastrService, vcr: ViewContainerRef, private router: Router,
    public storageService: StorageService, public operationsService: OperationsService) {
    this.currentUser = this.storageService.read<any>('currentUser'); 
  }
  ngOnInit() {
    this.ol.OFFER_LETTER = this.mobile.OFFER_LETTER;
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  saveOL(value, valid) {
    this.loading = true;
    this.operationsService.saveOLSettings(this.currentUser.token, value)
      .subscribe(data => {
        this.loading = false;
        this.showSuccess(data.message);
      });
  }
}
