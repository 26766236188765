import { Component, OnInit, Input } from '@angular/core';
import { LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-total-investments',
  templateUrl: './total-investments.component.html',
  styleUrls: ['./total-investments.component.css']
})
export class TotalInvestmentsComponent implements OnInit {

  @Input('people_id') people_id;

  notifications = [];
  loading = false;
  currentUser: any;
  data:any;
  constructor(
    private loansService: LoansService,
    public storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getInvestmentSummary();
  }

  getInvestmentSummary() {
    this.loading = true;
    this.loansService.getInvestmentSummary(this.currentUser.token, this.people_id).subscribe(res => {
      this.data=res.data;
      this.loading = false;
    }, err => {
      console.log({ err });
      this.loading = false;
    });
  }

}
