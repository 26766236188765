import { Component, OnInit, OnDestroy, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, OptionsserviceService, LoansService, StorageService, AuthenticationService } from '../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../_services/constants.service';

@Component({
  selector: 'app-ippischecker',
  templateUrl: './ippischecker.component.html',
  styleUrls: ['./ippischecker.component.css']
})
export class IppischeckerComponent implements OnInit {
  upfront_account:any;
  upfront_account_:any;
  @Input('parentRouteId') parentRouteId: number;
  @Input('sub') sub: any;
  @Input('sub_summary') sub_summary: any;
  @Input('where') where = '1';
  @Input('customer_id') customer_id = '0';
  @Input('from') from = '0';
  @Input('token') token: any;
  @Input('loan') loan: any;
  ippis_number = '';
  @Input('view') view = 'default';
  attachments = [];
  currentUser: any;
  description = "";
  file_field: any;
  loading = false;
  file_type = '2';
  photoHasBeenUploaded = false;
  fileHasBeenUploaded = false;
  doc_types: any;
  DOCTYPE: any;
  @ViewChild('file') file;
  filesUploaded = [];
  public files: Set<File> = new Set();
  uploadingfile = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  photos = [];
  @Output() closed = new EventEmitter();
  @Output() runSearch = new EventEmitter();
  @Input('self') self = true;
  accounts_added = '0';
  accounts: any;
  userId: any;
  account_state = 'start';
  statement_request = {
    "bank": [],
    "statement_source": '0'
  }
  category: any;
  payslip: any;
  @Output() openMore = new EventEmitter();
  has_payslip_details = false;
  account_name: any;
  amount: any;
  constructor(private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    private authService: AuthenticationService,
    private constant: ConstantsService,
    private toastr: ToastrService, public loansService: LoansService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  fileUploadeds(event) {
    //console.log(event)
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    console.log(this.loan)
    if (this.where != '4') {
      this.loadRecords()
    } else {
      this.getAccounts();
    }

  }
  getData() {
    this.has_payslip_details = false;
    this.loading = true;
    this.loansService.queryPayslipDetails(this.currentUser.token, this.ippis_number, this.category)
      .subscribe(result => {
        this.loading = false;
        if (result.status == false) {
          this.showError('No record found')
        } else {
          this.showSuccess('Record found')
          this.payslip = result;
          this.has_payslip_details = true;

        }

      })
  }
  getAccounts() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/accounts`, {
      request_id: this.parentRouteId,
      token: this.currentUser.token, from: this.from,
      customer_id: this.customer_id
    }).subscribe(response => {
      this.loading = false;
      this.accounts_added = response.count;
      this.accounts = response.data;
      this.userId = response.user_id;
      this.account_state = 'start';
    }, error => {
      //console.log(error);
    });
  }
  getMerchantAccount() {
    this.loading = true;
    return this.http.post<any>('https://wema.creditclan.com/api/v3/wema/generate_partner_account', {
      merchantname: this.account_name,
      amount: this.amount
    }).subscribe(response => {
      this.loading = false;
      return this.http.post<any>('https://creditclanapis.creditclan.com/api/v2/operations/saveMerchantBank', {
        token: this.currentUser.token, from: this.from,
        customer_id: this.loan.people_id,
        bank: response.data,
        loan: this.loan,
        merchantname: this.account_name
    }).subscribe(response => {
      this.loading = false;
      this.showSuccess("Request successful")
    }, error => {
      //console.log(error);
    });
    }, error => {
      //console.log(error);
    });
  }
  getMerchantAccount_() {
    this.loading = true;
    return this.http.post<any>('https://creditclanapis.creditclan.com/api/v2/operations/generate_partner_account', {
      merchantname: this.account_name,
      amount: this.amount,
      loan: this.loan,
      token: this.currentUser.token,
    }).subscribe(response => {
      this.loading = false;
      return this.http.post<any>('https://creditclanapis.creditclan.com/api/v2/operations/saveMerchantBank_kda', {
        token: this.currentUser.token, from: this.from,
        customer_id: this.loan.people_id,
        bank: response.data,
        loan: this.loan,
        merchantname: this.account_name
    }).subscribe(response => {
      this.loading = false;
      this.showSuccess("Request successful")
    }, error => {
      //console.log(error);
    });
    }, error => {
      //console.log(error);
    });
  }
  getUpfrontAccount() {
    this.upfront_account = this.upfront_account_;
     this.loading = true;
     this.loansService.getUpfrontAccount(this.loan.request_id, this.amount)
      .subscribe(result => {
        if (result.status == false) {
          this.showError(result.message)
        } else {
          this.showSuccess('Request successful')
          this.upfront_account = result;
        }

        this.loading = false;
      });
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  addingphoto() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.file_type = '2';
      this.open('photo_form');
      this.filesUploaded = [];
    }
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  uploadTheFile() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    this.loansService.saveIPPISInfoForCustomer(this.currentUser.token, this.loan.request_id, this.loan.people_id, this.ippis_number, this.category)
      .subscribe(result => {
        if (result.status == false) {
          this.showError(result.message)
        } else {
          this.showSuccess('Request successful')

        }

        this.loading = false;
      });
  }
  loadRecords() {
    this.currentUser = this.storageService.read<any>('currentUser');

    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.getLoanAttachments(this.currentUser.token, this.parentRouteId)
          .subscribe(result => {
            this.attachments = result.data.attachments;
            this.doc_types = result.data.doctypes;

          });
      });
    } else {
      this.loading = true;
      this.loansService.getLoanAttachments_(this.currentUser.token, this.parentRouteId, this.customer_id, this.from)
        .subscribe(result => {
          this.photos = result.data.attachments.filter(attach => attach.FILE_TYPE === '2' && attach.FILE_NAME);
          this.attachments = result.data.attachments.filter(attach => attach.FILE_TYPE === '1' && attach.FILE_NAME);
          this.doc_types = result.data.doctypes;
          this.loading = false;
        });
    }
  }
  onFilesAdded(event) {
    this.uploadingfile = true;
    let files = event.target.files;
    this.loansService.doUpload(files[0])
      .subscribe(result => {
        this.uploadingfile = false;
        if (result.status == true) {
          this.photoHasBeenUploaded = true;
          this.filesUploaded.push(result.result);
        }

      });
  }
  openUploadDialog() {
    this.file.nativeElement.click();
  }
  uploadFile(event) {
    // let files = event.target.files;
    // //this.loansService.uploadFile(files[0],this.description,this.parentRouteId);
    // let uploadFile = files[0];
    // let myUploadItem = new MyUploadItem(uploadFile);
    // myUploadItem.formData = { description: this.description, token: this.currentUser.token, request_id: this.parentRouteId, section: this.file_type };  // (optional) form data can be sent with file
    // this.loading = true;
    // this.uploaderService.onSuccessUpload = (item, response, status, headers) => {
    //   // success callback
    //   this.loading = false;
    // };
    // this.uploaderService.onErrorUpload = (item, response, status, headers) => {
    //   // error callback
    //   this.loading = false;
    // };
    // this.uploaderService.onCompleteUpload = (item, response, status, headers) => {
    //   // complete callback, called regardless of success or failure
    //   this.loading = false;
    //   this.loadRecords();
    //   this.view = "photo"
    // };
    // this.uploaderService.upload(myUploadItem);
  }
  open(section) {
    this.view = section;
    this.description = '';
    this.filesUploaded = [];
  }
  close() {
    this.view = 'photo';
    this.filesUploaded = [];
  }

}

