import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-microcreditresult',
  templateUrl: './microcreditresult.component.html',
  styleUrls: ['./microcreditresult.component.css']
})
export class MicrocreditresultComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() summary :any;
  constructor() { }

  ngOnInit() {
    console.log(this.summary,'result')
  }  
}
