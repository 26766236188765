import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-applicationseligibility',
  templateUrl: './applicationseligibility.component.html',
  styleUrls: ['./applicationseligibility.component.css']
})
export class ApplicationseligibilityComponent implements OnInit {
  @Input() requestId;
  @Input() embedModal = false;
  @Output() openProfileAnalysisModal = new EventEmitter();
  @Output() analyseCustomerComplete = new EventEmitter();
  rerunning = false;
  interest_bands: any;
  where_from = 'eligibility';
  is_default = '1';
  endDate = '1561568400000';
  view = 'details';
  currentUser: any;
  parentRouteId: any;
  sub: any;
  loan: any;
  docpickups: any;
  creating_contract = false;
  showPicture = false;
  showActionsModal = false;
  showAccountConfirmation = false;
  loading = false;
  createdContract = false;
  eligibility: any;
  analysis: any;
  recommended: any;
  status: boolean;
  currentview = 'result';
  leaves: any;
  data_status: any;
  pageLoading: boolean;
  editingBands = false;
  doRefresh = false;
  eligibilitycriteria: any;
  criteria = {
    account_to_analyse:'',
    card_to_analyse:'',
    nin_to_analyse:'',
    credit_bureau_to_analyse:'',
    bankstate_to_analyse:'',
    skip_account:'',
    skip_card:'',
    skip_identity:'',
    skip_credit_bureau:'',
    skip_bank_statement:'',
  }
  @Input() runstatus = true;
  changingCriteria = false;
  constructor(private DataService: DataService,
    public toastr: ToastrService, public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.eligibilitySettingsChanged.subscribe(res => {
      this.doRefresh = true;
    });
    this.dataService.sendBackLoanToCaller.subscribe(res => {
      this.loan = res.loan;
    })
    // this.dataService.runEligibility.subscribe(res => {
    //   this.runstatus = false;
    //   this.criteria = res.criteria;
    //   this.rerunEligibility();
    // })
  }
  ngOnInit() {
    this.getSummary();
    this.status = false;
    this.is_default = '1';
    this.rerunning = false;
    this.editingBands = false;
    this.view = 'details';
  }
  manualEligibity(event) {
    this.is_default = '0'
  }
  changeCurrentView(newview) {
    this.currentview = newview;
  }
  openSettings() {
    if (this.embedModal) return this.openProfileAnalysisModal.emit();
    this.doRefresh = false;
    this.DataService.showProfileAnalysisModal.emit();
  }
  openProfile(request_id) {
    this.router.navigate(['./applications/profile/' + request_id]);
  }
  // openSettings() {
  //   this.router.navigate(['./system/settings/profile-analysis']);
  // }
  openGuarantors(request_id) {
    this.router.navigate(['./applications/guarantors/' + request_id]);
  }
  openContract(request_id) {
    this.router.navigate(['./applications/pending/' + request_id]);
  }
  openLoanSpider(request_id) {
    this.router.navigate(['applications/analytics/' + request_id + '/spider']);
  }
  opentimeline(request_id) {
    this.router.navigate(['./applications/timeline/' + request_id]);
  }
  openAnalytics(request_id) {
    this.router.navigate(['./applications/analytics/' + request_id]);
  }
  openCreditCheck(request_id) {
    this.router.navigate(['./applications/creditcheck/' + request_id]);
  }
  openBankStatement(request_id) {
    this.router.navigate(['./applications/bankstatements/' + request_id]);
  }
  openP2P(request_id) {
    this.router.navigate(['./applications/p2p/' + request_id]);
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.DataService.disbursingRequest.emit(event);
  }
  openPreviousRequests(request_id) {
    this.router.navigate(['./applications/requests/' + request_id]);
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  creatingContract() {
    this.creating_contract = true;
  }
  editingContract() {
    this.DataService.openContractModal.emit({ currentUser: this.currentUser, request_id: this.parentRouteId, loan: this.loan });
  }
  openContractModal() {
    this.DataService.openContractModal.emit({ currentUser: this.currentUser.token, request_id: this.parentRouteId, loan: this.loan });
  }
  reopenRequest(request_id) {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'reopenrequest', data: this.loan });
  }

  getSummary() {
    this.sub = this.route.params.subscribe(params => {
      // console.log(+params['id'], this.request_Id;
      this.parentRouteId = +params['id'] || this.requestId;
      this.getLoan();
    });

  }
  loan_: any;
  getLoan() {
    this.loan = this.loan_;
    this.dataService.getLoanFromTheDashboard.emit();
    if (this.loan) {
      this.getEligibility();
    } else {
      this.loan = this.loan_;
      this.pageLoading = true;
      this.status = false;
      this.loansService.getLoanForTimeline(this.currentUser.token, this.parentRouteId)
        .subscribe(data => {
          this.loan = data.loan;
          // this.dataService.onRequestLoadingEndFromBackend.emit({loan: this.loan});
          this.getEligibility();
        });
    }
  }
  getEligibility() {
    this.rerunning = true;
    this.pageLoading = true;
    this.loansService.getEligibilityStatus(this.currentUser.token, this.parentRouteId)
      .subscribe(result => {
        this.rerunning = false;
        this.pageLoading = false;
        if (result.status === true) {
          this.pageLoading = false;
          this.status = result.status;
          this.eligibility = result.eligibility;
          this.analysis = result.description;
          this.recommended = result.recommended;
          this.data_status = result.data.status;
          this.criteria = result.criteria;
        } else {
          this.eligibility = [];
          this.analysis = [];
          this.status = false;
        }
        this.leaves = result.leafids;
      });
  }
  analyseCustomer() {
    this.rerunning = true;
    this.loansService.analyseCustomerForEligibility(this.currentUser.token, this.parentRouteId, this.leaves, this.is_default)
      .subscribe(result => {
        this.eligibilitycriteria = result;
        this.rerunning = false
        this.changingCriteria = true;
        //this.analyseCustomerComplete.emit(result)
      });
  }
  rerunEligibility() {
    this.changingCriteria = false;
    this.editingBands = false;
    this.view = 'details';
    //this.pageLoading = true;
    this.eligibility = [];
    this.analysis = [];
    this.recommended = [];
    // this.status = false;
    this.rerunning = true;
    this.loansService.rerunEligibility(this.currentUser.token, this.parentRouteId, this.leaves, this.is_default, this.criteria)
      .subscribe(result => {
        this.rerunning = false;
        // this.currentview = 'result';
        if (result.status === true) {
          this.view = 'details';
          this.pageLoading = false;
          this.status = result.status;
          this.eligibility = result.eligibility;
          this.analysis = result.description;
          this.recommended = result.recommended;
          this.data_status = result.data.status;
        } else {
          this.eligibility = [];
          this.analysis = [];
        }
      });
  }
  closeSettings() {
    this.view = 'details';
  }
  saveBandForCustomer(people_id, band) {
    this.loading = true;
    this.loansService.saveBandForCustomer(this.currentUser.token, people_id, band)
      .subscribe(result => {
        this.loading = false;

      });
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId, loan: this.loan });
  }
  acceptContract() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'accept_contract_mod', data: this.loan });
  }
  requestFinancials() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_financial_mod', data: this.loan });
  }
  requestOthers() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_others_mod', data: this.loan });
  }
  requestGuarantors() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
  }
  requestToGetPaid() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_to_get_paid', data: this.loan });
  }

  requestKYC() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_kyc_mod', data: this.loan });
  }

  requestDocs() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_docs_mod', data: this.loan, docpickups: this.docpickups });
  }

  deleteRequest() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'delete_request_mod', data: this.loan });
  }
  sendBVN() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_bvn_mod', data: this.loan });
  }

  requestCard() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
  }

  sendContract() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
  }

  confirmAccount() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'confirm_account_mod', data: this.loan });
  }

  setupdirectdebit() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'setup_debit_mandate', data: this.loan });
  }
  checkdirectdebitstatus() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'check_debit_mandate', data: this.loan });
  }
  assignLoanOfficer() {
    this.DataService.onChangeLoanOfficer.emit({ loan: this.loan });
  }
  setDefaultRepaymentMode(loan, repayment_mode) {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'check_debit_mandate', data: this.loan });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  showProfilePic() {
    this.dataService.showProfilePic.emit();
  }
  changeView() {
    this.editingBands = false;
  }

  openDefinedSettings() {

  }
}
