<div class="navbar-fixed-top">

  <!-- Top Menu -->
  <header class="header">
    <div class="app-container">
      <div class="flex-center">
        <a class="logo bold pull-left" style="margin-right:15px" [routerLink]="['/features']">
          <img src="assets/img/CC_small_colour_1.png" style="width: 35px">
        </a>

        <div class="flex">
          <!-- Search Field -->
          <div class="hide">
            <input type="hidden" id="USER_DEFINED_ACTORS">
            <input (keypress)="search($event)" type="text" [(ngModel)]="searchTerm" class="form-control input-sm"
              placeholder="Search"
              style=" margin-top:0;width: 260px;border-top-right-radius: 0;border-bottom-right-radius: 0;">
          </div>

          <div class="notification">
            <div class="nav-items mr-3 hide">
              <a class="nav-item"
                (click)="showNotifications = (notifications && notifications.count > 0 ? !showNotifications : showNotifications)">
                <div class="icon">
                  <i class="fas fa-bell"></i>
                  <div *ngIf="notifications && notifications.count > 0" class="not-dot">{{notifications.count}}</div>
                </div>
              </a>
            </div>
            <div class="notification-wrapper scroll-y" *ngIf="showNotifications && notifications.count > 0">
              <div class="notification-item" *ngFor="let notification of notifications.response">
                <div class="cool-flex cool-date-action">
                  <small>{{notification.date_added | date:'dd/MM/yyyy'}}</small>
                  <small class="cool-action"
                    (click)="markMessageAsRead(notification.notification_id, notification.people_people_id)">Mark
                    as Read</small>
                </div>
                <strong class="cool-title">{{notification.LEGAL_NAME}}</strong>
                <p class="cool-message">{{notification.message}}</p>
              </div>
            </div>
          </div>

          <!-- New Search -->
          <div class="search_box">
            <input name="lender_name" placeholder="Search.." (keyup)="search($event)" [(ngModel)]="searchTerm"
              type="text">
            <button class="icon"><i class="fa fa-search"></i></button>
            <!--<span style="display:inline-block; vertical-align:top; margin:-3px;padding: 3px; background: #FAFAFA; cursor: pointer;" (click)="search()">-->
            <!--<img src="assets/images/search.PNG">-->
            <!--</span>-->
          </div>

          <!-- Search Results -->
          <div class="searchlist panel" *ngIf='searching' [ngClass]="{'loading': searching && !customers}"
            style="min-height: 80px">
            <div *ngIf="!customers" class="flex flex-center fill-h">
              <!-- <p class="mt-4">Press ENTER to search</p> -->
            </div>
            <div *ngIf="customers">
              <div class="item user" *ngFor="let customer of customers?.a">
                <div class="image">
                  <img alt="Customer" (click)="openLoan(customer)" style="cursor: pointer;"
                    src="{{customer?.FILE_NAME | imagenotfound}}">
                </div>
                <div class="content flex-1 flex justify-between align-center">
                  <div class="cursor-pointer" (click)="openLoan(customer)">
                    <h4 class="title no-margin" style="text-decoration: underline;">{{customer.LEGAL_NAME}}</h4>
                    <p class="subtitle">{{customer.COMPANY_OF_WORK_ID}}</p>
                  </div>
                  <div class="btn-group ml-4">
                    <button (click)="openLoanForm(customer)" class="btn btn-outline-secondary btn-xs">
                      Create Request
                    </button>
                    <button class="btn btn-outline-secondary btn-xs" (click)="sendEmailToCustomer(customer)">
                      Send email
                    </button>
                    <button class="btn btn-outline-secondary btn-xs" (click)="sendSMSToCustomer(customer)">
                      Send SMS
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span style="position: absolute; right: 5px; top: 15px; left: 25px" class="txt t_l4">Individual</span> -->
          </div>
        </div>
      </div>



      <div class="flex-center bottom">
        <!-- search button -->
        <!--<input type="button" class="btn btn-primary hide" value="Search" (click)="search()" style="-->
        <!--padding-top: 5px;-->
        <!--padding-bottom: 5px;-->
        <!--line-height: 18px;-->
        <!--margin-top: 2px;-->
        <!--border-top-left-radius: 0;-->
        <!--border-bottom-left-radius: 0;-->
        <!--margin-left: -1px;-->
        <!--background-color: #f3a535 !important;-->
        <!--border-color: #f5a534 !important;-->
        <!--">-->
        <div>
          <div class="mr-3" *ngIf="isloan=='loans'">
            <app-button *ngIf="!is_company_staff && test_mode!='1' && lender?.PEOPLE_ID!='40245'" buttonclass="small mr-3"
              (click)="overlayOpenNewLoan=true" [disabled]="!grantRight('14')" label="Book Loan"></app-button>
              <app-button *ngIf="!is_company_staff && test_mode!='1' && lender?.PEOPLE_ID=='40245' && (currentUser?.account_id == 6547||currentUser?.account_id == 6518)" buttonclass="small mr-3"
              (click)="overlayOpenNewLoan=true" [disabled]="!grantRight('14')" label="Book Loan"></app-button>
            <app-button *ngIf="is_company_staff && test_mode!='1'" buttonclass="small mr-3"
              (click)="newCustomerRecord=true" label="Update Staff Record"></app-button>
            <!-- <app-button *ngIf="is_company_staff && test_mode!='1'" buttonclass="small mr-3" (click)="newLoanOpen=true"
              label="Book Loan"></app-button> -->
            <!-- <button *ngIf="test_mode && !is_company_staff" class="edged_box big_btn wd_btn btn-orange t_l4"
                  style="padding: 5px; margin: 0; margin-right: 25px"
                  (click)="webussd = !webussd">Go Live
          </button>
          <button *ngIf="test_mode" class="edged_box big_btn wd_btn btn-orange t_l4"
                  style="padding: 5px; margin: 0; margin-right: 25px"
                  onclick="event.preventDefault(); CreditClan.open('iFNvz2BhpgddcgjybqDFNUAmHDYcTleACv99G8hZ')">Go
            Live
          </button> -->
            <app-button *ngIf="test_mode=='1'" buttonclass="small mr-3 outline white" (click)="openSimulator()"
              label="Simulate"></app-button>
            <app-button *ngIf="test_mode=='1'" buttonclass="small mr-3 outline white" (click)="openGoLive()"
              label="Go Live"></app-button>
            <button *ngIf="test_mode=='1'" class="mr-3 btn btn-outline-secondary">
              <countdown [config]="{leftTime: timeLeft}"></countdown>
            </button>
          </div>

          <div class="mr-3" *ngIf="isloan=='savings'">
            <app-button class="small" (click)="overlayOpen=false; overlayOpenInvestment=true;" label="Book Savings">
            </app-button>
          </div>
        </div>

        <div>
          <div *ngIf="!is_company_staff && isloan=='loans'" class="nav-items">
            <a [ngClass]="{disabled: disableLinkIfNotRole('requests')}" class="nav-item"
              [routerLink]="['/applications/dashboard']" [routerLinkActive]="'active'">
              <div class="icon">
                <i class="fas fa-clipboard-list"></i>
              </div>
              <div class="text">Requests</div>
            </a>

            <a [ngClass]="{disabled: disableLinkIfNotRole('portfolio')}" class="nav-item"
              [routerLink]="['/portfolio/dashboard']" [routerLinkActive]="'active'">
              <div class="icon"><i class="fa fa-briefcase"></i></div>
              <div class="text">Portfolio</div>
            </a>
            <div class="dropdown more-menu">
              <a class="nav-item dropdown-toggle" id="moreMenu2" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
                <div class="icon"><i class="fa fa-ellipsis-h"></i></div>
                <div class="text">More <b class="caret"></b></div>
              </a>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="moreMenu2">
                <li *ngIf="enable_peer=='1'"><a [routerLinkActive]="'active'" [routerLink]="['/investments']">P2P
                    Funding</a>
                </li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('customers')}" [routerLinkActive]="'active'"
                    [routerLink]="['/crm']">Customers</a>
                </li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('operations')}" [routerLinkActive]="'active'"
                    [routerLink]="['/operations/queue']">Operations</a></li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('settings')}" [routerLinkActive]="'active'"
                    [routerLink]="['/system/settings']">Settings</a>
                </li>
                <li *ngIf="accounting=='1'">
                  <a [ngClass]="{disabled: disableLinkIfNotRole('reports')}" [routerLinkActive]="'active'"
                    [routerLink]="['/reports']">Reports</a>
                </li>
                <li *ngIf="lender?.PEOPLE_ID=='40245'">
                  <a [ngClass]="{disabled: disableLinkIfNotRole('reports')}" [routerLinkActive]="'active'"
                    [routerLink]="['/accounting/chart_of_accounts']">Accounting</a>
                </li>

                
                <li role="separator" class="divider"></li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('products')}" [routerLinkActive]="'active'"
                    [routerLink]="['/loanproducts']">Products</a>
                </li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('branches')}" [routerLinkActive]="'active'"
                    [routerLink]="['/operations/branches']">Branches</a>
                </li>
                <li>
                  <a [ngClass]="{disabled: disableLinkIfNotRole('clusters')}" [routerLinkActive]="'active'"
                    [routerLink]="['/serviced/companies']">Customer Clusters</a>
                </li>
                <li *ngIf="lender?.PEOPLE_ID=='40245'">
                  <a [routerLinkActive]="'active'"
                    [routerLink]="['/merchantwallet']">Merchant Wallet</a></li>
                <li role="separator" class="divider"></li>
                <li>
                  <a [routerLinkActive]="'active'" [routerLink]="['/documentation']">Documentation</a>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="!is_company_staff && isloan=='savings'" class="nav-items">
            <a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/savings/pending']">
              <div class="icon"><i class="fas fa-clipboard-list"></i></div>
              <div class="text">Requests</div>
            </a>
            <a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/savings/portfolio']">
              <div class="icon"><i class="fa fa-briefcase"></i></div>
              <div class="text">Portfolio</div>
            </a>
            <div class="dropdown">
              <a class="nav-item dropdown-toggle" id="moreMenu3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
                <div class="icon"><i class="fa fa-ellipsis-h"></i></div>
                <div class="text">More <b class="caret"></b></div>
              </a>
              <ul class="dropdown-menu" aria-labelledby="moreMenu3">
                <li><a [routerLinkActive]="'active'" [routerLink]="['/savings/customers']">Customers</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/savings/operations']">Operations</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/savings/reports']">Reports</a></li>
              </ul>
            </div>
          </div>

          <div *ngIf="!is_company_staff && isloan=='cooperatives'" class="nav-items">
            <a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/cooperatives']">
              <div class="icon"><i class="fa fa-home"></i></div>
              <div class="text">Home</div>
            </a>
            <a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/cooperatives/all']">
              <div class="icon"><i class="fa fa-users"></i></div>
              <div class="text">Cooperatives</div>
            </a>
            <div class="dropdown">
              <a class="nav-item dropdown-toggle" id="moreMenu4" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
                <div class="icon"><i class="fa fa-ellipsis-h"></i></div>
                <div class="text">More <b class="caret"></b></div>
              </a>
              <ul class="dropdown-menu" aria-labelledby="moreMenu4">
                <li><a [routerLinkActive]="'active'" [routerLink]="['/cooperatives/customers']">Customers</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/cooperatives/operations']">Operations</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/cooperatives/reports']">Reports</a></li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Right side Icon Links -->
        <div class="nav notify-row pull-right" id="top_menu" style="margin-top:0px !important">
          <ul class="nav top-menu " style=" margin-top: 0;">
            <!--<li class="">-->
            <!--<a class="i" [routerLinkActive]="'active'" [routerLink]="['/credit/creditdashboard']" title="Home">-->
            <!--<i class="fa fa-home"></i>-->
            <!--</a>-->
            <!--</li>-->
            <!--<li *ngIf="!is_company_staff" class="">-->
            <!--<a class="i" [routerLinkActive]="'active'" [routerLink]="['/portfolio/dashboard']" title="Chat">-->
            <!--<i class="fa fa-comment"></i>-->
            <!--</a>-->
            <!--</li>-->
            <!-- <li *ngIf="!is_company_staff" class="">
                <a class="i" [routerLinkActive]="'active'" [routerLink]="['/requests/market']" title="Loan Market">
                    <i class="fa fa-shopping-cart"></i>
                </a>
            </li> -->
            <!-- <li *ngIf="!is_company_staff" class="">
                <a class="i" [routerLinkActive]="'active'" [routerLink]="['/savings']" title="Savings">
                    <i class="fa fa-shopping-cart"></i>
                </a>
            </li> -->
            <!--<li *ngIf="!is_company_staff" class="">-->
            <!--<a class="i" [routerLinkActive]="'active'" [routerLink]="['/applications/dashboard']" title="KYC">-->
            <!--<i class="fa fa-street-view"></i>-->
            <!--</a>-->
            <!--</li>-->

            <li *ngIf="!is_company_staff" class="dropdown">
              <a class="i" style="cursor:pointer;font-size:20px !important" data-toggle="dropdown"
                class="dropdown-toggle " href="#" title="Wallet">
                <i class="fa fas-wallet"></i>
                <!-- <span class="badge bg-white">{{dashboarddata?.DASHBOARD_BL.WALLET_BALANCES.LENDER_WALLET_BALANCE| money}}</span>-->
              </a>
              <ul class="dropdown-menu extended" style="left:5px !important">
                <li>
                  <div class="wallet_summary">
                    <h6>Actual Balance : {{dashboarddata?.data.LENDER_WALLET_BALANCE| money}}</h6>
                    <h6>Book Balance : {{dashboarddata?.data.LENDER_WALLET_BOOK_BALANCE| money}}</h6>
                    <p>WALLET BALANCE</p>
                  </div>
                </li>
              </ul>
            </li>
            <li class="dropdown user-dropdown mt-1">
              <a data-toggle="dropdown" class="dropdown-toggle " href="#">
                <img alt="" class="tiny" src="assets/img/avatar-mini.png">
                <span class="dot"
                  *ngIf="notifications?.remaining_messages_count">{{notifications?.remaining_messages_count||0}}</span>
                <!--<span class="username" style="color:#fff;">{{currentUser?.legal_name}}</span>-->
                <b class="caret"></b>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="logged-in-user">
                  <div class="image">
                    <img alt="" class="tiny" src="assets/img/avatar-mini.png">
                  </div>
                  <div class="name">
                    {{currentUser?.legal_name}}
                  </div>
                </li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/password']">Change Password</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/security']">Change Security</a></li>
                <li role="separator" class="divider"></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/summary']">Profile</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/request']">Requests</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/portfolio']">Portfolio</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/approval']">Approvals
                    ({{approvals_count}})</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/notifications']">Notifications
                    ({{notifications?.remaining_messages_count||0}})</a></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/home/openedtask']">Task ({{taskCount || 0}})</a>
                </li>
                <li role="separator" class="divider"></li>
                <li><a [routerLinkActive]="'active'" [routerLink]="['/login']" class="lg flex align-center">
                    <i class="fa fa-power-off mr-2"></i> Log Out</a></li>
              </ul>
            </li>

          </ul>
        </div>
        <!-- Book Loan Button -->

      </div>
    </div>

  </header>
</div>

<div *ngIf="overlayOpen">
  <div class="overlay_ app_overlay animated flex flex-column">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpen=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content tall floating-title overflow-visible wide" role="document">
      <app-newloanform [contract_details]="contract_details" [request_id]='contract_details?.loan?.request_id'
        [request_step]="request_step" [selectedCustomer]="selectedCustomer" [sub]='sub' [from]='2'
        (close)="overlayOpen=false">
      </app-newloanform>
    </div>
  </div>
</div>
<div *ngIf="overlayOpenPartnerLoan">
  <div class="overlay_ app_overlay animated flex flex-column">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpen=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content tall floating-title overflow-visible wide" role="document">
      <app-newloanform [contract_details]="contract_details" [request_id]='contract_details?.loan?.request_id'
        [request_step]="request_step" [selectedCustomer]="selectedCustomer" [sub]='sub' [from]='2'
        (close)="overlayOpen=false">
      </app-newloanform>
    </div>
  </div>
</div>

<div *ngIf="overlayOpenContractConfirm">
  <div class="overlay_ app_overlay animated flex flex-column">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpenContractConfirm=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content tall floating-title overflow-visible" role="document">
      <app-newloanform [request_id]='contract_details?.loan?.request_id' [request_step]="5" [sub]='10' [from]='2'
        (close)="overlayOpenContractConfirm=false">
      </app-newloanform>
    </div>
  </div>
</div>

<div *ngIf="overlayModifyContractConfirm">
  <div class="overlay_ app_overlay animated flex flex-column">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModifyContractConfirm=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content tall floating-title overflow-visible" role="document">
      <app-newloanform [request_id]='contract_details?.loan?.request_id' [request_step]="3" [sub]='11' [from]='2'
        (close)="overlayModifyContractConfirm=false">
      </app-newloanform>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="disbursing_request">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="disbursing_request=!disbursing_request">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding animated zoomIn" role="document">
    <app-disbursement (paymentHasBeenProcessedFinally)="paymentHasBeenProcessedFinally($event)" [record_type]="1"
      [schedule_type]="1" [sub]="1" [disburse]="disburse" [security_question]="security_question" [newloan]="loan">
    </app-disbursement>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="approving_prepayemnt">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="approving_prepayemnt=!approving_prepayemnt">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding animated zoomIn" role="document">
    <app-approvingprepayment [disburse]="disburse" [security_question]="security_question" [newloan]="loan">
    </app-approvingprepayment>
  </div>
</div>

<div *ngIf="open_profile_analysis_modal">
  <div class="overlay_ app_overlay animated">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="open_profile_analysis_modal=false;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
      <div class="overpanel-content floating-title wide no-padding" style="top: 45px;">
        <app-eligibilityanalysismodal></app-eligibilityanalysismodal>
      </div>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated fadeIn" *ngIf="open_approval">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="approving=false;rejecting=false;open_approval=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding animated zoomIn confirmation" role="document">
    <app-approveloan [loading]="loading" [request_id]="request_id" [prev_levels]="prev_levels"
      (cancelThisApproval)="cancelThisApproval()" (rejectThisRequest)="rejectThisRequest($event)"
      (approveThisRequest)="approveThisRequest($event)" [level]="level" [approving]="approving" [rejecting]="rejecting"
      [loan]="loan" [token]="currentUser.token"></app-approveloan>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="open_market">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="approving=false;rejecting=false;open_market=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding animated zoomIn confirmation" role="document">
    <!-- <app-approveloan [loading]="loading" [request_id]="request_id" [prev_levels]="prev_levels"
      (cancelThisApproval)="cancelThisApproval()" (rejectThisRequest)="rejectThisRequest($event)"
      (approveThisRequest)="approveThisRequest($event)" [level]="level" [approving]="approving" [rejecting]="rejecting"
      [loan]="loan" [token]="currentUser.token"></app-approveloan> -->
      <app-pushingtomarket [loan]="loan" [currentUser]="currentUser"></app-pushingtomarket>
  </div>
</div>
<div *ngIf="overlayOpenNewLoan">
  <div class="overlay_ app_overlay animated flex flex-column">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpenNewLoan=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content tall floating-title overflow-visible wide" role="document">
      <app-newloanform [selectedCustomer]="selectedCustomer" [sub]="2" [customerSelected]="customerSelected" [from]="1"
        (close)="overlayOpenNewLoan=false"></app-newloanform>
    </div>
  </div>
</div>

<!-- <div *ngIf="overlayOpen">
    <div class="overlay_ app_overlay animated full-page">
      <div class="col-md-12">
        <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpen=false">
          <i class="fa fa-times" aria-hidden="true"></i>
        </a>
      </div>
      <div class="overpanel-content" role="document">
        <app-loanform [selectedCustomer]="selectedCustomer" [sub]="2" [customerSelected]="customerSelected" [from]="1">
        </app-loanform>
      </div>
    </div>
  </div> -->

<div class="overlay_ app_overlay animated" style="overflow:hidden;background: rgba(138, 138, 138, 0.95);"
  *ngIf="overlayOpenInvestment">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayOpenInvestment=false; overlayOpen = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content container" role="document"
    style="text-align:left;width:400px; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
    <app-booksavings></app-booksavings>
  </div>
</div>
<div *ngIf="newLoanOpen">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="newLoanOpen=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-requestform></app-requestform>
    </div>
  </div>
</div>
<div *ngIf="newCustomerRecord">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="newCustomerRecord=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-requestform [editing_customer]="true"></app-requestform>
    </div>
  </div>
</div>
<div *ngIf="webussd">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="webussd=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
      <app-web-ussd></app-web-ussd>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated ln_mdl" style="overflow:hidden" *ngIf="openShowInterest">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeShowInterest()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class=" overpanel-content container  animated bounceIn" role="document"
    style="background:rgba(255, 255, 255, 0.95); height:100%; text-align:left;width:650px; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden">

    <div class="modl text-center">

      <div class="modl_title" style="margin-bottom: 50px">
        <h2>
          <span class="text-success">Show Interest</span>

        </h2>
      </div>

      <div style="width: 500px; margin: 0 auto 25px">

      </div>

      <div style="width: 200px; margin: 0 auto">
        <div style="margin-bottom: 30px">
          <button class="text-center txt t_l4 btn-block"
            [ngClass]="{'btn-danger': is_done=='0', 'btn-success': is_done=='1'}"
            style="padding-top: 12px; padding-bottom: 12px" (click)="makeOfferToAdvertisedLoan()"
            [disabled]="loading||is_done=='1'">
            <strong *ngIf="is_done=='0'">Make Offer</strong>
            <i class="fa fa-spinner fa-spin" *ngIf="loading && is_done=='0'"></i>
            <i class="fa fa-check" *ngIf="!loading && is_done=='1'"></i>
          </button>
        </div>

      </div>
    </div>


  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="updatingofferletter">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="updatingofferletter=!updatingofferletter">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title wide small" role="document">
    <app-brodcast [mobile]="offerletterdata"></app-brodcast>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='header'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-email-header-content></app-email-header-content>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="emailopen =='footer'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-email-footer-content></app-email-footer-content>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='welcome_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email></app-welcome-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='offer_letter'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-offer-letter></app-offer-letter>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="mailadveropen">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="mailadveropen = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email [for_advert]=true></app-welcome-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="remindercontenteditor">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="remindercontenteditor = false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email (onSubmitSMSMessageContentUpdate)="onSubmitSMSMessageContentUpdate($event)"
      (onSubmitMessageContentUpdate)="onSubmitMessageContentUpdate($event)"
      [is_for_email_reminder]="is_for_email_reminder" [for_advert]=true [for_content]="true"
      [message_subject]="message_subject" [message_content]="message_content" [message_type]="message_type">
    </app-welcome-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='phone_otp'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-phone-otp></app-phone-otp>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='reset_pin'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-rest-pin></app-rest-pin>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='direct_debit'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-direct-debit-email></app-direct-debit-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='validate_work'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-validation-work-email></app-validation-work-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='loan_rejection'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-loan-rejection-email></app-loan-rejection-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='payment_received'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-payment-received></app-payment-received>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='borrower_received_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-borrower-received-offer-email></app-borrower-received-offer-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='lender_make_offer'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-lender-make-offer></app-lender-make-offer>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='borrower_accepted'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-borrower-accpected-offer-letter></app-borrower-accpected-offer-letter>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='withdrawal_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-welcome-email></app-welcome-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='send_to_market'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-sent-to-market></app-sent-to-market>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='guarantor_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-guarantor-request-email></app-guarantor-request-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='card_request_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-card-request-email></app-card-request-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='bank_account_confirmation'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-bank-account-confirmation-email></app-bank-account-confirmation-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='request_attachment'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-request-for-attachments></app-request-for-attachments>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='bvn_verification'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-bvn-verification-email></app-bvn-verification-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='repayment_email'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-repayment-reminder-email></app-repayment-reminder-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='loan_disbursed'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-loan-disbursed-email></app-loan-disbursed-email>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="emailopen =='due-reminder'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title widest small" role="document">
    <app-due-reminder></app-due-reminder>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="emailopen =='sms_lateness'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content overpanel-content-sms widesms" role="document">
    <app-sms-lateness-notification></app-sms-lateness-notification>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="emailopen =='sms-due-reminder'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="emailopen=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content overpanel-content-sms widesms" role="document">
    <app-sms-due-reminder></app-sms-due-reminder>
  </div>
</div>
<div *ngIf="schedule_open">
  <div class="overlay_ app_overlay animated fadeIn">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="schedule_open=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn long wider" role="document">
      <app-schedulemodal [from_where]="from_where" [loan]="loan" [schedule]="schedule" [loan_status]='loan_status'
        [parentRouteId]="parentRouteId" [canEditSchedule]="canEditSchedule" [from_where]='from_where'>
      </app-schedulemodal>
    </div>
  </div>
</div>
<div *ngIf="preview_schedule">
  <div class="overlay_ app_overlay animated fadeIn">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="preview_schedule=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn wide" role="document">
      <app-previewrepaymentschedule [schedule]="schedule" [goandgetschedule]='1' [loan_status]='loan_status'
        [parentRouteId]="parentRouteId" [canEditSchedule]="canEditSchedule"></app-previewrepaymentschedule>
    </div>
  </div>
</div>
<div *ngIf="new_collateral_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="new_collateral_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title tall" role="document">
      <app-newcollateral></app-newcollateral>
    </div>
  </div>
</div>
<div *ngIf="guarantor_contacts_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="guarantor_contacts_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0;padding-left:0;padding-right:0; overflow:hidden; border-radius: 5px;">
      <app-guarantorcontacts [request_id]="parentRouteId" [contacts]="contacts"></app-guarantorcontacts>
    </div>
  </div>
</div>
<div *ngIf="add_guarantor_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="add_guarantor_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class=" overpanel-content container" role="document"
      style="text-align:left; padding-top:0;padding-left:0;padding-right:0; overflow:hidden; border-radius: 5px;">
      <app-add-guarantor [parentRouteID]="parentRouteId"></app-add-guarantor>
    </div>
  </div>
</div>
<div *ngIf="open_portfolio_target_modal">
  <div class="overlay_ app_overlay animated" style="overflow:auto;background:#fff;">

    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="open_portfolio_target_modal=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title tall" role="document">
      <app-portfoliotargetmodal></app-portfoliotargetmodal>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="overlayModalSection==='specific_analytics'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn tall wide" role="document">
    <app-runselectedanalyticsmodal (closeSelectedAnalyticsModal)="overlayModalSection=''" [loan]="loan"
      [parentRouteId]="request_id" [token]="currentUser.token" [analytics_data_status]="analytics_data_status">
    </app-runselectedanalyticsmodal>
  </div>
</div>
<div class="overlay_ app_overlay" *ngIf="action == '1'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeChildModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn floating-title confirmation tall" role="document">
    <h3 class="title flex-column flex-center align-start">Take Ownership<br>
      <small>This implies you are the account officer of the customer.</small>
    </h3>
    <div class="padding-regular text-center">
      <div class="flex flex-column align-center">
        <div class="image-avatar large">
          <img class="img-circle"
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{ownershipCustomer?.FILE_NAME | imagenotfound}}">
        </div>
        <p>{{ownershipCustomer?.LEGAL_NAME}}</p>
      </div>
      <label class="form-group">
        <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
        <span class="control-label normal">Also take owner of all previous transactions up to date</span>
      </label>

      <div class="mt-4">
        <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
          (click)="confirmOwner(currentUser,customer)" [disabled]="loading||is_done=='1'"
          [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'"
          class="small">
        </app-button>
      </div>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay" *ngIf="queueing_for_dis">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="queueing_for_dis=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content animated zoomIn floating-title confirmation tall" role="document">
    <h3 class="title flex-column flex-center align-start">Disbursement<br>
      <small>Are you sure you want to queue for disbursement ?</small>
    </h3>
    <div class="padding-regular text-center">
      
      <!-- <label class="form-group" *ngIf="loan_bnpl?.LOAN_PRODUCT_ID=='29942'">
        <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
        <span class="control-label normal">Send message to customer</span>
      </label> -->
      <div style="height:300px; overflow:auto">
        <app-fraudtest (updatechecker)="updatechecker($event)" *ngIf="lender?.PEOPLE_ID=='40245'" [type]="'datacheck'" [reqid]="request_id_bnpl"></app-fraudtest>
      </div>
      
      <div class="mt-4">
        <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
          (click)="qdbnpl()" [disabled]="checkerloading||loading||is_done=='1'"
          [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'"
          class="small">
        </app-button>
      </div>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayModalSection == 'approvingbulkdisburse'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <div class="h-group center mb-4 padded">
      <h3 class="title">Bulk Payment Upload</h3>
      <p class="subtitle mb-4">Are you sure you want to approve ?</p>
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="confirmBulkPaymentApproval()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="mt-3">
      </app-button>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="pushing_to_partners">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="pushing_to_partners=!pushing_to_partners">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <div class="h-group center mb-4 padded">
      <h3 class="title">Are you sure you want to queue for partners</h3>

      <div class="flex mt-4 flex-center">
        <label class="form-group">
          <label class="control-label">Select Partner</label>
          <select class="form-control" name="partner" [(ngModel)]="partner">
              <option value=""></option>
              <option value="40460">Kuda Microfinance Bank</option>
              <option value="40490">Credit Direct Limited</option>
              <option value="40497">Advancly</option>
              <option value="17036">VFD  Microfinance Bank</option>
          </select>
        </label>
      </div>
      
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="queuePart()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="mt-3">
      </app-button>
    </div>
  </div>
</div>
<div *ngIf="schedule_open">
  <div class="overlay_ app_overlay animated fadeIn">
    <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="schedule_open=false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
    <div class="overpanel-content floating-title animated zoomIn long wider" role="document">
      <app-loanschedulemodal [record]="record" [is_partner]="is_partner">
      </app-loanschedulemodal>
    </div>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="unpushing_to_partners">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="unpushing_to_partners=!unpushing_to_partners">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <div class="h-group center mb-4 padded">
      <h3 class="title">Are you sure you want to unqueue for partners</h3>
      
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="unqueuePart()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="mt-3">
      </app-button>
    </div>
  </div>
</div>

<div class="overlay_ app_overlay animated ln_mdl" style="overflow:hidden" *ngIf="action == '2'">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="closeChildModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div class="overpanel-content floating-title tall" role="document">

    <app-accountofficer [ownershipCustomer]="ownershipCustomer"></app-accountofficer>
    <!-- <div class="padding-regular text-center">
      <h3 class="title">Change Ownership<br>
        <small>This implies you are changing the account officer of the customer.</small>
      </h3>

      <div class="flex w-75 mx-auto">
        <div class="flex-1 flex-center">
          <div class="image-avatar medium">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507989748/{{ownershipCustomer?.accountofficer?.ACCOUNT_OFF_PHOTO | imagenotfound}}">
          </div>
          <h5>{{ownershipCustomer?.accountofficer?.ACCOUNT_OFF}}</h5>
        </div>
        <div class="flex-1 flex-center flex-column">
          <div class="image-avatar medium">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{ownershipCustomer?.profile_pic | imagenotfound}}">
          </div>
          <h5>{{ownershipCustomer?.legal_name}}</h5>
        </div>
      </div>
      <div class="flex mt-4 flex-center">
        <label class="form-group">
          <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
          <span class="control-label normal">Also take owner of all previous transactions up to date</span>
        </label>
      </div>
      <app-empsearch (lenderChosen)="chooseLender($event)"></app-empsearch>

      <div class="mt-4">
        <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}"
          (click)="confirmChangeOwner(currentUser,customer)" [disabled]="loading||is_done=='1'||!chosen_lender"
          [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'"
          class="small">
        </app-button>
      </div>
    </div> -->
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="posting_bulk_payments">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_bulk_payments=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-postingbulkpayments></app-postingbulkpayments>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="posting_upload_payment">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_upload_payment=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated wide zoomIn tall" role="document">
    <app-uploadpayments></app-uploadpayments>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="posting_break_loans">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="posting_break_loans=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-postingbreakloans></app-postingbreakloans>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="invitingCustomer">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="invitingCustomer=!invitingCustomer">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content no-padding tall" role="document">
    <app-otherheader [text]="text"></app-otherheader>
    <app-invitingnewcustomer [currentUser]='currentUser'></app-invitingnewcustomer>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="invitingMultipleCustomer">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="invitingMultipleCustomer=!invitingMultipleCustomer">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content wider" role="document">
    <app-invitemultiplecustomers [currentUser]='currentUser'></app-invitemultiplecustomers>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="invitingMultipleGuarantors">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button"
      (click)="invitingMultipleGuarantors=!invitingMultipleGuarantors">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content wider" role="document">
    <app-inviteguarantors [currentUser]='currentUser'></app-inviteguarantors>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="download_disbursement">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="download_disbursement=!download_disbursement">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title tall wide" role="document">
    <app-bulkdisbursementdownload [disbursements]="disbursements" [currentUser]='currentUser'>
    </app-bulkdisbursementdownload>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="showingUnderConstruction">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="showingUnderConstruction=!showingUnderConstruction">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title tall wide" role="document">
    <app-underconstruction [endDate]='endDate'></app-underconstruction>
  </div>
</div>

<!-- <div class="overlay_ app_overlay animated" *ngIf="overLayModalSection == 'cardsopen'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overLayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title" role="document">
    <app-cardmodal [from]="1" [customer_id]="userId" [token]="currentUser.token"></app-cardmodal>
  </div>
</div>

<div class="overlay_ app_overlay animated" *ngIf="overLayModalSection == 'accountsopen'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overLayModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title" role="document">
    <app-accountmodal [from]="1" [customer_id]="userId" [token]="currentUser.token"></app-accountmodal>
  </div>
</div> -->
<div class="overlay_ app_overlay animated fadeIn" *ngIf="updatingApprovalLevels">

  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="updatingApprovalLevels=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class=" overpanel-content floating-title animated zoomIn" role="document" style="overflow-y: auto;">
    <app-updatingapprovallevel [APPROVAL_LEVEL_ID_]="APPROVAL_LEVEL_ID_"></app-updatingapprovallevel>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="sending_email">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="sending_email=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-emailsender [is_multiple]="is_multiple" [userId]="userId" [customer]="customerdetail" [from_where]='1'>
    </app-emailsender>
  </div>
</div>
<div class="overlay_ app_overlay animated" *ngIf="sending_sms">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="sending_sms=false">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content floating-title animated zoomIn wider" role="document">
    <app-smssender [is_multiple]="is_multiple" [userId]="userId" [customer]="customerdetail" [from_where]='1'>
    </app-smssender>
  </div>
</div>
<div class="overlay_ app_overlay animated fadeIn" *ngIf="overlayEditModalSection == 'requesting_data'">
  <div class="col-md-12">
    <a class="dismiss cancel_treat_ close" role="button" (click)="overlayEditModalSection=''">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>
  <div class="overpanel-content animated zoomIn short" role="document">
    <!-- <div class="h-group center mb-4 padded">
      <h3 class="title">Requesting {{requestingData?.section}}</h3>
      <p class="subtitle mb-4">An email will be sent to the customer.</p>
      <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="sendRequestForData()"
        [disabled]="loading||is_done=='1'" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
        [loading]="loading && is_done=='0'" buttonclass="small mt-3 mr-1">
      </app-button>
      <app-linkgenerator [link_type]='requestingData.link_type' [request_id]='loan?.request_id'
        (linkGenerated)="linkGenerated($event)"></app-linkgenerator>
      <div class="form-group mt-2">
        <p>{{link}}</p>
      </div>
    </div> -->
    <app-requestingdata [customerdetail]="customerdetail"  [priority]="priority" [requestingData]="requestingData">
    </app-requestingdata>
  </div>
</div>
<app-homecustomersideview *ngIf="drawer" (close)="closeDrawer()" [requestId]="activeRequestId" [loan]="loan"
  (openModal)="openModal($event)">
</app-homecustomersideview>
<app-createanewcustomer *ngIf="drawer_customer" (close)="closeDrawer()" >
</app-createanewcustomer>
