<div class="cont">
  <div class="sect-wrap">
    <div class="align-start flex-center flex-column left-container">
      <div class="onb-title">
        <h3>Direct Sales Agent Management</h3>
      </div>
      <div class="onb-content">
        <p>Monitor and manage your staffs from anywhere.</p>
        <p>Simply get them to download the app and start registring new customers on the move. Its that easy</p>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container text-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/sales.svg" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
