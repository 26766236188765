import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from '../../../_services/constants.service';
import {DataService, OptionsserviceService, LoansService, OperationsService, AuthenticationService} from '../../../_services/index';
@Component({
  selector: 'app-cardmodal',
  templateUrl: './cardmodal.component.html',
  styleUrls: ['./cardmodal.component.css']
})
export class CardmodalComponent implements OnInit {
  sending_account_request = false;
  is_done = '0';
  @Input('request_id') request_id: any;
  @Input('token') token: any;
  @Input('from') from = '0';
  @Input('customer_id') customer_id: any;
  cards: any = [];
  card_count: any;
  bank: any;
  brand: any;
  bin: any;
  expiry_month: any;
  expiry_year: any;
  default: any;
  masterClosed = '0';
  slaveOpen = '0';
  loading = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  link:any;
  @Output() close = new EventEmitter();
  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    public operationsService: OperationsService,
    private dataService: DataService,
    private authService: AuthenticationService
  ) {
  }
  openCardIframe(){
    this.close.emit();
  }
  ngOnInit() {
    return this.getCards();
  }
  linkGenerated(data){ 
    this.link = data.url;
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  openSlave(REQUEST_ID) {

    this.masterClosed = REQUEST_ID;
    this.slaveOpen = REQUEST_ID;
  }
  getCards() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/cards`, {
      request_id: this.request_id,
      token: this.token,
      from: this.from,
      customer_id: this.customer_id
    }).subscribe(response => {
      this.loading = false;
      this.cards = response.data;
      this.card_count = response.count;
    }, error => {
      //console.log(error);
    })
  }
  deleteCard(account_card_id, loan_id) {
    this.loading = true;
    this.operationsService.deleteConnectedCard(this.token, account_card_id, loan_id)
      .subscribe(status => {
        this.loading = false;
        this.getCards();
      });
  }
  setAsConnectedCard(loan_id, account_card_id) {
    this.loading = true;
    this.operationsService.setAsConnectedCard(this.token, account_card_id, loan_id)
      .subscribe(status => {
        this.loading = false;
        this.getCards();
      });
  }
  requestCard() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendCardRequest(this.token, this.request_id);
  }
  requestCardClick() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({'location': 'test_mode'});
    } else {
      this.sending_account_request = !this.sending_account_request;
    }
  }
}
