<section [ngClass]="external == true? '': 'panel'" class="">
    <div [ngClass]="external == true? '': 'panel-body padding-regular'" class="">
        <h3 class="section-title mb-5" *ngIf="!external">
            <a [routerLink]="['/customer/', userType, userId ]" class="btn btn-outline-secondary mr-2 btn-sm"> <i
                    class="fa fa-arrow-left"></i></a>
            Account Settings
        </h3>

        <div class="panel overflow-visible mb-1">
            <div class="panel-body">
                <h5 class="section-title mb-3">Suspend Customer
                    <small>Suspend the customer till the selected date</small>
                </h5>
                <!-- <button class="pull-right btn btn-white btn-blue_line wid_20 tbda_" style=" " data-content-id="_gen_statement">Generate</button> -->

                <form #suspendForm="ngForm" novalidate>
                    <div class="form-group">
                        <input type="checkbox" name="IS_SUSPENDED" [(ngModel)]="usersuspend.IS_SUSPENDED">
                        <span class="control-label normal">Enable</span>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" *ngIf="usersuspend.IS_SUSPENDED">
                                <label class="control-label normal">Date</label>
                                <input type="hidden" name="PEOPLE_CUSTOMERS_ID"
                                    [(ngModel)]="usersuspend.PEOPLE_CUSTOMERS_ID">
                                <input type="text" name="TPDATE" class="form-control" [(ngModel)]="usersuspend.TPDATE"
                                    ngui-datetime-picker date-only="true" />
                            </div>
                        </div>
                    </div>
                    <div class="text-left">
                        <app-button type="submit" (click)="suspendCustomer(suspendForm.value, suspendForm.valid)"
                            [disabled]="loading" class="small outline" label="Submit" [loading]="loading">
                        </app-button>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body">
                <h5 class="section-title mb-3">Eligibility
                    <small>Exclude customer from eligibility</small>
                </h5>

                <form #eligibilityForm="ngForm" novalidate>
                    <div class="form-group">
                        <input type="checkbox" name="EXCLUDE_FROM_ELIGIBILITY"
                            [(ngModel)]="userexclude.EXCLUDE_FROM_ELIGIBILITY">
                        <span class="control-label normal">Enable</span>
                    </div>

                    <app-button type="submit" (click)="excludeCustomer(eligibilityForm.value, eligibilityForm.valid)"
                        [disabled]="loading" class="small outline" label="Submit" [loading]="loading">
                    </app-button>
                </form>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body">
                <h5 class="section-title mb-3">Customer Request Limit
                    <small>Set the maximum amount and duration that can be requested</small>
                </h5>
                <form #rlimit="ngForm" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <span class="control-label normal">Minimum Amount</span>
                                <input type="hidden" name="PEOPLE_CUSTOMERS_ID"
                                    [(ngModel)]="request_limit.PEOPLE_CUSTOMERS_ID">
                                <input name="MIN_REQUEST_AMOUNT" [(ngModel)]="request_limit.MIN_REQUEST_AMOUNT"
                                    id="MIN_REQUEST_AMOUNT" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <span class="control-label normal">Maximum Amount</span>
                                <input name="MAX_REQUEST_AMOUNT" [(ngModel)]="request_limit.MAX_REQUEST_AMOUNT"
                                    id="MAX_REQUEST_AMOUNT" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <span class="control-label normal">Minimum Duration</span>
                                <input name="MIN_REQUEST_DURATION" [(ngModel)]="request_limit.MIN_REQUEST_DURATION"
                                    id="MIN_REQUEST_DURATION" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <span class="control-label normal">Maximum Duration</span>
                                <input name="MAX_REQUEST_DURATION" [(ngModel)]="request_limit.MAX_REQUEST_DURATION"
                                    id="MAX_REQUEST_DURATION" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-left mt-2">
                            <app-button type="submit" (click)="saveRequestLimit(rlimit.value, rlimit.valid)"
                                [disabled]="loading" class="small outline" label="Submit" [loading]="loading">
                            </app-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body">
                <h5 class="section-title mb-3">USSD Request Limit
                    <small>Set the maximum amount that can be requested</small>
                </h5>
                <form #ussd="ngForm" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label normal">Maximum Amount</label>
                                <input type="hidden" name="PEOPLE_CUSTOMERS_ID"
                                    [(ngModel)]="userussd.PEOPLE_CUSTOMERS_ID">
                                <input name="MAX_AMOUNT" [(ngModel)]="userussd.MAX_AMOUNT" id="MAX_AMOUNT" type="text"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-left">
                            <app-button type="submit" (click)="saveUSSD(ussd.value, ussd.valid)" [disabled]="loading"
                                class="small outline" label="Submit" [loading]="loading">
                            </app-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body">
                <h5 class="section-title mb-3">USSD Rating</h5>
                <form #rating="ngForm" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label normal">Ratings</label>
                                <input type="hidden" name="PEOPLE_CUSTOMERS_ID"
                                    [(ngModel)]="userratings.PEOPLE_CUSTOMERS_ID">
                                <input name="PEOPLE_RATING_ID" [(ngModel)]="userratings.PEOPLE_RATING_ID"
                                    id="PEOPLE_RATING_ID" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-left">
                            <app-button type="submit" (click)="saveUserRatings(rating.value, rating.valid)"
                                [disabled]="loading" class="small outline" label="Submit" [loading]="loading">
                            </app-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body flex justify-between align-center">

                <h5 class="section-title mb-3">Wallet Statement of Account
                    <small>Generate, send wallet statement of account for customer</small>
                </h5>
                <app-button class="small outline" (click)="generate_statement_account('1')"
                    data-content-id="_gen_statement" label="Generate"></app-button>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body flex justify-between align-center">
                <h5 class="section-title mb-3">Investment Statetemt
                    <small>Generate, send investment statement of account for customer</small>
                </h5>
                <app-button class="small outline" (click)="generate_statement_account('4')"
                    data-content-id="_gen_statement" label="Generate"></app-button>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body flex align-center justify-between">
                <h5 class="section-title mb-3">Loan Statement
                    <small>Generate, send loan statement of account for customer</small>
                </h5>
                <app-button class="small outline" (click)="generate_statement_account('3')"
                    data-content-id="_gen_statement" label="Generate"></app-button>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body flex align-center justify-between">
                <h5 class="section-title mb-3">Register Funds
                    <small>Post, reverse, Make adjustments to Customer ﬁnancial account</small>
                </h5>
                <app-button class="small outline" data-content-id="_register_funds" label="Register"></app-button>
            </div>
        </div>

        <div class="panel mb-1">
            <div class="panel-body flex justify-between align-center">
                <h5 class="section-title mb-3">Reset Credentials
                    <small>Reset credentials and pushes password to the client.</small>
                </h5>
                <app-button class="small outline" data-content-id="_reset_password" label="Reset"></app-button>
            </div>
        </div>
        <div class="panel mb-1">
            <div class="panel-body flex justify-between align-center">
                <h5 class="section-title mb-3">Delete Customer
                    <small>Delete customer information</small>
                </h5>
                <app-button class="small outline" (click)="deleCustomerData()" label="Delete"></app-button>
            </div>
        </div>
    </div>

    <!-- Template: This is specifically testing the popup -->
    <div class="hide" id="_gen_statement">
        <ul class="frm v-wel vcc animated">
            <li class="sub details animated">
                <div class="panel-body">

                    <div style="width:50%; margin:10px auto;">
                        <p style="border-bottom: 1px solid #d9d9d9; padding-bottom: 4px; margin-bottom: 20px"
                            class="blue_c">Please
                            Select Transaction to send Statement for:</p>

                        <div style="border-bottom: 1px solid #d9d9d9; margin-bottom: 10px; padding-bottom: 10px">
                            <label class="radio-inline" style="padding-left:0px; min-width: 50%;">
                                <input type="radio" checked="" name="ASSET_DEPRECIATION_METHOD"
                                    id="ASSET_DEPRECIATION_METHOD" value="1"> Loans
                            </label>
                            <label class="radio-inline" style="padding-left:0; margin-left: 0">
                                <input type="radio" name="ASSET_DEPRECIATION_METHOD" id="ASSET_DEPRECIATION_METHOD_"
                                    value="2"> Investment
                            </label>
                        </div>

                        <div style="border-bottom: 1px solid #d9d9d9 ; padding-bottom: 10px">
                            <select class="form-control" id="ATTACHMENT_TYPE">
                                <option value="0"></option>
                                <option value="1">KYC</option>
                                <option value="2">Underwriting</option>
                                <option value="4">Checklists</option>
                            </select>
                        </div>

                        <div class="form-group" style="padding: 10px 0; border-bottom: 1px solid #d9d9d9">
                            <span class="pull-left text-primary">Current Account Balance</span>
                            <span class="pull-right">234,990</span>
                            <div class="clearfix"></div>
                        </div>

                        <a href="#customers/update_/6757" class="btn btn-block btn-blue"
                            style="padding: 6px 12px; color: #fff">Send
                            Statement</a>
                    </div>

                </div>
            </li>
        </ul>
    </div>

    <!-- Template: Password Update Template-->
    <div class="hide" id="_reset_password">
        <ul class="frm v-wel vcc animated">
            <li class="sub details animated">
                <div class="panel-body">

                    <div style="width:50%; margin:10px auto;">
                        <p style="border-bottom: 1px solid #d9d9d9; padding-bottom: 4px; margin-bottom: 20px"
                            class="blue_c">Kindly
                            Reset the Password.</p>

                        <div class="form-group" style="padding: 10px 0; border-bottom: 1px solid #d9d9d9">
                            <span class="pull-left text-primary">Email</span>
                            <span class="pull-right">femiapps@gmail.com</span>
                            <div class="clearfix"></div>
                        </div>

                        <a href="#customers/update_/6757" class="btn btn-block btn-blue"
                            style="padding: 6px 12px; color: #fff">Reset
                            Password</a>
                    </div>

                </div>
            </li>
        </ul>
    </div>



</section>