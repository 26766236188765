import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-setupdashboard',
  templateUrl: './setupdashboard.component.html',
  styleUrls: ['./setupdashboard.component.css']
})
export class SetupdashboardComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  displayNext() {
    this.router.navigate(['/eligibility']);
  }
}
