<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Loan Report</h3>

      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="6">
                  <div>
                    <label>Product</label>
                    <select (change)="getRecords_()" name="loan_product_id" [(ngModel)]="loan_product_id">
                      <option value=""></option>
                      <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                    </select>
                  </div>
                  <div>
                    <label>Loan Id</label>
                    <input type="text" (keyup.enter)="getRecords_()" name="loan_id" [(ngModel)]="loan_id">
                  </div>
                  <div>
                    <label>Partner</label>
                    <select (change)="getRecords_()" name="general_lender" [(ngModel)]="general_lender">
                      <option value=""></option>
                      <option value="40460">Kuda</option>
                      <option value="40490">CDL</option>
                    </select>
                  </div>
                  <!-- <select name="loan_id" [(ngModel)]="loan_id">
                    <option value=""></option>
                    <option *ngFor="let p of data" value="{{p.loan_id}}">
                      {{p.loan_id}}-{{p.legal_name}}-{{p.request_principal}}</option>
                  </select> -->
                  <div class="pull-right"> 
                      <i *ngIf="loading" class="fa fa-spin fa-spinner"></i>
                       
                  </div>
                </td>
              </tr>

            </thead>
            <thead>
              <th>Num Loans Released</th>
              <th>Principal Released</th>
              <th></th>
              <th>Principal</th>
              <th>Interest</th>
              <th>Total</th>
            </thead>

            <tbody *ngFor="let record of data">
              <tr style="background-color: #dcdfdf;">
                <td colspan="7">
                  {{record.loan_id}}-{{record.legal_name}}-{{record.loan_title}}-{{record.disbursement_date}}
                  <button class="btn btn-xs pull-right" (click)="loadRequest(record)">Open Request</button>
                  <button class="btn btn-xs pull-right" (click)="openSchedule(record)">Schedule</button>
                  <button class="btn btn-xs pull-right" (click)="partnerSchedule(record)">Partner Schedule</button>
                  <button class="btn btn-xs pull-right" (click)="updateSch(record)">Update Schedule</button>
                  <a class="btn  btn-xs  pull-right"
                href="https://creditclanapis.creditclan.com/api/v2/services/downloadkudacontract?token={{record.request_id}}"
                target="_blank">Download Offer Letter</a>
                </td>
              </tr>
              <!-- <tr>
                <td>1</td>
                <td>{{record.request_principal|number:'.2-2'}}</td>
                <td>Due Loans</td>
                <td>{{(+record.principal_due||0)}}</td>
                <td>{{(+record.interest_due||0)}}</td>
                <td>{{((+record.principal_due||0)+(+record.interest_due||0))}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Payments</td>
                <td>{{record.principal_paid==null?0:record.principal_paid}}</td>
                <td>{{record.interest_paid==null?0:record.interest_paid}}</td>
                <td>{{record.how_much_paid==null?0:record.how_much_paid}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Net Due</td>
                <td>{{record.principal_net_due==null?0:record.principal_net_due}}</td>
                <td>{{record.interest_net_due==null?0:record.interest_net_due}}</td>
                <td>{{record.total_net_due==null?0:record.total_net_due}}</td>
              </tr> -->
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecords()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-portfolio-sideview *ngIf="modal!=''" (close)="closeDrawer()" [requestId]="activeRequestId" [loan]="loan"
                        [repayment]="repayment">
</app-portfolio-sideview>
