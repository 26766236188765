<div class="padding-regular">
  <div class="section-title mb-4">Area of focus</div>
  <ul class="decList bigger">
    <li>
      <span>Bank statement</span>
      <span>
        <div class="switch-wrap">
          <input name="showHelp" type="checkbox" id="bs" /><label for="bs"></label>
        </div>
      </span>
    </li>
    <li>
      <span>Mobile phone data</span>
      <span>
        <div class="switch-wrap">
          <input name="showHelp" type="checkbox" id="mbmp" /><label for="mbmp"></label>
        </div>
      </span>
    </li>
    <li>
      <span>Public data</span>
      <span>
        <div class="switch-wrap">
          <input name="showHelp" type="checkbox" id="pd" /><label for="pd"></label>
        </div>
      </span>
    </li>
    <li>
      <span>Loan history sources</span>
      <span>
        <div class="switch-wrap">
          <input name="showHelp" type="checkbox" id="lhs" /><label for="lhs"></label>
        </div>
      </span>
    </li>
    <li>
      <span>Social</span>
      <span>
        <div class="switch-wrap">
          <input name="showHelp" type="checkbox" id="social" /><label for="social"></label>
        </div>
      </span>
    </li>
  </ul>
</div>