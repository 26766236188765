import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interestduration'
})
export class InterestdurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == "1") {
      return "Per Day";
    }
    if (value == "2") {
      return "Per Month";
    }
    if (value == "3") {
      return "Per Year";
    }
    if (value == "4") {
      return "Per Week";
    } 
    return 'N/A';
  }

}
