<div class="cont">
  <div class="sect-wrap">
    <div class="align-start flex-center flex-column left-container">
      <div class="onb-title">
        <h3>Address Verification, BVN, NIN, FRSC and other KYC</h3>
      </div>
      <div class="onb-content">
        <p>Verify your customer's addresses in 24 hours. We have patnered with the best agencies for customers' KYC</p>
        <ul class="bullet-list">
          <li>Enter address to verify</li>
          <li>System routes to nearest field agent</li>
          <li>Get GPS, pictures & KYC report in 24 hours</li>
        </ul>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container text-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/address.svg" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
