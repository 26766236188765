<div class="panel padding-regular flex flex-column align-center" *ngIf="view === 'assign'">
  <br><br>
  <div class="image-avatar large mb-5">
    <img src="/assets/images/user.png" alt="">
  </div>

  <div class="mt-5">
    <label class="form-group">
      <input type="radio" name="collection_mode" value="auto">
      <div class="control-label normal">Let creditclan collect for you</div>
    </label>
    <label class="form-group">
      <input type="radio" name="collection_mode" value="manual">
      <div class="control-label normal">Select from your list of agents</div>
    </label>
  </div>

  <div class="mt-5 w-60 mx-auto" style="align-self: stretch;">
    <div class="align-center flex padding-regular-medium panel bg-green-faded">
      <div class="image-avatar mr-2 smaller">
        <img alt="" src="/assets/images/user.png"></div>
      <span class="default-text-size">John doe</span>
    </div>
    <div class="align-center flex padding-regular-medium panel">
      <div class="image-avatar mr-2 smaller">
        <img alt="" src="/assets/images/user.png"></div>
      <span class="default-text-size">John doe</span>
    </div>
  </div>

  <app-button class="small mt-4" label="Assign" (click)="setView('home')"></app-button>
</div>

<div *ngIf="view === 'home'">
  <div class="panel padding-regular flex flex-column align-center">
    <br><br>
    <div class="image-avatar large mb-5">
      <img src="/assets/images/user.png" alt="">
    </div>
    <div class="flex mt-5" style="align-self: stretch;">
      <div class="flex-1 flex-center border-right">
        <div class="h-group text-center">
          <div class="title">N100K</div>
          <div>Total collections</div>
        </div>
      </div>
      <div class="flex-1 flex-center">
        <div class="h-group text-center">
          <div class="title">N1234K</div>
          <div>Total pending</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="panel padding-regular flex justify-between align-center">
      <div class="section-title color-red">Collection history
        <small class="no-margin color-grey">See the history of all collections made</small>
      </div>
      <app-button class="small outline red" label="View"></app-button>
    </div>
    <div class="panel padding-regular flex justify-between align-center" (click)="setView('modify')">
      <div class="section-title color-orange">Update collection mandate
        <small class="no-margin color-grey">Make updates to collection mandate</small>
      </div>
      <app-button class="small outline orange" label="Update"></app-button>
    </div>
    <div class="panel padding-regular flex justify-between align-center" (click)="setView('assign')">
      <div class="section-title color-teal">Reassign agent
        <small class="no-margin color-grey">Assign another agent to this request</small>
      </div>
      <app-button class="small outline teal" label="Reassign"></app-button>
    </div>
  </div>
</div>

<div *ngIf="view === 'modify'">
  <div class="section-title mb-4 cursor-pointer" (click)="setView('home')">
    <i class="fas fa-long-arrow-alt-left mr-3"></i> Back
  </div>
  <div class="panel padding-regular flex flex-column align-center">
    <div class="section-title color-green mb-5">Modify Mandate</div>
    <div class="flex mt-5" style="align-self: stretch;">
      <div class="flex-1 flex-center border-right">
        <div class="h-group text-center">
          <div class="title">N100K</div>
          <div>Total collections</div>
        </div>
      </div>
      <div class="flex-1 flex-center">
        <div class="h-group text-center">
          <div class="title">N123K</div>
          <div>Total pending</div>
        </div>
      </div>
      <div class="flex-1 flex-center">
        <div class="h-group text-center">
          <div class="title">N123K</div>
          <div>Total pending</div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel padding-regular mt-5">
    <form class="w-60 mx-auto">
      <label class="form-group">
        <span class="control-label normal">Full name</span>
        <input type="text" class="form-control">
      </label>

      <app-button class="small mt-4" label="Assign"></app-button>
    </form>
  </div>
</div>