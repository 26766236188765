<div class="help-pop" *ngIf="showHelp">
  <button type="button" class="btn btn-xs btn-outline-secondary help-popover" (click)="openOverlay()">
    <i class="fa fa-question"></i>
  </button>
</div>
<div class="overlay" *ngIf="showHelp && showOverlay">
  <div class="content flex" [ngClass]="{'flex-column flex-center text-center': center}">
    <div class="left flex-1 flex-center" [ngClass]="{'mb-4': center}">
      <img [src]="content[index].img || 'assets/images/help.svg'" alt="Description" appLazyload>
    </div>
    <div class="right flex-2 flex flex-column flex-center" [ngClass]="{'align-start': !center}">
      <h4 class="section-title mb-2">{{ content[index].title }}</h4>
      <div [innerHTML]="content[index].text" [ngClass]="{'w-75 mx-auto': center}"></div>
    </div>
  </div>

  <button type="button" class="h-close h-button border-red color-red" (click)="closeOverlay()">
    <i class="fa fa-times"></i>
  </button>

  <div class="h-buttons">
    <button [disabled]="index < 1" (click)="previous()" class="h-button left">
      <i class="fa fa-chevron-left"></i>
    </button>
    <button [disabled]="index >= content.length - 1" (click)="next()" class="h-button right">
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</div>
<div class="extra" *ngIf="showHelp && showOverlay"></div>