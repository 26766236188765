<div class="padding-regular text-center">
  <h4 class="title mb-5">Change Ownership<br>
    <small>This implies you are changing the account officer of the customer.</small>
  </h4>

  <div class="flex w-75 mx-auto">
    <div class="flex-1 flex-center">
      <div class="image-avatar medium">
        <img
          src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507989748/avatar-mini.jpg">
      </div>
      <h5>{{ownershipCustomer?.accountofficer?.ACCOUNT_OFF}}</h5>
    </div>
    <div class="flex-1 flex-center flex-column">
      <div class="image-avatar medium">
        <img
          src="{{ownershipCustomer?.profile_pic | imagenotfound}}">
      </div>
      <h5>{{ownershipCustomer?.legal_name}}</h5>
    </div>
  </div>
  <div class="flex mt-4 flex-center">
    <label class="form-group">
      <input type="checkbox" name="TRANSFER_ALL_ACCOUNT" [(ngModel)]="TRANSFER_ALL_ACCOUNT">
      <span class="control-label normal">Also take owner of all previous transactions up to date</span>
    </label>
  </div>
  <app-empsearch (lenderChosen)="chooseLender($event)"></app-empsearch>

  <div class="mt-4">
    <app-button [ngClass]="{'danger': is_done=='0', 'success': is_done=='1'}" (click)="confirmChangeOwner()"
      [disabled]="loading||is_done=='1'||!chosen_lender" [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'"
      [loading]="loading && is_done=='0'" class="small">
    </app-button>
  </div>
</div>