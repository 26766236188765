<div class="cont">
  <div class="sect-wrap">
    <div class="align-start flex-center flex-column left-container">
      <h2 class="onb-title">Mobile Apps, USSD, etc</h2>
      <div class="onb-content">
        <p>Receive loan requests from Mobile apps, USSD, Facebook messenger, Whatsapp, Telegram, everywhere. We have
          every feature to ensure that your lending back office operations is efficient</p>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container flex-center">
      <div class="mobile-phones">
        <div class="wrap">
          <img style="right: -50px;position: relative;" src="assets/images/ussd.png" alt="Illustration">
          <img src="assets/images/app.png" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
