import { Component, OnInit, Input , Output, EventEmitter } from '@angular/core'; 
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from '../../_services/constants.service';
import { ToastrService } from 'ngx-toastr';
import {OperationsService, CustomerService, StorageService, DataService, AuthenticationService, LoansService} from '../../_services/index';


@Component({
  selector: 'app-linkgenerator',
  templateUrl: './linkgenerator.component.html',
  styleUrls: ['./linkgenerator.component.css']
})
export class LinkgeneratorComponent implements OnInit {
  @Input('link_type') link_type:any;
  @Input('request_id') request_id:any;
  @Input('people_id') people_id:any;
  @Input('disabled') disabled = false;
  loading = false;
  @Output() linkGenerated = new EventEmitter();
  @Input('guarantors_request') guarantors_request:any;
  @Input('other_docs') other_docs:any;
  @Input('priority') priority = 'loan';
  currentUser: any;
  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private operationsService: OperationsService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private dataService: DataService,
    private authService: AuthenticationService,
    private loansService: LoansService,
    public toastr: ToastrService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  generateBVNLink(bvn_details){
    this.loading = true;
    if(this.priority=='customer'){
      this.loansService.generateBVNLinkForCustomer(this.currentUser.token, this.people_id, this.link_type)
      .subscribe(data => {
        this.loading = false;
        if(data.status == true){
          this.linkGenerated.emit(data);
        }else{
          this.showError(data.message)
        }
    });
    }else{

      this.loansService.generateBVNLink(this.currentUser.token, this.request_id, this.link_type)
      .subscribe(data => {
        this.loading = false;
        if(data.status == true){
          this.linkGenerated.emit(data);
        }else{
          this.showError(data.message)
        }
    });

    }
   
  }
  generateGuarantorLink(){
    this.loading = true;
    this.loansService.generateGuarantorLink(this.currentUser.token, this.request_id, this.guarantors_request)
      .subscribe(data => {
        this.loading = false;
        if(data.status == true){
          this.linkGenerated.emit(data);
        }else{
          this.showError(data.message)
        }
    });
  }
  generateOtherDocsLink(){
    this.loading = true;
    this.loansService.generateLinkForOtherDocuments(this.currentUser.token, this.request_id, this.other_docs)
      .subscribe(data => {
        this.loading = false;
        if(data.status == true){
          this.linkGenerated.emit(data);
        }else{
          this.showError(data.message)
        }
    });
  }
}
