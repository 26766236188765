import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../_services/data.service';
import { StorageService } from 'src/app/_services';

@Component({
  selector: 'app-customeritem',
  templateUrl: './customeritem.component.html',
  styleUrls: ['./customeritem.component.css']
})
export class CustomeritemComponent implements OnInit {

  @Input('request') request: any;
  @Input('from_loan') from_loan = true;
  @Output() loadRequest = new EventEmitter();
  @Output() loadPortfolio = new EventEmitter();
  @Input('loading') loading = false;
  @Input('active') active = false;
  request_loading = false;
  currentUser: any;
  showoff = false;
  constructor(private dataService: DataService, private storageService: StorageService) {
    this.dataService.onRequestLoadingFromBackend.subscribe(res => {
      this.loading = false;
    });
    this.dataService.onRequestLoadingEndFromBackend.subscribe(res => {
      this.loading = false;
    });
    this.currentUser = this.storageService.read<any>('currentUser');
    this.showoff = this.currentUser.showoff ? this.currentUser.showoff : false;
  }

  ngOnInit() {
  }

  openLoan(request) {
    this.loadRequest.emit({ request: request });
    this.loadPortfolio.emit({ request: request });
  }
}
