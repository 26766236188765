import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoansService, StorageService, DataService } from 'src/app/_services';

@Component({
  selector: 'app-sms-transactions-analysis',
  templateUrl: './sms-transactions-analysis.component.html',
  styleUrls: ['./sms-transactions-analysis.component.css']
})
export class SmsTransactionsAnalysisComponent implements OnInit {

  @Input() reqid = '';
  @Output() openMore = new EventEmitter();
  view = 'list';
  currentUser: any;
  loading = false;
  statements: Array<any> = [];
  loan: any;
  statement: any;
  checking = [];
  viewing_transactions = false;
  constructor(
    public loansService: LoansService,
    public storageService: StorageService,
    public dataService: DataService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.analysisComplete.subscribe(res => {
      this.getAllSMSTransactionAnalysis();
    });
  }

  ngOnInit() {
    this.getAllSMSTransactionAnalysis();
  }

  runAnalytics(statement) {
    this.statement = statement;
    this.viewing_transactions = false;
  }

  viewTransactions(statement) {
    this.statement = statement;
    this.viewing_transactions = true;
  }

  viewResult(statement) {
    this.statement = statement;
    this.view = 'result';
  }

  done(statements) {
    this.getAllSMSTransactionAnalysis();
  }

  cancel() {
    this.view = 'list';
    this.statement = null;
    this.viewing_transactions = false;
  }

  checkAnalytics(statement) {
    this.checking = [...this.checking, statement.ATTACHMENT_ID];
    this.loansService.checkBankStatementAnalysisStatus(this.currentUser.token, statement)
      .subscribe(data => {
        console.log(data);
        this.checking = this.checking.filter(s => s.ATTACHMENT_ID === statement.ATTACHMENT_ID);
      });
  }

  getAllSMSTransactionAnalysis() {
    this.loading = true;
    this.loansService.getAllSMSTransactionAnalysis(this.currentUser.token, this.reqid)
      .subscribe(({ data: statements, loan }) => {
        this.loading = false;
        this.statements = statements;
        this.loan = loan;
      });
  }
}
