import { Component, OnInit, OnDestroy, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, OptionsserviceService, LoansService, StorageService, AuthenticationService, OperationsService } from '../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';  
import { HttpClient } from '@angular/common/http';   
import { ConstantsService } from '../../_services/constants.service';

@Component({
  selector: 'app-bankstatementsettings',
  templateUrl: './bankstatementsettings.component.html',
  styleUrls: ['./bankstatementsettings.component.css']
})
export class BankstatementsettingsComponent implements OnInit {

  @Input('parentRouteId') parentRouteId: number;
  @Input('sub') sub: any;
  @Input('sub_summary') sub_summary: any;
  @Input('where') where = '1';
  @Input('customer_id') customer_id = '0';
  @Input('from') from = '0';
  @Input('token') token: any;
  view = 'attach';
  attachments = [];
  currentUser: any;
  description = "";
  file_field: any;
  loading = false;
  file_type = '2';
  photoHasBeenUploaded = false;
  fileHasBeenUploaded = false;
  doc_types: any;
  DOCTYPE: any;
  @ViewChild('file') file;
  filesUploaded = [];
  public files: Set<File> = new Set();
  uploadingfile = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  photos = [];
  @Output() closed  = new EventEmitter();
  @Input('self') self=true;
  accounts_added = '0';
  accounts:any;
  userId : any;
  account_state = 'start';
  mbssettings = {
    "client_id":"",
    "client_secret":""
  }
  
  @Output() openMore = new EventEmitter();
  constructor(private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    private authService: AuthenticationService,
    private operationService: OperationsService,
    private constant: ConstantsService,
    private toastr: ToastrService,public loansService: LoansService) {
      this.currentUser = this.storageService.read<any>('currentUser');
  }
  fileUploadeds(event) {
    //console.log(event)
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    console.log(this.where)
    if(this.where !='4'){
      this.loadRecords()
    }else{
      this.getAccounts();
    }
    
  }
  getAccounts() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/accounts`, {
      request_id: this.parentRouteId,
      token: this.currentUser.token,from: this.from,
      customer_id: this.customer_id
    }).subscribe(response => {
      this.loading = false;
      this.accounts_added = response.count;
      this.accounts = response.data;
      this.userId = response.user_id;
      this.account_state = 'start';
    }, error => {
      //console.log(error);
    });
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  addingphoto() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.file_type = '2';
      this.open('photo_form');
      this.filesUploaded = [];
    }
  }
  saveFines(value,valid){
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    this.operationService.saveMBSSettings(this.currentUser.token, value)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          if(this.self){
            this.dataService.analysisComplete.emit();
            this.openMore.emit('bankstatement');
            this.showSuccess(result.message);
            
          //  this.closed.emit();

          }else{
            this.showSuccess(result.message);
          }
          
        }else{
          this.showError('Error processing request');
        }
      });
  }
  sendRequest(){
    // this.currentUser = this.storageService.read<any>('currentUser');
    // this.loading = true;
    // this.loansService.sendRequestToCustomerForStatementAnalysis(this.currentUser.token, this.parentRouteId, this.statement_request)
    //   .subscribe(result => {
    //     this.loading = false;
    //     if(result.status == true){
    //       if(this.self){
    //         this.dataService.analysisComplete.emit();
    //         this.openMore.emit('bankstatement');
    //         this.showSuccess(result.message);
            
    //       //  this.closed.emit();

    //       }else{
    //         this.showSuccess(result.message);
    //       }
          
    //     }else{
    //       this.showError('Error processing request');
    //     }
    //   });
  }
  uploadThePhoto() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    if(this.where=='2'){
      this.loansService.getTheCustomerSMSTransactions(this.currentUser.token, this.parentRouteId, this.DOCTYPE)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          
          this.showSuccess(result.message);
         // this.closed.emit();
        }else{
          this.showError('Error processing request');
        }
      });

    }else{
      this.loansService.runMicroCreditReport(this.currentUser.token, this.parentRouteId, this.DOCTYPE)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          
          this.showSuccess(result.message);
          if(!this.self){
            this.dataService.eligibilitycriteriarefreshed.emit()
          }
        }else{
          this.showError('Error processing request');
        }
      });
    }
    
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  uploadTheFile() {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.loading = true;
    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
          .subscribe(result => {
            this.attachments = result.data.attachments;
            this.doc_types = result.data.doctypes;
            this.open('attach');
            this.loading = false;
          });
      });
    } else {
      this.loansService.uploadTheLoanPhoto(this.currentUser.token, this.parentRouteId, this.filesUploaded, this.DOCTYPE, this.description)
        .subscribe(result => {
          this.attachments = result.data.attachments;
          this.doc_types = result.data.doctypes;
          this.open('attach');
          this.loading = false;
        });
    }
  }
  loadRecords() {
    this.currentUser = this.storageService.read<any>('currentUser');

    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {
        this.parentRouteId = +params["id"];
        this.loansService.getLoanAttachments(this.currentUser.token, this.parentRouteId)
          .subscribe(result => {
            this.attachments = result.data.attachments;
            this.doc_types = result.data.doctypes;

          });
      });
    } else {
      this.loading = true;
      this.loansService.getLoanAttachments_(this.currentUser.token, this.parentRouteId, this.customer_id, this.from)
        .subscribe(result => {
          this.photos = result.data.attachments.filter(attach => attach.FILE_TYPE === '2' && attach.FILE_NAME);
          this.attachments = result.data.attachments.filter(attach => attach.FILE_TYPE === '1' && attach.FILE_NAME);
          this.doc_types = result.data.doctypes;
          this.loading = false;
        });
    }
  }
  onFilesAdded(event) {
    this.uploadingfile = true;
    let files = event.target.files;
    this.loansService.doUpload(files[0])
      .subscribe(result => {
        this.uploadingfile = false;
        if (result.status == true) {
          this.photoHasBeenUploaded = true;
          this.filesUploaded.push(result.result);
        }

      });
  }
  openUploadDialog() {
    this.file.nativeElement.click();
  }
  uploadFile(event) {
    // let files = event.target.files;
    // //this.loansService.uploadFile(files[0],this.description,this.parentRouteId);
    // let uploadFile = files[0];
    // let myUploadItem = new MyUploadItem(uploadFile);
    // myUploadItem.formData = { description: this.description, token: this.currentUser.token, request_id: this.parentRouteId, section: this.file_type };  // (optional) form data can be sent with file
    // this.loading = true;
    // this.uploaderService.onSuccessUpload = (item, response, status, headers) => {
    //   // success callback
    //   this.loading = false;
    // };
    // this.uploaderService.onErrorUpload = (item, response, status, headers) => {
    //   // error callback
    //   this.loading = false;
    // };
    // this.uploaderService.onCompleteUpload = (item, response, status, headers) => {
    //   // complete callback, called regardless of success or failure
    //   this.loading = false;
    //   this.loadRecords();
    //   this.view = "photo"
    // };
    // this.uploaderService.upload(myUploadItem);
  }
  open(section) {
    this.view = section;
    this.description = '';
    this.filesUploaded = [];
  }
  close() {
    this.view = 'photo';
    this.filesUploaded = [];
  }

}

