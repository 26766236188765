<div class="title flex justify-between align-center" *ngIf="!for_advert">
  <span>Welcome Email</span>
  <div class="btn-group">
    <button type="button" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">Select option you want to display</button>
    <ul class="dropdown-menu">
      <app-emailtags (iTAC)="insertTextAtCursor($event)"></app-emailtags>
    </ul>
  </div>
</div>
<div class="title flex justify-between align-center" *ngIf="for_advert">
  <span *ngIf="!for_content">Email Advert Image</span>
  <span *ngIf="for_content">Update Message Content</span>
</div>

<div class="overpanel-scroll" *ngIf="!for_advert">
  <app-email-header-content></app-email-header-content>
  <div class="flex">
    <div class="col-xs-12 col-md-6 pd_15">
      <!-- Email template start here -->
      <div class="right">
        <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
          <tr>
            <td align="center" valign="top">
              <!-- BEGIN TEMPLATE // -->
              <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateBody">
                <tr>
                  <td align="center" valign="top">

                    <!-- BEGIN BODY // -->
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateBody">
                      <tr>


                        <td valign="top" class="bodyContent" mc:edit="body_content">
                          <span class="flex justify-end align-center">
                            <button class="btn btn-outline-info btn-xs" (click)="viewMode = 'subject'">
                              <i class="fa fa-edit mr-1"></i> Edit</button>
                          </span>
                          <div>
                            <div [innerHTML]="this.mailSubjectContent"></div>
                          </div>

                          <div *ngIf="!this.mailSubjectContent" class="linear-background floader">
                            <div class="inter-draw"></div>
                            <div class="inter-crop"></div>
                            <div class="inter-right--top"></div>
                            <div class="inter-right--bottom"></div>
                          </div>
                        </td>
                    </table>
                    <!-- // END BODY -->
                  </td>
                </tr>
              </table>
              <!-- // END TEMPLATE -->
            </td>
          </tr>
          <tr>
            <td align="center" valign="top">
              <!-- BEGIN BODY // -->
              <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateBody">
                <tr>
                  <td valign="top" class="bodyContent" mc:edit="body_content">
                    <span class="flex justify-end align-center">
                      <button class="btn btn-outline-info btn-xs" (click)="viewMode = 'heading'">
                        <i class="fa fa-edit mr-1"></i> Edit</button>
                    </span>
                    <div>
                      <div [innerHTML]="this.mailBodyContent"></div>
                    </div>

                    <div *ngIf="!this.mailBodyContent" class="linear-background floader">
                      <div class="inter-draw"></div>
                      <div class="inter-crop"></div>
                      <div class="inter-right--top"></div>
                      <div class="inter-right--bottom"></div>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- // END BODY -->
            </td>
          </tr>
          <tr>
            <td>
              <div class="static-content">
                <a class="btn btn-primary" href="http://app.creditclan.com/private?token=[LINK] ">Activate Account</a>
              </div>
            </td>
          </tr>

        </table>
      </div>
      <!-- Email template end here -->
      <div *ngIf="editorContent">{{ editorContent }}</div>

    </div>
    <div class="col-xs-6 col-md-6 pd_15">
      <div class="left" *ngIf="viewMode !== ''">
        <div [ngSwitch]="viewMode">
          <div @fade *ngSwitchCase="'subject'">
            <!-- Page setting header division-->
            <div class="page-header setting">
              <div class="top-design__setting">
                <p class="backToSetting" (click)="viewMode = ''">
                  <i class="fa fa-chevron-left"> </i>
                  Back <span class="pull-right" style="font-size: 13px;">Update Subject Content</span>
                </p>
              </div>
              <form [formGroup]="updateSubjectContentForm" (ngSubmit)="onUpdateSubjectContent(this.mailSubjectContent)">
                <app-ngx-editor [config]="editorConfig" [(ngModel)]='this.mailSubjectContent'
                  formControlName="subjectContent" [style]="{height: '250px'}" [required]="true">
                </app-ngx-editor>
                <p>
                  <button style="margin-top: 20px;" [disabled]="!updateSubjectContentForm.valid" type="submit"
                    class="btn btn-primary">Update
                    <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
                  </button>
                </p>
              </form>
            </div>
          </div>
          <div @fade *ngSwitchCase="'heading'">
            <!-- Page setting header division-->
            <div>
              <div class="flex justify-between align-center mb-3">
                <button class="btn btn-outline-secondary btn-xs" (click)="viewMode = ''">Close</button>
                <button class="btn btn-outline-secondary btn-xs">Update Body Content</button>
              </div>
              <form [formGroup]="updateBodyContentForm" (ngSubmit)="onUpdateBodyContent(this.mailBodyContent)">
                <app-ngx-editor [config]="editorConfig" [(ngModel)]='this.mailBodyContent' formControlName="bodyContent"
                  [style]="{height: '250px'}" [required]="true">
                </app-ngx-editor>
                <div class="flex justify-end mt-3">
                  <app-button [disabled]="!updateBodyContentForm.valid" type="submit" class="small" label="Update"
                    [loading]="loading"></app-button>
                </div>
              </form>
            </div>
          </div>
          <div @fade *ngSwitchDefault>
          </div>
        </div>
      </div>
      <div class="large-icon flex-center" *ngIf="viewMode == ''"><i class="fa fa-edit"></i></div>
      <!-- <div  *ngIf="defaultSetting == false" [froalaEditor] [(froalaModel)]="editorContent"></div> -->
    </div>
  </div>
  <app-email-footer-content></app-email-footer-content>
</div>
<div class="overpanel-scroll" *ngIf="for_advert">

  <div class="flex" *ngIf="!for_content">
    <div class="col-xs-12 col-md-12 pd_15">
      <!-- Email template start here -->
      <div>
        <input class="file-upload" (change)="upload($event)" name="logo" type="file" #file accept="image/*" />
        <div class="mb-2" *ngIf="!advert_image"><img (click)="file.click()"
            src="https://via.placeholder.com/728x345.png"></div>
        <div class="mb-2" *ngIf="advert_image"><img (click)="file.click()" [src]="advert_image"></div>
      </div>

    </div>
  </div>
  <div class="flex" *ngIf="for_content">
    <div class="col-xs-12 col-md-12 pd_15">
      <div *ngIf="is_for_email_reminder">
        <form [formGroup]="updateMessageContentForm" (ngSubmit)="updateMessageConent(updateMessageContentForm.value)">
          <div class="form-group row">
            <div class="col-md-12">
              <label class="control-label">Email Subject</label>
              <input type="text" class="form-control" [(ngModel)]="message_subject" formControlName="message_subject">
            </div>
          </div>
          <app-ngx-editor [config]="editorConfig" [(ngModel)]='message_content' formControlName="message_content"
            [style]="{height: '250px'}" [required]="true">
          </app-ngx-editor>
          <div class="flex justify-end mt-3">
            <app-button [disabled]="!updateMessageContentForm.valid" type="submit" class="small" label="Update"
              [loading]="loading"></app-button>
          </div>
        </form>
      </div>
      <div *ngIf="!is_for_email_reminder">
        <form [formGroup]="updateSMSMessageContentForm" (ngSubmit)="updateSMSMessageConent(updateSMSMessageContentForm.value)"> 
          <app-ngx-editor [config]="editorConfig" [(ngModel)]='message_content' formControlName="message_content"
            [style]="{height: '250px'}" [required]="true">
          </app-ngx-editor>
          <div class="flex justify-end mt-3">
            <app-button [disabled]="!updateSMSMessageContentForm.valid" type="submit" class="small" label="Update"
              [loading]="loading"></app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>