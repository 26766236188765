import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-under-contruction',
  templateUrl: './under-contruction.component.html',
  styleUrls: ['./under-contruction.component.css']
})
export class UnderContructionComponent implements OnInit, OnDestroy {

  date = '08-17-2020';
  interval = null;
  timer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  constructor() { }

  ngOnInit() {
    this.countdown(this.date);
    this.interval = setInterval(() => {
      this.countdown(this.date);
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  countdown(date) {
    const future: any = new Date(date);
    const now: any = new Date(Date.now());

    const diff = future - now;

    this.timer.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.timer.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.timer.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.timer.seconds = Math.floor((diff % (1000 * 60)) / 1000);
  }
}
