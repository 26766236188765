import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UserService, OperationsService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../_services/constants.service';

@Component({
  selector: 'app-topupwallet',
  templateUrl: './topupwallet.component.html',
  styleUrls: ['./topupwallet.component.css']
})
export class TopupwalletComponent implements OnInit {
  @Output() cancel_payment = new EventEmitter();
  card: any;
  cards: any;
  loadingData = false;
  has_default_card = false;
  currentUser: any;
  card_count = '0';
  cardAddForm: FormGroup;
  walletTopUpForm: FormGroup;
  loading = false;
  tokennizeComplete = false;
  fundingComplete = false;
  @Input('loan') loan: any;
  @Input('wallet_balance') wallet_balance = '0';
  wallet_funding_status = true;
  result:any;
  constructor(
    private http: HttpClient,
    private constant: ConstantsService, public toastr: ToastrService, private dataService: DataService, public loansService: LoansService,
    public fb: FormBuilder, public operationsService: OperationsService, public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.cardAddForm = fb.group({
      'CARD_NUMBER': [null, Validators.required],
      'CVV': [null, Validators.required],
      'EXPIRY_MONTH': [null, Validators.required],
      'EXPIRY_YEAR': [null, Validators.required],
      'PASSWORD': [null, Validators.required],
    });
    this.walletTopUpForm = fb.group({
      'AMOUNT': [null, Validators.required],
      'PASSWORD': [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getLenderCards();
  }
  getLenderCards() {
    return this.http.post<any>(`${this.constant.read('api_loan')}lender/cards`, {
      token: this.currentUser.token, request_id: this.loan.REQUEST_ID
    }).subscribe(response => {
      this.result = response;
      if (response.status == true) {
        (<FormControl>this.walletTopUpForm.controls['AMOUNT'])
          .setValue(response.amount, { onlySelf: true });
        this.cards = response.data;
        if (response.count > 0) {
          this.cards.forEach(data => {
            this.card = data;
            this.has_default_card = true;
          });
        }
      }else{
        this.wallet_funding_status = false;
      }


    }, error => {
      //console.log(error);
    })
  }
  doTokenizeCard(value) {
    this.loading = true;
    this.operationsService.tokenizeLenderCard(this.currentUser.token, value)
      .subscribe(result => {
        this.loading = false;
        if (result.status == true) {
          this.tokennizeComplete = true;
          this.showSuccess(result.message);
        } else {
          this.showError(result.message);
        }
      });
  }
  doWalletTopup(value) {
    this.loading = true;
    this.operationsService.doWalletTopUp(this.currentUser.token, value, this.card)
      .subscribe(result => {
        this.loading = false;
        if (result.status == true) {
          this.tokennizeComplete = true;
          this.showSuccess(result.message);
        } else {
          this.showError(result.message);
        }
      });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }

}
