<div class="cont">
  <div class="sect-wrap">
    <div class="align-start flex-center flex-column left-container">
      <!-- <h2 class="onb-title">Receive Loan Requests Live from Your Website</h2> -->
      <h2 class="onb-title">Web Origination</h2>
      <div class="onb-content">
        <p>Drive traffic to your website and let us take it from there. Your customers can check rates, submit documents, repay loan, validate BVN, etc from the comfort of your site.</p>
        <p>It doesn't get better than this.</p>
      </div>
      <div class="mt-3">
        <app-button (click)="displayNext()" label="Next"></app-button>
      </div>
    </div>
    <div class="right-container flex-center">
      <div class="mobile-phones">
        <div class="wrap desktop">
          <img src="assets/images/web.png" alt="Illustration">
        </div>
      </div>
    </div>
  </div>
</div>
