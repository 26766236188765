<div class="cont">
  <div class="container">
    <div class="col-md-4 col-md-offset-1">
      <div class="right-container" style="text-align: justify">

        <h2 class="onb-title">Get started with Lending, Book your first loan </h2>
        <div class="onb-content">
          <div class="live__content">
            We are here to ensure your experience is world class. All sorts of settings are available, let's get started
          </div>
        </div>
        <div>
          <button (click)="bookLoan()" class="btn btn-default padded orange-btn wide-btn" style="width: 100%">Book Loan
          </button>
          <div style="font-size: 16px; padding: 8px; text-align: center"><a href="javascript:">Update loan settings</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="left-container text-center">
        <div class="circle__flex-container">
          <div class="circle"><img src="assets/images/user-icon.png" alt="" class="circle__icon"/></div>
          <div class="circle"><img src="assets/images/user_icon.png" alt="" class="circle__icon"/>
          </div>
          <div class="circle" style="border: 1px solid orange ;"><a href="javascript:" data-toggle="modal"
                                                                    data-target="#myModal"
                                                                    style="display: flex; align-items: center"><img
            src="assets/images/plus.png" alt=""
            class="circle__icon"
            style="height: 50px;"></a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->
<div class="modal fade" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-addmember></app-addmember>
      </div>
      <!-- <app-success-modal *ngIf="isdone == '1'"></app-success-modal> -->
    </div>
  </div>
</div>
