import { Component, OnInit, Input } from '@angular/core';
import { OperationsService, StorageService, AnalyticsService } from 'src/app/_services';

@Component({
  selector: 'app-identity-check',
  templateUrl: './identity-check.component.html',
  styleUrls: ['./identity-check.component.css']
})
export class IdentityCheckComponent implements OnInit {
  @Input() checklist;

  ids = [
    {
      name: 'National Identification Number', icon: 'fas fa-address-card color-red', type: 'NIN',
      label: 'Enter National Identification Number'
    },
    {
      name: 'Bank Verification Number', icon: 'fas fa-fingerprint color-teal', type: 'BVN',
      label: 'Enter Bank Verification Number'
    },
    {
      name: 'Tax Identification Number', icon: 'fas fa-id-badge color-red', type: 'TIN',
      label: 'Enter Tax Identification Number'
    },
    {
      name: 'Bank Account Number', icon: 'fas fa-building color-orange', type: 'BANK_ACCOUNT',
      label: 'Enter Bank Account Number'
    },
    {
      name: 'Corporate Affairs Commission', icon: 'fas fa-address-card color-purple', type: 'CAC',
      label: 'Enter Corporate Affairs Commission'
    },
    {
      name: 'National ID Card', icon: 'fas fa-address-card color-green', type: 'NATIONAL_ID',
      label: 'Enter the code on National ID Card'
    },
    {
      name: 'International Passport', icon: 'fas fa-passport color-yellow', type: 'PASSPORT',
      label: 'Enter the code on International Passport'
    },
    {
      name: 'Voters\' Card', icon: 'fas fa-id-badge color-blue', type: 'VOTER_ID',
      label: 'Enter the code on Voters\' Card'
    },
    {
      name: 'Driver\'s License', icon: 'fas fa-id-badge color-orange', type: 'DRIVERS_LICENCE',
      label: 'Enter the code on Driver\s license'
    },
  ]
  view = 'options';
  selected = null;
  id_number: string;
  loading = false;
  authenticating = false;
  loadingHistory = false;
  banks = [];
  code = null;
  currentUser;
  platform;
  token = null;
  data: any;
  history: any = {};
  error: any;
  currentData: any;
  directors: any;
  shareholders: any;


  constructor(
    private operationsService: OperationsService,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.platform = this.storageService.read<any>('platform');
    this.getBanks();
    this.getToken();
  }

  ngOnInit() {
  }

  select(id) {
    this.selected = id;
  }

  deselect() {
    this.id_number = '';
    this.error = null;
    this.selected = null;
  }

  backToIds() {
    this.id_number = '';
    this.error = null;
    this.selected = null;
    this.currentData = null;
    this.directors = null;
    this.shareholders = null;
  }

  getData() {
    this.loadingHistory = true;
    this.analyticsService.getIdChecks(this.token).subscribe(({ data }: any) => {
      this.loadingHistory = false;
      this.history = data.reduce((acc, item) => {
        // acc[item.type] = item.data.FullData;
        acc[item.type.trim()] = acc[item.type.trim()] ? [...acc[item.type.trim()], item.id_number] : [item.id_number];
        return acc;
      }, []);
      this.data = data;
      // console.log(this.history);
    })
  }

  viewHistory(id) {
    const data = this.data.find(item => item.id_number === id).data;
    this.setCurrentData(data.FullData);
  }

  getToken() {
    this.authenticating = true;
    this.analyticsService.getTokenForIdCheck({
      creditclan_id: this.platform.PEOPLE_ID,
      phone: 'N/A',
      email: this.currentUser.email,
      name: this.platform.LEGAL_NAME
    }).subscribe(({ status, token }: any) => {
      if (status) {
        this.authenticating = false;
        this.token = token;
        this.getData();
      }
    })
  }

  runCheck() {
    this.error = null;
    this.loading = true;
    const data: any = { type: this.selected.type, id_number: this.id_number };
    if (this.selected.type === 'BANK_ACCOUNT') data.code = this.code;
    this.analyticsService.runIdCheck(data, this.token).subscribe((res: any) => {
      if (res.status) {
        const fullData = res.data.FullData;
        this.setCurrentData(fullData);
        this.id_number = '';
      } else {
        if (res.message) this.error = res.message;
      }
      this.loading = false;
    }, (err) => {
      this.loading = false;
      console.log('Error..', err);
    });
  }

  setCurrentData(fullData) {
    this.currentData = Object.keys(fullData).reduce((acc, key) => {
      if (fullData[key] && `${fullData[key]}`.length < 100) {
        if (key === 'Directors') this.directors = fullData[key].split(',');
        else if (key === 'Shareholders') this.shareholders = fullData[key].split(',');
        else acc.push({ label: key, value: fullData[key] })
      };
      return acc;
    }, []);
  }

  getBanks() {
    this.operationsService.getNigerianBanks(this.currentUser.token).subscribe(banks => {
      this.banks = banks;
    });
  }
}
