import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {
  Headers,
  Http,
  RequestOptions,
  Response
} from '@angular/http';
import { validateConfig } from '@angular/router/src/config';
import { Observable } from 'rxjs';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  private postsUrl = 'https://creditclanapis.creditclan.com/api/v2/';

  constructor(public http: Http, private loggingService: LoggingService) { }
  filterAllCustomers(token, start, searchTerm, magic_filter, ages, adays, marital_statuses, occupations, sectors, designations, incomes, branches, states  ){
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomers', JSON.stringify({ token: token, start:start, searchTerm:searchTerm, magic_filter: magic_filter, ages: ages, adays: adays, marital_statuses: marital_statuses, occupations: occupations, sectors: sectors, designations: designations, incomes:incomes, branches: branches, states: states }))
      .map((response: Response) => response.json());
  }
  filterBranchCustomers(token, start, searchTerm, magic_filter, ages, adays, marital_statuses, occupations, sectors, designations, incomes, branches, states, branch_id: any){
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomers', JSON.stringify({ token: token, start:start, searchTerm:searchTerm, magic_filter: magic_filter, ages: ages, adays: adays, marital_statuses: marital_statuses, occupations: occupations, sectors: sectors, designations: designations, incomes:incomes, branches: branches, states: states, branch_id }))
      .map((response: Response) => response.json());
  }
  getAllCustomers(token: any, start:any, searchTerm:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomers', JSON.stringify({ token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
  getAllPlatformCustomers(token: any, start:any, searchTerm:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allPlatformCustomers', JSON.stringify({ token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
  getAllServicedCompanyCustomers(token: any, start:any, searchTerm:any, company_id:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/getAllServicedCompanyCustomers', JSON.stringify({ company_id:company_id,token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
  getBranchCustomers(token: any, start:any, searchTerm:any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomers', JSON.stringify({ token: token, start:start, searchTerm:searchTerm,branch_id }))
      .map((response) => response.json());
  }
  getAllCustomersSavings(token: any, start:any, searchTerm:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomersSavings', JSON.stringify({ token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
  getAllCustomersByUser(token: any, start:any, searchTerm:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/getAllCustomersByUser', JSON.stringify({ token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
  getAllCustomersByUserFilter(token: any, start:any, searchTerm:any, filter:any,ages:any , adays:any, marital_statuses:any, 
    occupations:any, sectors:any, designations:any, incomes:any, branches:any, states:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/getAllCustomersByUser', JSON.stringify({ token: token, start:start, searchTerm:searchTerm, filter: filter , ages:ages , adays:adays, marital_statuses:marital_statuses, 
    occupations:occupations, sectors:sectors, designations:designations, incomes:incomes, branches:branches, states:states}))
      .map((response: Response) => response.json());
  }
  getAllCustomersByUserInRequests(token: any, start:any, searchTerm:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/getAllCustomersByUserInRequests', JSON.stringify({ token: token, start:start, searchTerm:searchTerm }))
      .map((response: Response) => response.json());
  }
}
