import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../../../_services/index';
import { Location } from '@angular/common';
import { IonRangeSliderComponent } from 'ng2-ion-range-slider';

@Component({
  selector: 'app-analyticsspiderservice',
  templateUrl: './analyticsspiderservice.component.html',
  styleUrls: ['./analyticsspiderservice.component.css']
})
export class AnalyticsspiderserviceComponent implements OnInit {
  loading = false;
  view: any = 'data';
  title = 'Loan Crawler';
  @Input('details') details: any;
  @Input('token') token: any;
  @Input('parentRouteId') parentRouteId: any;
  @Input('loan') loan: any;
  @Input() showHeader = true;
  selected_analytics = '1';
  nodesandscores: any;
  analytics_result: any;
  sub: any;
  currentUser: any;
  data: any;
  average_monthly_cost = 0;
  average_calls_per_month = 0;
  nodes_and_scores: any;
  analytics_last_done = '';
  view_more: boolean = false;
  coming_soon: boolean = false;
  view_more_pending: boolean = false;
  view_more_closed: boolean = false;
  view_more_regs: boolean = false;
  constructor(public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public dataService: DataService,
    private _location: Location,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    this.getSpiderData();
  }
  getSpiderData() {
    this.loading = true;
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'];
      this.loansService.getSpiderData(this.currentUser.token, this.parentRouteId)
        .subscribe(data => {
          this.details = data.data;
          this.loading = false;
          // console.log(details.loan_performance)
        });
    });
  }

  dataView() {
    this.view = 'data';
  }

  analyticsView() {
    this.view = 'analytics';
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId });
  }
  goBack() {
    this._location.back();
  }
  modifyAnalytics() {
    this.dataService.onEditApplicationsModal.emit({
      section: "specific_analytics",
      request_id: this.parentRouteId,
      nodes_and_scores: this.nodes_and_scores,
      selected_analytics: "4",
      analytics_last_done: this.analytics_last_done,
      isedit: "1"
    });
  }
  viewMore() {
    this.view_more = true;
  }
  viewMorePending() {
    this.view_more_pending = true;
  }
  viewMoreClosed() {
    this.view_more_closed = true;
  }
  viewMoreRegs() {
    this.view_more_regs = true;
  }
  comingSoon() {
    this.coming_soon = true;
  }
}
