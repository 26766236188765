import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noa'
})
export class NoaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == '1') {
      return 'Owned';
    }
    if (value == '2') {
      return 'Rented';
    } 
  }

}
