<div class="side-view animated slideInRight">
  <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
  <div class="side-view-wrap">
    <div class="header" *ngIf="!customer">
      <div class="user">
        <div class="image large skeleton fit-content">
          <img class="image-avatar" src="" />
        </div>
        <div class="content skeleton-children">
          <div class="title mb-1">Lorem ipsum dolor sit lofoe.</div>
          <div class="subtitle mb-1">Lorem ipsum </div>
          <div>Lorem,.</div>
        </div>
      </div>
    </div>

    <div class="header" *ngIf="customer">
      <div class="user">
        <div class="image large cursor-pointer" (click)="child = 'profile-picture'">
          <img class="green" [ngClass]="{'image-avatar': true}"
            src="{{customer?.profile?.profile_pic | imagenotfound}}" />
        </div>
        <div class="content flex-1">
          <div class="title">{{ customer?.profile?.legal_name }}</div>
          <div class="subtitle">
            {{ customer?.profile?.email }}
          </div>
        </div>
        <div class="flex flex-row justify-around" style="align-self: flex-start">
          <app-button class="small outline mr-1" (click)="setChild('liste')" icon="fa fa-list" label="Send Message">
          </app-button>

        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
        Details
      </div>
      <div class="nav-item" (click)="setView('loans')" [ngClass]="{'active': view === 'loans'}">
        Loans
      </div>
      <div class="dropdown">
        <div class="dropdown-toggle nav-item" [ngClass]="{'active': view === 'more'}" id="dropdownMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{{ more }}
          <span class="caret ml-2"></span>
        </div>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li><a (click)="setView('schedule')">Schedule</a></li>
          <li><a (click)="setView('timeline')">Timeline</a></li>
          <li><a (click)="setView('settings')">Settings</a></li>
          <li><a (click)="setView('wallet')">Wallet</a></li>
        </ul>
      </div>
    </div>
    <div class="content skeleton-children fit-content" *ngIf="!customer">
      <div class="fill-w mb-2">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, possimus suscipit illum
        consectetur quibusdam magnam provident tempora ducimus expedita nostrum non aliquid quod earum eos
        dignissimos, recusandae unde sed soluta! recusandae unde sed
        soluta!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
        nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
        Quidem adipisci facilis porro doloremque at
        quos pariatur, illo magnam in minima!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
        nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
        Quidem adipisci facilis porro doloremque at
        quos pariatur, illo magnam in minima!
      </div>
    </div>
    <div class="content" *ngIf="customer && view === 'application'">


      <app-customerinfoonly *ngIf="!loading" [customer]="customer" [is_individual]="is_individual"
        (refresh)="getRequestCustomer(loan)" [reqid]="requestId" [pid]="loan.PEOPLE_ID" [from_loan]="true"
        [show_header]="false" (setMore)="setMore($event)" (openModalChild)="setChild($event)" [embeded]="true"
        [checklist]="checklist" (requestingData)="requestingData($event)" [cid]="loan.people_id">
      </app-customerinfoonly>
    </div>

    <div class="content" *ngIf="loan && view === 'timeline'">
      <app-customer-timeline [external]=true [userId]="loan.PEOPLE_ID"></app-customer-timeline>
    </div>

    <div class="content" *ngIf="loan && view === 'loans'">
      <app-customerotherrequestss [external]=true [userId]="loan.PEOPLE_ID"></app-customerotherrequestss>
    </div>

    <div class="content" *ngIf="loan && view === 'settings'">
      <app-customer-account-settings [external]=true [userId]="loan.PEOPLE_ID"></app-customer-account-settings>
    </div>

    <div class="content" *ngIf="loan && view === 'schedule'">
      <app-customer-schedule [external]=true [userId]="loan.PEOPLE_ID"></app-customer-schedule>
    </div>

    <!-- Child Modals -->
    <div class="overlay" *ngIf="child">
      <button class="closer" (click)="child = ''"><i class="fa fa-times"></i></button>
      <div class="child overflow-visible">

        <div class="grid three mt-4" *ngIf="child === 'liste'">
          <div class="flex-1 flex-center mb-3" (click)="sendSMSToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-mobile"></i></div>
              <div class="text">SMS</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="sendEmailToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-envelope"></i></div>
              <div class="text">EMAIL</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-whatsapp"></i></div>
              <div class="text">WhatsApp</div>
            </div>
          </div>

        </div>
        <div class="panel overflow-visible" *ngIf="child === 'card'"> 
          <app-cardmodal [from]="1" [customer_id]="loan.PEOPLE_ID" [token]="currentUser.token"></app-cardmodal>
        </div>

        <div class="panel overflow-visible" *ngIf="child === 'account'"> 
          <app-accountmodal [from]="1" [customer_id]="loan.PEOPLE_ID" [token]="currentUser.token"></app-accountmodal>
        </div>

        <div class="panel overflow-visible" *ngIf="child === 'bvn'">
            <app-bvnmodal [customer_id]="loan.PEOPLE_ID" [token]="currentUser.token"  [from]="1" [viewOnly]="true"></app-bvnmodal>
        </div>

        <div class="panel overflow-visible" *ngIf="child === 'attachment'">
            <app-loanphotos  [where]='0' [from]="1" [customer_id]="loan.PEOPLE_ID" [token]="currentUser.token"></app-loanphotos>
        </div>


      </div>
    </div>
  </div>
</div>




<!-- Summary Modal -->
<!-- <div class="overlay" *ngIf="details">
          <button class="closer" (click)="toggleDetails()"><i class="fa fa-times"></i></button>
          <div class="dialog panel padding-regular">
              <ul class="decList bigger">
                  <li><span>Reason</span><span>{{ loan.loan_purpose }}</span></li>
                  <li><span>Interest rate</span><span>{{ loan.request_rate }}%</span></li>
                  <li><span>Total fees</span><span>{{ loan.total_fees_and_charges }}</span></li>
                  <li><span>1st repayment</span><span>{{ loan.repayment_started_when }}</span></li>
                  <li><span>Net disbursement</span><span>{{ loan.net_disbursement }}</span></li>
                  <li><span>Officer</span><span><img class="image-avatar" src="assets/images/user.png" /></span></li>
              </ul>
              <app-button class="outline small orange block mb-1 mt-2" label="Edit request"
                  (click)="details = false; child = 'edit'"></app-button>
              <app-button class="outline small block" label="Send offer letter"></app-button>
          </div>
      </div> -->