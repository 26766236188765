<section class="panel" *ngIf="!editing_customer">
        <header class="panel-heading">
            &nbsp;
            <span class="tools pull-right">
                <button class="btn btn-white btn-xs"  (click)="formDone()">Done</button>
          </span>
        </header>
       
      </section>  
<section class="panel">
  <header class="panel-heading" *ngIf="!editing_customer">
    Personal Details
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='profile', 'fa fa-chevron-down':current_view!='profile'}" href="javascript:;"
        (click)="toogleView('profile')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='profile'">
    <form #f="ngForm" (ngSubmit)="updateBasicInfo(f.valid,f.value)" novalidate>
      <div class="form-group row">
        <label class="col-sm-2 control-label col-lg-4">Fullname</label>
        <div class="col-lg-8">
          <input type="text" class="form-control" required name="LEGAL_NAME" [(ngModel)]="customer.personal.LEGAL_NAME">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label col-lg-4">Email</label>
        <div class="col-lg-8">
          <input type="email" class="form-control" required name="EMAIL" [(ngModel)]="customer.personal.EMAIL">
          <input type="hidden" class="form-control" name="CUSTOMER_TYPE" [(ngModel)]="customer.personal.CUSTOMER_TYPE">
          <input type="hidden" class="form-control" name="PROFILE_PHOTO" [(ngModel)]="customer.personal.PROFILE_PHOTO">
          <input type="hidden" name="COUNTRY_CODE" id="COUNTRY_CODE" [(ngModel)]="customer.personal.COUNTRY_CODE" value="+234">
          <input type="hidden" name="PHONE_TYPE" id="PHONE_TYPE" [(ngModel)]="customer.personal.COUNTRY_CODE" value="1">
          <input type="hidden" name="PEOPLE_ID" id="PEOPLE_ID" [(ngModel)]="customer.personal.PEOPLE_ID" value="1">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label col-lg-4">Phone</label>
        <div class="col-lg-8">
          <input type="text" maxlength=11  class="form-control" required name="PHONE" [(ngModel)]="customer.personal.PHONE">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 control-label col-lg-4">Staff Number</label>
        <div class="col-lg-8">
          <input type="text" class="form-control" name="STAFF_NUMBER" [(ngModel)]="customer.personal.STAFF_NUMBER">
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="control-label">Date of Birth</label>
            <input type="text" class="form-control" name="DATE_OF_BIRTH" [(ngModel)]="customer.personal.DATE_OF_BIRTH" ngui-datetime-picker
              date-only="true">
          </div>
        </div>
        <div class="col-md-6">
          <label for="" class="control-label">Gender</label>
          <select class="form-control" name="GENDER" [(ngModel)]="customer.personal.GENDER">
            <option *ngFor="let sex of gender" [value]="sex.value">{{sex.display}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row " style="margin-bottom:5px;">
        <!-- <div class="col-md-5">
          <label for="" class="control-label">Nationality</label>
          <select class="form-control" name="NATIONALITY" [(ngModel)]="customer.personal.nationality">
              <option value="0"></option>
              <option *ngFor="let country of countries" [value]="country.calling_code[0]"> {{country.name['common']}} </option>
          </select>
          <div class="error otp_error alert alert-block alert-danger fade in hide" style="margin:5px 0px !important"> Please select your nationality </div>
      </div> -->
        <div class="col-md-12">
          <label for="" class="control-label">Marital Status</label>
          <select class="form-control" name="MARITAL_STATUS" [(ngModel)]="customer.personal.MARITAL_STATUS">
            <option *ngFor="let status of marital_statuses" [value]="status.value">{{status.display}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="" class="control-label">Means of ID</label>
          <select class="form-control" name="MEANS_OF_ID" [(ngModel)]="customer.personal.MEANS_OF_ID">
            <option *ngFor="let sex of id_means" [value]="sex.value">{{sex.display}}</option>
          </select>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="control-label">ID Number</label>
            <input type="text" class="form-control" name="ID_NUMBER" [(ngModel)]="customer.personal.ID_NUMBER">
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12"><button [disabled]="loading || !f.valid" class="btn btn-white btn-block" type="submit">Submit</button></div>
      </div>
    </form>
  </div>

</section>
<section class="panel" *ngIf="(appsettings?.mobile.accountcard=='2'||appsettings?.mobile.accountcard=='3') && !editing_customer">
    <header class="panel-heading">
      Bank Account (<small>The bank account will be validated</small>)
      <span class="tools pull-right">
        <a [ngClass]="{'fa fa-chevron-up': current_view=='bank', 'fa fa-chevron-down':current_view!='bank'}" href="javascript:;"
          (click)="toogleView('bank')"></a>
      </span>
    </header>
    <div class="panel-body" *ngIf="current_view=='bank'">
        <form #c="ngForm" (ngSubmit)="updateBank(c.value, c.valid)" novalidate>
      <div class="form-group row">
        <label for="" class="control-label col-md-6">Bank</label>
        <div class="col-md-6 ">
  
          <select id="bank_id" required name="bank_id" class="form-control" [(ngModel)]="customer.account.bank_id">
            <option value="0"></option>
            <option *ngFor="let nb of nigerian_banks" [ngValue]="nb">{{nb.BANK_NAME}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="control-label col-md-6">Account Number</label>
        <div class="col-md-6 ">
  
          <input type="text" maxlength=10 required name="account_number" id="account_number" (blur)="verifyBankDetails()" class="form-control" [(ngModel)]="customer.account.account_number">
        </div>
      </div>
      <div class="form-group row" *ngIf="customer.account.is_verified">
        <div class="col-md-12">
  
          <input type="text" [disabled]=true name="account_name" id="account_name" class="form-control" [(ngModel)]="customer.account.account_name">
          <div class="checkbox">
            <input [(ngModel)]="customer.account.i_certify" name="i_certify" type="checkbox"> I certify the account to be valid</div>
        </div>
      </div>
      <div class="form-group row" *ngIf="verify_error">
        <div class="col-md-12">
          <p><i>Unable to verify account</i></p>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12"><button [disabled]="loading || !c.valid || !customer.account.is_verified" class="btn btn-white btn-block" type="submit">Submit <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button></div>
        </div>
      </form>
    </div>
  </section>
<section class="panel" *ngIf="appsettings?.mobile.nextofkin && !editing_customer">
  <header class="panel-heading">
    Next of Kin
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='nok', 'fa fa-chevron-down':current_view!='nok'}" href="javascript:;" (click)="toogleView('nok')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='nok'">
      <form #a="ngForm" (ngSubmit)="updateNok(a.value, a.valid)" novalidate>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Fullname</label>
      <div class="col-lg-8">
        <input type="text" class="form-control" required name="nok_name" [(ngModel)]="customer.nok.nok_name">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Email</label>
      <div class="col-lg-8">
        <input type="text" class="form-control" name="nok_email" [(ngModel)]="customer.nok.nok_email">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Phone</label>
      <div class="col-lg-8">
        <input type="text" class="form-control" required name="nok_phone" [(ngModel)]="customer.nok.nok_phone">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="control-label">Address</label>
          <input type="text" class="form-control" name="nok_address" [(ngModel)]="customer.nok.nok_address">
        </div>
      </div>
      <div class="col-md-6">
        <label for="" class="control-label">Relationship</label>
        <select class="form-control" name="nok_relationship" [(ngModel)]="customer.nok.nok_relationship">
          <option *ngFor="let sex of relations" [value]="sex.value">{{sex.display}}</option>
        </select>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12"><button [disabled]="loading || !a.valid" class="btn btn-white btn-block" type="submit">Submit  <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button></div>
      </div>
    </form>
  </div>
</section>
<section class="panel" *ngIf="appsettings?.mobile.education && !editing_customer">
  <header class="panel-heading">
    Education
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='edu', 'fa fa-chevron-down':current_view!='edu'}" href="javascript:;" (click)="toogleView('edu')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='edu'">
      <form #dc="ngForm" (ngSubmit)="updateEdu(dc.value, dc.valid)" novalidate>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Institution Attended</label>
      <div class="col-lg-8">
        <input type="text" class="form-control" name="education_institution" [(ngModel)]="customer.education.education_institution">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Year</label>
      <div class="col-lg-8">
        <input type="text" class="form-control" name="education_year" [(ngModel)]="customer.education.education_year">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label col-lg-4">Highest Qualification</label>
      <div class="col-lg-8">
        <select class="form-control" name="education_qualification" [(ngModel)]="customer.education.education_qualification">
          <option *ngFor="let sex of edu_qua" [value]="sex.value">{{sex.display}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">

      <div class="col-lg-12">
        <label for="" class="control-label">Others</label>
        <textarea class="form-control" name="education_others" [(ngModel)]="customer.education.education_others"> 
                </textarea>

      </div>
    </div>
    <div class="row">
        <div class="col-md-12"><button [disabled]="loading || !dc.valid" class="btn btn-white btn-block" type="submit">Submit <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button></div>
      </div>
      </form>
  </div>
</section>
<section class="panel" *ngIf="appsettings?.mobile.homeaddress && !editing_customer">
  <header class="panel-heading">
    Address Details
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='address', 'fa fa-chevron-down':current_view!='address'}" href="javascript:;"
        (click)="toogleView('address')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='address'">
      <form #b="ngForm" (ngSubmit)="updateAddress(b.value, b.valid)" novalidate>

<!-- <div class="form-group row">
        <label class="control-label col-md-4" style="padding:0;">
            Country
        </label>
      <div class="col-md-8"> 
          
          <select id="country_id" name="country_id" class="form-control" [(ngModel)]="customer.address.country_id">
              <option value="">select a country</option>
              <option *ngFor="let country of countries" [value]="country.callingCode[0]"> {{country?.name['common']}} </option>
          </select>
      </div>
  </div> -->
  <div class="form-group row">
      <label class="control-label col-md-4">
        State
      </label>
      <div class="col-md-8">
        <select id="state_id" name="state_id" required class="form-control" [(ngModel)]="customer.address.state_id">
          <option value="">Select an option</option>
          <option value="1">Abia </option>
          <option value="2">Adamawa </option>
          <option value="3">Akwa Ibom </option>
          <option value="4">Anambra </option>
          <option value="5">Bauchi </option>
          <option value="6">Bayelsa </option>
          <option value="7">Benue </option>
          <option value="8">Borno </option>
          <option value="9">Cross River </option>
          <option value="10">Delta </option>
          <option value="11">Ebonyi </option>
          <option value="12">Edo </option>
          <option value="13">Ekiti </option>
          <option value="14">Enugu </option>
          <option value="15">FCT</option>
          <option value="16">Gombe </option>
          <option value="17">Imo </option>
          <option value="18">Jigawa </option>
          <option value="19">Kaduna </option>
          <option value="20">Kano </option>
          <option value="21">Katsina </option>
          <option value="22">Kebbi </option>
          <option value="23">Kogi </option>
          <option value="24">Kwara </option>
          <option value="25">Lagos </option>
          <option value="26">Nasarawa </option>
          <option value="27">Niger </option>
          <option value="28">Ogun </option>
          <option value="29">Ondo </option>
          <option value="30">Osun </option>
          <option value="31">Oyo </option>
          <option value="32">Plateau </option>
          <option value="33">Rivers </option>
          <option value="34">Sokoto </option>
          <option value="35">Taraba </option>
          <option value="36">Yobe </option>
          <option value="37">Zamfara </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-md-4">
        Address
      </label>
      <div class="col-md-8">

        <label class="pull-right item-text-counter2">160</label>
        <textarea id="address" required name="address" class="form-control" [(ngModel)]='customer.address.address'></textarea>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-md-4">
        Landmark
      </label>
      <div class="col-md-8">

        <input class="form-control" id="landmark" name="landmark" [(ngModel)]='customer.address.landmark'>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label class="control-label">
          Nature of Accom.
        </label>
        <select class="form-control"  name="nature_of_accomodation" [(ngModel)]='customer.address.nature_of_accomodation'>
          <option [value]="0">select nature of accomodation</option>
          <option [value]="1">Owned</option>
          <option [value]="2">Rent</option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="control-label">
          Time at Current Address (Years)
        </label>
        <input class="form-control" id="time_at_current_address" name="time_at_current_address" [(ngModel)]='customer.address.time_at_current_address'>
      </div>
    </div>

    <div class="form-group row" *ngIf="customer.address.nature_of_accomodation==='2'">

      <div class="col-md-6">
        <label class="control-label">
          Expiry Month
        </label>
        <select class="form-control" id="month" name="month" [(ngModel)]="customer.address.month">
          <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="control-label">
          Expiry Year
        </label>
        <select class="form-control" id="year" name="year" [(ngModel)]="customer.address.year">
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12"><button [disabled]="loading || !b.valid" class="btn btn-white btn-block" type="submit">Submit  <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button></div>
      </div>
    </form>
    

  </div>
</section>
<section class="panel" *ngIf="appsettings?.mobile.workinfo && !editing_customer">
  <header class="panel-heading">
    Work Details
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='work', 'fa fa-chevron-down':current_view!='work'}" href="javascript:;"
        (click)="toogleView('work')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='work'">
      <form #gf="ngForm" (ngSubmit)="updateWork(gf.value, gf.valid)" novalidate>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="control-label" style="padding:0;">
          <span> Approximate Monthly Income</span>
        </label>
        <div class="">
          <input type="text" name="NET_MONTHLY_INCOME" id="NET_MONTHLY_INCOME" class="form-control" [(ngModel)]="customer.work.NET_MONTHLY_INCOME">

        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12">
        <label class="control-label" style="padding:0;">
          <span> Approximate Gross Monthly Income</span>
        </label>
        <div class="input-group ">
          <input type="text" name="GROSS_ANNUAL_INCOME" id="GROSS_ANNUAL_INCOME" class="form-control" [(ngModel)]="customer.work.GROSS_ANNUAL_INCOME">

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group not_for_un not_for_ret row company ">
          <div class="col-md-12">
            <label class="control-label" style="padding:0;">
              Designation
            </label>
            <select class="form-control" name="DESIGNATION" [(ngModel)]="customer.work.DESIGNATION">
              <option></option>
              <option *ngFor="let key of designations" [value]="key.OCCUPATION_ID"> {{key.DESCRIPTION}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <label for="" class="control-label">Start Month</label>
        <select id="WORK_START_DATE" name="WORK_START_DATE" class="form-control" [(ngModel)]="customer.work.WORK_START_DATE">
          <option value=""></option>
          <!-- <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option> -->
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </div>
      <div class="col-md-6 ">
        <label for="" class="control-label">End Year</label>
        <select id="WORK_END_DATE" name="WORK_END_DATE" class="form-control" [(ngModel)]="customer.work.WORK_END_DATE">
          <option value="0"></option>
          <option *ngFor="let month of months" [value]="month.value">{{month.display}}</option>
        </select>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12"><button [disabled]="loading || !gf.valid" class="btn btn-white btn-block" type="submit">Submit  <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button></div>
      </div>
    </form>
  </div>
</section>
<section class="panel" *ngIf="appsettings?.mobile.checkifuserhasvalidatedbvn && !editing_customer">
  <header class="panel-heading">
    BVN
    <span class="tools pull-right">
      <a [ngClass]="{'fa fa-chevron-up': current_view=='bvn', 'fa fa-chevron-down':current_view!='bvn'}" href="javascript:;" (click)="toogleView('bvn')"></a>
    </span>
  </header>
  <div class="panel-body" *ngIf="current_view=='bvn'">
    <div class="form-group row">
      <label for="" class="control-label col-md-6">BVN Number (<small>The bank account will be validated</small>)</label>
      <div class="col-md-6 ">

        <input type="text" name="bvn_number" id="bvn_number" (blur)="verifyBVNDetails()" class="form-control" [(ngModel)]="customer.bvn.bvn_number">
      </div>
    </div>
    <div class="form-group row" *ngIf="customer.bvn.is_verified">
      <div class="col-md-12">

        <input type="text" [disabled]=true name="bvn_name" id="bvn_name" class="form-control" [(ngModel)]="customer.bvn.bvn_name">
        <div class="checkbox">
          <input [(ngModel)]="customer.bvn.i_certify" name="i_certify" type="checkbox"> I certify the bvn to be valid</div>
      </div>
    </div>
  </div>
</section>