<section>
  <h3 class="title">Top Up Wallet</h3>
  <div class="form-area col-md-6 no-padding">
    <form role="form">
      <div class="form-group">
        <label class="control-label normal">Amount</label>
        <input type="text" [(ngModel)]="wallettopup.AMOUNT" class="form-control" id="AMOUNT" name="AMOUNT">
      </div>
      <div class="form-group">
        <label class="control-label normal">Card Pin</label>
        <input type="password" [(ngModel)]="wallettopup.PIN" class="form-control" id="PIN" name="PIN">
      </div>
      <div class="form-group">
        <label class="control-label normal">Password</label>
        <input type="password" [(ngModel)]="wallettopup.PASSWORD" class="form-control" id="PASSWORD" name="PASSWORD">
      </div>
      <div class="form-group" *ngIf="topupstep=='2'">
        <label class="control-label normal">Token</label>
        <input type="text" [(ngModel)]="wallettopup.TOKEN" class="form-control" id="TOKEN" name="TOKEN">
      </div>
      <div class="mt-4" *ngIf="topupstep=='1'">
        <app-button [disabled]="loading" (click)="confirmWalletTop()" class="small" label="Submit" [loading]="loading">
        </app-button>
        <app-button [disabled]="loading" (click)="cancelAction()" class="small red outline ml-1" label="Cancel">
        </app-button>
      </div>
      <div class="mt-4" *ngIf="topupstep=='2'">
        <app-button [disabled]="loading" (click)="finishtopup()" class="small" label="Confirm" [loading]="loading">
        </app-button>
        <app-button [disabled]="loading" (click)="cancelAction()" class="small red outline ml-1" label="Cancel">
        </app-button>
      </div>
    </form>
  </div>
</section>