<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
    <div class="flex">
      <div class="col-md-12 no-padding">
        <h3>Wema Inflow Repayments</h3>
  
        <div class="panel">
          <div class="panel-body">
            <table class="table table-stripped">
              <thead>
                <tr>
                  <td colspan="6">
                    <div>
                      <label>Date</label>
                      <input type="date" (change)="getRecords()" name="selectedDate" [(ngModel)]="selectedDate">
                         
                    </div>
                     
                    <div class="pull-right"> 
                        <i *ngIf="loading" class="fa fa-spin fa-spinner"></i>
                         
                    </div>
                  </td>
                </tr>
  
              </thead>
              <thead>
                <th>Customer</th>
                <th>Amount</th>
                <th>Account Number</th>
              </thead>
  
              <tbody *ngFor="let record of data">
                <tr style="background-color: #dcdfdf;">
                  <td>{{record.customer}}</td>
                  <td>{{record.amount}}</td>
                  <td>{{record.transaction_payload.craccount}}</td>
                  
                </tr>
                
              </tbody>
  
               
            </table>
          </div>
        </div>
      </div>
    </div>
    
  </div>