<!-- <section class="panel" style="margin-bottom: 10px" *ngIf="where == '1'">
  <header class="panel-heading">
    <span class="tools pull-right">
      <ul class="list-inline">
        <li>
          <a [routerLink]="['../contract']">Summary</a>
        </li>
        <li>
          <a [routerLink]="['../repayments']">Schedule</a>
        </li>
        <li>
          <a [routerLink]="['../more']">More</a>
        </li>
      </ul>
    </span>
    <span>Request Timeline</span>
  </header>
</section> -->
<section class="panel padding-regular timeline-panel">
  <app-help-popover [content]="[
      {
        title: 'Timeline of events',
        img: 'assets/images/timeline.svg',
        text:
          '
  <div>
    <p>.....................................................................................
      .....................................................................................
    </p>
  <div>'
      }
    ]"></app-help-popover>
  <div class="flex justify-between align-center mb-5">
    <h3 class="section-title">Timeline of events</h3>
    <div class="flex align-center" *ngIf="grantRight('20')">
      <app-button (click)="adding_task = true" class="small outline green" label="Add Task"
        *ngIf="!adding_task && !adding_notes" icon="fa fa-plus">
      </app-button>

      <app-button (click)="adding_notes = true" class="small outline teal ml-1" label="Add Note"
        *ngIf="!adding_notes && !adding_task" icon="fa fa-plus">
      </app-button>

      <app-button (click)="adding_task = false; adding_notes = false" class="small outline red ml-1"
        *ngIf="adding_task || adding_notes" label="Close" icon="fa fa-times">
      </app-button>
      <!-- <span class="border-blue" *ngIf="!adding_task"><i class="fa fa-plus mr-2"></i>Add Task</span> -->
      <!-- <span class="color-red"><i class="fa fa-times mr-2"></i>Close</span> -->
      <!-- <button (click)="adding_notes = !adding_notes" class="btn btn-outline-secondary btn-sm mr-2"
        [disabled]="!grantRight('20')">
        <span *ngIf="!adding_notes"><i class="fa fa-plus mr-2"></i>Add Notes</span>
        <span *ngIf="adding_notes"><i class="fa fa-times mr-2"></i>Close</span>
      </button> -->
    </div>
  </div>

  <div *ngIf="adding_notes">
    <form #ols="ngForm" novalidate>
      <div class="form-group">
        <app-ngx-editor [config]="editorConfig" name="CONTENT" [(ngModel)]="notes.CONTENT"></app-ngx-editor>
      </div>
       
      <div class="form-group"><input [(ngModel)]="notes.apphistory" name="apphistory" type="checkbox"><label
        class="control-label normal">Show To Partners</label></div>
      
      <app-button type="submit" (click)="saveNotes(ols.value, ols.valid)" [disabled]="loading" class="small outline"
        [loading]="loading" label="Update">
      </app-button>
      <hr>
    </form>
  </div>


  <div *ngIf="adding_task">
    <form #ta="ngForm" novalidate>
      <div class="form-group">
        <label for="add_task">Add Task</label>
        <textarea class="form-control" [(ngModel)]="tasks.TASK" name="CONTENT" id="add_task" rows="3"></textarea>
      </div>

      <div>
        <label class="form-group">
          <span class="control-label normal">Select staff</span>
          <select (change)="preferredstaff($event)" name="STAFF" [(ngModel)]="STAFF" id="stateOriginID"
            class="form-control">
            <option>Select Staff</option>
            <option [value]="staff.PEOPLE_PEOPLE_ID" *ngFor="let staff of staffs; let i = index;">
              {{ staff.LEGAL_NAME }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of staffNames; index as i">{{item}}
            <button class="chip-close" (click)="removedchoosenstaff(item)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="end_date">End Date</label>
            <input type="date" class="form-control" name="end_date" [(ngModel)]="task_enddate" id="end_date">
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-group">
            <span class="control-label normal">Task status</span>
            <select name="STAFF" [(ngModel)]="tasks.TASKSTATUS" id="stateOriginID" class="form-control">
              <option></option>
              <option [value]="1">High</option>
              <option [value]="2">Medium</option>
              <option [value]="3">Low</option>
            </select>
          </label>
        </div>
      </div>

      <app-button type="submit" (click)="saveTasks(ta.value, ta.valid)" [disabled]="loading" class="small outline"
        [loading]="loading" label="Update">
      </app-button>
      <hr>

    </form>
  </div>




  <div class="timeline mt-5">
    <article class="timeline-item" *ngFor="let item of timeline; let idx = index" [ngClass]="{ alt: idx % 2 > 0 }">
      <div class="timeline-desk" *ngIf="idx % 2 > 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow"></span>
            <span class="timeline-icon red"></span>
            <span class="timeline-date">{{
              item?.DATE_ADDED | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-orange default-text-size">
              {{ item?.DATE_ADDED | amDateFormat: "D" }}
              {{ item?.DATE_ADDED | amDateFormat: "MMMM" }} |
              {{ item?.DATE_ADDED | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.LEGAL_NAME }} |
              <span [innerHTML]="item?.TIMELINE_ACTIVITY"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="timeline-desk" *ngIf="idx % 2 == 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow-alt"></span>
            <span class="timeline-icon green"></span>
            <span class="timeline-date">{{
              item?.DATE_ADDED | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-teal default-text-size">
              {{ item?.DATE_ADDED | amDateFormat: "D" }}
              {{ item?.DATE_ADDED | amDateFormat: "MMMM" }} |
              {{ item?.DATE_ADDED | amAdd: 1:"hours" | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.LEGAL_NAME }} |
              <span [innerHTML]="item?.TIMELINE_ACTIVITY"></span>
            </p>
          </div>
        </div>
      </div>
    </article>
  </div>
</section>