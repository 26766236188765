import { Component, OnInit, Input } from '@angular/core';
import { DataService, UserService, CustomerService, AuthenticationService, StorageService, OperationsService } from '../../_services/index';
import { Subject } from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-emailsender',
  templateUrl: './emailsender.component.html',
  styleUrls: ['./emailsender.component.css']
})
export class EmailsenderComponent implements OnInit {
  @Input('is_multiple') is_multiple = true;
  @Input('customer') customer: any;
  @Input('is_for_employee') is_for_employee = false;
  @Input('is_for_all_employee') is_for_all_employee = false;
  @Input('employee') employee: any;
  endDate = '1561762799000';
  overlayOpen: Boolean;
  showallCustomers: Boolean;
  choosedcustomer: number;
  loading: Boolean;
  loader: Boolean;
  currentUser: any;
  customerEmail: any;
  items = [true, 'Two', 3];
  listemail: any;
  emailSelected = [];
  dropdownList = [];
  selectedItems = [];
  selectedCustomer = [];
  selectedCustomerId = [];
  showSMSlist = false;
  selectedCustomerForSms = [];
  selectedCustomerIdForSms = [];

  dropdownSettings = {};
  openSms: Boolean;
  openEmail: Boolean = true;
  modalEmail: Boolean;
  modalSms: Boolean;
  loadsentmail: any;
  loadsentsms: any;
  showEmaillist = true;
  showSmslist = false;
  loadedemail: any;
  loadedsms: any;
  search = '';
  // Loader list
  preEmailloader: boolean;
  nextEmailloader: boolean;

  preSmsloader: boolean;
  nextSmsloader: boolean;


  // pagination
  emailpaginationstart: number;
  smspaginationstart;
  pageNo = 1;
  smsPageNo = 1;
  no_of_records_per_page = 4;
  defaultPageNumber = 3;

  emailCount: any;
  smsCount: any;

  results: Object;
  searchTerm$ = new Subject<string>();
  @Input('from_where') from_where = '0';
  hasCustomerLists = false;
  @Input('userId') userId: any;
  emailmessage = '';
  emailsubject = '';
  constructor(public operationsService: OperationsService,
    public toastr: ToastrService,
    public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    if (this.from_where == '1') {
      this.selectedCustomer = this.customer;
      this.selectedCustomerId.push(this.userId);
    }
    // this.operationsService.search(this.searchTerm$, this.currentUser.token)
    // .subscribe(results => {
    //   if(results.length > 0) { 
    //     this.results = results;
    //     this.hasCustomerLists = true;
    //   } else {
    //     this.results = 0;
    //     this.hasCustomerLists = false;
    //   }
    // }); 

    this.operationsService.loadSentEmail(this.currentUser.token, 0, this.defaultPageNumber).subscribe((data) => {
      this.emailpaginationstart = data.length;
      this.loadedemail = data;
    });

    this.operationsService.loadSentSMS(this.currentUser.token, 0, this.defaultPageNumber).subscribe((data) => {
      this.smspaginationstart = data.length;
      this.loadedsms = data;
    });

    this.operationsService.countEmail(this.currentUser.token).subscribe((data) => {
      this.emailCount = data;
    });

    this.operationsService.countSMS(this.currentUser.token).subscribe((data) => {
      this.smsCount = data;
    });

  }

  onShowEmailList() {
    this.showEmaillist = !this.showEmaillist;
  }

  onShowSMSList() {
    this.showSmslist = !this.showSmslist;
  }


  onNextEmailList() {
    this.nextEmailloader = true;
    const offset = (this.pageNo + 1) * this.no_of_records_per_page;
    this.operationsService.loadNextSentEmail(this.currentUser.token, offset, this.no_of_records_per_page)
      .subscribe((data) => {
        if (data) {
          this.pageNo++;
          this.nextEmailloader = false;
          this.loadedemail = data;
        }

        if (data.status == false) {
          this.nextEmailloader = false;
        }

      });
  }

  onPreviousEmailList() {
    this.preEmailloader = true;
    const offset = (this.pageNo - 1) * this.no_of_records_per_page;
    this.operationsService.loadPreviousSentEmail(this.currentUser.token, offset, this.no_of_records_per_page)
      .subscribe((data) => {
        if (data) {
          this.pageNo--;
          this.preEmailloader = false;
          this.loadedemail = data;
        }
        if (data.status == false) {
          this.preEmailloader = false;
        }

      });
  }


  onNextSMSList() {
    this.preSmsloader = true;
    const smsoffset = (this.smsPageNo + 1) * this.no_of_records_per_page;
    this.operationsService.loadNextSentSMS(this.currentUser.token, smsoffset, this.no_of_records_per_page)
      .subscribe((data) => {
        if (data) {
          this.smsPageNo++;
          this.preSmsloader = false;
          this.loadedsms = data;
        }
        if (data.status == false) {
          this.preSmsloader = false;
        }

      });
  }

  onPreviousSMSList() {
    this.nextSmsloader = true;
    const smsoffset = (this.smsPageNo - 1) * this.no_of_records_per_page;
    this.operationsService.loadPreviousSentSMS(this.currentUser.token, smsoffset, this.no_of_records_per_page)
      .subscribe((data) => {
        if (data) {
          this.nextSmsloader = false;
          this.smsPageNo--;
          this.loadedsms = data;

        }

        if (data.status == false) {
          this.nextSmsloader = false;
        }

      });
  }


  selectedName(selectedNameId, peopleId) {

    console.log(this.selectedCustomer)
    // if (!this.selectedCustomer.includes(selectedNameId)) {

    // }
    this.selectedCustomer.push(selectedNameId);
    // if (!this.selectedCustomerId.includes(peopleId)) {

    // }
    this.selectedCustomerId.push(peopleId);
    this.hasCustomerLists = false;
    console.log(this.selectedCustomer)
  }

  removeSelectedName(id) {
    this.selectedCustomer
      .splice(this.selectedCustomer.indexOf(id), 1);

    this.selectedCustomerId
      .splice(this.selectedCustomerId.indexOf(id), 1);

  }
  removeSelectedNames(id) {
    console.log(id)
    console.log(this.selectedCustomer)
    this.selectedCustomer
      .splice(this.selectedCustomer.indexOf(id), 1);

    this.selectedCustomerId
      .splice(this.selectedCustomerId.indexOf(id), 1);

  }
  selectedNameForSms(selectedNameId, peopleId) {
    if (!this.selectedCustomerForSms.includes(selectedNameId)) {
      this.selectedCustomerForSms.push(selectedNameId);
    }

    if (!this.selectedCustomerIdForSms.includes(peopleId)) {
      this.selectedCustomerIdForSms.push(peopleId);
    }
  }

  removeSelectedNameFromSms(id) {
    // this.selectedCustomerForSms
    // .splice(this.selectedCustomerForSms.indexOf(id), 1);

    // this.selectedCustomerIdForSms
    // .splice(this.selectedCustomerIdForSms.indexOf(id), 1);

  }

  onSubmitEmails() {
    this.loader = true;
    this.loader = false;
    this.toastr.success('Success', 'Success!');
if(!this.is_for_employee){
  this.operationsService.sendBulkEmail(
    this.currentUser.token,
    this.emailsubject,
    this.emailmessage,
    this.selectedCustomerId);
  this.emailmessage = '';
  this.emailsubject = '';
  this.selectedCustomerId = [];
  this.selectedCustomer = [];
}else{
  this.operationsService.sendEmailToEmployee(
    this.currentUser.token,
    this.emailsubject,
    this.emailmessage,
    this.is_for_all_employee,
    this.employee);
  this.emailmessage = '';
  this.emailsubject = '';
  this.selectedCustomerId = [];
  this.selectedCustomer = [];
  this.is_for_employee=false;
  this.is_for_all_employee=false
}
    
  }
  onSubmitEmail(f) {
    this.loader = true;
    if(!this.is_for_employee){
    const submited = {
      subject: f.value.subject,
      message: f.value.message,
      ids: JSON.stringify(this.selectedCustomerId),
      send_type: this.choosedcustomer,
      message_type: 1,
      token: this.currentUser.token,
      is_multple: this.is_multiple
    };

    this.operationsService.sendEmail(
      submited.token,
      submited.subject,
      submited.message,
      submited.send_type,
      submited.message_type,
      submited.ids, submited.is_multple)
      .subscribe(results => {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      });
    }else{
      const submited = {
        subject: f.value.subject,
        message: f.value.message,
        ids: JSON.stringify(this.selectedCustomerId),
        send_type: this.choosedcustomer,
        message_type: 1,
        token: this.currentUser.token,
        is_multple: this.is_multiple
      };
  
      this.operationsService.sendEmailToEmployees(
        submited.token,
        submited.subject,
        submited.message,
        this.is_for_all_employee,this.employee)
        .subscribe(results => {
          this.loader = false;
          this.toastr.success('Success', 'Success!');
        });
    }
  }


  onSubmitSMS(f) {
    this.loader = true;
    const submitsms = {
      message: f.value.message,
      ids: JSON.stringify(this.selectedCustomerIdForSms),
      send_type: this.choosedcustomer,
      message_type: 2,
      token: this.currentUser.token
    };

    this.operationsService.sendSMS(
      submitsms.token,
      submitsms.message,
      submitsms.send_type,
      submitsms.message_type,
      submitsms.ids)
      .subscribe(results => {
        console.log(results);
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      });
  }

  onEmailOpen() {
    this.openEmail = true;
    this.openSms = false;
  }

  onSmsOpen() {
    this.openSms = true;
    this.openEmail = false;
  }

  openModal() {
    this.overlayOpen = true;
    this.modalEmail = true;
    this.modalSms = false;
  }

  openModalSMS() {
    this.overlayOpen = true;
    this.modalSms = true;
    this.modalEmail = false;

  }

  closeOverlay() {
    this.overlayOpen = false;
  }

  selectCustomer() {
    this.showallCustomers = true;
    this.choosedcustomer = 1;
  }

  allCustomer() {
    this.showallCustomers = false;
    this.choosedcustomer = 2;
    this.hasCustomerLists = false;
  }
  searchForCustome() {
    this.loader = true;
    this.operationsService.searchCustomer(this.search, this.currentUser.token)
      .subscribe(results => {
        this.loader = false;
        if (results.count > 0) {
          this.results = results.customers;
          this.hasCustomerLists = true;
        } else {
          this.results = 0;
          this.hasCustomerLists = false;
        }
      });
  }
}
