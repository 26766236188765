import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { AppConfig } from '../app.config';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {
  sub: any;
  loading = false;
  constructor(public route: ActivatedRoute, public router: Router,
    public authenticationService: AuthenticationService,
    private toastr: ToastrService) {

  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  ngOnInit() {
    // reset login status
    this.login()
  }
  login() {
    this.sub = this.route.params.subscribe(params => {

      let parentRouteId = params['id'];
      this.authenticationService.authorize(parentRouteId)
        .subscribe((redirect: string) => {
          console.log(redirect,'Hello')
          if (redirect) {
            switch (redirect) {
              case 'request':
                return this.router.navigate(['/applications/dashboard']);
              case '2':
                return this.router.navigate(['/applications/dashboard']);
              case '1':
                return this.router.navigate(['/crm/customers']);
              case 'portfolio':
                return this.router.navigate(['/portfolio/dashboard']);
              case '3':
                return this.router.navigate(['/portfolio/dashboard']);
              case '5':
                return this.router.navigate(['/reports']);
              case '6':
                return this.router.navigate(['/settings']);
              case 'customers':
                return this.router.navigate(['/crm/customers']);
              case 'operations':
                return this.router.navigate(['/operations/queue']);
              case '4':
                return this.router.navigate(['/operations/queue']);
              case 'products':
                return this.router.navigate(['/loanproducts']);
              case '7':
                return this.router.navigate(['/loanproducts']);
              case 'branches':
                return this.router.navigate(['/operations/branches']);
              case '8':
                return this.router.navigate(['/operations/branches']);
              case 'customer-clusters':
                return this.router.navigate(['/serviced/companies']);
              case '9':
                return this.router.navigate(['/serviced/companies']);
              case '17':
                return this.router.navigate(['/home/summary']);
              default:
                return;
            }
          } else {
            this.showError('Unable to login');
            this.loading = false;
          }
        }, err => {
        });
    });

  }
}
