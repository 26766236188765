import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../../_services/constants.service';
import { HttpClient } from '@angular/common/http';
import { DataService,OperationsService,StorageService } from 'src/app/_services';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
  activation_code: any;
  loading = false;
  currentUser:any;

  constructor(
    private router: Router,
    private constant: ConstantsService,
    private http: HttpClient,
    private dataService: DataService,
    public operationsService: OperationsService,
    public storageService: StorageService,
    private toastr: ToastrService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.completelendersetup();
  }
  showError(msg) {
    return this.toastr.error(msg, 'Error');
  }
  showSuccess(msg) {
    return this.toastr.success(msg, 'Success');
  }
  completelendersetup(){
    return this.http.post<any>(`${this.constant.read('api_base')}onboard/completelendersetup`, {
      token: this.currentUser.token
    }).subscribe(res => { 
      const sample_user = res.sample_user;
      localStorage.setItem('sample_user', JSON.stringify(sample_user));
    }, error => { });
  }
  activate() {
    this.loading = true;
    return this.http.post<any>(`${this.constant.read('api_base')}onboard/activate`, {
      activation_code: this.activation_code
    }).subscribe(res => {
      if (res.lender_activated === '1') {
        this.router.navigate(['/terms']);
        this.loading = false;
      } else {
        this.loading = false;
        this.showError(res.nessage);
      }

    }, error => {
      console.dir({ error });
      this.loading = false;
    });
  }
}
