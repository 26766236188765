import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Input } from '@angular/core';

@Component({
  selector: 'app-customer-timeline',
  templateUrl: './customer-timeline.component.html',
  styleUrls: ['./customer-timeline.component.css']
})
export class CustomerTimelineComponent implements OnInit {
  loading: boolean;
  sub: any;
  userType: any;
  @Input('external') external = false; 
  @Input('userId') userId; // User Id

  constructor(
    private location: Location,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if(!this.external){
      this.loading = true;
      
      this.sub = this.route.parent.params.subscribe(params => {
        this.loading = false;
        this.userType = params["type"];
        this.userId = params["id"];
      });
    }
  }

  // load_records() {
  //   throw new Error("Method not implemented.");
  // }

  goBack() {
    this.location.back();
  }
}
