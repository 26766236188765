<app-requestheader [accounts_added]="accounts_added" (requestForNewAccount)="sendAccountRequest()"
  (changeTheAccountAccount_)="changeTheDefaultAccount($event)" [loan]="loan" [accounts]="accounts" [account]='account'
  [where]='where' (checkDirectDebitStatus)="checkDirectDebitStatus($event)" (resendingLink)="resendingLink($event)"
  (cancelDirectDebit)="cancelDirectDebit($event)" (setupDirectDebit)="setupDirectDebit($event)"></app-requestheader>

<div *ngIf="!loadingData" class="overpanel-scroll">
  <div *ngIf="!mandate_history">
    <div *ngIf="sending_account_request" class="fill-w fill-h flex-center flex-column">
      <div class="h-group center mt-3 mb-3 padded">
        <h2 class="title no-float">Send Account Request</h2>
        <p class="subtitle">An email will be sent to borrower to add an account</p>
      </div>
      <div class="h-group center mb-2 padded">
        <app-button class="small red outline" (click)="sending_account_request=false"
          [disabled]="loading || is_done=='1'" label="Cancel"></app-button>
        <app-button class="small ml-1" [ngClass]="{'danger' : is_done=='0', 'success': is_done=='1'}"
          (click)="requestAccount()" [disabled]="loading || is_done=='1'" [loading]="loading && is_done=='0'"
          [label]="!loading && is_done == '1' ? 'Confirmed' : 'Confirm'">
        </app-button>
        <app-linkgenerator [link_type]='3' [request_id]='request_id' (linkGenerated)="linkGenerated($event)">
        </app-linkgenerator> 
      </div>
      <div class="h-group center mb-2 padded">
        <p>{{link}}</p>
      </div>
    </div>

    <div
      *ngIf="!sending_account_request && !sending_direct_request && !checking_mandate_status && !cancelling_dd && !resending_link">
      <div class="flex flex-column pd_20" *ngIf="!loading">
        <div class="card mb-4">
          <div class="flex justify-between align-center">
            <span class="bank">{{account?.bank_name}}</span>
            <div class="flex">
              <span class="tag blue mr-2" *ngIf="account?.direct_debit_exists === true">DD Attached</span>
              <div class="dropdown">
                <button class="btn btn-outline-secondary btn-xs" type="button" id="dropdownMenu1" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="true"><i class="fa fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE !='1'"
                    (click)="checkDirectDebitStatus('')"><a>Check Status</a></li>
                  <li *ngIf="account?.direct_debit_exists" (click)="cancelDirectDebit('')"><a>Cancel</a></li>
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE !='1'"
                    (click)="resendingLink('')"><a>Resend Link</a></li>
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'"
                    (click)="sendDebitInstruction()"><a>Send Debit Instruction</a></li>
                  <li *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'"
                    (click)="debitMandateHistory()"><a>Show Mandate History</a></li>
                  <li *ngIf="!account?.direct_debit_exists" (click)="setupDirectDebit('')"><a>Set up Direct Debit</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="number">{{account?.account_number}}</div>
          <div class="name">{{account?.account_name}}</div>
        </div>
        <div class="pd_10" *ngIf="!sending_instruction">
          <h3 class="section-title flex justify-between align-center mb-3">
            Account Debit Settings
            <img style="width: 40px;" src="assets/images/remita.svg">
          </h3>
          <ul class="decList bigger" *ngIf="account?.direct_debit_exists">
            <li class="no-border"><span class="text-primary">Mandate Id</span><span
                class="pull-right">{{account?.direct_debit_request?.DD_MANDATE_ID}}</span></li>
            <li class="no-border"><span class="text-primary">Status</span><span
                class="pull-right">{{account?.direct_debit_request?.IS_ACTIVE=='0'?'Inactive':'Active'}}</span></li>
            <li><span class="text-primary">Initiated</span><span
                class="pull-right">{{account?.direct_debit_request?.DATE_ADDED}}</span></li>
            <li class="no-border"><span class="text-primary">Max Amount</span><span
                class="pull-right">{{account?.direct_debit_request?.ACTIVATION_RESPONSE?.max_amount | number:'.2-2'}}</span>
            </li>
            <li><span class="text-primary">Start Date</span><span
                class="pull-right">{{account?.direct_debit_request?.START_DATE}}</span></li>
            <li class="no-border"><span class="text-primary">End Date</span><span
                class="pull-right">{{account?.direct_debit_request?.END_DATE}}</span></li>

                <li class="no-border"><span class="text-primary">Mandate Link</span><span
                  class="pull-right"><a target="_blank" href="{{account?.direct_debit_request?.MANDATE_LINK}}">{{account?.direct_debit_request?.MANDATE_LINK}}</a></span></li>
          </ul>
          <div class="flex-center" *ngIf="!account?.direct_debit_exists">
            <div class="alert alert-danger pd_15">No direct debit</div>
            <ul class="decList bigger" *ngIf="account?.direct_debit_exists">

              <li (click)="setUpDirectDebitMandate()"><a>Set up Direct Debit Mandate</a></li>

            </ul>
          </div>
        </div>
        <div class="pd_10" *ngIf="sending_instruction">
          <div>
            <label class="form-group flex align-center">
              <input [value]="pay_opt[0].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
              <span class="control-label normal fill-w flex justify-between align-center">
                Pay Loan Balance <span>{{state?.LOAN_BALANCE|number:'.2-2'}}</span>
              </span>
            </label>

            <label class="form-group flex align-center">
              <input [value]="pay_opt[1].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
              <span class="control-label normal fill-w flex justify-between align-center">
                Pay Total Due <span>{{state?.TOTAL_INDEBTED_DEBIT}}</span>
              </span>
            </label>


            <label class="form-group flex align-center">
              <input [value]="pay_opt[2].value" type="radio" name="PAYMENT_OPTION" [(ngModel)]="payment.PAYMENT_OPTION">
              <span class="control-label normal fill-w flex justify-between align-center">
                Pay Any Amount
                <input type="text" class="form-control w-60" name="AMOUNT_TO_PAY" [(ngModel)]="payment.AMOUNT_TO_PAY"
                  *ngIf="payment.PAYMENT_OPTION=='3'">
              </span>
            </label>

            <label class="form-group flex align-center">

              <span class="control-label normal fill-w flex justify-between align-center">
                Password
                <input type="password" class="form-control w-60" name="SECURITY_QUESTION_ANSWER"
                  [(ngModel)]="payment.SECURITY_QUESTION_ANSWER">
              </span>
            </label>

            <div class="flex justify-between align-center mt-3">
              <app-button (click)="doSendDebitInstruction()"
                [disabled]="(payment.AMOUNT_TO_PAY==='0'||payment.AMOUNT_TO_PAY===''||payment.SECURITY_QUESTION_ANSWER==='')&&payment.PAYMENT_OPTION=='3'"
                class="small" label="Send Debit Instruction" [loading]="loading_"></app-button>
              <app-button (click)="sending_instruction=!sending_instruction" [disabled]="loading_"
                class="small red outline ml-2" label="Cancel" [loading]="loading_"></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="sending_direct_request">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <form class="password__form">
              <div class="h-group center mb-4 padded">
                <h2 class="title">Setup Direct Debit Request</h2>
              </div>
              <!-- <div class="form-group">
                <label class="control-label">Channel</label>
                <select  class="form-control custom" name="CHANNEL" [(ngModel)]="CHANNEL">
                  <option></option>
                  <option value="1">Remita</option>
                  <option value="2">CyberPay</option>
                </select>
              </div> -->
              <div class="form-group">
                <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                  placeholder="Enter Password">
              </div>

              <div class="h-group center mb-4 padded">
                <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForDirectDebitOnAccount()" [disabled]="loading || is_done == '1' || PASSWORD == ''"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="sending_direct_request=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checking_mandate_status">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Check mandate setup status</h2>
            </div>
            <form class="password__form">
              <div class="h-group center mb-4 padded">

                <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="checking_mandate_status=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cancelling_dd">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Cancelling Direct Debit Request</h2>
            </div>
            <form class="password__form">
              <!-- <div class="form-group">
                    <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                      placeholder="Enter Password">
                  </div> -->

              <div class="h-group center mb-4 padded">

                <app-button class="mr-1 small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForCancelDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="cancelling_dd=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="resending_link">
      <div class="password__setup-modal">
        <div class="password__setup-container">
          <div class="password__second-layer">
            <div class="h-group center mb-4 padded">
              <h2 class="title">Resending Link</h2>
            </div>
            <form class="password__form">
              <!-- <div class="form-group">
                        <input type="password" class="form-control custom" name="PASSWORD" [(ngModel)]="PASSWORD"
                          placeholder="Enter Password">
                      </div> -->

              <div class="h-group center mb-4 padded">

                <app-button class="small small" [ngClass]="{'danger': is_done == '0', 'success': is_done == '1'}"
                  (click)="sendForCancelDirectDebitStatus()" [disabled]="loading || is_done == '1'"
                  [label]="!loading && is_done=='1' ? 'Confirmed' : 'Confirm'" [loading]="loading && is_done=='0'">
                </app-button>
                <app-button class="small" (click)="resending_link=false" [disabled]="loading || is_done=='1'"
                  label="Cancel"></app-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mandate_history">
    <div class="flex justify-start mb-4">
      <button class="btn btn-xs btn-outline-secondary" (click)="mandate_history = false;"><i
          class="fa fa-arrow-left mr-2"></i>Back</button>
    </div>
    <div *ngIf="!records_found && !checking_instructions" class="panel-body">
      <div class="animated">
        <div class="text-center" style="min-height:304px; padding-top:70px">
          <a href="javascript:;" style="font-size:30px"><i class="fa fa-meh-o"></i></a>
          <h3>No records found</h3>
        </div>
      </div>
    </div>
    <div *ngIf="records_found && !checking_instructions">
      <table class="table table-striped">
        <thead>
          <!-- <th>#</th> -->
          <th>Amount</th>
          <th>Last Status Update</th>
          <th>Status</th>
          <th>RRR</th>
          <th>Transaction Reference</th>

        </thead>
        <tbody>
          <tr *ngFor="let trans of history">
            <td>{{trans?.amount| number:'.2-2'}}</td>
            <td>{{trans?.lastStatusUpdateTime| amDateFormat:'DD MMM YYYY'}}</td>
            <td>{{trans?.status}}</td>
            <td>{{trans?.RRR}}</td>
            <td>{{trans?.transactionRef}}</td>

          </tr>

        </tbody>

      </table>
    </div>
  </div>
</div>
<div *ngIf="loadingData" class="overpanel-scroll">
  <div class="flex flex-column pd_20">
    <div class="card mb-4">
      <div class="flex justify-between align-center">
        <span class="bank skeleton mb-1">Sample test</span>
        <div class="flex">
          <span class="tag blue mr-2 skeleton">Sample Text</span>

        </div>
      </div>
      <div class="number skeleton">Sample text}</div>
      <div class="name skeleton">Sample Text</div>
    </div>
  </div>
</div>