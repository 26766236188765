<div class="cont">
    <div class="sect-wrap">
      <div class="left-container">
        <div class="onb-title">
          <h3>Bank Statement Alanlysis</h3>
        </div>
        <div class="onb-content">
          <p>Analyze any bank statement in 4 steps, our system extracts everything you need to know from a bank statement</p>
          <p>Works with any kind of bank statement. Save your officers stress and use our first grade analytics</p>
        </div>
        <div class="mt-3">
          <app-button (click)="displayNext()" label="Next"></app-button>
        </div>
      </div>
      <div class="right-container text-center">
        <div class="mobile-phones">
          <div class="wrap desktop">
            <img src="assets/images/bankstatement.svg" alt="Illustration">
          </div>
        </div>
      </div>
    </div>
  </div>
  