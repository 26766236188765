import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ApprovalsService, DataService, UserService, OperationsService, OptionsserviceService, LoansService } from '../_services/index';
import { AuthenticationService, StorageService, AnalyticsService } from '../_services/index';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'; 
import { CustomService } from '../_services/custom.service';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '../_services/products.service';
import { ShareddataserviceService } from '../_services/shareddataservice.service';

@Component({
  selector: 'app-home-request',
  templateUrl: './home-request.component.html',
  styleUrls: ['./home-request.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class HomeRequestComponent implements OnInit {
  endDate = '1561676400000';
  show_empty_state = false;
  sharing_link = false;
  repayment:any;
  loan:any;
  miniSearch = false;
  searchView = false;
  view = 'master'; 
  loading = false;
  currentUser:any;
  platform_summary: any;
  loading_approvals = false;
  approvals: any;
  loading_portfolio_snapsot = false;
  snapshot:any;
  adding_more=false;
  filteringLoans: boolean;
  loans: any;
  NOTIFY_ALL_LENDERS = false;
  offer: any;
  making_market_place_offer = false;
  customerdetail: any; 
  showingiframe = false;
  sending_email = false;
  loan__: any;
  userId: any;
  overlayOpenPayConfirm = false;
  introducing_lends = false;
  loadingRouteConfig = false;
  text = 'Invite a Customer';
  modifying_bureau_settings = false;
  externalsettingopen = '';
  pictureshown = 'profile';
  sideview = 'master';
  bsrequest = {
    password: '',
    duration: '6',
    recent_check: true,
    url: ''
  };
  go_live_details = {
    interest_rate: '',
    interest_rate_period_id: '',
    max_amount: '0',
    plan: '1'
  }
  statement: any;
  min = 0;
  max = 100;
  min_ = 0;
  max_ = 10000000; 
  bureau_selected = '1';
  loan_: any;
  loanundefined: any;
  appKey = ''; 
  sample_user: any; 
  overlayOpen = false; 
  request_id: any;
  overlayModalSection = '';
  simulateSection = 'default';
  isedit = '1';
  sub: any; 
  kyc_type_id = '1';
  repayment_mode = '0';
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  appKeys = localStorage.getItem('appKey');
  activeRequestId = null;
  goLiveSection = "home";

  drawer = false;

  public adays = [
    { display: '1 - 3', checked: false, min: 0, max: 3 },
    { display: '4 - 10', checked: false, min: 4, max: 10 },
    { display: '> 10', checked: false, min: 10, max: 365 }
  ];

  public ages = [
    { display: '18 - 24', checked: false, min: 18, max: 24 },
    { display: '25 - 34', checked: false, min: 25, max: 34 },
    { display: '35 - 45', checked: false, min: 35, max: 45 },
    { display: 'above 45', checked: false, min: 46, max: 100 }
  ];
  choosingKYC = false;
  public request_date = [
    { display: 'Today', checked: false, min: 0, max: 1 },
    { display: '2 - 7 days', checked: false, min: 2, max: 7 },
    { display: '2 - 4 weeks', checked: false, min: 8, max: 30 },
    { display: '1+ months', checked: false, min: 31, max: 100000 }
  ];

  magic_filter = {
    repayment_status: '0',
    repayment_mode: '0',
    customer_category: '0',
    company_id: '',
    product_id: '',
    peer_to_peer: '',
    reset: false,
    my_approvals: false,
    account_officer: false,
    start: 0,
    funding: '100',
    token: '',
    min: 0,
    max: 10000000,
    loan_status: '1',
    searchText: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    funding_amount_one: 1,
    funding_amount_two: 1,
    funding_amount_three: false,
    funding_status_disbursement: false,
    funding_status_contract_created: false,
    funding_status_applied: false,
    funding_status_funded: false,
    funding_status: false,
    amount: false,
    approval_level: false,
    rating: false,
    sector: false,
    date: false,
    state_id: ''
  };
  template = {
    repayment_status: '0',
    repayment_mode: '0',
    customer_category: '0',
    company_id: '',
    product_id: '',
    peer_to_peer: '',
    reset: false,
    my_approvals: false,
    account_officer: false,
    start: 0,
    funding: '100',
    token: '',
    min: 0,
    max: 10000000,
    loan_status: '1',
    searchText: '',
    ratings_one: false,
    ratings_two: false,
    ratings_three: false,
    ratings_four: false,
    ratings_five: false,
    funding_amount_one: 1,
    funding_amount_two: 1,
    funding_amount_three: false,
    funding_status_disbursement: false,
    funding_status_contract_created: false,
    funding_status_applied: false,
    funding_status_funded: false,
    funding_status: false,
    amount: false,
    approval_level: false,
    rating: false,
    sector: false,
    date: false,
    state_id: ''
  };
  sectors: any;
  companies: any;
  approval_levels: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  cards = 'cards';
  accountdebit = 'accountdebit';
  open_approval = false;
  approving = false;
  rejecting = false;
  kyc_request = {
    KYC_COMMENTS: '',
    KYC_TYPE_ONE: false,
    KYC_TYPE_TWO: false,
    KYC_PACKAGE: '',
    KYC_TYPE_ID: '1',
    REQUEST_ID: '0'
  };
  financials_request = {
    HOW_MANY_MONTHS: '',
    BANK_STATEMENTS: false,
    PAYSLIPS: false
  };
  guarantors_request = {
    HOW_MANY_GUARANTORS: ''
  };
  pickup = {
    KYC_COMMENTS: '',
    PICKUP_ADDRESS_TYPE: '1',
    CUSTOM_PICKUP_ADDRESS: '',
    PICKUP_PACKAGE: ''
  };
  rejection = {
    MESSAGE: '',
    SEND_REJECTION_MAIL: false
  };
  reopen = {
    MESSAGE: '',
    SEND_REOPEN_MAIL: false,
    SUSPEND_CUSTOMER: false,
    SUSPEND_FOR_HOW_LONG: '24'
  };
  public other_docs = [
    {
      display: 'Valid ID Card',
      value: '1',
      checked: false
    },
    {
      display: 'Other ID Card',
      value: '15',
      checked: false
    },
    {
      display: 'Payslips',
      value: '6',
      checked: false
    },
    {
      display: 'Bank Statements',
      value: '7',
      checked: false
    },
    {
      display: 'Employment Letter',
      value: '8',
      checked: false
    },
    {
      display: 'Purchase Order',
      value: '9',
      checked: false
    },
    {
      display: 'Invoices',
      value: '10',
      checked: false
    },
    {
      display: 'Certificate of Incorporation',
      value: '11',
      checked: false
    },
    {
      display: 'Company Profile',
      value: '12',
      checked: false
    },
    {
      display: 'Allotment of Shares',
      value: '13',
      checked: false
    },
    {
      display: 'Particulars of Directors',
      value: '14',
      checked: false
    },
    {
      display: 'Work ID Card',
      value: '16',
      checked: false
    },
    {
      display: 'Utility Bill',
      value: '17',
      checked: false
    },
    {
      display: 'National ID',
      value: '18',
      checked: false
    },
    {
      display: 'Voter ID',
      value: '19',
      checked: false
    },
    {
      display: 'International Passport',
      value: '20',
      checked: false
    },
    {
      display: 'Offer Letter (Previous Loan)',
      value: '21',
      checked: false
    },
    {
      display: 'Confirmation Letter',
      value: '22',
      checked: false
    },
    {
      display: 'Completed application form',
      value: '23',
      checked: false
    },
    {
      display: 'Original proof of ownership certificate',
      value: '24',
      checked: false
    },
    {
      display: 'Original vehicle license',
      value: '25',
      checked: false
    },
    {
      display: 'Original road worthiness certificate',
      value: '26',
      checked: false
    },
    {
      display: 'Insurance certificate',
      value: '27',
      checked: false
    },
    {
      display: 'Passport photograph',
      value: '28',
      checked: false
    },
    {
      display: 'Inspection report',
      value: '29',
      checked: false
    },
    {
      display: 'Credit report',
      value: '30',
      checked: false
    },
    {
      display: 'Vehicle drop-off form',
      value: '31',
      checked: false
    },
    {
      display: 'Others',
      value: '32',
      checked: false
    },
    {
      display: 'Deed of Transfer',
      value: '33',
      checked: false
    },
    {
      display: 'Credit and Security Agreement',
      value: '34',
      checked: false
    },
    {
      display: 'Financial Statement',
      value: '35',
      checked: false
    },
    {
      display: 'Grace Periods',
      value: '36',
      checked: false
    },
    {
      display: 'Driver License',
      value: '36',
      checked: false
    }
  ];
  public bureaus = [
    {
      display: 'CRC',
      checked: true,
      value: '1'
    },
    {
      display: 'First Central(XDS)',
      checked: false,
      value: '2'
    },
    {
      display: 'CRServices',
      checked: false,
      value: '3'
    }
  ];
  products: any;
  public durations = [
    { display: '0 - 1', checked: false, min: 0, max: 30 },
    { display: '0 - 3', checked: false, min: 31, max: 90 },
    { display: '4 - 6', checked: false, min: 91, max: 180 },
    { display: '7 - 12', checked: false, min: 181, max: 365 },
    { display: '1+ Years', checked: false, min: 366, max: 3650 }
  ];
  public statuses = [
    // { value: '-2', display: 'All' },
    { value: '6', display: 'Contract Created' },
    { value: '-4', display: 'Disbursements' },
    { value: '-6', display: 'Terminated' },
    { value: '5', display: 'Rejected' },
    { value: '-3', display: 'Ineligible' },
    { value: '-10', display: 'Market Place' },
  ];
  docpickups: any;
  doctypes: any;
  public level: any;
  public prev_levels: any;
  public model_r = {
    ilo: 0,
    reject_reason: '',
    reject_action: '',
    approval_notes: '',
    reject_level: '',
    wtd: 0,
    request_id: '',
    level: ''
  };
  public model_a = {
    chk_acts: [],
    past_one: 1,
    approval_all_waivers: 1,
    approval_all_checklist: 1,
    is_waiver_level: 0,
    has_waiver: 0,
    ilo: 0,
    istd: 0,
    approval_notes: '',
    wtd: 1,
    request_id: '',
    level: ''
  };
  disbursing_request = false;
  disburse: any;
  islarger = true;
  public is_done = '0';
  banks: any;
  childModal = { location: '', data: {} };
  account_for_direct_debit: any;
  overlayEditModalSection = '';
  nodes_and_scores: any;
  selected_analytics = '1';
  analytics_last_done = '';
  changingofficer = false;
  attachment_for_analysis: any;
  date_chosen: any;
  show_approval_calendar = false;
  view_status = 'requests';
  calendar_from = '0';
  calendar_type = '0';
  analysing_statement = false;
  where = '';
  showPicture = false;
  sms_value: any;
  guarantor_value: any;
  call_logs_value: any;
  cards_value: any;
  profile_value: any;
  social_value: any;
  accounts_value: any;
  dd_value: any;
  repayment_value: any;
  lender_id: any;
  borrower_id: any;
  principal: any;
  maturity_date: any;
  account_card_id: any;
  loan_request_id: any;
  card_id: any;
  passed_analytics: any;
  failed_analytics: any;
  number_passed: number;
  number_failed: number;
  analytics_score: number;
  profile_pic: any;

  public incomes = [
    { display: '< 50K', checked: false, min: 0, max: 50000 },
    { display: '51 - 100k', checked: false, min: 50001, max: 100000 },
    { display: '101k - 250k', checked: false, min: 100001, max: 250000 },
    { display: '250k - 500k', checked: false, min: 250001, max: 500000 },
    { display: '500k - 1m', checked: false, min: 500001, max: 1000000 },
    { display: '> 1m', checked: false, min: 1000001, max: 120000000 }
  ];
  occupations: any;
  designations: any; 
  collateral_id: any;
  public marital_statuses = [
    // { value: '-2', display: 'All' },
    { value: '1', display: 'Single' },
    { value: '2', display: 'Married' },
    { value: '3', display: 'Divorced' },
    { value: '4', display: 'Widowed' },
  ];
  invitingCustomer = false;
  invitingMultipleCustomer = false;
  bvn_value: any;
  hideRolloutModal: true;
  link: any;
  rights: any = localStorage.getItem('rights');
  requestingData: any;
  //useDrawer = localStorage.getItem('requestLoanViewMode') || false;
  useDrawer = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppingImage = false;
  states: any;
  is_multiple = false;
  show_cards =  false;
  constructor(public analytics: AnalyticsService, public approvalService: ApprovalsService, public DataService: DataService,
    public router: Router, public fb: FormBuilder, public operationsService: OperationsService, public authService: AuthenticationService,
    public storageService: StorageService, private customerService: CustomService, 
    public analyticsService: AnalyticsService, 
    private dataService: DataService, 
    private route: ActivatedRoute, 
    public optionsService: OptionsserviceService,
    private productService: ProductsService,
    private http: HttpClient,
    public loansService: LoansService,
    private _sharedDataService: ShareddataserviceService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.onRequestLoadingFromBackend.subscribe(res => {
      if (res) this.loan = res.loan;
      this.sideview = !this.useDrawer ? 'child' : 'master';
    });
  }

  ngOnInit() {
    this.loan = this.loan_;
    let showpopup = this.storageService.read<any>('test_mode');
    this.optionsService.getSec().subscribe(sectors => (this.sectors = sectors));
    this.optionsService.getOcc().subscribe(sectors => (this.occupations = sectors));
    this.optionsService.getDes().subscribe(sectors => (this.designations = sectors));
    this.optionsService.getStates().subscribe(sectors => (this.states = sectors));
    this.optionsService
      .getAllCompanies(this.currentUser.token)
      .subscribe(companies => (this.companies = companies));
    this.productService
      .getLoanProductsSlim(this.currentUser.token)
      .subscribe(companies => (this.products = companies)); 
    this.sideview = 'master'; 
    this.runPlatformSummary(); 
    this.getPortfolioSnapshot(); 
    this.getUserCustomers()
    this.runStaffPerformance();
    let url = this.router.url; 
    const tokens = url.split('/');
    if(tokens.length == 5){
      this.view = 'sub';
    } 
  }
  getLoans(){
    
  }
  displayCondition(i) {
    // if (i <= 2) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  }
  bookanewloan(){
    this.DataService.bookanewloan.emit({});
  }
  invitenewmembers(){
    this.router.navigate(['./operations/employees']);
  }
  modifyloansettings(){
    this.router.navigate(['./system/settings/general']);
  }
  inviteNewCustomer() {
    this.DataService.inviteNewCustomer.emit({});
  }
  
  getPendingApprovals(){
    this.loading_approvals = true;
    this.analytics.getPendingApprovals(this.currentUser.token)
      .subscribe(data => {
        this.loading_approvals = false;
        this.approvals = data;

      });
  }
  getPortfolioSnapshot(){
    // this.loading_portfolio_snapsot = true;
    // this.analytics.getPortfolioSnapshot(this.currentUser.token)
    //   .subscribe(data => {
    //     this.loading_portfolio_snapsot = false;
    //     this.snapshot = data;

    //   });
  }
  runPlatformSummary() {
    this.loading = true;
    this.analytics.runPlatformSummary(this.currentUser.token)
      .subscribe(data => {
        this.loading = false;
        this.platform_summary = data;
        this.show_empty_state = data.show_empty_state;
        //this.DataService.platformSummaryLoaded.emit({platform_summary:data});
      });
  }
  openAnalytics(request_id){
    this.router.navigate(['./applications/approvals']);
  }
  openApprovals(){
    this.router.navigate(['./applications/approvals']);
  }
  getUserCustomers(){
    this.searchView = false;
    this.loading = true;
    this.magic_filter.token = this.currentUser.token;
    this.loansService
      .filtering_loans_new_new_new(
        this.incomes,
        this.designations,
        this.occupations,
        this.ages,
        this.currentUser.token,
        this.magic_filter,
        this.sectors,
        this.approval_levels,
        this.statuses,
        this.durations,
        this.request_date,
        this.adays,
        this.marital_statuses
      )

      .subscribe(customers => {
        this.filteringLoans = false;
        this.magic_filter.reset = false;
        this.loading = false;
        this.loans = customers;
        this.magic_filter.start = customers.start;
        this.miniSearch = false;
        this.searchView = false;
        
        this.show_cards = customers.show_cards;
      });
  }
  staff_loading = false;
  staff_performance_result:any;
  public staff_performance = {
    'staff_id':''
  }
  runStaffPerformance(){
    this.staff_performance.staff_id = this.currentUser.account_id;
    this.staff_loading = true;
    this.analytics.runStaffPerformance(this.currentUser.token, this.staff_performance)
      .subscribe(data => {
        // //console.log(data);
        this.staff_loading = false;
        this.staff_performance_result = data; 
      });
  }
  loadRequest(request) { 
    this.reloadCurrentRequestWithId(request.request.REQUEST_ID);
    this.activeRequestId = request.request.REQUEST_ID; 
  }
  reloadCurrentRequestWithId(request_id) {
    this.loan = this.loan_;
    this.sideview = 'placeholder';
    this.activeRequestId = request_id;
    // this.loading = true;
    this.loansService.retrieveLoanDetails(this.currentUser.token, this.activeRequestId)
      .subscribe(data => {
        this.loan = data.loan;
        this.sideview = !this.useDrawer ? 'child' : 'master';
        this.loading = false;
        this.dataService.onRequestLoadingFromBackend.emit();
        this.dataService.sendBackLoanToCaller.emit({ loan: this.loan });
        this.DataService.loanHomeRequestSideView.emit({ loan: this.loan });
      });
  }
  onScrollDown(ev) {
    if (this.loans.complete == false) {
      this.loadMoreRecordsAll(this.magic_filter.start);
    }
  }
  loadMoreRecordsAll(start) {
    this.filteringLoans = true;
    //this.magic_filter.reset = true;
    this.magic_filter.start = start;
    // this.getLoans()
    this.searchView = false;
    this.magic_filter.token = this.currentUser.token;
    this.adding_more = true;
    this.loansService
        .filtering_loans_new_filter(
          this.incomes,
          this.designations,
          this.occupations,
          this.adays,
          this.currentUser.token,
          this.magic_filter,
          this.sectors,
          this.approval_levels,
          this.statuses,
          this.durations,
          this.request_date,
          this.ages,
          this.marital_statuses
        )
        // this.loansService.getLoans(this.magic_filter)
        .subscribe(customers => {
          this.adding_more = false;
          this.loans = customers;
          this.magic_filter.start = customers.start;
        });
  }
}
