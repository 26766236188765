import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accoutreports',
  templateUrl: './accoutreports.component.html',
  styleUrls: ['./accoutreports.component.css']
})
export class AccoutreportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
