import { Component, OnInit, ElementRef, OnDestroy, ViewContainerRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AnonymousSubscription } from 'rxjs/Subscription';
import { MarketService, DataService, CustomerService, StorageService, OperationsService, AuthenticationService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-requestingdata',
  templateUrl: './requestingdata.component.html',
  styleUrls: ['./requestingdata.component.css']
})
export class RequestingdataComponent implements OnInit {
  ask_for_new_email = false;
  APPROVAL_LEVEL_ID_ = '';
  updatingApprovalLevels = false;
  approvingbulkdisburse = false;
  is_for_email_reminder = true;
  remindercontenteditor = false;
  message_content: any;
  message_subject: any;
  message_type: any;
  userId = '';
  taskCount = '0';
  opentaskCount = '0';
  endDate = '';
  showingUnderConstruction = false;
  approving_prepayemnt = false;
  security_question: any;
  lender_selected = false;
  chosen_lender = false
  selected: any;
  offerletterdata: any;
  updatingofferletter = false;
  schedule: any;
  open_schedule = false;
  disbursing_request = false;
  @Input('loan') loan: any;
  disburse: any;
  emailopen = '';
  mailadveropen = false;
  timeLeft = 2000;
  TRANSFER_ALL_ACCOUNT = false
  overlayModalSection = '';
  simulateSection = 'default';
  overlayOpenInvestment = false;
  @Input('isloan') isloan = 'loans';
  webussd = false;
  newLoanOpen = false;
  is_company_staff = false;
  test_mode = '0';
  public is_done = '0';
  public loading = false;
  public openShowInterest = false;
  public currentUser: any;
  customers: any;
  searchTerm: any;
  view = 'request';
  viewOptions = false;
  overlayOpen = false;
  request_step = '1';
  schedule_open = false;
  new_collateral_modal = false;
  add_guarantor_modal = false;
  guarantor_contacts_modal = false;
  posting_bulk_payments = false;
  posting_upload_payment = false;
  parentRouteId: any;
  public dashboarddata: any;
  public dashboardFilter = { byMe: false };
  public selectedCustomer: any;
  public customerSelected = false;
  public notifications: any;
  private timerSubscription: AnonymousSubscription;
  private postsSubscription: AnonymousSubscription;
  public enable_peer = '0';
  public accounting = '0';
  newCustomerRecord = false;
  main_menu = 'loans';
  contract_details: any;
  contract_details_: any;
  editing_contract = false;
  contacts: any;
  open_portfolio_target_modal = false;
  showNotifications = false;
  overlayOpenNewLoan = false;
  overlayOpenContractConfirm = false;
  overlayModifyContractConfirm = false;
  approvals_count = '0';
  request_id: any;
  canEditSchedule = false;
  loan_status: any;
  action = '0'
  ownershipCustomer: any;
  posting_break_loans = false;
  invitingCustomer = false;
  invitingMultipleCustomer = false;
  invitingMultipleGuarantors = false;
  preview_schedule = false;
  open_profile_analysis_modal = false;
  open_approval = false;
  level: any;
  prev_levels: any;
  approving = false;
  rejecting = false;
  searching = false;
  public model_r = {
    ilo: 0,
    reject_reason: '',
    reject_action: '',
    approval_notes: '',
    reject_level: '',
    wtd: 0,
    request_id: '',
    level: ''
  };
  public model_a = {
    chk_acts: [],
    past_one: 1,
    approval_all_waivers: 1,
    approval_all_checklist: 1,
    is_waiver_level: 0,
    has_waiver: 0,
    ilo: 0,
    istd: 0,
    approval_notes: '',
    wtd: 1,
    request_id: '',
    level: ''
  };
  disbursements: any;
  download_disbursement = false;
  analytics_data_status: any;
  perform_by: any;
  from_where = '1';
  sending_email = false;
  @Input('customerdetail') customerdetail: any;

  is_multiple = false;
  sending_sms = false;
  sub = '1';
  overlayEditModalSection = '';
  @Input('requestingData') requestingData: any;

  @Input('priority') priority = 'loan';
  link = '';
  customfields = '';
  constructor(
    public customerServ: CustomerService, private toastr: ToastrService, private _dataService: DataService,
    public router: Router, public operationsService: OperationsService,
    public storageService: StorageService,
    protected customerService: CustomerService, public marketService: MarketService,
    private _eref: ElementRef, private authService: AuthenticationService, private loansService: LoansService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    console.log(this.requestingData)


  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  sendRequestForData() {
    this.requestingData.customer_details = this.customerdetail;
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.showSuccess('Request successful');
    this.operationsService.sendRequestForCustomerData(
      this.currentUser.token,
      this.customerdetail.people_id,
      this.requestingData,
      this.ask_for_new_email
    );
  }
  requestForKYC() {
    this.requestingData.customer_details = this.customerdetail;
    this.loading = true;

    // this.operationsService.requestForKYC(
    //   this.currentUser.token,
    //   this.customerdetail.people_id,
    //   this.requestingData,
    //   this.ask_for_new_email,
    //   this.loan
    // );
    this.operationsService.requestForKYC(this.currentUser.token,
      this.customerdetail.people_id,
      this.requestingData,
      this.ask_for_new_email,
      this.loan,this.customfields)
      .subscribe(res => {
        this.loading = false;
        if (res.status == true) {
          this.loading = false;
          this.is_done = '1';
          this.showSuccess('Request successful');
        } else {
          this.showError(res.response.message);
        }
      });
  }
  requestForKYCWork() {
    // this.requestingData.customer_details = this.customerdetail;
    this.loading = true;
    // this.loading = false;
    // this.is_done = '1';
    // this.showSuccess('Request successful');
    // this.operationsService.requestForKYCWork(
    //   this.currentUser.token,
    //   this.customerdetail.people_id,
    //   this.requestingData,
    //   this.ask_for_new_email,
    //   this.loan
    // );
    this.operationsService.requestForKYCWork(this.currentUser.token,
      this.customerdetail.people_id,
      this.requestingData,
      this.ask_for_new_email,
      this.loan,this.customfields)
      .subscribe(res => {
        this.loading = false;
        if (res.status == true) {
          this.loading = false;
          this.is_done = '1';
          this.showSuccess('Request successful');
        } else {
          this.showError(res.response.message);
        }
      });
  }
  linkGenerated(data) {
    this.link = data.url;
  }
  amongSections(feat) {
    return [
      'Work Email'
    ].includes(feat)
  }
}

