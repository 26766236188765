<div class="flex">
  <div class="col-md-8 pl_0">
    <div class="row">
      <div class="col-md-12" style="padding-right: 0px">

        <app-homenavigation></app-homenavigation>
        <div class="panel padding-regular bg-off-white border-blue">

          <div class="flex align-center mt-5 mb-5">
            <div class="section-poster flex-1 no-margin">
              <div class="image flex-center"><img class="w-60" src="assets/images/share.svg"></div>
            </div>
            <div class="h-group flex-1">
              <h3 class="title no-margin">Invite a borrower</h3>
              <p class="subtitle fill-w mt-1 mb-2">
                It would be great to invite new members
              </p>
              <app-button class="small blue mt-1 mr-1" icon="fa fa-plus" label="New Customer"
                (click)="createNewCustomer()">
              </app-button>
              <app-button class="small blue mt-1" icon="fa fa-share-alt" label="Share Link"
                (click)="inviteNewCustomer()">
              </app-button>
            </div>
          </div>
        </div>


        <app-allinvites></app-allinvites>

      </div>

    </div>
  </div>
  <div class="col-md-4">
    <div *ngIf="!miniSearch && !searchView" class="req_panl animated fadeInDown panel sticky">

      <div class="search-box">
        <!-- <input type="text" name="searchText" [(ngModel)]="magic_filter.searchText" #searchText="ngModel"
          (keyup.enter)="searchForLoans()" class="form-control custom" placeholder="Search.."> -->
          <input type="text" name="searchTerm" [(ngModel)]="searchTerm" (keyup.enter)="searchLoad(0)"
          class="form-control search_input" placeholder="Search Here">
      </div>

      <div>
        <section infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
          class="item-area no-margin" style="border-top: 0; border-bottom: 0" slimScroll width="auto" height="270px"
          size="7px" *ngIf="!loading">
          <app-customeritem *ngFor="let request of loans?.customers" [request]='request'
            (loadRequest)="loadRequest(request)" [active]="activeRequestId === request.PEOPLE_ID">
          </app-customeritem>

          <div class="h-group center mt-4 empty-state mt-5" *ngIf="!loans?.customers.length">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title no-float">No customers</h2>
          </div>

          <div style="padding:10px 0px; text-align:center" *ngIf="adding_more">
            <span><i class="fa fa-spinner fa-spin"></i></span>
          </div>
        </section>
        <section *ngIf="loading">
          <div style="padding:10px 0px; text-align:center">
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
          </div>
        </section>
      </div>
      <div class="filter-box">
        <div class="flex align-center">
          <button type="button" (click)="miniSearch=true" class="btn btn-block btn-outline-info mr-1">
            <span *ngIf="!loading && !adding_more"><i class="fa fa-filter mr-2"></i>Filter</span>
            <span *ngIf="loading || adding_more" class="fa fa-spinner fa-spin"></span>
          </button>
          <button type="button" class="btn btn-outline-info mr-1" (click)="resetFilters()"><i
              class="fa fa-refresh"></i></button>

        </div>
      </div>

    </div>
    <div class="req_frm animated fadeInUp" *ngIf="miniSearch && !searchView">
      <div class="panel mb-1 pd_15">
        <button class="btn btn-outline-info btn-block" (click)="miniSearch=false"><i class="fa fa-arrow-left mr-2"></i>
          Back
        </button>
      </div>
      <div id="accordion" role="tablist" class="panel-group filter-items no-margin">
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingADays">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseADays" aria-expanded="false" aria-controls="collapseADays">
                <span>Join Days</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseADays" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingADays">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let aday of adays;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{aday.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="aday_{{aday.value}}" [(ngModel)]="aday.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingAges">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseAges" aria-expanded="false" aria-controls="collapseAges">
                <span>Age Range</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseAges" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingAges">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let age of ages;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{age.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="age_{{age.value}}" [(ngModel)]="age.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFour">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span>Customer Type</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
            <div class="panel-body">
              <select name="customer_category" class="form-control" [(ngModel)]="magic_filter.customer_category">
                <option [value]="0">All</option>
                <option [value]="1">New</option>
                <option [value]="2">Repeat</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingMS">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseMS" aria-expanded="false" aria-controls="collapseMS">
                <span>Marital Status</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseMS" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingMS">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let ms of marital_statuses;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{ms.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="ms.checked" name="ms_{{ms.value}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOccupation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseOccupation" aria-expanded="false"
                aria-controls="collapseOccupation">
                <span>Occupation</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseOccupation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingOccupation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let occupation of occupations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{occupation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="occupation.checked" [checked]="occupation.checked"
                            name="occupation_{{occupation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSector">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSector" aria-expanded="false" aria-controls="collapseSector">
                <span>Sector</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSector" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSector">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let sector of sectors;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{sector?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="sector.checked" [checked]="sector.checked"
                            name="section_{{sector?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingDesignation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseDesignation" aria-expanded="false"
                aria-controls="collapseDesignation">
                <span>Designations</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseDesignation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingDesignation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let designation of designations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{designation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="designation.checked" [checked]="designation.checked"
                            name="designation_{{designation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingIncome">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseIncome" aria-expanded="false" aria-controls="collapseIncome">
                <span>Income</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseIncome" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingIncome">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let income of incomes;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{income.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="income_{{income.value}}" [(ngModel)]="income.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default" *ngIf="branches">
          <div class="panel-heading" role="tab" id="headingBranch">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseBranch" aria-expanded="false" aria-controls="collapseBranch">
                <span>Branches</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseBranch" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingBranch">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let branch of branches;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{branch?.branch_name}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="branch.checked" [checked]="branch.checked"
                            name="branch_{{branch?.branch_id}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default" *ngIf="states">
          <div class="panel-heading" role="tab" id="headingSOR">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSOR" aria-expanded="false" aria-controls="collapseSOR">
                <span>State of Origin</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSOR" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSOR">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let state of states;let i = index" style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{state?.STATE_ID}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="state.checked" [checked]="state.checked"
                            name="state_{{state?.STATE_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-buttons">
        <app-button (click)="filterCustomers()" [loading]="loading" [disabled]="loading" class="medium block"
          label="Apply Filter"></app-button>
      </div>
    </div>

  </div>
</div>