import { Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsserviceService, UserService, CustomerService, AuthenticationService, StorageService, LoansService, OperationsService } from '../../_services/index';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDateModel, IMyInputFieldChanged, IMyCalendarViewChanged, IMyInputFocusBlur, IMyMarkedDate, IMyDate, IMySelector } from 'mydatepicker';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-breakingloan',
  templateUrl: './breakingloan.component.html',
  styleUrls: ['./breakingloan.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class BreakingloanComponent implements OnInit {
  complexForm: FormGroup;
  @Input('token') token: any;
  @Input('loan') loan: any;
  @Input('parentRouteId') request_id: any;
  public post_action = false;
  @Output() closeModal = new EventEmitter();
  @Output() showMessage = new EventEmitter();
  @Output() openThePaymentForFinalBreaking = new EventEmitter();
  @Output() breakLoanHasBeenSent = new EventEmitter();
  canDoNothing = false;
  canDoNothingMessage = "";
  currentUser: any;
  finalizing_breaking = false;
  break_loan = {
    BREAKING_DATE: '',
    REQUEST_ID: 0,
    IS_TOP_UP: '6',
    REQUEST_PRINCIPAL: 0,
    REQUEST_CURRENCY_ID: '',
    REQUEST_PERIOD_ID: 2,
    REQUEST_RATE_PERIOD_ID: 2,
    INTEREST_CALCULATION: '1',
    PERCENTAGE_FINE: '0',
    FLAT_FINE: '0',
    LOAN_BALANCE: '0',
    SECURITY_QUESTION_ANSWER:'',
    PASSWORD:'',
    CHARGE_ALL_INTEREST:false,
    ROLLOVER_OPTION:"0"
  }
  response_message= 'Payment Unsuccessful';
  data: any;
  loan_currency = "NGN";
  countries: any;
  otpError=false;
  confirmingAction = false;
  makingFinalPayment = false;
  paymentHasBeenProcessed = false;
  payment_status = false;
  paymentConfirmed=false;
  payment_result:any;
  
  public pay_opt = [
    { value: '6', 'display': 'Pay Balance' },
    { value: '8', 'display': 'Top Up' },
    { value: '7', 'display': 'Rollover' }
  ]
  public loan_duration = "Months";
  public interest_duration = "Per Month";
  loading = false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    height: '34px',
    width: '100%',
    dateFormat: 'yyyy-mm-dd',
    openSelectorTopOfInput: false,
  };
  lender:any; 
  public selDate: IMyDate = { year: 0, month: 0, day: 0 };
  public selDate_: IMyDate = { year: 0, month: 0, day: 0 };
  request_step = '0';
  public loan_durations = [{ "LOAN_INTEREST_DURATION_ID": 1, "LOAN_DURATION": "Days", "INTEREST_DURATION": "Per Day", "ADJECTIVAL": "Daily", "ABBREV": "d" },
  { "LOAN_INTEREST_DURATION_ID": 2, "LOAN_DURATION": "Months", "INTEREST_DURATION": "Per Month", "ADJECTIVAL": "Monthly", "ABBREV": "Mo" },
  { "LOAN_INTEREST_DURATION_ID": 3, "LOAN_DURATION": "Years", "INTEREST_DURATION": "Per Year", "ADJECTIVAL": "Yearly", "ABBREV": "Yr" },
  { "LOAN_INTEREST_DURATION_ID": 4, "LOAN_DURATION": "Weeks", "INTEREST_DURATION": "Per Week", "ADJECTIVAL": "Weekly", "ABBREV": "Wk" }]
    ;
  newprincipal = 0;
  security_question: any;
  opt_message = '';
  constructor(public toastr: ToastrService, public operationsService: OperationsService,public fb: FormBuilder, public optionsService: OptionsserviceService, public router: Router, public route: ActivatedRoute,
    public loansService: LoansService, public customerService: CustomerService,
    public userService: UserService, public storageService: StorageService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.lender = this.storageService.read<any>('platform');
    this.optionsService.getCountries().subscribe(countries => this.countries = countries);
    this.complexForm = fb.group({
      'SECURITY_QUESTION_ANSWER': [null, Validators.required],
      'PEOPLE_ID': '',
      'REQUEST_ID': '',
      'IS_TOP_UP':'6',
      'DISBURSEMENT_MODE':'0'
    })
  }

  ngOnInit() {
    this.loading = true;
    this.break_loan.IS_TOP_UP = this.pay_opt[0].value
    this.loansService.breakingLoan(this.currentUser.token, this.request_id).subscribe(data => {
      this.loading = false;
      if (data.status == false) {
        // this.closeModal.emit();
        let message = {
          type: "error",
          message: data.message
        }
        this.showMessage.emit(message)
        this.canDoNothing = true;
        this.canDoNothingMessage = data.message;
      } else {
        this.data = data
        this.newprincipal = data.loan.LOAN_BALANCE
        let d: Date = new Date(data.breaking_date);

        this.selDate = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        };
        let d_: Date = new Date(data.payment_date);

        this.selDate_ = {
          year: d_.getFullYear(),
          month: d_.getMonth() + 1,
          day: d_.getDate()
        };
        this.break_loan.REQUEST_PERIOD_ID = data.loan.REQUEST_PERIOD_ID;
        this.break_loan.REQUEST_RATE_PERIOD_ID = data.loan.REQUEST_RATE_PERIOD_ID;
        if (data.interest_calculation != "0") {
          this.break_loan.INTEREST_CALCULATION = data.interest_calculation;
        }
        this.break_loan.PERCENTAGE_FINE = data.loan.PERCENTAGE_FINE;
        this.break_loan.FLAT_FINE = data.loan.FLAT_FINE;
        this.break_loan.LOAN_BALANCE = data.loan.LOAN_BALANCE;
      }
    });
  }
  doPaymentConfirm(value: any): void {
    
    this.otpError = false;
    this.makingFinalPayment = true;
    this.paymentHasBeenProcessed = false;
    this.payment_status = false;
    this.loading = true;
    this.operationsService.doPaymentConfirm(this.currentUser.token, value, '1', '1')
      .subscribe(status => {

        this.loading = false;
        if (status.status == true) {
          this.paymentHasBeenProcessed = true;
          this.otpError = false;
          this.paymentConfirmed = true;
          this.payment_status = true;
          this.payment_result = status;
        } else {
          this.loading = false;
          this.makingFinalPayment = false;
          this.paymentHasBeenProcessed = false;
          this.otpError = true; 
          this.showError(status.message);
        }



      });
  }
  onChargeAllInterestChange(event) {
    this.loading = true;
    this.loansService.adjustTheCurrentBalanceByInterestChange(this.currentUser.token, this.request_id, this.break_loan, this.break_loan.IS_TOP_UP, this.break_loan.INTEREST_CALCULATION, this.break_loan).subscribe(data => {
      this.loading = false;
      if (data.status == '0') {
        if (event.formatted != "") {
          let message = {
            type: "error",
            message: data.data
          }
          this.showMessage.emit(message)
        }

        let d: Date = new Date();

        this.selDate = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        };

      } else {
        let d_: Date = new Date(data.payment_date);
        this.selDate_ = {
          year: d_.getFullYear(),
          month: d_.getMonth() + 1,
          day: d_.getDate()
        };
        this.data = data.data
        this.break_loan.LOAN_BALANCE = data.data.loan.LOAN_BALANCE;
      }
    });
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }
  
  showError(message) {
    this.toastr.error(message, 'Error');
  }
  new_principal(event) {
    this.newprincipal = parseInt(event.target.value) + parseInt(this.data.loan.LOAN_BALANCE)
  }
  closeM() {
    this.closeModal.emit()
  }
  changeCurrency(c) {
    this.loan_currency = c.currency[0];
    this.break_loan.REQUEST_CURRENCY_ID = this.loan_currency
  }
  // doPaymentConfirm(value: any): void {
    
  //   this.otpError = false;
  //   this.paytype = 'confirm';
  //   this.makingFinalPayment = true;
  //   this.paymentHasBeenProcessed = false;
  //   this.payment_status = false;
  //   this.loading = true;
  //   this.checking_direct_debit = false;
  //   this.needsToCheckDirectDebit = false
  //   this.operationsService.doPaymentConfirm(this.currentUser.token, value, this.schedule_type, this.record_type)
  //     .subscribe(status => {

  //       this.loading = false;
  //       if (this.record_type == '2') {
  //         this.withdrawal_step = '2';
  //         if (status.status == '1') {
  //           this.paymentHasBeenProcessed = true;
  //           this.otpError = false;
  //           this.paymentConfirmed = true;
  //           this.payment_status = true;
  //         } else {
  //           this.makingFinalPayment = false;
  //           this.paymentHasBeenProcessed = false;
  //           this.otpError = true;
  //         }
  //       } else {
  //         if (this.record_type == '3') {
  //           this.withdrawal_step = '2';
  //           if (status.status == '1') {
  //             this.paymentHasBeenProcessed = true;
  //             this.otpError = false;
  //             this.paymentConfirmed = true;
  //           } else {
  //             this.makingFinalPayment = false;
  //             this.paymentHasBeenProcessed = false;
  //             this.otpError = true;
  //           }
  //         } else {
  //           if (status.status == true) {
  //             this.DataService.runOperationsTest.emit();
  //             this.paymentHasBeenProcessed = true;
  //             this.otpError = false;
  //             this.paymentConfirmed = true;
  //             this.payment_status = true;
  //             this.payment_result = status;
  //           } else {
  //             this.loading = false;
  //             if (status.check_direct_debit == true) {
  //               this.needsToCheckDirectDebit = true;
  //             }
  //             this.makingFinalPayment = false;
  //             this.paymentHasBeenProcessed = false;
  //             this.otpError = true;
  //             this.otpmessage = status.message;
  //             this.showError(status.message);
  //           }
  //         }

  //       }



  //     });
  // }
  sendBreakLoan() {
    this.loading = true;
    this.loansService.submitBreakLoan(this.currentUser.token, this.request_id, this.break_loan).subscribe(data => {
      if (data.status == '1') {
        this.loading = false;
        if (this.break_loan.IS_TOP_UP == '6') {
          this.finalizing_breaking = true;
          this.request_step = '1';
          (<FormControl>this.complexForm.controls['PEOPLE_ID'])
            .setValue(data.disbursement.PEOPLE_ID, { onlySelf: true });
          (<FormControl>this.complexForm.controls['REQUEST_ID'])
            .setValue(data.disbursement.REQUEST_ID, { onlySelf: true });
            (<FormControl>this.complexForm.controls['IS_TOP_UP'])
            .setValue('6', { onlySelf: true });
          this.security_question = data.security_question.QUESTION;
        } else {
          this.breakLoanHasBeenSent.emit();
        }

      }
    });
  }
  approveBreakLoan() {
    if(this.break_loan.PASSWORD==''){
      this.showError('Please enter your password');
    }else{
      this.loading = true;
      this.loansService.approveBreakLoan(this.currentUser.token, this.request_id, this.break_loan).subscribe(data => {
        if (data.status == true) {
          this.loading = false;
          this.makingFinalPayment=true;
          this.paymentHasBeenProcessed=true;
          this.paymentConfirmed=true;
          this.loansService.completeApproveBreakLoan(this.currentUser.token, this.request_id, this.break_loan);
        }else{
          this.loading = false;
          this.makingFinalPayment=true;
          this.paymentHasBeenProcessed=true;
          this.paymentConfirmed=false;
          this.response_message = data.message;
        }
      });
    }
    
  }
  onPenaltyChanged(event) {
    this.loading = true;
    this.loansService.adjustTheCurrentBalancePenalty(this.currentUser.token, this.request_id, this.break_loan.BREAKING_DATE, this.break_loan.IS_TOP_UP, this.break_loan.INTEREST_CALCULATION, this.break_loan).subscribe(data => {
      this.loading = false;
      if (data.status == '0') {

        if (event.formatted != "") {
          let message = {
            type: "error",
            message: data.data
          }
          this.showMessage.emit(message)
        }

        let d: Date = new Date();

        this.selDate = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        };

      } else {
        let d_: Date = new Date(data.payment_date);
        this.selDate_ = {
          year: d_.getFullYear(),
          month: d_.getMonth() + 1,
          day: d_.getDate()
        };
        this.data = data.data
        this.break_loan.LOAN_BALANCE = data.data.loan.LOAN_BALANCE;
      }
    });
  }
  onDateChanged(event) {
    this.loading = true;
    this.loansService.adjustTheCurrentBalance(this.currentUser.token, this.request_id, event.formatted, this.break_loan.IS_TOP_UP, this.break_loan.INTEREST_CALCULATION, this.break_loan).subscribe(data => {
      this.loading = false;
      if (data.status == '0') {
        if (event.formatted != "") {
          let message = {
            type: "error",
            message: data.data
          }
          this.showMessage.emit(message)
        }

        let d: Date = new Date();

        this.selDate = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        };

      } else {
        let d_: Date = new Date(data.payment_date);
        this.selDate_ = {
          year: d_.getFullYear(),
          month: d_.getMonth() + 1,
          day: d_.getDate()
        };
        this.data = data.data
        this.break_loan.LOAN_BALANCE = data.data.loan.LOAN_BALANCE;
      }
    });
  }
  onInterestChange(event) {
    this.loading = true;
    this.loansService.adjustTheCurrentBalanceByInterest(this.currentUser.token, this.request_id, this.break_loan, this.break_loan.IS_TOP_UP, this.break_loan.INTEREST_CALCULATION, this.break_loan).subscribe(data => {
      this.loading = false;
      if (data.status == '0') {
        if (event.formatted != "") {
          let message = {
            type: "error",
            message: data.data
          }
          this.showMessage.emit(message)
        }

        let d: Date = new Date();

        this.selDate = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate()
        };

      } else {
        let d_: Date = new Date(data.payment_date);
        this.selDate_ = {
          year: d_.getFullYear(),
          month: d_.getMonth() + 1,
          day: d_.getDate()
        };
        this.data = data.data
        this.break_loan.LOAN_BALANCE = data.data.loan.LOAN_BALANCE;
      }
    });
  }
  changeDuration(d, T) {
    if (T === 1) {
      ////console.log(this.loan_durations[d]["LOAN_INTEREST_DURATION_ID"])
      this.loan_duration = this.loan_durations[d]["LOAN_DURATION"];
      this.break_loan.REQUEST_PERIOD_ID = this.loan_durations[d]["LOAN_INTEREST_DURATION_ID"];

    }
    if (T === 2) {
      this.interest_duration = this.loan_durations[d]["INTEREST_DURATION"];
      this.break_loan.REQUEST_RATE_PERIOD_ID = this.loan_durations[d]["LOAN_INTEREST_DURATION_ID"];

    }

  }

}
