import { Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsserviceService, UserService, CustomerService, AuthenticationService, StorageService, LoansService } from '../_services/index';
import { DataService } from '../_services/data.service'

@Component({
  selector: 'app-statementschedule',
  templateUrl: './statementschedule.component.html',
  styleUrls: ['./statementschedule.component.css']
})
export class StatementscheduleComponent implements OnInit, AfterViewInit {
  sub: any;
  @Input('parentRouteId') parentRouteId: number;
  @Input() showHeader = true;
  @Input('where') where = '1';
  @Input() embedModal = false;
  @Output() openCardDebit = new EventEmitter();
  @Output() openAccountDebit = new EventEmitter();
  @Output() openClearingFines = new EventEmitter();
  @Output() openStopRemita = new EventEmitter();
  @Output() openMakeLoanPayment = new EventEmitter();
  loan_status = '0'
  canViewLinks = false;
  loan: any;
  repayments: any;
  currentUser: any;
  open_approval = false;
  public repayment_mode = { 1: 'Remita Inflight', 2: 'Cards', 3: 'Direct Debit Mandate', 4: 'Others', 5: 'Cheques' };
  constructor(public DataService: DataService, public optionsService: OptionsserviceService,
    public router: Router, public route: ActivatedRoute, public loansService: LoansService,
    public customerService: CustomerService, public userService: UserService,
    public storageService: StorageService,
    public authService: AuthenticationService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.parentRouteId = route.snapshot.parent.params['id'];
    // if (!this.authService.canViewModule('1,3,1026')) {
    //   this.canViewLinks = true;
    // }
    this.DataService.onMakePaymentFromStatement.subscribe(res => {
      this.parentRouteId = res;
      this.open_approval = true;
    })
    // this.DataService.paymentHasBeenProcessedFinally.subscribe(res => {
    //   this.getData();

    // })
    // this.DataService.onRequestLoadingEndFromBackend.subscribe(res => {
    //   this.getData();

    // });
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.getData();
  }
  getData() {
    if (this.where == '1') {
      this.sub = this.route.params.subscribe(params => {
        const reid = +params['id']; 
      });
      this.loansService.getStatementSchedule(this.currentUser.token, this.parentRouteId, '2', '-1')

        .subscribe(loan => {
          this.loan = loan.loan;
          this.repayments = loan.repayments;
        });
    } else if(this.where == '6'){

      this.sub = this.route.params.subscribe(params => {
        const reid = +params['id']; 
        this.loansService.getCustomerStatementSchedule(this.currentUser.token, this.parentRouteId, '2', '-1')

        .subscribe(loan => {
          this.loan = loan.loan;
          this.repayments = loan.repayments;
        });

      });
      
    }else {
      this.sub = this.route.params.subscribe(params => {
        const reid = +params['id'];
        console.log(reid, 'Dont go thered')
        this.loansService.getStatementSchedule(this.currentUser.token, this.parentRouteId, '2', '-1')

          .subscribe(loan => {
            this.loan = loan.loan;
            this.repayments = loan.repayments;
          });

      });

    }
  }
  paytheinterest(repayment){
    this.loansService.paytheinterest(this.currentUser.token, repayment)

          .subscribe(loan => {
            this.loan = loan.loan;
            this.repayments = loan.repayments;
          });
  }
  makeLoanPayment() {
    this.DataService.onMakePaymentFromStatement.emit(this.parentRouteId)
  }
  breakLoan() {
    this.DataService.onBreakingLoan.emit(this.parentRouteId)
  }
  hideFunctions(event) {
    this.loan_status = '5'
  }
  initiate_recollection(event) {
    if (this.embedModal) return this.openCardDebit.emit(event);
    this.DataService.onInitiateRecollection.emit(event);
  }
  // initiateRecollection(repayment) {
  //   this.initiate_recollection.emit(repayment);
  // }
  initiateStopRemitaCollection(repayment) {
    if (this.embedModal) return this.openStopRemita.emit({ key: 'repayment', value: repayment });;
    this.DataService.initStopRemita.emit({ loan: this.loan, repayment: repayment });
  }
  open_schedule(request_id) {
    this.router.navigate(['/repay']);
  }
  open_payment(request_id) {
    this.router.navigate(['../payments/' + request_id]);
  }
  open_statement(request_id) {
    this.router.navigate(['../statement/' + request_id]);
  }
  open_contract(request_id) {
    this.router.navigate(['../loan', request_id]);
  }
  getTotal(key, schedule) {
    if (schedule === undefined || schedule === null) { } else {
      let total = 0;
      if (key === 'OUTSTANDING_PRINCIPAL') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.OUTSTANDING_PRINCIPAL); }, 0);
      }
      if (key === 'PRINCIPAL_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.PRINCIPAL_REPAYMENT); }, 0);
      }
      if (key === 'TERM_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.TERM_REPAYMENT); }, 0);
      }
      if (key === 'TOTAL_FINES_SO_FAR') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.TOTAL_FINES_SO_FAR); }, 0);
      }
      if (key === 'HOW_MUCH_PAID') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.HOW_MUCH_PAID); }, 0);
      }
      if (key === 'HOW_MUCH_REMAINING') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.HOW_MUCH_REMAINING) }, 0);
      }
      if (key === 'INTEREST_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.INTEREST_REPAYMENT); }, 0);
      }
      return total;
    }
    //

  }
  initiateSendDebitInstruction(repayment) {
    if (this.embedModal) return this.openAccountDebit.emit({ key: 'repayment', value: repayment });
    this.DataService.initiateDebitInstruction.emit({ repayment: repayment, location: 'initiate_direct_debit' });
  }
  initiateClearFines(repayment) {
    if (this.embedModal) return this.openClearingFines.emit({ key: 'repayment', value: repayment });
    this.DataService.onInitiateClearFines.emit({ repayment: repayment, location: 'initiate_direct_debit' });
  }
  initiateReverseTransaction(repayment) {
    // if (this.embedModal) return this.makeLoanPayment.emit();
    if (this.embedModal) return this.openMakeLoanPayment.emit({ key: 'repayment', value: repayment });
    this.DataService.oninitiateReverseTransaction.emit({ repayment: repayment, location: 'initiate_direct_debit' });
  }
  initiateCheckDebitInstruction(repayment) {
    this.DataService.initiateCheckDebitInstruction.emit({ repayment: repayment, location: 'initiate_direct_debit_check' });
  }
  initiateCancelDebitInstruction(repayment) {
    this.DataService.initiateDebitInstructionCancel.emit({ repayment: repayment, location: 'initiate_direct_debit_cancel' });
  }
  changePortfolioView(view) {
    this.DataService.changePortfolioView.emit({ view: view });
  }
  viewExpandedSchedule() { 
    this.DataService.showScheduleDetails.emit({ request_id: this.parentRouteId, loan_status: this.loan.LOAN_STATUS, schedule: this.repayments.repayments });
  }
}
