<!-- <div class="panel box decSection edged_box">
  <div class="panel-body">
    <div class="pull-left">
      <div class="withImage">
        <div class="decRoundImage">
          <img
            src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.FILE_NAME | imagenotfound}}">
        </div>
      </div>
    </div>
    <div class="pull-left">
      <h4 class="t_l4"><span class="text-danger">{{loan?.LEGAL_NAME}}</span><br><small>{{loan?.REQUEST_PRINCIPAL|number:'.2-2'}} | {{loan?.REQUEST_TENOR}}{{loan?.LOAN_DURATION}}</small></h4>
    </div>
  </div>
</div> -->

<div class="overpanel-content animated zoomIn wide" role="document" *ngIf="step == 'home'"
  [ngClass]="{'floating-title': fromModal}">
  <app-flowsummary [loan_viewed]="request_id" [view_state]="view_state" [dontshownext]="dontshownext"
    (openForFinalPayment)="openForFinalPayment($event)"></app-flowsummary>
</div>

<div class="overpanel-content" role="document" *ngIf="step == 'chooseoption'" [ngClass]="{'floating-title': fromModal}">
  <app-chooseoption (chosenPayment)="chosenPayment($event)"></app-chooseoption>
</div>

<div class="overpanel-content no-padding" role="document" *ngIf="step == 'confirm_payment'">
  <app-confirmpayment [disburse]="newpayment?.disbursement" [schedule_type]="2" [record_type]="3" [sub]="2"
    [paytype]="confirm" [request_id]="request_id"></app-confirmpayment>
</div>

<div class="overpanel-content no-padding" role="document" *ngIf="step == 'card_debit'">
  <app-cardpayment [disburse]="newpayment?.disbursement" [schedule_type]="2" [record_type]="3" [sub]="2"
    [paytype]="confirm" [request_id]="request_id"></app-cardpayment>
</div>

<div class="overpanel-content no-padding" role="document" *ngIf="step == 'direct_debit'">
  <app-remita [disburse]="newpayment?.disbursement" [schedule_type]="2" [record_type]="3" [sub]="2" [paytype]="confirm"
    [request_id]="request_id"></app-remita>
</div>