import { Component, OnInit, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../_services/constants.service';
import { DataService, OptionsserviceService, LoansService, OperationsService, AuthenticationService } from '../../_services/index';
import { FormArray, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-carddebitsetup',
  templateUrl: './carddebitsetup.component.html',
  styleUrls: ['./carddebitsetup.component.css']
})

export class CarddebitsetupComponent implements OnInit {
  loadingData = false;
  sending_card_request = false;
  has_default_card = false;
  link:any;
  @Input('parentRouteId') parentRouteId: any;
  @Input('loan') loan: any;
  @Input('where') where = 'cards';
  @Input('token') token: any;
  cards: any;
  card_count = 0;
  is_done = '0';
  loading = false;
  card: any;
  setting_card_as_default = false;
  paymentHasBeenProcessed = false;
  autodebit_form = {
    SECURITY_QUESTION_ANSWER: '',
    CHARGE_TYPE: '1',
    AMOUNT_TO_DEBIT: '',
    DUE: '0'
  }
  public rows = [];
  public columns = {};
  objectKeys = Object.keys;
  card_recollection_times = [
    {
      'display': 'Ist Try',
      'amount_to_try': '0',
      'time_to_try': '00',
      'time_to_try_minutes': ':00'
    },
    {
      'display': '2nd Try',
      'amount_to_try': '0',
      'time_to_try': '00',
      'time_to_try_minutes': ':00'
    },
    {
      'display': '3rd Try',
      'amount_to_try': '0',
      'time_to_try': '00',
      'time_to_try_minutes': ':00'
    },
    {
      'display': '4th Try',
      'amount_to_try': '0',
      'time_to_try': '00',
      'time_to_try_minutes': ':00'
    }
  ];
  updateCardSchedule = true;
  @Input('chargingCard') chargingCard = true;
  processDone = '0';
  amount_debited = 0;
  otpError = false;
  otpmessage = '';
  autodebit_history = false;
  filter = {
    TPDATE: '',
    TPDATE_: ''
  };
  message = 'Wrong answer';
  requestSuccess = false;
  constructor(private toastr: ToastrService, private http: HttpClient, private authService: AuthenticationService,
    private constant: ConstantsService,
    public operationsService: OperationsService, private dataService:DataService) { }

  ngOnInit() {
    //this.has_default_card = this.loan.connected_card_id != '0' ? true : false;
    this.getCards();
    if (this.loan.loan_status == 3) {
      this.updateCardSchedule = false;
      this.autodebit_form.DUE = this.loan.due;
    }
    this.columns = {
      'Customer': true,
      'Amount': true,
      'Repayment Date': true,
      'Last Autodebit': true,
      'Response': true,
      'Count': true
    }
  }
  linkGenerated(data){ 
    this.link = data.url;
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  autodebitHistory(event) {
    this.autodebit_history = true;
  }
  initiateAutodebit() {
    this.loading = true;
    this.processDone = '0';
    this.otpError = false;
    this.requestSuccess = false;
    this.operationsService.chargeThisCard(this.token, this.autodebit_form, this.loan.request_id, this.card)
      .subscribe(status => {
        this.loading = false;

        if (status.status == true) {
          if (status.data.percent >= 100) {
            this.processDone = '3';
            this.amount_debited = status.data.debited;
            this.otpError = true;
            this.requestSuccess = true;
            this.message = "Amount charged successfully"
          } else {
            this.processDone = '3';
            this.amount_debited = status.data.debited;
            this.requestSuccess = true;
            this.message = "Amount charged successfully"
          }
          this.dataService.breakLoanSentReloadRequest.emit();
        } else {
          this.otpError = true;
          this.message = status.data.response.data.message;
          this.showError(status.data.response.data.message);
        }
      });
  }
  getCards() {
    this.setting_card_as_default = false;
    this.sending_card_request = false;
    this.loadingData = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/cards`, {
      request_id: this.parentRouteId,
      token: this.token
    }).subscribe(response => {
      this.loadingData = false;
      this.cards = response.data;
      this.card_count = response.data.count;
      this.cards.forEach(data => {
        if (data.default === true) {
          this.card = data;
          this.has_default_card = true;
        }
      });
      if (response.card_collection_times) {
        this.card_recollection_times = response.card_collection_times;
      }
      this.card_count = response.count;
      this.getAutodebitReports()
    }, error => {
      //console.log(error);
    });
  }
  requestCard() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendCardRequest(this.token, this.parentRouteId);
  }
  requestForNewCard() {
    this.setting_card_as_default = false;
    this.sending_card_request = !this.sending_card_request;

  }
  changeTheDefaultCard(event) {
    this.sending_card_request = false;
    this.card = event.default_card;
    this.has_default_card = true;
    this.setting_card_as_default = false;
  }
  setCardAsDefault() {
    this.sending_card_request = false;
    this.setting_card_as_default = true;
  }
  setAsDefaultCard() {

  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  }
  saveRecollectionTimes(event) {
    this.loading = true;
    this.operationsService.saveCardRecollectionTimes(this.token, event.card_recollection_times, this.parentRouteId, this.card)
      .subscribe(data => {
        this.loading = false;
        this.updateCardSchedule = false;
        if (data.status) {
          this.is_done = '1';
        } else {
          this.showError(data.message);
        }
      });
  }
  setAsConnectedCard() {
    this.loading = true;
    this.operationsService.setAsConnectedCard(this.token, this.card.account_card_id, this.parentRouteId)
      .subscribe(status => {
        this.loading = false;
        this.getCards();
      });
  }
  chargeCard(event) {

  }
  getAutodebitReports() {
    this.operationsService.getAutodebitReportForLoan(this.token, this.filter, this.parentRouteId).subscribe(data => {
      this.loading = false;
      this.rows = data.message;
      if (typeof this.rows !== 'undefined' && this.rows.length > 0) {
        this.resetColumn(data.message[0]);
      }

    });
  }
  resetColumn(obj) {
    var cols = {};
    for (var key in obj) {
      cols[key] = true;
    }
    this.columns = cols;
  }
}
