<div class="flex">
  <div class="col-md-8 pl_0">
    <app-pending-approvals *ngIf="view=='master'"></app-pending-approvals>
    <app-openapproval *ngIf="view=='sub'"></app-openapproval>

  </div>
  <div class="col-md-4 pl_0">
    <div *ngIf="!miniSearch && !searchView" class="req_panl animated fadeInDown panel">

      <div class="search-box">
        <div class="icon"><i class="fas fa-search"></i></div>
        <input type="text" name="searchText" [(ngModel)]="magic_filter.searchText" #searchText="ngModel"
          (keyup.enter)="searchForLoans()" class="form-control custom" placeholder="Search..">
      </div>

      <div>
        <section infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
          class="item-area no-margin" style="border-top: 0; border-bottom: 0" slimScroll width="auto" height="270px"
          size="7px" *ngIf="!loading">
          <app-item *ngFor="let request of loans?.loans" [request]='request' (loadRequest)="loadRequest($event)"
            [active]="activeRequestId === request.REQUEST_ID">
          </app-item>

          <div class="h-group center mt-4 empty-state mt-5" *ngIf="!loans?.loans.length">
            <div class="icon"><i class="fa fa-meh-o"></i></div>
            <h2 class="title no-float">No approvals</h2>
          </div>

          <div style="padding:10px 0px; text-align:center" *ngIf="adding_more">
            <span><i class="fa fa-spinner fa-spin"></i></span>
          </div>
        </section>
        <section *ngIf="loading">
          <div style="padding:10px 0px; text-align:center">
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
            <app-item [loading]="true" class="mb-1"></app-item>
          </div>
        </section>
      </div>
    </div>

    <div class="req_frm animated fadeInUp" *ngIf="miniSearch && !searchView">
      <div class="panel mb-1 pd_15">
        <button class="btn btn-outline-info btn-block" (click)="miniSearch=false"><i class="fa fa-arrow-left mr-2"></i>
          Back
        </button>
      </div>
      <div id="accordion" role="tablist" class="panel-group filter-items no-margin">
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOne">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span>Amount</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            <div class="panel-body">
              <ion-range-slider [from_min]="0" [min]="min_" [max]="max_" (onChange)="update__(simpleSlider_, $event)">
              </ion-range-slider>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingTwo">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span>Duration</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let duration of durations;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{duration.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="duration_{{duration.value}}"
                            (change)="checkLevel(duration,$event.target.checked,i)">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingADays">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseADays" aria-expanded="false" aria-controls="collapseADays">
                <span>Application Days</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseADays" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingADays">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let aday of adays;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{aday.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="aday_{{aday.value}}" [(ngModel)]="aday.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingAges">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseAges" aria-expanded="false" aria-controls="collapseAges">
                <span>Age Range</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseAges" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingAges">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let age of ages;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{age.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="age_{{age.value}}" [(ngModel)]="age.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFour">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span>Customer Type</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
            <div class="panel-body">
              <select (change)="getLoans()" name="customer_category" class="form-control"
                [(ngModel)]="magic_filter.customer_category">
                <option [value]="0">All</option>
                <option [value]="1">New</option>
                <option [value]="2">Repeat</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingRequestCategory">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseRequestCategory" aria-expanded="false"
                aria-controls="collapseRequestCategory">
                <span>Request Category</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseRequestCategory" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingRequestCategory">
            <div class="panel-body">
              <select (change)="getLoans_()" name="loan_status" class="form-control"
                [(ngModel)]="magic_filter.loan_status">
                <option *ngFor="let status of statuses" [value]="status.value">{{status.display}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFunding">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFunding" aria-expanded="false" aria-controls="collapseFunding">
                <span>Funding</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFunding" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFunding">
            <div class="panel-body">
              <select name="peer_to_peer" class="form-control" [(ngModel)]="magic_filter.peer_to_peer">
                <option [value]=""></option>
                <option [value]="0">Not Peer To Peer</option>
                <option [value]="1">Peer To Peer</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingMS">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseMS" aria-expanded="false" aria-controls="collapseMS">
                <span>Marital Status</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseMS" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingMS">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let ms of marital_statuses;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{ms.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="ms.checked" name="ms_{{ms.value}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOccupation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseOccupation" aria-expanded="false"
                aria-controls="collapseOccupation">
                <span>Occupation</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseOccupation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingOccupation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let occupation of occupations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{occupation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="occupation.checked" [checked]="occupation.checked"
                            name="occupation_{{occupation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSector">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSector" aria-expanded="false" aria-controls="collapseSector">
                <span>Sector</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSector" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSector">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let sector of sectors;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{sector?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="sector.checked" [checked]="sector.checked"
                            name="section_{{sector?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingDesignation">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseDesignation" aria-expanded="false"
                aria-controls="collapseDesignation">
                <span>Designations</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseDesignation" class="panel-collapse collapse" role="tabpanel"
            aria-labelledby="headingDesignation">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let designation of designations;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{designation?.DESCRIPTION}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="designation.checked" [checked]="designation.checked"
                            name="designation_{{designation?.OCCUPATION_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingIncome">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseIncome" aria-expanded="false" aria-controls="collapseIncome">
                <span>Income</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseIncome" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingIncome">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngFor="let income of incomes;let i = index" style="">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{income.display}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" name="income_{{income.value}}" [(ngModel)]="income.checked">
                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFive">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span>Companies</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
            <div class="panel-body">
              <select (change)="getLoans()" name="company_id" class="form-control"
                [(ngModel)]="magic_filter.company_id">
                <option [value]='-1'>All</option>
                <option [value]='0'>Individual</option>
                <option *ngFor="let company of companies?.a" [value]="company.PEOPLE_CUSTOMERS_ID">
                  {{company.LEGAL_NAME}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default" *ngIf="brances">
          <div class="panel-heading" role="tab" id="headingBranch">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseBranch" aria-expanded="false" aria-controls="collapseBranch">
                <span>Branches</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseBranch" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingBranch">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let branch of branches;let i = index"
                  style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{branch?.branch_name}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="branch.checked" [checked]="branch.checked"
                            name="branch_{{branch?.branch_id}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default" *ngIf="products?.count > 0">
          <div class="panel-heading" role="tab" id="headingSix">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span>Products</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
            <div class="panel-body">
              <select (change)="getLoans()" name="product_id" class="form-control"
                [(ngModel)]="magic_filter.product_id">
                <option [value]='-1'>All</option>
                <option *ngFor="let product of products?.products" [value]="product.LOAN_PRODUCT_ID">
                  {{product.LOAN_TITLE}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel panel-default" *ngIf="state">
          <div class="panel-heading" role="tab" id="headingSOR">
            <h4 class="panel-title">
              <a class="collapsed flex flex-row justify-between align-center" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseSOR" aria-expanded="false" aria-controls="collapseSOR">
                <span>State of Origin</span><button class="btn btn-link btn-sm text-muted"><i
                    class="fa fa-chevron-down"></i></button>
              </a>
            </h4>
          </div>
          <div id="collapseSOR" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSOR">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12" *ngFor="let state of states;let i = index" style="padding-right:5px !important">
                  <div class="form-group" style="margin-bottom:0px">
                    <span class="pull-left text-primary">{{state?.STATE_ID}}</span>
                    <span class="pull-right">
                      <div class="checkbox" style="margin:0px !important">
                        <label>
                          <input type="checkbox" [(ngModel)]="state.checked" [checked]="state.checked"
                            name="state_{{state?.STATE_ID}}">

                        </label>
                      </div>
                    </span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-buttons">
        <app-button (click)="getLoans()" [loading]="loading" [disabled]="loading" class="medium block"
          label="Apply Filter"></app-button>
      </div>
    </div>
    <div class="flex-1">
      <div class="panel mb-1">
        <div class="padding-regular">
          <h3 class="section-title mb-4">Quick Links</h3>
          <ul class="decList bigger">
            <li><a [routerLink]="['/password']" class="no-padding">Change Password</a></li>
            <li><a [routerLink]="['/security']" class="no-padding">Set Security Question</a></li>
            <li><a [routerLink]="['/login']" class="no-padding">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>