import {Component, OnInit} from '@angular/core';
import {LenderModel} from '../../_models/lender.model';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  interest_duration = 'Per Month';
  loading = false;
  formLender: LenderModel;
  setup = {
    legal_name: '',
    email: '',
    phone: '',
    license: '',
    interest_rate: '',
    REQUEST_RATE_PERIOD_ID: '2'
  };
  public licenses = [
    {value: '1', display: 'CBN LICENSE'},
    {value: '2', display: 'STATE LENDER LICENSE'},
    {value: '3', display: 'NONE'}
  ];
  public loan_durations = [{ 'LOAN_INTEREST_DURATION_ID': '1', 'LOAN_DURATION': 'Days', 'INTEREST_DURATION': 'Per Day', 'ADJECTIVAL': 'Daily', 'ABBREV': 'd' },
  { 'LOAN_INTEREST_DURATION_ID': '2', 'LOAN_DURATION': 'Months', 'INTEREST_DURATION': 'Per Month', 'ADJECTIVAL': 'Monthly', 'ABBREV': 'Mo' },
  { 'LOAN_INTEREST_DURATION_ID': '3', 'LOAN_DURATION': 'Years', 'INTEREST_DURATION': 'Per Year', 'ADJECTIVAL': 'Yearly', 'ABBREV': 'Yr' },
  { 'LOAN_INTEREST_DURATION_ID': '4', 'LOAN_DURATION': 'Weeks', 'INTEREST_DURATION': 'Per Week', 'ADJECTIVAL': 'Weekly', 'ABBREV': 'Wk' }];
  constructor(
    private toastService: ToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this._setFormLender();
  }

  _setFormLender() {
    this.formLender = new LenderModel('', '');
  }

  showErrorMessage(message) {
    this.toastService.show(message, 'Error');
  }

  displaySetUp() {
    localStorage.setItem('setup', JSON.stringify(this.setup));
    this.router.navigate(['/web']);
  }
  changeDuration(d, T) {
    this.interest_duration = this.loan_durations[d]['INTEREST_DURATION'];
    this.setup.REQUEST_RATE_PERIOD_ID = this.loan_durations[d]['LOAN_INTEREST_DURATION_ID'];

  }
}
