import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-tat',
  templateUrl: './tat.component.html',
  styleUrls: ['./tat.component.css']
})
export class TatComponent implements OnInit {
  @Input('token') token: string;
  @Input('url') url: string;
  tat_data: any;
  request_duration: any;
  request_tenor: any;
  has_data = false;
  constructor (
    private http: HttpClient
  ) {
  }

  ngOnInit () {
    this.getLongestTAT()
  }

  getLongestTAT () {
    return this.http.post<any>(`${this.url}/getLongestTAT`, {token: this.token}).subscribe(res => {
      if (res.status) {
        this.has_data = res.has_data;
        this.tat_data = res.data;
        this.request_duration = this.tat_data.duration;
        switch (this.tat_data.request_period_id) {
          case '1':
            this.request_tenor = `${this.tat_data.request_tenor} days`;
            break;
          case '2':
            this.request_tenor = `${this.tat_data.request_tenor} months`;
            break;
          case '3':
            this.request_tenor = `${this.tat_data.request_tenor} years`;
            break;
          case '4':
            this.request_tenor = `${this.tat_data.request_tenor} weeks`;
            break;
        }
      }
    })
  }

}
