<h5 class="title flex-center">Register Payment</h5>
<div class="overpanel-scroll">
  <div class="overpanel-center">
    <div *ngIf="payment_done">
      <div class="padding-regular">
        <div class="h-group padded center flex-center flex-column">
          <div class="circle large green filled flex-center mb-5"><i class="fa fa-check"></i></div>
          <p class="subtitle mt-1">Payment Successful</p>
        </div>
      </div>
    </div>
    <div *ngIf="!payment_done">
      <div class="padding-regular-x mt-2" *ngIf="state?.queue_payments">
        <div class="alert alert-info alert-sm">Payments are queued for final approval</div>
      </div>
      <div class="flex-center">
        <div class="profile_area ml-3 flex-1">
          <div class="decList bigger">
            <div class="padding-regular" *ngIf="makingPayment">
              <div style="margin:20px auto; width:75%" *ngIf="choosePayment && !chosenPayment">
                <div class="row">
                  <div class="col-md-6">
                    <section (click)="paywithwallet()" class="panel text-center"
                      style="border:1px solid #70c041; border-radius:15px; cursor:pointer">
                      <div class="panel-body">
                        <h4>Wallet</h4>
                        <p style="margin:0px 0px 10px 0px">Debit Wallet</p>
                        <img src="assets/img/wallet.png" style="width: 115px;
                                border: 3px solid #70c041;
                                margin: 15px 0px;
                                border-radius: 44px;
                                padding: 15px; margin-bottom:90px" />
                      </div>
                    </section>
                  </div>
                  <div class="col-md-6">
                    <section (click)="paywithbank()" class="panel text-center"
                      style="border:1px solid #53a8f9; border-radius:15px;cursor:pointer">
                      <div class="panel-body">
                        <h4>Bank</h4>
                        <p style="margin:0px 0px 10px 0px">Debit Account</p>
                        <img src="assets/img/bank.png" style="width: 115px;
                                    border: 3px solid #53a8f9;
                                    margin: 15px 0px;
                                    border-radius: 44px;
                                    padding: 15px; margin-bottom:90px" />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div style="margin:20px auto; width:75%" *ngIf="choosePayment && chosenPayment">
                <div *ngIf="loading" class="text-center">
                  <a><i class="fa fa-spinner fa-spin"></i></a>
                </div>
                <div class="row" *ngIf="payment_status?.status=='1'&&!loading">
                  <div class="col-md-12">
                    <section class="panel text-center">
                      <div class="panel-body">
                        <img src="assets/img/check.png" style="width: 170px;margin: 15px 0px;padding: 15px;" />
                        <h4>{{payment_status?.AMOUNT}}</h4>
                        <p style="margin:0px 0px 10px 0px">Payment Received</p>
                        <a (click)="backtoloansummary()" class="btn btn-white btn-block">Back</a>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="row" *ngIf="payment_status?.status=='0'&&!loading">
                  <div class="col-md-12">
                    <section class="panel text-center">
                      <div class="panel-body">
                        <img src="assets/img/cancel.png" style="width: 170px;margin: 15px 0px;padding: 15px;" />

                        <p style="margin:0px 0px 10px 0px">Payment Not Received</p>
                        <a (click)="backtoloansummary()" class="btn btn-white btn-block">Back</a>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div *ngIf="!choosePayment">
                <div *ngIf="!showPassword">
                  <label class="form-group flex align-center border-bottom">
                    <input [value]="pay_opt[0].value" type="radio" name="PAYMENT_OPTION"
                      [(ngModel)]="payment.PAYMENT_OPTION">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Pay Loan Balance <span>{{state?.LOAN_BALANCE|number:'.2-2'}}</span>
                    </span>
                  </label>
                  <label class="form-group flex align-center border-bottom">
                    <input [value]="pay_opt[1].value" type="radio" name="PAYMENT_OPTION"
                      [(ngModel)]="payment.PAYMENT_OPTION">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Pay Total Due <span>{{state?.TOTAL_INDEBTED_DEBIT|number:'.2-2'}}</span>
                    </span>
                  </label>
                  <label class="form-group flex align-center border-bottom">
                    <input [value]="pay_opt[2].value" type="radio" name="PAYMENT_OPTION"
                      [(ngModel)]="payment.PAYMENT_OPTION">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Pay Any Amount
                      <input type="text" class="form-control w-60" name="AMOUNT_TO_PAY"
                        [(ngModel)]="payment.AMOUNT_TO_PAY" *ngIf="payment.PAYMENT_OPTION=='3'"
                        style="width: 200px!important">
                    </span>
                  </label>
                  <!-- <div class="row mt-4">
                            <div class="col-md-12">
                              <div class="form-group"> 
                                <input type="text" placeholder="Payment Date" name="PAYMENT_DATE" class="form-control"
                                  [(ngModel)]="payment.PAYMENT_DATE" ngui-datetime-picker date-only="true" />
                              </div>
                            </div>
                          </div> -->
                  <label class="form-group flex align-center border-bottom">
                    <!-- <input [value]="pay_opt[2].value" type="radio" name="PAYMENT_OPTION"
                              [(ngModel)]="payment.PAYMENT_OPTION"> -->
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Date
                      <input type="date" data-date="" data-date-format="DD MMMM YYYY" autocomplete="false"
                        placeholder="Payment Date" name="PAYMENT_DATE" class="form-control"
                        style="width: 200px!important" [(ngModel)]="payment.PAYMENT_DATE" />
                    </span>
                  </label>
                  <label class="form-group flex align-center border-bottom">
                    <!-- <input [value]="pay_opt[2].value" type="radio" name="PAYMENT_OPTION"
                              [(ngModel)]="payment.PAYMENT_OPTION"> -->
                    <span class="control-label normal fill-w flex align-center" style="justify-content: end !important;">
                      Receiving Bank
                      <select style="width: 200px!important" class="form-control"
                        [(ngModel)]="payment.LENDER_BANK_ACCOUNT_ID" name="LENDER_BANK_ACCOUNT_ID"
                        id="LENDER_BANK_ACCOUNT_ID">
                        <option value="0"></option>
                        <option *ngFor="let bank of banks" [ngValue]="bank.LENDER_BANK_ACCOUNT_ID">
                          {{bank.LENDER_ACCOUNT_NAME}}|{{bank.GL_NAME}}</option>
                      </select>
                    </span>
                  </label>

                  <!-- <label class="form-group flex align-center border-bottom">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Date
                      <input type="date" autocomplete="false" placeholder="Payment Date" name="PAYMENT_DATE"
                        class="form-control" [(ngModel)]="payment.PAYMENT_DATE" />
                    </span>
                  </label> -->


                  <label class="form-group flex align-center border-bottom">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Send payment received message
                      <input type="checkbox" name="SEND_PAYMENT_RECEIVED" [(ngModel)]="payment.SEND_PAYMENT_RECEIVED" />
                    </span>
                  </label>

                  <div class="row" *ngIf="default_bank?.GL_NAME">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="label-value-list">
                          <div class="item">
                            <span class="label">Default Bank</span>
                            <span class="value">{{default_bank?.GL_NAME}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 text-left">
                      <app-button (click)="showPassword = true"
                        [disabled]="loading || payment.PAYMENT_DATE==''||(payment.PAYMENT_DATE==''&&(payment.AMOUNT_TO_PAY==='0'||payment.AMOUNT_TO_PAY==='')&&payment.PAYMENT_OPTION=='3')"
                        class="small" label="Enter password" [loading]="newloading"></app-button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showPassword">
                  <label class="form-group flex align-center">
                    <span class="control-label normal fill-w flex justify-between align-center">
                      Password
                      <input type="password" autocomplete="false" class="form-control" name="SECURITY_QUESTION_ANSWER"
                        [(ngModel)]="payment.SECURITY_QUESTION_ANSWER" style="width: 200px!important">
                    </span>
                  </label>

                  <div class="row mt-3">
                    <div class="col-md-12 text-left">
                      <app-button (click)="paywithbank()"
                        [disabled]="!payment.SECURITY_QUESTION_ANSWER || loading || payment.PAYMENT_DATE==''||(payment.PAYMENT_DATE==''&&(payment.AMOUNT_TO_PAY==='0'||payment.AMOUNT_TO_PAY==='')&&payment.PAYMENT_OPTION=='3')"
                        class="small" label="Pay" [loading]="newloading"></app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section class="panel" style="border:1px solid #eee" *ngIf="!makingPayment">
              <div class="panel-body" style="padding:35px">
                <div class="row">
                  <div class="col-md-12">
                    <div>
                      <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Repayment Details</span>
                          <span class="pull-right">
                          </span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Loan Balance</span>
                          <span class="pull-right">
                            {{state?.LOAN_BALANCE}}
                          </span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Due</span>
                          <span class="pull-right">
                            {{state?.TOTAL_INDEBTED_DEBIT}}
                          </span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Late / Pending</span>
                          <span class="pull-right">
                          </span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div style="border-bottom: 1px solid #d9d9d9;margin-bottom: 10px;">
                        <div class="form-group">
                          <span class="pull-left text-primary">Total Due</span>
                          <span class="pull-right">
                            {{state?.TOTAL_INDEBTED_BALANCE}}
                          </span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>