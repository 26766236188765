import {Component, Input, OnInit} from '@angular/core';
import {DataService, LoansService, OperationsService, StorageService} from '../_services';

@Component({
  selector: 'app-guarantorcontacts',
  templateUrl: './guarantorcontacts.component.html',
  styleUrls: ['./guarantorcontacts.component.css']
})
export class GuarantorcontactsComponent implements OnInit {
@Input('request_id') request_id: any;
@Input('contacts') contacts: any;
currentUser: any;
display_contacts: any;
hashed_contacts: any;
showHashed: boolean = false;
// contacts: any;
  constructor(
    private loanService: LoansService,
    private storageService: StorageService,
    private operationService: OperationsService,
    private dataService: DataService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.hashed_contacts = this.replaceAsterisks(this.contacts);
  }
  replaceAsterisks(numberArray) {
    return numberArray.map((number) => {
      let start = number.substring(0, 4), end = number.substring(7);
      return `${start}***${end}`;
    });
  }
getDetailedContacts() {
  // this.loanService.getGuarantorsContacts(this.currentUser.token, this.request_id).subscribe(res => {
  //   //console.log(res);
  // });
  this.operationService.getGuarantorContacts('13126').subscribe(res => {
    this.contacts = res.result.frequent_nameless_calls;
    this.hashed_contacts = this.replaceAsterisks(this.contacts);
  });
}
}
