<app-requestheader style="margin-bottom:16px" [loan]="newloan" [where]=''></app-requestheader>
<div *ngIf="!makingFinalPayment">
  <div *ngIf="payment_stage=='1'">
    <div class="content-wrap">
      <div class="items">
        <div class="item">
          <div class="icon" (click)="switchpay('confirm')">
            <i class="fas fa-check-double"></i>
          </div>
          <div class="text">Confirm Payment</div>
        </div>
        <div class="item" *ngIf="loan?.is_top_up !='7'">
          <div class="icon" (click)="switchpay('make')">
            <i class="fas fa-wallet"></i>
          </div>
          <div class="text">Pay from wallet</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-wrap" *ngIf="payment_stage=='2'">
    <section class="panel" *ngIf="lender_banks == 0" style="border:0px">
      <div class="animated">
        <div class="text-center" style="min-height:304px; padding-top:70px">
          <a href="javascript:;" style="font-size:40px"><i class="fa fa-meh-o"></i></a>
          <h3>You have not added any bank accounts</h3>
          <div>
            <button class="btn btn-xs btn-white" (click)="createBankAccount()">Add Bank Account</button>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="lender_banks > 0">
      <form [formGroup]="complexForm" (ngSubmit)="doPaymentConfirm(complexForm.value)">
        <h4 class="section-title mb-5 mt-3">Make Payment</h4>
        <br><br>
        <div
          *ngIf="disburse?.ADJUST_CONTRACT_DATE && disbursement?.IS_TOP_UP!='7' && disbursement?.IS_TOP_UP!='6'  && disbursement?.IS_TOP_UP!='8'">
          <div *ngIf="disburse?.ADJUST_CONTRACT_DATE">
            <div class="form-group row">
              <div class="col-md-6">
                <span class="control-label normal">Contract Date</span>
              </div>
              <div class="col-md-6">
                <label class="form-group">
                  <input [formControl]="complexForm.controls['ADJUST_CONTRACT_DATE']" type="checkbox"
                    name="ADJUST_CONTRACT_DATE" class="vertical-align-middle no-margin">
                  <span class="control-label normal ml-1">Adjust</span>
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group row" *ngIf="complexForm.get('ADJUST_CONTRACT_DATE').value">
          <div class="col-md-6">
            <label for="CONTRACT_DATE" class="control-label normal">Contract Date</label>
          </div>
          <div class="col-md-6">
            <my-date-picker [selDate]="selDate" name="CONTRACT_DATE" [options]="myDatePickerOptions"
              formControlName="CONTRACT_DATE"></my-date-picker>
          </div>
        </div>
        <!-- <div class="form-group">
              <div>
                <label class="checkbox vertical-align-middle">
                  <input [formControl]="complexForm.controls['UPFRONT_FEES_PAID']" type="checkbox"
                    name="UPFRONT_FEES_PAID" class="vertical-align-middle no-margin">
                  <span class="control-label normal ml-1">Fees paid upfront</span>
                </label>
              </div>
              
            </div>  -->

        <div class="form-group row">
          <div class="col-md-6">
            <label for="REPAYMENT_MODE" class="control-label normal">Repayment Mode</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" (change)="repaymentModeChanged($event)"
              [formControl]="complexForm.controls['REPAYMENT_MODE']" name="REPAYMENT_MODE" id="REPAYMENT_MODE">
              <option value="0"></option>
              <option *ngFor="let mode of mode_of_repayment" [value]="mode.value">{{mode.display}}</option>
            </select>
          </div>
        </div>


        <div class="form-group hide"
          [ngClass]="{'has-error':!complexForm.controls['LENDER_BANK_ACCOUNT_ID'].valid && complexForm.controls['LENDER_BANK_ACCOUNT_ID'].touched}">
          <label for="LENDER_BANK_ACCOUNT_ID" class="control-label normal">Disbursement Bank</label>
          <select class="form-control" [formControl]="complexForm.controls['LENDER_BANK_ACCOUNT_ID']"
            name="LENDER_BANK_ACCOUNT_ID" id="LENDER_BANK_ACCOUNT_ID">
            <option value="0"></option>
            <option *ngFor="let bank of banks" [ngValue]="bank">
              {{bank.LENDER_ACCOUNT_NAME}}|{{bank.LENDER_ACCOUNT_NUMBER}}</option>
          </select>
        </div>

        <div class="form-group row">
          <div class="col-md-6">
            <label for="exampleInputEmail1" class="control-label normal">{{security_question}}</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="false" [formControl]="complexForm.controls['SECURITY_QUESTION_ANSWER']" type="password"
              class="form-control" name="SECURITY_QUESTION_ANSWER">
          </div>
          <div *ngIf="otpError && !needsToCheckDirectDebit" class="alert alert-danger mt-2 alert-sm">
            {{otpmessage}}
          </div>
        </div>

        <div class="row" *ngIf="needsToCheckDirectDebit">
          <div class="col-md-6">
            <button class="btn btn-block btn-white" (click)="checkDirectDebitStatus()"
              [disabled]="checking_direct_debit" type="button">Check
              Debit Mandate Status
              <i class="fa fa-spinner fa-spin" *ngIf="checking_direct_debit"></i>
            </button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-block btn-white" (click)="cancelPayment()" type="submit">Cancel</button>
          </div>
        </div>
        <br>
        <div class="mt-4" *ngIf="!needsToCheckDirectDebit">
          <button class="btn btn-success" [disabled]="!complexForm.valid" type="submit">Confirm Payment
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </button>
          <app-button class="small danger outline ml-2" (click)="cancelPayment()" type="submit" label="Cancel">
          </app-button>
        </div>
      </form>
    </div>

  </div>
  <div class="padding-regular animated" *ngIf="payment_stage=='3'">
    <ng-container *ngIf="!wallet_status">
      <div *ngIf="!funding_wallet" class="flex-center flex-column">
        <div class="circle large red filled flex-center"><i class="fa fa-times"></i></div>
        <div class="h-group">
          <div class="title mt-4 mb-4 no-float">You have insufficient funds</div>
        </div>
        <ul class="decList bigger">
          <li><span>Wallet Balance</span><span>{{wallet_balance|number:'.2-2'}}</span></li>
          <li><span>Balance Required</span><span>{{newloan?.request_principal - wallet_balance|number:'.2-2'}}</span>
          </li>
        </ul>
        <div class="mt-4">
          <app-button class="small" (cancel)="cancelAction()"
            (click)="fundyourWallet(newloan?.request_principal - wallet_balance)"
            label="Top up {{wallet_balance - newloan?.request_principal|number:'.2-2'}}"></app-button>
        </div>
      </div>
      <app-fundyourwallet *ngIf="funding_wallet" (getWalletAccountStatus)="getWalletAccountStatus()"
        (showError)="showEMessage($event)" (showSuccess)="showMessage($event)" [wallet_settings]="wallet_settings"
        [wallettopup]="wallettopup"></app-fundyourwallet>
    </ng-container>
    <ng-container *ngIf="wallet_status">
      <div class="flex">
        <!-- <div class="flex-1">
          <div class="h-group">
            <h3 class="mb-1 medium mt-4 no-float title w-75" style="font-size: 1.5rem">
              You want to disburse directly from wallet or account
            </h3>
          </div>
        </div> -->
        <div class="flex-1" *ngIf="addingAccount">
          <h3 class="section-title mb-4 border-bottom">Make Payment</h3>
          <form [formGroup]="newAccountForm" (ngSubmit)="doAddNewBorrowerAccount(newAccountForm.value)">
            <div class="form-group mb-1"
              [ngClass]="{'has-error':!newAccountForm.controls['BANK_ID'].valid && newAccountForm.controls['BANK_ID'].touched}"
              style="margin-bottom:15px">
              <label for="exampleInputEmail1">Account Bank</label>
              <select class="form-control" [formControl]="newAccountForm.controls['BANK_ID']" name="BANK_ID"
                id="BANK_ID">
                <option value="0"></option>
                <option *ngFor="let nb of nigerian_banks" [ngValue]="nb">{{nb.BANK_NAME}}</option>
              </select>
            </div>
            <div class="form-group mb-1"
              [ngClass]="{'has-error':!newAccountForm.controls['LENDER_ACCOUNT_NUMBER'].valid && newAccountForm.controls['LENDER_ACCOUNT_NUMBER'].touched}"
              style="margin-bottom:15px">
              <label for="exampleInputEmail1">Account Number</label>
              <input [formControl]="newAccountForm.controls['LENDER_ACCOUNT_NUMBER']" type="text" class="form-control"
                id="LENDER_ACCOUNT_NUMBER" name="LENDER_ACCOUNT_NUMBER">
              <div class="error phone_error alert alert-block alert-danger fade in hide"
                style="margin:10px 0px !important">

              </div>
            </div>
            <div class="form-group mb-1" *ngIf="newaccountconfirmed&&VERIFY_STATUS">

              <label class="control-label">Account Name</label>
              <input [formControl]="newAccountForm.controls['LENDER_BANK_ACCOUNT_NAME']" type="text" disabled=""
                class="form-control" id="LENDER_BANK_ACCOUNT_NAME" name="LENDER_BANK_ACCOUNT_NAME">
            </div>
            <div class="form-group mb-1" *ngIf="!newaccountconfirmed&&VERIFY_STATUS">
              <div class="error alert alert-block alert-danger fade in" style="margin:10px 0px !important">
                Account could not be confirmed.
              </div>
            </div>

            <div class="row" *ngIf="!continuetosave">
              <div class="col-md-6">
                <button class="btn btn-block btn-white" [disabled]="!newAccountForm.valid" type="submit">Add Account
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </button>
              </div>
              <div class="col-md-6">
                <button [disabled]="loading" class="btn btn-block btn-white"
                  (click)="addingAccount=false">Cancel</button>
              </div>
            </div>
            <div class="form-group row" *ngIf="newaccountconfirmed&&VERIFY_STATUS">
              <div class="col-md-12">
                <button class="btn btn-block btn-white" [disabled]="loading" type="button"
                  (click)="saveBorrowerAccount()">Use
                  Account
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="newaccountconfirmed&&VERIFY_STATUS">
              <div class="col-md-12">
                <button class="btn btn-block btn-white" type="button" (click)="doNotUseAccount()">Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="flex-1" *ngIf="!addingAccount">


          <h3 class="section-title mb-4">Make Payment</h3>
          <br>
          <!-- <div class="flex align-center justify-between mb-2 border-bottom">
            <p class="default-text-size no-margin">Wallet Balance</p>
            <span class="tag medium blue">
              {{wallet_balance|number:'.2-2'}}
            </span>
          </div> -->
          <div class="form-group flex">
            <select class="form-control" [(ngModel)]="selectedAccount" (change)="changeTheSelectedAccount($event)">
              <option disabled>Select bank account</option>
              <option [ngValue]="acc" *ngFor="let acc of disbursement?.BORROWER_ACCOUNT_DETAILS">{{acc.BANK_NAME}} -
                {{acc.LAST_FOUR_DIGITS}}</option>
            </select>
            <button class="btn btn-outline-secondary ml-1" (click)="addNewCustomerAccount()"><i
                class="fa fa-plus"></i></button>
          </div>
          <div class="mb-4 panel padding-regular-medium" *ngIf="disbursement?.BORROWER_ACCOUNT_DETAILS">
            <div class="alert flex justify-between no-padding">
              <div class="texts no-padding">
                <div class="title no-float">{{selectedAccount?.BANK_NAME}}</div>
                <div class="subtitle">{{selectedAccount?.CARD_NAME}}</div>
                <div class="subtitle">{{selectedAccount?.LAST_FOUR_DIGITS}}</div>
              </div>
              <div class="beachacc-status">
                <a><i class="fa fa-check-square"></i></a>
              </div>
            </div>
          </div>

          <form [formGroup]="walletPaymentForm" (ngSubmit)="confirmBorrowerHasBeenPaid(walletPaymentForm.value)">
            <div *ngIf="disburse?.ADJUST_CONTRACT_DATE" class="mb-2">
              <div class="form-group row">
                <div class="col-md-6">
                  <span class="control-label normal">Contract Date</span>
                </div>
                <div class="col-md-6">
                  <label class="form-group">
                    <input [formControl]="walletPaymentForm.controls['ADJUST_CONTRACT_DATE']" type="checkbox"
                      name="ADJUST_CONTRACT_DATE">
                    <span class="control-label normal ml-1">Adjust</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group row mb-4" *ngIf="walletPaymentForm.get('ADJUST_CONTRACT_DATE').value">
              <div class="col-md-6">
                <label for="CONTRACT_DATE" class="control-label normal">Contract Date</label>
              </div>
              <div class="col-md-6">
                <my-date-picker [selDate]="selDate" name="CONTRACT_DATE" [options]="myDatePickerOptions"
                  formControlName="CONTRACT_DATE"></my-date-picker>
              </div>
            </div>
            <!-- <div class="form-group">
              <div>
                <label class="checkbox vertical-align-middle">
                  <input [formControl]="complexForm.controls['UPFRONT_FEES_PAID']" type="checkbox"
                  name="UPFRONT_FEES_PAID" class="vertical-align-middle no-margin">
                  <span class="control-label normal ml-1">Fees paid upfront</span>
                </label>
              </div>
              
            </div>  -->
            <div class="form-group row mb-4" *ngIf="disbursement?.IS_TOP_UP!='7' && disbursement?.IS_TOP_UP!='6'">
              <div class="col-md-6">
                <label class="control-label normal">Repayment Mode</label>
              </div>
              <div class="col-md-6">
                <select class="form-control" (change)="repaymentModeChanged($event)"
                  [formControl]="walletPaymentForm.controls['REPAYMENT_MODE']" name="REPAYMENT_MODE"
                  id="REPAYMENT_MODE">
                  <option value="0"></option>
                  <option *ngFor="let mode of mode_of_repayment" [value]="mode.value">{{mode.display}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row mb-4" *ngIf="disbursement?.IS_TOP_UP!='7' && disbursement?.IS_TOP_UP!='6'">
              <div class="col-md-6">
                <label class="control-label normal">Narration</label>
              </div>
              <div class="col-md-6">
                <textarea class="form-control" formControlName="NARRATTION" [formControl]="walletPaymentForm.controls['NARRATTION']" name="NARRATTION"
                  id="NARRATTION"> </textarea>
              </div>
            </div>
            <div class="form-group row mb-4">
              <div class="col-md-6">
                <label class="control-label normal" for="exampleInputEmail1">{{security_question}}</label>
              </div>
              <div class="col-md-6">
                <input [formControl]="walletPaymentForm.controls['SECURITY_QUESTION_ANSWER']" type="password"
                  class="form-control" id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
              </div>
              <div *ngIf="otpError && !needsToCheckDirectDebit" class="error alert alert-block alert-danger fade in"
                style="margin:10px 0px !important">
                {{otpmessage}}
              </div>
            </div>
            <!-- <div class="flex justify-between align-center mb-3 mt-3 ml-1 border-bottom">
              <p class="default-text-size no-margin">Split destination bank</p>
              <button (click)="beginSplitDisbursement()" class="btn btn-outline-secondary btn-xs ml-1"><i
                  class="fa fa-plus"></i></button>
            </div> -->
            <!-- <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter your password">
          </div> -->
            <br>
            <div class="mt-4">
              <app-button label="Back" [disabled]="loading" (click)="cancelPayment()" class="small outline"
                icon="fa fa-arrow-left"></app-button>
              <app-button [disabled]="!walletPaymentForm.valid||loading" [loading]="loading" type="submit"
                label="Make payment" class="small green ml-1"></app-button>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="panel-body animated" *ngIf="payment_stage=='4'">
    <app-topupwallet [wallet_balance]="wallet_balance" [loan]="disburse" (cancel_payment)="cancelPayment()">
    </app-topupwallet>
  </div>
  <div class="panel-body animated" *ngIf="payment_stage=='5'">
    <div class="content">
      <h4 class="title" style="margin:0px !important">Split Payments</h4>
      <p class="subtitle">{{how_much_remaining}}</p>
    </div>
    <!-- <app-splitpayment (cancel_payment)="cancelPayment()"></app-splitpayment> -->
    <div class="flex">
      <div class="flex-1 mr-2">
        <!-- <h3 class="section-title mb-3">Select Bank</h3>
        <div class="card">
        </div>
        <div class="card">
        </div> -->
        <div *ngIf="addingNewSplitPaymentAccount">
          <h3 class="section-title mb-4 border-bottom">Add New Account</h3>
          <form [formGroup]="newAccountForm" (ngSubmit)="doAddNewBorrowerAccount(newAccountForm.value)">
            <div class="form-group mb-1"
              [ngClass]="{'has-error':!newAccountForm.controls['BANK_ID'].valid && newAccountForm.controls['BANK_ID'].touched}"
              style="margin-bottom:15px">
              <label for="exampleInputEmail1">Account Bank</label>
              <select class="form-control" [formControl]="newAccountForm.controls['BANK_ID']" name="BANK_ID"
                id="BANK_ID">
                <option value="0"></option>
                <option *ngFor="let nb of nigerian_banks" [ngValue]="nb">{{nb.BANK_NAME}}</option>
              </select>
            </div>
            <div class="form-group mb-1"
              [ngClass]="{'has-error':!newAccountForm.controls['LENDER_ACCOUNT_NUMBER'].valid && newAccountForm.controls['LENDER_ACCOUNT_NUMBER'].touched}"
              style="margin-bottom:15px">
              <label for="exampleInputEmail1">Account Number</label>
              <input [formControl]="newAccountForm.controls['LENDER_ACCOUNT_NUMBER']" type="text" class="form-control"
                id="LENDER_ACCOUNT_NUMBER" name="LENDER_ACCOUNT_NUMBER">
              <div class="error phone_error alert alert-block alert-danger fade in hide"
                style="margin:10px 0px !important">

              </div>
            </div>
            <div class="form-group mb-1" *ngIf="newaccountconfirmed&&VERIFY_STATUS">

              <label class="control-label">Account Name</label>
              <input [formControl]="newAccountForm.controls['LENDER_BANK_ACCOUNT_NAME']" type="text" disabled=""
                class="form-control" id="LENDER_BANK_ACCOUNT_NAME" name="LENDER_BANK_ACCOUNT_NAME">
            </div>
            <div class="form-group mb-1" *ngIf="!newaccountconfirmed&&VERIFY_STATUS">
              <div class="error alert alert-block alert-danger fade in" style="margin:10px 0px !important">
                Account could not be confirmed.
              </div>
            </div>

            <div class="row" *ngIf="!continuetosave">
              <div class="col-md-6">
                <button class="btn btn-block btn-white" [disabled]="!newAccountForm.valid" type="submit">Add Account
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </button>
              </div>
              <div class="col-md-6">
                <button [disabled]="loading" class="btn btn-block btn-white"
                  (click)="addingAccount=false;addingNewSplitPaymentAccount=false">Cancel</button>
              </div>
            </div>
            <div class="form-group row" *ngIf="newaccountconfirmed&&VERIFY_STATUS">
              <div class="col-md-12">
                <button class="btn btn-block btn-white" [disabled]="loading" type="button"
                  (click)="saveBorrowerAccountOnly()">Use
                  Account
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="newaccountconfirmed&&VERIFY_STATUS">
              <div class="col-md-12">
                <button class="btn btn-block btn-white" type="button" (click)="doNotUseAccount()">Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="!addingNewSplitPaymentAccount">
          <form #fines="ngForm" novalidate>

            <div class="form-group mb-1">
              <span class="control-label normal">Amount</span>
              <input name="amount" required [(ngModel)]="splitpay.amount" type="number" class="form-control">
            </div>
            <div class="form-group flex">
              <div>
                <span class="control-label normal">Select account</span>
                <select required name="accountSelected" [(ngModel)]="splitpay.accountSelected" class="form-control">
                  <option></option>
                  <option [ngValue]="acc" *ngFor="let acc of disbursement?.BORROWER_ACCOUNT_DETAILS">{{acc.BANK_NAME}} -
                    {{acc.LAST_FOUR_DIGITS}}</option>
                </select>
              </div>
              <button class="btn btn-outline-secondary ml-1" (click)="addingNewSplitPaymentAccount=true"><i
                  class="fa fa-plus"></i></button>
            </div>
            <div class="form-group mb-1">
              <label class="control-label normal">Narration</label>
              <textarea class="form-control" [(ngModel)]="splitpay.narration" name="NARRATTION"
                id="NARRATTION"> </textarea>
            </div>
            <app-button type="submit" (click)="addASplitPayment(fines.value)" [disabled]="!fines.valid" class="small"
              label="Submit"></app-button>
          </form>
        </div>
      </div>
      <div class="flex-1 ml-2">
        <div class="card" *ngFor="let account of splitpayments; let i = index">
          <div class="number flex justify-between align-center">
            {{account.accountSelected.CARD_NAME}}
            <a (click)="removeSplitPayment(i,account)"><i class="fa fa-times color-red"></i></a>
          </div>
          <div class="name">{{account.accountSelected.BANK_NAME}}</div>
          <div class="name">{{account.amount | number:'.2-2'}}</div>
          <div class="flex justify-end">
            <div class="name">{{account.accountSelected.LAST_FOUR_DIGITS}}</div>
          </div>
        </div>
        <div class="mt-3" *ngIf="splitpayments.length != 0">
          <app-button
            [disabled]="how_much_remaining > disburse?.HOW_MUCH_WAS_GIVEN || splitpayments.length == 0 || splittingIsIncomplete()"
            (click)="proceedWithSplitPayment()" class="small" label="Continue"></app-button>
          <app-button (click)="clearSplitPayment()" class="small pull-right" label="Clear"></app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body animated" *ngIf="payment_stage=='6'">
    <div class="flex">
      <div class="flex-1 mr-2">
        <div class="card" *ngFor="let account of splitpayments; let i = index">
          <div class="number flex justify-between align-center">
            {{account.accountSelected.CARD_NAME}}
            <!-- <a (click)="removeSplitPayment(i,account)"><i class="fa fa-times color-red"></i></a> -->
          </div>
          <div class="name">{{account.accountSelected.BANK_NAME}}</div>
          <div class="name">{{account.amount | number:'.2-2'}}</div>
          <div class="flex justify-end">
            <div class="name">{{account.accountSelected.LAST_FOUR_DIGITS}}</div>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <h3 class="section-title mb-4 border-bottom">Make Payment</h3>
        <!-- <div class="flex align-center justify-between mb-2 border-bottom">
          <p class="default-text-size no-margin">Wallet Balance</p>
          <span class="tag medium blue">
            {{wallet_balance|number:'.2-2'}}
          </span>
        </div> -->
        <form [formGroup]="walletPaymentForm" (ngSubmit)="confirmBorrowerHasBeenPaid(walletPaymentForm.value)">

          <div *ngIf="disburse?.ADJUST_CONTRACT_DATE" class="form-group mb-1 flex justify-between align-center">
            <div class="form-group row">
              <div class="col-md-6">
                <span class="control-label normal">Contract Date</span>
              </div>
              <div class="col-md-6">
                <label class="form-group">
                  <input class="pull-right" [formControl]="walletPaymentForm.controls['ADJUST_CONTRACT_DATE']"
                    type="checkbox" name="ADJUST_CONTRACT_DATE">
                  <span class="control-label normal ml-1">Adjust</span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="walletPaymentForm.get('ADJUST_CONTRACT_DATE').value">
            <div class="col-md-6">
              <label for="CONTRACT_DATE" class="control-label normal">Contract Date</label>
            </div>
            <div class="col-md-6">
              <my-date-picker [selDate]="selDate" name="CONTRACT_DATE" [options]="myDatePickerOptions"
                formControlName="CONTRACT_DATE"></my-date-picker>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="disbursement?.IS_TOP_UP!='7' && disbursement?.IS_TOP_UP!='6'">
            <div class="col-md-6">
              <label class="control-label normal">Repayment Mode</label>
            </div>
            <div class="col-md-6">
              <select class="form-control" (change)="repaymentModeChanged($event)"
                [formControl]="walletPaymentForm.controls['REPAYMENT_MODE']" name="REPAYMENT_MODE" id="REPAYMENT_MODE">
                <option value="0"></option>
                <option *ngFor="let mode of mode_of_repayment" [value]="mode.value">{{mode.display}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mb-4"
            [ngClass]="{'has-error':!walletPaymentForm.controls['SECURITY_QUESTION_ANSWER'].valid && walletPaymentForm.controls['SECURITY_QUESTION_ANSWER'].touched}">
            <div class="col-md-6">
              <label class="control-label normal" for="exampleInputEmail1">{{security_question}}</label>
            </div>
            <div class="col-md-6">
              <input [formControl]="walletPaymentForm.controls['SECURITY_QUESTION_ANSWER']" type="password"
                class="form-control" id="SECURITY_QUESTION_ANSWER" name="SECURITY_QUESTION_ANSWER">
            </div>
            <div *ngIf="otpError && !needsToCheckDirectDebit" class="error alert alert-block alert-danger fade in"
              style="margin:10px 0px !important">
              {{otpmessage}}
            </div>
          </div>
          <br>
          <div class="mt-4">
            <app-button label="Back" [disabled]="loading" (click)="cancelPayment()" class="small outline"
              icon="fa fa-arrow-left"></app-button>
            <app-button [disabled]="!walletPaymentForm.valid||loading" [loading]="loading" type="submit"
              label="Make payment" class="small success ml-1"></app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="panel-body animated" *ngIf="makingFinalPayment">
  <div>
    <div *ngIf="paytype=='make'">
      <div class="panel-body" style="padding-bottom: 0px">
        <div class="text-center" style="padding: 20px 0px; padding-bottom: 0px"
          *ngIf="makingFinalPayment&&!paymentHasBeenProcessed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-hourglass fa-spin"></i>
          </a>
          <span style="display: block;">processing payment...</span>

        </div>
        <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
          *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&paymentConfirmed">
          <div *ngIf="payment_status">
            <a href="javascript:;" style="font-size:40px">
              <i class="fa fa-check"></i>
            </a>
            <span style="display: block;">Payment Successful</span>
          </div>
          <div *ngIf="!payment_status">
            <a href="javascript:;" style="font-size:40px">
              <i class="fa fa-check"></i>
            </a>
            <span style="display: block;">Payment Pending</span>
            <p>Check back for transaction status in 10mins</p>
          </div>
          <button type="button" class="btn btn-white" (click)="paymentProcessDoneDone()">Close</button>
        </div>
        <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
          *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&!paymentConfirmed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-info"></i>
          </a>
          <span style="display: block;">Payment Unsuccessful</span>
          <button type="button" class="btn btn-white" (click)="paymentProcessDone()">Close</button>
        </div>
      </div>
    </div>
    <div *ngIf="paytype=='confirm'">
      <div class="panel-body" style="padding-bottom: 0px">
        <div class="text-center" style="padding: 20px 0px; padding-bottom: 0px"
          *ngIf="makingFinalPayment&&!paymentHasBeenProcessed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-hourglass fa-spin"></i>
          </a>
          <span style="display: block;">processing payment...</span>

        </div>
        <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
          *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&paymentConfirmed">
          <div>
            <a href="javascript:;" style="font-size:40px">
              <i class="fa fa-check"></i>
            </a>
            <span style="display: block;">Payment Successful</span>
          </div>

          <button type="button" class="btn btn-white" (click)="paymentProcessDone()">Close</button>
        </div>
        <div class="text-center" style="padding: 20px 0px;padding-bottom: 0px"
          *ngIf="makingFinalPayment&&paymentHasBeenProcessed&&!paymentConfirmed">
          <a href="javascript:;" style="font-size:40px">
            <i class="fa fa-info"></i>
          </a>
          <span style="display: block;">Payment Unsuccessful</span>
          <button type="button" class="btn btn-white" (click)="paymentProcessDone()">Close</button>
        </div>
      </div>
    </div>

  </div>
</div>