import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-approveloan',
  templateUrl: './approveloan.component.html',
  styleUrls: ['./approveloan.component.css']
})
export class ApproveloanComponent implements OnInit {
  @Input('request_id') request_id:any;
  @Input('token') token:any;
  @Input('loan') loan:any;
  @Input('approving')  approving = false;
  @Input('rejecting') rejecting = false;
  @Input('level') level:any
  @Input('prev_levels') prev_levels:any;
  @Output() approveThisRequest = new EventEmitter();
  @Output() cancelThisApproval = new EventEmitter();
  @Output() rejectThisRequest = new EventEmitter();
  @Input('loading') loading = false;
  TRANSFER_ALL_ACCOUNT = false;
  public model_a = {
    chk_acts: [],
    past_one: 1, 
    approval_all_waivers: 1,
    approval_all_checklist: 1,
    is_waiver_level: 0,
    has_waiver: 0,
    ilo: 0,
    istd: 0,
    approval_notes: '',
    wtd: 1,
    request_id: '',
    level: '',
    TRANSFER_ALL_ACCOUNT: false
  };
  public model_r = {
    ilo: 0,
    reject_reason: '',
    reject_action: '',
    approval_notes: '',
    reject_level: '',
    wtd: 0,
    request_id: '',
    level: ''
  };
  constructor() { }

  ngOnInit() {
    console.log(this.level,'Approving Loan')
    this.loading = false;
  }
  approveRequest(){
    this.approveThisRequest.emit({model_a: this.model_a});
  }
  rejectRequest(){
    this.rejectThisRequest.emit({model_r: this.model_r});
  }
  cancelApproval(){
    this.cancelThisApproval.emit();
  }
}
