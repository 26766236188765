<div class="side-view animated slideInRight">
  <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
  <div class="side-view-wrap">
    <div class="header" *ngIf="!loan">
      <div class="user">
        <div class="image large skeleton fit-content">
          <img class="image-avatar" src="assets/img/avatar-mini.png" />
        </div>
        <div class="content skeleton-children">
          <div class="title mb-1">Lorem ipsum dolor sit lofoe.</div>
          <div class="subtitle mb-1">Lorem ipsum </div>
          <div>Lorem,.</div>
        </div>
      </div>
    </div>
    <div class="header" *ngIf="loan">
      <div class="user">
        <div class="image large">
          <img class="image-avatar" onerror="this.src='assets/img/avatar-mini.png';"
            src="{{loan.customer.profile_pic | imagenotfound}}" />
        </div>
        <div class="content flex-1">
          <div class="title">{{ loan.customer.legal_name }}</div>
          <div class="subtitle">N{{ loan.request_principal }} | {{ loan.request_tenor }} {{ loan.loan_duration }}</div>
          <div class="loan-action-buttons mt-2">
            <app-loanportfolioaction (doingDisbursement)="child = 'repaymentflow'" [request_id]="requestId"
              *ngIf="loanportfolio?.show_portfolio" [buttonOnly]="true">
            </app-loanportfolioaction>
          </div>
        </div>
        <div class="flex flex-row justify-around" style="align-self: flex-start">
          <!-- <button class="btn btn-outline-secondary btn-xs" (click)="child = 'edit'"><i class="fa fa-edit"></i></button> -->
          <app-button class="small outline mr-1" (click)="more = 'liste'" icon="fa fa-list" label="Send Message">
          </app-button>
          <button class="btn btn-outline-secondary btn-xs ml-2" (click)="child = 'statement'">
            <i class="fa fa-book mr-1"></i> Statement</button>
          <button class="btn btn-outline-secondary btn-xs ml-2" (click)="child = 'schedule'">
            <i class="fa fa-list mr-1"></i> Schedule</button>

          <button *ngIf="lender?.PEOPLE_ID=='40245'" class="btn btn-outline-secondary btn-xs ml-2"
            (click)="doLedger(loan)">
            <i class="fa fa-list mr-1"></i> Do Legder <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
        </div>
      </div>
    </div>

    <div class="nav">
      <div (click)="setView('repayment')" [ngClass]="{'active': view === 'repayment'}">Repayment</div>
      <div (click)="setView('application')" *ngIf="lender?.PEOPLE_ID=='40245'"
        [ngClass]="{'active': view === 'application'}">Application</div>
      <div (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">Timeline</div>
      <div (click)="setView('comment')" [ngClass]="{'active': view === 'comment'}">Recovery Comments</div>

      <!-- <div (click)="setView('links')" [ngClass]="{'active': view === 'links'}">More options</div> -->
    </div>

    <div class="content skeleton-children fit-content" *ngIf="!loan || !loanportfolio">
      <div class="fill-w mb-2">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, possimus suscipit illum
        consectetur quibusdam magnam provident tempora ducimus expedita nostrum non aliquid quod earum eos dignissimos,
        recusandae unde sed soluta! recusandae unde sed soluta!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut nulla.
        Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab? Quidem adipisci
        facilis porro doloremque at quos pariatur, illo magnam in minima!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut nulla.
        Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab? Quidem adipisci
        facilis porro doloremque at quos pariatur, illo magnam in minima!
      </div>
    </div>

    <div class="content" *ngIf="loan && loanportfolio && view === 'repayment'">
      <div class="panel padding-regular">
        <div class="padding-regular-y text-center">
          <div class="section-title default-text-size">Total repayment made</div>
          <div class="progress mt-2 mb-1" style="height: 4px; width: 60%; margin: auto;">
            <div class="progress-bar progress-bar-striped progress-bar-primary active" role="progressbar"
              aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': 100 - loanportfolio.percent_remaining + '%'}"
              [ngClass]="{'progress-bar-primary': (100 - loanportfolio.percent_remaining) >= 100, 'progress-bar-danger':
              (100 - loanportfolio.percent_remaining) < 100}">
            </div>
            <span class="sr-only">{{100 - loanportfolio.percent_remaining}}% Complete</span>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 flex-center border-right">
            <div class="h-group text-center">
              <div class="title">N{{ (loanportfolio.paid) | number: '.2-2' }}</div>
              <div>Paid</div>
            </div>
          </div>
          <div class="flex-1 flex-center">
            <div class="h-group text-center">
              <div class="title">N{{ loanportfolio.balance | number:'.2-2' }}</div>
              <div>Balance</div>
            </div>
          </div>
        </div>
        <!-- <div class="panel-overlay flex-center">
          <app-loanportfolioaction (doingDisbursement)="makePayment($event)" [request_id]="requestId"
            *ngIf="loanportfolio?.show_portfolio" [buttonOnly]="true">
          </app-loanportfolioaction>
        </div> -->
      </div>

      <div class="panel padding-regular" *ngIf="loan?.loan_status > 3">
        <div class="h-group center flex-center flex-column">
          <div class="section-poster">
            <div class="image flex-center">
              <img src="assets/images/closed.svg" class="fill-w">
            </div>
          </div>
          <p class="subtitle mt-2">Loan has been closed</p>
          <div class="text-center mt-4 width_1_2">
            <app-button class="small outline" label="View Letter of Indebtedness" (click)="child = 'indebtedness'">
            </app-button>
          </div>
        </div>
      </div>
      <section class="panel mb-1" *ngIf="loan?.has_top_up && loan?.loan_status == '3'">
        <div class="alert padding-regular bg-off-white">
          <div class="icon color-teal">
            <i class="fa fa-arrow-up"></i>
          </div>
          <div class="texts">
            <h3 class="title">Top up request</h3>
            <p class="subtitle">Review the top up request</p>
          </div>
          <div class="action">
            <!-- <app-button class="small outline mr-1" (click)="reviewToUp()" label="Open"></app-button> -->
            <app-button class="small teal outline mr-1" (click)="cancelToUp(loan?.top_up_loan)" label="Cancel">
            </app-button>
          </div>
        </div>
      </section>
      <section class="panel mb-1" *ngIf="loan?.has_roll_over && loan?.loan_status <='3'">
        <div class="alert padding-regular bg-off-white">
          <div class="icon color-orange">
            <i class="fas fa-excalamation-circle"></i>
          </div>
          <div class="texts">
            <h3 class="title">Rollover request</h3>
            <p class="subtitle">Review the top up request</p>
          </div>
          <div class="action">
            <!-- <app-button class="small outline mr-1" (click)="reviewRollover()" label="Open"></app-button> -->
            <app-button class="small outline mr-1" (click)="cancelRollover(loan?.top_up_request_id)" label="Cancel">
            </app-button>
          </div>
        </div>
      </section>
      <app-loancontractsection (showActionsModal)="openActionsForRolloverRequest($event)"
        (editOtherAction)="child = 'edit'" [is_top_up]="loan?.loan?.is_top_up"
        *ngIf="loan?.has_top_up && loan?.loan?.loan_status <=3" [loan]="loan?.loan"
        [request_id]="loan?.top_up_request_id" [currentUser]="currentUser" [loading]="loading">
      </app-loancontractsection>
      <app-upfrontpayment [reqid]="loan?.request_id" *ngIf="loan && lender?.PEOPLE_ID=='40245'">
      </app-upfrontpayment>



      <div class="panel padding-regular text-center">
        <div class="flex justify-between align-center mb-4">
          <h3 class="section-title">Repayment mode</h3>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"><i class="fa fa-ellipsis-v"></i></button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a class="cursor-pointer" (click)="child = 'changepayment'">Change repayment mode</a></li>
            </ul>
          </div>
        </div>



        <div *ngIf="loan.repayment_mode === 1">
          <div class="svg-bg mb-5">
            <svg class="cursor-pointer" (click)="child = 'remita'" width="140px" viewBox="0 0 138 39" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 43.1 (39012) - http://www.bohemiancoding.com/sketch -->
              <title>remita_login_logo</title>
              <desc>Created with Sketch.</desc>
              <defs>
                <polygon id="path-1"
                  points="9.33766459 24.1305199 0 24.1305199 0 0.00804350664 18.6753292 0.00804350664 18.6753292 24.1305199">
                </polygon>
                <polygon id="path-3"
                  points="0.127181578 23.7283446 6.00382678 23.7283446 6.00382678 0.130566221 0.127181578 0.130566221 0.127181578 23.7283446">
                </polygon>
              </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Remita_Web_Login" transform="translate(-215.000000, -121.000000)">
                  <g id="remita_login_logo" transform="translate(215.000000, 121.000000)">
                    <path
                      d="M124.314514,32.815094 C120.218251,33.1370354 116.814882,30.4245638 116.814882,25.6899548 C116.814882,20.9603729 120.211215,18.3017928 124.301449,18.6110657 C132.767757,19.2507255 132.778612,32.1500971 124.314514,32.815094 L124.314514,32.815094 Z M137.370577,15.0019442 L137.368165,15.0019442 L137.368165,14.5066653 C137.368165,14.2450503 137.154286,14.0331039 136.890153,14.0331039 L131.974154,14.0331039 C131.710223,14.0331039 131.496143,14.2450503 131.496143,14.5066653 L131.496143,14.9850529 L131.498957,14.9874659 L131.496143,14.9874659 L131.496143,15.1036946 C131.472021,15.3349454 131.198441,15.4081413 131.034011,15.2436516 C130.071757,14.2810449 126.890107,13.3902266 123.989877,13.3902266 C117.058712,13.3425688 111.620076,17.5857196 111.620076,25.6899548 C111.620076,33.9371632 116.817897,38.2275696 123.845549,38.1805151 C126.462952,38.1330584 129.466705,37.8093073 131.495942,35.1290098 L131.495942,37.7867855 C131.495942,38.0481994 131.710223,38.2601458 131.974154,38.2601458 L136.890153,38.2601458 C137.154286,38.2601458 137.368165,38.0481994 137.368165,37.7867855 L137.368165,36.8837008 L137.370577,36.0793501 L137.370577,15.0019442 Z"
                      id="Fill-1" fill="#F16521"></path>
                    <path
                      d="M23.704841,27.7875002 C24.0897828,30.6954289 26.6410529,32.7933766 30.7801328,32.7933766 C32.946059,32.7933766 35.7857842,31.9825911 37.1335833,30.6005156 L40.8879224,34.2709687 C38.3850967,36.845293 34.2936571,38.0847974 30.6836461,38.0847974 C22.5011689,38.0847974 17.6398456,33.0791221 17.6398456,25.5467803 C17.6398456,18.3961029 22.5496133,13.2474543 30.2506619,13.2474543 C38.1927262,13.2474543 43.1499333,18.1101563 42.2357214,27.7875002 L23.704841,27.7875002 Z M36.6041124,22.972456 C36.2191705,19.921554 33.8126305,18.3961029 30.4432333,18.3961029 C27.2664076,18.3961029 24.6670951,19.921554 23.8009257,22.972456 L36.6041124,22.972456 Z"
                      id="Fill-3" fill="#F16521"></path>
                    <g id="Group-7" transform="translate(0.000000, 14.139077)">
                      <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                      </mask>
                      <g id="Clip-6"></g>
                      <path
                        d="M12.5626935,0.00804350664 C10.1563545,0.00804350664 7.70096815,0.437365673 5.8723435,3.34529441 L5.8723435,1.04646021 C5.8723435,0.785046248 5.65826356,0.573099848 5.39413112,0.573099848 L0.478332989,0.573099848 C0.214200552,0.573099848 -8.04056126e-05,0.785046248 -8.04056126e-05,1.04646021 L-8.04056126e-05,2.4454271 L-8.04056126e-05,3.31814757 L-8.04056126e-05,4.09233509 L-8.04056126e-05,6.37508227 L-8.04056126e-05,19.4381392 L-8.04056126e-05,20.6657794 L0.00554798726,20.6720131 L-8.04056126e-05,20.6752305 L-8.04056126e-05,22.3979486 L-8.04056126e-05,23.6567574 C-8.04056126e-05,23.9183724 0.214200552,24.1305199 0.478332989,24.1305199 L5.39413112,24.1305199 C5.65826356,24.1305199 5.8723435,23.9183724 5.8723435,23.6567574 L5.8723435,21.2048955 C5.8723435,21.0860526 5.88621347,20.9734436 5.8723435,20.8588236 L5.8723435,17.2108922 L5.8723435,11.7354762 C5.8723435,7.39781414 8.71186771,5.77664538 11.7924077,5.77664538 C13.7175191,5.77664538 14.8247044,6.30088092 16.0283764,7.30189532 L18.6753292,2.24876337 C17.3759745,0.961601218 15.0170748,0.00804350664 12.5626935,0.00804350664"
                        id="Fill-5" fill="#F16521" mask="url(#mask-2)"></path>
                    </g>
                    <g id="Group-10" transform="translate(85.229949, 14.541252)">
                      <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                      </mask>
                      <g id="Clip-9"></g>
                      <path
                        d="M6.00382678,20.7677912 L6.00382678,11.1429312 L6.00161563,11.1429312 L6.00161563,0.603926587 C6.00161563,0.342512621 5.78753568,0.130566221 5.52320223,0.130566221 L0.607605112,0.130566221 C0.343472675,0.130566221 0.128990704,0.342512621 0.128990704,0.603926587 L0.128990704,16.3257647 C0.128990704,16.3340093 0.131402872,16.3418517 0.1318049,16.3500963 L0.1318049,20.2636644 L0.139644448,20.2731155 L0.128990704,20.2731155 L0.128990704,20.7758347 C0.128387662,20.7895087 0.127181578,20.8037859 0.127181578,20.8180631 C0.127181578,20.8321393 0.128387662,20.8462154 0.128990704,20.8602915 L0.128990704,23.2546424 C0.128990704,23.5162574 0.343472675,23.7284049 0.607605112,23.7284049 L5.52320223,23.7284049 C5.78753568,23.7284049 6.00161563,23.5162574 6.00161563,23.2546424 L6.00161563,20.861297 C6.00181664,20.8466176 6.00382678,20.8323403 6.00382678,20.8180631 C6.00382678,20.8037859 6.00241968,20.7895087 6.00161563,20.7756336 L6.00161563,20.7679923 L6.00382678,20.7677912 Z"
                        id="Fill-8" fill="#F16521" mask="url(#mask-4)"></path>
                    </g>
                    <path
                      d="M71.7575868,14.4333487 C68.6772478,14.4333487 65.9336073,15.3865043 63.8157235,18.7237552 C62.4679244,15.4818198 59.6281992,14.3380332 56.788474,14.3380332 C54.526061,14.3380332 51.7826215,15.1958732 50.5315102,17.5795664 L50.5315102,15.1856177 C50.5315102,14.9240026 50.3172293,14.7120562 50.0530968,14.7120562 L45.1372987,14.7120562 C44.8731662,14.7120562 44.6590863,14.9240026 44.6590863,15.1856177 L44.6590863,18.1836337 L44.6590863,20.5142398 L44.6590863,32.4580428 L44.6590863,34.8049369 L44.6590863,37.7866648 C44.6590863,38.0480788 44.8731662,38.2602263 45.1372987,38.2602263 L50.0530968,38.2602263 C50.3172293,38.2602263 50.5315102,38.0480788 50.5315102,37.7866648 L50.5315102,34.8049369 L50.5315102,25.6842036 C50.5315102,22.5852416 52.4085792,19.7726284 55.585204,19.7726284 C58.8098711,19.7726284 60.4465273,22.5852416 60.4465273,25.6842036 L60.4465273,34.8049369 L60.4616034,34.8228337 L60.4616034,37.7959149 C60.4616034,38.0575299 60.6756833,38.2694763 60.9398157,38.2694763 L65.8556139,38.2694763 C66.1197463,38.2694763 66.3340273,38.0575299 66.3340273,37.7959149 L66.3340273,31.3500497 L66.3185492,31.3500497 L66.3185492,25.6842036 C66.3185492,22.5852416 68.2919039,19.867944 71.4687297,19.867944 C74.6453544,19.867944 75.9451111,22.4899261 75.9451111,25.5884859 L75.9451111,32.4580428 L75.9447091,32.4580428 L75.9447091,37.7866648 C75.9447091,38.0480788 76.1589901,38.2602263 76.4229215,38.2602263 L81.3387196,38.2602263 C81.6030531,38.2602263 81.817133,38.0480788 81.817133,37.7866648 L81.817133,34.8049369 L81.817133,32.4580428 L81.817133,25.5884859 C81.817133,18.5331241 78.4959792,14.4333487 71.7575868,14.4333487"
                      id="Fill-11" fill="#F16521"></path>
                    <path
                      d="M66.2954728,9.81559169 C65.1147164,10.9967806 63.2002587,10.9967806 62.0195023,9.81559169 C60.8387459,8.63440274 60.8387459,6.71924381 62.0195023,5.53805486 C63.2002587,4.35686591 65.1147164,4.35686591 66.2954728,5.53805486 C67.4762292,6.71924381 67.4762292,8.63440274 66.2954728,9.81559169"
                      id="Fill-13" fill="#F16521"></path>
                    <path
                      d="M66.2954728,5.53815541 C67.4762292,6.71934436 67.4762292,8.63450329 66.2954728,9.81569224 C65.1147164,10.9968812 63.2002587,10.9968812 62.0195023,9.81569224"
                      id="Fill-15" fill="#D95129"></path>
                    <path
                      d="M78.47037,9.52642763 C76.9042697,11.0931016 74.3650605,11.0931016 72.7989601,9.52642763 C71.2330608,7.95975362 71.2330608,5.41961423 72.7989601,3.85294022 C74.3650605,2.28646731 76.9042697,2.28646731 78.47037,3.85294022 C80.0364703,5.41961423 80.0364703,7.95975362 78.47037,9.52642763"
                      id="Fill-17" fill="#F16521"></path>
                    <path
                      d="M78.47037,3.85306087 C80.0364703,5.41973488 80.0364703,7.95987428 78.47037,9.52654828 C76.9042697,11.0930212 74.3650605,11.0930212 72.7989601,9.52654828"
                      id="Fill-19" fill="#D95129"></path>
                    <path
                      d="M92.2633699,9.13426646 C90.1746331,11.2237684 86.7881497,11.2237684 84.6990109,9.13426646 C82.6104751,7.04476453 82.6104751,3.65683953 84.6990109,1.56713651 C86.7881497,-0.52236543 90.1746331,-0.52236543 92.2633699,1.56713651 C94.3523077,3.65683953 94.3523077,7.04476453 92.2633699,9.13426646"
                      id="Fill-21" fill="#1CA78B"></path>
                    <path
                      d="M92.2633699,1.56727727 C94.3523077,3.6567792 94.3523077,7.0447042 92.2633699,9.13440722 C90.1746331,11.2239092 86.7881497,11.2239092 84.6990109,9.13440722"
                      id="Fill-23" fill="#168972"></path>
                    <path
                      d="M110.244517,31.8506977 C110.244517,31.8506977 109.161253,32.2019978 108.467754,32.3747321 C107.774859,32.5484719 107.182269,32.6353418 106.691594,32.6353418 C106.315899,32.6353418 105.961913,32.5919068 105.630039,32.5054391 C105.297361,32.4183682 105.00187,32.2599111 104.741557,32.0286603 C104.481646,31.7976105 104.271586,31.4796909 104.113187,31.0749015 C103.954386,30.6705142 103.875187,30.1503004 103.875187,29.5144612 L103.875187,16.8744927 L109.915055,16.8744927 C110.176574,16.8744927 110.388443,16.6601332 110.388443,16.3961051 L110.388443,11.4785063 C110.388443,11.2142771 110.176574,10.9999176 109.915055,10.9999176 L103.875187,10.9999176 L103.875187,3.78529433 L101.838512,3.78529433 C101.376381,6.41491774 100.517247,8.55308289 99.2607085,10.1999909 C98.0041698,11.8475021 96.3644984,13.0900228 94.3429002,13.927754 L94.3429002,16.7451933 L97.6357111,16.7451933 L97.6357111,29.0811173 C97.6357111,30.6415576 97.7728027,32.0001058 98.0475888,33.1551534 C98.321772,34.3112064 98.7623947,35.2649652 99.3688541,36.0162287 C99.9755144,36.7678944 100.783792,37.3239018 101.795093,37.6848541 C102.805993,38.0456054 104.733517,38.255742 106.206145,38.2267854 C109.697357,38.1582145 111.103651,37.214309 111.73966,36.8089163 L110.244517,31.8506977 Z"
                      id="Fill-25" fill="#F16521"></path>
                  </g>
                </g>
              </g>
            </svg>
            <div class="default-text-size text-center mt-2">Remita Inflight</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 2">
          <div class="svg-bg mb-5">
            <svg class="cursor-pointer" (click)="child = 'carddebit'" width="140" version="1.1" id="Capa_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
              <path style="fill:#146AA8;" d="M481.53,347.033l30.015-191.649c3.121-19.448-10.112-37.747-29.561-40.877l0,0L76.973,49.501
           C57.525,46.38,39.227,59.613,36.106,79.061l-14.392,89.627v177.23L481.53,347.033z" />
              <path style="fill:#126099;" d="M481.985,114.507L366.953,96.049C289.694,214.264,164.799,296.142,69.019,346.034l412.511,0.999
           l30.015-191.649C514.666,135.935,501.433,117.637,481.985,114.507L481.985,114.507z" />
              <path style="fill:#4398D1;" d="M35.669,159.771h410.192c19.698,0,35.669,15.971,35.669,35.669v231.848
           c0,19.698-15.971,35.669-35.669,35.669H35.669C15.971,462.956,0,446.986,0,427.288V195.44C0,175.742,15.971,159.771,35.669,159.771z
           " />
              <path style="fill:#3E8CC7;" d="M445.861,159.771h-7.134c-84.179,149.444-241.469,251.956-337.49,303.185h344.624
           c19.698,0,35.669-15.971,35.669-35.669V195.44C481.53,175.742,465.559,159.771,445.861,159.771z" />
              <rect x="44.586" y="204.357" style="fill:#FDB62F;" width="89.172" height="71.338" />
              <g>
                <rect x="44.586" y="231.109" style="fill:#FD7B2F;" width="26.752" height="17.834" />
                <rect x="107.007" y="231.109" style="fill:#FD7B2F;" width="26.752" height="17.834" />
              </g>
              <circle style="fill:#FDB62F;" cx="392.358" cy="373.784" r="44.586" />
              <circle style="fill:#DE4C3C;" cx="338.854" cy="373.784" r="44.586" />
              <g>
                <rect x="35.669" y="338.115" style="fill:#87CED9;" width="53.503" height="17.834" />
                <rect x="124.841" y="338.115" style="fill:#87CED9;" width="53.503" height="17.834" />
                <rect x="214.013" y="338.115" style="fill:#87CED9;" width="53.503" height="17.834" />
                <rect x="35.669" y="391.619" style="fill:#87CED9;" width="231.848" height="17.834" />
                <rect x="312.103" y="204.357" style="fill:#87CED9;" width="17.834" height="35.669" />
                <rect x="347.772" y="204.357" style="fill:#87CED9;" width="17.834" height="35.669" />
                <rect x="383.44" y="204.357" style="fill:#87CED9;" width="17.834" height="35.669" />
                <rect x="419.109" y="204.357" style="fill:#87CED9;" width="17.834" height="35.669" />
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>
            <div class="default-text-size text-center mt-2">Card</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 3">
          <div class="svg-bg mb-5">
            <svg (click)="child = 'accountdebit'" width="140" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m248 456h184v40h-184z" fill="#3ea7ff" />
              <path d="m232 336 48 120h80v-120z" fill="#fe99a0" />
              <path d="m80 16h336v40h-336z" fill="#808080" />
              <path d="m136 56h224v280h-224z" fill="#a0cc00" />
              <g fill="#85b200">
                <path d="m208 336h-72v-72a72.00575 72.00575 0 0 1 72 72z" />
                <path d="m360 264v72h-72a72.00575 72.00575 0 0 1 72-72z" />
                <path d="m320 56a72 72 0 0 1 -144 0z" />
              </g>
              <path
                d="m445.83008 299.37012-29.83008 156.62988h-72l-8-8a79.19237 79.19237 0 0 1 0-112l-54.06006-54.07007a33.89088 33.89088 0 0 1 -9.93994-23.98999v-.65994a33.27993 33.27993 0 0 1 33.28-33.28h.65991a33.95618 33.95618 0 0 1 24 9.93994l30.06009 30.06006v-144a44.39571 44.39571 0 0 1 42.12012 30.35986l42.22 126.66016a44.40117 44.40117 0 0 1 1.48996 22.3501z"
                fill="#ffc7cc" />
              <path d="m336 240 48 48" fill="#ffc7cc" />
              <path
                d="m395.31006 288a8.00358 8.00358 0 0 1 -11.31006 0l-24-24v-22.62012l35.31006 35.31006a7.98533 7.98533 0 0 1 0 11.31006z"
                fill="#fe99a0" />
            </svg>
            <div class="default-text-size text-center mt-2">Direct debit</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 4">
          <div class="svg-bg mb-5">
            <svg class="cursor-pointer" (click)="child = 'cheque'" viewBox="0 0 512.00013 512" width="140"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m512 256c0 141.382812-114.613281 255.996094-255.996094 255.996094-141.386718 0-255.99999975-114.613282-255.99999975-255.996094s114.61328175-256 255.99999975-256c141.382813 0 255.996094 114.617188 255.996094 256zm0 0"
                fill="#40649c" />
              <path
                d="m497.960938 339.832031c-34.710938 100.214844-129.933594 172.164063-241.957032 172.164063-41.042968 0-79.828125-9.652344-114.214844-26.820313-52.894531-101.074219-95.179687-207.496093-126.117187-317.554687-5.195313-18.453125-10.0625-37.019532-14.609375-55.671875-4.929688-20.226563 7.785156-40.5625 28.128906-45.023438 24.605469-5.390625 49.203125-10.792969 73.8125-16.195312 68.699219-15.066407 137.390625-30.144531 206.09375-45.210938 7.074219-1.546875 14.136719-3.105469 21.210938-4.652343 11.449218-2.507813 22.820312.554687 31.292968 7.429687 6.082032 4.910156 10.667969 11.785156 12.707032 19.957031.0625.25.125.511719.199218.761719 9.800782 39.152344 21.449219 77.761719 34.917969 115.679687 23.917969 67.429688 53.539063 132.703126 88.535157 195.136719zm0 0"
                fill="#f1f4fe" />
              <path
                d="m497.960938 339.832031c-34.710938 100.214844-129.933594 172.164063-241.957032 172.164063-41.042968 0-79.828125-9.652344-114.214844-26.820313-11.171874-21.339843-21.859374-42.914062-32.066406-64.710937 10.175782 7.105468 20.886719 13.488281 32.066406 19.070312 34.386719 17.164063 73.171876 26.820313 114.214844 26.820313 102.023438 0 190.117188-59.683594 231.246094-146.042969 3.511719 6.539062 7.085938 13.039062 10.710938 19.519531zm0 0"
                fill="#c5cae2" />
              <g fill="#274b7b">
                <path
                  d="m120.171875 282.40625c-6.328125 0-12.289063-3.859375-14.660156-10.132812-3.0625-8.097657 1.019531-17.140626 9.113281-20.203126l248.683594-94.039062c8.097656-3.0625 17.144531 1.019531 20.207031 9.117188 3.058594 8.09375-1.019531 17.140624-9.117187 20.203124l-248.683594 94.039063c-1.828125.691406-3.699219 1.015625-5.542969 1.015625zm0 0" />
                <path
                  d="m172.125 400.101562c-5.898438 0-11.542969-3.34375-14.207031-9.039062-3.667969-7.839844-.285157-17.167969 7.554687-20.835938l154.121094-72.097656c7.839844-3.667968 17.171875-.285156 20.839844 7.554688 3.667968 7.839844.285156 17.171875-7.558594 20.839844l-154.121094 72.097656c-2.144531 1.003906-4.40625 1.480468-6.628906 1.480468zm0 0" />
                <path
                  d="m145.5625 342.347656c-5.886719-.332031-11.335938-3.988281-13.675781-9.820312-3.21875-8.035156.6875-17.160156 8.722656-20.378906l157.941406-63.285157c8.035157-3.21875 17.160157.683594 20.378907 8.71875 3.21875 8.035157-.683594 17.160157-8.71875 20.378907l-157.945313 63.285156c-2.199219.878906-4.484375 1.226562-6.703125 1.101562zm0 0" />
                <path
                  d="m212.882812 500.738281c-2.328124 0-4.617187-1.105469-6.035156-3.167969-8.507812-12.382812-15.105468-26.382812-18.566406-39.421874-2.769531-10.429688-4.714844-24.929688 1.746094-34.796876 2.925781-4.46875 10.105468-11.527343 25.261718-8.59375 11.695313 2.261719 24.210938 8.839844 36.3125 15.199219 13.5 7.09375 28.796876 15.136719 35.757813 12.175781.847656-.363281 3.425781-1.460937 5.296875-7.929687 2.46875-8.53125 1.433594-17.625-3.074219-27.03125-1.746093-3.640625-.207031-8.011719 3.433594-9.757813 3.640625-1.742187 8.011719-.207031 9.757813 3.433594 6.109374 12.742188 7.433593 25.332032 3.9375 37.417969-2.523438 8.722656-7.105469 14.550781-13.621094 17.324219-13.34375 5.683594-30.320313-3.238282-48.292969-12.683594-11.164063-5.867188-22.707031-11.933594-32.285156-13.785156-7.78125-1.507813-9.636719 1.316406-10.246094 2.246094-5.347656 8.164062.171875 33.957031 16.640625 57.914062 2.289062 3.328125 1.445312 7.882812-1.882812 10.171875-1.269532.871094-2.710938 1.285156-4.140626 1.285156zm0 0" />
              </g>
              <path
                d="m325.644531 372.902344-17.742187 30.320312c-.671875 1.144532-1.523438 2.128906-2.5 2.914063-3.390625 2.773437-8.273438 3.300781-12.285156.953125-4.011719-2.347656-5.941407-6.863282-5.183594-11.179688.203125-1.238281.644531-2.457031 1.316406-3.605468l17.742188-30.320313zm0 0"
                fill="#fee175" />
              <path
                d="m305.402344 406.136719c-3.390625 2.773437-8.273438 3.300781-12.285156.953125-4.011719-2.347656-5.941407-6.863282-5.183594-11.179688 3.390625-2.773437 8.273437-3.300781 12.285156-.953125 4.007812 2.347657 5.941406 6.863281 5.183594 11.179688zm0 0"
                fill="#40649c" />
              <path
                d="m336.285156 206.652344c-48.097656 82.191406-62.375 163.285156-31.886718 181.128906 30.488281 17.84375 94.195312-34.324219 142.296874-116.515625 48.097657-82.191406 62.375-163.28125 31.886719-181.125s-94.195312 34.320313-142.296875 116.511719zm0 0"
                fill="#f974ac" />
              <path
                d="m435.535156 361.949219c-2.90625-1.699219-5.164062-4.511719-6.085937-8.027344-7.617188-29.125 10.636719-40.429687 23.96875-48.683594 9.039062-5.597656 18.386719-11.386719 25.085937-22.832031 14.027344-23.96875-14.976562-51.164062-15.269531-51.4375-5.332031-4.917969-5.691406-13.234375-.792969-18.582031 4.902344-5.347657 13.191406-5.734375 18.550782-.851563.449218.410156 11.101562 10.199219 18.96875 24.730469 11.335937 20.925781 11.769531 41.480469 1.261718 59.4375-10 17.085937-23.839844 25.660156-33.945312 31.917969-13.351563 8.265625-14.957032 9.703125-12.359375 19.640625 1.839843 7.03125-2.367188 14.226562-9.402344 16.066406-3.515625.917969-7.074219.324219-9.980469-1.378906zm0 0"
                fill="#fee175" />
              <path
                d="m304.414062 387.777344c-30.488281-17.847656-16.203124-98.929688 31.898438-181.117188s111.800781-134.351562 142.292969-116.507812c-11.714844-6.855469-60.203125 54.210937-108.304688 136.398437-48.101562 82.1875-77.597656 154.371094-65.886719 161.226563zm0 0"
                fill="#ec4081" />
              <path
                d="m484.722656 229.023438c-3.679687 6.28125-11.738281 8.378906-18.011718 4.710937l-107.460938-62.894531c-6.269531-3.671875-8.386719-11.726563-4.710938-18.011719 3.667969-6.269531 11.738282-8.378906 18.007813-4.710937l107.464844 62.894531c6.269531 3.671875 8.378906 11.738281 4.710937 18.011719zm0 0"
                fill="#fcbc77" />
              <path
                d="m484.722656 229.023438c-3.679687 6.28125-11.738281 8.378906-18.011718 4.710937l-73.234376-42.863281c-6.269531-3.667969-8.386718-11.726563-4.710937-18.007813 3.667969-6.273437 11.738281-8.382812 18.007813-4.710937l73.238281 42.859375c6.269531 3.671875 8.378906 11.738281 4.710937 18.011719zm0 0"
                fill="#fee175" />
              <path
                d="m425.839844 268.960938c10.097656-17.253907 13.707031-33.921876 8.058594-37.226563-5.648438-3.304687-18.410157 8.003906-28.507813 25.257813-10.097656 17.253906-13.703125 33.921874-8.058594 37.226562 5.648438 3.304688 18.410157-8.003906 28.507813-25.257812zm0 0"
                fill="#ff99c2" />
              <path
                d="m478.058594 177.128906c5.046875-8.625 4.5625-18.300781-1.082032-21.605468-5.648437-3.304688-14.316406 1.011718-19.367187 9.640624-5.046875 8.625-4.5625 18.296876 1.082031 21.601563 5.648438 3.304687 14.316406-1.007813 19.367188-9.636719zm0 0"
                fill="#ff99c2" />
              <path
                d="m134.890625 196.710938c-16.644531 5.140624-33.019531 1.285156-36.054687-7.488282-1.613282-4.660156.132812-12.941406 5.339843-14.742187 5.761719-1.992188 13.21875 4.488281 23.980469 2.761719l-7.679688-22.210938c-14.273437-.4375-31.121093-1.214844-37.191406-18.761719-6.019531-17.414062 3.976563-30.234375 17.796875-37.316406l-1.324219-3.839844c-.664062-1.917969.914063-4.460937 3.929688-5.503906 2.605469-.902344 5.824219-.019531 6.488281 1.902344l1.183594 3.425781c8.597656-2.664062 25.398437-6.015625 28.667969 3.441406 1.28125 3.703125 1.421875 12.097656-4.613282 14.183594-4.523437 1.5625-8.644531-1.921875-17.792968.472656l6.921875 20.019532c14.085937.347656 30.851562 1.765624 37.25 20.277343 5.878906 17-1.53125 31.078125-16.675781 39.230469l1.375 3.976562c.664062 1.917969-1.324219 4.601563-3.929688 5.503907-3.015625 1.042969-5.824219.019531-6.488281-1.902344zm-20.535156-63.820313-5.640625-16.316406c-5.742188 3.371093-7.257813 7.421875-6.121094 10.714843 1.375 3.976563 5.792969 5.210938 11.761719 5.601563zm16.449219 22.710937 6.355468 18.375c4.28125-2.710937 7.082032-6.59375 5.375-11.53125-1.5625-4.523437-5.988281-6.21875-11.730468-6.84375zm0 0"
                fill="#36b990" />
            </svg>
            <div class="default-text-size text-center mt-2">Cheque</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 5">
          <div class="svg-bg mb-5">
            <img style="width: 140px" src="/assets/images/ellipsis.svg" />
            <div class="default-text-size text-center mt-2">Others</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 6">
          <div class="svg-bg mb-5">
            <img style="width: 140px" src="/assets/images/coat_of_arms_of_nigeria.svg" />
            <div class="default-text-size text-center mt-2">Others</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 7">
          <div class="svg-bg mb-5">
            <svg class="cursor-pointer" (click)="child = 'agents'" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              width="140" x="0px" y="0px" viewBox="0 0 468.302 468.302"
              style="enable-background:new 0 0 468.302 468.302;" xml:space="preserve">
              <path style="fill:#FCD462;" d="M243.381,83.243c-60.488,0-109.525,55.518-109.525,124.003s49.036,124.006,109.525,124.006
	s109.525-55.52,109.525-124.006S303.869,83.243,243.381,83.243z" />
              <path style="fill:#64798A;" d="M214.295,173.376c20.539,3.055,48.68,1.084,84.977-14.619c0,0,42.98,0,40.505,95.069
	c0,0,25.573-36.254,19.798-75.733l9.9,17.725c0,0-9.9-111.987-90.743-120.85c0,0,15.674-9.668,37.122-4.027
	c0,0-68.361-22.869-93.576-1.768c0,0-3.05-10.813,11.082-15.957c0,0-61.871-5.641-68.47,41.895c0,0-59.246,15.307-51.484,81.372
	c0,0,8.587-3.464,11.061-14.22c0,0-4.566,91.565,20.815,102.844c0,0-23.289-98.292,26.206-110.376c0,0,4.916,5.097,15.013,10.129
	L214.295,173.376z" />
              <path style="fill:#D5D6DB;" d="M330.35,350.227H137.952c-65.206,0-118.066,52.86-118.066,118.066h428.529
	C448.415,403.087,395.555,350.227,330.35,350.227z" />
              <path style="fill:#FCD462;" d="M268.701,320.945v62.689c-8.117,0-14.673,6.556-14.673,14.673h-27.598
	c0-8.117-6.556-14.673-14.611-14.673v-62.689H268.701z" />
              <g>
                <polygon style="fill:#64798A;"
                  points="257.516,350.227 293.728,350.227 261.028,400.821 238.566,400.821 	" />
                <polygon style="fill:#64798A;"
                  points="223.871,350.227 187.659,350.227 220.359,400.821 242.821,400.821 	" />
              </g>
              <polygon style="fill:#E56353;"
                points="258.086,468.302 222.308,468.302 229.551,400.805 250.843,400.805 " />
              <g>
                <path style="fill:#64798A;" d="M327.206,221.48c0,16.734-17.233,30.345-38.587,30.345c-21.292,0-38.525-13.612-38.525-30.345
		c0-16.796,17.233-30.408,38.525-30.408C309.973,191.072,327.206,204.684,327.206,221.48z" />
                <path style="fill:#64798A;" d="M235.545,221.48c0,16.734-17.233,30.345-38.525,30.345c-21.354,0-38.587-13.612-38.587-30.345
		c0-16.796,17.233-30.408,38.587-30.408C218.312,191.072,235.545,204.684,235.545,221.48z" />
              </g>
              <g>
                <path style="fill:#27A2DB;" d="M317.215,221.48c0,12.425-12.8,22.478-28.597,22.478c-15.735,0-28.535-10.053-28.535-22.478
		c0-12.425,12.8-22.54,28.535-22.54C304.415,198.94,317.215,209.055,317.215,221.48z" />
                <path style="fill:#27A2DB;" d="M225.555,221.48c0,12.425-12.8,22.478-28.535,22.478c-15.797,0-28.597-10.053-28.597-22.478
		c0-12.425,12.8-22.54,28.597-22.54C212.755,198.94,225.555,209.055,225.555,221.48z" />
              </g>
              <g>
                <path style="fill:#64798A;" d="M252.392,218.09c-10.415-7.287-17.125-1.817-18.363-0.646l-8.768-8.89
		c5.021-5.018,18.634-11.622,34.284-0.707L252.392,218.09z" />
                <path style="fill:#64798A;" d="M159.953,218.188c-1.28,0-2.573-0.39-3.686-1.207l-19.098-14.006
		c-2.78-2.043-3.381-5.951-1.341-8.732c2.04-2.774,5.942-3.384,8.726-1.341l19.098,14.006c2.78,2.043,3.381,5.951,1.341,8.732
		C163.77,217.304,161.873,218.188,159.953,218.188z" />
                <path style="fill:#64798A;" d="M321.12,218.188c-1.924,0-3.817-0.884-5.04-2.549c-2.04-2.78-1.439-6.689,1.341-8.732l19.098-14.006
		c2.784-2.043,6.689-1.427,8.726,1.341c2.04,2.78,1.439,6.689-1.341,8.732l-19.098,14.006
		C323.693,217.798,322.401,218.188,321.12,218.188z" />
              </g>
              <ellipse style="fill:#3A556A;" cx="243.379" cy="141.991" rx="179.856" ry="49.102" />
              <path style="fill:#64798A;" d="M231.266,19.643c-83.249-82.007-93.189,119.283-93.189,119.283c83.871,54.05,203.153,0,203.153,0
	C318.243-59.88,231.266,19.643,231.266,19.643z" />
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>
            <div class="default-text-size text-center mt-2">Assign Agent</div>
          </div>
        </div>

        <div *ngIf="loan.repayment_mode === 10">
          <div class="svg-bg mb-5">
            <svg (click)="child = 'nibbsdebit'" width="140" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m248 456h184v40h-184z" fill="#3ea7ff" />
              <path d="m232 336 48 120h80v-120z" fill="#fe99a0" />
              <path d="m80 16h336v40h-336z" fill="#808080" />
              <path d="m136 56h224v280h-224z" fill="#a0cc00" />
              <g fill="#85b200">
                <path d="m208 336h-72v-72a72.00575 72.00575 0 0 1 72 72z" />
                <path d="m360 264v72h-72a72.00575 72.00575 0 0 1 72-72z" />
                <path d="m320 56a72 72 0 0 1 -144 0z" />
              </g>
              <path
                d="m445.83008 299.37012-29.83008 156.62988h-72l-8-8a79.19237 79.19237 0 0 1 0-112l-54.06006-54.07007a33.89088 33.89088 0 0 1 -9.93994-23.98999v-.65994a33.27993 33.27993 0 0 1 33.28-33.28h.65991a33.95618 33.95618 0 0 1 24 9.93994l30.06009 30.06006v-144a44.39571 44.39571 0 0 1 42.12012 30.35986l42.22 126.66016a44.40117 44.40117 0 0 1 1.48996 22.3501z"
                fill="#ffc7cc" />
              <path d="m336 240 48 48" fill="#ffc7cc" />
              <path
                d="m395.31006 288a8.00358 8.00358 0 0 1 -11.31006 0l-24-24v-22.62012l35.31006 35.31006a7.98533 7.98533 0 0 1 0 11.31006z"
                fill="#fe99a0" />
            </svg>
            <div class="default-text-size text-center mt-2">Direct debit</div>
          </div>
        </div>

        <br>
        <br>
        <br>
        <div class="flex">
          <!-- <div class="flex-1 flex-center border-right">
            <div class="h-group text-center">
              <div class="title">N{{ loan.monthly_repayment | number:'.2-2' }}</div>
              <div>Monthly Repayment</div>
            </div>
          </div> -->
          <div class="flex-1 flex-center border-right" *ngIf="loan.request_period_id!='1'">
            <div class="h-group text-center">
              <div class="title" *ngIf="loan.monthly_repayment">
                N{{ loan.monthly_repayment | number:'.2-2' }}</div>
              <div class="title" *ngIf="!loan.monthly_repayment">
                N{{ calculateMonthlyRepayment(loan.request_principal, loan.request_tenor, loan.request_rate) |
                number:'.2-2' }}
              </div>
              <div>Monthly Repayment</div>
            </div>
          </div>
          <div class="flex-1 flex-center border-right" *ngIf="loan.request_period_id=='1'">
            <div class="h-group text-center">
              <div class="title" *ngIf="loan.total_repayment">
                N{{ loan.total_repayment | number:'.2-2' }}</div>
              <div class="title" *ngIf="!loan.total_repayment">
                N0
              </div>
              <div>Total Repayment</div>
            </div>
          </div>
          <div class="flex-1 flex-center">
            <div class="h-group text-center">
              <div class="title">{{ loan.request_principal | number:'.2-2' }}</div>
              <div>for {{ loan.request_tenor }} {{ loan.loan_duration }}</div>
            </div>
          </div>
          <div class="flex-1 flex-center border-left">
            <div class="h-group text-center">
              <div class="title">{{ loan.request_rate }}% / {{ loan.interest_duration }}</div>
              <div>Interest rate</div>
            </div>
          </div>
          <!-- <div class="flex-1 flex-center border-left">
                  <div class="icon-text medium cursor-pointer" (click)="toggleDetails()">
                    <div class="icon color-blue"><i class="fas fa-ellipsis-h"></i></div>
                    <div class="text">More</div>
                  </div>
                </div> -->
        </div>

      </div>

      <div class="panel padding-regular flex bg-off-white" *ngIf="loan?.loan_status <= '3'">

        <!-- <div class="flex-1 flex-center" (click)="registerPayment()" *ngIf="loan?.loan_status <= '3'"> -->
        <div class="flex-1 flex-center" (click)="child = 'payment'" *ngIf="loan?.loan_status <= '3'">
          <div class="icon-text medium cursor-pointer">
            <div class="icon color-green"><i class="fas fa-cash-register"></i></div>
            <div class="text">Register payment</div>
          </div>
        </div>
        <!-- <div class="flex-1 flex-center" (click)="openModal_('prepayment')">
                <div class="icon-text medium cursor-pointer">
                  <div class="icon color-yellow"><i class="fas fa-money-check-alt"></i></div>
                  <div class="text">Prepayment</div>
                </div>
              </div> -->
        <!-- <div class="flex-1 flex-center" (click)="breakLoan()"> -->
        <div class="flex-1 flex-center" (click)="child = 'breakloan'">
          <div class="icon-text medium cursor-pointer">
            <div class="icon color-red"><i class="fas fa-book-open"></i></div>
            <div class="text">Break loan</div>
          </div>
        </div>
        <div class="flex-1 flex-center" *ngIf="loan?.repayment_mode == 2" (click)="child = 'carddebit'">
          <!-- <div (click)="openModal_('setup_cards')" class="icon-text medium cursor-pointer"> -->
          <div class="icon-text medium cursor-pointer">
            <div class="icon color-blue"><i class="fas fa-credit-card"></i></div>
            <div class="text">Debit/Credit Card</div>
          </div>
        </div>
        <div class="flex-1 flex-center" *ngIf="loan?.repayment_mode == 1" (click)="child = 'remita'">
          <!-- <div (click)="openModal_('remita_inflight')" class="icon-text medium cursor-pointer"> -->
          <div class="icon-text medium cursor-pointer">
            <div class="icon color-red"><i class="fas fa-wallet"></i></div>
            <div class="text">Remita Inflight</div>
          </div>
        </div>
        <div class="flex-1 flex-center" *ngIf="loan?.repayment_mode == 3" (click)="child = 'accountdebit'">
          <!-- <div (click)="openModal_('account_debit')" class="icon-text medium cursor-pointer"> -->
          <div class="icon-text medium cursor-pointer">
            <div class="icon color-orange"><i class="fas fa-hand-holding-usd"></i></div>
            <div class="text">Direct Debit</div>
          </div>
        </div>
        <div class="flex-1 flex-center" *ngIf="loan?.repayment_mode == 3" (click)="child = 'cheque'">
          <!-- <div (click)="openModal_('cheques')" class="icon-text medium cursor-pointer"> -->
          <div (click)="openModal_('cheques')" class="icon-text medium cursor-pointer">
            <div class="icon color-purple"><i class="fas fa-money-check-alt"></i></div>
            <div class="text">Cheques</div>
          </div>
        </div>
        <div class="flex-1 flex-center" *ngIf="loan?.loan_status <= '3'">
          <div (click)="openActionsForPortfolioRequest()" class="icon-text medium cursor-pointer">
            <div class="icon color-blue"><i class="fas fa-ellipsis-h"></i></div>
            <div class="text">Other Actions</div>
          </div>
        </div>
      </div>
      <div class="panel padding-regular">
        <app-nibbsdebit *ngIf="lender?.PEOPLE_ID=='40245'" [token]="currentUser.token" [parentRouteId]="requestId"
          [loan]="loan">
        </app-nibbsdebit>
      </div>
      <div class="panel padding-regular">
        <div class="flex align-center mt-5 mb-5">
          <div class="image flex-center justify-start mr-4">
            <img src="assets/images/form.svg" style="max-width: 100px;">
          </div>
          <div class="h-group flex-1 ml-4">
            <h3 class="title no-margin">NIBBS Mandate</h3>
            <p class="subtitle fill-w mb-2">
              Set up NIBBS Mandate
            </p>
            <!-- <app-button class="small outline orange mt-2" label="Run analysis"
                    (click)="setupmandate()"></app-button> -->
            <app-button class="small outline orange mt-2" label="Open" (click)="child='nibbsdebit'">
            </app-button>
          </div>
        </div>
      </div>
      <!-- <div class="panel padding-regular">
        <div class="flex align-center mt-5 mb-5">
            <div class="image flex-center justify-start mr-4">
                <img src="assets/images/form.svg" style="max-width: 100px;">
            </div>
            <div class="h-group flex-1 ml-4">
                <h3 class="title no-margin">Account Balance</h3>
                <p class="subtitle fill-w mb-2">
                    Fetch Account Balance
                </p>
               <app-button class="small outline orange mt-2" label="Run analysis"
                    (click)="setupmandate()"></app-button>  
                <app-button class="small outline orange mt-2" label="Open" (click)="child='accountbalance'">
                </app-button>
            </div>
        </div>
      </div> -->
      <app-microcreditresult *ngIf="loan && lender?.PEOPLE_ID=='40245'" [summary]="microcredit" (close)="cancel()"></app-microcreditresult>
      <app-attachments-card [reqid]="loan?.request_id" (openModal)="more='attachment'">
      </app-attachments-card>
      <app-requestloanproduct [loan]="loan" *ngIf="loan && (lender?.PEOPLE_ID=='40245'||lender?.PEOPLE_ID=='40460')"></app-requestloanproduct>
      <app-ninbyphonenumber *ngIf="loan && lender?.PEOPLE_ID=='40245'" [reqid]="loan?.people_id">
      </app-ninbyphonenumber>
      <app-request-sideview-more-pictures [phone]="loan?.customer?.phone" [referal_code]="loan?.referal_code"
        [creditclan_request_id]="loan?.request_id" *ngIf="lender?.PEOPLE_ID=='40245' && loan">
      </app-request-sideview-more-pictures>
      <app-loanstatement *ngIf="subview == 'statement'" [where]="2" [parentRouteId]="requestId" [showHeader]="true">
      </app-loanstatement>

      <app-statementschedule *ngIf="subview == 'schedule'" [where]="1" [parentRouteId]="requestId" [showHeader]="true"
        [embedModal]="true" (openCardDebit)="setChild('carddebit', $event)"
        (openAccountDebit)="setChild('accountdebit', $event)" (openClearingFines)="setChild('clearingfines', $event)"
        (openMakeLoanPayment)="setChild('makeloanpayment', $event)" (openStopRemita)="setChild('stopremita', $event)">
      </app-statementschedule>
      <app-collections [loan]="loan?.customer?.lender_logo"></app-collections>

    </div>
    <div class="content" *ngIf="loan && view === 'application'">
      <div *ngIf="loan.status < 3" class="panel padding-regular flex">
        <div class="flex-1 flex-center border-right">
          <div class="h-group text-center">
            <div class="title">N{{ loan.request_principal }}</div>
            <div>Monthly Repayment</div>
          </div>
        </div>
        <div class="flex-1 flex-center">
          <div class="h-group text-center">
            <div class="title">{{ loan.request_rate }}%</div>
            <div>{{ loan.interest_duration }}</div>
          </div>
        </div>
        <div class="flex-1 flex-center border-left">
          <div class="icon-text medium cursor-pointer" (click)="toggleDetails()">
            <div class="icon color-blue"><i class="fas fa-ellipsis-h"></i></div>
            <div class="text">More</div>
          </div>
        </div>
      </div>
      <!-- <app-ippisdetails *ngIf="loan && loan?.repayment_mode=='6'" [loan]="loan">
      </app-ippisdetails>
      <app-remitainfodetails [customer]="customer" *ngIf="loan && loan?.use_remita==1">
      </app-remitainfodetails> -->
      <app-bank-statement-analyze *ngIf="loan && lender?.PEOPLE_ID=='40245'" [reqid]="requestId"
        (openMore)="setChild($event)">
      </app-bank-statement-analyze>
      <app-customer-info *ngIf="!loading" [embeded]="true" [loan]="loan" [customer]="customer"
        (openModalChild)="setChild($event)" [is_individual]="is_individual" (refresh)="getCustomer()"
        [reqid]="requestId" [pid]="loan.people_id" [from_loan]="true" [show_header]="false">
      </app-customer-info>
    </div>
    <div class="content" *ngIf="loan && view === 'timeline'">
      <app-applicationstimeline [loan]="loan" [view_from]="'drawer'" [requestId]="requestId"></app-applicationstimeline>
    </div>
    <div class="content" *ngIf="loan && view === 'comment'">
      <app-comment [loan]="loan" [view_from]="'drawer'" [requestId]="requestId"></app-comment>
    </div>
    <div class="content" *ngIf="loan && view === 'links'">
      <div class="grid three mt-4">
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'analytics'">
          <div class="icon color-blue round-icon"><i class="fas fa-building"></i></div>
          <div class="text">Analytics</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'avs'">
          <div class="icon color-blue round-icon"><i class="fas fa-credit-card"></i></div>
          <div class="text">Adress VS</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'creditbureau'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Credit bureau</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'collateral'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Collateral</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'recommendation'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Recommendation</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'eligibility'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Eligibility</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'actions'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Take action</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'checklist'" *ngIf="grantRight('21')">
          <div class="icon color-blue round-icon"><i class="fas fa-tasks"></i></div>
          <div class="text">Checklist</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="more = 'list'">
          <div class="icon color-blue round-icon"><i class="fas fa-ellipsis-h"></i></div>
          <div class="text">More</div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="details">
      <button class="closer" (click)="toggleDetails()"><i class="fa fa-times"></i></button>
      <div class="dialog panel padding-regular">
        <ul class="decList bigger">
          <li><span>Reason</span><span>{{ loan.loan_purpose }}</span></li>
          <li><span>Interest rate</span><span>{{ loan.request_rate }}%</span></li>
          <li><span>Total fees</span><span>{{ loan.total_fees_and_charges }}</span></li>
          <li><span>1st repayment</span><span>{{ loan.repayment_started_when }}</span></li>
          <li><span>Net disbursement</span><span>{{ loan.net_disbursement }}</span></li>
          <li><span>Officer</span><span><img class="image-avatar" src="assets/images/user.png" /></span></li>
        </ul>
        <app-button class="outline small orange block mb-1" label="Edit request"></app-button>
        <app-button class="outline small block" label="Send offer letter"></app-button>
      </div>
    </div>

    <div class="overlay" *ngIf="child">
      <button class="closer" (click)="child = ''"><i class="fa fa-times"></i></button>
      <div class="child overflow-visible">
        <div class="panel overflow-visible" *ngIf="child === 'account'">
          <app-accountmodal [request_id]="requestId" [token]="currentUser.token"></app-accountmodal>
        </div>
        <app-applicationsindebtedness [requestId]="requestId" *ngIf="child === 'indebtedness'">
        </app-applicationsindebtedness>

        <div class="panel padding-regular" *ngIf="child === 'repaymentflow'">
          <app-repaymentflow [request_id]="requestId" [token]="currentUser.token" [fromModal]="false">
          </app-repaymentflow>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'stopremita'">
          <app-stopremita [repayment]="repayment" [loan]="loan" (modalClosed)="child = ''"></app-stopremita>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'changepayment'">
          <app-changepayment [loan]="loan" [currentUser]='currentUser'></app-changepayment>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'cheque'">
          <app-requestheader [loan]="loan" [where]=''></app-requestheader>
          <h5 class="title">Loan cheque</h5>
          <app-loancheque [parentRouteId]="requestId" [where]='2' [scroll]="false"></app-loancheque>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'accountdebit'">
          <app-accountdebit [sending_instruction]="false" [token]="currentUser.token" [parentRouteId]="requestId"
            [repayment]="repayment" [loan]="loan"></app-accountdebit>
        </div>
        <div class="panel overflow-visible" *ngIf="child === 'bvn'">
          <app-bvnmodal [request_id]="requestId" [customer_id]="loan?.people_id" [token]="currentUser.token"
              [viewOnly]="true"></app-bvnmodal>
      </div>
        <div class="panel padding-regular" *ngIf="child === 'nibbsdebit'">
          <app-nibbsdebit [sending_instruction]="false" [token]="currentUser.token" [parentRouteId]="requestId"
            [repayment]="repayment" [loan]="loan"></app-nibbsdebit>
        </div>
        <div class="panel padding-regular" *ngIf="child === 'accountbalance'">
          <app-accountbalance [reqid]="requestId"></app-accountbalance>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'remita'">
          <app-remitainflight [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan" [scroll]="false">
          </app-remitainflight>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'carddebit'">
          <app-carddebitsetup [chargingCard]='chargingCard' [token]="currentUser.token" [parentRouteId]="requestId"
            [loan]="loan"></app-carddebitsetup>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'breakloan'">
          <app-breakingloan (breakLoanHasBeenSent)="breakloanSent($event)" [token]="currentUser.token"
            [parentRouteId]="requestId" [loan]="loan"></app-breakingloan>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'payment'">
          <app-repaymentflow [request_id]="requestId" [token]="currentUser.token" [fromModal]="false">
          </app-repaymentflow>
        </div>

        <app-analyticshome [requestId]="requestId" *ngIf="child === 'analytics'" [showHeader]="false">
        </app-analyticshome>

        <app-locationdetails [customer_id]='loan.people_id' *ngIf="child === 'avs'" from="drawer">
        </app-locationdetails>

        <app-creditregistry [from_where]='1' [request_id]="requestId" *ngIf="child === 'creditbureau'">
        </app-creditregistry>

        <div class="panel padding-regular" *ngIf="child === 'checklist'">
          <app-applicationschecklist [loan]='loan' [where]="1" [parentRouteId]="requestId">
          </app-applicationschecklist>
        </div>

        <app-loanstatement *ngIf="child == 'statement'" (sendLoanStatement)="sendLoanStatement($event)"
          (modifyLoanContract)="modifyLoanContract($event)" [where]="2" [parentRouteId]="requestId"
          [showHeader]="false">
        </app-loanstatement>

        <app-statementschedule *ngIf="child === 'schedule'" [where]="1" [parentRouteId]="requestId" [showHeader]="false"
          [embedModal]="true" (openCardDebit)="setChild('carddebit', $event)"
          (openAccountDebit)="setChild('accountdebit', $event)" (openClearingFines)="setChild('clearingfines', $event)"
          (openMakeLoanPayment)="setChild('makeloanpayment', $event)" (openStopRemita)="setChild('stopremita', $event)">
        </app-statementschedule>

        <div class="panel padding-regular" *ngIf="child === 'clearingfines'">
          <app-clearingfines [repayment]="repayment" [record_type]='21' (modalClosed)="child = ''"></app-clearingfines>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'makeloanpayment'">
          <app-makeloanpayment [repayment]="repayment" [record_type]='21' (modalClosed)="child = ''">
          </app-makeloanpayment>
        </div>

        <app-applicationseligibility *ngIf="child === 'eligibility'" [requestId]="requestId">
        </app-applicationseligibility>

        <app-recommendations *ngIf="child === 'recommendation'" [requestId]="requestId">
        </app-recommendations>

        <app-collateral *ngIf="child === 'collateral'" [requestId]="requestId">
        </app-collateral>



        <app-loanactions [requestId]="requestId" [loan]="loan" *ngIf="child === 'actions'"></app-loanactions>

        <app-agents-repayment-mode *ngIf="child === 'agents'"></app-agents-repayment-mode>


        <div class="panel padding-regular" *ngIf="child === 'edit'">
          <app-newloanform [request_id]='loan?.top_up_request_id' [request_step]="1" [sub]='1' [from]='2'
            [contraints]="false"
            [contract_details]="{ currentUser: this.currentUser.token, request_id: loan?.top_up_request_id, loan: loan?.loan }"
            (close)="child = ''">
          </app-newloanform>
        </div>
        <div class="panel padding-regular" *ngIf="child === 'editcontract'">
          <app-modifyloanform [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2' [contraints]="false"
            [contract_details]="{ currentUser: this.currentUser.token, request_id: requestId, loan: loan }"
            (close)="child = ''">
          </app-modifyloanform>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="more">
      <button class="closer" (click)="more = ''"><i class="fa fa-times"></i></button>
      <div class="more panel overflow-visible">
        <div class="grid three mt-4" *ngIf="more === 'list'">
          <div class="flex-1 flex-center mb-3" (click)="more = 'bvn'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-fingerprint"></i></div>
              <div class="text">BVN</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'account'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-building"></i></div>
              <div class="text">Account</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-credit-card"></i></div>
              <div class="text">Card</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'attachment'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-file"></i></div>
              <div class="text">Attachment</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="openModal.emit('location')" *ngIf="grantRight('26')">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-map"></i></div>
              <div class="text">Request Location</div>
            </div>
          </div>
        </div>
        <div class="grid three mt-4" *ngIf="more === 'liste'">
          <div class="flex-1 flex-center mb-3" (click)="sendSMSToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-mobile"></i></div>
              <div class="text">SMS</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="sendEmailToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-envelope"></i></div>
              <div class="text">EMAIL</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-whatsapp"></i></div>
              <div class="text">WhatsApp</div>
            </div>
          </div>

        </div>
        <div *ngIf="more === 'bvn'">
          <div class="padding-regular-x mt-4">
            <button class=" btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-bvnmodal [request_id]="requestId" [token]="currentUser.token" [viewOnly]="true"></app-bvnmodal>
        </div>
        <div *ngIf="more === 'card'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-cardmodal [request_id]="requestId" [token]="currentUser.token"></app-cardmodal>
        </div>
        <div *ngIf="more === 'account'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-accountmodal [request_id]="requestId" [token]="currentUser.token"></app-accountmodal>
        </div>
        <div *ngIf="more === 'attachment'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-loanphotos [parentRouteId]="requestId" [where]='2'></app-loanphotos>
        </div>
      </div>
    </div>
  </div>
</div>